import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './BlockLoader.scss';
import { Spinner } from 'reactstrap';

class BlockLoader extends Component {
    render() {
        if (this.props.loading) {
            return (
                <div className="block-loader">
                    <Spinner color="secondary" />
                </div>
            );
        } else {
            return null;
        }
    }
}


BlockLoader.propTypes = {
    translation: PropTypes.object.isRequired,
    loading: PropTypes.bool
};

export default BlockLoader;
