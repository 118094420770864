import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import { handleApiCallEpic } from "./common/handleApiCall";
// admin reducers
import closestClusters from "./admin/closestClusters";
import closestSkills from "./admin/closestSkills";
import clusters from "./admin/clusters";
import relatedProfiles from "./admin/relatedProfiles";
import relatedSkills from "./admin/relatedSkills";
import systemCurrentNode from "./admin/systemCurrentNode";
import systemModel from "./admin/systemModel";
import systemNodesToAdd from "./admin/systemNodesToAdd";
import domainAndSC from "./admin/domainsAndSC";
// applicant reducers
import applicantProfiles from "./applicant/applicantProfiles";
import applicantSkills from "./applicant/applicantSkills";
import applicantPastJobs from "./applicant/applicantPastJobs";
import quickfind from "./quickfind/quickfind";
import applicantsComparison from "./quickfind/applicantsComparison";
import applicantComparison from "./quickfind/applicantComparison";
import jobComparison from "./quickfind/jobComparison";
import jobsComparison from "./quickfind/jobsComparison";
import applicantJobs from "./applicant/applicantJobs";
import security from "./authorization/security";
// authorization reducers
import activation from "./authorization/activation";
import login from "./authorization/login";
import resetPassword from "./authorization/resetEmailAndPassword";
import restoreAccount from "./authorization/restoreAccount";
// brainChain reducers
import brainChainData from "./brainChain/brainChainData";
// common reducers
import activeModal from "./common/activeModal";
import errors from "./common/errors";
import localization from "./common/localization";
import location from "./common/location";
import requestStatus from "./common/requestStatus";
// job reducers
import jobProfiles from "./job/jobProfiles";
import jobSkills from "./job/jobSkills";
import openJob from "./job/openJob";
import openJobs from "./job/openJobs";
import selectedCandidates from "./job/selectedCandidates";
import organizationJobs from "./job/organizationJobs";
// organization reducers
import organizations from "./organization/organizations";
import departments from "./organization/departments";
import departmentsList from "./organization/departmentsList";
import staffList from "./organization/staffList";
import organizationPricing from "./organization/organizationPricing";
import teams from "./organization/teams";
// profiles reducers
import searchFutureProfiles from "./profiles/searchFutureProfiles";
import searchProfiles from "./profiles/searchProfiles";
import suggestedProfiles from "./profiles/suggestedProfiles";
// skillsAndClusters reducers
import currentClusterSkills from "./skillsAndClusters/currentClusterSkills";
import searchSkillsAndClusters from "./skillsAndClusters/searchSkillsAndClusters";
import suggestedClusters from "./skillsAndClusters/suggestedClusters";
import suggestedSkills from "./skillsAndClusters/suggestedSkills";
// user reducers
import userData from "./user/userData";
import userRole from "./user/userRole";
// job compass
import jobCompassSettings from "./jobCompass/jobCompassSettings";
import blackList from "./jobCompass/blackList";
import jobCompassProfiles from "./jobCompass/jobCompassProfiles";
// static pages reducer
import staticViews from "./staticViews/staticViews";

const adminReducers = {
    closestClusters,
    closestSkills,
    clusters,
    relatedProfiles,
    relatedSkills,
    systemCurrentNode,
    systemModel,
    systemNodesToAdd,
    domainAndSC,
};

const applicantReducers = {
    applicantProfiles,
    applicantSkills,
    applicantPastJobs,
    applicantJobs,
};

const quickfindReducers = {
    quickfind,
    applicantsComparison,
    applicantComparison,
    jobComparison,
    jobsComparison,
};

const authorizationReducers = {
    activation,
    login,
    resetPassword,
    restoreAccount,
    security,
};

const brainChainReducers = {
    brainChainData,
};

const commonReducers = {
    activeModal,
    errors,
    localization,
    location,
    requestStatus,
};

const jobReducers = {
    jobProfiles,
    jobSkills,
    openJob,
    openJobs,
    selectedCandidates,
    jobCompassSettings,
    blackList,
    jobCompassProfiles,
    organizationJobs,
};

const organizationReducers = {
    organizations,
    departments,
    departmentsList,
    staffList,
    organizationPricing,
    teams,
};

const profilesReducers = {
    searchFutureProfiles,
    searchProfiles,
    suggestedProfiles,
};

const skillsAndClustersReducers = {
    currentClusterSkills,
    searchSkillsAndClusters,
    suggestedClusters,
    suggestedSkills,
};

const userReducers = {
    userData,
    userRole,
};

const staticViewsReducers = {
    staticViews,
};

const appReducer = combineReducers({
    ...adminReducers,
    ...applicantReducers,
    ...authorizationReducers,
    ...brainChainReducers,
    ...commonReducers,
    ...jobReducers,
    ...organizationReducers,
    ...profilesReducers,
    ...skillsAndClustersReducers,
    ...userReducers,
    ...quickfindReducers,
    ...staticViewsReducers,
});

export const rootReducer = (state, action) => {
    if (action.type === "LOGOUT") {
        state = {
            location: state.location,
            localization: state.localization,
        };
    }

    return appReducer(state, action);
};

export const rootEpic = combineEpics(handleApiCallEpic);
