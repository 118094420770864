import { connect } from 'react-redux';
import RemoveDepartmentModal from './RemoveDepartmentModal';
import { getTranslation } from '../../../../../reducers/common/localization';
import { getActiveModalType, MODAL_REMOVE_DEPARTMENT, hideModal } from '../../../../../reducers/common/activeModal';
import { removeDepartment } from '../../../../../reducers/organization/departments';

const mapStateToProps = (state) => {
	return {
		translation: getTranslation(state),
		isOpen: getActiveModalType(state) === MODAL_REMOVE_DEPARTMENT,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onClose: () => dispatch(hideModal()),
		onRemoveDepartment: (data) => dispatch(removeDepartment(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveDepartmentModal);
