import _ from "lodash";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Input, Container, Button } from "reactstrap";
import Icon from "../../../common/icon/Icon";
import { ACCOUNT_SETUP_STEPS } from "../../../employee/profile/EmployeeProfile";
import "./TeamsList.scss";

class TeamsList extends Component {
    componentDidMount() {
        this.props.onMount();
    }

    state = {
        selectedDepartment: null,
        selectedDepartmentToDefineNewTeam: null,
        newTeamLabel: "",
        selectedTeam: null,
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedDepartment !== this.state.selectedDepartment) {
            this.props.onFetchTeamsRelatedToDept(this.state.selectedDepartment);
        }

        if (
            prevProps !== this.props &&
            !_.isEmpty(this.props.userDepts) &&
            _.isEmpty(prevState.selectedDepartment)
        ) {
            this.setState({
                selectedDepartment: this.props.userDepts[0].id,
                selectedDepartmentToDefineNewTeam: this.props.userDepts[0].id,
            });
        }
    }

    render() {
        return (
            <div className="d-flex flex-row justify-content-center">
                <div className="d-flex flex-column p-3 mr-5">
                    <b className="mb-3">
                        {_.get(this.props.translation, "team_profile.all_teams_label")}
                    </b>
                    <div className="border p-3">
                        {this.props.allBOTeams.map((item) => (
                            <div className="d-flex flex-row justify-content-between">
                                <span className="text-nowrap">{item.name}</span>
                                <Icon
                                    type="cancel"
                                    className="ml-3"
                                    onClick={() => this.handleUnassignBo(item.id)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="p-3 d-flex flex-column">
                    <b>{_.get(this.props.translation, "team_profile.team_actions_label")}</b>
                    <div className="mt-3 border-left p-3 d-flex flex-column">
                        <div className="d-flex flex-row align-items-center mb-3">
                            <span className="teams-list__title mr-3 text-nowrap">
                                {_.get(this.props.translation, "team_profile.existing_teams_label")}
                            </span>
                            {this.renderDepartmentsDropdown("add")}
                            {this.renderDepartmentsTeams()}
                            <Button
                                color="success"
                                className="text-nowrap"
                                disabled={_.isEmpty(this.state.selectedTeam)}
                                onClick={() => this.handleAddNewTeam()}
                            >
                                {_.get(this.props.translation, "team_profile.add_new_team_label")}
                            </Button>
                        </div>
                        <div className="d-flex flex-row align-items-center">
                            <span className="teams-list__title mr-3 text-nowrap">
                                {_.get(
                                    this.props.translation,
                                    "team_profile.define_new_team_label"
                                )}
                            </span>
                            {this.renderDepartmentsDropdown("define")}
                            <Input
                                type="text"
                                value={this.state.newTeamLabel}
                                onChange={(e) => this.setState({ newTeamLabel: e.target.value })}
                                className="mr-3"
                            />
                            <Button
                                color="success"
                                className="text-nowrap"
                                disabled={_.isEmpty(this.state.newTeamLabel)}
                                onClick={() => this.handleDefineNewTeam()}
                            >
                                {_.get(this.props.translation, "team_profile.add_to_existing_team")}
                            </Button>
                        </div>
                        <Button
                            color="success"
                            className="align-self-end text-nowrap mt-3"
                            onClick={() =>
                                this.props.history.push("/my-profile", {
                                    activeTab: ACCOUNT_SETUP_STEPS.RICH_MEDIA,
                                    activeMediaSource: "teams",
                                })
                            }
                        >
                            {_.get(this.props.translation, "actions.manage_rich_media")}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    renderDepartmentsDropdown(id) {
        return (
            <Input
                type="select"
                onChange={(e) => {
                    if (id === "add") {
                        this.setState({
                            selectedDepartment: e.target.value,
                        });
                    } else this.setState({ selectedDepartmentToDefineNewTeam: e.target.value });
                }}
                className="mr-3"
            >
                {this.props.userDepts?.map((department) => (
                    <option id={department.id} value={department.id}>
                        {department.name}
                    </option>
                ))}
            </Input>
        );
    }

    renderDepartmentsTeams() {
        return (
            <Input
                type="select"
                className="mr-3"
                onChange={(e) => this.setState({ selectedTeam: e.target.value })}
            >
                <option
                    hidden
                    selected={
                        _.isEmpty(this.state.selectedTeam) || _.isNull(this.state.selectedTeam)
                    }
                    disabled
                >
                    {_.isEmpty(this.props.userTeams)
                        ? _.get(this.props.translation, "team_profile.no_teams_label")
                        : _.get(this.props.translation, "team_profile.select_team_label")}
                </option>
                {this.props.userTeams
                    ?.filter(
                        (team) => !this.props.allBOTeams.some((boTeam) => boTeam.id === team.id)
                    )
                    .map((team) => (
                        <option
                            id={team.id}
                            value={team.id}
                            selected={this.state.selectedTeam?.id === team?.id}
                        >
                            {team.name}
                        </option>
                    ))}
            </Input>
        );
    }

    handleDefineNewTeam() {
        this.props.onDefineNewTeam(
            this.state.selectedDepartmentToDefineNewTeam,
            this.state.newTeamLabel
        );
        this.setState({
            newTeamLabel: "",
        });
    }

    handleAddNewTeam() {
        this.props.onHandleBoTeam(this.state.selectedTeam, true);
        this.setState({
            selectedTeam: {},
            newTeamLabel: "",
        });
    }

    handleUnassignBo(teamId) {
        this.props.onHandleBoTeam(teamId, false);
    }
}

export default withRouter(TeamsList);
