import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './StaffList.scss';
import _ from 'lodash';
import { Button, Table } from 'reactstrap';
import Icon from '../../../common/icon/Icon';
import StaffListPagination from '../../../common/tablePagination/StaffListPagination.container';
import AddStaffMemberModal from '../modals/addStaffMemberModal/AddStaffMemberModal.container';
import RemoveStaffMemberModal from '../modals/removeStaffMemberModal/RemoveStaffMemberModal.container';
import ResendStaffMemberActivationModal from '../modals/resendStaffMemberActivationModal/ResendStaffMemberActivationModal.container';
import ChangeAnchor from '../modals/changeAnchorModal/ChangeAnchor.container';

class StaffList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            staffMemberToRemove: null,
            staffMemberToReactivate: null
        };
    }

    componentDidMount() {
        this.props.getInitialStaffList();
    }

    render() {
        return (
            <div className="staff-list">
                <h4 className="page-header">{_.get(this.props.translation, "staff.list_label")}</h4>
                <div className="buttons-container content-space-between">
                    <Button onClick={this.props.onChangeAnchor} color="secondary">
                        {_.get(this.props.translation, "staff.change_anchor.title")}
                    </Button>
                    <Button onClick={this.props.onAddStaffMember} color="primary">
                        {_.get(this.props.translation, "actions.add_label")}
                    </Button>
                </div>
                <StaffListPagination />
                {this.renderContent()}
                <AddStaffMemberModal />
                <RemoveStaffMemberModal data={this.state.staffMemberToRemove} />
                <ResendStaffMemberActivationModal data={this.state.staffMemberToReactivate} />
                <ChangeAnchor />
            </div>
        );
    }

    renderContent() {
        if (this.props.loading) {
            return <h6 className="text-center">{_.get(this.props.translation, "actions.fetching_message")}</h6>;
        }

        if (_.isNil(this.props.data) || this.props.data.length === 0) {
            return <h6 className="text-center">{_.get(this.props.translation, "staff.no_data_label")}</h6>;
        }

        return (
            <Table>
                <thead>
                    <tr>
                        <th>{_.get(this.props.translation, "person_data.first_name_label")}</th>
                        <th>{_.get(this.props.translation, "person_data.last_name_label")}</th>
                        <th>{_.get(this.props.translation, "person_data.email_label")}</th>
                        <th>{_.get(this.props.translation, "person_data.roles")}</th>
                        <th />
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {this.renderListData()}
                </tbody>
            </Table>
        )
    }

    renderListData() {
        return _.map(this.props.data, (elem, index) => (
            <tr
                key={index}
                className={!elem.active ? "table-row-disabled" : ""}
            >
                <td>{elem.firstName}</td>
                <td>{elem.lastName}</td>
                <td>{elem.email}</td>
                <td>{!_.isEmpty(elem.roles) ? elem.roles.join(", ") : ""}</td>
                <td className="table-cell-align-right">
                    {
                        !elem.active ?
                            <Button className="btn-sm" color="link"
                                onClick={this.onResendStaffMemberActivationClick(elem)}
                            >
                                {_.get(this.props.translation, "staff.resend_activation_label")}
                            </Button>
                            : null
                    }
                </td>
                <td
                  onClick={this.onRemoveStaffMemberClick(elem)}
                  className="table-cell-with-icon"
                >

                    <Icon
                        type="delete"
                        title={_.get(this.props.translation, "actions.remove_button")}
                    />
                </td>
            </tr>
        ))
    }

    onRemoveStaffMemberClick = (data) => () => {
        this.setState({ staffMemberToRemove: data });
        this.props.onRemoveStaffMember();
    };

    onResendStaffMemberActivationClick = (data) => () => {
        this.setState({ staffMemberToReactivate: data });
        this.props.onResendStaffMemberActivation();
    }
}

StaffList.propTypes = {
    translation: PropTypes.object.isRequired,
    getInitialStaffList: PropTypes.func.isRequired,
    onResendStaffMemberActivation: PropTypes.func.isRequired,
    onRemoveStaffMember: PropTypes.func.isRequired,
    onAddStaffMember: PropTypes.func.isRequired,
    onChangeAnchor: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default StaffList;
