import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CandidateModalHeader.scss';
import { ModalHeader } from 'reactstrap';
import _ from "lodash";
import Icon from '../../../../../common/icon/Icon';
import UserPhoto from '../../../../../common/userPhoto/UserPhoto.container';
import { USER_PHOTO_MODE } from '../../../../../common/userPhoto/UserPhoto';
import { Utils } from '../../../../../../utils/utils';
import { CANDIDATE_GROUP } from '../../../../../../constants/candidatesComparisonConstants';

class CandidateModalHeader extends Component {
    render() {
        if (!this.props.userId || !this.props.userData) {
            return null;
        }
        return (
            <ModalHeader className="candidate-modal-header" toggle={this.props.toggle} tag={"div"}>
                {this.renderCandidateButtons()}
                <UserPhoto
                    mode={USER_PHOTO_MODE.COMPARISON}
                    userId={this.props.userId}
                    imageSource=""
                    size="sm"
                />
                <div>
                    <h6>
                        {`${this.renderUserName()}, ${this.props.userData.user.currentJob}`}
                    </h6>
                </div>
            </ModalHeader>
        );
    }


    renderCandidateButtons() {
        return (
            <div className="candidate-buttons">
                {
                    this.props.userData.applicantMetaData.groupId === CANDIDATE_GROUP.TOP_X ?
                        <Icon
                            className="top-x-icon"
                            type="best_mark"
                            size="lg"
                            title={_.replace(_.get(this.props.translation, 'candidates_comparison_page.top_x_candidate_label'), "{x}", this.props.plannedTopAmount)}
                        />
                        :
                        <Icon
                            className="top-x-icon"
                            type="best_mark_outline"
                            size="lg"
                            onClick={this.handleAddToTopXGroup}
                            title={_.replace(_.get(this.props.translation, 'candidates_comparison_page.add_to_top_x_label'), "{x}", this.props.plannedTopAmount)}
                        />
                }
                {
                    this.props.userData.applicantMetaData.favorite ?
                        <Icon
                            className="favorite-icon"
                            type="star"
                            size="lg"
                            title={_.get(this.props.translation, 'candidates_comparison_page.remove_from_favorite_label')}
                            onClick={this.handleRemoveFromFavorite}
                        />
                        :
                        <Icon
                            className="favorite-icon"
                            type="star_outline"
                            size="lg"
                            title={_.get(this.props.translation, 'candidates_comparison_page.add_to_favorite_label')}
                            onClick={this.handleAddToFavorite}
                        />
                }
            </div>
        )
    }

    renderUserName() {
        if (this.props.anonymityMode) {
            return Utils.getUserAnonymousNameByUUID(this.props.userId);
        } else {
            return this.props.userData.user.alias.firstName + " " + this.props.userData.user.alias.lastName;
        }
    }


    handleRemoveFromFavorite = () => {
        this.props.onRemoveFromFavorite(this.props.userId);
    }

    handleAddToFavorite = () => {
        this.props.onAddToFavorite(this.props.userId);
    }

    handleAddToTopXGroup = () => {
        this.props.onAddToTopXGroup(this.props.userId);
    }
}

CandidateModalHeader.propTypes = {
    translation: PropTypes.object.isRequired,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    userData: PropTypes.object,
    anonymityMode: PropTypes.bool,
    plannedTopAmount: PropTypes.number.isRequired,
    toggle: PropTypes.func,
    onRemoveFromFavorite: PropTypes.func.isRequired,
    onAddToFavorite: PropTypes.func.isRequired,
    onAddToTopXGroup: PropTypes.func.isRequired
};

export default CandidateModalHeader;
