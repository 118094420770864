
import React, { Component } from 'react';

class PersonSoftSkillsOutline extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 512 512">
                <path d="M490.7,196.8c0.5-24.5-2.5-48.9-8.9-72.5c-8.6-29.3-26.5-55.1-51-73.4C382.5,13.3,337.5-2.4,284.9,0.3
                    c-27.8,1.9-55.4,5.9-82.5,11.8c-38.5,7.7-66.5,21.3-85.5,41.4c-15.1,16.1-26.7,35-34.2,55.8c-3.2,9.1-5.8,18.5-7.7,27.9l-0.7,3
                    c-1.8,6.6-4.2,13.1-7.2,19.3c-1.8,3.9-3.5,7.9-5.1,11.9c-2,4.5-2.8,9.4-2.3,14.3c1.7,7.6,6.2,14.4,12.5,19c4.2,3.8,6.9,6.5,7,9
                    c-0.3,1.3-0.8,2.5-1.5,3.6c-10.2,21.5-22.7,41.9-37.4,60.8c-6.1,7.5-12.5,16.2-11.2,25.1c1.9,6.7,6.3,12.4,12.4,15.9
                    c2.8,1.9,5.8,3.7,8.9,5.1c3.7,1.9,9.8,5,10.2,7.3c0.6,2.9,0.2,5.8-0.9,8.5c-1.3,2.7-2.6,5-3.7,7c-3.1,5.5-8.4,14.8-0.5,21.5
                    c1.8,1.6,5,4.1,7.9,6.3c-2.1,3.7-3.1,8-2.7,12.3c0.5,3.3,2.3,6.3,5,8.3c1.9,1.4,4,2.6,6,3.7c5,2.9,7,4.2,7.7,6.8
                    c0.5,3.5,0.1,7-0.9,10.4c-0.5,2.2-1,4.5-1.3,6.7c-0.9,7.8,0.1,15.6,2.9,22.9c4.4,16.2,20.8,26.1,37.2,22.6
                    c24.4-5.9,48.4-13.1,72-21.5c19.2,11.3,31.3,45.6,34.5,58.5c0.9,3.8,4.4,6.5,8.3,6.5l214.2-0.6c2.7,0,5.2-1.3,6.8-3.5
                    c1.6-2.2,2.1-5,1.3-7.6c-2.1-5.8-5-11.3-8.7-16.3c-2-2.9-3.8-5.9-5.5-9.1c-3.8-7.9-7.1-16-9.6-24.4c-4.9-16.5-8.2-33.4-10.1-50.5
                    c-3-25.5,0.8-51.4,11.2-74.9c6.3-13,14.1-25.3,23.2-36.6c5.1-6.9,10.5-14.1,15.3-21.7C483.2,246,490.5,221.7,490.7,196.8z
                    M440.8,278.5c-9.9,12.3-18.3,25.7-25.1,39.9c-11.5,26.3-15.8,55.2-12.5,83.8c1.9,18.1,5.5,36,10.6,53.5c2.8,9.2,6.4,18.2,10.6,26.8
                    c1.9,3.8,4.1,7.5,6.6,11l0.6,0.9l-193.5,0.5c-5.7-20.2-16-38.9-30.1-54.5c2.2-0.8,4.3-1.5,6.2-2.2c6.5-2.3,11.4-4,13.8-4.7
                    c36.6-11.5,54.9-28.1,54.4-49.4c-0.3-4.6-4.1-8.3-8.7-8.3c-2.3,0.1-4.4,1-6,2.6s-2.4,3.8-2.4,6.1c0.2,8.8-7,21.6-42.4,32.7
                    c-2.6,0.8-7.6,2.6-14.3,4.9c-6.1,2.1-13.6,4.7-21.7,7.5c-24,8.7-48.5,16.1-73.4,22.1c-8,1.5-15.8-3.5-17.6-11.5
                    c-1.8-4.8-2.5-9.9-2.1-15.1c0.2-1.8,0.6-3.5,1-5.3c1.8-5.9,2-12.2,0.8-18.2c-2.4-7.8-8.2-14.2-15.7-17.5l-1.8-1
                    c0.9-1.9,2-3.6,3.3-5.3c1.4-1.8,2-4.1,1.7-6.4c-0.3-2.3-1.5-4.3-3.4-5.7c-2-1.5-6.9-5.2-10.4-8c0.4-0.8,0.9-1.7,1.4-2.5
                    c1.3-2.3,2.8-5,4.3-8c2.7-6,3.5-12.6,2.3-19.1c-2-10.5-11.9-15.6-19.3-19.3c-2.5-1.2-4.9-2.6-7.1-4.1c-1.8-1.2-3.4-2.6-5-4.1
                    c0-1.3,1.4-4.4,7.9-12.4c15.2-19.7,28.3-40.9,39-63.4c2.1-3.5,3.2-7.5,3.3-11.6c-0.9-8.6-5.4-16.4-12.5-21.3
                    c-3.4-3.2-6.7-6.1-7.1-8.8c0-2.1,0.5-4.1,1.4-6c1.4-3.7,3-7.3,4.7-11c3.5-7.2,6.3-14.7,8.3-22.4l0.7-3c1.8-8.8,4.2-17.4,7.1-25.8
                    c6.7-18.6,17.1-35.6,30.6-50c16.5-17.4,41.5-29.4,76.5-36.4c26.4-5.8,53.1-9.6,80.1-11.5c48.3-2.6,89.7,12.1,134.4,47.1
                    c21.7,16,37.6,38.7,45.1,64.6c6,22,8.8,44.7,8.3,67.5c-0.1,21.7-6.5,43-18.3,61.3C450.9,264.9,445.7,271.8,440.8,278.5L440.8,278.5z
                    "/>
                <g>
                    <path d="M192,153.6v8.5c0,4.7,3.8,8.5,8.5,8.5h8.5c4.7,0,8.5,3.8,8.5,8.5s-3.8,8.5-8.5,8.5h-8.5c-14.1,0-25.6-11.5-25.6-25.6v-8.5
		                c0-14.1,11.5-25.6,25.6-25.6h8.5c4.7,0,8.5,3.8,8.5,8.5s-3.8,8.5-8.5,8.5h-8.5C195.8,145.1,192,148.9,192,153.6z"/>
                    <path d="M268.8,128v17.1c0,14.1-11.5,25.6-25.6,25.6h-8.5c-4.7,0-8.5-3.8-8.5-8.5s3.8-8.5,8.5-8.5h8.5c4.7,0,8.5-3.8,8.5-8.5V128
		                c0-4.7,3.8-8.5,8.5-8.5S268.8,123.3,268.8,128z"/>
                    <path d="M285.9,128c-4.7,0-8.5-3.8-8.5-8.5v-8.5c0-4.7-3.8-8.5-8.5-8.5h-17.1c-4.7,0-8.5,3.8-8.5,8.5v8.5c0,4.7-3.8,8.5-8.5,8.5
		                s-8.5-3.8-8.5-8.5v-8.5c0-14.1,11.5-25.6,25.6-25.6h17.1c14.1,0,25.6,11.5,25.6,25.6v8.5C294.4,124.2,290.6,128,285.9,128z"/>
                    <path d="M328.5,187.7c0,4.7-3.8,8.5-8.5,8.5h-8.5c-14.1,0-25.6-11.5-25.6-25.6v-8.5c0-4.7,3.8-8.5,8.5-8.5s8.5,3.8,8.5,8.5v8.5
		                c0,4.7,3.8,8.5,8.5,8.5h8.5C324.7,179.2,328.5,183,328.5,187.7z"/>
                    <path d="M354.1,162.1h-8.5c-14.1,0-25.6-11.5-25.6-25.6s11.5-25.6,25.6-25.6h8.5c4.7,0,8.5,3.8,8.5,8.5s-3.8,8.5-8.5,8.5h-8.5
		                c-4.7,0-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5h8.5c4.7,0,8.5,3.8,8.5,8.5C362.7,158.3,358.8,162.1,354.1,162.1z"/>
                    <path d="M396.8,162.1v17.1c0,14.1-11.5,25.6-25.6,25.6h-8.5c-4.7,0-8.5-3.8-8.5-8.5s3.8-8.5,8.5-8.5h8.5c4.7,0,8.5-3.8,8.5-8.5
		                v-17.1c0-4.7,3.8-8.5,8.5-8.5S396.8,157.4,396.8,162.1z"/>
                    <path d="M456.2,179.4c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.4c-0.8-15.2-3.2-30.4-7.2-45.1c-6.6-22.4-20.4-42-39.2-55.8
		                c-38.6-30.3-73.6-43.8-113.5-43.8c-3.1,0-6.3,0.1-9.6,0.2c-2.9,0.2-5.8,0.5-8.7,0.7c-0.2,0-0.4-0.1-0.6-0.1c-0.4,0-0.9,0.1-1.3,0.3
                        c-22.5,2.2-44.8,5.6-66.9,10.3c-9.7,1.9-19.2,4.5-28.5,7.9c-0.2,0.1-0.4,0.1-0.6,0.2c-14.4,4.7-27.5,12.7-38.3,23.3
                        c-10.5,11.2-18.8,24.3-24.6,38.5c-0.3,0.5-0.5,1-0.6,1.5c-0.6,1.4-1.2,2.7-1.8,4.1c-2.6,7.1-6.7,24.8-7.3,27
                        c-1.9,9.5-6.7,42.3,12.7,62.8c12.3,13,31.1,17.8,55.8,14.3l11.5-1.6c54.7-7.9,82.2-11.8,105.9,11.3c4.5,4.4,9.2,9,14.1,13.4
                        c-5.4,16.9-12.3,52.9,16.2,74.2c30.7,23,30.7,29.3,30.7,44.4c0,3,1.5,5.7,4,7.3c2.5,1.6,5.7,1.7,8.3,0.4c2.2-1.2,21.8-12,21.8-50.3
                        c0-20.9,15.6-39.1,31-54l0.3-0.2c8.4-5.7,15.6-13,21.3-21.3c6.2-9.8,10.6-20.5,13.1-31.8c0.5-1.1,0.8-2.3,0.8-3.5
                        c1.1-5.6,1.7-11.3,1.8-17C456.6,190.6,456.5,185,456.2,179.4z M371.2,324.3c0.1,7.1-0.9,14.2-3,20.9c-4-10-13.2-20.3-34.5-36.3
                        c-17-12.7-15.8-33.8-12.1-48.6c16,12.8,35.8,19.9,56.3,20.1c3.3,0,6.6-0.2,9.9-0.6C377.6,292.4,371.7,308,371.2,324.3z
                        M439.4,196.1c0,3-0.4,5.8-0.7,8.7h-16.3c-4.7,0-8.5,3.8-8.5,8.5c0,4.7,3.8,8.5,8.5,8.5h12.5c-2.1,6.2-4.9,12.1-8.4,17.7
                        c-6,9-14.4,16-24.3,20.2c-15.7,5-32.5,4.8-48.1-0.7v-11.6c0-4.7,3.8-8.5,8.5-8.5h25.6c4.7,0,8.5-3.8,8.5-8.5s-3.8-8.5-8.5-8.5
                        h-25.6c-14.1,0-25.6,11.5-25.6,25.6v2.7c-11.5-8.1-22.2-17.3-32-27.4c-9.9-10-22.5-17-36.3-20v-6.5c0-4.7-3.8-8.5-8.5-8.5
                        s-8.5,3.8-8.5,8.5v4.4c-22.4,0-44.8,2.1-66.9,6.1l-11.5,1.6c-5.1,0.8-10.4,1-15.6,0.8v-4.4c0-4.7-3.8-8.5-8.5-8.5s-8.5,3.8-8.5,8.5
                        v0.4c-3.1-1.5-5.9-3.5-8.3-6c-4.9-5.7-8-12.6-9-20h8.8c14.1,0,25.6-11.5,25.6-25.6c0-4.7-3.8-8.5-8.5-8.5s-8.5,3.8-8.5,8.5
                        c0,4.7-3.8,8.5-8.5,8.5h-9.6c0.2-3.5,0.7-7,1.4-10.4c1.1-4.8,4.3-17.9,6.3-23.7h27.6c4.7,0,8.5-3.8,8.5-8.5s-3.8-8.5-8.5-8.5h-19.8
                        c4.3-8.1,9.7-15.6,15.9-22.3c6-6,13.1-11,20.9-14.5v11.2c0,14.1,11.5,25.6,25.6,25.6c4.7,0,8.5-3.8,8.5-8.5s-3.8-8.5-8.5-8.5
                        S192,90,192,85.3V67.5c6.7-2.1,13.6-3.8,20.5-5.2c7.3-1.5,14.7-2.9,22.2-4.2v1.7c0,4.7,3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5v-4.5
                        c5.7-0.9,11.4-1.6,17.1-2.3v6.8c0,4.7,3.8,8.5,8.5,8.5c4.7,0,8.5-3.8,8.5-8.5v-8.2c0.5,0,1.1-0.1,1.6-0.1c5.3-0.2,10.4-0.2,15.5,0
                        v33.9c0,4.7,3.8,8.5,8.5,8.5h17.1c4.7,0,8.5-3.8,8.5-8.5s-3.8-8.5-8.5-8.5H320V53.3c14.9,2.5,29.4,7.4,42.8,14.4
                        c0,0.2-0.1,0.4-0.1,0.6v25.6c0,4.7,3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5V77.6c5.6,3.6,11.3,7.6,17.1,12v21.3c0,4.7-3.8,8.5-8.5,8.5
                        s-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5c14.1,0,25.6-11.5,25.6-25.6v-5.8c8.7,9.5,15,20.9,18.6,33.3c2.9,10.6,4.8,21.4,5.8,32.3h-15.9
                        c-4.7,0-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5h16.9C439.3,190.5,439.5,193.3,439.4,196.1z"/>
                </g>
            </svg>
        );
    }
}

export default PersonSoftSkillsOutline;
