import { connect } from "react-redux";
import NodesFilters from "./NodesFilters";
import { getTranslation } from "../../../../reducers/common/localization";
import {
    getRelatedSkillsNameFilter,
    getRelatedSkillsSourceFilter,
    getRelatedSkillsStatusFilter,
    getRelatedSkillsDomainFilter,
    onFetchGetRelatedSkills,
    updateRelatedSkillsNameFilter,
    onUpdateRelatedSkillsSourceFilter,
    onUpdateRelatedSkillsStatusFilter,
    onUpdateRelatedSkillsDomainFilter,
    onUpdateRelatedSkillsSortingMode,
    getRelatedSkillsSortingMode,
} from "../../../../reducers/admin/relatedSkills";
import {
    getSystemNodesExternalSources,
    getSystemNodesExternalSourcesFetchingStatus,
    refreshSystemNodesExternalSources,
} from "../../../../reducers/admin/systemModel";
import { fetchGetDomainList, getDomains, getSC } from "../../../../reducers/admin/domainsAndSC";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        disableTypeFilter: true,
        nodeNameFilter: getRelatedSkillsNameFilter(state),
        nodeSourceFilter: getRelatedSkillsSourceFilter(state),
        nodeStatusFilter: getRelatedSkillsStatusFilter(state),
        nodeDomainFilter: getRelatedSkillsDomainFilter(state),
        sortingMode: getRelatedSkillsSortingMode(state),
        externalSources: getSystemNodesExternalSources(state),
        loadingExternalSources: getSystemNodesExternalSourcesFetchingStatus(state),
        domains: getDomains(state),
        skill_clusters: getSC(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onMount: () => {
            dispatch(refreshSystemNodesExternalSources());
            dispatch(fetchGetDomainList());
        },
        onGetNodesList: () => dispatch(onFetchGetRelatedSkills()),
        onUpdateNodesNameFilter: (filter) => dispatch(updateRelatedSkillsNameFilter(filter)),
        onUpdateNodesSourceFilter: (filter) => dispatch(onUpdateRelatedSkillsSourceFilter(filter)),
        onUpdateNodesStatusFilter: (filter) => dispatch(onUpdateRelatedSkillsStatusFilter(filter)),
        onUpdateNodesDomainFilter: (filter) => dispatch(onUpdateRelatedSkillsDomainFilter(filter)),
        onUpdateNodesSortingMode: (sortingMode) =>
            dispatch(onUpdateRelatedSkillsSortingMode(sortingMode)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NodesFilters);
