import React, { Component } from 'react';

class EmojiNeutral extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 473.931 473.931">
                <circle fill="#60E0D0" cx="236.966" cy="236.966" r="236.966" />
                <g fill="#333333">
                    <circle cx="164.937" cy="155.231" r="37.216" />
                    <circle cx="305.664" cy="155.231" r="37.216" />
                    <path d="M105.278,326.581c86.386,0,172.776,0,259.162,0c12.067,0,12.067-18.709,0-18.709
                        c-86.386,0-172.776,0-259.162,0C93.211,307.872,93.211,326.581,105.278,326.581L105.278,326.581z"/>
                </g>
            </svg>
        );
    }
}

export default EmojiNeutral;
