import { connect } from 'react-redux';
import TablePagination from "./TablePagination";
import { 
    getSystemNodesListCurrentPage, 
    getSystemNodesListTotalPages, 
    onFetchGetSystemNodesListByPage,
    getSystemNodesListFetchingStatus
} from '../../../reducers/admin/systemModel';

const mapStateToProps = state => {
    return {
        current: getSystemNodesListCurrentPage(state),
        total: getSystemNodesListTotalPages(state),
        loading: getSystemNodesListFetchingStatus(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getDataForPage: (page) => {
            dispatch(onFetchGetSystemNodesListByPage(page));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePagination);
