import { connect } from 'react-redux';
import ResendActivation from './ResendActivation';
import { getTranslation } from '../../../../reducers/common/localization';
import { fetchResendAccountActivation } from '../../../../reducers/authorization/activation';
import { getRequestSuccessStatus } from '../../../../reducers/common/requestStatus';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        success: getRequestSuccessStatus(state)
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onResendActivation: (data) => dispatch(fetchResendAccountActivation(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResendActivation);
