import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SoftSkillsComparison.scss';
import _ from 'lodash';
import { Alert, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Utils } from '../../../../../utils/utils';
import Icon from '../../../../common/icon/Icon';
import { CANDIDATE_GROUP, SOFT_SKILLS_MODE } from '../../../../../constants/candidatesComparisonConstants';
import UserPhoto from '../../../../common/userPhoto/UserPhoto.container';
import { USER_PHOTO_MODE } from '../../../../common/userPhoto/UserPhoto';
import BrainChainCheckMark from '../../../../common/brainChainCheckMark/BrainChainCheckMark.container';
import FFMChart from '../../../../common/softSkills/FFMChart/FFMChart.container';
import { FFM_CHART_MODE } from '../../../../common/softSkills/FFMChart/FFMChart';

// TODO: (BE) add soft skills data 
class SoftSkillsComparison extends Component {
    render() {
        if (this.props.loading) {
            return (
                <h6 className="text-center">{_.get(this.props.translation, "actions.fetching_message")}</h6>
            )
        }

        if (!this.props.loading && !this.props.data) {
            return (
                <div className="select-candidate-hint">
                    <Alert>
                        {_.get(this.props.translation, 'candidates_comparison_page.select_candidate_hint')}
                    </Alert>
                </div>
            )
        }

        return (
            <div className="soft-skills-comparison">
                {this.renderCandidateInfo()}
                {this.renderContent()}
            </div>
        );
    }

    renderCandidateInfo() {
        return (
            <div className="candidate-info">
                <div className="buttons-group">
                    {
                        this.props.data.applicantMetaData.groupId === CANDIDATE_GROUP.TOP_X ?
                            <Icon
                                className="top-x-icon"
                                type="best_mark"
                                size="lg"
                                title={_.replace(_.get(this.props.translation, 'candidates_comparison_page.top_x_candidate_label'), "{x}", this.props.plannedTopAmount)}
                            />
                            :
                            <Icon
                                className="top-x-icon"
                                type="best_mark_outline"
                                size="lg"
                                onClick={this.handleAddToTopXGroup}
                                title={_.replace(_.get(this.props.translation, 'candidates_comparison_page.add_to_top_x_label'), "{x}", this.props.plannedTopAmount)}
                            />
                    }
                    {
                        this.props.data.applicantMetaData.favorite ?
                            <Icon
                                className="favorite-icon"
                                type="star"
                                size="lg"
                                title={_.get(this.props.translation, 'candidates_comparison_page.remove_from_favorite_label')}
                                onClick={this.handleRemoveFromFavorite}
                            />
                            :
                            <Icon
                                className="favorite-icon"
                                type="star_outline"
                                size="lg"
                                title={_.get(this.props.translation, 'candidates_comparison_page.add_to_favorite_label')}
                                onClick={this.handleAddToFavorite}
                            />
                    }
                </div>
                <UserPhoto
                    mode={USER_PHOTO_MODE.COMPARISON}
                    userId={this.props.userId}
                    // TODO: remove hardcoded values
                    imageSource=""
                    isDraggable
                    size="sm"
                />
                <div className="candidate-name">
                    <h5>{`${this.getUserName()}, ${this.props.data.user.currentJob}`}</h5>
                </div>
                <div className="buttons-group">
                    <BrainChainCheckMark
                        userId={this.props.userId}
                        qualityCheck={this.props.data.user.qualityCheck}
                    />
                    <Icon
                        className="candidate-notes-icon"
                        type={this.props.data.applicantMetaData.notes ? "microphone" : "microphone_outline"}
                        size="lg"
                        onClick={this.handleCandidateNotesClick}
                        title={_.get(this.props.translation, 'candidates_comparison_page.notes_about_candidate_label')}
                    />
                </div>
            </div>
        )
    }

    getUserName = () => {
        if (this.props.anonymityMode) {
            return Utils.getUserAnonymousNameByUUID(this.props.userId);
        } else {
            return this.props.data.user.alias.firstName + " " + this.props.data.user.alias.lastName;
        }
    }

    renderContent() {
        switch (this.props.softSkillsMode) {
            case SOFT_SKILLS_MODE.FFM:
                return (
                    <div className="soft-skills-ffm">
                        {this.renderFFMHeader()}
                        {this.renderFFMData()}
                        {this.renderDesiredQualities()}
                    </div>
                );

            case SOFT_SKILLS_MODE.TEST_1:
                return (
                    <div className="soft-skills-test">
                        {/* TODO: remove hardcoded values */}
                        <h5 className="text-center">Test 1</h5>
                        <h6 className="text-center">Main intent: lorem ipsum</h6>
                        <div className="soft-skills-test-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        </div>
                        {this.renderDesiredQualities()}
                    </div>
                );

            case SOFT_SKILLS_MODE.TEST_2:
                return (
                    <div className="soft-skills-test">
                        {/* TODO: remove hardcoded values */}
                        <h5 className="text-center">Test 2</h5>
                        <h6 className="text-center">Main intent: nostrud fugiat</h6>
                        <div className="soft-skills-test-content">
                            Nostrud fugiat ipsum aliqua laboris eiusmod esse sunt irure id esse sit esse qui. Sit nostrud id aute id tempor duis exercitation consectetur cillum. Labore aute nisi dolor nulla velit consectetur elit dolore anim commodo amet.<br />
                            Occaecat dolor ut cupidatat Lorem minim occaecat est ipsum tempor cillum pariatur consectetur. Ea quis aliqua esse fugiat. Ea sint ad tempor veniam occaecat est ut qui in dolore duis nisi et. Commodo exercitation ex cillum anim officia pariatur deserunt duis incididunt anim ipsum ut aliquip quis.<br />
                            Commodo ea et commodo officia eiusmod amet sunt occaecat dolore ea quis. Labore incididunt laboris id qui exercitation. Qui duis eiusmod dolore nulla nisi nulla tempor esse. Ea mollit amet in magna cupidatat incididunt duis minim voluptate irure consequat commodo. Proident enim dolor aliquip esse cillum fugiat labore excepteur aliqua cupidatat. Anim esse adipisicing enim ipsum aute eu eiusmod elit amet enim amet non qui ex. Quis ut tempor mollit aute amet mollit incididunt labore dolore non id sit.<br />
                            Culpa veniam culpa non laborum. Dolor anim irure exercitation aute veniam occaecat mollit eiusmod Lorem. Est duis irure quis aute aliquip.
                        </div>
                        {this.renderDesiredQualities()}
                    </div>
                );

            default:
                break;
        }
    }

    renderFFMHeader() {
        return (
            <div className="ffm-info">
                <h5>
                    {_.get(this.props.translation, 'psychology.five_factor_model_analysis_label')}
                    <Icon
                        className="ffm-info-icon"
                        id="ffm-info-icon"
                        type="info"
                        color="info"
                        title={_.get(this.props.translation, 'actions.about_label')}
                    />
                </h5>
                <UncontrolledPopover placement="top" target="ffm-info-icon" trigger="legacy" boundariesElement="window">
                    <PopoverHeader>
                        {_.get(this.props.translation, 'psychology.relevance_of_five_factor_model_label')}
                    </PopoverHeader>
                    <PopoverBody>
                        {/* TODO: remove hardcoded values */}
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
        )
    }

    renderFFMData() {
        if (!this.props.jobFFMData) {
            return null
        }
        return (
            <div className="ffm-data">
                <div className="ffm-data-item">
                    <h6 className="text-center">
                        {_.get(this.props.translation, 'job_and_candidates.person_label')}<br />
                        {_.get(this.props.translation, 'psychology.versus_label')}<br />
                        {_.get(this.props.translation, 'psychology.normalized_company_label')}
                    </h6>
                    <div className="ffm-chart-container">
                        <FFMChart
                            mode={FFM_CHART_MODE.COMPARE}
                            actualData={this.props.candidateFFMData}
                            requirementsData={this.props.jobFFMData.company}
                        />
                    </div>
                </div>
                <div className="ffm-data-item">
                    <h6 className="text-center">
                        {_.get(this.props.translation, 'job_and_candidates.person_label')}<br />
                        {_.get(this.props.translation, 'psychology.versus_label')}<br />
                        {_.get(this.props.translation, 'psychology.normalized_department_label')}
                    </h6>
                    <div className="ffm-chart-container">
                        <FFMChart
                            mode={FFM_CHART_MODE.COMPARE}
                            actualData={this.props.candidateFFMData}
                            requirementsData={this.props.jobFFMData.department}
                        />
                    </div>
                </div>
                <div className="ffm-data-item">
                    <h6 className="text-center">
                        {_.get(this.props.translation, 'job_and_candidates.person_label')}<br />
                        {_.get(this.props.translation, 'psychology.versus_label')}<br />
                        {_.get(this.props.translation, 'psychology.normalized_biz_owner_label')}
                    </h6>
                    <div className="ffm-chart-container">
                        <FFMChart
                            mode={FFM_CHART_MODE.COMPARE}
                            actualData={this.props.candidateFFMData}
                            requirementsData={this.props.jobFFMData.bo}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderDesiredQualities() {
        // TODO: remove hardcoded values
        let data = [
            {
                name: "Communication",
                value: true
            },
            {
                name: "Adaptability",
                value: false
            },
            {
                name: "Problem solving",
                value: true
            },
            {
                name: "Time management",
                value: false
            },
            {
                name: "Attentional to detail",
                value: true
            }
        ];
        data = _.orderBy(data, "name");
        return (
            <div className="desired-qualities">
                <h5 className="text-center">
                    {_.get(this.props.translation, 'psychology.desired_qualities_label')}
                </h5>
                <div className="desired-qualities-items">
                    {
                        _.map(data, (elem, id) => {
                            return (
                                <div className={`desired-qualities-item ${elem.value ? "matched" : "missing"}`} key={id}>
                                    {elem.name}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    handleRemoveFromFavorite = () => {
        this.props.onRemoveFromFavorite(this.props.userId);
    }

    handleAddToFavorite = () => {
        this.props.onAddToFavorite(this.props.userId);
    }

    handleCandidateNotesClick = () => {
        this.props.onCandidateNotesClick(this.props.userId);
    }

    handleAddToTopXGroup = () => {
        this.props.onAddToTopXGroup(this.props.userId);
    }
}

SoftSkillsComparison.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.object,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    loading: PropTypes.bool,
    anonymityMode: PropTypes.bool,
    plannedTopAmount: PropTypes.number,
    softSkillsMode: PropTypes.string,
    candidateFFMData: PropTypes.array,
    jobFFMData: PropTypes.object,
    onAddToFavorite: PropTypes.func.isRequired,
    onRemoveFromFavorite: PropTypes.func.isRequired,
    onAddToTopXGroup: PropTypes.func.isRequired,
    onCandidateNotesClick: PropTypes.func.isRequired,
}

export default SoftSkillsComparison;
