import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link, NavLink as RRDNavLink, withRouter } from "react-router-dom";
import {
    NavLink,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { ROUTES, USER_ROLE, USER_SUB_ROLE } from "../../../../constants/constants";

const NAV_TYPE = {
    NAV: "NAV",
    DROPDOWN: "DROPDOWN",
};

class NavItemManager extends Component {
    render() {
        return this.getNavItems();
    }

    getNavItemsData = () => {
        switch (this.props.userRole) {
            case USER_ROLE.SYS_ADMIN:
                return [
                    {
                        type: NAV_TYPE.NAV,
                        to: ROUTES.MODEL_MANAGEMENT,
                        title: _.get(this.props.translation, "model_management_page.menu_label"),
                    },
                ];

            case USER_ROLE.APPLICANT:
                return [
                    {
                        type: NAV_TYPE.NAV,
                        to: ROUTES.APPLICANT_ACCOUNT,
                        key: ROUTES.APPLICANT_ACCOUNT,
                        title: _.get(this.props.translation, "applicant_account_page.page_label"),
                    },
                    {
                        type: NAV_TYPE.NAV,
                        to: ROUTES.EDIT_APPLICANT_SKILLS,
                        key: ROUTES.EDIT_APPLICANT_SKILLS,
                        title: _.get(
                            this.props.translation,
                            "edit_applicant_skills_page.page_label_short"
                        ),
                    },
                    {
                        type: NAV_TYPE.NAV,
                        to: ROUTES.SKILLS_JOURNEY,
                        key: ROUTES.SKILLS_JOURNEY,
                        title: _.get(this.props.translation, "skills_journey_page.page_label"),
                    },
                    {
                        type: NAV_TYPE.NAV,
                        to: ROUTES.QUICKFIND_JOB,
                        key: ROUTES.QUICKFIND_JOB,
                        title: _.get(this.props.translation, "quickfind_job_page.page_label"),
                    },
                    {
                        to: ROUTES.JOB_COMPASS,
                        type: NAV_TYPE.NAV,
                        key: ROUTES.JOB_COMPASS,
                        title: _.get(this.props.translation, "job_compass_page.page_label"),
                    },
                    {
                        to: ROUTES.JOBS,
                        type: NAV_TYPE.NAV,
                        key: ROUTES.JOBS,
                        title: _.get(this.props.translation, "jobs_page.page_label"),
                    },
                ];

            case USER_ROLE.EMPLOYEE:
                switch (this.props.userSubRole) {
                    case USER_SUB_ROLE.ANCHOR:
                        return [
                            {
                                to: ROUTES.STAFF_AND_DEPARTMENTS,
                                type: NAV_TYPE.NAV,
                                key: ROUTES.STAFF_AND_DEPARTMENTS,
                                title: _.get(
                                    this.props.translation,
                                    "staff_and_departments_page.page_label"
                                ),
                            },
                            // {
                            //     to: ROUTES.COMPANY_PROFILE,
                            //     key: ROUTES.COMPANY_PROFILE,
                            //     title: _.get(
                            //         this.props.translation,
                            //         "company_profile_page.page_label"
                            //     ),
                            // },
                            {
                                to: ROUTES.CORPORATE_IDENTITY,
                                type: NAV_TYPE.NAV,
                                key: ROUTES.CORPORATE_IDENTITY,
                                title: _.get(
                                    this.props.translation,
                                    "corporate_identity_page.page_label"
                                ),
                            },
                            {
                                to: ROUTES.FINANCIALS,
                                type: NAV_TYPE.NAV,
                                key: ROUTES.FINANCIALS,
                                title: _.get(this.props.translation, "financial_page.label"),
                            },
                            {
                                to: ROUTES.MY_PROFILE,
                                type: NAV_TYPE.NAV,
                                key: ROUTES.MY_PROFILE,
                                title: _.get(this.props.translation, "my_profile_page.label"),
                            },
                            {
                                to: ROUTES.CORPORATE_PROFILE,
                                type: NAV_TYPE.NAV,
                                key: ROUTES.CORPORATE_PROFILE,
                                title: _.get(this.props.translation, "corporate_profile.label"),
                            },
                            {
                                to: ROUTES.DEPARTMENTS_PROFILE,
                                type: NAV_TYPE.NAV,
                                key: ROUTES.DEPARTMENTS_PROFILE,
                                title: _.get(this.props.translation, "departments_profile.label"),
                            },
                        ];
                    case USER_SUB_ROLE.HM:
                        return [
                            {
                                type: NAV_TYPE.DROPDOWN,
                                title: _.get(this.props.translation, "job.jobs_label"),
                                children: [
                                    {
                                        to: ROUTES.OPEN_JOBS,
                                        title: _.get(
                                            this.props.translation,
                                            "open_jobs_page.menu_label"
                                        ),
                                    },
                                    {
                                        to: ROUTES.CREATE_NEW_JOB,
                                        title: _.get(
                                            this.props.translation,
                                            "create_new_job_page.menu_label"
                                        ),
                                    },
                                ],
                            },
                            {
                                type: NAV_TYPE.NAV,
                                to: ROUTES.POST_JOB,
                                title: _.get(this.props.translation, "post_job.page_label"),
                            },
                            {
                                type: NAV_TYPE.NAV,
                                to: ROUTES.APPLICANT_QUICKFIND,
                                title: _.get(
                                    this.props.translation,
                                    "applicant_quickfind_page.header_label"
                                ),
                            },
                        ];

                    default:
                        break;
                }
                break;

            default:
                break;
        }
    };

    getNavItems = () => {
        let navsData = this.getNavItemsData();
        let result = [];

        _.forEach(navsData, (navData, id) => {
            switch (navData.type) {
                case NAV_TYPE.NAV:
                    if (!navData.hidden) {
                        result.push(
                            <NavItem key={id}>
                                <NavLink
                                    className="header-link"
                                    tag={RRDNavLink}
                                    to={navData.to}
                                    activeClassName="active"
                                >
                                    {navData.title}
                                </NavLink>
                            </NavItem>
                        );
                    }
                    break;

                case NAV_TYPE.DROPDOWN:
                    if (!navData.hidden) {
                        result.push(
                            <UncontrolledDropdown
                                key={"dropdown" + id}
                                className="header-nav-dropdown"
                                nav
                                inNavbar
                                setActiveFromChild
                            >
                                <DropdownToggle nav caret>
                                    {navData.title}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {_.map(navData.children, (child, childId) => {
                                        if (child.hidden) {
                                            return null;
                                        }
                                        return (
                                            <DropdownItem
                                                key={childId}
                                                tag={Link}
                                                to={child.to}
                                                activeClassName="active"
                                                active={this.props.location.pathname === child.to}
                                            >
                                                {child.title}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        );
                    }
                    break;

                default:
                    break;
            }
        });

        return result;
    };
}

NavItemManager.propTypes = {
    translation: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    userRole: PropTypes.string,
    userSubRole: PropTypes.string,
};

export default withRouter(NavItemManager);
