import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import {
    FormGroup,
    Input,
    TabContent,
    TabPane,
    Label,
    UncontrolledPopover,
    PopoverBody,
} from "reactstrap";
import "./PostJob.scss";
import PostJobThirdStep from "./tabs/PostJobThirdStep/PostJobThirdStep.container";
import _ from "lodash";
import CustomSwitch from "../../common/controls/customSwitch/CustomSwitch";
import CustomSelect from "../../common/inputs/customSelect/CustomSelect";
import PostJobFirstStep from "./tabs/PostJobFirstStep/PostJobFirstStep.container";
import {
    currenciesOptions,
    TABLE_STATUSES,
    USER_SUB_ROLE,
    tableConfig,
} from "../../../constants/constants";
import moment from "moment";
import PostJobSecondStep from "./tabs/PostJobSecondStep/PostJobSecondStep.container";
import { StorageManager } from "../../../utils/storageManager";
import {
    emptyObjectValidator,
    emptyStringValidator,
    nullValidator,
    requiredMediaValidator,
    validate,
} from "../../../utils/validationUtils";

const POST_JOB_TABS = {
    FIRST_TAB: "FIRST_TAB",
    SECOND_TAB: "SECOND_TAB",
    THIRD_TAB: "THIRD_TAB",
};

export const DROPZONE_TABS = {
    OFFICE: "office",
    BOSS: "boss",
};

class PostJob extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobToEdit: {},
            activeTab: POST_JOB_TABS.FIRST_TAB,
            selectedDept: null,
            activeDropzoneTab: DROPZONE_TABS.OFFICE,
            errors: {},
            assistHRM: "",
            assistBO: "",
            bossMedia: [],
            officeMedia: [],
            jobTitle: "",
            internalReference: "",
            region: "",
            countryCode: "",
            city: "",
            district: "",
            isTravelRequired: true,
            travelImpact: 50,
            homeWorkingAllowed: true,
            homeWorkingImpact: 50,
            workRegime: 50,
            maxSalary: 1,
            salaryCurrency: currenciesOptions[0].value,
            contractType: null,
            deepScreeningAmount: 1,
            topX: 1,
            topY: 1,
            applicationDeadline: moment().add(1, "d").format("YYYY-MM-DD"),
            expectedStart: moment().add(2, "d").format("YYYY-MM-DD"),
            jobDescription: [],
            question: "",
            timer: 0,
            situationContext: "",
            verbal: "",
        };

        this.containerRef = React.createRef();
    }

    static getCurrentSubRoleLabel = () => {
        const currentSubRole = StorageManager.getLastUsedSubRole();
        const currentSubRoleLabel = currentSubRole === USER_SUB_ROLE.HM ? "assistBO" : "assistHRM";
        return currentSubRoleLabel;
    };

    getRequiredFields = (excludeArray) => {
        const REQUIRED_FIELDS = {
            [POST_JOB_TABS.FIRST_TAB]: {
                bossMedia: [
                    (value) =>
                        emptyObjectValidator(
                            value,
                            _.get(this.props.translation, "errors.empty_error_label")
                        ),
                    (value) =>
                        requiredMediaValidator(
                            value,
                            "video",
                            _.get(this.props.translation, "errors.video_type_error_label")
                        ),
                ],
                officeMedia: [
                    (value) =>
                        emptyObjectValidator(
                            value,
                            _.get(this.props.translation, "errors.empty_error_label")
                        ),
                    (value) =>
                        requiredMediaValidator(
                            value,
                            "image",
                            _.get(this.props.translation, "errors.image_type_error_label")
                        ),
                ],
                jobTitle: [
                    (value) =>
                        emptyStringValidator(
                            value,
                            _.get(this.props.translation, "errors.empty_error_label")
                        ),
                ],
                region: [
                    (value) =>
                        nullValidator(
                            value,
                            _.get(this.props.translation, "errors.null_error_label")
                        ),
                ],
                countryCode: [
                    (value) =>
                        nullValidator(
                            value,
                            _.get(this.props.translation, "errors.null_error_label")
                        ),
                ],
                city: [
                    (value) =>
                        nullValidator(
                            value,
                            _.get(this.props.translation, "errors.null_error_label")
                        ),
                ],
                workRegime: [
                    (value) =>
                        nullValidator(
                            value,
                            _.get(this.props.translation, "errors.null_error_label")
                        ),
                ],
                maxSalary: [
                    (value) =>
                        nullValidator(
                            value,
                            _.get(this.props.translation, "errors.null_error_label")
                        ),
                ],
                topX: [
                    (value) =>
                        nullValidator(
                            value,
                            _.get(this.props.translation, "errors.null_error_label")
                        ),
                ],
                topY: [
                    (value) =>
                        nullValidator(
                            value,
                            _.get(this.props.translation, "errors.null_error_label")
                        ),
                ],
                applicationDeadline: [
                    (value) =>
                        nullValidator(
                            value,
                            _.get(this.props.translation, "errors.null_error_label")
                        ),
                ],
                expectedStart: [
                    (value) =>
                        nullValidator(
                            value,
                            _.get(this.props.translation, "errors.null_error_label")
                        ),
                ],
                deepScreeningAmount: [
                    (value) =>
                        nullValidator(
                            value,
                            _.get(this.props.translation, "errors.null_error_label")
                        ),
                ],
                [PostJob.getCurrentSubRoleLabel()]: [
                    (value) =>
                        emptyStringValidator(
                            value,
                            _.get(this.props.translation, "errors.empty_error_label")
                        ),
                ],
            },
            [POST_JOB_TABS.SECOND_TAB]: {
                profiles: [
                    (value) =>
                        emptyObjectValidator(
                            value,
                            _.get(this.props.translation, "errors.empty_error_label")
                        ),
                ],
                skills: [
                    (value) =>
                        emptyObjectValidator(
                            value,
                            _.get(this.props.translation, "errors.empty_error_label")
                        ),
                ],
            },
            [POST_JOB_TABS.THIRD_TAB]: {
                question: [
                    (value) =>
                        emptyStringValidator(
                            value,
                            _.get(this.props.translation, "errors.empty_error_label")
                        ),
                ],
                timer: [
                    (value) => {
                        nullValidator(
                            value,
                            _.get(this.props.translation, "errors.null_error_label")
                        );
                    },
                ],
            },
        };
        if (excludeArray) {
            return Object.values(REQUIRED_FIELDS).map((stepFields) => {
                const newObj = {};
                for (const prop in stepFields) {
                    if (!excludeArray.includes(prop)) {
                        newObj[prop] = stepFields[prop];
                    }
                }
                return newObj;
            });
        } else {
            return REQUIRED_FIELDS;
        }
    };

    componentDidMount() {
        this.props.onMount();

        this.setState(
            {
                jobToEdit: this.props.location.state ? this.props.location.state.job : {},
                isEditMode: !_.isEmpty(this.props.location.state),
            },
            () => {
                if (this.state.isEditMode)
                    this.setState({
                        ...this.state.jobToEdit,
                        readOnly: tableConfig.lifecycleStatuses[TABLE_STATUSES.PUBLISHED].includes(
                            this.state.jobToEdit.status.toLowerCase()
                        ),
                        maxSalary: this.state.jobToEdit.salary.amount,
                        salaryCurrency: currenciesOptions.filter(
                            (e) => e.value === this.state.jobToEdit.salary.currency
                        )[0].value,
                        deepScreeningAmount: this.state.jobToEdit.deepScreeningAmount || 1,
                        topY: this.state.jobToEdit.amountToHire || 1,
                        topX: this.state.jobToEdit.topAmount || 1,
                        isTravelRequired: !!this.state.jobToEdit.expectedImpactOfTravel,
                        travelImpact: !!this.state.jobToEdit.expectedImpactOfTravel
                            ? this.state.jobToEdit.expectedImpactOfTravel
                            : 0,
                        homeWorkingAllowed: !!this.state.jobToEdit.expectedImpactOfTravel,
                        homeWorkingImpact: !!this.state.jobToEdit.percentageOfHomeWorking
                            ? this.state.jobToEdit.percentageOfHomeWorking
                            : 0,
                        ...this.state.jobToEdit.location,
                        applicationDeadline: this.state.jobToEdit.deadline,
                        expectedStart: this.state.jobToEdit.startDate,
                        bossMedia: this.state.jobToEdit.bossMediaUris,
                        officeMedia: this.state.jobToEdit.workMediaUris,
                        profiles: this.state.jobToEdit.profile,
                        skills: this.state.jobToEdit,
                    });
            }
        );
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedDept, city, region, countryCode, district } = this.state;
        const {
            departmentsList,
            organization: { address },
        } = this.props;
        if (!selectedDept && departmentsList.length) {
            this.setState({ selectedDept: departmentsList[0] });
        }
        if (!city && address?.city) {
            this.setState({ city: address.city });
        }
        if (!region && countryCode) {
            const regionByCountry = this.getContinentByCountry(countryCode);
            this.setState({ region: regionByCountry });
        }
        if (!countryCode && address?.countryCode) {
            this.setState({ countryCode: address.countryCode });
        }
        if (!district && address?.district) {
            this.setState({ district: address.district });
        }
    }

    handleChangeTab = (selectedOption) => {
        if (this.state.activeTab !== selectedOption.value) {
            this.setState({ activeTab: selectedOption.value });
            let containerNode = ReactDOM.findDOMNode(this.containerRef.current);
            containerNode.scrollTo({ top: 0 });
        }
    };

    getTabCompleteProgress = (step) => {
        let counter = 0;

        if (!this.getRequiredFields()[step]) {
            return null;
        }

        _.map(Object.keys(this.getRequiredFields()[step]), (fieldName) => {
            const value = _.isNil(this.state[fieldName])
                ? this.props[fieldName]
                : this.state[fieldName];
            if (Array.isArray(value) ? !_.isEmpty(value) : !_.isNil(value) && value !== "") {
                counter++;
            }
        });
        return Object.keys(this.getRequiredFields()[step]).length === 0
            ? 100
            : (counter / Object.keys(this.getRequiredFields()[step]).length) * 100;
    };

    handleChangeSelectField = (fieldName) => ({ value }) => {
        this.setState({ [fieldName]: value });
    };

    handleChangeField = (fieldName) => ({ target: { type, checked, value } }) => {
        const realValue = type === "checkbox" ? checked : value;

        this.setState({ [fieldName]: realValue });
    };

    renderInlineSelectFormGroup = (params) => {
        if (params.type === "select") {
            return (
                !params.hide && (
                    <FormGroup key={params.fieldName} className={params.className || ""}>
                        <h6>{params.title + (params.isRequired ? "*" : "")}</h6>
                        {this.state.errors[params.fieldName] && (
                            <div className="field-error">
                                {this.state.errors[params.fieldName].join(", ")}
                            </div>
                        )}
                        <CustomSelect
                            value={
                                params.value ||
                                _.find(
                                    params.options,
                                    (option) => option.value === this.state[params.fieldName]
                                ) ||
                                null
                            }
                            disabled={this.state.readOnly}
                            className={params.isRequired ? "required-field" : ""}
                            options={params.options}
                            onChange={this.handleChangeSelectField(params.fieldName)}
                            isSearchable={params.isSearchable}
                            menuPosition="fixed"
                            isRequired={params.isRequired}
                        />
                    </FormGroup>
                )
            );
        } else if (params.type === "range") {
            return (
                !params.hide && (
                    <div key={params.fieldName} className={params.className}>
                        <h6>{`${params.title}: ${this.state[params.fieldName]}%`}</h6>
                        {this.state.errors[params.fieldName] && (
                            <div className="field-error">
                                {this.state.errors[params.fieldName].join(", ")}
                            </div>
                        )}
                        <Input
                            className="work-regime-input"
                            value={this.state[params.fieldName]}
                            type="range"
                            step="1"
                            onChange={this.handleChangeField(params.fieldName)}
                            disabled={this.state.readOnly}
                        />
                    </div>
                )
            );
        } else if (params.type === "checkbox") {
            return (
                <FormGroup key={params.fieldName} className={params.className}>
                    <FormGroup check>
                        <Label check>
                            <Input
                                checked={this.state[params.fieldName]}
                                type="checkbox"
                                id={params.fieldName}
                                name={params.fieldName}
                                onChange={this.handleChangeField(params.fieldName)}
                                disabled={this.state.readOnly}
                            />
                            {params.title}
                        </Label>
                    </FormGroup>
                </FormGroup>
            );
        } else if (params.type === "number") {
            return (
                <FormGroup key={params.fieldName} className={params.className || ""}>
                    <h6>{params.title + (params.isRequired ? "*" : "")}</h6>
                    {this.state.errors[params.fieldName] && (
                        <div className="field-error">
                            {this.state.errors[params.fieldName].join(", ")}
                        </div>
                    )}
                    <Input
                        type="number"
                        value={this.state[params.fieldName]}
                        onChange={this.handleChangeField(params.fieldName)}
                        placeholder={params.placeholder}
                        min={params.min}
                        max={params.max}
                        className={params.isRequired ? "required" : ""}
                        disabled={this.state.readOnly}
                    />
                </FormGroup>
            );
        } else if (params.type === "file") {
            return (
                <FormGroup key={params.fieldName} className={params.className || ""}>
                    {params.innerHtml ? (
                        <h6
                            dangerouslySetInnerHTML={{
                                __html: params.title + (params.isRequired ? "*" : ""),
                            }}
                        />
                    ) : (
                        <h6>{params.title + (params.isRequired ? "*" : "")}</h6>
                    )}
                    {this.state.errors[params.fieldName] && (
                        <div className="field-error">
                            {this.state.errors[params.fieldName].join(", ")}
                        </div>
                    )}
                    <Input
                        type="file"
                        accept={params.accept}
                        onChange={this.handleChangeFileField(params.fieldName)}
                        placeholder={params.placeholder}
                        min={params.min}
                        max={params.max}
                        className={params.isRequired ? "required" : ""}
                        multiple={params.multiple}
                        disabled={this.state.readOnly}
                    />
                </FormGroup>
            );
        } else if (params.type === "date") {
            return (
                <>
                    <FormGroup key={params.fieldName} className={params.className || ""}>
                        <h6>{params.title + (params.isRequired ? "*" : "")}</h6>
                        {this.state.errors[params.fieldName] && (
                            <div className="field-error">
                                {this.state.errors[params.fieldName].join(", ")}
                            </div>
                        )}
                        <Input
                            type="date"
                            value={this.state[params.fieldName]}
                            onChange={this.handleChangeField(params.fieldName)}
                            placeholder={params.placeholder}
                            id={params.fieldName}
                            className={params.isRequired ? "required" : ""}
                            min={params.min}
                            max={params.max}
                            disabled={this.state.readOnly}
                        />
                    </FormGroup>
                    {params.popover && (
                        <UncontrolledPopover
                            target={params.fieldName}
                            placement="bottom"
                            trigger="hover"
                            boundariesElement="window"
                            key={`popover_${params.fieldName}`}
                        >
                            <PopoverBody>{params.popover}</PopoverBody>
                        </UncontrolledPopover>
                    )}
                </>
            );
        } else {
            return (
                <FormGroup key={params.fieldName} className={params.className}>
                    <h6>{params.title + (params.isRequired ? "*" : "")}</h6>
                    {this.state.errors[params.fieldName] && (
                        <div className="field-error">
                            {this.state.errors[params.fieldName].join(", ")}
                        </div>
                    )}
                    <Input
                        type={params.type || "text"}
                        value={this.state[params.fieldName]}
                        onChange={this.handleChangeField(params.fieldName)}
                        placeholder={
                            params.isDisabled
                                ? this.state[params.fieldName]
                                : params.placeholder || params.title
                        }
                        required={params.isRequired}
                        disabled={params.isDisabled || this.state.readOnly}
                        className={params.isRequired ? "required" : ""}
                    />
                </FormGroup>
            );
        }
    };

    handleChangeFileField = (fieldName, urlFieldName) => (e) => {
        let { files } = e.target;
        this.setState({ [fieldName]: [...files] });
    };

    getContinentByCountry = (countryCode) => {
        const { locationData } = this.props;
        return _.find(
            _.mapValues(locationData, (countries, continentCode) => {
                return _.includes(countries, countryCode) && continentCode;
            })
        );
    };

    getCountryOptions = () => {
        const { translation, locationData, organization } = this.props;
        const countryOptions = _.map(
            _.get(locationData, this.getContinentByCountry(organization.address.countryCode)),
            (countryCode) => {
                return {
                    value: countryCode,
                    label: _.get(translation, `countries.${countryCode}`),
                };
            }
        );
        return _.sortBy(countryOptions, ["label"]);
    };

    render() {
        let tabsOptions = [
            {
                value: POST_JOB_TABS.FIRST_TAB,
                label: _.get(this.props.translation, "post_job.first_step.tab_label"),
                progress: this.getTabCompleteProgress(POST_JOB_TABS.FIRST_TAB),
            },
            {
                value: POST_JOB_TABS.SECOND_TAB,
                label: _.get(this.props.translation, "post_job.second_step.tab_label"),
                progress: this.getTabCompleteProgress(POST_JOB_TABS.SECOND_TAB),
            },
            {
                value: POST_JOB_TABS.THIRD_TAB,
                label: _.get(this.props.translation, "post_job.third_step.tab_label"),
                progress: this.getTabCompleteProgress(POST_JOB_TABS.THIRD_TAB),
            },
        ];

        return (
            <div className="post-job-container">
                <div className="post-job-header">
                    <h4 className="page-header">
                        {_.get(this.props.translation, "post_job.page_label")}
                    </h4>
                </div>
                <CustomSwitch
                    selectedOption={_.find(
                        tabsOptions,
                        (option) => option.value === this.state.activeTab
                    )}
                    options={tabsOptions}
                    onChange={this.handleChangeTab}
                    showWarning
                />
                <TabContent
                    className="tabs-container"
                    activeTab={this.state.activeTab}
                    ref={this.containerRef}
                >
                    <TabPane tabId={POST_JOB_TABS.FIRST_TAB}>
                        <PostJobFirstStep
                            disabled={this.state.readOnly}
                            handleChangeFileField={this.handleChangeFileField}
                            renderInlineSelectFormGroup={this.renderInlineSelectFormGroup}
                            setParentStateValue={(key, value) => this.setState({ [key]: value })}
                            departmentsList={this.props.departmentsList}
                            job={this.state.jobToEdit}
                            {...this.state}
                            validate={(cb) =>
                                validate(
                                    () => this.setState({ errors: {} }),
                                    (errors) => {
                                        this.setState(
                                            { errors },
                                            () => cb && cb(this.state.errors)
                                        );
                                    },
                                    this.getRequiredFields(),
                                    this.state,
                                    this.props
                                )
                            }
                        />
                    </TabPane>
                    <TabPane tabId={POST_JOB_TABS.SECOND_TAB}>
                        <PostJobSecondStep
                            setParentStateValue={(key, value) => this.setState({ [key]: value })}
                            {...this.state}
                            renderInlineSelectFormGroup={this.renderInlineSelectFormGroup}
                        />
                    </TabPane>
                    <TabPane tabId={POST_JOB_TABS.THIRD_TAB}>
                        <PostJobThirdStep
                            handleChangeFileField={this.handleChangeFileField}
                            renderInlineSelectFormGroup={this.renderInlineSelectFormGroup}
                            {...this.state}
                            validate={(cb, excludeFields) =>
                                validate(
                                    () => this.setState({ errors: {} }),
                                    (errors) => {
                                        this.setState(
                                            { errors },
                                            () => cb && cb(this.state.errors)
                                        );
                                    },
                                    this.getRequiredFields(excludeFields),
                                    this.state,
                                    this.props
                                )
                            }
                            isEditMode={this.state.isEditMode}
                            isReadOnly={this.state.readOnly}
                        />
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}

PostJob.propTypes = {
    translation: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default withRouter(PostJob);
