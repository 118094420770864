import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";

//- Actions
export const fetchGetDepartmentsListFailed = createAction("FETCH_GET_DEPARTMENTS_LIST_FAILED");
export const fetchGetDepartmentsListSuccess = createAction("FETCH_GET_DEPARTMENTS_LIST_SUCCESS");
export const onGetDepartmentsListSuccess = (data) => (dispatch) => {
    let isEmpty = data.empty;
    let currentPage = data.number;
    if ((!isEmpty && currentPage !== 0) || currentPage === 0) {
        dispatch(fetchGetDepartmentsListSuccess(data));
    } else {
        dispatch(fetchGetDepartmentsList(currentPage - 1));
    }
};
export const fetchGetDepartmentsList = createAction(
    "FETCH_GET_DEPARTMENTS_LIST",
    (data) => Api.getDepartmentsList(data),
    () => ({
        apiCall: true,
        onSuccess: onGetDepartmentsListSuccess,
        onFail: fetchGetDepartmentsListFailed,
    })
);

export const getInitialDepartmentsList = () => (dispatch) => {
    dispatch(fetchGetDepartmentsList());
};

export const onUpdateDepartmentsListCurrentPage = () => (dispatch, getState) => {
    const currentPage = getDepartmentsListCurrentPage(getState());
    dispatch(fetchGetDepartmentsList({ page: currentPage }));
};

export const onClickDepartmentsListPaginationItem = (page) => (dispatch) => {
    dispatch(fetchGetDepartmentsList({ page }));
};

export const updateDepartmentMedia = createAction("UPDATE_DEPARTMENT_MEDIA");

export const onUpdateDepartmentMediaSuccess = (data) => (dispatch) => {
    dispatch(updateDepartmentMedia(data));
};

export const onUpdateDepartmentMediaFail = createAction("UPDATE_DEPARTMENT_MEDIA_FAIL");

export const fetchUpdateDepartmentMedia = createAction(
    "FETCH_UPDATE_DEPARTMENT_MEDIA",
    (departmentId, data) => Api.updateDepartmentMedia(departmentId, data),
    () => ({
        apiCall: true,
        onSuccess: onUpdateDepartmentMediaSuccess,
        onFail: onUpdateDepartmentMediaFail,
    })
);

export const fetchDeleteDepartmentMedia = createAction(
    "FETCH_DELETE_DEPARTMENT_MEDIA",
    (departmentId, mediaId) => Api.deleteDepartmentMedia(departmentId, mediaId),
    () => ({
        apiCall: true,
        onSuccess: onUpdateDepartmentMediaSuccess,
        onFail: onUpdateDepartmentMediaFail,
    })
);

//- State
const initialState = {
    data: [],
    fetching: false,
    error: null,
    currentPage: null,
    totalPages: null,
};

//- Reducers
export default handleActions(
    {
        FETCH_GET_DEPARTMENTS_LIST: (state) => {
            return { ...state, error: null, fetching: true };
        },
        FETCH_GET_DEPARTMENTS_LIST_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetching: false };
        },
        FETCH_GET_DEPARTMENTS_LIST_SUCCESS: (state, action) => {
            return {
                ...state,
                data: action.payload.content,
                currentPage: action.payload.number,
                totalPages: action.payload.totalPages,
                error: null,
                fetching: false,
            };
        },
        UPDATE_DEPARTMENT_MEDIA: (state, action) => {
            return {
                ...state,
                data: state.data.map((department) => {
                    return department.id === action.payload.id
                        ? { ...action.payload }
                        : { ...department };
                }),
            };
        },
    },
    initialState
);

//- Selectors
export const getDepartmentsListState = (state) => state.departmentsList;

export const getDepartmentsListData = (state) => getDepartmentsListState(state).data;

export const getDepartmentsListDataError = (state) => getDepartmentsListState(state).error;

export const getDepartmentsListFetchingStatus = (state) => getDepartmentsListState(state).fetching;

export const getDepartmentsListCurrentPage = (state) => getDepartmentsListState(state).currentPage;

export const getDepartmentsListTotalPages = (state) => getDepartmentsListState(state).totalPages;
