import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import _ from 'lodash';

class SkillsValidationModal extends Component {
	render() {
		const { translation, onClose, isOpen } = this.props;
		return (
			<Modal
				isOpen={isOpen}
				toggle={onClose}
				centered
			>
				<ModalBody>
					<Label>
						{_.get(translation, 'shared.skills_validation_label')}
					</Label>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={onClose}>
						{_.get(translation, 'actions.ok_button')}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

SkillsValidationModal.propTypes = {
	translation: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};

export default SkillsValidationModal;
