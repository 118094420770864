import { connect } from 'react-redux';
import CandidatesComparison from './CandidatesComparison';
import { getTranslation } from '../../../../reducers/common/localization';
import {
    fetchGetOpenJobDataForComparison,
    getOpenJobData,
    getOpenJobAllCandidatesDataInitialFetchingStatus,
    getOpenJobViewMode,
    updateOpenJobViewMode,
    getOpenJobComparingMode,
    updateOpenJobComparingMode,
    getOpenJobPlannedTopCandidatesAmount,
    getOpenJobTopXCandidatesAmount,
    getOpenJobNoGoCandidatesAmount,
    getCandidateDraggingStatus,
    onFinishDragCandidate,
    onAddCandidateToTopXGroup,
    onAddCandidateToNoGoGroup,
    setCurrentCandidateForActiveModal,
    getOpenJobAnonymityMode,
    getSoftSkillsMode,
    updateSoftSkillsMode
} from '../../../../reducers/job/openJob';
import {
    showModal,
    MODAL_CANDIDATES_GROUP,
    MODAL_CANDIDATE_CROSS_JOB_INFO,
    MODAL_CANDIDATE_JOB_SPECIFIC_INFO,
    MODAL_CANDIDATE_ESSENCE_INFO
} from '../../../../reducers/common/activeModal';
import { getCurrentCandidateData } from '../../../../reducers/job/selectedCandidates';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        data: getOpenJobData(state),
        loading: getOpenJobAllCandidatesDataInitialFetchingStatus(state),
        viewMode: getOpenJobViewMode(state),
        comparingMode: getOpenJobComparingMode(state),
        plannedTopAmount: getOpenJobPlannedTopCandidatesAmount(state),
        topXAmount: getOpenJobTopXCandidatesAmount(state),
        noGoAmount: getOpenJobNoGoCandidatesAmount(state),
        isCandidateDragging: getCandidateDraggingStatus(state),
        currentCandidateData: getCurrentCandidateData(state),
        anonymityMode: getOpenJobAnonymityMode(state),
        softSkillsMode: getSoftSkillsMode(state)
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onMount: (jobId) => dispatch(fetchGetOpenJobDataForComparison(jobId)),
        onChangeViewMode: (mode) => dispatch(updateOpenJobViewMode(mode)),
        onChangeComparingMode: (mode) => dispatch(updateOpenJobComparingMode(mode)),
        onShowCandidatesGroupModal: () => dispatch(showModal(MODAL_CANDIDATES_GROUP)),
        onFinishDragCandidate: () => dispatch(onFinishDragCandidate()),
        onAddCandidateToTop: (userId) => dispatch(onAddCandidateToTopXGroup(userId)),
        onAddCandidateToNoGo: (userId) => dispatch(onAddCandidateToNoGoGroup(userId)),
        onShowCrossJobInfoModal: () => {
            dispatch(setCurrentCandidateForActiveModal());
            dispatch(showModal(MODAL_CANDIDATE_CROSS_JOB_INFO));
        },
        onShowJobSpecificInfoModal: () => {
            dispatch(setCurrentCandidateForActiveModal());
            dispatch(showModal(MODAL_CANDIDATE_JOB_SPECIFIC_INFO));
        },
        onShowCandidateEssenceInfoModal: () => {
            dispatch(setCurrentCandidateForActiveModal());
            dispatch(showModal(MODAL_CANDIDATE_ESSENCE_INFO));
        },
        onChangeSoftSkillsMode: (mode) => dispatch(updateSoftSkillsMode(mode)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidatesComparison);
