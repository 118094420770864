import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Label,
    FormGroup
} from 'reactstrap';

class RemoveClusterModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notShowAgain: false
        };
    }

    render() {
        if (!this.props.data) {
            return null;
        }

        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.onClose}
            >
                <ModalBody>
                    {this.renderModalBody()}
                </ModalBody>
                <ModalFooter className="content-space-between">
                    <Button
                        color="primary"
                        onClick={this.props.onClose}
                    >
                        {_.get(this.props.translation, 'actions.cancel_button')}
                    </Button>
                    <Button
                        color="danger"
                        onClick={this.onRemove}
                    >
                        {_.get(this.props.translation, "skills_management.delete_cluster_modal_button")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalBody = () => {
        return (
            <div>
                <h6>
                    {_.replace(_.get(this.props.translation, 'skills_management.are_you_sure_remove_cluster_label'), "{clusterName}", this.props.data.categoryTitle)}
                </h6>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" value={this.state.notShowAgain} onChange={this.handleChangeNotShowAgain} />
                        {_.get(this.props.translation, 'skills_management.not_show_modal_again_label')}
                    </Label>
                </FormGroup>
            </div>
        )
    }

    handleChangeNotShowAgain = () => {
        this.setState({ notShowAgain: !this.state.notShowAgain })
    }

    onRemove = () => {
        if (this.state.notShowAgain) {
            this.props.onNotShowAgain();
        }

        this.setState({ notShowAgain: false })

        this.props.onClose();
        this.props.onRemove();
    }
}

RemoveClusterModal.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    onRemove: PropTypes.func,
    onNotShowAgain: PropTypes.func,
    onClose: PropTypes.func
};

export default RemoveClusterModal;
