import { connect } from 'react-redux';
import { getTranslation } from '../../../reducers/common/localization';
import HardSkillComparisonControls from './HardSkillComparisonControls';

const mapStateToProps = state => {
	return {
		translation: getTranslation(state),
	};
};

export default connect(mapStateToProps, null)(HardSkillComparisonControls)
