import { connect } from 'react-redux';
import CandidateStack from './CandidateStack';
import { getTranslation } from '../../../../../reducers/common/localization';
import {
    getOpenJobCandidatesData,
    getOpenJobLeftCandidatesAmount,
    getOpenJobTotalCandidatesAmount,
    getOpenJobPinTopAndFavsStatus,
    onUpdateOpenJobPinTopAndFavs,
    getOpenJobAllCandidatesDataInitialFetchingStatus,
    getOpenJobSortingMode,
    onUpdateOpenJobSortingMode,
    getOpenJobCombinedSortingRatio,
    onChangeCombinedSortingRatio,
    getOpenJobAnonymityMode,
    updateOpenJobAnonymityMode,
    getOpenJobPlannedTopCandidatesAmount,
    onAddCandidateToFavorite,
    onRemoveCandidateFromFavorite,
    setCandidateForActiveModal
} from '../../../../../reducers/job/openJob';
import { showModal, MODAL_CANDIDATE_NOTES } from '../../../../../reducers/common/activeModal';
import {
    getCurrentCandidateId,
    getSelectedCandidatesIds,
    getCandidatesSelectionMode
} from '../../../../../reducers/job/selectedCandidates';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        data: getOpenJobCandidatesData(state),
        loading: getOpenJobAllCandidatesDataInitialFetchingStatus(state),
        leftAmount: getOpenJobLeftCandidatesAmount(state),
        totalAmount: getOpenJobTotalCandidatesAmount(state),
        isPinTopAndFavs: getOpenJobPinTopAndFavsStatus(state),
        sortingMode: getOpenJobSortingMode(state),
        combinedSortingRatio: getOpenJobCombinedSortingRatio(state),
        anonymityMode: getOpenJobAnonymityMode(state),
        plannedTopAmount: getOpenJobPlannedTopCandidatesAmount(state),
        selectionMode: getCandidatesSelectionMode(state),
        currentCandidateId: getCurrentCandidateId(state),
        selectedCandidatesIds: getSelectedCandidatesIds(state)
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onUpdateOpenJobPinTopAndFavs: (isPinTopAndFavs) => dispatch(onUpdateOpenJobPinTopAndFavs(isPinTopAndFavs)),
        onChangeSortingMode: (mode) => dispatch(onUpdateOpenJobSortingMode(mode)),
        onChangeCombinedSortingRatio: (ratio) => dispatch(onChangeCombinedSortingRatio(ratio)),
        onChangeAnonymityMode: (value) => dispatch(updateOpenJobAnonymityMode(value)),
        onAddToFavorite: (userId) => dispatch(onAddCandidateToFavorite(userId)),
        onRemoveFromFavorite: (userId) => dispatch(onRemoveCandidateFromFavorite(userId)),
        onCandidateNotesClick: (userId) => {
            dispatch(setCandidateForActiveModal(userId));
            dispatch(showModal(MODAL_CANDIDATE_NOTES));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateStack);
