import { connect } from "react-redux";
import SkillsSetup, { SKILLS_SETUP_MODE } from "./SkillsSetup";
import { getTranslation } from "../../../../reducers/common/localization";
import { getUserData, getUserId } from "../../../../reducers/user/userData";
import {
    getApplicantSkillsData,
    getApplicantSkillsDataTree,
    getApplicantCurrentSkillId,
    getApplicantCurrentSkillData,
    onChangeCurrentSkill,
    getApplicantCurrentClusterId,
    getApplicantCurrentClusterData,
    onChangeCurrentCluster,
    removeApplicantSkill,
    removeApplicantCluster,
    addApplicantSkill,
    updateApplicantSkillProperty,
    getApplicantEmptyClustersData,
    addEmptyCluster,
    getApplicantClustersData,
    onAddNewCluster,
    onAddNewSkill,
    onSaveApplicantSkills,
} from "../../../../reducers/applicant/applicantSkills";
import {
    getApplicantProfilesData,
    onChangeSelectedProfile,
    onRemoveApplicantProfile,
    onAddApplicantProfile,
    getApplicantSelectedProfilesData,
    getApplicantProfilesInitialFetchingStatus,
} from "../../../../reducers/applicant/applicantProfiles";
import {
    getSuggestedProfilesData,
    getSuggestedProfilesFetchingStatus,
} from "../../../../reducers/profiles/suggestedProfiles";
import {
    getSearchProfilesData,
    fetchGetSearchProfiles,
    cleanSearchProfiles,
    getSearchProfilesFetchingStatus,
} from "../../../../reducers/profiles/searchProfiles";
import {
    getSuggestedSkillsData,
    getSuggestedSkillsFetchingStatus,
} from "../../../../reducers/skillsAndClusters/suggestedSkills";
import {
    getCurrentClusterSkillsData,
    getCurrentClusterSkillsFetchingStatus,
} from "../../../../reducers/skillsAndClusters/currentClusterSkills";
import {
    getSearchSkillsData,
    getSearchClustersData,
    getSearchSkillsAndClustersFetchingStatus,
    onGetSearchSkillsAndClusters,
    cleanSearchSkillsAndClusters,
} from "../../../../reducers/skillsAndClusters/searchSkillsAndClusters";
import {
    showModal,
    MODAL_SKILL_NOTES,
    MODAL_REMOVE_SKILL,
    MODAL_REMOVE_CLUSTER,
    CUSTOM_SKILL_LEVEL_MODAL,
    POTENTIAL_CLUSTERS_MODAL,
} from "../../../../reducers/common/activeModal";
import { fetchGetSkillClustersByDomain, getSC } from "../../../../reducers/admin/domainsAndSC";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        mode: SKILLS_SETUP_MODE.APPLICANT,
        userId: getUserId(state),
        user: getUserData(state),
        // TODO: remove hardcoded values
        imageSource: "",
        profiles: getApplicantProfilesData(state),
        selectedProfiles: getApplicantSelectedProfilesData(state),
        suggestedProfiles: getSuggestedProfilesData(state),
        searchProfiles: getSearchProfilesData(state),
        loadingProfiles: getApplicantProfilesInitialFetchingStatus(state),
        loadingSearchProfiles: getSearchProfilesFetchingStatus(state),
        loadingSuggestedProfiles: getSuggestedProfilesFetchingStatus(state),
        skills: getApplicantSkillsData(state),
        skillsTree: getApplicantSkillsDataTree(state),
        clusters: getApplicantClustersData(state),
        searchSkills: getSearchSkillsData(state),
        searchClusters: getSearchClustersData(state),
        emptyClusters: getApplicantEmptyClustersData(state),
        suggestedSkills: getSuggestedSkillsData(state),
        currentSkillId: getApplicantCurrentSkillId(state),
        currentSkillData: getApplicantCurrentSkillData(state),
        currentClusterId: getApplicantCurrentClusterId(state),
        currentClusterData: getApplicantCurrentClusterData(state),
        currentClusterSkills: getCurrentClusterSkillsData(state),
        loadingSuggestedSkills: getSuggestedSkillsFetchingStatus(state),
        loadingSearchSkillsAndClusters: getSearchSkillsAndClustersFetchingStatus(state),
        loadingCurrentClusterSkills: getCurrentClusterSkillsFetchingStatus(state),
        skill_clusters: getSC(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onChangeSelectedProfile: (profileId, onMount) =>
            dispatch(onChangeSelectedProfile(profileId, onMount)),
        onAddProfile: (profile, altTitles, searchValue) =>
            dispatch(onAddApplicantProfile(profile, altTitles, searchValue)),
        onRemoveProfile: (profileId) => dispatch(onRemoveApplicantProfile(profileId)),
        onGetSearchProfiles: (filter) => dispatch(fetchGetSearchProfiles(filter)),
        onCleanSearchProfiles: () => dispatch(cleanSearchProfiles()),
        onGetSearchSkillsAndClusters: (filter) => dispatch(onGetSearchSkillsAndClusters(filter)),
        onCleanSearchSkillsAndClusters: () => dispatch(cleanSearchSkillsAndClusters()),
        onSelectSkill: (skillId, clusterId) => dispatch(onChangeCurrentSkill(skillId, clusterId)),
        onSelectCluster: (clusterId, flags) => dispatch(onChangeCurrentCluster(clusterId, flags)),
        onAddSkill: (skillData) => dispatch(addApplicantSkill(skillData)),
        AddNewSkill: (skillData) => dispatch(onAddNewSkill(skillData)),
        onAddEmptyCluster: (clusterData) => dispatch(addEmptyCluster(clusterData)),
        onRemoveSkill: (skillId) => dispatch(removeApplicantSkill(skillId)),
        onRemoveCluster: (clusterId) => dispatch(removeApplicantCluster(clusterId)),
        updateSkillProperty: (key, value, mergeObject) =>
            dispatch(updateApplicantSkillProperty(key, value, mergeObject)),
        onShowSkillNotesModal: () => dispatch(showModal(MODAL_SKILL_NOTES)),
        onShowRemoveSkillModal: () => dispatch(showModal(MODAL_REMOVE_SKILL)),
        onShowRemoveClusterModal: () => dispatch(showModal(MODAL_REMOVE_CLUSTER)),
        onGetClustersList: () => dispatch(fetchGetSkillClustersByDomain()),
        onAddNewCluster: (cluster) => dispatch(onAddNewCluster(cluster)),
        onSaveChanges: () => dispatch(onSaveApplicantSkills()),
        onShowCustomSkillLevelModal: () => dispatch(showModal(CUSTOM_SKILL_LEVEL_MODAL)),
        onShowPotentialClustersModal: () => dispatch(showModal(POTENTIAL_CLUSTERS_MODAL)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsSetup);
