import { connect } from 'react-redux';
import AddOrEditDepartmentModal from "./AddOrEditDepartmentModal";
import { getTranslation } from '../../../../../reducers/common/localization';
import { getActiveModalType, MODAL_ADD_DEPARTMENT, hideModal } from '../../../../../reducers/common/activeModal';
import { onFetchCreateDepartment, onFetchEditDepartment } from '../../../../../reducers/organization/departments';

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_ADD_DEPARTMENT,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => dispatch(hideModal()),
        onAddDepartment: (data) => dispatch(onFetchCreateDepartment(data)),
        onEditDepartment: (data) => dispatch(onFetchEditDepartment(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditDepartmentModal);
