import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    ModalHeader
} from 'reactstrap';
import { FormUtils } from '../../../../../utils/formUtils';

class AddStaffMemberModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            email: ""
        };
    }

    closeModal = () => {
        const { onClose } = this.props;

        this.setState({ firstName: "", lastName: "", email: "" });
        onClose();
    };

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.closeModal}
            >
                <ModalHeader>
                    {_.get(this.props.translation, 'staff.add_new_staff_member_label')}
                </ModalHeader>
                <ModalBody>
                    {this.renderModalBody()}
                </ModalBody>
                <ModalFooter className="content-space-between">
                    <Button
                        color="secondary"
                        onClick={this.closeModal}
                    >
                        {_.get(this.props.translation, 'actions.cancel_button')}
                    </Button>
                    <Button
                        color="primary"
                        form="add-staff-member-modal-form"
                        type="submit"
                    >
                        {_.get(this.props.translation, "actions.send_invitation")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalBody = () => {
        let formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField
        };

        let params = [
            {
                fieldName: "firstName",
                title: _.get(this.props.translation, 'person_data.first_name_label'),
                isRequired: true
            },
            {
                fieldName: "lastName",
                title: _.get(this.props.translation, 'person_data.last_name_label'),
                isRequired: true
            },
            {
                fieldName: "email",
                title: _.get(this.props.translation, 'person_data.email_label'),
                type: 'email',
                isRequired: true
            },
        ];
        return (
            <Form id="add-staff-member-modal-form" onSubmit={this.onSubmit}>
                {_.map(params, (fieldParams) => FormUtils.renderFormGroup(fieldParams, formParams))}
            </Form>
        )
    }

    handleChangeField = (fieldName) => (e) => {
        this.setState({ [fieldName]: e.target.value });
    }

    onSubmit = (e) => {
        e.preventDefault();

        let data = {
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
        };

        this.props.onAddStaffMember({ data });

        this.closeModal();
    }
}

AddStaffMemberModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onAddStaffMember: PropTypes.func,
    onClose: PropTypes.func.isRequired,
};

export default AddStaffMemberModal;
