import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ServerError.scss';
import _ from 'lodash';

class ServerError extends Component {
    render() {
        return (
            <div className='server-error'>
                <h2>{"500 - " + _.get(this.props.translation, 'server_error.server_error_label')}</h2>
                <h6>{_.get(this.props.translation, 'server_error.sorry_label')}</h6>
                <h6>{_.get(this.props.translation, 'server_error.cause_label')}</h6>
            </div>
        );
    }
}

ServerError.propTypes = {
    translation: PropTypes.object.isRequired
};

export default ServerError;
