
import React, { Component } from 'react';

class PersonHardSkillsOutline extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 512 512">
                <g>
                    <path d="M280.4,47.8l-5.1,25.5c-4.9,1.6-9.7,3.6-14.4,6l-21.7-14.5c-6.8-4.4-15.7-3.5-21.4,2.2l-14.2,14.2
                        c-5.8,5.7-6.8,14.8-2.3,21.6l14.4,21.6c-2.4,4.6-4.4,9.4-6,14.4l-25.5,5.1c-8,1.6-13.7,8.6-13.7,16.7v20.1
                        c0,8.1,5.7,15.1,13.7,16.7l25.5,5.1c1.6,4.9,3.6,9.7,6,14.4l-14.5,21.7c-4.4,6.8-3.5,15.7,2.2,21.4l14.2,14.2
                        c5.7,5.8,14.8,6.8,21.6,2.2l21.6-14.4c4.6,2.4,9.4,4.4,14.4,6l5.1,25.5c1.6,8,8.6,13.7,16.7,13.7h20.1c8.1,0,15.1-5.7,16.7-13.7
                        l5.1-25.5c4.9-1.6,9.7-3.6,14.4-6l21.7,14.5c6.8,4.4,15.7,3.5,21.4-2.2l14.2-14.2c5.8-5.7,6.8-14.8,2.3-21.6L398.6,217
                        c2.4-4.6,4.4-9.4,6-14.4l25.5-5.1c5.1-1,9.4-4.3,11.8-8.9l12.9,8.6c2.5,1.6,5.4,2.4,8.3,2.4c4.1,0,8-1.6,10.9-4.5l11.3-11.3
                        c5.2-5.2,6-13.3,2-19.4l-11-16.5c1.6-3.2,3-6.5,4.1-9.9l19.3-3.8c7.2-1.4,12.4-7.8,12.4-15.1v-16c0-7.3-5.1-13.7-12.3-15.1
                        l-19.4-3.9c-1.1-3.4-2.5-6.7-4.1-9.9l11.2-16.7c3.9-6.1,3-14.1-2.1-19.2L474.1,27c-5.2-5.2-13.3-6-19.4-2l-16.5,11
                        c-3.2-1.6-6.5-3-9.9-4.1l-3.9-19.3c-1.6-7.4-8-12.6-15.3-12.6h-16c-7.3,0-13.7,5.1-15.1,12.3l-3.9,19.4c-3.4,1.2-6.7,2.5-9.9,4.1
                        l-16.7-11.2c-6.1-3.9-14.1-3-19.2,2.1l-7.7,7.7c-1.1-0.2-2.2-0.4-3.4-0.4h-20.1C289,34.1,282,39.8,280.4,47.8z M410.9,81.2l-4-4
                        c14.8,2,26.6,13.4,29,28.1s-5,29.3-18.4,36l-13-2.6c-1.6-4.9-3.6-9.7-6-14.4l14.5-21.7C417.5,95.9,416.6,87,410.9,81.2L410.9,81.2z
                        M339.4,39.8l19.5,13.1c2.7,1.8,6.3,1.9,9.1,0.2c4.9-2.9,10.1-5.1,15.6-6.5c3.1-0.8,5.5-3.4,6.1-6.6l4.6-23h13.3l4.6,23
                        c0.6,3.2,3,5.7,6.1,6.6c5.5,1.5,10.7,3.7,15.6,6.5c2.8,1.7,6.4,1.6,9.1-0.3l19.5-13.1l9.5,9.5l-13.1,19.5c-1.8,2.7-1.9,6.3-0.2,9.1
                        c2.9,4.9,5.1,10.1,6.5,15.6c0.8,3.1,3.4,5.5,6.6,6.1l23,4.6v13.3l-23,4.6c-3.2,0.6-5.7,3-6.6,6.1c-1.5,5.5-3.7,10.7-6.5,15.6
                        c-1.7,2.8-1.6,6.4,0.2,9.1l13.1,19.5l-9.5,9.5l-19-12.7v-8.7c0-5-2.2-9.7-6-13c15-14.5,19.7-36.6,11.9-56
                        c-7.8-19.3-26.6-32-47.5-31.9c-5.2,0-10.3,0.9-15.2,2.5c-4.2-0.6-8.4,0.3-11.9,2.6L353.3,79c-4.6-2.4-9.4-4.4-14.4-6L334,47.8
                        c-0.2-0.7-0.3-1.4-0.6-2L339.4,39.8z M291,81.7l6.1-30.5h20.1l6.1,30.5c0.6,3.2,3,5.7,6.1,6.6c7.1,1.9,13.8,4.7,20.1,8.4
                        c2.8,1.6,6.3,1.5,9-0.3l25.8-17.3l14.3,14.2l-17.3,25.8c-1.8,2.7-1.9,6.2-0.3,9c3.7,6.3,6.5,13.1,8.4,20.1c0.8,3.1,3.4,5.5,6.6,6.1
                        l30.5,6.1v20.1l-30.5,6.1c-3.2,0.6-5.7,3-6.6,6.1c-1.9,7.1-4.7,13.8-8.4,20.1c-1.6,2.8-1.5,6.3,0.3,9l17.3,25.8l-14.2,14.3
                        L358.7,245c-2.7-1.8-6.2-1.9-9-0.3c-6.3,3.7-13.1,6.5-20.1,8.4c-3.1,0.8-5.5,3.4-6.1,6.6l-6.1,30.5h-20.1l-6.1-30.5
                        c-0.6-3.2-3-5.7-6.1-6.6c-7.1-1.9-13.8-4.7-20.1-8.4c-2.8-1.6-6.3-1.5-9,0.3l-25.8,17.3L215.6,248l17.3-25.8c1.8-2.7,1.9-6.2,0.3-9
                        c-3.7-6.3-6.5-13.1-8.4-20.1c-0.8-3.1-3.4-5.5-6.6-6.1l-30.5-6.1v-20.1l30.5-6.1c3.2-0.6,5.7-3,6.6-6.1c1.9-7.1,4.7-13.8,8.4-20.1
                        c1.6-2.8,1.5-6.3-0.3-9l-17.3-25.8L229.9,79l25.8,17.3c2.7,1.8,6.2,1.9,9,0.3c6.3-3.7,13.1-6.5,20.1-8.4
                        C288,87.4,290.4,84.9,291,81.7z"/>
                    <path d="M307.2,230.4c33,0,59.7-26.7,59.7-59.7S340.2,111,307.2,111s-59.7,26.7-59.7,59.7C247.5,203.6,274.2,230.4,307.2,230.4z
		                M307.2,128c23.6,0,42.7,19.1,42.7,42.7s-19.1,42.7-42.7,42.7s-42.7-19.1-42.7-42.7C264.6,147.1,283.6,128,307.2,128z"/>
                    <path d="M307.2,162.2c4.7,0,8.5,3.8,8.5,8.5s-3.8,8.5-8.5,8.5s-8.5-3.8-8.5-8.5S302.5,162.2,307.2,162.2z" />
                </g>
                <g>
                    <path d="M452.1,215.3c-4.5-1.1-9.1,1.7-10.2,6.3c-3,12.9-8.2,25.1-15.4,36.3c-4.4,7-9.5,13.9-14.5,20.6
                        c-9.9,12.3-18.3,25.7-25.1,40c-11.6,26.3-15.9,55.2-12.5,83.8c2,18.1,5.6,36,10.8,53.5c2.8,9.2,6.3,18.1,10.6,26.7
                        c1.9,3.8,4.2,7.6,6.6,11.1l0.6,0.9l-193.6,0.6c-5.7-20.2-16-38.9-30-54.5l7.7-2.7c5.7-2,10.1-3.5,12.3-4.3
                        c36.5-11.6,54.8-28.3,54.3-49.5c0-2.3-1-4.4-2.6-6s-3.8-2.4-6.1-2.3c-4.7,0.1-8.4,4-8.3,8.7c0.2,8.7-7,21.5-42.5,32.8
                        c-2.3,0.7-6.8,2.3-12.7,4.4c-6.3,2.2-14.4,5-23.3,8h-0.1c-24,8.8-48.5,16.2-73.3,22.2c-7.9,1.5-15.7-3.5-17.6-11.4
                        c-1.8-4.8-2.6-10-2.1-15.1c0.2-1.7,0.6-3.5,1-5.2c1.8-6,2.1-12.3,0.9-18.4c-2.5-7.8-8.2-14.2-15.8-17.4l-1.8-1
                        c0.9-1.9,2-3.6,3.3-5.3c1.4-1.8,2.1-4.1,1.8-6.4s-1.5-4.4-3.4-5.7c-2-1.5-6.8-5.1-10.4-8c0.4-0.7,0.9-1.6,1.3-2.3
                        c1.3-2.4,2.9-5.1,4.4-8.3c2.7-6,3.5-12.6,2.3-19.1c-2-10.4-12-15.5-19.3-19.2c-2.5-1.2-4.8-2.5-7-4.1c-1.8-1.2-3.5-2.6-5.1-4.1
                        c0-1.3,1.4-4.4,7.9-12.3c15.3-19.7,28.4-40.9,39-63.4c2-3.5,3.1-7.5,3.3-11.5c-0.9-8.6-5.5-16.4-12.5-21.3
                        c-3.5-3.2-6.7-6.2-7.1-8.7c0.1-2.1,0.5-4.1,1.4-6.1c1.4-3.7,3-7.2,4.6-10.8c3.6-7.4,6.5-15.2,8.5-23.1c2-9.6,4.5-19,7.7-28.2
                        c6.7-18.6,17.1-35.6,30.6-50c16.3-17.3,42-29.5,76.5-36.3c26.4-5.8,53.1-9.6,80.1-11.5c9.1-0.5,18.1-0.3,27.2,0.6
                        c3,0.3,6-1.1,7.8-3.6s2.1-5.7,0.8-8.5c-1.3-2.8-3.9-4.7-7-4.9c-9.9-0.9-19.8-1.1-29.7-0.6c-27.8,1.9-55.3,5.9-82.5,11.8
                        c-38.5,7.7-66.4,21.2-85.5,41.3c-15.4,15.8-27,34.8-34.5,55.5c-3.5,10.1-6.3,20.4-8.4,30.8c-1.8,6.7-4.3,13.2-7.3,19.5
                        c-1.7,3.9-3.5,7.8-5,11.8c-1.9,4.5-2.7,9.4-2.3,14.3c1.8,7.6,6.2,14.4,12.5,19c4.2,3.8,6.9,6.5,7,8.9c-0.3,1.3-0.8,2.5-1.5,3.7
                        c-10.2,21.5-22.7,41.9-37.3,60.7c-6.2,7.5-12.7,16.3-11.3,25.1c1.8,6.8,6.3,12.5,12.4,16c2.8,1.9,5.8,3.7,8.9,5.1
                        c3.7,1.9,9.9,5,10.3,7.3c0.5,2.9,0.2,5.9-1,8.6c-1.3,2.7-2.6,5.1-3.8,7.1c-3.1,5.5-8.4,14.8-0.5,21.2c1.8,1.7,5,4.2,7.9,6.4
                        c-2.1,3.7-3.1,8-2.8,12.3c0.4,3.4,2.3,6.4,5,8.4c1.9,1.4,4,2.6,6,3.7c5,2.9,7,4.2,7.7,6.8c0.5,3.5,0.1,7-0.9,10.3
                        c-0.5,2.2-1,4.4-1.3,6.7c-0.8,7.8,0.1,15.7,2.9,23c3.8,13.6,16.1,23.1,30.2,23.3c2.3,0,4.7-0.3,7-0.7c24.4-5.8,48.4-13,71.9-21.5
                        c19.2,11.3,31.4,45.6,34.5,58.5c0.9,3.8,4.4,6.5,8.3,6.5l214.2-0.6c2.7,0,5.3-1.3,6.9-3.5s2.1-5,1.3-7.6c-2.1-5.8-5-11.2-8.6-16.2
                        c-2-2.9-3.9-6-5.5-9.2c-3.9-7.8-7.1-16-9.6-24.3c-4.9-16.5-8.2-33.4-10.1-50.5c-3-25.5,0.9-51.4,11.2-74.9
                        c6.3-13.1,14.1-25.3,23.2-36.6c5.1-6.9,10.4-14.1,15.2-21.6c8.2-12.7,14.1-26.8,17.6-41.6C459.5,220.8,456.6,216.3,452.1,215.3z"/>
                    <path d="M101.9,127.1c5.1-14.2,13.1-27.3,23.4-38.4c11.4-12,31-20.9,58.5-26.4c4.6-0.9,7.6-5.4,6.7-10c-0.9-4.6-5.4-7.6-10-6.7
		                c-31,6.2-53.7,16.7-67.5,31.4c-11.9,12.8-21.1,27.8-27,44.3c-2.9,8.5-5.2,17.2-7,26l16.7,3.7C97.1,142.8,99.3,134.9,101.9,127.1z"/>
                    <path d="M76.8,170.7c4.7,0,8.5,3.8,8.5,8.5s-3.8,8.5-8.5,8.5s-8.5-3.8-8.5-8.5S72.1,170.7,76.8,170.7z" />
                </g>
            </svg>
        );
    }
}

export default PersonHardSkillsOutline;
