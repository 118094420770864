import { connect } from 'react-redux';
import JobCompass from './JobCompass';
import { getTranslation } from '../../../reducers/common/localization';
import { getUserId } from '../../../reducers/user/userData';
import {
    getSearchFutureProfilesData,
    fetchGetSearchFutureProfiles,
    getSearchFutureProfilesFetchingStatus
} from '../../../reducers/profiles/searchFutureProfiles';
import { showModal, MODAL_ALTERNATIVE_NOW_BLACK_LIST } from '../../../reducers/common/activeModal';
import {
    fetchGetJobCompassSettings,
    getJobCompassSettings,
    setJobCompassSettings
} from '../../../reducers/jobCompass/jobCompassSettings';
import {
    addProfileToBlackList,
    fetchGetBlackList,
    getBlackListData
} from '../../../reducers/jobCompass/blackList';
import {
    deleteProfile,
    fetchAlternativeProfiles,
    fetchCurrentProfiles, fetchSelectedFutureProfiles,
    getAlternativeProfilesData,
    getCurrentProfilesData,
    getSelectedFutureProfilesData,
    setFutureProfile
} from '../../../reducers/jobCompass/jobCompassProfiles';
import { PROFILE_TYPES } from '../../../constants/constants';

const mapStateToProps = state => {

    const settings = getJobCompassSettings(state);

    return {
        translation: getTranslation(state),
        userId: getUserId(state),
        jobCompassSettings: {
            shareWithFutureEmployees: settings.shareWithFutureEmployees || false,
            matchingNumber: settings.matchingNumber || 0,
            findJobsByPreferences: settings.findJobsByPreferences || false,
            domains: settings.domains || [],
        },
        blackList: getBlackListData(state),
        alternativeProfiles: getAlternativeProfilesData(state),
        currentProfiles: getCurrentProfilesData(state),
        selectedFutureProfiles: getSelectedFutureProfilesData(state),
        imageSource: "",
        searchProfiles: getSearchFutureProfilesData(state),
        loadingSearchProfiles: getSearchFutureProfilesFetchingStatus(state),
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onGetSearchProfiles: (filter) => dispatch(fetchGetSearchFutureProfiles(filter)),
        onShowAlternativeNowBlackList: () => dispatch(showModal(MODAL_ALTERNATIVE_NOW_BLACK_LIST)),
        onGetJobCompassSettings: () => dispatch(fetchGetJobCompassSettings()),
        onSetJobCompassSettings: (data) => dispatch(setJobCompassSettings(data)),
        onGetBlackList: () => dispatch(fetchGetBlackList()),
        onAddProfileToBlackList: (profileId) => dispatch(addProfileToBlackList(profileId)),
        onGetAlternativeProfiles: () => dispatch(fetchAlternativeProfiles()),
        onGetCurrentProfiles: () => dispatch(fetchCurrentProfiles()),
        onGetSelectedFutureProfiles: () => dispatch(fetchSelectedFutureProfiles()),
        onAddFutureProfile: (profileId) => dispatch(setFutureProfile(profileId)),
        onRemoveProfileFromFutureProfiles: (profileId) => dispatch(deleteProfile({ id: profileId, type: PROFILE_TYPES.FUTURE }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobCompass);
