import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import _ from 'lodash';

//- Actions
export const fetchGetSearchFutureProfilesFailed = createAction('FETCH_GET_SEARCH_FUTURE_PROFILES_FAILED');
export const fetchGetSearchFutureProfilesSuccess = createAction('FETCH_GET_SEARCH_FUTURE_PROFILES_SUCCESS');
export const onFetchGetSearchFutureProfilesSuccess = (data) => (dispatch) => {
    let formattedProfiles = _.map(data, (item) => {
        return {
            id: item.profile.profile.id,
            title: item.profile.profile.titles.en,
            description: item.profile.profile.description,
            missingSkillsNumber: item.profile.missingSkillsIds.length,
            jobsNumber: item.jobs.length
        }
    });
    dispatch(fetchGetSearchFutureProfilesSuccess(formattedProfiles));
};

export const fetchGetSearchFutureProfiles = createAction(
    'FETCH_GET_SEARCH_FUTURE_PROFILES',
    (filter) => Api.getPossibleProfiles(filter),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSearchFutureProfilesSuccess,
        onFail: fetchGetSearchFutureProfilesFailed
    })
);

//- State
const initialState = {
    data: null,
    error: null,
    fetching: false,
};

//- Reducers
export default handleActions({
    FETCH_GET_SEARCH_FUTURE_PROFILES: (state) =>
      ({ ...state, data: null, error: null, fetching: true }),
    FETCH_GET_SEARCH_FUTURE_PROFILES_FAILED: (state, action) =>
      ({ ...state, data: null, error: action.payload, fetching: false }),
    FETCH_GET_SEARCH_FUTURE_PROFILES_SUCCESS: (state, action) =>
      ({ ...state, data: action.payload, error: null, fetching: false }),
    CLEAR_JOB_COMPASS_ERROR: (state) =>
      ({ ...state, error: null }),
}, initialState);

//- Selectors
export const getSearchFutureProfilesState = state => state.searchFutureProfiles;

export const getSearchFutureProfilesData = state => getSearchFutureProfilesState(state).data;

export const getSearchFutureProfilesFetchingStatus = state => getSearchFutureProfilesState(state).fetching;

export const getSearchFutureProfilesError = state => getSearchFutureProfilesState(state).error;
