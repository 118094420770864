import { connect } from "react-redux";
import JobRichMedia from "./JobRichMedia";
import { getTranslation } from "../../../reducers/common/localization";

const mapStateToProps = (state) => ({
    translation: getTranslation(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(JobRichMedia);
