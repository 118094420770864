import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import _ from 'lodash';

class RemoveDepartmentModal extends Component {
	render() {
		const { translation, isOpen, onClose, onRemoveDepartment, clearDepartment, departmentData } = this.props;

		if (_.isEmpty(departmentData)) return null;

		return (
			<Modal
				isOpen={isOpen}
				toggle={() => {
					clearDepartment();
					onClose();
				}}
			>
				<ModalHeader>
					{_.get(translation, 'departments.remove_department_label')}
				</ModalHeader>
				<ModalBody>
					<h6>{_.get(translation, 'departments.remove_department_name_label').replace('{name}', `${departmentData.name}`)}</h6>
					<h6>{_.get(translation, 'departments.remove_department_email_label').replace('{email}', `${departmentData.email}`)}</h6>
				</ModalBody>
				<ModalFooter className="content-space-between">
					<Button
						color="secondary"
						onClick={() => {
							clearDepartment();
							onClose();
						}}
					>
						{_.get(translation, 'actions.cancel_button')}
					</Button>
					<Button
						color="primary"
						onClick={() => {
							onRemoveDepartment({ departmentId: departmentData.id });
							clearDepartment();
							onClose();
						}}
					>
						{_.get(translation, "actions.remove_button")}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

RemoveDepartmentModal.propTypes = {
	translation: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onRemoveDepartment: PropTypes.func.isRequired,
	clearDepartment: PropTypes.func.isRequired,
	departmentData: PropTypes.object.isRequired
};

export default RemoveDepartmentModal;
