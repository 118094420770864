import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Icon from '../icon/Icon';
import './HardSkillComparisonControls.scss';

class HardSkillComparisonControls extends Component {
	render() {
		const { translation, isShowAllSkills, handleAllSkillsVisibility } = this.props;

		return (
			<div className="hard-skills-header">
				<h5 className="text-center">
					{_.get(translation, 'job_and_candidates.hard_skills_label')}
				</h5>
				<div className="hard-skills-controls">
					<div className="controls-title">
						{_.get(translation, 'actions.show_label')}
					</div>
					<div className="control-button" onClick={handleAllSkillsVisibility(false)}>
						<Icon type={`radio${isShowAllSkills ? "_blank" : ""}`} size="sm" />
						{_.get(translation, 'job.required_skills_job_label')}
					</div>
					<div className="control-button" onClick={handleAllSkillsVisibility(true)}>
						<Icon type={`radio${isShowAllSkills ? "" : "_blank"}`} size="sm" />
						{_.get(translation, 'job.all_skills_candidate_label')}
					</div>
				</div>
			</div>
		)
	}
}

HardSkillComparisonControls.propTypes = {
	translation: PropTypes.object.isRequired,
	isShowAllSkills: PropTypes.bool.isRequired,
	handleAllSkillsVisibility: PropTypes.func.isRequired
};

export default HardSkillComparisonControls;
