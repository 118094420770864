import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CandidateJobSpecificInfoModal.scss';
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';
import _ from "lodash";
import Icon from '../../../../../common/icon/Icon';
import CandidateModalHeader from '../candidateModalHeader/CandidateModalHeader.container';

export const JOB_SPECIFIC_INFO_TYPE = {
    LETTER: "LETTER",
    AUDIO: "AUDIO",
    VIDEO: "VIDEO"
}

class CandidateJobSpecificInfoModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: JOB_SPECIFIC_INFO_TYPE.LETTER
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeTab && this.props.activeTab !== prevProps.activeTab) {
            this.setState({ activeTab: this.props.activeTab });
        }
    }

    // TODO: (BE) add Job specific data
    render() {
        if (!this.props.data) {
            return null;
        }
        return (
            <Modal isOpen={this.props.isOpen} className="candidate-job-specific-info-modal" toggle={this.props.onCancel}>
                <CandidateModalHeader toggle={this.props.onCancel} />
                <ModalBody>
                    {this.renderModalBody()}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        onClick={this.props.onCancel}
                    >
                        {_.get(this.props.translation, 'actions.cancel_button')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalBody() {
        return (
            <>
                <div className="modes-buttons">
                    <div
                        className={"mode-button " + (this.state.activeTab === JOB_SPECIFIC_INFO_TYPE.LETTER ? "active" : "")}
                        title={_.get(this.props.translation, 'job_and_candidates.motivation_letter_label')}
                        onClick={this.handleChangeTab(JOB_SPECIFIC_INFO_TYPE.LETTER)}
                    >
                        <Icon type="text_message" size="xl" />
                    </div>
                    <div
                        className={"mode-button " + (this.state.activeTab === JOB_SPECIFIC_INFO_TYPE.AUDIO ? "active" : "")}
                        title={_.get(this.props.translation, 'job_and_candidates.audio_message_label')}
                        onClick={this.handleChangeTab(JOB_SPECIFIC_INFO_TYPE.AUDIO)}
                    >
                        <Icon type="audio_message" size="xl" />
                    </div>
                    <div
                        className={"mode-button " + (this.state.activeTab === JOB_SPECIFIC_INFO_TYPE.VIDEO ? "active" : "")}
                        title={_.get(this.props.translation, 'job_and_candidates.video_message_label')}
                        onClick={this.handleChangeTab(JOB_SPECIFIC_INFO_TYPE.VIDEO)}
                    >
                        <Icon type="video_message" size="xl" />
                    </div>
                </div>
                <div className="modes-content">
                    <div className="mode-content" hidden={this.state.activeTab !== JOB_SPECIFIC_INFO_TYPE.LETTER}>
                        <h6 className="text-center">
                            {_.get(this.props.translation, 'job_and_candidates.motivation_letter_label')}
                        </h6>
                        {this.renderLetterTabContent()}
                    </div>
                    <div className="mode-content" hidden={this.state.activeTab !== JOB_SPECIFIC_INFO_TYPE.AUDIO}>
                        <h6 className="text-center">
                            {_.get(this.props.translation, 'job_and_candidates.audio_message_label')}
                        </h6>
                        {this.renderAudioTabContent()}
                    </div>
                    <div className="mode-content" hidden={this.state.activeTab !== JOB_SPECIFIC_INFO_TYPE.VIDEO}>
                        <h6 className="text-center">
                            {_.get(this.props.translation, 'job_and_candidates.video_message_label')}
                        </h6>
                        {this.renderVideoTabContent()}
                    </div>
                </div>
            </>
        )
    }

    renderLetterTabContent() {
        // TODO: remove hardcoded values
        return (
            <div className="letter-text">
                Id minim irure incididunt veniam labore labore nostrud culpa. Ullamco voluptate tempor nisi minim sit veniam ut. Eiusmod sint eiusmod consequat pariatur reprehenderit veniam. Duis dolore Lorem cillum ex eu qui dolor sunt ullamco.
            </div>
        )
    }

    renderAudioTabContent() {
        return (
            <audio className="audio-player" controls></audio>
        )
    }

    renderVideoTabContent() {
        return (
            <video className="video-player" controls></video>
        )
    }

    handleChangeTab = (type) => () => {
        if (this.state.activeTab !== type) {
            this.setState({ activeTab: type });
        }
    }
}

CandidateJobSpecificInfoModal.propTypes = {
    translation: PropTypes.object.isRequired,
    activeTab: PropTypes.string,
    data: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default CandidateJobSpecificInfoModal;
