import { connect } from 'react-redux';
import StaffAndDepartments from './StaffAndDepartments';
import { getTranslation } from '../../../reducers/common/localization';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
    };
};

export default connect(mapStateToProps)(StaffAndDepartments);
