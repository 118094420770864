import { connect } from "react-redux";
import SkillsSetup, { SKILLS_SETUP_MODE } from "./SkillsSetup";
import { getTranslation } from "../../../../reducers/common/localization";
import { JOB_IMAGE_PLACEHOLDER } from "../../../../constants/imagesConstants";
import {
    getJobSkillsData,
    getJobSkillsDataTree,
    getJobCurrentSkillId,
    getJobCurrentSkillData,
    onChangeCurrentSkill,
    getJobCurrentClusterId,
    getJobCurrentClusterData,
    onChangeCurrentCluster,
    removeJobSkill,
    removeJobCluster,
    addJobSkill,
    updateJobSkillProperty,
    getJobEmptyClustersData,
    addEmptyCluster,
    getJobClustersData,
} from "../../../../reducers/job/jobSkills";
import {
    getJobProfilesData,
    onChangeSelectedProfile,
    onRemoveUserProfile,
    onAddUserProfile,
    getJobSelectedProfilesData,
} from "../../../../reducers/job/jobProfiles";
import {
    getSuggestedProfilesData,
    getSuggestedProfilesFetchingStatus,
} from "../../../../reducers/profiles/suggestedProfiles";
import {
    getSearchProfilesData,
    fetchGetSearchProfiles,
    cleanSearchProfiles,
    getSearchProfilesFetchingStatus,
} from "../../../../reducers/profiles/searchProfiles";
import {
    getSuggestedSkillsData,
    getSuggestedSkillsFetchingStatus,
} from "../../../../reducers/skillsAndClusters/suggestedSkills";
import {
    getCurrentClusterSkillsData,
    getCurrentClusterSkillsFetchingStatus,
} from "../../../../reducers/skillsAndClusters/currentClusterSkills";
import {
    getSearchSkillsData,
    getSearchClustersData,
    getSearchSkillsAndClustersFetchingStatus,
    onGetSearchSkillsAndClusters,
    cleanSearchSkillsAndClusters,
} from "../../../../reducers/skillsAndClusters/searchSkillsAndClusters";
import {
    showModal,
    MODAL_SKILL_NOTES,
    MODAL_REMOVE_SKILL,
    MODAL_REMOVE_CLUSTER,
    CUSTOM_SKILL_LEVEL_MODAL,
    POTENTIAL_CLUSTERS_MODAL,
} from "../../../../reducers/common/activeModal";
import { getApplicantCurrentClusterId } from "../../../../reducers/applicant/applicantSkills";
import { getUserData } from "../../../../reducers/user/userData";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        mode: SKILLS_SETUP_MODE.JOB,
        imageSource: JOB_IMAGE_PLACEHOLDER,
        user: getUserData(state),

        profiles: getJobProfilesData(state),
        selectedProfiles: getJobSelectedProfilesData(state),
        suggestedProfiles: getSuggestedProfilesData(state),
        searchProfiles: getSearchProfilesData(state),
        loadingSearchProfiles: getSearchProfilesFetchingStatus(state),
        loadingSuggestedProfiles: getSuggestedProfilesFetchingStatus(state),
        skills: getJobSkillsData(state),
        skillsTree: getJobSkillsDataTree(state),
        clusters: getJobClustersData(state),
        searchSkills: getSearchSkillsData(state),
        searchClusters: getSearchClustersData(state),
        emptyClusters: getJobEmptyClustersData(state),
        suggestedSkills: getSuggestedSkillsData(state),
        currentSkillId: getJobCurrentSkillId(state),
        currentSkillData: getJobCurrentSkillData(state),
        currentClusterId: getJobCurrentClusterId(state),
        currentClusterData: getJobCurrentClusterData(state),
        currentClusterSkills: getCurrentClusterSkillsData(state),
        loadingSuggestedSkills: getSuggestedSkillsFetchingStatus(state),
        loadingSearchSkillsAndClusters: getSearchSkillsAndClustersFetchingStatus(state),
        loadingCurrentClusterSkills: getCurrentClusterSkillsFetchingStatus(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onChangeSelectedProfile: (profileId) => dispatch(onChangeSelectedProfile(profileId)),
        onAddProfile: (profile) => dispatch(onAddUserProfile(profile)),
        onRemoveProfile: (profileId) => dispatch(onRemoveUserProfile(profileId)),
        onGetSearchProfiles: (filter) => dispatch(fetchGetSearchProfiles(filter)),
        onCleanSearchProfiles: () => dispatch(cleanSearchProfiles()),
        onGetSearchSkillsAndClusters: (filter) => dispatch(onGetSearchSkillsAndClusters(filter)),
        onCleanSearchSkillsAndClusters: () => dispatch(cleanSearchSkillsAndClusters()),
        onSelectSkill: (skillId, clusterId) => dispatch(onChangeCurrentSkill(skillId, clusterId)),
        onSelectCluster: (clusterId, flags) => dispatch(onChangeCurrentCluster(clusterId, flags)),
        onAddSkill: (skillData) => dispatch(addJobSkill(skillData)),
        onAddEmptyCluster: (clusterData) => dispatch(addEmptyCluster(clusterData)),
        onRemoveSkill: (skillId) => dispatch(removeJobSkill(skillId)),
        onRemoveCluster: (clusterId) => dispatch(removeJobCluster(clusterId)),
        updateSkillProperty: (key, value, mergeObject) =>
            dispatch(updateJobSkillProperty(key, value, mergeObject)),
        onShowSkillNotesModal: () => dispatch(showModal(MODAL_SKILL_NOTES)),
        onShowRemoveSkillModal: () => dispatch(showModal(MODAL_REMOVE_SKILL)),
        onShowRemoveClusterModal: () => dispatch(showModal(MODAL_REMOVE_CLUSTER)),
        onShowCustomSkillLevelModal: () => dispatch(showModal(CUSTOM_SKILL_LEVEL_MODAL)),
        onShowPotentialClustersModal: () => dispatch(showModal(POTENTIAL_CLUSTERS_MODAL)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsSetup);
