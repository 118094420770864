import { connect } from "react-redux";
import { getTranslation } from "../../../../reducers/common/localization";
import JobsFilter from "./JobsFilter";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
    };
};

export default connect(mapStateToProps, null)(JobsFilter);
