import React, { Component } from "react";
import { renderToString } from "react-dom/server";
import PropTypes from "prop-types";
import "./NodesGraph.scss";
import _ from "lodash";
import CytoscapeComponent from "react-cytoscapejs";
import Cytoscape from "cytoscape";
import nodeHtmlLabel from "cytoscape-node-html-label";
import coseBilkent from "cytoscape-cose-bilkent";
import {
    NODE_STATUS_COLOR,
    EDGE_TYPE,
    NODE_TYPE_LIST,
    NODE_TYPE_CLASS_NAME,
    NODE_TYPE_SHORT_LABEL,
    NODE_TYPE,
} from "../../../../constants/systemNodesConstants";
import { Alert, Spinner } from "reactstrap";

Cytoscape.use(nodeHtmlLabel);
Cytoscape.use(coseBilkent);

const edgeColors = {
    [`${NODE_TYPE.PROFILE}-${NODE_TYPE.DOMAIN}`]: "#d9bf24",
    [`${NODE_TYPE.DOMAIN}-${NODE_TYPE.CLUSTER}`]: "#cc2a7b",
    [`${NODE_TYPE.CLUSTER}-${NODE_TYPE.SKILL}`]: "#9fc542",
    [`${NODE_TYPE.SKILL}-${NODE_TYPE.SKILL}`]: "#00acbe",
};

class NodesGraph extends Component {

    state = {
        data: [],
    };

    componentDidMount() {
        if (this.cyRef) {

            this.cyRef.on("select", "node", (event) => {
                let node = event.target.data();

                this.props.onSelectNode(_.toInteger(node.id), node.type);
            });

            this.cyRef.on("unselect", "node", (event) => {
                this.props.onSelectNode(null);
            });

            this.cyRef.nodeHtmlLabel(
                _.map(NODE_TYPE_LIST, (type) => {
                    return {
                        query: `.${NODE_TYPE_CLASS_NAME[type]}`,
                        tpl: (data) => {
                            return renderToString(
                                <span style={{ color: "#ffffff", fontSize: "12px" }}>
                                    {_.get(this.props.translation, NODE_TYPE_SHORT_LABEL[type])}
                                </span>,
                            );
                        },
                    };
                }),
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data });
        }
    }

    getLayout() {
        return {
            // TODO: remove unused layouts
            /*
                name: 'breadthfirst',
                fit: true, // whether to fit the viewport to the graph
                directed: false, // whether the tree is directed downwards (or edges can point in any direction if false)
                padding: 30, // padding on fit
                circle: false, // put depths in concentric circles if true, put depths top down if false
                grid: false, // whether to create an even grid into which the DAG is placed (circle:false only)
                spacingFactor: 1.1, // positive spacing factor, larger => more space between nodes (N.B. n/a if causes overlap)
                boundingBox: undefined, // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
                avoidOverlap: true, // prevents node overlap, may overflow boundingBox if not enough space
                nodeDimensionsIncludeLabels: false, // Excludes the label when calculating node bounding boxes for the layout algorithm
                roots: '.root', // the roots of the trees
                maximal: false, // whether to shift nodes down their natural BFS depths in order to avoid upwards edges (DAGS only)
                animate: false, // whether to transition the node positions
                animationDuration: 500, // duration of animation in ms if enabled
                animationEasing: undefined, // easing of animation if enabled,
                animateFilter: function (node, i) { return true; }, // a function that determines whether the node should be animated.  All nodes animated by default on animate enabled.  Non-animated nodes are positioned immediately when the layout starts
                ready: undefined, // callback on layoutready
                stop: undefined, // callback on layoutstop
                transform: function (node, position) { return position; }, // transform a given node position. Useful for changing flow direction in discrete layouts
            */
            /*
                name: 'cose',
                // Called on `layoutready`
                ready: function () { },
                // Called on `layoutstop`
                stop: function () { },
                // Whether to animate while running the layout
                // true : Animate continuously as the layout is running
                // false : Just show the end result
                // 'end' : Animate with the end result, from the initial positions to the end positions
                // animate: true,
                animate: false,
                // Easing of the animation for animate:'end'
                animationEasing: undefined,
                // The duration of the animation for animate:'end'
                animationDuration: undefined,
                // A function that determines whether the node should be animated
                // All nodes animated by default on animate enabled
                // Non-animated nodes are positioned immediately when the layout starts
                animateFilter: function (node, i) { return true; },
                // The layout animates only after this many milliseconds for animate:true
                // (prevents flashing on fast runs)
                animationThreshold: 250,
                // Number of iterations between consecutive screen positions update
                refresh: 20,
                // Whether to fit the network view after when done
                fit: true,
                // Padding on fit
                padding: 30,
                // Constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
                boundingBox: undefined,
                // Excludes the label when calculating node bounding boxes for the layout algorithm
                nodeDimensionsIncludeLabels: false,
                // Randomize the initial positions of the nodes (true) or use existing positions (false)
                randomize: false,
                // Extra spacing between components in non-compound graphs
                componentSpacing: 40,
                // Node repulsion (non overlapping) multiplier
                nodeRepulsion: function (node) { return 2048; },
                // Node repulsion (overlapping) multiplier
                nodeOverlap: 8,
                // Ideal edge (non nested) length
                // idealEdgeLength: function (edge) { return 32; },
                idealEdgeLength: 200,
                // Divisor to compute edge forces
                // edgeElasticity: function (edge) { return 32; },
                edgeElasticity: 200,
                // Nesting factor (multiplier) to compute ideal edge length for nested edges
                nestingFactor: 1.2,
                // Gravity force (constant)
                gravity: 1,
                // Maximum number of iterations to perform
                numIter: 1000,
                // Initial temperature (maximum node displacement)
                initialTemp: 1000,
                // Cooling factor (how the temperature is reduced between consecutive iterations
                coolingFactor: 0.99,
                // Lower temperature threshold (below this point the layout will end)
                minTemp: 1.0,
            */
            name: "cose-bilkent",
            // Called on `layoutready`
            ready: function() {
            },
            // Called on `layoutstop`
            stop: function() {
            },
            // 'draft', 'default' or 'proof"
            // - 'draft' fast cooling rate
            // - 'default' moderate cooling rate
            // - "proof" slow cooling rate
            quality: "default",
            // Whether to include labels in node dimensions. Useful for avoiding label overlap
            // nodeDimensionsIncludeLabels: false,
            nodeDimensionsIncludeLabels: true,
            // number of ticks per frame; higher is faster but more jerky
            refresh: 30,
            // Whether to fit the network view after when done
            fit: true,
            // Padding on fit
            padding: 10,
            // Whether to enable incremental mode
            randomize: false,
            // Node repulsion (non overlapping) multiplier
            nodeRepulsion: 4500,
            // Ideal (intra-graph) edge length
            idealEdgeLength: 50,
            // Divisor to compute edge forces
            edgeElasticity: 0.45,
            // Nesting factor (multiplier) to compute ideal edge length for inter-graph edges
            nestingFactor: 0.1,
            // Gravity force (constant)
            gravity: 0.25,
            // Maximum number of iterations to perform
            numIter: 2500,
            // Whether to tile disconnected nodes
            tile: true,
            // Type of layout animation. The option set is {'during', 'end', false}
            // animate: 'end',
            animate: "false",
            // Duration for animate:end
            animationDuration: 500,
            // Amount of vertical space to put between degree zero nodes during tiling (can also be a function)
            tilingPaddingVertical: 10,
            // Amount of horizontal space to put between degree zero nodes during tiling (can also be a function)
            tilingPaddingHorizontal: 10,
            // Gravity range (constant) for compounds
            gravityRangeCompound: 1.5,
            // Gravity force (constant) for compounds
            gravityCompound: 1.0,
            // Gravity range (constant)
            gravityRange: 3.8,
            // Initial cooling factor for incremental layout
            initialEnergyOnIncremental: 0.5,
        };
    }

    getStyles() {
        return [
            {
                selector: "node",
                style: {
                    width: (elem) => (elem.selected() ? 37 : 34),
                    height: (elem) => (elem.selected() ? 37 : 34),
                    "background-color": (elem) => elem.selected() ? "#FF0000" : "#999999",
                    "border-color": (elem) =>
                        (() => {
                            switch (elem.data().type) {
                                case NODE_TYPE.CLUSTER:
                                    return "#9fc643";
                                case NODE_TYPE.DOMAIN:
                                    return "#cb2a7b";
                                case NODE_TYPE.SKILL:
                                    return "#00a8b7";
                                case NODE_TYPE.PROFILE:
                                    return "#d9c025";
                                default:
                                    return "#ED8ED6";
                            }
                        })(),
                    "border-width": (elem) => (elem.selected() ? 6 : 3),
                    label: "data(title)",
                    color: "#000000",
                    "text-halign": "center",
                    "text-valign": "bottom",
                    "text-wrap": "wrap",
                    "text-max-width": 150,
                    "text-overflow-wrap": "whitespace",
                    "font-size": "14px",
                    "font-weight": (elem) => (elem.selected() ? "500" : "normal"),
                    "text-margin-y": 10,
                    "text-outline-color": "white",
                    "text-outline-opacity": 1,
                    "text-outline-width": 3,
                    "overlay-opacity": 0,
                },
            },
            {
                selector: "edge",
                style: {
                    width: 2,
                    "curve-style": "bezier",
                    "line-color": (elem) => {
                        if (edgeColors[`${elem.data().sourceType}-${elem.data().targetType}`])
                            return edgeColors[
                                `${elem.data().sourceType}-${elem.data().targetType}`
                                ];
                        else if (edgeColors[`${elem.data().targetType}-${elem.data().sourceType}`])
                            return edgeColors[
                                `${elem.data().targetType}-${elem.data().sourceType}`
                                ];
                        else return "grey";
                    },
                    "line-style": (elem) => {
                        if (
                            elem.data().sourceType === NODE_TYPE.SKILL &&
                            elem.data().targetType === NODE_TYPE.SKILL
                        )
                            return "dashed";
                        return "solid";
                    },
                    "target-arrow-color": "#422C7B",
                    "target-arrow-shape": (elem) => {
                        if (
                            elem.data().sourceType === NODE_TYPE.SKILL &&
                            elem.data().targetType === NODE_TYPE.SKILL
                        )
                            return "triangle";
                        return "none";
                    },
                    "target-arrow-fill": "filled",
                    "overlay-opacity": 0,
                },
            },
            {
                selector: `edge[type = "${EDGE_TYPE.NOT_MODERATED}"]`,
                style: {
                    "line-style": "dashed",
                    "line-dash-pattern": [8, 8],
                },
            },
        ];
    }

    render() {
        return (
            <div className="nodes-graph">
                <CytoscapeComponent className="cytoscape-component"
                                    cy={(cy) => {
                                        this.cyRef = cy;
                                    }}
                                    stylesheet={this.getStyles()}
                                    layout={this.getLayout()}
                                    userZoomingEnabled={true}
                                    boxSelectionEnabled={false}
                                    autounselectify={false}
                                    minZoom={0}
                                    maxZoom={2}
                                    elements={this.state.data} />

                {!this.props.currentNodeId || (!this.props.loading && !this.props.data) ? (
                    <div className="graph-overlay">
                        <Alert>
                            {_.get(this.props.translation, "model_management_page.find_nodes_hint")}
                        </Alert>
                    </div>
                ) : this.props.loading ? (
                    <div className="graph-overlay graph-loader">
                        <Spinner color="secondary" />
                    </div>
                ) : null}
            </div>
        );
    }

}

NodesGraph.propTypes = {
    translation: PropTypes.object.isRequired,
    currentNodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedGraphNode: PropTypes.oneOfType([PropTypes.string, PropTypes.number,PropTypes.any]),
    data: PropTypes.array,
    loading: PropTypes.bool,
    onSelectNode: PropTypes.func.isRequired,
};

export default NodesGraph;
