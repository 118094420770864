import { connect } from 'react-redux';
import ConfirmPasswordFormGroups from './ConfirmPasswordFormGroups';
import { getTranslation } from '../../../../reducers/common/localization';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
    };
};

export default connect(mapStateToProps)(ConfirmPasswordFormGroups);