import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Header.scss";
import { Link, NavLink as RRDNavLink, withRouter } from "react-router-dom";
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Nav,
    Collapse,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavItem,
    NavLink,
} from "reactstrap";
import Logo from "./../../../images/logo.png";
import Mantra from "./../../../images/mantra.png";
import { ROUTES } from "../../../constants/constants";
import _ from "lodash";
import LanguageSelect from "./languageSelect/LanguageSelect.container";
import Icon from "../../common/icon/Icon";
import SecondaryNavBar from "./secondaryNavBar/SecondaryNavBar.container";
import NavItemManager from "./navItemsManager/NavItemsManager.container";

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: true,
        };
    }

    render() {
        return (
            <>
                <Navbar
                    className={`header ${this.props.loadingUserData ? "user-data-loading" : ""} ${
                        !this.props.loadingUserData && this.props.isAuthorized
                            ? "user-authorized"
                            : "user-unauthorized"
                    }`}
                    color="dark"
                    dark
                    expand="md"
                >
                    <NavbarBrand
                        className="logo-container"
                        tag={Link}
                        to={this.props.isAuthorized ? ROUTES.HOME : ROUTES.LOGIN}
                    >
                        <img className="logo" alt="HF1" src={Logo} />
                    </NavbarBrand>
                    {this.renderNavMenus()}
                    {/* <Nav className="language-select-container">
                        <LanguageSelect />
                    </Nav> */}
                    <NavbarToggler className="header-toggler" onClick={this.handleToggleCollapse} />
                </Navbar>
                {this.props.isAuthorized ? (
                    <SecondaryNavBar location={this.props.location} />
                ) : null}
            </>
        );
    }

    renderNavMenus() {
        if (!this.props.isServerError && !this.props.loadingUserData) {
            return (
                <>
                    {this.renderMainMenu()}
                    {this.renderUserMenu()}
                    {this.renderUnauthorizedMenu()}
                </>
            );
        }
    }

    renderMainMenu() {
        if (this.props.isAuthorized && this.props.location.pathname !== ROUTES.HOME) {
            return (
                <Collapse className="header-collapse" isOpen={true} navbar>
                    <Nav navbar>
                        <NavItemManager />
                    </Nav>
                </Collapse>
            );
        } else {
            return (
                <div className="mantra-container">
                    <img className="mantra" alt="" src={Mantra} />
                </div>
            );
        }
    }

    renderUserMenu() {
        if (this.props.isAuthorized) {
            return (
                <Nav className="ml-auto">
                    <UncontrolledDropdown nav inNavbar className="user-menu-dropdown">
                        <DropdownToggle nav caret>
                            <Icon className="user-icon" type="person" color="light" />
                            <span className="user-email" title={this.props.userEmail}>
                                {this.props.userEmail}
                            </span>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem header>{this.props.userEmail}</DropdownItem>
                            <DropdownItem tag={Link} to={ROUTES.PERSONAL_SETTINGS}>
                                {_.get(this.props.translation, "account_page.menu_label")}
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={this.onLogOut}>
                                {_.get(this.props.translation, "login_page.menu_logout_label")}
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            );
        }
    }

    renderUnauthorizedMenu() {
        if (!this.props.isAuthorized) {
            return (
                <Nav className="ml-auto unauthorized-menu" navbar>
                    <NavItem>
                        <NavLink
                            className="header-link"
                            tag={RRDNavLink}
                            to={ROUTES.SUPPORT}
                            activeClassName="active"
                        >
                            {_.get(this.props.translation, "restore_account_page.menu_label")}
                        </NavLink>
                    </NavItem>
                </Nav>
            );
        }
    }

    handleToggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    onLogOut = () => {
        this.props.onLogOut();
    };
}

Header.propTypes = {
    translation: PropTypes.object.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
    isServerError: PropTypes.bool,
    userEmail: PropTypes.string,
    loadingUserData: PropTypes.bool,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onLogOut: PropTypes.func.isRequired,
};

export default withRouter(Header);
