import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ResendActivation.scss';
import _ from 'lodash';
import BackLink from '../../../common/buttons/backLink/BackLink.container';
import { ROUTES } from '../../../../constants/constants';
import { Button, Form, FormGroup, Input } from 'reactstrap';

class ResendActivation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.success && prevProps.success !== this.props.success) {
            this.setState({ email: "" });
        }
    }

    render() {
        return (
            <div className="resend-activation">
                <div className="back-link-container">
                    <BackLink to={ROUTES.LOGIN} label={_.get(this.props.translation, 'login_page.back_to_login_page_label')} />
                </div>
                <div className="resend-activation-form">
                    <h5 className="text-center">{_.get(this.props.translation, 'resend_activation_page.page_label')}</h5>
                    <Form onSubmit={this.onResendActivation}>
                        <FormGroup>
                            <Input
                                type="email"
                                value={this.state.email}
                                onChange={this.handleChangeEmail}
                                required
                                placeholder={_.get(this.props.translation, 'person_data.email_label')}
                            />
                        </FormGroup>
                        <Button type="submit" block color="primary" disabled={_.isEmpty(this.state.email)}>
                            {_.get(this.props.translation, 'resend_activation_page.submit_button')}
                        </Button>
                    </Form>
                </div>
            </div>
        );
    }

    handleChangeEmail = (e) => {
        this.setState({ email: e.target.value });
    };

    onResendActivation = (e) => {
        e.preventDefault();

        this.props.onResendActivation({ email: this.state.email });
    }
}

ResendActivation.propTypes = {
    translation: PropTypes.object.isRequired,
    success: PropTypes.bool,
    onResendActivation: PropTypes.func.isRequired
};

export default ResendActivation;
