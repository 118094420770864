import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './FFMChart.scss';
import _ from "lodash";

export const FFM_CHART_MODE = {
    VIEW_DATA: "VIEW_DATA",
    VIEW_REQUIREMENTS: "VIEW_REQUIREMENTS",
    COMPARE: "COMPARE"
}

export const FFM_AXIS = {
    CONSCIENTIOUSNESS: 0,
    EXTROVERSION: 1,
    AGREEABLENESS: 2,
    OPENNESS_TO_EXPERIENCE: 3,
    NEUROTICISM: 4
}

export const MIN_FFX_VALUE = 1;
export const MAX_FFX_VALUE = 5;

export const NORMAL_FFX_VALUE = 3;

export const POINTS = {
    CENTER: { x: 250, y: 250 },
    CONSCIENTIOUSNESS: { x: 250, y: 0 },
    EXTROVERSION: { x: 487.76, y: 172.75 },
    AGREEABLENESS: { x: 397, y: 452.25 },
    OPENNESS_TO_EXPERIENCE: { x: 103, y: 452.25 },
    NEUROTICISM: { x: 12.24, y: 172.75 },
    MIN: { x: 0, y: 0 },
    MAX: { x: 500, y: 500 },
};

export const CHART_SIZE = 500;

export const AXIS_LENGTH = 250;
export const AXIS_ANGLE = 72;

export const MATCH_LEVEL = {
    NOT_MATCH: 0,
    LOW_MATCH: 1,
    PERFECT_MATCH: 2,
}

export const LOW_MATCH_TOLERANCE = 1;

export const MATCH_LEVEL_CLASS_NAME = {
    [MATCH_LEVEL.NOT_MATCH]: "not-match",
    [MATCH_LEVEL.LOW_MATCH]: "low-match",
    [MATCH_LEVEL.PERFECT_MATCH]: "perfect-match",
}

// NOTE: min value (0%) = 1, max value (100%) = 5, middle (50%) = 3
// From 0 to 1 - empty area

class FFMChart extends Component {
    render() {
        return (
            <div className="ffm-chart">
                <svg
                    width="100%"
                    height="100%"
                    viewBox="-80 -80 640 580"
                >
                    {this.renderChartBackground()}
                    {this.renderAxes()}
                    {this.getNormalPolygon()}
                    {
                        this.props.mode === FFM_CHART_MODE.VIEW_REQUIREMENTS || this.props.mode === FFM_CHART_MODE.COMPARE ?
                            this.getRequirementsRanges()
                            : null
                    }
                    {
                        this.props.mode === FFM_CHART_MODE.VIEW_DATA || this.props.mode === FFM_CHART_MODE.COMPARE ?
                            this.getActualPolygon()
                            : null
                    }
                </svg>
            </div>
        );
    }

    renderChartBackground() {
        return (
            <g>
                <polygon
                    className="ffm-background"
                    points={this.getAllAxesPolygonPoints(_.fill(Array(5), MAX_FFX_VALUE))}
                />
                <polygon
                    className="ffm-empty-area"
                    points={this.getAllAxesPolygonPoints(_.fill(Array(5), MIN_FFX_VALUE))}
                />
            </g>
        )
    }

    renderAxes() {
        const ICON_SIZE = 36;
        const MARGIN = 14;

        return (
            <g>
                <g className="ffm-axes" stroke="#c6c6c6" strokeWidth="2">
                    <line x1={POINTS.CONSCIENTIOUSNESS.x} y1={POINTS.CONSCIENTIOUSNESS.y} x2={POINTS.CENTER.x} y2={POINTS.CENTER.y} />
                    <line x1={POINTS.EXTROVERSION.x} y1={POINTS.EXTROVERSION.y} x2={POINTS.CENTER.x} y2={POINTS.CENTER.y} />
                    <line x1={POINTS.AGREEABLENESS.x} y1={POINTS.AGREEABLENESS.y} x2={POINTS.CENTER.x} y2={POINTS.CENTER.y} />
                    <line x1={POINTS.OPENNESS_TO_EXPERIENCE.x} y1={POINTS.OPENNESS_TO_EXPERIENCE.y} x2={POINTS.CENTER.x} y2={POINTS.CENTER.y} />
                    <line x1={POINTS.NEUROTICISM.x} y1={POINTS.NEUROTICISM.y} x2={POINTS.CENTER.x} y2={POINTS.CENTER.y} />
                </g>
                <g className="ffm-axes-titles" fill="purple" fontWeight="600" fontSize="34" cursor="pointer">
                    <svg x={POINTS.CONSCIENTIOUSNESS.x - ICON_SIZE / 2} y={POINTS.CONSCIENTIOUSNESS.y - ICON_SIZE - MARGIN} width={ICON_SIZE} height={ICON_SIZE} viewBox="0 0 48 48">
                        <title>
                            {_.get(this.props.translation, "five_factor_model.conscientiousness_label")}
                        </title>
                        <path d="M48,48H0V0H48Z" fill="rgba(0, 0, 0, 0)" />
                        <path d="M39.79,31.81q0,4-1.37,5.41t-5.35,1.43H16.89q-3.93,0-5.35-1.43t-1.43-5.41V16.58q0-4,1.43-5.4t5.35-1.43H33.07c3.29,0,5.32.84,6.11,2.52q.62,1.35.61,7.06L35,20.22V13.67h-20V34.61H35V27.39l4.81,1Z" />
                    </svg>
                    <svg x={POINTS.MAX.x} y={POINTS.EXTROVERSION.y - ICON_SIZE / 3 * 2} width={ICON_SIZE} height={ICON_SIZE} viewBox="0 0 48 48">
                        <title>
                            {_.get(this.props.translation, "five_factor_model.extroversion_label")}
                        </title>
                        <path d="M48,48H0V0H48Z" fill="rgba(0, 0, 0, 0)" />
                        <path d="M39.59,38.84H11.53V9.94H39.37v3.87h-23v8.06H30.41v3.81H16.35v9.18H39.59Z" />
                    </svg>
                    <svg x={POINTS.AGREEABLENESS.x + MARGIN / 2 * 3} y={POINTS.AGREEABLENESS.y - ICON_SIZE / 3 * 2} width={ICON_SIZE} height={ICON_SIZE} viewBox="0 0 48 48">
                        <title>
                            {_.get(this.props.translation, "five_factor_model.agreeableness_label")}
                        </title>
                        <path d="M48,48H0V0H48Z" fill="rgba(0, 0, 0, 0)" />
                        <path d="M42.31,38.84H37l-3.7-7.11H13.75l-3.7,7.11H5.29L21.08,9.94h5.15ZM31.33,28,23.54,13.25,15.76,28Z" />
                    </svg>
                    <svg x={POINTS.OPENNESS_TO_EXPERIENCE.x - ICON_SIZE - MARGIN / 2 * 3} y={POINTS.OPENNESS_TO_EXPERIENCE.y - ICON_SIZE / 3 * 2} width={ICON_SIZE} height={ICON_SIZE} viewBox="0 0 48 48">
                        <title>
                            {_.get(this.props.translation, "five_factor_model.openness_to_experience_label")}
                        </title>
                        <path d="M48,48H0V0H48Z" fill="rgba(0, 0, 0, 0)" />
                        <path d="M40.51,32c0,2.65-.46,4.45-1.4,5.4s-2.7,1.43-5.32,1.43h-20q-3.92,0-5.35-1.43T7,32V16.78c0-2.65.48-4.46,1.43-5.41s2.74-1.43,5.35-1.43h20q3.93,0,5.32,1.43c.94,1,1.4,2.76,1.4,5.41Zm-4.76,2.8v-21H11.84v21Z" />
                    </svg>
                    <svg x={POINTS.NEUROTICISM.x - ICON_SIZE - MARGIN} y={POINTS.NEUROTICISM.y - ICON_SIZE / 3 * 2} width={ICON_SIZE} height={ICON_SIZE} viewBox="0 0 48 48">
                        <title>
                            {_.get(this.props.translation, "five_factor_model.neuroticism_label")}
                        </title>
                        <path d="M48,48H0V0H48Z" fill="rgba(0, 0, 0, 0)" />
                        <path d="M39.76,38.84H37.07L14.84,18.74a30.8,30.8,0,0,1-2.92-3.25,45.31,45.31,0,0,1,.34,5.93V38.84H7.84V9.94h3L32.48,29.26a28.58,28.58,0,0,1,3.3,3.64,39.56,39.56,0,0,1-.45-6.44V9.94h4.43Z" />
                    </svg>
                </g>
            </g>
        )
    }

    getNormalPolygon() {
        let arrayValues = _.fill(Array(5), NORMAL_FFX_VALUE);
        let arrayPoints = this.getAllAxesPointsCoordinates(arrayValues);
        return (
            <g>
                <polygon
                    className="ffm-normal-polygon"
                    points={this.getAllAxesPolygonPoints(arrayValues)}
                />
                {
                    _.map(arrayPoints, (point, id) => {
                        return (
                            <circle
                                key={id}
                                className="ffm-normal-point"
                                cx={point.x}
                                cy={point.y}
                                r={7}
                            />
                        );
                    })

                }
            </g>
        )
    }

    getRequirementsRanges() {
        const ALFA = 0.15;
        let requirementsArray = _.map(this.props.requirementsData, (data, id) => {
            data.minPoint = this.getAxisPointCoordinate(data.min - ALFA, id);
            data.maxPoint = this.getAxisPointCoordinate(data.max + ALFA, id);

            if (this.props.mode === FFM_CHART_MODE.COMPARE) {
                let level;
                if (this.props.actualData[id] >= this.props.requirementsData[id].min && this.props.actualData[id] <= this.props.requirementsData[id].max) {
                    level = MATCH_LEVEL.PERFECT_MATCH;
                } else if ((this.props.actualData[id] >= this.props.requirementsData[id].min - LOW_MATCH_TOLERANCE && this.props.actualData[id] <= this.props.requirementsData[id].max + LOW_MATCH_TOLERANCE)) {
                    level = MATCH_LEVEL.LOW_MATCH;
                } else {
                    level = MATCH_LEVEL.NOT_MATCH;
                }
                data.level = level;
            }

            return data;
        });

        return (
            <g>
                {this.getRangesLinesMarkers()}
                {
                    _.map(requirementsArray, (data, id) => {
                        return (
                            <line
                                key={id}
                                className={`range-line ${MATCH_LEVEL_CLASS_NAME[data.level]}`}
                                x1={data.minPoint.x}
                                y1={data.minPoint.y}
                                x2={data.maxPoint.x}
                                y2={data.maxPoint.y}
                                markerStart={!_.isNil(data.level) ? `url(#range-marker-${MATCH_LEVEL_CLASS_NAME[data.level]})` : `url(#range-marker-default)`}
                                markerEnd={!_.isNil(data.level) ? `url(#range-marker-${MATCH_LEVEL_CLASS_NAME[data.level]})` : `url(#range-marker-default)`}
                            />
                        )
                    })
                }
            </g>
        );
    }

    getRangesLinesMarkers() {
        return (
            <g>
                <marker
                    id={`range-marker-default`}
                    className={`range-marker-default`}
                    viewBox="0 0 10 10"
                    refX="2"
                    refY="5"
                    markerWidth="20"
                    markerHeight="20"
                    markerUnits="userSpaceOnUse"
                    orient="auto-start-reverse">
                    <path d="M 0 0 L 4 0 L 4 10 L 0 10 z" />
                </marker>
                {
                    _.map(_.mapValues(MATCH_LEVEL), (level, id) => {
                        return (
                            <marker
                                key={id}
                                id={`range-marker-${MATCH_LEVEL_CLASS_NAME[level]}`}
                                className={`range-marker-${MATCH_LEVEL_CLASS_NAME[level]}`}
                                viewBox="0 0 10 10"
                                refX="2"
                                refY="5"
                                markerWidth="20"
                                markerHeight="20"
                                markerUnits="userSpaceOnUse"
                                orient="auto-start-reverse">
                                <path d="M 0 0 L 4 0 L 4 10 L 0 10 z" />
                            </marker>
                        );
                    })
                }
            </g>
        )
    }

    getActualPolygon() {
        let arrayValues = this.props.actualData;
        let arrayPoints = this.getAllAxesPointsCoordinates(arrayValues);
        return (
            <g>
                <polygon
                    className="ffm-actual-polygon"
                    points={this.getAllAxesPolygonPoints(arrayValues)}
                />
                {
                    _.map(arrayPoints, (point, id) => {
                        return (
                            <circle
                                key={id}
                                className="ffm-actual-point"
                                cx={point.x}
                                cy={point.y}
                                r={7}
                            />
                        );
                    })

                }
            </g>
        )
    }

    getPolygonPoints(arrayPoints) {
        return _.reduce(arrayPoints, (total, point) => {
            return total + "" + point.x + "," + point.y + " ";
        }, "");
    }

    getAllAxesPolygonPoints(arrayValues) {
        return this.getPolygonPoints(this.getAllAxesPointsCoordinates(arrayValues));
    }

    getAllAxesPointsCoordinates(arrayValues) {
        return _.map(arrayValues, (value, id) => {
            return this.getAxisPointCoordinate(value, id);
        });
    }

    getAxisPointCoordinate(value, axis) {
        let resultPoint = {
            x: 0,
            y: 0
        }

        if (value < MIN_FFX_VALUE) {
            value = MIN_FFX_VALUE;
        } else if (value > MAX_FFX_VALUE) {
            value = MAX_FFX_VALUE;
        }

        let length = AXIS_LENGTH / MAX_FFX_VALUE * value;
        let side = 1.176 * length;
        let radius = 0.809 * length;
        switch (axis) {
            case FFM_AXIS.CONSCIENTIOUSNESS:
                resultPoint.x = AXIS_LENGTH;
                resultPoint.y = AXIS_LENGTH - length;
                break;

            case FFM_AXIS.EXTROVERSION:
                resultPoint.x = AXIS_LENGTH + length * Math.sin(AXIS_ANGLE * (Math.PI / 180));
                resultPoint.y = AXIS_LENGTH - length * Math.cos(AXIS_ANGLE * (Math.PI / 180));
                break;

            case FFM_AXIS.AGREEABLENESS:
                resultPoint.x = AXIS_LENGTH + side / 2;
                resultPoint.y = AXIS_LENGTH + radius;
                break;

            case FFM_AXIS.OPENNESS_TO_EXPERIENCE:
                resultPoint.x = AXIS_LENGTH - side / 2;
                resultPoint.y = AXIS_LENGTH + radius;
                break;

            case FFM_AXIS.NEUROTICISM:
                resultPoint.x = AXIS_LENGTH - length * Math.sin(AXIS_ANGLE * (Math.PI / 180));
                resultPoint.y = AXIS_LENGTH - length * Math.cos(AXIS_ANGLE * (Math.PI / 180));
                break;

            default:
                break;
        }
        return resultPoint;
    }
}

FFMChart.propTypes = {
    translation: PropTypes.object.isRequired,
    mode: PropTypes.string.isRequired,
    actualData: PropTypes.array,
    requirementsData: PropTypes.array
};

export default FFMChart;
