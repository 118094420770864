import { connect } from 'react-redux';
import SkillsValidationModal from './SkillsValidationModal';
import { getTranslation } from '../../../../../reducers/common/localization';

const mapStateToProps = state => {
	return {
		translation: getTranslation(state),
	};
};

export default connect(mapStateToProps, null)(SkillsValidationModal)
