import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import { fetchAlternativeProfiles } from './jobCompassProfiles';

//- Actions
export const fetchGetJobCompassSettingsFailed = createAction('FETCH_GET_JOB_COMPASS_SETTINGS_FAILED');
export const fetchGetJobCompassSettingsSuccess = createAction('FETCH_GET_JOB_COMPASS_SETTINGS_SUCCESS');
export const fetchGetJobCompassSettings = createAction(
	'FETCH_GET_JOB_COMPASS_SETTINGS',
	() => Api.getJobCompassSettings(),
	() => ({
		apiCall: true,
		onSuccess: fetchGetJobCompassSettingsSuccess,
		onFail: fetchGetJobCompassSettingsFailed
	})
);

export const setJobCompassSettingsFailed = createAction('SET_JOB_COMPASS_SETTINGS_FAILED');
export const setJobCompassSettingsSuccess = createAction('SET_JOB_COMPASS_SETTINGS_SUCCESS');
export const onSetJobCompassSettingsSuccessSuccess = (data) => (dispatch) => {
	dispatch(setJobCompassSettingsSuccess(data));
	dispatch(fetchAlternativeProfiles());
};

export const setJobCompassSettings = createAction(
	'SET_JOB_COMPASS_SETTINGS',
	(data) => Api.setJobCompassSettings(data),
	() => ({
		apiCall: true,
		onSuccess: onSetJobCompassSettingsSuccessSuccess,
		onFail: setJobCompassSettingsFailed
	})
);
//- State
const initialState = {
	settings: {},
	error: null,
	fetchingSettings: false,
	fetchingUpdSettings: false,

};

//- Reducers
export default handleActions({
	FETCH_GET_JOB_COMPASS_SETTINGS: (state) => {
		return { ...state, settings: {}, error: null, fetchingSettings: true };
	},
	FETCH_GET_JOB_COMPASS_SETTINGS_FAILED: (state, action) => {
		return { ...state, settings: {}, error: action.payload, fetchingSettings: false };
	},
	FETCH_GET_JOB_COMPASS_SETTINGS_SUCCESS: (state, action) => {
		return { ...state, settings: action.payload, error: null, fetchingSettings: false };
	},
	SET_JOB_COMPASS_SETTINGS: (state) => {
		return { ...state, error: null, fetchingUpdSettings: true };
	},
	SET_JOB_COMPASS_SETTINGS_FAILED: (state, action) => {
		return { ...state, error: action.payload, fetchingUpdSettings: false };
	},
	SET_JOB_COMPASS_SETTINGS_SUCCESS: (state, action) => {
		return { ...state, settings: action.payload, error: null, fetchingUpdSettings: false };
	},
	CLEAR_JOB_COMPASS_ERROR: (state) =>
		({ ...state, error: null }),
}, initialState);

//- Selectors
export const getJobCompassSettingsState = state => state.jobCompassSettings;

export const getJobCompassSettings = state => getJobCompassSettingsState(state).settings;

export const getJobCompassSettingsLoading = state =>
	getJobCompassSettingsState(state).fetchingSettings || getJobCompassSettingsState(state).fetchingUpdSettings;

export const getJobCompassSettingsError = state => getJobCompassSettingsState(state).error;


