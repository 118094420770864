import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import { setRequestStatusFailed, onSetRequestStatusSuccess, setRequestStatusSubmit } from '../common/requestStatus';
import { REQUEST_SUCCESS_MESSAGE } from '../../constants/requestStatusConstants';

//- Actions
export const fetchAccountToActivateDataSuccess = createAction('FETCH_ACCOUNT_TO_ACTIVATE_DATA_SUCCESS');
export const fetchAccountToActivateDataFailed = createAction('FETCH_ACCOUNT_TO_ACTIVATE_DATA_FAILED');
export const fetchAccountToActivateData = createAction(
    'FETCH_ACCOUNT_TO_ACTIVATE_DATA',
    (token) => Api.getAccountDataByToken(token),
    () => ({
        apiCall: true,
        onSuccess: fetchAccountToActivateDataSuccess,
        onFail: fetchAccountToActivateDataFailed,
        ignoreUnauthorized: true,
        ignoreForbidden: true
    })
);

export const fetchActivateAccount = (data, token) => (dispatch) => {
    dispatch(setRequestStatusSubmit());
    dispatch(onFetchActivateAccount(data, token));
}
export const onFetchActivateAccount = createAction(
    'FETCH_ACTIVATE_ACCOUNT',
    (data, token) => Api.activateAccount(data, token),
    () => ({
        apiCall: true,
        onSuccess: onSetRequestStatusSuccess(REQUEST_SUCCESS_MESSAGE.NO_MESSAGE),
        onFail: setRequestStatusFailed,
        ignoreUnauthorized: true,
        ignoreForbidden: true
    })
);

export const fetchResendAccountActivation = (data) => (dispatch) => {
    dispatch(setRequestStatusSubmit());
    dispatch(onFetchResendAccountActivation(data));
}
export const onFetchResendAccountActivation = createAction(
    'FETCH_RESEND_ACCOUNT_ACTIVATION',
    (data) => Api.resendActivation(data),
    () => ({
        apiCall: true,
        onSuccess: onSetRequestStatusSuccess(REQUEST_SUCCESS_MESSAGE.RESEND_ACTIVATION),
        onFail: setRequestStatusFailed,
        ignoreUnauthorized: true,
        ignoreForbidden: true
    })
);

//- State
const initialState = {
    data: null,
    fetching: false,
    error: null
};

//- Reducers
export default handleActions({

    FETCH_ACCOUNT_TO_ACTIVATE_DATA: (state) => {
        return { ...state, data: null, error: null, fetching: true };
    },
    FETCH_ACCOUNT_TO_ACTIVATE_DATA_FAILED: (state, action) => {
        return { ...state, data: null, error: action.payload, fetching: false };
    },
    FETCH_ACCOUNT_TO_ACTIVATE_DATA_SUCCESS: (state, action) => {
        return { ...state, data: action.payload, error: null, fetching: false };
    },

}, initialState);


//- Selectors
export const getActivationState = state => state.activation;

export const getAccountToActivateData = state => getActivationState(state).data;

export const getAccountToActivateFetchingStatus = state => getActivationState(state).fetching;

export const getAccountToActivateError = state => getActivationState(state).error;
