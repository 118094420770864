import { connect } from 'react-redux';
import RequestStatus from "./RequestStatus";
import { getTranslation } from "../../../reducers/common/localization";
import {
    getRequestFetchingStatus,
    getRequestSuccessStatus,
    getRequestErrorStatus,
    resetRequestStatusData,
    getRequestSuccessMessage,
    getRequestErrorMessage
} from '../../../reducers/common/requestStatus';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        loading: getRequestFetchingStatus(state),
        success: getRequestSuccessStatus(state),
        successMessage: getRequestSuccessMessage(state),
        error: getRequestErrorStatus(state),
        errorMessage: getRequestErrorMessage(state),
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onResetRequestStatus: () => dispatch(resetRequestStatusData()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(RequestStatus);
