import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalFooter, ModalBody, UncontrolledPopover } from 'reactstrap';
import _ from 'lodash';
import { APPLICANT_INFO_MODAL_TAB_TYPE } from '../applicantQuickfindInfoModal/ApplicantQuickfindInfoModal';
import Icon from '../../common/icon/Icon';
import './ApplicantQuickComparison.scss';
import ApplicantQuickfindInfoModal from '../applicantQuickfindInfoModal/ApplicantQuickfindInfoModal.container';
import HardSkillsComparison from '../../common/quickfindHardSkillsComparison/HardSkillsComparison.container';

class ApplicantQuickComparison extends Component {
	constructor(props) {
		super(props);

		this.state = {
			crossJobInfoType: null,
			isModalVisible: false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.applicantId !== this.props.applicantId && this.props.applicantId) {
			this.props.onFetchApplicantById(this.props.applicantId);
		}
	}

	handleOpenInfoModal = (type) => () => {
		this.setState({ isModalVisible: true, crossJobInfoType: type });
	};

	renderMediaButtons() {
		const { applicantData, translation } = this.props;

		if (_.isEmpty(applicantData)) {
			return null;
		}

		return (
			<div className="pane-buttons-container with-bottom-margin">
				<div className="pane-button pane-title">
					{_.get(translation, 'job_and_candidates.profile_label')}
				</div>
				<div
					className="pane-button"
					onClick={this.handleOpenInfoModal(APPLICANT_INFO_MODAL_TAB_TYPE.MOVIE)}
				>
					<Icon type="movie" size="sm" />
				</div>
				<div
					className="pane-button"
					onClick={this.handleOpenInfoModal(APPLICANT_INFO_MODAL_TAB_TYPE.CV)}
				>
					<Icon type="cv" size="sm" />
				</div>
			</div>
		);
	}

	renderContactsButtons() {
		const { applicantData, translation } = this.props;

		if (_.isEmpty(applicantData)) {
			return null;
		}

		return (
			<div className="pane-buttons-container">
				<div className="pane-button pane-title">
					{_.get(translation, 'job_and_candidates.contacts_label')}
				</div>
				<div
					className="pane-button with-link"
					title={_.get(translation, 'job.send_email_label')}
				>
					<a className="pane-button-link" href={`mailto:${applicantData.email}`}>
						<Icon type="email" size="sm" />
					</a>
				</div>
				<div
					className="pane-button"
					id="contact-button-phone-number"
				>
					<Icon type="phone" size="sm" />
					<UncontrolledPopover
						trigger="hover"
						placement="left"
						target="contact-button-phone-number"
						className="phone-number-popover"
					>
						<div className="phone-number">
							{applicantData.alias.phoneNumber}
						</div>
					</UncontrolledPopover>
				</div>
			</div>
		);
	}

	render() {
		const { isOpen, onCloseModal, translation, loading, applicantData } = this.props;

		const { crossJobInfoType, isModalVisible } = this.state;

		return (
			<>
				<Modal
					centered
					isOpen={isOpen}
					toggle={onCloseModal}
				>
					<ModalBody>
						{
							loading ? (
								<h6 className="page-message">{_.get(translation, "actions.fetching_message")}</h6>
							) : (
								<div className="modal-content-wrapper">
									<HardSkillsComparison data={applicantData} />
									<div className="buttons-pane candidate-pane">
										{this.renderMediaButtons()}
										{this.renderContactsButtons()}
									</div>
								</div>
							)
						}
					</ModalBody>
					<ModalFooter>
						<Button
							color="primary"
							onClick={onCloseModal}
						>
							{_.get(translation, "actions.cancel_button")}
						</Button>
					</ModalFooter>
				</Modal>
				<ApplicantQuickfindInfoModal
					activeTab={crossJobInfoType}
					isOpen={isModalVisible}
					onCancel={() => this.setState({ isModalVisible: false, crossJobInfoType: null })}
				/>
			</>
		)
	}
}

ApplicantQuickComparison.propTypes = {
	translation: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onCloseModal: PropTypes.func.isRequired,
	onFetchApplicantById: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	applicantData: PropTypes.object.isRequired,
	applicantId: PropTypes.string.isRequired,
};

export default ApplicantQuickComparison;
