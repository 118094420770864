import { connect } from 'react-redux';
import AddStaffMemberModal from "./AddStaffMemberModal";
import { getTranslation } from '../../../../../reducers/common/localization';
import { getActiveModalType, MODAL_ADD_STAFF_MEMBER, hideModal } from '../../../../../reducers/common/activeModal';
import { onFetchRegisterStaffMember } from '../../../../../reducers/organization/staffMembers';

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_ADD_STAFF_MEMBER,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => dispatch(hideModal()),
        onAddStaffMember: (data) => dispatch(onFetchRegisterStaffMember(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStaffMemberModal);
