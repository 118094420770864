import { connect } from 'react-redux';
import JobQuickComparison from './JobQuickComparison';
import { getTranslation } from '../../../reducers/common/localization';
import {
	onFetchJobById,
	onCloseJobQuickCompareModal,
	getJobComparisonModalVisibility,
	getFetchingJobById,
	getSelectedJobId,
	getJobData
} from '../../../reducers/quickfind/jobComparison';

const mapStateToProps = state => ({
	translation: getTranslation(state),
	isOpen: getJobComparisonModalVisibility(state),
	loading: getFetchingJobById(state),
	selectedJobId: getSelectedJobId(state),
	jobData: getJobData(state),
});

const mapDispatchToProps = dispatch => ({
	onFetchJobById: (jobId) => dispatch(onFetchJobById(jobId)),
	onClose: () => dispatch(onCloseJobQuickCompareModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobQuickComparison);
