import { connect } from 'react-redux';
import NoProfilesModal from "./NoProfilesModal";
import { getTranslation } from '../../../../reducers/common/localization';
import { getActiveModalType, MODAL_NO_PROFILES, hideModal } from '../../../../reducers/common/activeModal';

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_NO_PROFILES
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(hideModal());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoProfilesModal);
