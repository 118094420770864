import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup,
    Form,
    Row,
    Label,
    Input,
    Col,
    Spinner,
} from "reactstrap";

import { withRouter } from "react-router-dom";
import { ROUTES } from "../../../../constants/constants";

const initialState = {
    employer: "",
    jobTitle: "",
    startedAt: "",
    endedAt: null,
    workingUntillNow: false,
    whyTookJob: "",
    whatLearned: "",
};
class AddNewPastJobModal extends Component {
    constructor(props) {
        super(props);

        this.state = { ...initialState, isJobAdded: false };
    }

    handleChange({ type, checked, name, value }) {
        if (name === "workingUntillNow" && checked) {
            this.setState({ endedAt: null });
        }
        const realValue = type === "checkbox" ? checked : value;
        this.setState({ [name]: realValue });
    }

    handleSubmit = () => {
        const { onFetchAddApplicantPastJobSuccess } = this.props;
        const { employer, jobTitle, startedAt, endedAt, whyTookJob, whatLearned } = this.state;
        onFetchAddApplicantPastJobSuccess({
            employer,
            jobTitle,
            startedAt: new Date(startedAt).toISOString(),
            endedAt: !endedAt ? null : new Date(endedAt).toISOString(),
            whyTookJob,
            whatLearned,
            onSuccessCallback: () => this.setState({ ...initialState, isJobAdded: true }),
        });
    };

    renderModalButtons() {
        const { translation, pastJobsData, onClose } = this.props;
        if (pastJobsData.fetchingAddPastJob) {
            return <Spinner size="sm" color="secondary" />;
        } else if (!pastJobsData.fetchingAddPastJob && this.state.isJobAdded) {
            return (
                <>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.props.history.push(ROUTES.EDIT_APPLICANT_SKILLS);
                            onClose();
                        }}
                    >
                        {_.get(translation, "skills_management.edit_skills")}
                    </Button>
                    <Button
                        color="danger"
                        onClick={() => {
                            onClose();
                            this.setState({ isJobAdded: false });
                        }}
                    >
                        {_.get(translation, "actions.cancel_button")}
                    </Button>
                </>
            );
        } else {
            return (
                <Button color="primary" onClick={this.handleSubmit}>
                    {_.get(translation, "skills_journey_page.add_past_job")}
                </Button>
            );
        }
    }

    render() {
        const { translation, isOpen, onClose } = this.props;
        const {
            employer,
            jobTitle,
            startedAt,
            endedAt,
            workingUntillNow,
            whyTookJob,
            whatLearned,
        } = this.state;

        return (
            <Modal
                isOpen={isOpen}
                toggle={() => {
                    onClose();
                    this.setState({ isJobAdded: false });
                }}
            >
                <ModalHeader toggle={this.props.onClose} tag="h3">
                    {_.get(translation, "skills_journey_page.add_new_past_job_modal_header")}
                </ModalHeader>
                <ModalBody>
                    <Form onChange={({ target }) => this.handleChange(target)}>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="employer">
                                    {_.get(translation, "shared.employer")}
                                </Label>
                                <Input value={employer} type="text" id="employer" name="employer" />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="jobTitle">
                                    {_.get(translation, "job.job_title_label")}
                                </Label>
                                <Input value={jobTitle} type="text" id="jobTitle" name="jobTitle" />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="startedAt">
                                            {_.get(translation, "shared.from")}
                                        </Label>
                                        <Input
                                            type="date"
                                            name="startedAt"
                                            id="startedAt"
                                            value={startedAt}
                                        />
                                    </FormGroup>
                                </Col>
                                {!workingUntillNow && (
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="endedAt">
                                                {_.get(translation, "shared.to")}
                                            </Label>
                                            <Input
                                                type="date"
                                                name="endedAt"
                                                id="endedAt"
                                                value={endedAt}
                                            />
                                        </FormGroup>
                                    </Col>
                                )}
                            </Row>
                            <FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            value={workingUntillNow}
                                            type="checkbox"
                                            id="workingUntillNow"
                                            name="workingUntillNow"
                                        />
                                        {_.get(translation, "skills_journey_page.current_job")}
                                    </Label>
                                </FormGroup>
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                                <Label for="whyTookJob">
                                    {_.get(translation, "skills_journey_page.why_i_took_job_label")}
                                </Label>
                                <Input
                                    value={whyTookJob}
                                    type="textarea"
                                    name="whyTookJob"
                                    id="whyTookJob"
                                />
                            </FormGroup>
                        </Col>
                        {!workingUntillNow && (
                            <Col md={10}>
                                <FormGroup>
                                    <Label for="whatLearned">
                                        {_.get(
                                            translation,
                                            "skills_journey_page.what_i_learned_label"
                                        )}
                                    </Label>
                                    <Input
                                        value={whatLearned}
                                        type="textarea"
                                        name="whatLearned"
                                        id="whatLearned"
                                    />
                                </FormGroup>
                            </Col>
                        )}
                    </Form>
                </ModalBody>
                <ModalFooter className="content-center">{this.renderModalButtons()}</ModalFooter>
            </Modal>
        );
    }
}

AddNewPastJobModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(AddNewPastJobModal);
