import React, { Component } from "react";
import _ from "lodash";
import "./ApplicantJobs.scss";
import ApplicationOverview from "./applicationOverview/ApplicationOverview.container";
import SkillsMap from "./skillsAndFeedback/SkillsAndFeedback.container";
import JobDetails from "./jobDetails/JobDetails.container";

class ApplicantJobs extends Component {
    componentDidMount() {
        this.props.onMount();
    }
    render() {
        const { translation } = this.props;
        return (
            <div className="applicant-jobs-container">
                <span className="applicant-jobs-container__title">
                    {_.get(translation, "applicant_jobs_page.page_label")}
                </span>
                <div className="d-flex flex-row justify-content-between">
                    <div className="application-overview section">
                        <div className="block">
                            <h6>
                                {_.get(
                                    translation,
                                    "applicant_jobs_page.application_overview_label"
                                )}
                            </h6>
                            <ApplicationOverview />
                        </div>
                    </div>
                    <div className="skills-map section">
                        <div className="block">
                            <h6>{_.get(translation, "shared.skills_map_label")}</h6>
                            <SkillsMap />
                        </div>
                    </div>
                    <div className="job-details section">
                        <div className="block">
                            <h6>{_.get(translation, "applicant_jobs_page.job_details_label")}</h6>
                            <JobDetails />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ApplicantJobs.propTypes = {};

export default ApplicantJobs;
