import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import { SkillsUtils } from "../../utils/skillsUtils";
import _ from "lodash";
import moment from "moment";
import {
    SORTING_MODE,
    CANDIDATE_GROUP,
    VIEW_MODE,
    SOFT_SKILLS_MODE,
} from "../../constants/candidatesComparisonConstants";
import {
    refreshCurrentCandidateData,
    refreshSelectedCandidatesData,
    removeCandidateFromCurrentAndSelected,
    getCurrentCandidateId,
    getSelectedCandidatesIds,
    getCurrentCandidateData,
    getSelectedCandidatesData,
} from "./selectedCandidates";

//- Actions
export const fetchGetOpenJobDataFailed = createAction("FETCH_GET_OPEN_JOB_DATA_FAILED");
export const fetchGetOpenJobDataSuccess = createAction("FETCH_GET_OPEN_JOB_DATA_SUCCESS");
export const onFetchGetOpenJobDataSuccess = (data) => (dispatch) => {
    let formattedData = {
        id: data.id,
        title: data.title,
        description: data.description,
        createdAt: data.createdAt,
        startDate: data.startDate,
        deadline: data.deadline,
        topAmount: data.topAmount,
        amountToHire: data.amountToHire,
        maxSalary: data.salary,
        location: data.location,
        expectedImpactOfTravel: data.expectedImpactOfTravel,
        percentageOfHomeWorking: data.percentageOfHomeWorking,
        temporaryAssignmentData: data.temporaryAssignmentData,
        skills: _.map(data.skills, (skill) => {
            // TODO: remove hardcoded values
            if (skill.skill.id === 476) {
                skill.notes = [
                    {
                        date: moment().toISOString(),
                        text: "HM some note",
                    },
                ];
                skill.version = {
                    ratio: 2,
                    value: "v.10",
                };
            }
            return {
                id: skill.skill.id,
                title: skill.skill.titles.en,
                categoryId: skill.skill.category.id,
                categoryTitle: skill.skill.category.titles.en,
                level: skill.level,
                requirementCoefficient: skill.requirementCoefficient,
                notes: skill.notes,
                version: skill.version,
                spokenLevel: skill.spokenLevel,
                understandingLevel: skill.understandingLevel,
            };
        }),
        // TODO: remove hardcoded values
        ffmData: {
            normalized: [
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
            ],
            company: [
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
            ],
            department: [
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
            ],
            bo: [
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
                { min: _.random(1, 3), max: _.random(4, 5) },
            ],
        },
    };

    dispatch(fetchGetOpenJobDataSuccess(formattedData));
};
export const fetchGetOpenJobData = createAction(
    "FETCH_GET_OPEN_JOB_DATA",
    (id) => Api.getJobData(id),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetOpenJobDataSuccess,
        onFail: fetchGetOpenJobDataFailed,
    })
);

export const fetchGetOpenJobCandidatesFailed = createAction("FETCH_GET_OPEN_JOB_CANDIDATES_FAILED");
export const fetchGetOpenJobCandidatesSuccess = createAction(
    "FETCH_GET_OPEN_JOB_CANDIDATES_SUCCESS"
);
export const fetchGetOpenJobCandidates = createAction(
    "FETCH_GET_OPEN_JOB_CANDIDATES",
    (jobId, sortingMode, isPinTopAndFavs) =>
        Api.getJobCandidates(jobId, sortingMode, isPinTopAndFavs),
    () => ({
        apiCall: true,
        onSuccess: fetchGetOpenJobCandidatesSuccess,
        onFail: fetchGetOpenJobCandidatesFailed,
    })
);

export const onFetchGetOpenJobCandidates = () => (dispatch, getState) => {
    let jobId = getOpenJobId(getState());
    let sortingMode = getOpenJobSortingMode(getState());
    let isPinTopAndFavs = getOpenJobPinTopAndFavsStatus(getState());
    dispatch(fetchGetOpenJobCandidates(jobId, sortingMode, isPinTopAndFavs));
};

export const fetchGetOpenJobDataForComparison = (jobId) => (dispatch, getState) => {
    dispatch(setOpenJobId(jobId));
    dispatch(fetchGetOpenJobData(jobId));
    let sortingMode = getOpenJobSortingMode(getState());
    let isPinTopAndFavs = getOpenJobPinTopAndFavsStatus(getState());
    dispatch(fetchGetOpenJobCandidates(jobId, sortingMode, isPinTopAndFavs));
    dispatch(fetchGetTopXCandidatesData(jobId));
    dispatch(fetchGetNoGoCandidatesData(jobId));
};

export const fetchGetTopXCandidatesDataSuccess = createAction(
    "FETCH_GET_TOP_X_CANDIDATES_DATA_SUCCESS"
);
export const fetchGetTopXCandidatesDataFailed = createAction(
    "FETCH_GET_TOP_X_CANDIDATES_DATA_FAILED"
);
export const fetchGetTopXCandidatesData = createAction(
    "FETCH_GET_TOP_X_CANDIDATES_DATA",
    (jobId) => Api.getOpenJobGroupCandidatesData(jobId, CANDIDATE_GROUP.TOP_X),
    () => ({
        apiCall: true,
        onSuccess: fetchGetTopXCandidatesDataSuccess,
        onFail: fetchGetTopXCandidatesDataFailed,
    })
);
export const onFetchGetTopXCandidatesData = () => (dispatch, getState) => {
    let jobId = getOpenJobId(getState());
    dispatch(fetchGetTopXCandidatesData(jobId));
};

export const fetchGetNoGoCandidatesDataFailed = createAction(
    "FETCH_GET_NO_GO_CANDIDATES_DATA_FAILED"
);
export const fetchGetNoGoCandidatesDataSuccess = createAction(
    "FETCH_GET_NO_GO_CANDIDATES_DATA_SUCCESS"
);
export const fetchGetNoGoCandidatesData = createAction(
    "FETCH_GET_NO_GO_CANDIDATES_DATA",
    (jobId) => Api.getOpenJobGroupCandidatesData(jobId, CANDIDATE_GROUP.NO_GO),
    () => ({
        apiCall: true,
        onSuccess: fetchGetNoGoCandidatesDataSuccess,
        onFail: fetchGetNoGoCandidatesDataFailed,
    })
);
export const onFetchGetNoGoCandidatesData = () => (dispatch, getState) => {
    let jobId = getOpenJobId(getState());
    dispatch(fetchGetNoGoCandidatesData(jobId));
};

export const setOpenJobId = createAction("SET_OPEN_JOB_ID");

export const updateOpenJobPinTopAndFavs = createAction("UPDATE_OPEN_JOB_PIN_TOP_AND_FAVS");
export const onUpdateOpenJobPinTopAndFavs = (isPinTopAndFavs) => (dispatch, getState) => {
    let jobId = getOpenJobId(getState());
    let sortingMode = getOpenJobSortingMode(getState());
    if (jobId) {
        dispatch(fetchGetOpenJobCandidates(jobId, sortingMode, isPinTopAndFavs));
    }
    dispatch(updateOpenJobPinTopAndFavs(isPinTopAndFavs));
};

export const updateOpenJobSortingMode = createAction("UPDATE_OPEN_JOB_SORTING_MODE");
export const onUpdateOpenJobSortingMode = (sortingMode) => (dispatch, getState) => {
    let jobId = getOpenJobId(getState());
    let isPinTopAndFavs = getOpenJobPinTopAndFavsStatus(getState());
    if (jobId) {
        dispatch(fetchGetOpenJobCandidates(jobId, sortingMode, isPinTopAndFavs));
    }
    dispatch(updateOpenJobSortingMode(sortingMode));
};

export const updateOpenJobCombinedSortingRatio = createAction(
    "UPDATE_OPEN_JOB_COMBINED_SORTING_RATIO"
);
export const onChangeCombinedSortingRatio = (ratio) => (dispatch) => {
    // TODO: (BE) add the ability to sort hs/ss with a custom ratios
    dispatch(updateOpenJobCombinedSortingRatio(ratio));
};

export const updateOpenJobAnonymityMode = createAction("UPDATE_OPEN_JOB_ANONYMITY_MODE");

export const onAddCandidateToFavorite = (userId) => (dispatch) => {
    dispatch(onUpdateCandidateMetadata(userId, { favorite: true }));
};

export const onRemoveCandidateFromFavorite = (userId) => (dispatch) => {
    dispatch(onUpdateCandidateMetadata(userId, { favorite: false }));
};

export const onUpdateCandidateNotes = (userId, notes) => (dispatch) => {
    dispatch(onUpdateCandidateMetadata(userId, { notes: notes }));
};

export const onAddCandidateToTopXGroup = (userId) => (dispatch) => {
    dispatch(onUpdateCandidateMetadata(userId, { groupId: CANDIDATE_GROUP.TOP_X }));
};

export const onAddCandidateToNoGoGroup = (userId) => (dispatch) => {
    dispatch(removeCandidateFromCurrentAndSelected(userId));
    dispatch(onUpdateCandidateMetadata(userId, { groupId: CANDIDATE_GROUP.NO_GO }));
};

export const onRemoveCandidateFromGroup = (groupId, userId) => (dispatch) => {
    dispatch(onUpdateCandidateMetadata(userId, { groupId: CANDIDATE_GROUP.NO_GROUP }));
};

export const onUpdateCandidateMetadata = (userId, meta) => (dispatch, getState) => {
    let jobId = getOpenJobId(getState());
    let candidateData = getOpenJobCandidateDataById(getState(), userId);

    let metaData = {
        notes: !_.isNil(meta.notes) ? meta.notes : candidateData.applicantMetaData.notes,
        groupId: !_.isNil(meta.groupId) ? meta.groupId : candidateData.applicantMetaData.groupId,
        favorite: !_.isNil(meta.favorite)
            ? meta.favorite
            : candidateData.applicantMetaData.favorite,
    };

    dispatch(fetchUpdateCandidateMetadata(jobId, userId, metaData));
};

export const fetchUpdateCandidateMetadataFailed = createAction(
    "FETCH_UPDATE_CANDIDATE_METADATA_FAILED"
);
export const fetchUpdateCandidateMetadataSuccess = (jobId, candidateId) => () => (
    dispatch,
    getState
) => {
    // TODO: add functionality - separate success actions by metadata update type (remove from group/add to group/change favorite/change notes)
    dispatch(onFetchGetOpenJobCandidates());
    let currentCandidateId = getCurrentCandidateId(getState());
    if (currentCandidateId === candidateId) {
        dispatch(refreshCurrentCandidateData());
    }
    let selectedCandidatesIds = getSelectedCandidatesIds(getState()) || [];
    if (selectedCandidatesIds.includes(candidateId)) {
        dispatch(refreshSelectedCandidatesData());
    }
    dispatch(fetchGetTopXCandidatesData(jobId));
    dispatch(fetchGetNoGoCandidatesData(jobId));
};
export const fetchUpdateCandidateMetadata = createAction(
    "FETCH_UPDATE_CANDIDATE_METADATA",
    (jobId, candidateId, candidateMeta) =>
        Api.updateJobCandidateMetadata(jobId, candidateId, candidateMeta),
    (jobId, candidateId, candidateMeta) => ({
        apiCall: true,
        onSuccess: fetchUpdateCandidateMetadataSuccess(jobId, candidateId),
        onFail: fetchUpdateCandidateMetadataFailed,
    })
);

export const fetchRemoveAllCandidatesFromGroupFailed = createAction(
    "FETCH_REMOVE_ALL_CANDIDATES_FROM_GROUP_FAILED"
);
export const fetchRemoveAllCandidatesFromGroupSuccess = (jobId, groupId) => () => (
    dispatch,
    getState
) => {
    dispatch(onFetchGetOpenJobCandidates());

    if (groupId === CANDIDATE_GROUP.NO_GO) {
        dispatch(fetchGetNoGoCandidatesData(jobId));
    } else if (groupId === CANDIDATE_GROUP.TOP_X) {
        dispatch(fetchGetTopXCandidatesData(jobId));
        let currentCandidateData = getCurrentCandidateData(getState());
        if (currentCandidateData && currentCandidateData.applicantMetaData.groupId === groupId) {
            dispatch(refreshCurrentCandidateData());
        }
        let selectedCandidatesData = getSelectedCandidatesData(getState());
        if (
            selectedCandidatesData &&
            selectedCandidatesData.find((elem) => elem.applicantMetaData.groupId === groupId)
        ) {
            dispatch(refreshSelectedCandidatesData());
        }
    }
};
export const fetchRemoveAllCandidatesFromGroup = createAction(
    "FETCH_REMOVE_ALL_CANDIDATES_FROM_GROUP",
    (jobId, groupId) => Api.removeAllCandidatesFromGroup(jobId, groupId),
    (jobId, groupId) => ({
        apiCall: true,
        onSuccess: fetchRemoveAllCandidatesFromGroupSuccess(jobId, groupId),
        onFail: fetchRemoveAllCandidatesFromGroupFailed,
    })
);
export const onRemoveAllCandidatesFromGroup = (groupId) => (dispatch, getState) => {
    let jobId = getOpenJobId(getState());
    if (jobId) {
        dispatch(fetchRemoveAllCandidatesFromGroup(jobId, groupId));
    }
};

export const setCurrentCandidateForActiveModal = () => (dispatch, getState) => {
    let currentCandidateId = getCurrentCandidateId(getState());
    dispatch(setCandidateForActiveModal(currentCandidateId));
};

export const setCandidateForActiveModal = createAction("SET_CANDIDATE_FOR_ACTIVE_MODAL");

export const updateOpenJobViewMode = createAction("UPDATE_OPEN_JOB_VIEW_MODE");

export const updateOpenJobComparingMode = createAction("UPDATE_OPEN_JOB_COMPARING_MODE");

export const onStartDragCandidate = createAction("START_DRAG_CANDIDATE");
export const onFinishDragCandidate = createAction("FINISH_DRAG_CANDIDATE");

export const updateSoftSkillsMode = createAction("UPDATE_SOFT_SKILLS_MODE");

//- State
const initialState = {
    jobId: null,
    data: null,
    error: null,
    fetching: false,
    candidatesData: null,
    fetchingCandidates: false,
    topXCandidatesData: null,
    fetchingTopXCandidates: false,
    noGoCandidatesData: null,
    fetchingNoGoCandidates: false,
    isPinTopAndFavs: true,
    sortingMode: SORTING_MODE.DATE,
    combinedSortingRatio: 0,
    anonymityMode: false,
    candidateIdForActiveModal: null,
    viewMode: VIEW_MODE.HARD_SKILLS,
    comparingMode: null,
    isCandidateDragging: false,
    softSkillsMode: SOFT_SKILLS_MODE.FFM,
};

//- Reducers
export default handleActions(
    {
        FETCH_GET_OPEN_JOB_DATA: (state) => {
            return { ...state, error: null, fetching: true };
        },
        FETCH_GET_OPEN_JOB_DATA_FAILED: (state, action) => {
            return { ...state, data: null, error: action.payload, fetching: false };
        },
        FETCH_GET_OPEN_JOB_DATA_SUCCESS: (state, action) => {
            return { ...state, data: action.payload, error: null, fetching: false };
        },
        FETCH_GET_OPEN_JOB_CANDIDATES: (state) => {
            return { ...state, error: null, fetchingCandidates: true };
        },
        FETCH_GET_OPEN_JOB_CANDIDATES_FAILED: (state, action) => {
            return {
                ...state,
                candidatesData: null,
                error: action.payload,
                fetchingCandidates: false,
            };
        },
        FETCH_GET_OPEN_JOB_CANDIDATES_SUCCESS: (state, action) => {
            // TODO: (BE) candidates data should with pages, not just a list
            return {
                ...state,
                candidatesData: action.payload,
                error: null,
                fetchingCandidates: false,
            };
        },
        SET_OPEN_JOB_ID: (state, action) => {
            return { ...state, jobId: action.payload };
        },
        UPDATE_OPEN_JOB_PIN_TOP_AND_FAVS: (state, action) => {
            return { ...state, isPinTopAndFavs: action.payload };
        },
        UPDATE_OPEN_JOB_SORTING_MODE: (state, action) => {
            return { ...state, sortingMode: action.payload };
        },
        UPDATE_OPEN_JOB_COMBINED_SORTING_RATIO: (state, action) => {
            return { ...state, combinedSortingRatio: action.payload };
        },
        UPDATE_OPEN_JOB_ANONYMITY_MODE: (state, action) => {
            return { ...state, anonymityMode: action.payload };
        },
        SET_CANDIDATE_FOR_ACTIVE_MODAL: (state, action) => {
            return { ...state, candidateIdForActiveModal: action.payload };
        },
        UPDATE_OPEN_JOB_VIEW_MODE: (state, action) => {
            return { ...state, viewMode: action.payload };
        },
        UPDATE_OPEN_JOB_COMPARING_MODE: (state, action) => {
            return { ...state, comparingMode: action.payload };
        },
        FETCH_GET_TOP_X_CANDIDATES_DATA: (state) => {
            return { ...state, error: null, fetchingTopXCandidates: true };
        },
        FETCH_GET_TOP_X_CANDIDATES_DATA_FAILED: (state, action) => {
            return {
                ...state,
                topXCandidatesData: null,
                error: action.payload,
                fetchingTopXCandidates: false,
            };
        },
        FETCH_GET_TOP_X_CANDIDATES_DATA_SUCCESS: (state, action) => {
            return {
                ...state,
                topXCandidatesData: action.payload,
                error: null,
                fetchingTopXCandidates: false,
            };
        },
        FETCH_GET_NO_GO_CANDIDATES_DATA: (state) => {
            return { ...state, error: null, fetchingNoGoCandidates: true };
        },
        FETCH_GET_NO_GO_CANDIDATES_DATA_FAILED: (state, action) => {
            return {
                ...state,
                noGoCandidatesData: null,
                error: action.payload,
                fetchingNoGoCandidates: false,
            };
        },
        FETCH_GET_NO_GO_CANDIDATES_DATA_SUCCESS: (state, action) => {
            return {
                ...state,
                noGoCandidatesData: action.payload,
                error: null,
                fetchingNoGoCandidates: false,
            };
        },
        START_DRAG_CANDIDATE: (state) => {
            return { ...state, isCandidateDragging: true };
        },
        FINISH_DRAG_CANDIDATE: (state) => {
            return { ...state, isCandidateDragging: false };
        },
        UPDATE_SOFT_SKILLS_MODE: (state, action) => {
            return { ...state, softSkillsMode: action.payload };
        },
    },
    initialState
);

//- Selectors
export const getOpenJobState = (state) => state.openJob;

export const getOpenJobId = (state) => getOpenJobState(state).jobId;

export const getOpenJobData = (state) => getOpenJobState(state).data;

export const getOpenJobFetchingStatus = (state) => getOpenJobState(state).fetching;

export const getOpenJobSkillsData = (state) => {
    let data = getOpenJobData(state);
    if (!data) {
        return null;
    } else {
        return data.skills;
    }
};

export const getOpenJobSkillsDataTree = (state) => {
    let skills = getOpenJobSkillsData(state);
    if (!skills) {
        return null;
    }

    return SkillsUtils.createSkillsTree(skills);
};

export const getOpenJobPlannedTopCandidatesAmount = (state) => {
    let data = getOpenJobData(state);
    if (!data) {
        return null;
    } else {
        return data.topAmount;
    }
};

export const getOpenJobCandidatesData = (state) => getOpenJobState(state).candidatesData;

export const getOpenJobCandidatesDataFetchingStatus = (state) =>
    getOpenJobState(state).fetchingCandidates;

export const getOpenJobCandidatesDataInitialFetchingStatus = (state) =>
    getOpenJobCandidatesDataFetchingStatus(state) && _.isNil(getOpenJobCandidatesData(state));

export const getOpenJobTopXCandidatesData = (state) => getOpenJobState(state).topXCandidatesData;

export const getOpenJobTopXCandidatesDataFetchingStatus = (state) =>
    getOpenJobState(state).fetchingTopXCandidates;

export const getOpenJobTopXCandidatesDataInitialFetchingStatus = (state) =>
    getOpenJobTopXCandidatesDataFetchingStatus(state) &&
    _.isNil(getOpenJobTopXCandidatesData(state));

export const getOpenJobNoGoCandidatesData = (state) => getOpenJobState(state).noGoCandidatesData;

export const getOpenJobNoGoCandidatesDataFetchingStatus = (state) =>
    getOpenJobState(state).fetchingNoGoCandidates;

export const getOpenJobNoGoCandidatesDataInitialFetchingStatus = (state) =>
    getOpenJobNoGoCandidatesDataFetchingStatus(state) &&
    _.isNil(getOpenJobNoGoCandidatesData(state));

export const getOpenJobAllCandidatesDataInitialFetchingStatus = (state) =>
    getOpenJobCandidatesDataInitialFetchingStatus(state) ||
    getOpenJobTopXCandidatesDataInitialFetchingStatus(state) ||
    getOpenJobNoGoCandidatesDataInitialFetchingStatus(state);

export const getOpenJobCandidateDataById = (state, userId) => {
    let data = getOpenJobCandidatesData(state);
    let noGoCandidatesData = getOpenJobNoGoCandidatesData(state);

    return (
        _.find(data, (candidateData) => candidateData.user.id === userId) ||
        _.find(noGoCandidatesData, (candidateData) => candidateData.user.id === userId)
    );
};

export const getOpenJobTopXCandidatesAmount = (state) => {
    let topXCandidatesData = getOpenJobTopXCandidatesData(state);
    if (topXCandidatesData) {
        return topXCandidatesData.length;
    }
};

export const getOpenJobNoGoCandidatesAmount = (state) => {
    let noGoCandidatesData = getOpenJobNoGoCandidatesData(state);
    if (noGoCandidatesData) {
        return noGoCandidatesData.length;
    }
};

export const getOpenJobTotalCandidatesAmount = (state) => {
    let candidatesData = getOpenJobCandidatesData(state);
    let noGoCandidatesData = getOpenJobNoGoCandidatesData(state);
    let amount = 0;
    if (candidatesData && noGoCandidatesData) {
        amount += candidatesData.length;
        amount += noGoCandidatesData.length;
    }
    return amount;
};

export const getOpenJobLeftCandidatesAmount = (state) => {
    let candidatesData = getOpenJobCandidatesData(state);
    let topXCandidatesData = getOpenJobTopXCandidatesData(state);
    let amount = 0;
    if (candidatesData && topXCandidatesData) {
        amount += candidatesData.length;
        amount -= topXCandidatesData.length;
    }
    return amount;
};

export const getOpenJobPinTopAndFavsStatus = (state) => getOpenJobState(state).isPinTopAndFavs;

export const getOpenJobSortingMode = (state) => getOpenJobState(state).sortingMode;

export const getOpenJobCombinedSortingRatio = (state) =>
    getOpenJobState(state).combinedSortingRatio;

export const getOpenJobAnonymityMode = (state) => getOpenJobState(state).anonymityMode;

export const getCandidateIdForActiveModal = (state) =>
    getOpenJobState(state).candidateIdForActiveModal;

export const getCandidateNoteForActiveModal = (state) => {
    let userId = getCandidateIdForActiveModal(state);
    let candidatesData = getOpenJobCandidatesData(state);
    let data = _.find(candidatesData, (candidateData) => candidateData.user.id === userId);

    if (data) {
        return data.applicantMetaData.notes;
    }
};

export const getCandidateDataForActiveModal = (state) => {
    let userId = getCandidateIdForActiveModal(state);
    let candidatesData = getOpenJobCandidatesData(state);

    return _.find(candidatesData, (candidateData) => candidateData.user.id === userId);
};

export const getOpenJobViewMode = (state) => getOpenJobState(state).viewMode;

export const getOpenJobComparingMode = (state) => getOpenJobState(state).comparingMode;

export const getCandidateDraggingStatus = (state) => getOpenJobState(state).isCandidateDragging;

export const getSoftSkillsMode = (state) => getOpenJobState(state).softSkillsMode;

export const getOpenJobFFMData = (state) => {
    let data = getOpenJobData(state);

    if (data) {
        return data.ffmData;
    }
};
