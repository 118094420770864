import SendFeedbackModal from "./SendFeedbackModal";
import { connect } from "react-redux";
import { getTranslation } from "../../../../../reducers/common/localization";
import {
	getActiveModalType,
	hideModal,
	MODAL_INVITATIONS_SEND_FEEDBACK
} from "../../../../../reducers/common/activeModal";

const mapStateToProps = state => ({
	translation: getTranslation(state),
	isOpen: getActiveModalType(state) === MODAL_INVITATIONS_SEND_FEEDBACK
});

const mapDispatchToProps = dispatch => ({
	onCloseModal: () => dispatch(hideModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendFeedbackModal);
