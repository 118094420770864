import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Legend.scss";
import _ from "lodash";
import {
    LEVEL_TRANSLATION_LABEL,
    LEVEL,
    LEVEL_CLASS_NAME,
    COMPARISON_LEVEL,
    COMPARISON_LEVEL_CLASS_NAME,
    COMPARISON_LEVEL_TRANSLATION_LABEL,
    STATUS,
    STATUS_CLASS_NAME,
    STATUS_TRANSLATION_LABEL,
} from "../../../../constants/skillsConstants";
import Icon from "../../icon/Icon";

export const LEGEND_TYPE = {
    LEVELS_AND_MODERATION: "LEVELS_AND_MODERATION",
    LEVELS_ONLY: "LEVELS_ONLY",
    COMPARISON: "COMPARISON",
    COMPARISON_REVERSED: "COMPARISON_REVERSED",
    SKILLS_VIEW: "SKILLS_VIEW",
};

const LEVELS_ARRAY = [LEVEL.JUNIOR, LEVEL.MEDIOR, LEVEL.SENIOR, LEVEL.NINJA];
const STATUSES_ARRAY = [STATUS.NEW, STATUS.EVERGREEN, STATUS.FADING];

const COMPARISON_LEVELS_ARRAY = [
    COMPARISON_LEVEL.MISSING,
    COMPARISON_LEVEL.LOWER,
    COMPARISON_LEVEL.PERFECT,
];

class Legend extends Component {
    render() {
        return (
            <div className={`legend ${this.props.vertical ? "vertical" : ""}`}>
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        switch (this.props.type) {
            case LEGEND_TYPE.LEVELS_AND_MODERATION:
                return (
                    <>
                        {this.renderLevelItems()}
                        {this.renderModerationItem()}
                    </>
                );

            case LEGEND_TYPE.LEVELS_ONLY:
                return <>{this.renderLevelItems()}</>;

            case LEGEND_TYPE.COMPARISON:
                return (
                    <div className="legend-parts-container">
                        {this.renderJobRelativeItems()}
                        {this.renderActualsItems()}
                    </div>
                );

            case LEGEND_TYPE.COMPARISON_REVERSED:
                return (
                    <div className="legend-parts-container">
                        {this.renderActualsItems()}
                        {this.renderJobRelativeItems()}
                    </div>
                );
            case LEGEND_TYPE.SKILLS_VIEW:
                return (
                    <div className="legend-parts-container vertical">
                        {this.renderStatusItems()}
                    </div>
                );
            default:
                break;
        }
    }

    renderStatusItems() {
        return _.map(STATUSES_ARRAY, (status) => (
            <div
                key={status}
                className="legend-item"
                title={_.get(
                    this.props.translation,
                    `skill_properties.status_values.${STATUS_TRANSLATION_LABEL[status]}`
                )}
            >
                <div className={`skill-status ${STATUS_CLASS_NAME[status]}`} />
                <span>
                    {_.get(
                        this.props.translation,
                        `skill_properties.status_values.${STATUS_TRANSLATION_LABEL[status]}`
                    )}
                </span>
            </div>
        ));
    }

    renderLevelItems() {
        return _.map(LEVELS_ARRAY, (level) => {
            return (
                <div
                    key={level}
                    className="legend-item"
                    title={_.get(
                        this.props.translation,
                        `skill_properties.levels_values.${LEVEL_TRANSLATION_LABEL[level]}_with_range`
                    )}
                >
                    <div className={`skill-level ${LEVEL_CLASS_NAME[level]}`} />
                    <span>
                        {_.get(
                            this.props.translation,
                            `skill_properties.levels_values.${LEVEL_TRANSLATION_LABEL[level]}`
                        )}
                    </span>
                </div>
            );
        });
    }

    renderModerationItem() {
        return (
            <div className="legend-item">
                <Icon className="not-moderated-icon" type="not_moderated" size="sm" />
                <span>{_.get(this.props.translation, "system_nodes.not_moderated_yet_label")}</span>
            </div>
        );
    }

    renderJobRelativeItems() {
        return (
            <div className="legend-part">
                <div className="legend-part-title">
                    {_.get(this.props.translation, "skill_properties.job_relative_label")}
                </div>
                <div className="legend-part-content">
                    {_.map(COMPARISON_LEVELS_ARRAY, (level) => {
                        return (
                            <div
                                key={level}
                                className="legend-item"
                                title={_.get(
                                    this.props.translation,
                                    `skill_properties.comparison_level_values.${COMPARISON_LEVEL_TRANSLATION_LABEL[level]}`
                                )}
                            >
                                <div
                                    className={`skill-level ${COMPARISON_LEVEL_CLASS_NAME[level]}`}
                                />
                                <span>
                                    {_.get(
                                        this.props.translation,
                                        `skill_properties.comparison_level_values.${COMPARISON_LEVEL_TRANSLATION_LABEL[level]}_short`
                                    )}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderActualsItems() {
        return (
            <div className="legend-part">
                <div className="legend-part-title">
                    {_.get(this.props.translation, "skill_properties.actuals_label")}
                </div>
                <div className="legend-part-content">
                    {_.map(LEVELS_ARRAY, (level) => {
                        return (
                            <div
                                key={level}
                                className="legend-item"
                                title={_.get(
                                    this.props.translation,
                                    `skill_properties.levels_values.${LEVEL_TRANSLATION_LABEL[level]}_with_range`
                                )}
                            >
                                <div
                                    className={`skill-level ${LEVEL_CLASS_NAME[level]} light-level`}
                                />
                                <span>
                                    {_.get(
                                        this.props.translation,
                                        `skill_properties.levels_values.${LEVEL_TRANSLATION_LABEL[level]}_short`
                                    )}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

Legend.defaultProps = {
    type: LEGEND_TYPE.LEVELS_AND_MODERATION,
};

Legend.propTypes = {
    translation: PropTypes.object.isRequired,
    type: PropTypes.string,
};

export default Legend;
