import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CandidatesComparison.scss';
import _ from 'lodash';
import {
    Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Badge, UncontrolledPopover, PopoverBody
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Utils } from '../../../../utils/utils';
import { ROUTES } from '../../../../constants/constants';
import CandidateStack from './candidateStack/CandidateStack.container';
import CandidateNotesModal from './modals/candidateNotesModal/CandidateNotesModal.container';
import { VIEW_MODE, COMPARING_MODE, CANDIDATE_GROUP, SOFT_SKILLS_MODE } from '../../../../constants/candidatesComparisonConstants';
import Icon from '../../../common/icon/Icon';
import { MODAL_GROUP_TYPE } from './modals/candidatesGroupModal/CandidatesGroupModal';
import CandidatesGroupModal from './modals/candidatesGroupModal/CandidatesGroupModal.container';
import HardSkillsComparison from './hardSkillsComparison/HardSkillsComparison.container';
import CandidateCrossJobInfoModal from './modals/candidateCrossJobInfoModal/CandidateCrossJobInfoModal.container';
import { CROSS_JOB_INFO_TYPE } from './modals/candidateCrossJobInfoModal/CandidateCrossJobInfoModal';
import CandidateJobSpecificInfoModal from './modals/candidateJobSpecificInfoModal/CandidateJobSpecificInfoModal.container';
import { JOB_SPECIFIC_INFO_TYPE } from './modals/candidateJobSpecificInfoModal/CandidateJobSpecificInfoModal';
import { CANDIDATE_ESSENCE_INFO_TYPE } from './modals/candidateEssenceInfoModal/CandidateEssenceInfoModal';
import CandidateEssenceInfoModal from './modals/candidateEssenceInfoModal/CandidateEssenceInfoModal.container';
import SoftSkillsComparison from './softSkillsComparison/SoftSkillsComparison.container';
import DashboardComparison from './dashboardComparison/DashboardComparison.container';

const VIEW_MODE_CLASS_NAME = {
    [VIEW_MODE.HARD_SKILLS]: "hard-skills-mode",
    [VIEW_MODE.SOFT_SKILLS]: "soft-skills-mode",
    [VIEW_MODE.COMPARING_DASHBOARD]: "comparison-mode",
}

const COMPARING_MODES_LIST = [COMPARING_MODE.HARD_SKILLS, COMPARING_MODE.SOFT_SKILLS, COMPARING_MODE.COMBINED];

const ICON_BY_COMPARING_MODE = {
    [COMPARING_MODE.HARD_SKILLS]: "gear",
    [COMPARING_MODE.SOFT_SKILLS]: "brain",
    [COMPARING_MODE.COMBINED]: "gear_and_brain"
}

const COMPARING_MODE_TITLE = {
    [COMPARING_MODE.HARD_SKILLS]: "job_and_candidates.hard_skills_label",
    [COMPARING_MODE.SOFT_SKILLS]: "job_and_candidates.soft_skills_label",
    [COMPARING_MODE.COMBINED]: "job_and_candidates.hard_and_soft_skills_label"
};

class CandidatesComparison extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalGroupType: null,
            crossJobInfoType: null,
            jobSpecificInfoType: null,
            candidateEssenceType: null
        };
    }

    componentDidMount() {
        const jobId = Utils.getParamsFromLocationSearch(this.props.location.search).id;

        if (jobId) {
            this.props.onMount(jobId);
        } else {
            this.props.history.push(ROUTES.OPEN_JOBS);
        }
    }

    render() {
        return (
            <div className="candidates-comparison">
                <h4 className="page-header">{_.get(this.props.translation, "candidates_comparison_page.page_label")}</h4>
                {this.renderContent()}
                <CandidateNotesModal />
                <CandidatesGroupModal groupType={this.state.modalGroupType} />
                <CandidateCrossJobInfoModal activeTab={this.state.crossJobInfoType} />
                <CandidateJobSpecificInfoModal activeTab={this.state.jobSpecificInfoType} />
                <CandidateEssenceInfoModal activeTab={this.state.candidateEssenceType} />
                <div
                    className="drop-overlay"
                    hidden={!this.props.isCandidateDragging}
                    onDrop={this.handleDrop()}
                    onDragOver={(e) => e.preventDefault()}
                />
            </div>
        );
    }

    renderContent() {
        if (this.props.loading) {
            return (
                <h6 className="text-center">{_.get(this.props.translation, "actions.fetching_message")}</h6>
            )
        }

        if (this.props.data) {
            return (
                <>
                    <h6 className="page-subheader">
                        {_.get(this.props.translation, "shared.title_label")}
                        {": "}
                        {this.props.data.title}
                    </h6>
                    <div className="candidates-comparison-content-container">
                        <Col md="3" className="content-column candidates-stack-column">
                            <div className="candidates-stack-wrapper">
                                <CandidateStack />
                            </div>
                        </Col>
                        <Col className="content-column details-column">
                            <div className={`details-wrapper ${VIEW_MODE_CLASS_NAME[this.props.viewMode]}`}>
                                <div className="buttons-pane mode-pane">
                                    {this.renderModeButtons()}
                                </div>
                                <div className="buttons-pane groups-pane">
                                    {this.renderGroupsButtons()}
                                </div>
                                <div className="buttons-pane soft-skills-pane" hidden={this.props.viewMode !== VIEW_MODE.SOFT_SKILLS}>
                                    {this.renderSoftSkillsButtons()}
                                </div>
                                <div className="buttons-pane candidate-pane" hidden={this.props.viewMode === VIEW_MODE.COMPARING_DASHBOARD || !this.props.currentCandidateData}>
                                    {this.renderMediaButtons()}
                                    {this.renderMessagesButtons()}
                                    {this.renderContactsButtons()}
                                </div>
                                {this.renderDetailsContent()}
                            </div>
                        </Col>
                    </div>
                </>
            )
        } else {
            return null;
        }
    }

    renderModeButtons() {
        return (
            <>
                <div className={`pane-button ${this.props.viewMode === VIEW_MODE.HARD_SKILLS ? "active" : ""}`} onClick={this.handleChangeViewMode(VIEW_MODE.HARD_SKILLS)}>
                    <Icon type={`person_hard_skills${this.props.viewMode === VIEW_MODE.HARD_SKILLS ? "" : "_outline"}`} size="xl" />
                    <span className="pane-button-label">{_.get(this.props.translation, "job_and_candidates.hard_skills_label")}</span>
                </div>
                <div className={`pane-button ${this.props.viewMode === VIEW_MODE.SOFT_SKILLS ? "active" : ""}`} onClick={this.handleChangeViewMode(VIEW_MODE.SOFT_SKILLS)}>
                    <Icon type={`person_soft_skills${this.props.viewMode === VIEW_MODE.SOFT_SKILLS ? "" : "_outline"}`} size="xl" />
                    <span className="pane-button-label">{_.get(this.props.translation, "job_and_candidates.soft_skills_label")}</span>
                </div>
                <UncontrolledDropdown className="pane-button-dropdown">
                    <DropdownToggle caret
                        tag="div"
                        className={`pane-button ${this.props.viewMode === VIEW_MODE.COMPARING_DASHBOARD ? "active" : ""}`}
                    >
                        <Icon type={`persons_comparison${this.props.viewMode === VIEW_MODE.COMPARING_DASHBOARD ? "" : "_outline"}`} size="xl" />
                        <span className="pane-button-label">{_.get(this.props.translation, "candidates_comparison_page.comparison_label")}</span>
                        {
                            this.props.comparingMode ?
                                <Icon className="comparing-mode-icon" type={ICON_BY_COMPARING_MODE[this.props.comparingMode]} size="sm" />
                                : null
                        }
                    </DropdownToggle>
                    <DropdownMenu>
                        {
                            _.map(COMPARING_MODES_LIST, (mode) => {
                                return (
                                    <DropdownItem
                                        key={mode}
                                        tag="div"
                                        className={`pane-button-dropdown-item ${this.props.comparingMode === mode ? "active" : ""}`}
                                        onClick={this.handleChangeComparingMode(mode)}
                                    >
                                        <Icon type={`${ICON_BY_COMPARING_MODE[mode]}${this.props.comparingMode === mode ? "" : "_outline"}`} />
                                        <span>{_.get(this.props.translation, COMPARING_MODE_TITLE[mode])}</span>
                                    </DropdownItem>
                                )
                            })
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
            </>
        )
    }

    handleChangeViewMode = (mode) => () => {
        if (this.props.viewMode !== mode) {
            this.props.onChangeViewMode(mode);
            this.props.onChangeComparingMode(null);
        }
    }

    handleChangeComparingMode = (mode) => () => {
        if (this.props.comparingMode !== mode) {
            this.props.onChangeViewMode(VIEW_MODE.COMPARING_DASHBOARD);
            this.props.onChangeComparingMode(mode);
        }
    }

    renderGroupsButtons() {
        const MAX_AMOUNT = 99;

        return (
            <>
                <div
                    className={`pane-button ${this.props.isCandidateDragging ? "ready-for-drop" : ""}`}
                    onClick={this.handleOpenGroupModal(MODAL_GROUP_TYPE.TOP_X)}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={this.handleDrop(CANDIDATE_GROUP.TOP_X)}
                >
                    <Icon type="best_mark" size="xl" />
                    <span className="pane-button-label">{_.replace(_.get(this.props.translation, 'job.top_x_label'), "{x}", this.props.plannedTopAmount)}</span>
                    <Badge className="count-badge" color="success" pill>{this.props.topXAmount > MAX_AMOUNT ? MAX_AMOUNT + "+" : this.props.topXAmount}</Badge>
                </div>
                <div className={`pane-button ${this.props.isCandidateDragging ? "ready-for-drop" : ""}`}>
                    <Icon type="emails_delete" size="xl" />
                    <span className="pane-button-label">{_.get(this.props.translation, 'candidates_comparison_page.feedback_tray_label')}</span>
                    <Badge className="count-badge" color="success" pill>{this.props.noGoAmount > MAX_AMOUNT ? MAX_AMOUNT + "+" : this.props.noGoAmount}</Badge>
                    <div className="pane-button-collapse">
                        <div
                            className="pane-sub-button"
                            onClick={this.handleOpenGroupModal(MODAL_GROUP_TYPE.NO_GO)}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={this.handleDrop(CANDIDATE_GROUP.NO_GO)}
                        >
                            <Icon type="email_delete" size="xl" />
                            <span className="pane-button-label">{_.get(this.props.translation, 'job.no_go_label')}</span>
                            <Badge className="count-badge" color="success" pill>{this.props.noGoAmount > MAX_AMOUNT ? MAX_AMOUNT + "+" : this.props.noGoAmount}</Badge>
                        </div>
                        <div className="pane-sub-button" onClick={this.handleOpenGroupModal(MODAL_GROUP_TYPE.DEADLINE_POSTPONED)}>
                            <Icon type="email_expire" size="xl" />
                            <span className="pane-button-label">{_.get(this.props.translation, 'job.deadline_postponed_label')}</span>
                            <Icon className="info-icon" type="info" size="sm" color="info" id="deadline-postponed-group-icon" />
                            <UncontrolledPopover placement="top" target="deadline-postponed-group-icon" trigger="hover">
                                <PopoverBody>
                                    {_.get(this.props.translation, 'candidates_comparison_page.not_dragging_candidates_group_hint')}
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                        <div className="pane-sub-button" onClick={this.handleOpenGroupModal(MODAL_GROUP_TYPE.CANCELED_JOB)}>
                            <Icon type="email_discard" size="xl" />
                            <span className="pane-button-label">{_.get(this.props.translation, 'job.canceled_job_label')}</span>
                            <Icon className="info-icon" type="info" size="sm" color="info" id="cancelled-job-group-icon" />
                            <UncontrolledPopover placement="top" target="cancelled-job-group-icon" trigger="hover">
                                <PopoverBody>
                                    {_.get(this.props.translation, 'candidates_comparison_page.not_dragging_candidates_group_hint')}
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    handleOpenGroupModal = (modalType) => () => {
        this.setState({ modalGroupType: modalType });
        this.props.onShowCandidatesGroupModal();
    }

    renderSoftSkillsButtons() {
        if (!this.props.currentCandidateData) {
            return null;
        }

        return (
            <>
                <div className="pane-buttons-container">
                    <div
                        className="pane-button"
                        onClick={this.handleOpenCandidateEssenceModal(CANDIDATE_ESSENCE_INFO_TYPE.PERSON)}
                        title={_.get(this.props.translation, 'psychology.essential_personal_traits_label')}
                    >
                        <Icon type="person_in_circle" size="xl" />
                    </div>
                    <div
                        className="pane-button"
                        onClick={this.handleOpenCandidateEssenceModal(CANDIDATE_ESSENCE_INFO_TYPE.PERSON_IN)}
                        title={_.replace(_.get(this.props.translation, 'psychology.how_to_speak_to_label'), "{personName}", this.getUserFirstName())}
                    >
                        <Icon type="person_arrows_in" size="xl" />
                    </div>
                    <div
                        className="pane-button"
                        onClick={this.handleOpenCandidateEssenceModal(CANDIDATE_ESSENCE_INFO_TYPE.PERSON_OUT)}
                        title={_.replace(_.get(this.props.translation, 'psychology.how_to_engage_label'), "{personName}", this.getUserFirstName())}
                    >
                        <Icon type="person_arrows_out" size="xl" />
                    </div>
                </div>
                <div className="pane-buttons-container">
                    <div
                        className={`pane-button ${this.props.softSkillsMode === SOFT_SKILLS_MODE.FFM ? "active" : ""}`}
                        onClick={this.handleChangeSoftSkillsMode(SOFT_SKILLS_MODE.FFM)}
                    >
                        <Icon type={`ffm${this.props.softSkillsMode !== SOFT_SKILLS_MODE.FFM ? "_outline" : ""}`} size="xl" />
                        <span className="pane-button-label">
                            {_.get(this.props.translation, 'psychology.ffm_label')}
                        </span>
                    </div>
                    <div
                        className={`pane-button ${this.props.softSkillsMode === SOFT_SKILLS_MODE.TEST_1 ? "active" : ""}`}
                        onClick={this.handleChangeSoftSkillsMode(SOFT_SKILLS_MODE.TEST_1)}
                    >
                        <Icon type={`test${this.props.softSkillsMode !== SOFT_SKILLS_MODE.TEST_1 ? "_outline" : ""}`} size="xl" />
                        <span className="pane-button-label">
                            {/* TODO: remove hardcoded values */}
                            Test 1
                        </span>
                    </div>
                    <div
                        className={`pane-button ${this.props.softSkillsMode === SOFT_SKILLS_MODE.TEST_2 ? "active" : ""}`}
                        onClick={this.handleChangeSoftSkillsMode(SOFT_SKILLS_MODE.TEST_2)}
                    >
                        <Icon type={`test${this.props.softSkillsMode !== SOFT_SKILLS_MODE.TEST_2 ? "_outline" : ""}`} size="xl" />
                        <span className="pane-button-label">
                            {/* TODO: remove hardcoded values */}
                            Test 2
                        </span>
                    </div>
                </div>
            </>
        )
    }

    handleOpenCandidateEssenceModal = (type) => () => {
        this.props.onShowCandidateEssenceInfoModal();
        this.setState({ candidateEssenceType: type });
    }

    handleChangeSoftSkillsMode = (type) => () => {
        if (this.props.softSkillsMode !== type) {
            this.props.onChangeSoftSkillsMode(type);
        }
    }

    getUserFirstName = () => {
        if (this.props.anonymityMode) {
            return Utils.getUserAnonymousNameByUUID(this.props.currentCandidateData.user.id);
        } else {
            return this.props.currentCandidateData.user.alias.firstName;
        }
    }

    renderMediaButtons() {
        if (_.isNil(this.props.currentCandidateData)) {
            return null;
        }

        return (
            <div className="pane-buttons-container">
                <div className="pane-button pane-title">
                    {_.get(this.props.translation, 'job_and_candidates.profile_label')}
                </div>
                <div
                    className="pane-button"
                    onClick={this.handleOpenCrossJobInfoModal(CROSS_JOB_INFO_TYPE.MOVIE)}
                >
                    <Icon type="movie" size="xl" />
                    <span className="pane-button-label">
                        {_.get(this.props.translation, 'job_and_candidates.movie_label')}
                    </span>
                </div>
                <div
                    className="pane-button"
                    onClick={this.handleOpenCrossJobInfoModal(CROSS_JOB_INFO_TYPE.CV)}
                >
                    <Icon type="cv" size="xl" />
                    <span className="pane-button-label">
                        {_.get(this.props.translation, 'job_and_candidates.cv_label')}
                    </span>
                </div>
            </div>
        );
    }

    handleOpenCrossJobInfoModal = (type) => () => {
        this.props.onShowCrossJobInfoModal();
        this.setState({ crossJobInfoType: type });
    }

    renderMessagesButtons() {
        if (_.isNil(this.props.currentCandidateData)) {
            return null;
        }

        return (
            <div className="pane-buttons-container">
                <div className="pane-button pane-title">
                    {_.get(this.props.translation, 'job.job_specific_label')}
                </div>
                <div
                    className="pane-button"
                    onClick={this.handleOpenJobSpecificInfoModal(JOB_SPECIFIC_INFO_TYPE.LETTER)}
                >
                    <Icon type="text_message" size="xl" />
                    <span className="pane-button-label">
                        {_.get(this.props.translation, 'job_and_candidates.letter_label')}
                    </span>
                </div>
                <div
                    className="pane-button"
                    onClick={this.handleOpenJobSpecificInfoModal(JOB_SPECIFIC_INFO_TYPE.AUDIO)}
                >
                    <Icon type="audio_message" size="xl" />
                    <span className="pane-button-label">
                        {_.get(this.props.translation, 'job_and_candidates.audio_label')}
                    </span>
                </div>
                <div
                    className="pane-button"
                    onClick={this.handleOpenJobSpecificInfoModal(JOB_SPECIFIC_INFO_TYPE.VIDEO)}
                >
                    <Icon type="video_message" size="xl" />
                    <span className="pane-button-label">
                        {_.get(this.props.translation, 'job_and_candidates.video_label')}
                    </span>
                </div>
            </div>
        );
    }

    handleOpenJobSpecificInfoModal = (type) => () => {
        this.props.onShowJobSpecificInfoModal();
        this.setState({ jobSpecificInfoType: type });
    }

    renderContactsButtons() {
        if (_.isNil(this.props.currentCandidateData)) {
            return null;
        }

        return (
            <div className="pane-buttons-container">
                <div className="pane-button pane-title">
                    {_.get(this.props.translation, 'job_and_candidates.contacts_label')}
                </div>
                <div
                    className="pane-button with-link"
                    title={_.get(this.props.translation, 'job.send_email_label')}
                >
                    <a className="pane-button-link" href={"mailto:" + this.props.currentCandidateData.user.email}>
                        <Icon type="email" size="xl" />
                        <span className="pane-button-label">
                            {_.get(this.props.translation, 'person_data.email_label')}
                        </span>
                    </a>
                </div>
                <div
                    className="pane-button"
                    id="contact-button-phone-number"
                >
                    <Icon type="phone" size="xl" />
                    <span className="pane-button-label">
                        {_.get(this.props.translation, 'job_and_candidates.mobile_label')}
                    </span>
                    <UncontrolledPopover
                        trigger="hover"
                        placement="left"
                        target="contact-button-phone-number"
                        className="phone-number-popover"
                    >
                        <div className="phone-number">
                            {this.props.currentCandidateData.user.alias.phoneNumber}
                        </div>
                    </UncontrolledPopover>
                </div>
            </div>
        );
    }

    renderDetailsContent() {
        switch (this.props.viewMode) {
            case VIEW_MODE.HARD_SKILLS:
                return (
                    <HardSkillsComparison />
                );

            case VIEW_MODE.SOFT_SKILLS:
                return (
                    <SoftSkillsComparison />
                );


            case VIEW_MODE.COMPARING_DASHBOARD:
                return (
                    <DashboardComparison
                        onSetCrossJobInfoType={(type) => this.setState({ crossJobInfoType: type })}
                    />
                );

            default:
                break;
        }
    }

    handleDrop = (groupId) => (e) => {
        let userId = e.dataTransfer.getData('userId');
        if (groupId === CANDIDATE_GROUP.TOP_X) {
            this.props.onAddCandidateToTop(userId);
        }

        if (groupId === CANDIDATE_GROUP.NO_GO) {
            this.props.onAddCandidateToNoGo(userId);
        }

        this.props.onFinishDragCandidate();
    }
}

CandidatesComparison.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.object,
    loading: PropTypes.bool,
    viewMode: PropTypes.string,
    comparingMode: PropTypes.string,
    plannedTopAmount: PropTypes.number,
    topXAmount: PropTypes.number,
    noGoAmount: PropTypes.number,
    isCandidateDragging: PropTypes.bool,
    currentCandidateData: PropTypes.object,
    anonymityMode: PropTypes.bool,
    softSkillsMode: PropTypes.string,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onMount: PropTypes.func.isRequired,
    onChangeViewMode: PropTypes.func.isRequired,
    onChangeComparingMode: PropTypes.func.isRequired,
    onShowCandidatesGroupModal: PropTypes.func.isRequired,
    onFinishDragCandidate: PropTypes.func.isRequired,
    onAddCandidateToTop: PropTypes.func.isRequired,
    onAddCandidateToNoGo: PropTypes.func.isRequired,
    onShowCrossJobInfoModal: PropTypes.func.isRequired,
    onShowJobSpecificInfoModal: PropTypes.func.isRequired,
    onShowCandidateEssenceInfoModal: PropTypes.func.isRequired,
    onChangeSoftSkillsMode: PropTypes.func.isRequired,
};

export default withRouter(CandidatesComparison);
