import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './DepartmentDetails.scss';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Utils } from '../../../../utils/utils';
import { ROUTES } from '../../../../constants/constants';
import BackLink from '../../../common/buttons/backLink/BackLink.container';
import Icon from '../../../common/icon/Icon';
import DepartmentsDetailsEditModal from '../modals/departmentsDetailsEditModal/DepartmentsDetailsEditModal.container';
import { Button, Table } from 'reactstrap';
import AddEmployeeToDepartmentModal from '../modals/addEmployeeToDepartmentModal/AddEmployeeToDepartmentModal.container';
import RemoveEmployeeFromDepartmentModal
  from '../modals/removeEmployeeFromDepartmentModal/RemoveEmployeeFromDepartmentModal.container';

class DepartmentDetails extends Component {
    constructor(props) {
      super(props);

      this.state = {
        selectedEmployee: null
      }
    }
    componentDidMount() {
        const departmentId = Utils.getParamsFromLocationSearch(this.props.location.search).id;

        if (departmentId) {
            this.props.fetchDepartmentData({ departmentId });
        } else {
            this.props.history.push(ROUTES.STAFF_AND_DEPARTMENTS);
        }
    }

    render() {
        if (this.props.loading) {
            return (
              <h6 className="text-center">{_.get(this.props.translation, "actions.fetching_message")}</h6>
            )
        }

        return (
            <>
                <div className="department-details">
                    <BackLink to={ROUTES.STAFF_AND_DEPARTMENTS} />
                    <h4 className="page-header">{_.get(this.props.translation, "organization.department_label")}</h4>
                    {!_.isEmpty(this.props.data)
                        ? (
                            <>
                                <div className="department-info">
                                    <div className="edit-icon-wrapper">
                                        <h6><b>{_.get(this.props.translation, "shared.name_label") + ": "}</b>{this.props.data.name}</h6>
                                        <Icon
                                          onClick={this.props.onEditDepartment}
                                          type="edit"
                                          title={_.get(this.props.translation, "actions.edit_label")}
                                        />
                                    </div>
                                    <h6><b>{_.get(this.props.translation, "person_data.email_label") + ": "}</b>{this.props.data.email}</h6>
                                </div>
                                <div className="buttons-container content-space-between">
                                    <h6>{_.get(this.props.translation, "shared.employee")}</h6>
                                    <Button onClick={this.props.onAddEmployeeToDepartment} color="primary">
                                        {_.get(this.props.translation, "actions.add_label")}
                                    </Button>
                                </div>
                                {this.renderContent()}
                            </>
                        )
                      : null
                    }
                </div>
                <DepartmentsDetailsEditModal selectedDepartment={this.props.data} />
                <AddEmployeeToDepartmentModal department={this.props.data} />
                <RemoveEmployeeFromDepartmentModal
                  selectedEmployee={this.state.selectedEmployee}
                  department={this.props.data}
                />
            </>
        );
    }

  renderContent() {
        if (_.isNil(this.props.data) || _.isNil(this.props.data[0].employees) || !this.props.data[0].employees.length) {
            return <h6 className="text-center">{_.get(this.props.translation, "staff.no_data_label")}</h6>;
        }

        return (
            <Table>
                <thead>
                <tr>
                    <th>{_.get(this.props.translation, "person_data.first_name_label")}</th>
                    <th>{_.get(this.props.translation, "person_data.last_name_label")}</th>
                    <th>{_.get(this.props.translation, "person_data.email_label")}</th>
                    <th>{_.get(this.props.translation, "person_data.roles")}</th>
                    <th />
                </tr>
                </thead>
                <tbody>
                    {
                        _.map(this.props.data[0].employees, (employee) => (
                            <tr key={employee.id}>
                                <td>{employee.firstName}</td>
                                <td>{employee.lastName}</td>
                                <td>{employee.email}</td>
                                <td>{employee.roles.join(", ")}</td>
                                <td
                                  onClick={() => {
                                    this.setState({ selectedEmployee: employee });
                                    this.props.onRemoveEmployeeFromDepartment();
                                  }}
                                  className="table-cell-with-icon"
                                >

                                    <Icon
                                        type="delete"
                                        title={_.get(this.props.translation, "actions.remove_button")}
                                    />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        )
    }
}

DepartmentDetails.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.object,
    loading: PropTypes.bool,
    history: PropTypes.object.isRequired,
    fetchDepartmentData: PropTypes.func.isRequired,
    onAddEmployeeToDepartment: PropTypes.func.isRequired,
    onEditDepartment: PropTypes.func.isRequired,
    onRemoveEmployeeFromDepartment: PropTypes.func.isRequired,
};

export default withRouter(DepartmentDetails);
