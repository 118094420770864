import { connect } from "react-redux";
import AddNewPastJobModal from "./AddNewPastJobModal";
import { getTranslation } from "../../../../reducers/common/localization";
import {
    getActiveModalType,
    MODAL_ADD_PAST_JOB_META_DATA,
    hideModal,
} from "../../../../reducers/common/activeModal";
import {
    fetchAddApplicantPastJob,
    getApplicantPastJobs,
} from "../../../../reducers/applicant/applicantPastJobs";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_ADD_PAST_JOB_META_DATA,
        pastJobsData: getApplicantPastJobs(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClose: () => {
            dispatch(hideModal());
        },
        onFetchAddApplicantPastJobSuccess: (data) => dispatch(fetchAddApplicantPastJob(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewPastJobModal);
