import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import { fetchAlternativeProfiles } from './jobCompassProfiles';

// Actions
export const fetchGetBlackListsFailed = createAction('FETCH_GET_BLACK_LIST_FAILED');
export const fetchGetBlackListSuccess = createAction('FETCH_GET_BLACK_LIST_SUCCESS');
export const fetchGetBlackList = createAction(
	'FETCH_GET_BLACK_LIST',
	() => Api.getJobCompassBlackList(),
	() => ({
		apiCall: true,
		onSuccess: fetchGetBlackListSuccess,
		onFail: fetchGetBlackListsFailed
	})
);

export const addProfileToBlackListSuccess = createAction('ADD_PROFILE_TO_BLACK_LIST_SUCCESS');
export const addProfileToBlackListFailed = createAction('ADD_PROFILE_TO_BLACK_LIST_FAILED');
export const onAddProfileToBlackListSuccess = () => (dispatch) => {
	dispatch(addProfileToBlackListSuccess());
	dispatch(fetchGetBlackList());
	dispatch(fetchAlternativeProfiles());
};
export const addProfileToBlackList = createAction(
	'ADD_PROFILE_TO_BLACK_LIST',
	(profileId) => Api.addProfileToBlackList(profileId),
	() => ({
		apiCall: true,
		onSuccess: onAddProfileToBlackListSuccess,
		onFail: addProfileToBlackListFailed
	})
);

//- State
const initialState = {
	blackList: [],
	error: null,
	fetchingBlackList: false,
	addToBlackListLoading: false,
};

//- Reducers
export default handleActions({
	FETCH_GET_BLACK_LIST: (state) => {
		return { ...state, blackList: [], error: null, fetchingBlackList: true };
	},
	FETCH_GET_BLACK_LIST_FAILED: (state, action) => {
		return { ...state, blackList: [], error: action.payload, fetchingBlackList: false };
	},
	FETCH_GET_BLACK_LIST_SUCCESS: (state, action) => {
		return { ...state, blackList: action.payload, error: null, fetchingBlackList: false };
	},
	ADD_PROFILE_TO_BLACK_LIST: (state) => {
		return { ...state, error: null, addToBlackListLoading: true };
	},
	ADD_PROFILE_TO_BLACK_LIST_SUCCESS: (state) => {
		return { ...state, error: null, addToBlackListLoading: false };
	},
	ADD_PROFILE_TO_BLACK_LIST_FAILED: (state, action) => {
		return { ...state, error: action.payload, addToBlackListLoading: false };
	},
	CLEAR_JOB_COMPASS_ERROR: (state) =>
		({ ...state, error: null }),
}, initialState);

//- Selectors
export const getBlackListState = state => state.blackList;

export const getBlackListData = state => getBlackListState(state).blackList;

export const getBlackListLoading = state =>
	getBlackListState(state).fetchingBlackList || getBlackListState(state).addToBlackListLoading;

export const getBlackListError = state => getBlackListState(state).error;
