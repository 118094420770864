import { connect } from 'react-redux';
import TablePagination from "./TablePagination";
import {
    getSystemNodesToAddCurrentPage,
    getSystemNodesToAddTotalPages,
    getSystemNodesToAddFetchingStatus,
    onFetchGetSystemNodesToAddByPage
} from '../../../reducers/admin/systemNodesToAdd';

const mapStateToProps = state => {
    return {
        current: getSystemNodesToAddCurrentPage(state),
        total: getSystemNodesToAddTotalPages(state),
        loading: getSystemNodesToAddFetchingStatus(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getDataForPage: (page) => {
            dispatch(onFetchGetSystemNodesToAddByPage(page));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePagination);
