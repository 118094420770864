import React, { Component } from "react";

class JobDiscovery extends Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <defs></defs>
                <title>n</title>
                <path
                    fill="#1d75b8"
                    d="M34.74361,56.63218a9.73953,9.73953,0,0,0,2.01992-3.96477,19.61933,19.61933,0,0,0,.58212-4.84115V28.55742a3.93389,3.93389,0,0,0-7.86778,0V47.42215q0,3.32778-1.26678,4.7454a5.30513,5.30513,0,0,1-4.13579,1.41051,9.8,9.8,0,0,1-3.62914-.65728c-.048-.02049-.096-.03428-.14383-.055a3.38026,3.38026,0,0,0-1.28048-.24628,3.3509,3.3509,0,0,0-1.25309,6.46386c.32184.13.66418.25338,1.01341.36982a18.557,18.557,0,0,0,5.90253.83539,16.64644,16.64644,0,0,0,6.1764-.98612A9.81251,9.81251,0,0,0,34.74361,56.63218Z"
                />
                <path
                    fill="#1d75b8"
                    d="M67.558,24.97444a19.62,19.62,0,0,0-6.761-1.15714,19.15156,19.15156,0,0,0-6.63355,1.15714,15.67882,15.67882,0,0,0-5.54823,3.483,16.7126,16.7126,0,0,0-3.80411,5.73111,20.32631,20.32631,0,0,0-1.41193,7.88492,21.11207,21.11207,0,0,0,1.36213,7.89608,16.03523,16.03523,0,0,0,3.73209,5.70332,15.46119,15.46119,0,0,0,5.54823,3.4497A19.48391,19.48391,0,0,0,60.797,60.29083a20.05666,20.05666,0,0,0,6.88835-1.16829,15.0247,15.0247,0,0,0,5.52054-3.4497,16.17851,16.17851,0,0,0,3.6546-5.70332A21.46576,21.46576,0,0,0,78.195,42.07344a21.20763,21.20763,0,0,0-1.35107-7.88492,16.42663,16.42663,0,0,0-3.74315-5.73111A15.36813,15.36813,0,0,0,67.558,24.97444Zm1.88816,21.74479A10.39011,10.39011,0,0,1,67.63,50.26854a7.98929,7.98929,0,0,1-2.92368,2.30352,9.98532,9.98532,0,0,1-7.84617,0,8.03521,8.03521,0,0,1-2.896-2.30352,10.48939,10.48939,0,0,1-1.81624-3.54931,17.43852,17.43852,0,0,1,0-9.25831,10.60528,10.60528,0,0,1,1.81624-3.58258,8.30294,8.30294,0,0,1,2.896-2.31448,8.942,8.942,0,0,1,3.93688-.83621,9.12328,9.12328,0,0,1,3.90929.80862A7.89983,7.89983,0,0,1,67.63,33.82864a10.50136,10.50136,0,0,1,1.81614,3.58258,15.93112,15.93112,0,0,1,.63131,4.66222A15.68768,15.68768,0,0,1,69.44614,46.71923Z"
                />
                <path
                    fill="#1d75b8"
                    d="M111.1414,44.09437a8.301,8.301,0,0,0-4.5405-3.43287,7.39832,7.39832,0,0,0,4.031-6.60606,8.88146,8.88146,0,0,0-1.13513-4.66222,8.56659,8.56659,0,0,0-3.15068-3.05655,14.51874,14.51874,0,0,0-4.81731-1.63346,36.22854,36.22854,0,0,0-6.12974-.4818H84.75659v34.807h.00548v1.00783H96.41235q7.9155,0,11.92719-2.77437,4.00332-2.774,4.00342-8.27239A9.83831,9.83831,0,0,0,111.1414,44.09437ZM92.42556,30.72766c.60362-.06654,1.29569-.11076,2.07094-.12172.76967-.02232,1.48953-.02759,2.17065-.02759a10.44263,10.44263,0,0,1,4.73424.85243A3.08346,3.08346,0,0,1,103.01821,34.41a3.2398,3.2398,0,0,1-1.56692,3.00117,10.62719,10.62719,0,0,1-5.18845.93013H92.42556Zm9.78434,21.8444a13.67536,13.67536,0,0,1-5.70342,1.00783c-.80283,0-1.54481-.01663-2.21487-.0499-.67554-.03327-1.29569-.08865-1.866-.155V44.34917h4.94471a10.616,10.616,0,0,1,5.18845,1.02994,3.66557,3.66557,0,0,1,1.82172,3.46066A3.75006,3.75006,0,0,1,102.2099,52.57206Z"
                />
                <path
                    fill="#2d3e50"
                    d="M39.90234,22.07064a9.00351,9.00351,0,0,1,2.02195,3.12492,28.029,28.029,0,0,1,2.4156-2.80338,27.76633,27.76633,0,0,1,35.16983-3.41806H95.39844c.509,0,.98328.04138,1.47989.0568A40.13222,40.13222,0,0,0,35.5999,13.65543a40.55228,40.55228,0,0,0-5.08154,6.1975,9.147,9.147,0,0,1,9.384,2.21771Z"
                />
                <path
                    fill="#2d3e50"
                    d="M79.5154,65.28352v-.15377a27.76909,27.76909,0,0,1-35.17723-3.4144,28.00151,28.00151,0,0,1-3.695-4.60461,14.36641,14.36641,0,0,1-1.90185,2.917,14.995,14.995,0,0,1-5.92191,4.14167,16.50056,16.50056,0,0,1-1.89871.62705A40.21247,40.21247,0,0,0,57.4653,81.683c.45908.07506.92293.13551,1.38729.19556V87.1547l-4.6608-.00243,1.18452,30.318a8.6235,8.6235,0,1,0,17.247.013l1.81005-30.333h-5.231l.0003-5.268c.68791-.08885,1.37217-.19353,2.052-.31809A39.85623,39.85623,0,0,0,92.398,70.45371,40.64256,40.64256,0,0,0,96.766,65.275c-.1202.00081-.23167.00852-.353.00852Z"
                />
            </svg>
        );
    }
}

export default JobDiscovery;
