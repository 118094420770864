import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';

//- Actions
export const fetchStaticApplicantViewDataSuccess = createAction('FETCH_STATIC_APPLICANT_VIEW_DATA_SUCCESS');
export const fetchStaticApplicantViewDataFailed = createAction('FETCH_STATIC_APPLICANT_VIEW_DATA_FAILED');
export const fetchStaticApplicantViewData = createAction(
	'FETCH_STATIC_APPLICANT_VIEW_DATA',
	(applicantHash) => Api.getApplicantByHash(applicantHash),
	() => ({
		apiCall: true,
		onSuccess: fetchStaticApplicantViewDataSuccess,
		onFail: fetchStaticApplicantViewDataFailed
	})
);

export const fetchStaticJobViewDataSuccess = createAction('FETCH_STATIC_JOB_VIEW_DATA_SUCCESS');
export const fetchStaticJobViewDataFailed = createAction('FETCH_STATIC_JOB_VIEW_DATA_FAILED');
export const fetchStaticJobViewData = createAction(
	'FETCH_STATIC_JOB_VIEW_DATA',
	(hash) => Api.getJobByHash(hash),
	() => ({
		apiCall: true,
		onSuccess: fetchStaticJobViewDataSuccess,
		onFail: fetchStaticJobViewDataFailed
	})
);

//- State
const initialState = {
	applicantData: null,
	jobData: null,
	error: null,
	fetchingApplicantData: false,
	fetchingJobData: false
};

//- Reducers
export default handleActions({
	FETCH_STATIC_APPLICANT_VIEW_DATA: (state) =>
		({ ...state, applicantData: null, error: null, fetchingApplicantData: true }),
	FETCH_STATIC_APPLICANT_VIEW_DATA_FAILED: (state, action) =>
		({ ...state, applicantData: null, error: action.payload, fetchingApplicantData: false }),
	FETCH_STATIC_APPLICANT_VIEW_DATA_SUCCESS: (state, action) =>
		({ ...state, applicantData: action.payload, error: null, fetchingApplicantData: false }),
	FETCH_STATIC_JOB_VIEW_DATA: (state) =>
		({ ...state, jobData: null, error: null, fetchingJobData: true }),
	FETCH_STATIC_JOB_VIEW_DATA_FAILED: (state, action) =>
		({ ...state, jobData: null, error: action.payload, fetchingJobData: false }),
	FETCH_STATIC_JOB_VIEW_DATA_SUCCESS: (state, action) =>
		({ ...state, jobData: action.payload, error: null, fetchingJobData: false }),
}, initialState);

//- Selectors
export const getStaticViewsState = state => state.staticViews;

export const getApplicantData = state => {
	const staticViewState = getStaticViewsState(state);

	return staticViewState.applicantData;
};

export const getJobData = state => {
	const staticViewState = getStaticViewsState(state);

	return staticViewState.jobData;
};

export const getFetchingApplicantDataLoading = state => getStaticViewsState(state).fetchingApplicantData;

export const getFetchingJobDataLoading = state => getStaticViewsState(state).fetchingJobData;
