import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ClusterSkillsPopover.scss';
import _ from "lodash";
import {
    UncontrolledPopover,
    PopoverHeader,
    PopoverBody,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import Icon from '../../icon/Icon';

class ClusterSkillsPopover extends Component {
    render() {
        return (
            <UncontrolledPopover
                target={this.props.target}
                placement="top"
                trigger="hover"
                boundariesElement="window"
                className="cluster-skills-popover"
            >
                <PopoverHeader>
                    {_.get(this.props.translation, 'skills_management.cluster_popover_label')}
                </PopoverHeader>
                <PopoverBody>
                    <ListGroup className="skills-items-container" flush>
                        {this.getListGroupItems()}
                    </ListGroup>
                </PopoverBody>
            </UncontrolledPopover>
        );
    }

    getListGroupItems() {
        return _.map(this.props.data, (skill) => {
            return (
                <ListGroupItem key={skill.id}>
                    <div className="text-container">{skill.title}</div>
                    {
                        !skill.isContains ?
                            <Icon
                                type="plus_outline"
                                color="success"
                                size="sm"
                                title={_.replace(_.get(this.props.translation, 'skills_management.add_skill_label'), '{skillName}', skill.title)}
                                onClick={this.props.onAddSkill(skill)}
                            />
                            : null
                    }
                </ListGroupItem>
            )
        })
    }
}

ClusterSkillsPopover.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    target: PropTypes.string.isRequired,
    onAddSkill: PropTypes.func.isRequired
};

export default ClusterSkillsPopover;
