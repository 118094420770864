import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './BackLink.scss';
import _ from 'lodash';
import { Link } from "react-router-dom";
import Icon from "../../icon/Icon";

class BackLink extends Component {
    render() {
        let defaultLabel = _.get(this.props.translation, "actions.back_button");
        return (
            <Link to={this.props.to} className='back-link' title={this.props.label || defaultLabel}>
                <Icon type="arrow_left" color="link" size="xs" />
                {this.props.label || defaultLabel}
            </Link>
        );
    }
}

BackLink.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string
};

export default BackLink;
