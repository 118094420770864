import { connect } from 'react-redux';
import CompanyRegistration from "./CompanyRegistration";
import { getTranslation } from "../../../../reducers/common/localization";
import { showModal, MODAL_LICENSE_AGREEMENT, MODAL_REGISTER_ORG_BY_ANCHOR } from '../../../../reducers/common/activeModal';
import { fetchRegisterCompany } from '../../../../reducers/authorization/registration';
import { getRequestSuccessStatus } from '../../../../reducers/common/requestStatus';
import {
    fetchGetOrganizationPricingByAnchor,
    getOrganizationPricingData
} from '../../../../reducers/organization/organizationPricing';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        success: getRequestSuccessStatus(state),
        pricingData: getOrganizationPricingData(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowLicenseAgreement: () => {
            dispatch(showModal(MODAL_LICENSE_AGREEMENT));
        },
        onOpenPricingModal: (countryCode, employeeCount) => {
            dispatch(fetchGetOrganizationPricingByAnchor(countryCode, employeeCount));
            // dispatch(showModal(MODAL_REGISTER_ORG_BY_ANCHOR));
        },
        onRegister: (data) => {
            dispatch(fetchRegisterCompany(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyRegistration);
