import { connect } from 'react-redux';
import CandidateEssenceInfoModal from "./CandidateEssenceInfoModal";
import { getTranslation } from "../../../../../../reducers/common/localization";
import { getActiveModalType, hideModal, MODAL_CANDIDATE_ESSENCE_INFO } from '../../../../../../reducers/common/activeModal';
import { getOpenJobAnonymityMode, getCandidateDataForActiveModal } from '../../../../../../reducers/job/openJob';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_CANDIDATE_ESSENCE_INFO,
        data: getCandidateDataForActiveModal(state),
        anonymityMode: getOpenJobAnonymityMode(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => dispatch(hideModal())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateEssenceInfoModal);
