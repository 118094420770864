import React, { Component } from 'react';

class Hired extends Component {
	render() {
		return (
			<svg width="128" height="128" viewBox="0 0 128 128">
				<path fill="#1d75b8" d="M77.86786,38.88436a2.81427,2.81427,0,0,0-3.97965,0L60.08059,52.69226l-5.969-5.969a2.81384,2.81384,0,1,0-3.97937,3.97937l7.95873,7.95873a2.81377,2.81377,0,0,0,3.97937,0L77.86786,42.86372A2.81391,2.81391,0,0,0,77.86786,38.88436Z"/>
				<path fill="#2d3e50" d="M125.43329,71.85639a4.99487,4.99487,0,0,0-2.68358-3.62252c-4.67818-2.328-14.93468-5.77276-14.93468-5.77276V59.07746l.28336-.21666a9.80333,9.80333,0,0,0,3.72258-6.22835l.05554-.36116h.28278a3.77539,3.77539,0,0,0,3.50089-2.35577,4.13417,4.13417,0,0,0,.51671-1.99461,3.76816,3.76816,0,0,0-.27225-1.40569,1.95127,1.95127,0,0,0-.75619-1.22234l-.95006-.57225.23945-1.02789c1.70517-7.45622-4.067-14.16794-11.81827-14.35126-.18835-.00556-.37727-.00556-.56059,0-.18337-.00556-.37229-.00556-.56118,0-7.75069.18332-13.52345,6.895-11.81217,14.35126l.23335,1.02789-.94454.57225a1.9557,1.9557,0,0,0-.76117,1.22234,3.75762,3.75762,0,0,0-.27225,1.40569,4.13436,4.13436,0,0,0,.51671,1.99461A3.788,3.788,0,0,0,91.9636,52.2713h.27781l.055.36116a9.80393,9.80393,0,0,0,3.72315,6.22835l.28278.21666V62.4611s-10.25648,3.44477-14.92856,5.77276a4.98911,4.98911,0,0,0-1.639,1.31122c4.2226,1.539,9.35638,3.517,12.60611,5.13379a9.84263,9.84263,0,0,1,5.28435,7.1173c.13889.81677.2667,1.70015.3778,2.61692H126.3778A109.05407,109.05407,0,0,0,125.43329,71.85639Z"/>
				<path fill="#2d3e50" d="M35.65858,74.67863c3.25028-1.6168,8.38407-3.59475,12.60611-5.13379a4.99355,4.99355,0,0,0-1.63846-1.31122c-4.67267-2.328-14.92911-5.77274-14.92911-5.77274V59.07726l.28336-.21669A9.80329,9.80329,0,0,0,35.703,52.63222l.05554-.36113h.2778a3.788,3.788,0,0,0,3.50587-2.35577A4.13424,4.13424,0,0,0,40.059,47.9207a3.76833,3.76833,0,0,0-.27223-1.40568,1.95547,1.95547,0,0,0-.76119-1.22234l-.94508-.57229.2339-1.02786c1.71073-7.45624-4.06148-14.16792-11.81214-14.3513-.18894-.00555-.37786-.00555-.56118,0-.18335-.00555-.37227-.00555-.56118,0-7.75069.18337-13.52344,6.89506-11.81827,14.3513l.23945,1.02786-.95006.57229a1.94815,1.94815,0,0,0-.75566,1.22234,3.769,3.769,0,0,0-.27223,1.40568,4.13424,4.13424,0,0,0,.51671,1.99462,3.77407,3.77407,0,0,0,3.50034,2.35577h.28333l.055.36113a9.80382,9.80382,0,0,0,3.72313,6.22835l.28279.21669v3.38362S9.92792,65.90563,5.25032,68.23362a4.997,4.997,0,0,0-2.68416,3.62256,109.00245,109.00245,0,0,0-.944,12.55667H29.997c.11112-.91674.2389-1.80016.3778-2.61689A9.842,9.842,0,0,1,35.65858,74.67863Z"/>
				<path fill="#1d75b8" d="M90.85341,77.662c-6.06723-3.02415-19.38288-7.494-19.38288-7.494h-1.1262l-3.64365,4.861,3.41583,23.63378H95.566a140.8743,140.8743,0,0,0-1.224-16.30144A6.48222,6.48222,0,0,0,90.85341,77.662Z"/>
				<path fill="#1d75b8" d="M56.52905,70.16814s-13.31564,4.46929-19.38284,7.494a6.48218,6.48218,0,0,0-3.48868,4.69931,140.87491,140.87491,0,0,0-1.224,16.30149H57.8836L61.2989,75.02914l-3.64365-4.861Z"/>
			</svg>
		);
	}
}

export default Hired;
