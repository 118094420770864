import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import _ from "lodash";
import Icon from "../../../../common/icon/Icon";
import UserPhoto from "../../../../common/userPhoto/UserPhotoQuickfind.container";
import HardSkillComparisonControls from "../../../../common/hardSkillComparisonControls/HardSkillComparisonControls.container";
import QuickfindMultipleHardSkillComparison from "../../../../common/quickfindMultipleHardSkillComparison/QuickfindMultipleHardSkillComparison";
import Legend from "../../../../common/skills/legend/Legend.container";
import { LEGEND_TYPE } from "../../../../common/skills/legend/Legend";
import "./TopXCompareModal.scss";

class TopXCompareModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowAllSkills: false,
        };
    }

    componentDidMount() {
        this.props.onMount(this.props.jobId);
    }

    handleAllSkillsVisibility = (value) => () => {
        this.setState({ isShowAllSkills: value });
    };

    render() {
        const { onCloseModal, isOpen, translation, applicantsToCompare } = this.props;

        const { isShowAllSkills } = this.state;
        const applicantsToCompareSkills = !applicantsToCompare
            ? applicantsToCompare.map((applicant) =>
                  applicant.skills.map((skillcontainer) => ({
                      skill: skillcontainer.skill,
                      level: skillcontainer.level,
                  }))
              )
            : [];

        const comparedSkills = applicantsToCompareSkills.map((applicantSkillArr) =>
            applicantSkillArr.map((applicantSkill) => ({
                categoryId: applicantSkill.skill.category.id,
                categoryTitle: applicantSkill.skill.category.titles["en"],
                children: [
                    {
                        id: applicantSkill.skill.id,
                        title: applicantSkill.skill.titles["en"],
                        categoryId: applicantSkill.skill.category.id,
                        categoryTitle: applicantSkill.skill.category.titles["en"],
                        comparisonLevel: null,
                        actualData: {
                            level: applicantSkill.level,
                        },
                        isSecondary: true,
                    },
                ],
            }))
        );

        let jobClusters = [];

        if (this.props.jobApplicants.length) {
            const jobSkills = this.props.jobApplicants
                .map((applicant) => applicant.job)
                .map((jobParam) => jobParam.skills);

            jobClusters = jobSkills[0].map((jobSkill) => {
                return {
                    categoryId: jobSkill.skill.category.id,
                    categoryTitle: jobSkill.skill.category.titles["en"],
                    children: [
                        {
                            id: jobSkill.skill.id,
                            title: jobSkill.skill.titles["en"],
                            categoryId: jobSkill.skill.category.id,
                            categoryTitle: jobSkill.skill.category.titles["en"],
                            level: jobSkill.level,
                        },
                    ],
                };
            });
        }

        return (
            <Modal centered isOpen={isOpen} toggle={onCloseModal} className="compare-modal">
                <ModalHeader>
                    <h5 className="text-center">
                        {_.replace(
                            _.get(
                                translation,
                                `invitations_management_page.side_by_side_top_x_comparison`
                            ),
                            "{x}",
                            2
                        )}
                    </h5>
                </ModalHeader>
                <ModalBody>
                    <div className="applicants-info">
                        <div className="applicants-info-col job-requirements">
                            <h6 className="text-center">
                                {_.get(translation, "job.job_requirements_label")}
                            </h6>
                        </div>
                        {!applicantsToCompare && //TODO: figure out why it comes from be
                            _.map(applicantsToCompare, (applicant) => {
                                return (
                                    <div
                                        className="applicants-info-col applicant-info"
                                        key={applicant.userId}
                                    >
                                        <div className="applicant-controls">
                                            <div className="buttons-group">
                                                <Icon type="movie" size="lg" />
                                            </div>
                                            <UserPhoto
                                                userId={applicant.userId}
                                                imageSource=""
                                                size="sm"
                                            />
                                            <div className="buttons-group">
                                                <Icon type="cv" size="lg" />
                                            </div>
                                        </div>
                                        <div className="applicant-name">
                                            {`${applicant.alias.firstName} ${applicant.alias.lastName}`}
                                            <br />
                                            {applicant.currentJob}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <HardSkillComparisonControls
                        isShowAllSkills={isShowAllSkills}
                        handleAllSkillsVisibility={this.handleAllSkillsVisibility}
                    />
                    <QuickfindMultipleHardSkillComparison
                        isShowAllSkills={isShowAllSkills}
                        comparedSkills={comparedSkills.map((applicantSkills) => ({
                            ...applicantSkills.map((applicantSkillCategory) => ({
                                ...applicantSkillCategory,
                                children: comparedSkills[0]
                                    .filter(
                                        (skill) =>
                                            applicantSkillCategory.categoryId === skill.categoryId
                                    )
                                    .map((skill) => ({ ...skill.children[0] })),
                            })),
                        }))}
                        clusters={jobClusters}
                    />
                    <Legend type={LEGEND_TYPE.COMPARISON_REVERSED} />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={onCloseModal}>
                        {_.get(translation, "actions.cancel_button")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

TopXCompareModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
};

export default TopXCompareModal;
