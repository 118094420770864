import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import { logout } from './login';

//- Actions
export const fetchGetSecurityFailed = createAction('FETCH_GET_SECURITY_FAILED');
export const fetchGetSecuritySuccess = createAction('FETCH_GET_SECURITY_SUCCESS');
export const fetchGetSecurity = createAction(
    'FETCH_GET_SECURITY',
    () => Api.getUserState(),
    () => ({
        apiCall: true,
        onSuccess: fetchGetSecuritySuccess,
        onFail: fetchGetSecurityFailed,
        onUnauthorized: logout,
    })
);

export const fetchGenerate2FASecretFailed = createAction('FETCH_GENERATE_2FA_SECRET_FAILED');
export const fetchGenerate2FASecretSuccess = createAction('FETCH_GENERATE_2FA_SECRET_SUCCESS');
export const fetchGenerate2FASecret = createAction(
    'FETCH_GENERATE_2FA_SECRET',
    () => Api.generateUserSecret(),
    () => ({
        apiCall: true,
        onSuccess: fetchGenerate2FASecretSuccess,
        onFail: fetchGenerate2FASecretFailed,
        onUnauthorized: logout,
    })
);

export const onUpdateSecuritySuccess = () => (dispatch) => {
    dispatch(fetchGetSecurity());
};

export const fetchEnable2FA = (checkCode) => dispatch => {
    dispatch(onEnable2FA(checkCode));
};
export const onEnable2FA = createAction(
    'FETCH_ENABLE_2FA',
    (checkCode) => Api.enableUser2FA(checkCode),
    () => ({
        apiCall: true,
        onSuccess: onUpdateSecuritySuccess,
        onFail: fetchOnHandle2FAFailed,
        onUnauthorized: logout,
    })
);

export const fetchDisable2FA = () => dispatch => {
    dispatch(onDisable2FA());
};
export const onDisable2FA = createAction(
    'FETCH_DISABLE_2FA',
    () => Api.disableUser2FA(),
    () => ({
        apiCall: true,
        onSuccess: onUpdateSecuritySuccess,
        onFail: fetchOnHandle2FAFailed,
        onUnauthorized: logout,
    })
);
export const fetchOnHandle2FAFailed = createAction("FETCH_HANDLE_2FA_FAILED");

export const clearSecurity = createAction('CLEAR_SECURITY');

//- State
const initialState = {
    fetching: false,
    error: null,
    is2FAEnable: null,
    QRCodeData: null,
    fetchingQRCodeData: false
};

//- Reducers
export default handleActions({
    FETCH_GET_SECURITY: (state) => {
        return { ...state, error: null, fetching: true };
    },
    FETCH_GET_SECURITY_FAILED: (state, action) => {
        return { ...state, is2FAEnable: null, error: action.payload, fetching: false };
    },
    FETCH_GET_SECURITY_SUCCESS: (state, action) => {
        return { ...state, is2FAEnable: action.payload.enabled, error: null, fetching: false };
    },
    FETCH_GENERATE_2FA_SECRET: (state) => {
        return { ...state, QRCodeData: null, error: null, fetchingQRCodeData: true };
    },
    FETCH_GENERATE_2FA_SECRET_FAILED: (state, action) => {
        return { ...state, QRCodeData: null, error: action.payload, fetchingQRCodeData: false };
    },
    FETCH_GENERATE_2FA_SECRET_SUCCESS: (state, action) => {
        return { ...state, QRCodeData: action.payload, error: null, fetchingQRCodeData: false };
    },
    CLEAR_SECURITY: (state) => {
        return { ...state, is2FAEnable: null, QRCodeData: null };
    },
    FETCH_HANDLE_2FA_FAILED: (state, action) => {
        return { ...state, error: action.payload, fetching: false };
    }
}, initialState);

//- Selectors
export const getSecurity = state => state.security;

export const is2FAEnable = state => getSecurity(state).is2FAEnable;

export const getSecurityLoading = state => getSecurity(state).fetching;

export const getQRCodeData = state => getSecurity(state).QRCodeData;

export const getQRCodeDataLoading = state => getSecurity(state).fetchingQRCodeData;