export const getCompanyPricingType = (employeeCount) => {
	switch (employeeCount) {
		case 'ONE_TO_TEN':
		case 'TEN_TO_FIFTY':
			return 'SME';
		case 'FIFTY_TO_TWO_HUNDRED':
		case 'TWO_HUNDRED_TO_FIVE_HUNDRED':
			return 'LE';
		case 'FIVE_HUNDRED_TO_ONE_THOUSAND':
		case 'ONE_THOUSAND_TO_FIVE_THOUSAND':
		case 'FIVE_THOUSAND_TO_TEN_THOUSAND':
		case 'TEN_THOUSAND_PLUS':
			return 'VLE';
		default:
			return '';
	}
};
