import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Legend.scss";
import Icon from "../../../../common/icon/Icon";
import JobBall from "../jobBall/JobBall";
import _ from "lodash";

class Legend extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { translation } = this.props;
        return (
            <div className="legend-container">
                <h6>{_.get(translation, "applicant_jobs_page.legend_label")}</h6>
                <div className="legend-row">
                    <JobBall disabled daysFromApply={30} daysToDeadline={14} />
                    <div className="text">
                        {_.get(translation, "applicant_jobs_page.ball_defenition_label")}
                    </div>
                </div>
                <div className="legend-row">
                    <svg height="50" width="50">
                        <circle className="job-circle possible-future" cx="25" cy="25" r="22" />
                    </svg>
                    <div
                        className="text"
                        dangerouslySetInnerHTML={{
                            __html: _.get(
                                translation,
                                "applicant_jobs_page.green_halo_defenition_label"
                            ),
                        }}
                    />
                </div>
                <div className="legend-row">
                    <Icon color="danger" type="clear" />
                    <div className="text">
                        {_.get(translation, "applicant_jobs_page.remove_job_defenition_label")}
                    </div>
                </div>
                <div className="legend-row">
                    <Icon type="red_notification" number={2} x={33} />
                    <div className="text">
                        {_.get(translation, "applicant_jobs_page.feedback_defenition_label")}
                    </div>
                </div>
                <div className="legend-row">
                    <Icon type="heart" />
                    <div
                        className="text"
                        dangerouslySetInnerHTML={{
                            __html: _.get(
                                translation,
                                "applicant_jobs_page.add_to_favorites_jobs_definition"
                            ),
                        }}
                    />
                </div>
            </div>
        );
    }
}

Legend.propTypes = {
    translation: PropTypes.object.isRequired,
};

export default Legend;
