import { connect } from "react-redux";
import { getTranslation } from "../../../../reducers/common/localization";
import {
    fetchGetOrganizationsList,
    fetchUpdateCompanyMetadata,
    getOrganizationsListState,
} from "../../../../reducers/organization/organizations";
import CompanyProfile from "./CompanyProfile";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        data: getOrganizationsListState(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onMount: () => {
            dispatch(fetchGetOrganizationsList());
        },
        onEditCompanyData: (data) => {
            dispatch(fetchUpdateCompanyMetadata(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);
