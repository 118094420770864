import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import { SkillsUtils } from "../../utils/skillsUtils";
import _ from "lodash";
import { fetchGetSuggestedProfiles } from "../profiles/suggestedProfiles";
import { fetchGetSuggestedSkills } from "../skillsAndClusters/suggestedSkills";
import {
    onFetchGetCurrentClusterSkillsForApplicant,
    cleanCurrentClusterSkills,
} from "../skillsAndClusters/currentClusterSkills";
import {
    getSuggestedClustersData,
    fetchGetSuggestedClusters,
} from "../skillsAndClusters/suggestedClusters";
import { getApplicantSelectedProfilesData } from "./applicantProfiles";

//- Actions
export const fetchGetApplicantSkillsFailed = createAction("FETCH_GET_APPLICANT_SKILLS_FAILED");
export const fetchGetApplicantSkillsSuccess = createAction("FETCH_GET_APPLICANT_SKILLS_SUCCESS");
export const onFetchGetApplicantSkillsSuccess = (withSuggestions) => (skills) => (dispatch) => {
    let formattedSkills = _.map(skills, (skill) => {
        return {
            ...skill.skill,
            id: skill.skill.id,
            title: skill.skill.titles.en,
            clusters: skill.skill.clusters
                ? skill.skill.clusters.map((cluster) => ({
                      clusterId: cluster.id,
                      clusterTitle: cluster.titles.en,
                  }))
                : [],
            categoryId: skill.skill.clusters.map((el) => el.id),
            // categoryTitle: skill.skill.clusters.map(el => el.titles.en),
            level: skill.level,
            eagerness: skill.eagerness,
            notes: skill.notes,
            version: skill.version,
            spokenLevel: skill.spokenLevel,
            understandingLevel: skill.understandingLevel,
            isNotModerated: skill.isNotModerated,
            skillLifecycle: skill.skillLifecycle,
        };
    });

    if (withSuggestions && formattedSkills && formattedSkills.length > 0) {
        dispatch(fetchGetSuggestedSkills(formattedSkills));
        dispatch(fetchGetSuggestedProfiles(formattedSkills));
    }
    dispatch(fetchGetApplicantSkillsSuccess(formattedSkills));
};
export const fetchGetApplicantSkills = createAction(
    "FETCH_GET_APPLICANT_SKILLS",
    () => Api.getApplicantSkills(),
    (withSuggestions) => ({
        apiCall: true,
        onSuccess: onFetchGetApplicantSkillsSuccess(withSuggestions),
        onFail: fetchGetApplicantSkillsFailed,
    })
);

export const fetchGetApplicantSkillsEventsFailed = createAction(
    "FETCH_GET_APPLICANT_SKILLS_EVENTS_FAILED"
);
export const fetchGetApplicantSkillsEventsSuccess = createAction(
    "FETCH_GET_APPLICANT_SKILLS_EVENTS_SUCCESS"
);
export const onFetchGetApplicantSkillsEventsSuccess = () => (skills) => (dispatch) => {
    return dispatch(fetchGetApplicantSkillsEventsSuccess(skills));
};
export const fetchGetApplicantSkillsEvents = createAction(
    "FETCH_GET_APPLICANT_SKILLS_EVENTS",
    () => Api.getApplicantSkillsEvents(),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetApplicantSkillsEventsSuccess(),
        onFail: fetchGetApplicantSkillsEventsFailed(),
    })
);
export const setCurrentSkill = createAction("SET_CURRENT_SKILL");
export const onChangeCurrentSkill = (skillId, clusterId) => (dispatch) => {
    dispatch(setCurrentSkill(skillId));
    dispatch(onSetCurrentCluster(clusterId));
};

export const setCurrentCluster = createAction("SET_CURRENT_CLUSTER");
export const onSetCurrentCluster =
    (clusterId, flags = {}) =>
    (dispatch, getState) => {
        let currentClusterId = getApplicantCurrentClusterId(getState());

        if (currentClusterId !== clusterId) {
            if (flags.isSuggestion) {
                let suggestedClusters = _.cloneDeep(getSuggestedClustersData(getState()));
                let emptyClusters = _.cloneDeep(getApplicantEmptyClustersData(getState())) || [];
                let clusterData = _.find(suggestedClusters, (cluster) => {
                    return cluster.categoryId === clusterId;
                });
                delete clusterData.isSuggestion;
                emptyClusters.push(clusterData);
                dispatch(updateApplicantEmptyClusters(emptyClusters));
            }
            dispatch(setCurrentCluster(clusterId));
            if (flags.isNotModerated) {
                dispatch(cleanCurrentClusterSkills());
            } else {
                dispatch(onFetchGetCurrentClusterSkillsForApplicant(clusterId));
            }
        }
        dispatch(setCurrentCluster(clusterId));
    };
export const onChangeCurrentCluster = (clusterId, flags) => (dispatch) => {
    dispatch(setCurrentSkill(null));
    dispatch(onSetCurrentCluster(clusterId, flags));
};

export const clearCurrentSkillAndCluster = () => (dispatch) => {
    dispatch(setCurrentSkill(null));
    dispatch(setCurrentCluster(null));
};

export const updateApplicantSkills = createAction("UPDATE_APPLICANT_SKILLS");

export const addApplicantSkill = (skillData, clusterId) => (dispatch, getState) => {
    let skills = getApplicantSkillsData(getState());
    let resultSkills = [...skills, skillData];

    dispatch(setCurrentSkill(skillData.id));
    let currentClusterId = getApplicantCurrentClusterId(getState());
    dispatch(onSetCurrentCluster(currentClusterId));

    let emptyClusters = getApplicantEmptyClustersData(getState());
    if (
        _.find(
            emptyClusters,
            (cluster) => !cluster.isNotModerated && cluster.categoryId === skillData.categoryId
        )
    ) {
        emptyClusters = _.filter(
            emptyClusters,
            (cluster) => cluster.categoryId !== skillData.categoryId
        );
        dispatch(updateApplicantEmptyClusters(emptyClusters));
    }

    dispatch(fetchGetSuggestedProfiles(resultSkills));
    dispatch(fetchGetSuggestedSkills(resultSkills));
    dispatch(updateApplicantSkills(resultSkills));
};

export const removeApplicantSkill = (skillId) => (dispatch, getState) => {
    let skills = getApplicantSkillsData(getState());
    let resultSkills = _.filter(skills, (skill) => skill.id !== skillId);

    let currentSkillId = getApplicantCurrentSkillId(getState());
    if (currentSkillId === skillId) {
        dispatch(clearCurrentSkillAndCluster());
    }

    dispatch(fetchGetSuggestedProfiles(resultSkills));
    dispatch(fetchGetSuggestedSkills(resultSkills));
    dispatch(updateApplicantSkills(resultSkills));
    dispatch(onSaveApplicantSkills());
};

export const removeApplicantCluster = (clusterId) => (dispatch, getState) => {
    let clusters = getSuggestedClustersData(getState());
    let emptyClusters = getApplicantEmptyClustersData(getState());
    let clusterData = _.find(clusters, (cluster) => cluster.categoryId === clusterId);

    if (clusterData) {
        emptyClusters = _.filter(emptyClusters, (cluster) => cluster.categoryId !== clusterId);
        dispatch(updateApplicantEmptyClusters(emptyClusters));
        dispatch(deleteApplicantCluster(clusterId));
    }
    if (!clusterData || clusterData.isNotModerated) {
        let skills = getApplicantSkillsData(getState());
        let resultSkills = _.filter(skills, (skill) => skill.categoryId !== clusterId);

        dispatch(fetchGetSuggestedProfiles(resultSkills));
        dispatch(fetchGetSuggestedSkills(resultSkills));
        dispatch(updateApplicantSkills(resultSkills));
    }

    let currentClusterId = getApplicantCurrentClusterId(getState());
    if (currentClusterId === clusterId) {
        dispatch(clearCurrentSkillAndCluster());
    }
};

export const updateApplicantSkillProperty = (key, value, mergeObject) => (dispatch, getState) => {
    let resultSkills = _.cloneDeep(getApplicantSkillsData(getState()));
    let currentSkillId = getApplicantCurrentSkillId(getState());
    let skill = _.find(resultSkills, (elem) => elem.id === currentSkillId);

    if (mergeObject) {
        skill[key] = { ...skill[key], ...value };
    } else {
        skill[key] = value;
    }

    dispatch(updateApplicantSkills(resultSkills));
};

export const updateApplicantEmptyClusters = createAction("UPDATE_APPLICANT_EMPTY_CLUSTERS");

export const addEmptyCluster = (cluster) => (dispatch, getState) => {
    let emptyClusters = _.cloneDeep(getApplicantEmptyClustersData(getState())) || [];
    emptyClusters.push(cluster);
    dispatch(updateApplicantEmptyClusters(emptyClusters));
    dispatch(onSetCurrentCluster(cluster.categoryId, { isNotModerated: cluster.isNotModerated }));
};

// TODO: (BE) save empty clusters
// TODO: (BE) save not moderated skills and empty clusters
// TODO: (BE) it will be better to have one request to update skills
// TODO: sync add/remove requests (fetchGetApplicantSkills duplication)
export const fetchAddApplicantSkillsFailed = createAction("FETCH_ADD_APPLICANT_SKILLS_FAILED");
export const fetchAddApplicantSkillsSuccess = createAction("FETCH_ADD_APPLICANT_SKILLS_SUCCESS");
export const onFetchAddApplicantSkillsSuccess = () => (dispatch, getState) => {
    dispatch(clearCurrentSkillAndCluster());
    dispatch(fetchAddApplicantSkillsSuccess());
    dispatch(fetchGetApplicantSkills());
    dispatch(fetchGetSuggestedClusters(getApplicantSelectedProfilesData(getState())));
    // window.location.reload();
};
export const fetchDeleteApplicantClusterSuccess = createAction(
    "FETCH_DELETE_APPLICANT_CLUSTER_SUCCESS"
);
export const fetchDeleteApplicantClusterFailed = createAction(
    "FETCH_DELETE_APPLICANT_CLUSTER_FAILED"
);
export const onFetchDeleteApplicantClusterSuccess = () => (dispatch, getState) => {
    dispatch(fetchDeleteApplicantClusterSuccess());
    dispatch(fetchGetApplicantSkills());
    dispatch(fetchGetSuggestedClusters(getApplicantSelectedProfilesData(getState())));
};
export const deleteApplicantCluster = createAction(
    "FETCH_DELETE_APPLICANT_CLUSTER",
    (data) => Api.deleteSuggestedCluster(data),
    () => ({
        apiCall: true,
        onSuccess: onFetchDeleteApplicantClusterSuccess,
        onFail: fetchDeleteApplicantClusterFailed,
    })
);
export const fetchAddApplicantClusterSuccess = createAction("FETCH_ADD_APPLICANT_CLUSTER_SUCCESS");
export const fetchAddApplicantClusterFailed = createAction("FETCH_ADD_APPLICANT_CLUSTER_FAILED");
export const onFetchAddApplicantClusterSuccess = () => (dispatch, getState) => {
    dispatch(fetchAddApplicantClusterSuccess());
    dispatch(fetchGetApplicantSkills());
    dispatch(fetchGetSuggestedClusters(getState().applicantProfiles.selectedProfiles));
};
export const addApplicantCluster = createAction(
    "FETCH_ADD_APPLICANT_CLUSTER",
    (data, profileId) => Api.addNewCluster(data, profileId),
    () => ({
        apiCall: true,
        onSuccess: onFetchAddApplicantClusterSuccess,
        onFail: fetchAddApplicantClusterFailed,
    })
);
export const fetchAddApplicantSkills = createAction(
    "FETCH_ADD_APPLICANT_SKILLS",
    (data, profileId) => Api.addApplicantSkills(data, profileId),
    () => ({
        apiCall: true,
        onSuccess: onFetchAddApplicantSkillsSuccess,
        onFail: fetchAddApplicantSkillsFailed,
    })
);
export const fetchAddApplicantNewSkill = createAction(
    "FETCH_ADD_APPLICANT_NEW_SKILLS",
    (data, profileId) => Api.addNewSkill(data, profileId),
    () => ({
        apiCall: true,
        onSuccess: onFetchAddApplicantSkillsSuccess,
        onFail: fetchAddApplicantSkillsFailed,
    })
);
export const fetchRemoveApplicantSkillsFailed = createAction(
    "FETCH_REMOVE_APPLICANT_SKILLS_FAILED"
);
export const fetchRemoveApplicantSkillsSuccess = createAction(
    "FETCH_REMOVE_APPLICANT_SKILLS_SUCCESS"
);
export const onFetchRemoveApplicantSkillsSuccess = () => (dispatch) => {
    dispatch(fetchRemoveApplicantSkillsSuccess());
    dispatch(fetchGetApplicantSkills());
};
export const fetchRemoveApplicantSkills = createAction(
    "FETCH_REMOVE_APPLICANT_SKILLS",
    (data) => Api.removeApplicantSkills(data),
    () => ({
        apiCall: true,
        onSuccess: onFetchRemoveApplicantSkillsSuccess,
        onFail: fetchRemoveApplicantSkillsFailed,
    })
);

export const onAddNewCluster = (cluster) => (dispatch, getState) => {
    const selectProfileId = getApplicantSelectedProfilesData(getState());
    dispatch(addApplicantCluster(cluster, selectProfileId));
};

export const onFetchGetProfilesBySkillIdSuccess = createAction(
    "FETCH_GET_PROFILES_BY_SKILL_ID_SUCCESS"
);
export const onFetchGetProfilesBySkillIdFail = createAction("FETCH_GET_PROFILES_BY_SKILL_ID_FAIL");

export const fetchGetProfilesByClusterId = createAction(
    "FETCH_GET_PROFILES_BY_SKILL_ID",
    (clusterId) => Api.getProfilesBySkillCluster(clusterId),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetProfilesBySkillIdSuccess,
        onFail: onFetchGetProfilesBySkillIdFail,
    })
);

export const onAddNewSkill = (skill) => (dispatch, getState) => {
    const selectProfileId = getApplicantSelectedProfilesData(getState());
    let skillData = {
        ...skill,
        level: skill.level,
        eagerness: skill.eagerness,
        version: skill.version,
        clusterId: skill.clusters ? skill.clusters[0].clusterId : skill.categoryId,
        resourceType: skill.resourceType,
        titles: { en: skill.title },
    };
    dispatch(fetchAddApplicantNewSkill(skillData, selectProfileId));
};

export const onSaveApplicantSkills = (profileId) => (dispatch, getState) => {
    let initialSkillsData = _.cloneDeep(getApplicantInitialSkillsData(getState()));
    let skillsData = _.cloneDeep(getApplicantSkillsData(getState()));
    const selectProfileId = getApplicantSelectedProfilesData(getState());
    let toDelete = [];
    let toInsert = [];

    _.map(initialSkillsData, (skill) => {
        if (!skillsData || !skillsData.find((elem) => elem.id === skill.id)) {
            toDelete.push(skill.id);
        }
    });
    _.map(skillsData, (skill) => {
        if (!skill.clusters.length) {
            // remove it when skills will came back from BE side with clusters
            return;
        }
        let initialSkill = _.find(initialSkillsData, (elem) => elem.id === skill.id);
        if (
            !initialSkillsData ||
            !initialSkill ||
            initialSkill.level !== skill.level ||
            initialSkill.eagerness !== skill.eagerness ||
            initialSkill.spokenLevel !== skill.spokenLevel ||
            initialSkill.understandingLevel !== skill.understandingLevel ||
            !_.isEqual(initialSkill.notes, skill.notes) ||
            !_.isEqual(initialSkill.version, skill.version) ||
            !_.isEqual(initialSkill.skillLifecycle, skill.skillLifecycle)
        ) {
            let skillData = {
                ...skill,
                skill: {
                    id: skill.id,
                    skillId: skill.id,
                    clusterId: skill.clusterId,
                },
                skillId: skill.id,
                level: skill.level,
                eagerness: skill.eagerness,
                notes: skill.notes,
                version: skill.version,
                spokenLevel: skill.spokenLevel,
                understandingLevel: skill.understandingLevel,
                skillLifecycle: skill.skillLifecycle,
                clusterId: skill.clusters ? skill.clusters[0].clusterId : skill.categoryId,
            };

            toInsert.push(skillData);
        }
    });

    if (toInsert.length > 0) {
        dispatch(
            fetchAddApplicantSkills(
                toInsert,
                selectProfileId.length ? selectProfileId : [profileId]
            )
        );
    }
    if (toDelete.length > 0) {
        dispatch(fetchRemoveApplicantSkills(toDelete));
    }
};

export const updateApplicantSkill = createAction("UPDATE_APPLICANT_SKILL");

export const fetchUpdateSkillVersionList = createAction(
    "FETCH_UPDATE_SKILL_VERSION_LIST",
    (skillId, versionTitle) => Api.defineNewSkillVersion(skillId, versionTitle),
    () => ({
        apiCall: true,
        onSuccess: updateApplicantSkill,
        onFail: () => {},
    })
);

//- State
const initialState = {
    initialSkillsData: null,
    skillsData: null,
    currentSkillId: null,
    currentClusterId: null,
    emptyClusters: null,
    error: null,
    fetching: false,
    fetchingAddSkills: false,
    fetchingRemoveSkills: false,
    profiles: null,
};

//- Reducers
export default handleActions(
    {
        FETCH_GET_APPLICANT_SKILLS: (state) => {
            return { ...state, error: null, fetching: true };
        },
        FETCH_GET_APPLICANT_SKILLS_FAILED: (state, action) => {
            return {
                ...state,
                skillsData: null,
                initialSkillsData: null,
                error: action.payload,
                fetching: false,
            };
        },

        UPDATE_APPLICANT_SKILL: (state, action) => ({
            ...state,
            skillsData: state.skillsData.map((skill) =>
                action.payload.id === skill.id
                    ? {
                          ...action.payload,
                      }
                    : { ...skill }
            ),
        }),
        FETCH_GET_PROFILES_BY_SKILL_ID_SUCCESS: (state, action) => ({
            ...state,
            profiles: action.payload.content,
        }),
        FETCH_GET_APPLICANT_SKILLS_SUCCESS: (state, action) => {
            return {
                ...state,
                skillsData: action.payload,
                initialSkillsData: action.payload,
                error: null,
                fetching: false,
            };
        },
        FETCH_GET_APPLICANT_SKILLS_EVENTS_SUCCESS: (state, action) => {
            return {
                ...state,
                lqGraphData: action.payload,
            };
        },
        FETCH_GET_APPLICANT_SKILLS_EVENTS_FAILED: (state, action) => {
            return {
                ...state,
                error: action.payload,
            };
        },
        FETCH_ADD_APPLICANT_SKILLS: (state) => {
            return { ...state, error: null, fetchingAddSkills: true };
        },
        FETCH_ADD_APPLICANT_NEW_SKILLS: (state) => {
            return { ...state, error: null, fetchingAddSkills: true };
        },
        FETCH_ADD_APPLICANT_SKILLS_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetchingAddSkills: false };
        },
        FETCH_ADD_APPLICANT_SKILLS_SUCCESS: (state) => {
            return { ...state, error: null, fetchingAddSkills: false };
        },
        FETCH_REMOVE_APPLICANT_SKILLS: (state) => {
            return { ...state, error: null, fetchingRemoveSkills: true };
        },
        FETCH_REMOVE_APPLICANT_SKILLS_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetchingRemoveSkills: false };
        },
        FETCH_REMOVE_APPLICANT_SKILLS_SUCCESS: (state) => {
            return { ...state, error: null, fetchingRemoveSkills: false };
        },
        SET_CURRENT_SKILL: (state, action) => {
            return { ...state, currentSkillId: action.payload };
        },
        SET_CURRENT_CLUSTER: (state, action) => {
            return { ...state, currentClusterId: action.payload };
        },
        UPDATE_APPLICANT_SKILLS: (state, action) => {
            return { ...state, skillsData: action.payload };
        },
        UPDATE_APPLICANT_EMPTY_CLUSTERS: (state, action) => {
            return { ...state, emptyClusters: action.payload };
        },
        FETCH_DELETE_APPLICANT_CLUSTER_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetchingDeleteCluster: false };
        },
        FETCH_DELETE_APPLICANT_CLUSTER_SUCCESS: (state) => {
            return { ...state, error: null, fetchingDeleteCluster: false };
        },
        FETCH_ADD_APPLICANT_CLUSTER_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetchingAddCluster: false };
        },
        FETCH_ADD_APPLICANT_CLUSTER_SUCCESS: (state) => {
            return { ...state, error: null, fetchingAddCluster: false };
        },
    },
    initialState
);

//- Selectors
export const getApplicantSkillsState = (state) => state.applicantSkills;

export const getProfilesByClusterId = (state) => getApplicantSkillsState(state).profiles;

export const getApplicantInitialSkillsData = (state) =>
    getApplicantSkillsState(state).initialSkillsData;

export const getApplicantSkillsInitialFetchingStatus = (state) =>
    getApplicantSkillsFetchingStatus(state) && _.isNil(getApplicantInitialSkillsData(state));

export const getApplicantAddSkillsFetchingStatus = (state) =>
    getApplicantSkillsState(state).fetchingAddSkills;

export const getApplicantRemoveSkillsFetchingStatus = (state) =>
    getApplicantSkillsState(state).fetchingRemoveSkills;

export const getApplicantSaveSkillsFetchingStatus = (state) =>
    getApplicantAddSkillsFetchingStatus(state) || getApplicantRemoveSkillsFetchingStatus(state);

export const getApplicantSkillsData = (state) => getApplicantSkillsState(state).skillsData;

export const getApplicantSkillsLQGraphData = (state) => state.applicantSkills.lqGraphData;
export const getApplicantSkillsDataTree = (state) => {
    let skills = getApplicantSkillsData(state);
    if (!skills) {
        return null;
    }

    let suggestedClusters = getSuggestedClustersData(state) || [];
    let emptyClusters = getApplicantEmptyClustersData(state) || [];
    suggestedClusters = _.filter(suggestedClusters, (cluster) => {
        return (
            !_.find(
                emptyClusters,
                (emptyCluster) => emptyCluster.categoryId === cluster.categoryId
            ) && !_.find(skills, (skill) => skill.categoryId === cluster.categoryId)
        );
    });
    _.forEach(suggestedClusters, (cluster) => (cluster.isSuggestion = true));

    return SkillsUtils.createSkillsTree(skills, suggestedClusters);
};

export const getApplicantSkillsFetchingStatus = (state) => getApplicantSkillsState(state).fetching;

export const getApplicantCurrentSkillId = (state) => getApplicantSkillsState(state).currentSkillId;

export const getApplicantCurrentSkillData = (state) => {
    let data = getApplicantSkillsData(state);
    let id = getApplicantCurrentSkillId(state);
    let skill = _.find(data, (elem) => elem.id === id);
    return skill;
};

export const getApplicantCurrentClusterId = (state) =>
    getApplicantSkillsState(state).currentClusterId;

export const getApplicantCurrentClusterData = (state) => {
    let data = getApplicantSkillsData(state);
    let clusterId = getApplicantCurrentClusterId(state);
    let skill = _.find(data, (elem) => elem.categoryId === clusterId);
    if (skill) {
        return {
            categoryId: skill.categoryId,
            categoryTitle: skill.categoryTitle,
        };
    } else {
        let emptyClusters = getApplicantEmptyClustersData(state);
        return _.find(emptyClusters, (elem) => elem.categoryId === clusterId);
    }
};

export const getApplicantEmptyClustersData = (state) =>
    getApplicantSkillsState(state).emptyClusters;

export const getApplicantClustersData = (state) => {
    let emptyClusters = getApplicantEmptyClustersData(state) || [];
    let skills = getApplicantSkillsData(state);
    let clusters = [...emptyClusters];

    _.mapValues(_.groupBy(skills, "categoryId"), (value) => {
        let firstChild = value[0];
        let cluster = {
            categoryId: firstChild.categoryId,
            categoryTitle: firstChild.categoryTitle,
        };
        clusters.push(cluster);
    });

    return clusters;
};
