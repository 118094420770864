import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import "./ApplicantAccount.scss";
import _ from "lodash";
import {
    Button,
    TabContent,
    TabPane,
    Form,
    FormGroup,
    Label,
    Row,
    Input,
    Col,
    Badge,
    Modal,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import CustomSelect from "../../common/inputs/customSelect/CustomSelect";
import ContinentAndCountryFormGroups from "../../common/formGroups/continentAndCountryFormGroups/ContinentAndCountryFormGroups.container";
import Icon from "../../common/icon/Icon";
import CustomSwitch from "../../common/controls/customSwitch/CustomSwitch";
import { Link } from "react-router-dom";
import { FormUtils } from "../../../utils/formUtils";
import {
    DISTURBING_TIME_OPTION,
    EXPOSE_NID_OPTION,
    CURRENT_SITUATION_OPTION,
    JOB_LEVEL_OPTION,
    NOTIFICATIONS_FREQUENCY_OPTION,
    NOTIFY_BY_OPTION,
    CURRENCY,
    CURRENCY_SIGN,
    FOOD_OPTION,
    ROUTES,
    COMMUTE_TIME_OPTION,
    COMMUTE_OPTION,
    START_WORK_PERIOD_OPTION,
    COMMUTE_TIME_OPTION_TRANSLATION,
    COMMUTE_OPTION_SHORT_TRANSLATION,
    START_WORK_PERIOD_OPTION_TRANSLATION,
    PREFERRED_CONTRACT_TYPE,
} from "../../../constants/constants";
import { Utils } from "../../../utils/utils";
import { v4 as uuidv4 } from "uuid";
import { getFormData } from "../../../utils/formData";
import RichMedia from "../applicantJobs/richMedia/RichMedia.container";

const ACCOUNT_SETUP_STEPS = {
    JOB_RELATED_DATA: "JOB_RELATED_DATA",
    LOCATION_AND_COMMUTE_DATA: "LOCATION_AND_COMMUTE_DATA",
    RICH_MEDIA: "RICH_MEDIA",
};

const REQUIRED_FIELDS = {
    [ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA]: [
        "firstName",
        "lastName",
        "email",
        "currentSituation",
        "jobTitle",
        "experienceLevel",
        "notificationsFrequency",
        "notifyBy",
    ],
    [ACCOUNT_SETUP_STEPS.LOCATION_AND_COMMUTE_DATA]: ["countryCode", "city"],
    [ACCOUNT_SETUP_STEPS.RICH_MEDIA]: ["profilePictureFileURI"],
};

class ApplicantAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA,
            firstName: props.userData.firstName || "",
            lastName: props.userData.lastName || "",
            email: props.userData.email || "",
            phoneNumber: props.userData.phoneNumber || "",
            nationalId: props.userData.nationalId || null,
            exposeNationalId: props.userData.exposeNationalId || null,
            cvFile: props.userData.cvFile || "",
            disturbingTime: props.userData.disturbingTime || null,
            currentSituation: props.userData.currentSituation || null,
            jobTitle: props.userData.jobTitle || "",
            experienceLevel: props.userData.experienceLevel || null,
            notificationsFrequency: props.userData.notificationsFrequency || null,
            instantNotification: props.userData.instantNotification || false,
            notifyBy: props.userData.notifyBy || null,
            isSetMinSalary: props.userData.isSetMinSalary || false,
            minSalary: props.userData.minSalary || "",
            minSalaryCurrency: props.userData.minSalaryCurrency || CURRENCY.USD,
            isIncludeWorkOut: props.userData.isIncludeWorkOut || false,
            isIncludeSpecialFood: props.userData.isIncludeSpecialFood || false,
            specialFoodType: props.userData.specialFoodType || null,
            isIncludeTrainingBudget: props.userData.isIncludeTrainingBudget || false,
            isChooseProjectToContribute: props.userData.isChooseProjectToContribute || false,
            isSetOtherJobOpportunity: props.userData.isSetOtherJobOpportunity || false,
            otherJobOpportunity: props.userData.otherJobOpportunity || "",
            countryCode: props.userData.countryCode || null,
            city: props.userData.city || "",
            district: props.userData.district || "",
            street: props.userData.street || "",
            maxCommuteTime: props.userData.maxCommuteTime || null,
            dreamLocations: props.userData.dreamLocations || [],
            dreamLocationRegion: "",
            dreamLocationCountry: "",
            dreamLocationCity: "",
            dreamLocationDistrict: "",
            dreamLocationCommute: null,
            dreamLocationCommuteTime: null,
            dreamLocationStartPeriod: null,
            dreamLocationWorkRegime: 100,
            percentageOfHomeWorking: props.userData.percentageOfHomeWorking || 100,
            twitterLink: props.userData.twitterLink || "",
            linkedInLink: props.userData.linkedInLink || "",
            facebookLink: props.userData.facebookLink || "",
            emptyFieldError: null,
            preferredContractType: props.userData.preferredContractType || null,
            profilePictureFileURI: props.userDataNotFormatted.profilePictureFileURI || null,
            pictureFilesURI: props.userDataNotFormatted.pictureFilesURI || null,
            videoFileURI: props.userDataNotFormatted.videoFileURI || null,
            cvFileURI: props.userDataNotFormatted.cvFileURI || null,
            audioFileURI: props.userDataNotFormatted.audioFileURI || null,
        };

        this.containerRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                profilePictureFileURI:
                    this.props.userDataNotFormatted.profilePictureFileURI || null,
                pictureFilesURI: this.props.userDataNotFormatted.pictureFilesURI || null,
                videoFileURI: this.props.userDataNotFormatted.videoFileURI || null,
                cvFileURI: this.props.userDataNotFormatted.cvFileURI || null,
                audioFileURI: this.props.userDataNotFormatted.audioFileURI || null,
            });
        }
    }

    uploadMediaFiles = () => {
        const videoFile = this.state.videoFile ? { videoFile: this.state.videoFile } : {};
        const profilePictureFile = this.state.profilePictureFile
            ? { profilePictureFile: this.state.profilePictureFile }
            : {};
        const audioFile = this.state.audioFile ? { audioFile: this.state.audioFile } : {};
        const cvFile = this.state.cvFile ? { cvFile: this.state.cvFile } : {};
        const pictureFiles = !_.isEmpty(this.state.pictures)
            ? { pictureFiles: this.state.pictures }
            : {};

        const newMediaData = {
            ...videoFile,
            ...profilePictureFile,
            ...audioFile,
            ...pictureFiles,
            ...cvFile,
        };

        if (!_.isEmpty(newMediaData)) {
            const formData = getFormData(newMediaData);

            this.props.onUploadMediaUser(formData);
        }
    };

    uploadUserData = () => {
        const data = {
            email: this.state.email,
            identityAlias: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phoneNumber: this.state.phoneNumber,
                exposePhone: true,
            },
            address: {
                countryCode: this.state.countryCode,
                city: this.state.city,
                district: this.state.district,
                street: this.state.street,
            },
            preferredJobDescription: {
                nationalId: this.state.nationalId,
                exposeNationalIdLevel: this.state.exposeNationalId,
                distributingTime: this.state.disturbingTime,
                currentSituation: this.state.currentSituation,
                jobTitle: this.state.jobTitle,
                experienceLevel: this.state.experienceLevel,
                notificationsFrequency: this.state.notificationsFrequency,
                instantNotification: this.state.instantNotification,
                notifyBy: this.state.notifyBy,
                percentageOfHomeWorking: this.state.percentageOfHomeWorking,
                minSalary: {
                    amount: this.state.minSalary,
                    currency: this.state.minSalaryCurrency,
                },
                specialFoodType: this.state.specialFoodType,
                otherJobOpportunity: this.state.otherJobOpportunity,
                maxCommuteTime: this.state.maxCommuteTime,
                contractType: this.state.preferredContractType,
                locations: this.state.dreamLocations,
                chooseProjectToContribute: this.state.isChooseProjectToContribute,
                includeTrainingBudget: this.state.isIncludeTrainingBudget,
                includeWorkOut: this.state.isIncludeWorkOut,
            },
            videoLink: this.state.videoLink,
            twitterURI: this.state.twitterLink,
            linkedinURI: this.state.linkedInLink,
            facebookURI: this.state.facebookLink,
        };

        this.props.onUpdateUser(data);
    };

    render() {
        return (
            <div className="applicant-account">
                {/* <RequestStatus /> */}
                <div className="account-header-container">
                    <h4 className="page-header">
                        {_.get(this.props.translation, "applicant_account_page.page_label")}
                    </h4>
                    <Button
                        className="save-changes-button"
                        color="primary"
                        onClick={this.onSaveChanges}
                    >
                        {_.get(this.props.translation, "actions.save_button")}
                    </Button>
                </div>
                {this.renderContent()}
                {this.renderEmptyFiledErrorModal()}
            </div>
        );
    }

    renderEmptyFiledErrorModal() {
        return (
            <Modal isOpen={!_.isNil(this.state.emptyFieldError)} toggle={this.onCloseModal}>
                <ModalBody>
                    {Utils.getTagsFromStringWithStyles(
                        _.replace(
                            _.get(
                                this.props.translation,
                                "applicant_account_page.empty_contact_label"
                            ),
                            "{fieldName}",
                            _.get(
                                this.props.translation,
                                "person_data." + this.state.emptyFieldError
                            )
                        )
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.onCloseModal}>
                        {_.get(this.props.translation, "actions.ok_button")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    onCloseModal = () => {
        this.setState({ emptyFieldError: null });
    };

    renderContent() {
        let tabsOptions = [
            {
                value: ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA,
                label: _.get(
                    this.props.translation,
                    "applicant_account_page.job_related_tab_label"
                ),
                progress: this.getTabCompleteProgress(ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA),
            },
            {
                value: ACCOUNT_SETUP_STEPS.LOCATION_AND_COMMUTE_DATA,
                label: _.get(
                    this.props.translation,
                    "applicant_account_page.location_and_commute_tab_label"
                ),
                progress: this.getTabCompleteProgress(
                    ACCOUNT_SETUP_STEPS.LOCATION_AND_COMMUTE_DATA
                ),
            },
            {
                value: ACCOUNT_SETUP_STEPS.RICH_MEDIA,
                label: _.get(this.props.translation, "applicant_account_page.rich_media_tab_label"),
                progress: this.getTabCompleteProgress(ACCOUNT_SETUP_STEPS.RICH_MEDIA),
            },
        ];

        return (
            <>
                <CustomSwitch
                    selectedOption={_.find(
                        tabsOptions,
                        (option) => option.value === this.state.activeTab
                    )}
                    options={tabsOptions}
                    onChange={this.handleChangeTab}
                    circleColor={"red"}
                />
                <div className="applicant-account-content-divider" />
                <TabContent
                    className="applicant-account-content"
                    activeTab={this.state.activeTab}
                    ref={this.containerRef}
                >
                    <TabPane tabId={ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA}>
                        {this.renderJobRelatedTab()}
                    </TabPane>
                    <TabPane tabId={ACCOUNT_SETUP_STEPS.LOCATION_AND_COMMUTE_DATA}>
                        {this.renderLocationAndCommuteTab()}
                    </TabPane>
                    <TabPane tabId={ACCOUNT_SETUP_STEPS.RICH_MEDIA}>
                        {this.renderRichMediaTab()}
                    </TabPane>
                </TabContent>
            </>
        );
    }

    renderJobRelatedTab() {
        let formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField,
            onSelectChange: this.handleChangeSelectField,
        };

        let aboutYouParams = [
            {
                fieldName: "firstName",
                title: _.get(this.props.translation, "person_data.first_name_label"),
                isRequired:
                    REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].includes("firstName"),
            },
            {
                fieldName: "lastName",
                title: _.get(this.props.translation, "person_data.last_name_label"),
                isRequired:
                    REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].includes("lastName"),
            },
            {
                fieldName: "email",
                title: _.get(this.props.translation, "person_data.personal_email_label"),
                type: "email",
                isRequired: REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].includes("email"),
                isDisabled: true,
            },
            {
                fieldName: "phoneNumber",
                title: _.get(this.props.translation, "person_data.phone_number_label"),
                isRequired:
                    REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].includes("phoneNumber"),
            },
            {
                fieldName: "nationalId",
                title: _.get(this.props.translation, "person_data.national_id_label"),
                isRequired:
                    REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].includes("nationalId"),
            },
            {
                fieldName: "exposeNationalId",
                title: _.get(this.props.translation, "person_data.expose_national_id_label"),
                type: "select",
                isRequired:
                    REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].includes(
                        "exposeNationalId"
                    ),
                options: [
                    {
                        value: EXPOSE_NID_OPTION.NOT_EXPOSE,
                        label: _.get(
                            this.props.translation,
                            "person_data.expose_nid_option_not_expose"
                        ),
                    },
                    {
                        value: EXPOSE_NID_OPTION.EXPOSE_SHORT,
                        label: _.get(this.props.translation, "person_data.expose_nid_option_short"),
                    },
                    {
                        value: EXPOSE_NID_OPTION.EXPOSE_FULL,
                        label: _.get(this.props.translation, "person_data.expose_nid_option_full"),
                    },
                ],
            },
        ];

        let disturbingTimeParams = {
            fieldName: "disturbingTime",
            title: _.get(this.props.translation, "applicant_account_page.disturbing_time_label"),
            type: "select",
            isRequired:
                REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].includes("disturbingTime"),
            options: [
                {
                    value: DISTURBING_TIME_OPTION.ANY_TIME,
                    label: _.get(this.props.translation, "disturbing_time.any_time"),
                },
                {
                    value: DISTURBING_TIME_OPTION.EARLY,
                    label: _.get(this.props.translation, "disturbing_time.early"),
                },
                {
                    value: DISTURBING_TIME_OPTION.MORNING,
                    label: _.get(this.props.translation, "disturbing_time.morning"),
                },
                {
                    value: DISTURBING_TIME_OPTION.LUNCH_BREAK,
                    label: _.get(this.props.translation, "disturbing_time.lunch_break"),
                },
                {
                    value: DISTURBING_TIME_OPTION.AFTERNOON,
                    label: _.get(this.props.translation, "disturbing_time.afternoon"),
                },
                {
                    value: DISTURBING_TIME_OPTION.EVENING,
                    label: _.get(this.props.translation, "disturbing_time.evening"),
                },
                {
                    value: DISTURBING_TIME_OPTION.MAIL_ONLY,
                    label: _.get(this.props.translation, "disturbing_time.mail_only"),
                },
            ],
        };

        let aboutSituationParams = [
            {
                fieldName: "currentSituation",
                title: _.get(
                    this.props.translation,
                    "applicant_account_page.current_situation_label"
                ),
                type: "select",
                isRequired:
                    REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].includes(
                        "currentSituation"
                    ),
                options: [
                    {
                        value: CURRENT_SITUATION_OPTION.ACTIVE,
                        label: _.get(this.props.translation, "job_situation.active"),
                    },
                    {
                        value: CURRENT_SITUATION_OPTION.PASSIVE,
                        label: _.get(this.props.translation, "job_situation.passive"),
                    },
                    {
                        value: CURRENT_SITUATION_OPTION.EMPLOYED_BUT_OPEN_FOR_BETTER_JOB,
                        label: _.get(
                            this.props.translation,
                            "job_situation.employed_but_open_for_better_job"
                        ),
                    },
                    {
                        value: CURRENT_SITUATION_OPTION.EMPLOYED_BUT_LOOKING_FOR_NEXT_STEP,
                        label: _.get(
                            this.props.translation,
                            "job_situation.employed_but_looking_for_next_step"
                        ),
                    },
                    {
                        value: CURRENT_SITUATION_OPTION.EMPLOYED_BUT_LOOKING_FOR_EASIER_JOB,
                        label: _.get(
                            this.props.translation,
                            "job_situation.employed_but_looking_for_easier_job"
                        ),
                    },
                    {
                        value: CURRENT_SITUATION_OPTION.EMPLOYED,
                        label: _.get(this.props.translation, "job_situation.employed"),
                    },
                    {
                        value: CURRENT_SITUATION_OPTION.RETIRED,
                        label: _.get(this.props.translation, "job_situation.retired"),
                    },
                ],
            },
            {
                fieldName: "jobTitle",
                title: _.get(this.props.translation, "applicant_account_page.job_title_label"),
                placeholder: _.get(this.props.translation, "job.job_title_label"),
                isRequired:
                    REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].includes("jobTitle"),
            },
            {
                fieldName: "experienceLevel",
                title: _.get(
                    this.props.translation,
                    "applicant_account_page.years_of_experience_label"
                ),
                isRequired:
                    REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].includes(
                        "experienceLevel"
                    ),
                type: "select",
                options: [
                    {
                        value: JOB_LEVEL_OPTION.JUNIOR,
                        label: _.get(
                            this.props.translation,
                            "skill_properties.levels_values.junior_with_short_range"
                        ),
                    },
                    {
                        value: JOB_LEVEL_OPTION.MEDIOR,
                        label: _.get(
                            this.props.translation,
                            "skill_properties.levels_values.medior_with_short_range"
                        ),
                    },
                    {
                        value: JOB_LEVEL_OPTION.SENIOR,
                        label: _.get(
                            this.props.translation,
                            "skill_properties.levels_values.senior_with_short_range"
                        ),
                    },
                    {
                        value: JOB_LEVEL_OPTION.NINJA,
                        label: _.get(
                            this.props.translation,
                            "skill_properties.levels_values.ninja_with_short_range"
                        ),
                    },
                ],
            },
        ];

        let notificationsFrequencyParams = {
            fieldName: "notificationsFrequency",
            title: _.get(
                this.props.translation,
                "applicant_account_page.notifications_frequency_label"
            ),
            type: "select",
            isRequired:
                REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].includes(
                    "notificationsFrequency"
                ),
            options: [
                {
                    value: NOTIFICATIONS_FREQUENCY_OPTION.DAILY,
                    label: _.get(this.props.translation, "shared.daily_label"),
                },
                {
                    value: NOTIFICATIONS_FREQUENCY_OPTION.WEEKLY,
                    label: _.get(this.props.translation, "shared.weekly_label"),
                },
                {
                    value: NOTIFICATIONS_FREQUENCY_OPTION.MONTHLY,
                    label: _.get(this.props.translation, "shared.monthly_label"),
                },
            ],
        };

        let notifyByParams = {
            fieldName: "notifyBy",
            title: _.get(this.props.translation, "applicant_account_page.notify_by_label"),
            isRequired: REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].includes("notifyBy"),
            type: "select",
            options: [
                {
                    value: NOTIFY_BY_OPTION.PHONE,
                    label: _.get(this.props.translation, "person_data.phone_label"),
                },
                {
                    value: NOTIFY_BY_OPTION.EMAIL,
                    label: _.get(this.props.translation, "person_data.email_label"),
                },
                {
                    value: NOTIFY_BY_OPTION.TWITTER,
                    label: _.get(this.props.translation, "person_data.twitter_label"),
                },
                {
                    value: NOTIFY_BY_OPTION.LINKEDIN,
                    label: _.get(this.props.translation, "person_data.linkedin_label"),
                },
                {
                    value: NOTIFY_BY_OPTION.FACEBOOK,
                    label: _.get(this.props.translation, "person_data.facebook_label"),
                },
            ],
        };

        let currenciesOptions = [
            { value: CURRENCY.USD, label: CURRENCY_SIGN[CURRENCY.USD] },
            { value: CURRENCY.EUR, label: CURRENCY_SIGN[CURRENCY.EUR] },
        ];

        let foodOptions = [
            {
                value: FOOD_OPTION.HEALTHY,
                label: _.get(this.props.translation, "job.healthy_food_label"),
            },
            {
                value: FOOD_OPTION.VEGAN,
                label: _.get(this.props.translation, "job.vegan_food_label"),
            },
            {
                value: FOOD_OPTION.ALLERGY_CONSCIOUS,
                label: _.get(this.props.translation, "job.allergy_conscious_food_label"),
            },
        ];

        return (
            <div className="tab-content-container">
                <h5 className="text-center">
                    {_.get(this.props.translation, "applicant_account_page.job_related_tab_label")}
                </h5>
                <h5 className="page-subsection-header">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.about_you_section_label"
                    )}
                </h5>
                {/* <div className="subsection-hint">
                    {_.get(this.props.translation, "applicant_account_page.about_you_section_hint")}
                </div> */}
                {_.map(aboutYouParams, (fieldParams) =>
                    FormUtils.renderFormGroup(fieldParams, formParams)
                )}
                {this.renderInlineSelectFormGroup(disturbingTimeParams)}
                <h5 className="page-subsection-header">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.about_current_situation_label"
                    )}
                </h5>
                {/* <div className="subsection-hint">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.about_current_situation_hint"
                    )}
                </div> */}
                {_.map(aboutSituationParams, (fieldParams) =>
                    this.renderInlineSelectFormGroup(fieldParams)
                )}

                <h5 className="page-subsection-header">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.past_and_future_opportunities_label"
                    )}
                </h5>
                {/* <div className="subsection-hint">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.past_and_future_opportunities_hint"
                    )}
                </div> */}
                {this.renderInlineSelectFormGroup(notificationsFrequencyParams)}
                <FormGroup check className="form-check-with-left-margin">
                    <Label>
                        <Input
                            type="checkbox"
                            value={this.state.instantNotification}
                            checked={this.state.instantNotification}
                            onChange={this.handleChangeFlag("instantNotification")}
                        />
                        {_.get(
                            this.props.translation,
                            "applicant_account_page.instant_notification_label"
                        )}
                    </Label>
                </FormGroup>
                {this.renderInlineSelectFormGroup(notifyByParams)}
                <h6>
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.icing_on_the_job_cake_label"
                    )}
                </h6>
                <FormGroup check className="form-check-with-left-margin form-check-with-inputs">
                    <Input
                        type="checkbox"
                        value={this.state.isSetMinSalary}
                        checked={this.state.isSetMinSalary}
                        onChange={this.handleChangeFlag("isSetMinSalary")}
                    />
                    <Label>
                        {_.get(
                            this.props.translation,
                            "applicant_account_page.set_min_salary_label"
                        )}
                        <div className="inputs-in-form-check-container">
                            <Input
                                className="input-in-text"
                                bsSize="sm"
                                type="number"
                                placeholder={_.get(
                                    this.props.translation,
                                    "applicant_account_page.min_salary_placeholder"
                                )}
                                value={this.state.minSalary}
                                onChange={this.handleChangeField("minSalary")}
                                disabled={!this.state.isSetMinSalary}
                            />
                            <CustomSelect
                                containerClassName="select-in-text currency"
                                value={_.find(
                                    currenciesOptions,
                                    (option) => option.value === this.state.minSalaryCurrency
                                )}
                                options={currenciesOptions}
                                onChange={this.handleChangeSelectField("minSalaryCurrency")}
                                sizeType="xs"
                                isDisabled={!this.state.isSetMinSalary}
                            />
                        </div>
                    </Label>
                </FormGroup>
                <FormGroup check className="form-check-with-left-margin">
                    <Label>
                        <Input
                            type="checkbox"
                            value={this.state.isIncludeWorkOut}
                            checked={this.state.isIncludeWorkOut}
                            onChange={this.handleChangeFlag("isIncludeWorkOut")}
                        />
                        {_.get(
                            this.props.translation,
                            "applicant_account_page.opportunity_to_work_out_label"
                        )}
                    </Label>
                </FormGroup>
                <FormGroup check className="form-check-with-left-margin form-check-with-inputs">
                    <Input
                        type="checkbox"
                        value={this.state.isIncludeSpecialFood}
                        checked={this.state.isIncludeSpecialFood}
                        onChange={this.handleChangeFlag("isIncludeSpecialFood")}
                    />
                    <Label>
                        {_.get(
                            this.props.translation,
                            "applicant_account_page.include_decent_restaurant_part_1"
                        )}
                        <div className="inputs-in-form-check-container">
                            <CustomSelect
                                containerClassName="select-in-text food"
                                value={_.find(
                                    foodOptions,
                                    (option) => option.value === this.state.specialFoodType
                                )}
                                options={foodOptions}
                                onChange={this.handleChangeSelectField("specialFoodType")}
                                sizeType="xs"
                                isDisabled={!this.state.isIncludeSpecialFood}
                            />
                        </div>
                        {_.get(
                            this.props.translation,
                            "applicant_account_page.include_decent_restaurant_part_2"
                        )}
                    </Label>
                </FormGroup>
                <FormGroup check className="form-check-with-left-margin">
                    <Label>
                        <Input
                            type="checkbox"
                            value={this.state.isIncludeTrainingBudget}
                            checked={this.state.isIncludeTrainingBudget}
                            onChange={this.handleChangeFlag("isIncludeTrainingBudget")}
                        />
                        {_.get(
                            this.props.translation,
                            "applicant_account_page.include_training_budget_label"
                        )}
                    </Label>
                </FormGroup>
                <FormGroup check className="form-check-with-left-margin">
                    <Label>
                        <Input
                            type="checkbox"
                            value={this.state.isChooseProjectToContribute}
                            checked={this.state.isChooseProjectToContribute}
                            onChange={this.handleChangeFlag("isChooseProjectToContribute")}
                        />
                        {_.get(
                            this.props.translation,
                            "applicant_account_page.choose_project_to_contribute_label"
                        )}
                    </Label>
                </FormGroup>
                <FormGroup check className="form-check-with-left-margin">
                    <Label>
                        <Input
                            type="checkbox"
                            value={this.state.isSetOtherJobOpportunity}
                            checked={this.state.isSetOtherJobOpportunity}
                            onChange={this.handleChangeFlag("isSetOtherJobOpportunity")}
                        />
                        {_.get(
                            this.props.translation,
                            "applicant_account_page.other_job_opportunity_label"
                        )}
                    </Label>
                    <FormGroup>
                        <Input
                            type="textarea"
                            value={this.state.otherJobOpportunity}
                            onChange={this.handleChangeField("otherJobOpportunity")}
                            placeholder={_.get(
                                this.props.translation,
                                "applicant_account_page.other_job_opportunity_label"
                            )}
                            disabled={!this.state.isSetOtherJobOpportunity}
                        />
                    </FormGroup>
                </FormGroup>
                <div className="subsection-hint">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.future_jobs_hint_part_1"
                    )}
                    <Link to={ROUTES.JOB_COMPASS}>
                        {_.get(
                            this.props.translation,
                            "applicant_account_page.future_jobs_hint_link"
                        )}
                    </Link>
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.future_jobs_hint_part_2"
                    )}
                </div>
                <div className="subsection-hint">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.past_skills_and_jobs_hint_part_1"
                    )}
                    <Link to={ROUTES.SKILLS_JOURNEY}>
                        {_.get(
                            this.props.translation,
                            "applicant_account_page.past_skills_and_jobs_hint_link"
                        )}
                    </Link>
                </div>
            </div>
        );
    }

    renderLocationAndCommuteTab() {
        let formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField,
            onSelectChange: this.handleChangeSelectField,
        };

        let cityParams = {
            fieldName: "city",
            title: _.get(this.props.translation, "shared.city_label"),
            isRequired: true,
        };

        let maxCommuteTimeOptions = [
            {
                value: COMMUTE_TIME_OPTION.MIN_15,
                label: _.get(this.props.translation, "shared.15_min_label"),
            },
            {
                value: COMMUTE_TIME_OPTION.MIN_30,
                label: _.get(this.props.translation, "shared.30_min_label"),
            },
            {
                value: COMMUTE_TIME_OPTION.HOUR_1,
                label: _.get(this.props.translation, "shared.1_hour_label"),
            },
            {
                value: COMMUTE_TIME_OPTION.HOUR_2,
                label: _.get(this.props.translation, "shared.2_hour_label"),
            },
            {
                value: COMMUTE_TIME_OPTION.HOUR_3,
                label: _.get(this.props.translation, "shared.3_hour_label"),
            },
        ];

        let locationSecondaryParams = [
            {
                fieldName: "district",
                title: _.get(this.props.translation, "shared.district_label"),
            },
            {
                fieldName: "street",
                title: _.get(this.props.translation, "shared.street_label"),
            },
            {
                fieldName: "maxCommuteTime",
                title: _.get(this.props.translation, "shared.max_commute_time_label"),
                type: "select",
                options: maxCommuteTimeOptions,
            },
        ];

        let dreamLocationParams = [
            {
                fieldName: "dreamLocationCity",
                title: _.get(this.props.translation, "shared.city_label"),
            },
            {
                fieldName: "dreamLocationDistrict",
                title: _.get(this.props.translation, "shared.district_label"),
            },
        ];

        let commuteOptions = [
            {
                value: COMMUTE_OPTION.PREFER_HOMEWORK,
                label: _.get(this.props.translation, "commute.prefer_homework_label"),
            },
            {
                value: COMMUTE_OPTION.MAX_COMMUTE_TIME,
                label: _.get(this.props.translation, "shared.max_commute_time_label"),
            },
            {
                value: COMMUTE_OPTION.FULLTIME_HOMEWORK,
                label: _.get(this.props.translation, "commute.fulltime_homework_label"),
            },
            {
                value: COMMUTE_OPTION.RELOCATION,
                label: _.get(this.props.translation, "commute.relocation_label"),
            },
        ];

        let startPeriodOptions = [
            {
                value: START_WORK_PERIOD_OPTION.ASAP,
                label: _.get(this.props.translation, "start_to_work.asap_label"),
            },
            {
                value: START_WORK_PERIOD_OPTION.FEW_MONTHS,
                label: _.get(this.props.translation, "start_to_work.in_few_months_label"),
            },
            {
                value: START_WORK_PERIOD_OPTION.YEAR,
                label: _.get(this.props.translation, "start_to_work.in_year_label"),
            },
            {
                value: START_WORK_PERIOD_OPTION.FOR_GOOD_OPPORTUNITY,
                label: _.get(this.props.translation, "start_to_work.for_good_opportunity_label"),
            },
        ];

        const preferredContractTypeOptions = [
            {
                value: PREFERRED_CONTRACT_TYPE.FREELANCE,
                label: _.get(this.props.translation, "shared.freelance"),
            },
            {
                value: PREFERRED_CONTRACT_TYPE.EMPLOYEE,
                label: _.get(this.props.translation, "shared.employee"),
            },
            {
                value: PREFERRED_CONTRACT_TYPE.NO_PREFERENCE,
                label: _.get(this.props.translation, "shared.no_preference"),
            },
        ];

        return (
            <div className="tab-content-container">
                <h5 className="text-center">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.location_and_commute_tab_label"
                    )}
                </h5>
                <h5 className="page-subsection-header">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.your_current_location_label"
                    )}
                </h5>
                {/* <div className="subsection-hint">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.your_current_location_hint"
                    )}{" "}
                </div> */}
                <Row>
                    <ContinentAndCountryFormGroups
                        countryCode={this.state.countryCode}
                        handleChangeCountry={this.handleChangeCountry}
                        formParams={{ inline: true, inlineCol: true, inlineColSm: "4" }}
                        showContinentFilterLabel={false}
                        isRegionRequired={false}
                        isCountryRequired={true}
                    />
                    {FormUtils.renderFormGroup(cityParams, {
                        ...formParams,
                        inline: true,
                        inlineCol: true,
                        inlineColSm: "4",
                    })}
                </Row>
                {/* <div className="subsection-hint">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.calculate_commute_time_hint"
                    )}
                </div> */}
                <Row>
                    {_.map(locationSecondaryParams, (fieldParams) =>
                        FormUtils.renderFormGroup(fieldParams, {
                            ...formParams,
                            inline: true,
                            inlineCol: true,
                            inlineColSm: "4",
                        })
                    )}
                </Row>
                <Row className="work-regime-row">
                    <FormGroup>
                        <Label className="text-center">
                            {_.get(this.props.translation, "shared.expected_work_regime_label")}
                        </Label>
                        <Input
                            className="work-regime-input"
                            value={this.state.percentageOfHomeWorking}
                            type="range"
                            step="5"
                            onChange={this.handleChangeField("percentageOfHomeWorking")}
                        />
                        <div className="work-regime-labels">
                            <div>{_.get(this.props.translation, "job.part_time_label")}</div>
                            <div>{this.state.percentageOfHomeWorking + "%"}</div>
                            <div>{_.get(this.props.translation, "job.full_time_label")}</div>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label className="text-center">
                            {_.get(this.props.translation, "job.contract_type")}
                        </Label>
                        <CustomSelect
                            value={
                                _.find(
                                    preferredContractTypeOptions,
                                    (option) => option.value === this.state.preferredContractType
                                ) || null
                            }
                            options={preferredContractTypeOptions}
                            onChange={(option) =>
                                this.setState({ preferredContractType: option.value })
                            }
                            menuPosition="fixed"
                        />
                    </FormGroup>
                </Row>
                <h5 className="page-subsection-header">
                    {_.get(this.props.translation, "applicant_account_page.dream_locations_label")}
                </h5>
                <div className="subsection-hint">
                    {Utils.getTagsFromStringWithStyles(
                        _.get(this.props.translation, "applicant_account_page.dream_locations_hint")
                    )}{" "}
                </div>
                <Form onSubmit={this.handleAddDreamLocation} className="account-form">
                    <Row>
                        <ContinentAndCountryFormGroups
                            countryCode={this.state.dreamLocationCountry}
                            continentCode={this.state.dreamLocationRegion}
                            handleChangeCountry={this.handleChangeDreamLocationCountry}
                            handleChangeContinent={this.handleChangeDreamLocationRegion}
                            formParams={{ inline: true, inlineCol: true, inlineColSm: "6" }}
                            showContinentFilterLabel={false}
                            isCountryRequired={false}
                            isRegionRequired={false}
                        />
                    </Row>
                    <Row>
                        {_.map(dreamLocationParams, (fieldParams) =>
                            FormUtils.renderFormGroup(fieldParams, {
                                ...formParams,
                                inline: true,
                                inlineCol: true,
                                inlineColSm: "6",
                            })
                        )}
                    </Row>
                    <Row>
                        <Col sm="6">
                            <Label>{_.get(this.props.translation, "shared.commute_label")}</Label>
                            <FormGroup row>
                                <Col
                                    sm={
                                        this.state.dreamLocationCommute ===
                                        COMMUTE_OPTION.MAX_COMMUTE_TIME
                                            ? "8"
                                            : ""
                                    }
                                >
                                    <CustomSelect
                                        value={
                                            _.find(
                                                commuteOptions,
                                                (option) =>
                                                    option.value === this.state.dreamLocationCommute
                                            ) || null
                                        }
                                        options={commuteOptions}
                                        onChange={this.handleChangeSelectField(
                                            "dreamLocationCommute"
                                        )}
                                    />
                                </Col>
                                {this.state.dreamLocationCommute ===
                                COMMUTE_OPTION.MAX_COMMUTE_TIME ? (
                                    <Col sm="4">
                                        <CustomSelect
                                            value={
                                                _.find(
                                                    maxCommuteTimeOptions,
                                                    (option) =>
                                                        option.value ===
                                                        this.state.dreamLocationCommuteTime
                                                ) || null
                                            }
                                            options={maxCommuteTimeOptions}
                                            onChange={this.handleChangeSelectField(
                                                "dreamLocationCommuteTime"
                                            )}
                                        />
                                    </Col>
                                ) : null}
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <Label>{_.get(this.props.translation, "shared.when_label")}</Label>
                                <CustomSelect
                                    value={
                                        _.find(
                                            startPeriodOptions,
                                            (option) =>
                                                option.value === this.state.dreamLocationStartPeriod
                                        ) || null
                                    }
                                    options={startPeriodOptions}
                                    onChange={this.handleChangeSelectField(
                                        "dreamLocationStartPeriod"
                                    )}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label className="text-center">
                            {_.get(this.props.translation, "job.work_regime_label")}
                        </Label>
                        <Input
                            className="work-regime-input"
                            value={this.state.dreamLocationWorkRegime}
                            type="range"
                            step="5"
                            onChange={this.handleChangeField("dreamLocationWorkRegime")}
                        />
                        <div className="work-regime-labels">
                            <div>{_.get(this.props.translation, "job.part_time_label")}</div>
                            <div>{this.state.dreamLocationWorkRegime + "%"}</div>
                            <div>{_.get(this.props.translation, "job.full_time_label")}</div>
                        </div>
                    </FormGroup>
                    <div className="buttons-container content-center">
                        <Button color="primary" type="submit">
                            {_.get(this.props.translation, "actions.add_label")}
                        </Button>
                    </div>
                </Form>
                <h5 className="page-subsection-header">
                    {_.get(this.props.translation, "applicant_account_page.location_summary_label")}
                </h5>
                {/* <div className="subsection-hint">
                    {_.get(this.props.translation, "applicant_account_page.location_summary_hint")}{" "}
                </div> */}
                <Label>
                    {Utils.getTagsFromStringWithStyles(
                        _.get(
                            this.props.translation,
                            "applicant_account_page.summary_current_location_label"
                        )
                    )}{" "}
                    {this.renderCurrentLocationData()}{" "}
                    {this.state.maxCommuteTime && (
                        <>
                            {Utils.getTagsFromStringWithStyles(
                                _.get(
                                    this.props.translation,
                                    "applicant_account_page.summary_max_commute_label"
                                )
                            )}{" "}
                            {_.get(
                                this.props.translation,
                                COMMUTE_TIME_OPTION_TRANSLATION[this.state.maxCommuteTime]
                            ) || "xx"}
                        </>
                    )}
                </Label>
                {this.renderDreamLocations()}
            </div>
        );
    }

    renderDreamLocations() {
        let result = _.map(this.state.dreamLocations, (location) => {
            let locationLabel = "";

            locationLabel += _.get(this.props.translation, `continents.${location.continentCode}`);

            if (location.countryCode) {
                locationLabel += ", ";
                locationLabel += _.get(this.props.translation, `countries.${location.countryCode}`);
            }

            if (location.city) {
                locationLabel += ", ";
                locationLabel += location.city;
            }

            if (location.district) {
                locationLabel += ", ";
                locationLabel += location.district;
            }

            return (
                <div key={location} className="dream-location-card">
                    <h6>{locationLabel}</h6>
                    <Icon
                        className="dream-location-remove-icon"
                        type="cancel"
                        color="danger"
                        size="xs"
                        onClick={this.handleRemoveDreamLocation(location.id)}
                    />
                    {location.commuteType ? (
                        <Badge className="preference-badge commute" color="light">
                            {_.get(
                                this.props.translation,
                                COMMUTE_OPTION_SHORT_TRANSLATION[location.commuteType]
                            ) +
                                (location.commuteType === COMMUTE_OPTION.MAX_COMMUTE_TIME
                                    ? " - " +
                                      _.get(
                                          this.props.translation,
                                          COMMUTE_TIME_OPTION_TRANSLATION[location.maxCommuteTime]
                                      )
                                    : "")}
                        </Badge>
                    ) : null}
                    {location.workRegime ? (
                        <Badge className="preference-badge work-regime" color="light">
                            {_.get(this.props.translation, "job.work_regime_label") +
                                " - " +
                                location.workRegime +
                                "%"}
                        </Badge>
                    ) : null}
                    <Badge className="preference-badge start-period" color="light">
                        {_.get(
                            this.props.translation,
                            START_WORK_PERIOD_OPTION_TRANSLATION[location.startPeriod]
                        )}
                    </Badge>
                </div>
            );
        });

        return <div className="dream-locations-container">{result}</div>;
    }

    renderRichMediaTab() {
        let formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField,
        };

        let socialParams = [
            {
                fieldName: "twitterLink",
                title: _.get(this.props.translation, "person_data.twitter_label"),
            },
            {
                fieldName: "linkedInLink",
                title: _.get(this.props.translation, "person_data.linkedin_label"),
            },
            {
                fieldName: "facebookLink",
                title: _.get(this.props.translation, "person_data.facebook_label"),
            },
        ];

        return (
            <div className="tab-content-container">
                <div>
                    <h5 className="page-subsection-header">
                        {_.get(this.props.translation, "rich_media.title")}
                    </h5>
                    <p className="subsection-hint mb-4">
                        {_.get(this.props.translation, "rich_media.applicant_account_help_label")}
                    </p>
                    <b>
                        Solving the <i>Double Blind Date</i> problem - Authenticity beats Beauty!
                    </b>
                    <p className="subsection-hint applicant-account__rich-media-subtitle">
                        {_.get(
                            this.props.translation,
                            "rich_media.applicant_account_second_help_label"
                        )}
                    </p>
                </div>
                <div className="mb-5 mt-5">
                    <RichMedia />
                </div>
                <h5 className="page-subsection-header">
                    {Utils.getTagsFromStringWithStyles(
                        _.get(
                            this.props.translation,
                            "applicant_account_page.social_antennas_section_label"
                        )
                    )}
                </h5>
                {/* <div className="subsection-hint">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.social_antennas_section_hint"
                    )}
                </div> */}
                {_.map(socialParams, (fieldParams) =>
                    FormUtils.renderFormGroup(fieldParams, formParams)
                )}
            </div>
        );
    }

    renderInlineSelectFormGroup(params) {
        if (params.type === "select") {
            return (
                <FormGroup key={params.fieldName}>
                    <h6>{`${params.title}${params.isRequired ? "*" : ""}`}</h6>
                    <CustomSelect
                        className={params.className}
                        value={
                            _.find(
                                params.options,
                                (option) => option.value === this.state[params.fieldName]
                            ) || null
                        }
                        options={params.options}
                        onChange={this.handleChangeSelectField(params.fieldName)}
                        isSearchable={params.isSearchable}
                        menuPosition="fixed"
                    />
                </FormGroup>
            );
        } else {
            return (
                <FormGroup key={params.fieldName}>
                    <h6>{`${params.title}${params.isRequired ? "*" : ""}`}</h6>
                    <Input
                        className={params.className}
                        type={params.type || "text"}
                        value={this.state[params.fieldName]}
                        onChange={this.handleChangeField(params.fieldName)}
                        placeholder={params.placeholder || params.title}
                        disabled={params.isDisabled}
                    ></Input>
                </FormGroup>
            );
        }
    }

    getTabCompleteProgress = (step) => {
        let counter = 0;

        if (!REQUIRED_FIELDS[step]) {
            return null;
        }

        _.map(REQUIRED_FIELDS[step], (fieldName) => {
            if (
                _.isArray(fieldName) &&
                !_.every(fieldName, (field) => {
                    return (
                        _.isNil(this.state[field]) &&
                        (_.isString(this.state[field]) || _.isEmpty(this.state[field]))
                    );
                })
            ) {
                counter++;
            }
            if (
                !_.isArray(fieldName) &&
                !_.isNil(this.state[fieldName]) &&
                (!_.isString(this.state[fieldName]) || !_.isEmpty(this.state[fieldName]))
            ) {
                counter++;
            }
        });

        return (counter / REQUIRED_FIELDS[step].length) * 100;
    };

    renderCurrentLocationData() {
        let location = "";

        if (this.state.countryCode) {
            let continentCode = _.find(
                _.mapValues(this.props.locationData, (countries, continentCode) => {
                    return _.includes(countries, this.state.countryCode) && continentCode;
                })
            );
            location += _.get(this.props.translation, `continents.${continentCode}`);
            location += ", ";
            location += _.get(this.props.translation, `countries.${this.state.countryCode}`);

            if (this.state.city) {
                location += ", ";
                location += this.state.city;
            }

            if (this.state.district) {
                location += ", ";
                location += this.state.district;
            }

            if (this.state.street) {
                location += ", ";
                location += this.state.street;
            }
        } else {
            location = "xx";
        }

        return location;
    }

    handleChangeTab = (selectedOption) => {
        if (this.state.activeTab !== selectedOption.value) {
            this.setState({ activeTab: selectedOption.value });
            let containerNode = ReactDOM.findDOMNode(this.containerRef.current);
            containerNode.scrollTo({ top: 0 });
        }
    };

    handleChangeField = (fieldName) => (e) => {
        this.setState({ [fieldName]: e.target.value });
    };

    handleChangeFileField = (fieldName, urlFieldName) => (e) => {
        let file = e.target.files[0];

        if (urlFieldName) {
            this.setState({
                [fieldName]: file,
                [urlFieldName]: URL.createObjectURL(file),
            });
        } else {
            this.setState({ [fieldName]: file });
        }
    };

    handleChangeSelectField = (fieldName) => (selectedOption) => {
        this.setState({ [fieldName]: selectedOption.value });
    };

    handleChangeFlag = (fieldName) => () => {
        this.setState({ [fieldName]: !this.state[fieldName] });
    };

    handleChangeCountry = (countryCode) => {
        this.setState({ countryCode: countryCode });
    };

    handleChangeDreamLocationCountry = (countryCode) => {
        this.setState({ dreamLocationCountry: countryCode });
    };

    handleChangeDreamLocationRegion = (continentCode) => {
        this.setState({ dreamLocationRegion: continentCode });
    };

    handleAddDreamLocation = (e) => {
        e.preventDefault();

        let data = _.cloneDeep(this.state.dreamLocations);

        data.push({
            continentCode: this.state.dreamLocationRegion,
            countryCode: this.state.dreamLocationCountry,
            city: this.state.dreamLocationCity,
            district: this.state.dreamLocationDistrict,
            commuteType: this.state.dreamLocationCommute,
            maxCommuteTime: this.state.dreamLocationCommuteTime,
            startPeriod: this.state.dreamLocationStartPeriod,
            workRegime: this.state.dreamLocationWorkRegime,
        });

        this.setState({
            dreamLocations: data,
            dreamLocationRegion: "",
            dreamLocationCountry: "",
            dreamLocationCity: "",
            dreamLocationDistrict: "",
            dreamLocationCommute: null,
            dreamLocationCommuteTime: null,
            dreamLocationStartPeriod: null,
            dreamLocationWorkRegime: 100,
        });
    };

    handleRemoveDreamLocation = (id) => () => {
        let data = _.cloneDeep(this.state.dreamLocations);
        data = _.filter(data, (elem) => elem.id !== id);
        this.setState({ dreamLocations: data });
    };

    handleAddPictureFile = (e) => {
        const pictures = Array.from(e.target.files).map((file) => ({
            id: uuidv4(),
            file: file,
            url: URL.createObjectURL(file),
        }));
        this.setState({ pictures });
    };

    onSaveChanges = () => {
        switch (this.state.notifyBy) {
            case NOTIFY_BY_OPTION.TWITTER:
                if (_.isEmpty(this.state.twitterLink)) {
                    this.setState({
                        emptyFieldError: "twitter_label",
                        activeTab: ACCOUNT_SETUP_STEPS.RICH_MEDIA,
                    });
                    return;
                }
                break;

            case NOTIFY_BY_OPTION.LINKEDIN:
                if (_.isEmpty(this.state.linkedInLink)) {
                    this.setState({
                        emptyFieldError: "linkedin_label",
                        activeTab: ACCOUNT_SETUP_STEPS.RICH_MEDIA,
                    });
                    return;
                }
                break;

            case NOTIFY_BY_OPTION.FACEBOOK:
                if (_.isEmpty(this.state.facebookLink)) {
                    this.setState({
                        emptyFieldError: "facebook_label",
                        activeTab: ACCOUNT_SETUP_STEPS.RICH_MEDIA,
                    });
                    return;
                }
                break;
            case NOTIFY_BY_OPTION.PHONE:
                if (_.isEmpty(this.state.phoneNumber)) {
                    this.setState({
                        emptyFieldError: "phone_label",
                        activeTab: ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA,
                    });
                    return;
                }
                break;
            default:
                break;
        }

        this.uploadMediaFiles();
        this.uploadUserData();
    };
}

ApplicantAccount.propTypes = {
    translation: PropTypes.object.isRequired,
    locationData: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    onUpdateUser: PropTypes.func.isRequired,
    onUploadMediaUser: PropTypes.func.isRequired,
};

export default ApplicantAccount;
