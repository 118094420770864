import { connect } from 'react-redux';
import CandidatesGroupModal from "./CandidatesGroupModal";
import { getTranslation } from "../../../../../../reducers/common/localization";
import { getActiveModalType, hideModal, MODAL_CANDIDATES_GROUP } from '../../../../../../reducers/common/activeModal';
import {
    getOpenJobTopXCandidatesData,
    getOpenJobNoGoCandidatesData,
    getOpenJobPlannedTopCandidatesAmount,
    getOpenJobAnonymityMode,
    onRemoveCandidateFromGroup,
    onRemoveAllCandidatesFromGroup
} from '../../../../../../reducers/job/openJob';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_CANDIDATES_GROUP,
        topXCandidatesData: getOpenJobTopXCandidatesData(state),
        noGoCandidatesData: getOpenJobNoGoCandidatesData(state),
        plannedTopAmount: getOpenJobPlannedTopCandidatesAmount(state),
        anonymityMode: getOpenJobAnonymityMode(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(hideModal());
        },
        onRemoveCandidateFromGroup: (groupId, userId) => {
            dispatch(onRemoveCandidateFromGroup(groupId, userId));
        },
        onRemoveAllCandidatesFromGroup: (groupId) => {
            dispatch(onRemoveAllCandidatesFromGroup(groupId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidatesGroupModal);
