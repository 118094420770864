import { Utils } from "../utils/utils";
import { StorageManager } from "../utils/storageManager";
import _ from "lodash";
import { NODE_STATUS } from "../constants/systemNodesConstants";
import { PREFERRED_CONTRACT_TYPE } from "../constants/constants";
import { api, API_URL, apiAuth } from "./apibase";

export class Api {
    static fetchGetRequest = api.get;
    static fetchPostRequest = api.post;
    static fetchGetRequestWithToken = apiAuth.get;
    static fetchPostRequestWithToken = apiAuth.post;
    static fetchPostRequestWithTokenAndMultipartData = apiAuth.multiPartPost;

    static fetchPutRequestWithTokenAndMultipartData(path, data) {
        let token = StorageManager.getLoginToken();
        const headers = new Headers();
        headers.append("Authorization", "Bearer " + token);
        const requestOptions = {
            method: "PUT",
            headers,
            body: data,
            redirect: "follow",
        };

        return fetch(path, requestOptions);
    }

    static getJobApplicants(jobId) {
        const path = `/api/organizations/jobs/${jobId}/applicants`;

        return this.fetchGetRequestWithToken(path);
    }

    static fetchPutRequestWithToken = apiAuth.put;

    static fetchPutRequestAddProfiles(path, data) {
        let token = StorageManager.getLoginToken();
        return fetch(`${API_URL}${path}`, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "text/plain;charset=UTF-8",
            },
            body: data,
        });
    }

    static fetchGetRequestWithTokenTextPlain(path, data) {
        let token = StorageManager.getLoginToken();

        return fetch(`${API_URL}${path}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "text/plain;charset=UTF-8",
            },
            body: data,
        });
    }

    static fetchPutRequestWithTokenTextPlain(path, data) {
        let token = StorageManager.getLoginToken();

        return fetch(`${API_URL}${path}`, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "text/plain;charset=UTF-8",
            },
            body: data,
        });
    }

    static fetchPutApproveJob(path) {
        let token = StorageManager.getLoginToken();

        return fetch(`${API_URL}${path}`, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
    }

    static fetchPostMultipart(path, data) {
        let token = StorageManager.getLoginToken();

        return fetch(`${API_URL}${path}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
            body: data,
        });
    }

    static deleteRequestWithPathVariable(path) {
        let token = StorageManager.getLoginToken();

        return fetch(`${API_URL}${path}`, {
            method: "Delete",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
    }

    static fetchDeleteRequestWithToken = apiAuth.delete;

    static fetchDeleteSuggestedCluster(path, data) {
        let token = StorageManager.getLoginToken();

        return fetch(`${API_URL}${path}`, {
            method: "Delete",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify([data]),
        });
    }

    static getLoginToken(data) {
        let path = "/api/login";

        return api.postForm(path, Utils.objectToFormData(data));
    }

    static getCountries() {
        let path = "/api/countries";

        return api.get(path);
    }

    static getOrgJobs() {
        let path = "/api/organizations/jobs?size=100";

        return apiAuth.get(path);
    }

    static getJobByHash(hash) {
        let path = `/api/static/job/${hash}`;

        return this.fetchGetRequest(path);
    }

    static getApplicantByHash(applicantHash) {
        let path = `/api/static/applicant/${applicantHash}`;

        return this.fetchGetRequest(path);
    }

    static sendJobApplicantFeedback(jobId, feedback) {
        let path = `/api/organizations/jobs/job_application/${jobId}/feedback`;

        return this.fetchPutRequestWithTokenTextPlain(path, feedback);
    }

    static handleInterviewedDate(jobId, date, toRemove) {
        const path = `/api/organizations/jobs/job_application/${jobId}/interviewed-date/${
            toRemove ? "remove" : "add"
        }/${date}`;

        return this.fetchPutRequestWithToken(path);
    }

    static fetchJobInfo(jobHash) {
        const path = `/api/static/job/${jobHash}`;

        return this.fetchGetRequestWithToken(path);
    }

    static fetchGetUsersJob() {
        const path = "/api/users/job_application";

        return this.fetchGetRequestWithToken(path);
    }

    static closeJob(jobId) {
        const path = `/api/organizations/jobs/${jobId}/closed`;

        return this.fetchPutRequestWithToken(path);
    }

    static addDomainToProfile(profileId, domainTitle, isRemove) {
        const path = `/api/profiles/${profileId}/domain/${isRemove ? "remove" : "add"}`;

        return this.fetchPutRequestWithTokenTextPlain(path, domainTitle);
    }

    static addDomainToCluster(clusterId, domainTitle, isRemove) {
        const path = `/api/clusters/${clusterId}/domain/${isRemove ? "remove" : "add"}`;

        return this.fetchPutRequestWithTokenTextPlain(path, domainTitle);
    }

    static makeUserJobfavorite(jobId, isFavorite) {
        const path = `/api/users/job_application/${jobId}/favorite`;

        return this.fetchPutRequestWithToken(path, isFavorite);
    }

    static fetchGetApplicantsToCompare(jobId) {
        const path = `/api/organizations/jobs/${jobId}/compare_candidates`;

        return this.fetchGetRequestWithToken(path);
    }

    static putHiredJobApplicant(jobId, isHired) {
        const path = `/api/organizations/jobs/job_application/${jobId}/applicant-hired`;

        return this.fetchPutRequestWithToken(path, isHired);
    }

    static putFavoriteJobApplicant(jobId, isFavorite) {
        const path = `/api/organizations/jobs/job_application/${jobId}/applicant-favorite`;

        return this.fetchPutRequestWithToken(path, isFavorite);
    }

    static getUserData() {
        let path = "/api/users";

        return this.fetchGetRequestWithToken(path);
    }

    static updateUserData(data) {
        let path = "/api/users";

        return this.fetchPutRequestWithToken(path, data);
    }

    static uploadUserMediaData(data) {
        let path = "/api/users/media";

        return this.fetchPutRequestWithTokenAndMultipartData(path, data);
    }

    static getHMGroups() {
        let path = "/api/organization/departments/hm-list";

        return this.fetchGetRequestWithToken(path);
    }

    static getBOGroups() {
        let path = "/api/organization/departments/bo-list";

        return this.fetchGetRequestWithToken(path);
    }

    static getAllTeams() {
        const path = "/api/teams";

        return this.fetchGetRequestWithToken(path);
    }

    static getTeams(departmentId) {
        const path = `/api/teams/department/${departmentId}`;

        return this.fetchGetRequestWithToken(path);
    }

    static defineNewTeam(departmentId, teamName) {
        const path = `/api/teams?departmentId=${departmentId}`;

        return this.fetchPostRequestWithToken(path, { name: teamName });
    }

    static addNewTeam(teamId, assignBO) {
        const path = `/api/teams/${teamId}/business_owner?assignBusinessOwner=${assignBO}`;

        return this.fetchPutRequestWithToken(path);
    }

    static deleteOrganizationMedia(mediaId) {
        const path = `/api/organizations/media/${mediaId}`;

        return this.fetchDeleteRequestWithToken(path);
    }

    static deleteUserMedia(mediaId) {
        const path = `/api/users/media/${mediaId}`;

        return this.fetchDeleteRequestWithToken(path);
    }

    static deleteDepartmentMedia(departmentId, mediaId) {
        const path = `/api/organizations/departments/${departmentId}/media/${mediaId}`;

        return this.fetchDeleteRequestWithToken(path);
    }

    static deleteTeamsMediaMedia(teamId, mediaId) {
        const path = `/api/teams/${teamId}/media/${mediaId}`;

        return this.fetchDeleteRequestWithToken(path);
    }

    static updateTeamsMedia(teamId, data) {
        const path = `/api/teams/${teamId}/media`;

        return this.fetchPutRequestWithTokenAndMultipartData(path, data);
    }

    static registerApplicant(language, data) {
        let path = "/api/users?language=" + language;

        return this.fetchPostRequest(path, data);
    }

    static deleteUserMediaByURI(fileURI, mediaType) {
        const path = `/api/users/media/delete/${mediaType}/${fileURI}`;

        return this.fetchDeleteRequestWithToken(path);
    }

    static getOrgPricingByAnchor(region) {
        const path = `api/organizations/prices?region=${region}`;

        return this.fetchGetRequest(path);
    }

    static registerOrgByAnchor(data) {
        let path = "/api/organizations";

        return this.fetchPostRequest(path, data);
    }

    static getOrganization() {
        return this.fetchGetRequestWithToken("/api/organizations");
    }

    static updateCompanyMetadata(data) {
        const path = "/api/organizations";

        return this.fetchPutRequestWithToken(path, data);
    }

    static getAccountDataByToken(token) {
        let path = "/api/users";

        return fetch(path, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    }

    static activateAccount(data, token) {
        let path = "/api/users/confirmations/activation";

        return fetch(path, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
    }

    static restoreAccount(email) {
        let path = "/api/users/actions/password";

        return this.fetchPostRequest(path, email);
    }

    static approveByBo(jobId) {
        let path = `/api/organizations/jobs/${jobId}/approve/bo`;

        return this.fetchPutApproveJob(path);
    }

    static approveByHRM(jobId) {
        let path = `/api/organizations/jobs/${jobId}/approve/hr`;

        return this.fetchPutApproveJob(path);
    }

    static resendActivation(data) {
        let path = "/api/users/actions/activation";

        return this.fetchPostRequest(path, data);
    }

    static moveJobToDeepScreening(jobId) {
        let path = `/api/organizations/jobs/${jobId}/deep-screening`;

        return this.fetchPutRequestWithToken(path);
    }

    static moveJobToInvitatons(jobId) {
        let path = `/api/organizations/jobs/${jobId}/invitations`;

        return this.fetchPutRequestWithToken(path);
    }

    static getApplicantsAppliedToJob(jobId) {
        let path = `/api/organizations/jobs/${jobId}/applicants`;

        return this.fetchGetRequestWithToken(path);
    }

    static getApplicantSkills() {
        let path = "/api/users/skills";

        return this.fetchGetRequestWithToken(path);
    }

    static getApplicantProfiles() {
        let path = "/api/users/occupations";

        return this.fetchGetRequestWithToken(path);
    }

    static getAllSkillClusters() {
        const path = "/api/clusters?size=100";

        return this.fetchGetRequestWithToken(path);
    }

    static getProfilesBySkills(ids) {
        let path = "/api/profiles/by-skills?skillIds=";

        path += _.join(ids, ",");

        return this.fetchGetRequestWithToken(path);
    }

    static getJobCompassSettings() {
        return this.fetchGetRequestWithToken("api/users/job_compass_settings");
    }

    static setJobCompassSettings(data) {
        return this.fetchPutRequestWithToken("api/users/job_compass_settings", data);
    }

    static getJobCompassBlackList() {
        return this.fetchGetRequestWithToken("api/users/occupations/black-list");
    }

    static getAlternativeProfiles() {
        return this.fetchGetRequestWithToken("api/users/occupations/alternative-profiles");
    }

    static getPossibleProfiles(titleFilter) {
        return this.fetchGetRequestWithToken(
            `api/users/occupations/possible-profiles?titleFilter=${encodeURIComponent(titleFilter)}`,
        );
    }

    static getCurrentProfiles() {
        return this.fetchGetRequestWithToken("api/users/occupations/current-profiles");
    }

    static getFutureProfiles() {
        return this.fetchGetRequestWithToken("api/users/occupations/future-profiles");
    }

    static setFutureProfile(profileId) {
        return this.fetchPutRequestWithToken(`api/users/occupations/future-profiles`, profileId);
    }

    static addProfileToBlackList(profileId) {
        return this.fetchPutRequestWithToken("api/users/occupations/black-list", profileId);
    }

    static deleteProfile(profileId) {
        return this.deleteRequestWithPathVariable(`api/users/occupations/${profileId}`);
    }

    static getSearchProfiles(filter) {
        let path = "/api/profiles/by-title?titleFilter=" + encodeURIComponent(filter);

        return this.fetchGetRequestWithToken(path);
    }

    static getProfilesBySkillCluster(clusterId) {
        const path = `/api/profiles/by-clusters?clusterIds=${clusterId}`;

        return this.fetchGetRequestWithToken(path);
    }

    static getSkillsBySkills(ids) {
        let path = "/api/skills/by-related?searchMode=normalized&ids=";

        path += _.join(ids, ",");

        return this.fetchGetRequestWithToken(path);
    }

    static getSkillsByClusterAndProfiles(clusterId, profileIds) {
        let path = "/api/skills/clusters/" + clusterId + "?profileIds=";

        path += _.join(profileIds, ",");

        return this.fetchGetRequestWithToken(path);
    }

    static addSynonym(nodeId, language, value) {
        const path = `/api/nodes/${nodeId}/synonyms/add?language=${language}&synonym=${encodeURIComponent(
            value,
        )}`;

        return this.fetchPutRequestWithToken(path);
    }

    static addTranslation(nodeId, language, value) {
        const path = `/api/`;
    }

    static getClustersByProfiles(ids) {
        let path = "/api/users/clusters/by-profiles?profileIds=";

        path += _.join(ids, ",");

        return this.fetchGetRequestWithToken(path);
    }

    static getSearchSkills(filter) {
        let path = "/api/skills/by-name?skillFilter=" + encodeURIComponent(filter);

        return this.fetchGetRequestWithToken(path);
    }

    static getApplicantsByIds(applicantsIds) {
        let path = "api/users/applicants?";

        const applicantsIdsString = applicantsIds
            .map((id) => `&applicantIds=${encodeURIComponent(id)}`)
            .join("");

        path += applicantsIdsString;

        return this.fetchGetRequestWithToken(path);
    }

    static getJobsByIds(jobIds) {
        let path = "api/jobs/by-ids?";

        const jobsIdsString = jobIds.map((id) => `&jobIds=${encodeURIComponent(id)}`).join("");

        path += jobsIdsString;

        return this.fetchGetRequestWithToken(path);
    }

    static getAllJobsApplications() {
        let path = "api/users/job_application";

        return this.fetchGetRequestWithToken(path);
    }

    static applyJobsByIds(jobId, data) {
        let path = "/api/users/job_application";

        const formData = new FormData();

        formData.append(
            "metadataDTO",
            new Blob(
                [
                    JSON.stringify({
                        jobIds: [jobId],
                        motivationLetter:
                            data && data.content instanceof String ? data.content : "",
                    }),
                ],
                {
                    type: "application/json",
                },
            ),
        );
        if (data && data.key !== "textNote") formData.append(data.key, data.content);

        return this.fetchPostRequestWithTokenAndMultipartData(path, formData);
    }

    static getStaffMemberJob() {
        const path = "/api/organizations/jobs/by_staff";

        return this.fetchGetRequestWithToken(path);
    }

    static updateOrganizationMedia(data) {
        const path = `/api/organizations/media`;

        return this.fetchPutRequestWithTokenAndMultipartData(path, data);
    }

    static updateDepartmentMedia(departmentId, data) {
        const path = `/api/organizations/departments/${departmentId}/media`;

        return this.fetchPutRequestWithTokenAndMultipartData(path, data);
    }

    static updateJobMedia(jobId, data) {
        const path = `/api/organizations/jobs/${jobId}/media/update`;

        return this.fetchPutRequestWithTokenAndMultipartData(path, data);
    }

    static deleteApplicantProfile() {
        const path = "/api/users/delete";

        return this.fetchDeleteRequestWithToken(path);
    }

    static getApplicantById(applicantId) {
        return this.fetchGetRequestWithToken(`api/users/applicants/${applicantId}`);
    }

    static getJobById(jobId) {
        return this.fetchGetRequestWithToken(`api/jobs/${jobId}`);
    }

    static getQuickfindApplicants(params) {
        let path = "api/users/applicants/jobs?";

        const skillIdsFilterString = params.skills
            .map((skill) => `&skillIdsFilter=${encodeURIComponent(skill.id)}`)
            .join("");
        const skillLevelsFilterString = params.skills
            .map((skill) => `&skillLevelsFilter=${encodeURIComponent(skill.level)}`)
            .join("");

        path += `cityFilter=${encodeURIComponent(params.cityFilter)}`;
        path += skillIdsFilterString;
        path += skillLevelsFilterString;
        path += `&percentageOfHomeWorkingFilter=${encodeURIComponent(
            params.percentageOfHomeWorkingFilter,
        )}`;
        path += `&workRegimeFilter=${encodeURIComponent(params.workRegimeFilter)}`;

        if (
            params.contractTypeFilter &&
            params.contractTypeFilter !== PREFERRED_CONTRACT_TYPE.NO_PREFERENCE
        ) {
            path += `&contractTypeFilter=${encodeURIComponent(params.contractTypeFilter)}`;
        }

        if (params.salaryFilter) {
            path += `&salaryFilter=${encodeURIComponent(params.salaryFilter)}`;
        }

        if (params.currencyFilter) {
            path += `&currencyFilter=${encodeURIComponent(params.currencyFilter)}`;
        }

        return this.fetchGetRequestWithToken(path);
    }

    static getQuickfindJobs(params) {
        let path = "/api/jobs?";

        const skillIdsFilterString = params.skills
            .map((skill) => `&skillIdsFilter=${encodeURIComponent(skill.id)}`)
            .join("");
        const skillLevelsFilterString = params.skills
            .map((skill) => `&skillLevelsFilter=${encodeURIComponent(skill.level)}`)
            .join("");

        path += `cityFilter=${encodeURIComponent(params.cityFilter)}`;
        path += skillIdsFilterString;
        path += skillLevelsFilterString;
        path += `&percentageOfHomeWorkingFilter=${encodeURIComponent(
            params.percentageOfHomeWorkingFilter,
        )}`;
        path += `&workRegimeFilter=${encodeURIComponent(params.workRegimeFilter)}`;

        if (
            params.contractTypeFilter &&
            params.contractTypeFilter !== PREFERRED_CONTRACT_TYPE.NO_PREFERENCE
        ) {
            path += `&contractTypeFilter=${encodeURIComponent(params.contractTypeFilter)}`;
        }

        if (params.salaryFilter) {
            path += `&salaryFilter=${encodeURIComponent(params.salaryFilter)}`;
        }

        if (params.currencyFilter) {
            path += `&currencyFilter=${encodeURIComponent(params.currencyFilter)}`;
        }

        if (params.distanceFilter) {
            path += `&distanceFilter=${encodeURIComponent(params.distanceFilter)}`;
        }

        if (params.distanceUnitFilter) {
            path += `&distanceUnitFilter=${encodeURIComponent(params.distanceUnitFilter)}`;
        }

        return this.fetchGetRequestWithToken(path);
    }

    static getSearchClusters(filter) {
        let path =
            "/api/clusters/by-name?categoryFilter=" +
            encodeURIComponent(filter) +
            "&skillsPerCategory=15";

        return this.fetchGetRequestWithToken(path);
    }

    static addApplicantProfiles(data, altTitles, searchValue) {
        const id = data.map((el) => el.id);
        const titles = altTitles ? altTitles : null;
        let path = `/api/users/occupations/current-profiles/${id[0]}?profileName=${searchValue}`;

        return this.fetchPutRequestAddProfiles(path, titles);
    }

    static addProfileToUser(profileId, title) {
        const path = `/api/users/occupations/current-profiles/${profileId}`;

        return this.fetchPutRequestWithTokenTextPlain(path, title);
    }

    static defineNewSkillVersion(skillId, versionTitle) {
        const path = `/api/skills/${skillId}/update-version`;

        return this.fetchPutRequestWithToken(path, versionTitle);
    }

    static removeApplicantProfiles(data) {
        let path = "/api/users/occupations";

        return this.fetchDeleteRequestWithToken(path, data);
    }

    static addApplicantSkills(data, profileId) {
        console.log(profileId);
        let path = `/api/users/skills/by-profile/${profileId}`;
        return this.fetchPutRequestWithToken(path, data);
    }

    static addApplicantEmptySkill(data) {
        let path = `/api/users/skills`;
        return this.fetchPutRequestWithToken(path, data);
    }

    static removeApplicantSkills(data) {
        let path = "/api/users/skills";

        return this.fetchDeleteRequestWithToken(path, data);
    }

    static fetchPutPublishJob(path) {
        let token = StorageManager.getLoginToken();

        return fetch(path, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
    }

    static publishJob(jobId) {
        let path = `/api/organizations/jobs/${jobId}/publish`;

        return this.fetchPutPublishJob(path);
    }

    // TODO: (BE) add ability to get jobs by status (path param)
    static getOpenJobsList() {
        return this.fetchGetRequestWithToken("api/organizations/jobs");
    }

    static getJobData(jobId) {
        return this.fetchGetRequestWithToken(`api/organizations/jobs/${jobId}`);
    }

    static getJobCandidates(jobId, sortingMode, isPinTopAndFavs) {
        let path = `api/organizations/jobs/${jobId}/applicants`;
        let mode = sortingMode === "HARD_SKILLS" ? "HARD_SKILLS_SCORE_DESC" : "APPLY_DATE_DESC";

        if (isPinTopAndFavs) {
            path += "?sorting=GROUPS,FAVORITES_FIRST," + mode;
        } else {
            path += "?sorting=" + mode;
        }

        path += "&groupFilter=1,0";

        return this.fetchGetRequestWithToken(path);
    }

    static updateJobCandidateMetadata(jobId, candidateId, candidateData) {
        const path = `api/organizations/jobs/${jobId}/applicant/${candidateId}/applicant-metadata`;

        return this.fetchPutRequestWithToken(path, candidateData);
    }

    static sendJobApplicantPrivateNote(jobId, privateNotes) {
        const path = `/api/organizations/jobs/job_application/${jobId}/private-note`;

        return this.fetchPutRequestWithTokenTextPlain(path, privateNotes);
    }

    static postJob(data) {
        let path = `api/organizations/jobs`;

        return this.fetchPostRequestWithTokenAndMultipartData(path, data);
    }

    static updateJob(jobId, data) {
        const path = `/api/organizations/jobs/${jobId}`;

        return this.fetchPutRequestWithTokenAndMultipartData(path, data);
    }

    static getOpenJobGroupCandidatesData(jobId, groupId) {
        const path = `api/organizations/jobs/${jobId}/applicants?groupFilter=${groupId}&sorting=MOVE_TO_GROUP_DATE_DESC`;

        return this.fetchGetRequestWithToken(path);
    }

    static removeAllCandidatesFromGroup(jobId, groupId) {
        const path = `api/organizations/jobs/${jobId}/group/${groupId}/applicants`;

        return this.fetchDeleteRequestWithToken(path);
    }

    static getCandidatesDataForJob(jobId, userIds) {
        let path = `api/organizations/jobs/${jobId}/applicants/full?userIds=`;
        path += _.join(userIds, ",");

        return this.fetchGetRequestWithToken(path);
    }

    static getSkillClustersByDomain(domainTitle) {
        let path = "/api/nodes/skill-clusters";
        if (!!domainTitle) path += `?domainTitle=${encodeURIComponent(domainTitle)}`;

        return this.fetchGetRequestWithToken(path);
    }

    static getSystemNodesList(params, page) {
        // TODO: (BE) add Status and Domain, update source filter
        let path = "/api/nodes?";

        if (params.typeFilter && params.typeFilter.length > 0) {
            path += "&resourceTypes=" + _.join(params.typeFilter, ",");
        } else {
            path += "&resourceTypes=PROFILE,SKILL_CLUSTER,SKILL,DOMAIN";
        }

        if (params.statusFilter && params.statusFilter.length > 0) {
            path += `&resourceStatus=${params.statusFilter}`;
        }

        if (params.sourceFilter && params.sourceFilter.length) {
            path += `&resourceOwners=${params.sourceFilter.join(",")}`;
        } else path += "&resourceOwners=ONET,HF1,USER,SE,ADMIN";

        if (params.domainFilter && params.domainFilter.length > 0) {
            path += `&domainFilter=${encodeURIComponent(params.domainFilter)}`;
        }

        if (!_.isNil(params.nameFilter)) {
            path += "&titleFilter=" + encodeURIComponent(params.nameFilter);
        }

        if (!_.isNil(params.sortingMode)) {
            path += "&sort=" + params.sortingMode;
        }

        if (!_.isNil(page)) {
            path += "&page=" + page;
        }

        return this.fetchGetRequestWithToken(path);
    }

    static getSkillsBySkillClusterId(skillClusterId) {
        const path = `/api/nodes/${skillClusterId}/relations?resourceType=SKILL`;

        return this.fetchGetRequestWithToken(path);
    }

    static getSystemNodeData(nodeId, nodeType) {
        let path = "/api/nodes/" + nodeId + "?resourceType=" + nodeType;

        return this.fetchGetRequestWithToken(path);
    }

    static testNodes(nodeId, nodeType) {

        return this.fetchGetRequestWithToken("/api/nodes?&resourceTypes=SKILL&resourceOwners=HF1,USER,SE,ADMIN&titleFilter=&sort=TITLE");
    }

    static getSystemNodeGraphData(nodeId) {
        let path = "/api/nodes/" + nodeId + "/graphs?size=50";

        return this.fetchGetRequestWithToken(path);
    }

    static getSystemNodesExternalSources() {
        // TODO: (BE) add correct request
        let path = "/api/countries";

        return this.fetchGetRequestWithToken(path);
    }

    static getRelatedNodes(nodeId, params, page) {
        let path = "/api/nodes/" + nodeId + "/relations?resourceType=" + params.nodeType;

        // TODO: add filters
        if (!_.isNil(params.nameFilter)) {
            path += "&titleFilter=" + encodeURIComponent(params.nameFilter);
        }

        if (!_.isNil(page)) {
            path += "&page=" + page;
        }

        return this.fetchGetRequestWithToken(path);
    }

    static updateNode(nodeId, nodeData) {
        const path = `/api/nodes/${nodeId}`;

        return this.fetchPutRequestWithToken(path, nodeData);
    }

    static getSystemNotRelatedNodes(nodeId, params, page) {
        if (!nodeId) {
            // TODO: add all filters
            // TODO: move logic to action
            params.statusFilter = [NODE_STATUS.REFERENCE_MODEL];
            params.typeFilter = [params.nodesType];
            return this.getSystemNodesList(params, page);
        }
        // TODO: (BE) sorting
        let path =
            "/api/nodes/" +
            nodeId +
            "/relations?resourceType=" +
            params.nodesType +
            "&related=" +
            !!params.related;

        if (_.isNil(params.scFilter)) {
            path += `&`;
        }

        // TODO: add all filters
        if (!_.isNil(params.nameFilter)) {
            path += `&titleFilter=${encodeURIComponent(params.nameFilter)}`;
        }

        if (!_.isNil(params.domainFilter)) {
            path += "&domainFilter=" + encodeURIComponent(params.domainFilter);
        }

        if (!_.isNil(page)) {
            path += "&page=" + page;
        }

        return this.fetchGetRequestWithToken(path);
    }

    static getClosestSkills(params, page) {
        // TODO: (BE) add the correct request
        // TODO: (BE) add all params to the request
        // TODO: remove hardcoded values
        let path =
            "/api/skills/by-name?skillFilter=" + encodeURIComponent(params.nameFilter || "a");

        if (!_.isNil(page)) {
            path += "&page=" + page;
        }

        return this.fetchGetRequestWithToken(path);
    }

    static getClosestClusters(params, page) {
        // TODO: (BE) add the correct request
        // TODO: (BE) add all params to the request
        // TODO: remove hardcoded values
        let path =
            "/api/clusters/by-name?categoryFilter=" + encodeURIComponent(params.nameFilter || "a");

        if (!_.isNil(page)) {
            path += "&page=" + page;
        }

        return this.fetchGetRequestWithToken(path);
    }

    static addClusterToSkill(nodeId, clusterId) {
        const path = `/api/skills/${nodeId}/cluster/add/${clusterId}`;

        return this.fetchPutRequestWithToken(path);
    }

    static removeClusterFromSkill(nodeId, clusterId) {
        const path = `/api/skills/${nodeId}/cluster/remove/${clusterId}`;

        return this.fetchPutRequestWithToken(path);
    }

    static addSkillDependency(skillId, skillToAddId) {
        const path = `/api/skills/${skillId}/add-dependency/${skillToAddId}`;

        return this.fetchPutRequestWithToken(path);
    }

    static removeSkillDependency(skillId, skillToRemoveId) {
        const path = `/api/skills/${skillId}/remove-dependency/${skillToRemoveId}`;

        return this.fetchPutRequestWithToken(path);
    }

    static getAllClusters(params, page) {
        // TODO: (BE) add Status and Domain, update source filter
        let path = "/api/nodes?resourceTypes=SKILL_CLUSTER";
        if (params.statusFilter && params.statusFilter.length > 0) {
            path += `&resourceStatus=${params.statusFilter}`;
        }

        if (params.sourceFilter && params.sourceFilter.length) {
            path += `&resourceOwners=${params.sourceFilter.join(",")}`;
        } else path += "&resourceOwners=HF1,USER,SE,ADMIN";

        if (params.domainFilter && params.domainFilter.length > 0) {
            path += `&domainFilter=${encodeURIComponent(params.domainFilter)}`;
        }

        if (!_.isNil(params.nameFilter)) {
            path += "&titleFilter=" + encodeURIComponent(params.nameFilter);
        }

        if (!_.isNil(params.sortingMode)) {
            path += "&sort=" + params.sortingMode;
        }

        if (!_.isNil(page)) {
            path += "&page=" + page;
        }

        return this.fetchGetRequestWithToken(path);
    }

    static getStaffList(data) {
        let path =
            !_.isEmpty(data) && data.showAllEmployees
                ? "api/organizations/staff"
                : `api/organizations/staff?size=5`;

        if (!_.isEmpty(data) && !_.isNil(data.page)) {
            path += "&page=" + data.page;
        }

        return this.fetchGetRequestWithToken(path);
    }

    static getDomainList() {
        const path = `/api/nodes/domains`;

        return this.fetchGetRequestWithToken(path);
    }

    static getDepartmentsList(data) {
        let path = `api/organizations/departments?size=5`;

        if (!_.isEmpty(data) && !_.isNil(data.page)) {
            path += "&page=" + data.page;
        }

        return this.fetchGetRequestWithToken(path);
    }

    static registerStaffMember(data) {
        return this.fetchPostRequestWithToken(`api/organizations/staff`, data.data);
    }

    static removeStaffMember(data) {
        return this.fetchDeleteRequestWithToken(`api/organizations/staff/${data.staffId}`);
    }

    static createDepartment(data) {
        return this.fetchPostRequestWithToken(`api/organizations/departments`, data.data);
    }

    static createNode(node) {
        const path = "/api/nodes/";

        return this.fetchPostRequestWithToken(path, node);
    }

    static editDepartment(data) {
        return this.fetchPutRequestWithToken(
            `api/organizations/departments/${data.departmentId}`,
            data.data,
        );
    }

    static deleteDepartment(data) {
        return this.fetchDeleteRequestWithToken(
            `api/organizations/departments/${data.departmentId}`,
        );
    }

    static getDepartmentData(data) {
        return this.fetchGetRequestWithToken(`api/organizations/departments/${data.departmentId}`);
    }

    static addEmployeeToDepartment(data) {
        return this.fetchPutRequestWithToken(
            `api/organizations/departments/${data.departmentId}/employees/${data.employeeId}?role=${data.data.role}`,
            data.data.role,
        );
    }

    static removeEmployeeFromDepartment(data) {
        return this.fetchDeleteRequestWithToken(
            `api/organizations/departments/${data.departmentId}/staff/${data.staffId}`,
        );
    }

    static changeAnchor(userId) {
        return this.fetchPutRequestWithToken("api/organizations/anchor", userId);
    }

    static addApplicantPastJob(data) {
        let path = "/api/users/past-jobs";
        return this.fetchPostRequestWithToken(path, data);
    }

    static getApplicantPastJobs() {
        let path = "/api/users/past-jobs";
        return this.fetchGetRequestWithToken(path);
    }

    static getApplicantSkillsEvents() {
        let path = "/api/users/skills/events";

        return this.fetchGetRequestWithToken(path);
    }

    // UserSettingsController endpoints

    static getUserState() {
        let path = "/api/user/2fa/state";
        return this.fetchGetRequestWithToken(path);
    }

    static generateUserSecret() {
        let path = "/api/user/2fa/secret";
        return this.fetchPostRequestWithToken(path);
    }

    static enableUser2FA(data) {
        let path = `/api/user/2fa/enable?checkCode=${data}`;
        return this.fetchPostRequestWithToken(path);
    }

    static disableUser2FA() {
        let path = "/api/user/2fa/disable";
        return this.fetchPostRequestWithToken(path);
    }

    static sendUserPasswordResetEmail(data) {
        let path = "/api/users/settings/password";
        return this.fetchPostRequestWithToken(path, data);
    }

    static resetPassword(data) {
        let path = "/api/users/settings/password";

        return apiAuth.put(path, data);
    }

    static sendUserEmailChangeEmail(data) {
        let path = "api/users/settings/email";
        return this.fetchPostRequestWithToken(path, data);
    }

    static resetUserEmail(data) {
        let path = "/api/users/settings/email";
        return apiAuth.put(path, data);
    }

    static deleteSuggestedCluster(data) {
        let path = "api/users/clusters";
        return this.fetchDeleteSuggestedCluster(path, data);
    }

    static addNewCluster(data, profileId) {
        let path = `api/users/clusters/by-profile/${profileId}`;
        return this.fetchPostRequestWithToken(path, data);
    }

    static addNewSkill(data, profileId) {
        let path = `api/users/skills/by-profile/${profileId}`;
        return this.fetchPostRequestWithToken(path, data);
    }
}
