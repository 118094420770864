import { connect } from 'react-redux';
import SkillsMap from "./SkillsMap";
import { getTranslation } from "../../../../reducers/common/localization";

const mapStateToProps = state => {
    return {
        translation: getTranslation(state)
    };
};

export default connect(mapStateToProps)(SkillsMap);
