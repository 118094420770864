import { connect } from "react-redux";
import { getTranslation } from "../../../../reducers/common/localization";
import { getUserJobsState } from "../../../../reducers/user/userData";
import ApplicationOverview from "./ApplicationOverview";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        usersJob: getUserJobsState(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationOverview);
