import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { SKILLS_MAP_MODE } from "../skills/skillsMap/SkillsMap";
import { ROUTES, USER_ROLE } from "../../../constants/constants";
import SkillsMap from "../skills/skillsMap/SkillsMap.container";
import Legend from "../skills/legend/Legend.container";
import { getStaticSkillsTree } from "../../../utils/getStaticSkillsTree";

class ApplicantProfile extends Component {
    componentDidMount() {
        const applicantHash = this.props.location.pathname.split("/")[2];
        if (applicantHash) {
            this.props.onMount(applicantHash);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isAuthorized !== this.props.isAuthorized) {
            this.props.history.push(ROUTES.LOGIN);
        }
    }

    copyToClipboard = async (elementId) => {
        const element = document.getElementById(elementId);
        const elementText = element.href;
        await navigator.clipboard.writeText(elementText);
    };

    render() {
        const { translation, isAuthorized, userRole, loading, applicantData } = this.props;
        const skillsTree = getStaticSkillsTree(applicantData);
        let isDeleted = false;
        if (applicantData) isDeleted = applicantData.user.deleted;

        return (
            <div className="root-container">
                <h4 className="page-header">
                    {_.get(translation, "applicant_profile.page_label")}
                </h4>
                <h5 className="page-header">
                    {applicantData && applicantData.user
                        ? `${applicantData.user.alias.firstName} ${
                              applicantData.user.alias.lastName
                          }${applicantData.profile ? `- ${applicantData.profile.titles.en}` : ""}`
                        : null}
                </h5>
                {skillsTree ? (
                    <div className="skills-map-container">
                        <SkillsMap
                            skillsTree={skillsTree}
                            mode={SKILLS_MAP_MODE.APPLICANT_VIEW}
                            userId={" "}
                        />
                        <Legend />
                        {/* {
                            // todo: not sure this is the right condition to display
                            isAuthorized && userRole === USER_ROLE.APPLICANT && (
                                <div className="info-wrapper">
                                    <h6>
                                        {_.get(
                                            translation,
                                            "applicant_profile.you_can_use_tooltip"
                                        )}
                                        <i>{_.get(translation, "applicant_profile.other")}</i>
                                        {_.get(
                                            translation,
                                            "applicant_profile.you_can_use_tooltip_part2"
                                        )}
                                    </h6>
                                    <div>
                                        <h6>
                                            {_.get(
                                                translation,
                                                "applicant_profile.to_avoid_tooltip"
                                            )}
                                            <i>
                                                {_.get(
                                                    translation,
                                                    "applicant_profile.again_and_again"
                                                )}
                                            </i>
                                            {_.get(translation, "applicant_profile.in_our_systems")}
                                            <a id="static_link" href={window.location.href}>
                                                <b>
                                                    <i>Link</i>
                                                </b>
                                            </a>
                                        </h6>
                                        <Button
                                            color="primary"
                                            onClick={() => this.copyToClipboard("static_link")}
                                        >
                                            {_.get(translation, "shared.copy_link")}
                                        </Button>
                                    </div>
                                </div>
                            )
                        } */}
                        {
                            <div className="generated-info-wrapper">
                                <h6 className="contact-me">
                                    {_.get(translation, "applicant_profile.reach_me")}
                                    {!_.isEmpty(applicantData) ? (
                                        <i>
                                            <a
                                                href={`mailto:${
                                                    applicantData.user.email
                                                }?Subject=${encodeURIComponent(
                                                    _.get(
                                                        translation,
                                                        "applicant_profile.email_subject"
                                                    )
                                                )}`}
                                            >
                                                {_.get(
                                                    translation,
                                                    "applicant_profile.contact_email"
                                                )}
                                            </a>
                                        </i>
                                    ) : null}
                                </h6>
                                <h6>
                                    {"Generated by\u2009"}
                                    <b>{"HireForceOne\u2122 -"}</b>
                                    <i>
                                        <a href="http://www.HireForceOne.com" target="_blank">
                                            {`\u2009${_.get(
                                                translation,
                                                "applicant_profile.register_label"
                                            )}`}
                                        </a>
                                    </i>
                                </h6>
                            </div>
                        }
                    </div>
                ) : (
                    <h5 className="page-header">{_.get(translation, "shared.no_data_label")}</h5>
                )}
            </div>
        );
    }
}

ApplicantProfile.propTypes = {
    translation: PropTypes.object.isRequired,
    applicantData: PropTypes.object,
    isAuthorized: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    userRole: PropTypes.string,
    onMount: PropTypes.func.isRequired,
};

export default withRouter(ApplicantProfile);
