import { connect } from 'react-redux';
import { getTranslation } from '../../../../../reducers/common/localization';
import { getActiveModalType, MODAL_CHANGE_ANCHOR_STAFF_MEMBER, hideModal } from '../../../../../reducers/common/activeModal';
import ChangeAnchor from './ChangeAnchor';
import { getAllEmployees, getEmployeesData } from '../../../../../reducers/organization/staffList';
import { changeAnchor } from '../../../../../reducers/organization/staffMembers';

const mapStateToProps = (state) => {
	return {
		translation: getTranslation(state),
		isOpen: getActiveModalType(state) === MODAL_CHANGE_ANCHOR_STAFF_MEMBER,
		employees: getEmployeesData(state)
	};
};

const mapDispatchToProps = dispatch => ({
	onClose: () => dispatch(hideModal()),
	getAllEmployees: () => dispatch(getAllEmployees()),
	onChangeAnchor: (userId) => dispatch(changeAnchor(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAnchor);
