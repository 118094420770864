import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormGroup, Label, Input, Col, FormFeedback } from 'reactstrap';
import { Utils } from '../../../../utils/utils';

class ConfirmPasswordFormGroups extends Component {
    render() {
        return (
            <>
                <FormGroup row>
                    <Label for="new-password" sm={3}>
                        {_.get(this.props.translation, 'person_data.new_password_label') + "*"}
                    </Label>
                    <Col sm={9}>
                        <Input
                            type="password"
                            id="new-password"
                            value={this.props.password}
                            onChange={this.props.handleChangeField('password')}
                            required
                            placeholder={_.get(this.props.translation, 'person_data.new_password_label')}
                            invalid={this.props.password !== "" ? !Utils.isValidPassword(this.props.password) : false}
                        />
                        <FormFeedback>{_.get(this.props.translation, 'person_data.invalid_new_password_label')}</FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="new-password-confirm" sm={3}>
                        {_.get(this.props.translation, 'person_data.confirm_password_label') + "*"}
                    </Label>
                    <Col sm={9}>
                        <Input
                            type="password"
                            id="new-password-confirm"
                            value={this.props.passwordConfirm}
                            onChange={this.props.handleChangeField('passwordConfirm')}
                            required
                            placeholder={_.get(this.props.translation, 'person_data.confirm_password_input_placeholder')}
                            invalid={(this.props.password !== "" && this.props.passwordConfirm !== "") ? this.props.password !== this.props.passwordConfirm : false}
                        />
                        <FormFeedback>{_.get(this.props.translation, 'person_data.invalid_confirm_password_label')}</FormFeedback>
                    </Col>
                </FormGroup>
            </>
        )
    }
}

ConfirmPasswordFormGroups.propTypes = {
    translation: PropTypes.object.isRequired,
    password: PropTypes.string.isRequired,
    passwordConfirm: PropTypes.string.isRequired,
    handleChangeField: PropTypes.func.isRequired
}

export default ConfirmPasswordFormGroups;

