import { from, of } from "rxjs";
import { filter, mergeMap, catchError } from "rxjs/operators";
import _ from "lodash";
import { setServerError, setForbiddenError } from "./errors";
import { logout } from "../authorization/login";
import { getUserId } from "../user/userData";
import { REQUEST_ERROR } from "../../constants/constants";
import { StorageManager } from "../../utils/storageManager";

//- Constants
const EMPTY_CONTENT = "0";
export const UNAUTHORIZED = "UNAUTHORIZED";
export const TOTP_CODE_REQUIRED = "TOTP_CODE_REQUIRED";

class ApiCallError extends Error {
    constructor(data, ...params) {
        super(...params);

        this.name = "ApiCallError";
        this.data = data;
        this.status = data.status;
        this.statusText = data.statusText;
        this.type = data.type;
        this.message = data.message;
        this.fieldsErrors = data.fieldsErrors;
    }
}

//- Epics
export const handleApiCallEpic = (action$, state$) => {
    return action$.pipe(
        filter((action) => !_.isNil(action.meta) && action.meta.apiCall),
        mergeMap((action) => {
            return from(action.payload).pipe(
                mergeMap((response) => {
                    let contentType = response.headers.get("Content-Type");
                    let isJSONContentType =
                        !_.isNil(contentType) && contentType.startsWith("application/json");

                    if (!response.ok) {
                        if (isJSONContentType) {
                            return response.json().then((data) => {
                                throw new ApiCallError({
                                    status: response.status,
                                    statusText: response.statusText,
                                    type: data.error,
                                    message: data.message,
                                    fieldsErrors: data.fieldsErrors,
                                });
                            });
                        } else {
                            throw new ApiCallError({
                                status: response.status,
                                statusText: response.statusText,
                            });
                        }
                    }

                    let userIdFromLocalStorage = StorageManager.getUserId();
                    let userIdFromState = getUserId(state$.value);
                    if (
                        !_.isNil(userIdFromState) &&
                        !_.isNil(userIdFromLocalStorage) &&
                        userIdFromLocalStorage.toString() !== userIdFromState.toString()
                    ) {
                        throw new ApiCallError({
                            status: REQUEST_ERROR.FORBIDDEN,
                            statusText: "Forbidden",
                        });
                    }

                    if (isJSONContentType) {
                        return from(response.json());
                    } else {
                        return from(EMPTY_CONTENT);
                    }
                }),
                mergeMap((result) => {
                    return of(action.meta.onSuccess(result));
                }),
                catchError((error) => {
                    if (!_.isNil(error.status) && error.status.toString().startsWith("5")) {
                        return of(setServerError(error.data));
                    }

                    let resultActions = [];
                    resultActions.push(action.meta.onFail(error.data));

                    if (
                        error.status === REQUEST_ERROR.UNAUTHORIZED &&
                        !action.meta.ignoreUnauthorized
                    ) {
                        resultActions.push(logout());
                    }
                    if (error.status === REQUEST_ERROR.FORBIDDEN && !action.meta.ignoreForbidden) {
                        resultActions.push(setForbiddenError());
                    }

                    return of(...resultActions);
                })
            );
        })
    );
};
