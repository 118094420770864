import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import _ from 'lodash';
import Icon from '../../common/icon/Icon';
import './ApplicantQuickfindInfoModal.scss';
import UserPhoto from '../../common/userPhoto/UserPhoto.container';

export const APPLICANT_INFO_MODAL_TAB_TYPE = {
	MOVIE: "MOVIE",
	CV: "CV"
};

class ApplicantQuickfindInfoModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTab: APPLICANT_INFO_MODAL_TAB_TYPE.MOVIE
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.activeTab && this.props.activeTab !== prevProps.activeTab) {
			this.setState({ activeTab: this.props.activeTab });
		}
	}

	handleChangeTab = (type) => () => {
		if (this.state.activeTab !== type) {
			this.setState({ activeTab: type });
		}
	};

	render() {
		const { isOpen, onCancel, translation, data } = this.props;

		const { activeTab } = this.state;

		return (
			<Modal
				isOpen={isOpen}
				className="candidate-cross-job-info-modal"
				toggle={onCancel}
				centered
			>
				{
					!_.isEmpty(data) && (
						<ModalHeader className="candidate-modal-header" toggle={onCancel}>
							<UserPhoto
								userId={data.userId}
								imageSource=""
								size="sm"
							/>
							<div>
								<h6>
									{`${data.user.alias.firstName} ${data.user.alias.lastName}, ${data.user.currentJob}`}
								</h6>
							</div>
						</ModalHeader>
					)
				}
				<ModalBody>
					<div className="modes-buttons">
						<div
							className={"mode-button " + (activeTab === APPLICANT_INFO_MODAL_TAB_TYPE.MOVIE ? "active" : "")}
							title={_.get(translation, 'job_and_candidates.movie_label')}
							onClick={this.handleChangeTab(APPLICANT_INFO_MODAL_TAB_TYPE.MOVIE)}
						>
							<Icon type="movie" size="xl" />
						</div>
						<div
							className={"mode-button " + (activeTab === APPLICANT_INFO_MODAL_TAB_TYPE.CV ? "active" : "")}
							title={_.get(translation, 'job_and_candidates.cv_label')}
							onClick={this.handleChangeTab(APPLICANT_INFO_MODAL_TAB_TYPE.CV)}
						>
							<Icon type="cv" size="xl" />
						</div>
					</div>
					<div className="modes-content">
						<div className="mode-content" hidden={activeTab !== APPLICANT_INFO_MODAL_TAB_TYPE.MOVIE}>
							<h6 className="text-center">
								{_.get(translation, 'job_and_candidates.movie_label')}
							</h6>
							<video className="video-player" controls />
						</div>
						<div className="mode-content" hidden={activeTab !== APPLICANT_INFO_MODAL_TAB_TYPE.CV}>
							<h6 className="text-center">
								{_.get(translation, 'job_and_candidates.cv_label')}
							</h6>
							<Button color="primary">
								{_.get(translation, 'job_and_candidates.download_cv_label')}
							</Button>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						color="secondary"
						onClick={onCancel}
					>
						{_.get(translation, 'actions.cancel_button')}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

ApplicantQuickfindInfoModal.propTypes = {
	translation: PropTypes.object.isRequired,
	data: PropTypes.object,
	isOpen: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	activeTab: PropTypes.string,
};

export default ApplicantQuickfindInfoModal;
