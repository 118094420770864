import { connect } from 'react-redux';
import Header from './Header';
import { getTranslation } from '../../../reducers/common/localization';
import { getLoginAuthorizeStatus, logout } from '../../../reducers/authorization/login';
import { getServerErrorStatus } from '../../../reducers/common/errors';
import { getUserEmail, getUserDataLoading } from '../../../reducers/user/userData';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        isAuthorized: getLoginAuthorizeStatus(state),
        isServerError: getServerErrorStatus(state),
        userEmail: getUserEmail(state),
        loadingUserData: getUserDataLoading(state)
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onLogOut: () => dispatch(logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
