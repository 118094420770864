import { connect } from 'react-redux';
import DepartmentDetails from './DepartmentDetails';
import { getTranslation } from '../../../../reducers/common/localization';
import { getDepartmentData, getDepartmentDataFetchingStatus, fetchGetDepartmentData } from '../../../../reducers/organization/departments';
import {
    MODAL_ADD_EMPLOYEE_TO_DEPARTMENT,
    MODAL_EDIT_DEPARTMENT,
    MODAL_REMOVE_EMPLOYEE_FROM_DEPARTMENT,
    showModal
} from '../../../../reducers/common/activeModal';

const mapStateToProps = state => ({
    translation: getTranslation(state),
    data: getDepartmentData(state),
    loading: getDepartmentDataFetchingStatus(state),
});


const mapDispatchToProps = dispatch => ({
    fetchDepartmentData: (data) => dispatch(fetchGetDepartmentData(data)),
    onEditDepartment: () => dispatch(showModal(MODAL_EDIT_DEPARTMENT)),
    onAddEmployeeToDepartment: () => dispatch(showModal(MODAL_ADD_EMPLOYEE_TO_DEPARTMENT)),
    onRemoveEmployeeFromDepartment: () => dispatch(showModal(MODAL_REMOVE_EMPLOYEE_FROM_DEPARTMENT)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentDetails);
