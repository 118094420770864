import React, { Component } from "react";

import GripLines from "../../../icons/GripLines";

class DraggableLines extends Component {
    render() {
        const { height } = this.props;
        return (
            <span
                className="resize-element"
                style={{
                    top: `${height}px`,
                }}
            >
                <GripLines />
            </span>
        );
    }
}

export default DraggableLines;
