import React, { Component } from "react";
import PropTypes from "prop-types";
import "./SecondaryNavBar.scss";
import { Navbar, Nav, NavbarToggler, UncontrolledCollapse } from "reactstrap";
import _ from "lodash";
import { components } from "react-select";
import CustomSelect from "../../../common/inputs/customSelect/CustomSelect";
import {
    USER_ROLE,
    USER_SUB_ROLE,
    NO_SUB_ROLES,
    USER_SUB_ROLE_TRANSLATION,
    ROUTES,
} from "../../../../constants/constants";

class SecondaryNavBar extends Component {
    render() {
        if (
            _.isNil(this.props.role) ||
            this.props.role !== USER_ROLE.EMPLOYEE ||
            _.isNil(this.props.currentSubRole) ||
            this.props.currentSubRole === NO_SUB_ROLES
        ) {
            return null;
        }

        return (
            <Navbar color="light" light className="secondary-navbar">
                <NavbarToggler id="sub-roles-navbar-toggler" className="sub-roles-navbar-toggler" />
                <UncontrolledCollapse
                    className="sub-roles-collapse"
                    navbar
                    toggler="#sub-roles-navbar-toggler"
                >
                    {this.renderNavs()}
                </UncontrolledCollapse>
            </Navbar>
        );
    }

    renderNavs() {
        if (this.props.loadingSubRolesAndGroups) {
            return (
                <Nav className="loading-nav">
                    <div className="loading-nav-bar-container">
                        <h6>{_.get(this.props.translation, "actions.fetching_message")}</h6>
                    </div>
                </Nav>
            );
        } else {
            let selectedSubRolesOption = this.getSubRoleSelectedOption();
            let selectedGroupsOption = this.getGroupSelectedOption();
            return (
                <>
                    <Nav
                        className={`sub-roles-nav ${
                            this.props.location.pathname === ROUTES.HOME ? "m-auto" : ""
                        }`}
                    >
                        <div className="sub-roles-container">
                            <h6>
                                {_.get(this.props.translation, "person_data.sub_role_label") + ":"}
                            </h6>
                            <div title={_.get(selectedSubRolesOption, "label")}>
                                <CustomSelect
                                    className="sub-role-select"
                                    options={this.getSubRolesOptions()}
                                    value={selectedSubRolesOption}
                                    onChange={this.handleChangeSubRole}
                                    components={this.getComponents()}
                                    isSearchable={false}
                                    sizeType="xs"
                                />
                            </div>
                        </div>
                    </Nav>
                    {this.props.currentSubRole !== USER_SUB_ROLE.ANCHOR &&
                    !_.isNil(this.props.currentGroup) ? (
                        <Nav className="groups-nav">
                            <div className="groups-container">
                                <h6>
                                    {_.get(
                                        this.props.translation,
                                        "organization.department_label"
                                    ) + ":"}
                                </h6>
                                <div title={_.get(selectedGroupsOption, "label")}>
                                    <CustomSelect
                                        className="group-select"
                                        options={this.getGroupsOptions()}
                                        value={selectedGroupsOption}
                                        onChange={this.handleChangeGroup}
                                        components={this.getComponents()}
                                        isSearchable={false}
                                        sizeType="xs"
                                    />
                                </div>
                            </div>
                        </Nav>
                    ) : null}
                </>
            );
        }
    }

    getSubRolesOptions = () => {
        return _.map(this.props.subRoles, (role) => ({
            value: role,
            label: _.get(this.props.translation, USER_SUB_ROLE_TRANSLATION[role]),
        }));
    };

    getSubRoleSelectedOption = () => {
        let selectedElem = _.find(
            this.props.subRoles,
            (role) => role === this.props.currentSubRole
        );
        if (_.isNil(selectedElem)) {
            return null;
        }
        return {
            value: selectedElem,
            label: _.get(this.props.translation, USER_SUB_ROLE_TRANSLATION[selectedElem]),
        };
    };

    getGroupsOptions = () => {
        return _.map(this.props.groups, (elem) => ({
            value: elem.id,
            label: elem.name,
            title: elem.name,
        }));
    };

    getComponents = () => ({
        Option: (props) => {
            return (
                <div title={props.data.title}>
                    <components.Option {...props} />
                </div>
            );
        },
    });

    getGroupSelectedOption = () => {
        let selectedElem = _.find(this.props.groups, (elem) => elem.id === this.props.currentGroup);
        if (_.isNil(selectedElem)) {
            return null;
        }
        return { value: selectedElem.id, label: selectedElem.name };
    };

    handleChangeSubRole = (selectedOption) => {
        this.props.onChangeCurrentSubRole(selectedOption.value);
    };

    handleChangeGroup = (selectedOption) => {
        this.props.onChangeCurrentGroup(selectedOption.value);
    };
}

SecondaryNavBar.propTypes = {
    loadingSubRolesAndGroups: PropTypes.bool,
    role: PropTypes.string,
    currentSubRole: PropTypes.string,
    subRoles: PropTypes.array,
    currentGroup: PropTypes.string,
    groups: PropTypes.array,
    translation: PropTypes.object.isRequired,
    onChangeCurrentSubRole: PropTypes.func.isRequired,
    onChangeCurrentGroup: PropTypes.func.isRequired,
};

export default SecondaryNavBar;
