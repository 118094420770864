import React, { Component } from "react";

export default class Financial extends Component {
    render() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 128"
            >
                <defs></defs>
                <title>a</title>
                <path
                    fill="#2d3e50"
                    d="M113.45913,81.8189c-.008.04906-3.91276-1.38425-4.19386-1.49828a18.58239,18.58239,0,0,1-5.80483-3.62372c-4.4776-4.0374-8.59588-8.59456-12.9873-12.80432A58.71556,58.71556,0,0,0,77.01647,53.74671a54.38478,54.38478,0,0,0-11.56194-4.41263,18.9222,18.9222,0,0,1-25.40578.52771C26.41308,54.39907,14.54087,66.47414,14.54087,81.41715c0,.20684.00265.41767.00663.62451.13392,6.131.9109,14.53066,4.49881,19.5015.004.004.00663.00663.00663.0106,2.74331,9.54258,4.48026,15.2798,7.71415,25.431H42.57726c-.02122-3.12518-.49192-6.46116-.49457-10.08754a52.22366,52.22366,0,0,0,14.227,1.95174h.03846a87.90111,87.90111,0,0,0,10.76772-.33015l.9414,1.32724,2.2156,3.1212c.67754,1.066,1.37894,2.10555,2.12676,3.09734q.352.46935.72.92283H91.392l-2.47547-12.60146a1.70841,1.70841,0,0,1,.36064-.28507q.3202-.10937.63644-.22409c.02785-.004.05569-.00662.08353-.00927,14.0414-3.55476,21.00773-13.78682,23.458-16.428Q113.45515,89.62719,113.45913,81.8189ZM66.62265,67.08935H38.29722a3.0496,3.0496,0,1,1,0-6.09919H66.62265a3.0496,3.0496,0,0,1,0,6.09919Z"
                />
                <path
                    fill="#1d75b8"
                    d="M52.45993,19.69054a15.858,15.858,0,1,0,15.85784,15.8581A15.87587,15.87587,0,0,0,52.45993,19.69054Z"
                />
                <path
                    fill="#1d75b8"
                    d="M83.30291,1.01261A13.44431,13.44431,0,1,0,96.74711,14.457,13.45948,13.45948,0,0,0,83.30291,1.01261Z"
                />
            </svg>
        );
    }
}
