import { connect } from "react-redux";
import { getTranslation } from "../../../../../reducers/common/localization";
import Feedback from "./Feedback";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
