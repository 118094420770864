import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CandidateCrossJobInfoModal.scss';
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';
import _ from "lodash";
import Icon from '../../../../../common/icon/Icon';
import CandidateModalHeader from '../candidateModalHeader/CandidateModalHeader.container';

export const CROSS_JOB_INFO_TYPE = {
    MOVIE: "MOVIE",
    CV: "CV"
}

class CandidateCrossJobInfoModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: CROSS_JOB_INFO_TYPE.MOVIE
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeTab && this.props.activeTab !== prevProps.activeTab) {
            this.setState({ activeTab: this.props.activeTab });
        }
    }

    // TODO: (BE) add Movie & CV
    render() {
        if (!this.props.data) {
            return null;
        }
        return (
            <Modal isOpen={this.props.isOpen} className="candidate-cross-job-info-modal" toggle={this.props.onCancel}>
                <CandidateModalHeader toggle={this.props.onCancel} />
                <ModalBody>
                    {this.renderModalBody()}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        onClick={this.props.onCancel}
                    >
                        {_.get(this.props.translation, 'actions.cancel_button')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalBody() {
        return (
            <>
                <div className="modes-buttons">
                    <div
                        className={"mode-button " + (this.state.activeTab === CROSS_JOB_INFO_TYPE.MOVIE ? "active" : "")}
                        title={_.get(this.props.translation, 'job_and_candidates.movie_label')}
                        onClick={this.handleChangeTab(CROSS_JOB_INFO_TYPE.MOVIE)}
                    >
                        <Icon type="movie" size="xl" />
                    </div>
                    <div
                        className={"mode-button " + (this.state.activeTab === CROSS_JOB_INFO_TYPE.CV ? "active" : "")}
                        title={_.get(this.props.translation, 'job_and_candidates.cv_label')}
                        onClick={this.handleChangeTab(CROSS_JOB_INFO_TYPE.CV)}
                    >
                        <Icon type="cv" size="xl" />
                    </div>
                </div>
                <div className="modes-content">
                    <div className="mode-content" hidden={this.state.activeTab !== CROSS_JOB_INFO_TYPE.MOVIE}>
                        <h6 className="text-center">
                            {_.get(this.props.translation, 'job_and_candidates.movie_label')}
                        </h6>
                        {this.renderMovieTabContent()}
                    </div>
                    <div className="mode-content" hidden={this.state.activeTab !== CROSS_JOB_INFO_TYPE.CV}>
                        <h6 className="text-center">
                            {_.get(this.props.translation, 'job_and_candidates.cv_label')}
                        </h6>
                        {this.renderCVTabContent()}
                    </div>
                </div>
            </>
        )
    }

    renderMovieTabContent() {
        return (
            <video className="video-player" controls></video>
        )
    }

    renderCVTabContent() {
        return (
            <Button color="primary">
                {_.get(this.props.translation, 'job_and_candidates.download_cv_label')}
            </Button>
        )
    }

    handleChangeTab = (type) => () => {
        if (this.state.activeTab !== type) {
            this.setState({ activeTab: type });
        }
    }
}

CandidateCrossJobInfoModal.propTypes = {
    translation: PropTypes.object.isRequired,
    activeTab: PropTypes.string,
    data: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default CandidateCrossJobInfoModal;
