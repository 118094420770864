import React, { Component } from 'react';

class LicenseText extends Component {
    render() {
        return (
            <div>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '8pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '14pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>Version date: August 10th, 2021</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#0d69aa' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>This
            HireForceOne Customer Agreement (this “</span><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#262626' }}>Agreement</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>”) contains the terms and conditions that govern your
                            access to and use of the Service Offerings (as defined below) and is an agreement between the
            applicable Contracting Party specified in Section 14 below (also referred to as “</span><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#262626' }}>HireForceOne</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>”,
            “</span><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#262626' }}>we</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>”,
            “</span><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#262626' }}>us</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>”,
            or “</span><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#262626' }}>our</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>”) and you or the entity you represent (“</span><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#262626' }}>you</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>”
            or “</span><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#262626' }}>your</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>”). This Agreement takes effect when you click an “I
                            Accept” button or check box presented with these terms or, if earlier, when you use any of the Service
            Offerings (the “</span><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#262626' }}>Effective
            Date</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>”). You represent to us that you are
                            lawfully able to enter into contracts (e.g., you are not a minor). If you are entering into this
                            Agreement for an entity, such as the company you work for, you represent to us that you have legal
                            authority to bind that entity. Please see Section 14 for definitions of certain capitalized terms used
            in this Agreement.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '14pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>1.
            Use of the Service Offerings.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>1.1
                    Generally. You may access and use the Service Offerings in accordance with this Agreement. Service
                    Level Agreements and Service Terms apply to certain Service Offerings. You will comply with the terms
            of this Agreement and all laws, rules and regulations applicable to your use of the Service Offerings.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>1.2
                    Your Account. To access the Services, you must have a HireForceOne or HireForceOne
            account associated with a valid email address and a valid form of payment. </span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>1.3
                    Third-Party Content. Third-Party Content may be used by you at your election. Third-Party Content is
                    governed by this Agreement and, if applicable, separate terms and conditions accompanying such
            Third-Party Content, which terms and conditions may include separate fees and charges.</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '14pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>2.
            Changes.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>2.1
                    To the Service Offerings. We may change or discontinue any or all of the Service Offerings or change or
                    remove functionality of any or all of the Service Offerings from time to time. We will notify you of
            any material change to or discontinuation of the Service Offerings.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>2.2
                    To the APIs. We may change or discontinue any APIs for the Services from time to time. For any
                    discontinuation of or material change to an API for a Service, we will use commercially reasonable
                    efforts to continue supporting the previous version of such API for 12 months after the change or
                    discontinuation (except if doing so (a) would pose a security or intellectual property issue, (b) is
                    economically or technically burdensome, or (c) would cause us to violate the law or requests of
            governmental entities).</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>2.3
                    To the Service Level Agreements. We may change, discontinue or add Service Level Agreements from time
            to time in accordance with Section 12.</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '14pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>3.
            Security and Data Privacy.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>3.1
                    HireForceOne Security. Without limiting Section 10 or your obligations under Section 4.2, we will
                    implement reasonable and appropriate measures designed to help you secure Your Content against
            accidental or unlawful loss, access or disclosure.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>3.2
                    Data Privacy. You consent to the storage of Your Content. We will not access or use Your Content except
                    as necessary to maintain or provide the Service Offerings, or as necessary to comply with the law or a
                    binding order of a governmental body. We will not (a) disclose Your Content, except after explicit
                    prior consent from your end, to any government or third party or (b) subject to Section 3.3, move Your
                    Content except in each case as necessary to comply with the law or a binding order of a governmental
                    body. Unless it would violate the law or a binding order of a governmental body, we will give you
                    notice of any legal requirement or order referred to in this Section 3.2. We will only use your Account
                    Information in accordance with the Privacy Policy, and you consent to such usage. The Privacy Policy
            does not apply to Your Content.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>3.3
                    Service Attributes. To provide billing and administration services, we may process Service Attributes
                    where you use the Service Offerings. To provide you with support services initiated by you and investigate fraud, abuse or
                    violations of this Agreement, we may process Service Attributes where we maintain our support and
            investigation personnel.</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '14pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>4.
            Your Responsibilities.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>4.1
                    Your Accounts. Except to the extent caused by our breach of this Agreement, (a) you are responsible for
                    all activities that occur under your account, regardless of whether the activities are authorized by
                    you or undertaken by you, your employees or a third party (including your contractors, agents or End
            Users), and (b) we and our affiliates are not responsible for unauthorized access to your account.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>4.2
                    Your Content. You will ensure that Your Content and your and End Users’ use of Your Content or the
                    Service Offerings will not violate any of the Policies or any applicable law. You are solely
            responsible for the development, content, operation, maintenance, and use of Your Content.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>4.3
                    Your Security and Backup. You are responsible for properly configuring and using the Service Offerings
                    and otherwise taking appropriate action to secure, protect and backup your accounts and Your Content in
                    a manner that will provide appropriate security and protection, which might include use of encryption
            to protect Your Content from unauthorized access and routinely archiving Your Content.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>4.4
                    Log-In Credentials or Account Keys. HireForceOne log-in credentials or private keys generated by the
                    Services are for your internal use only and you will not sell, transfer or sublicense them to any other
                    entity or person, except that you may disclose your private key to your agents and subcontractors
            performing work on your behalf.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>4.5
                    End Users. You will be deemed to have taken any action that you permit, assist or facilitate any person
                    or entity to take related to this Agreement, Your Content or use of the Service Offerings. You are
                    responsible for End Users’ use of Your Content and the Service Offerings. You will ensure that all End
                    Users comply with your obligations under this Agreement and that the terms of your agreement with each
                    End User are consistent with this Agreement. If you become aware of any violation of your obligations
                    under this Agreement caused by an End User, you will immediately suspend access to Your Content and the
                    Service Offerings by such End User. We do not provide any support or services to End Users unless we
            have a separate agreement with you or an End User obligating us to provide such support or services.</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '14pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>5.
            Fees and Payment.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>5.1
                    Service Fees. We calculate and bill fees and charges annually. We may bill you more frequently for fees
                    accrued if we suspect that your account is fraudulent or at risk of non-payment. You will pay us the
                    applicable fees and charges for use of the Service Offerings as described on the HireForceOne.org
                    Site using one of the payment methods we support. All amounts payable by you under this Agreement will
                    be paid to us without setoff or counterclaim, and without any deduction or withholding. Fees and
                    charges for any new Service or new feature of a Service will be effective when we post updated fees and
                    charges on the HireForceOne.org Site, unless we expressly state otherwise in a notice. We may
                    increase or add new fees and charges for any existing Services you are using by giving you at least 30
                    days’ prior notice. We may elect to charge you interest at the rate of 1.5% per month (or the highest
            rate permitted by law, if less) on all late payments.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>5.2
                    Taxes. Each party will be responsible, as required under applicable law, for identifying and paying all
                    taxes and other governmental fees and charges (and any penalties, interest, and other additions
                    thereto) that are imposed on that party upon or with respect to the transactions and payments under
                    this Agreement. All fees payable by you are exclusive of Indirect Taxes. We may charge and you will pay
                    applicable Indirect Taxes that we are legally obligated or authorized to collect from you. You will
                    provide such information to us as reasonably required to determine whether we are obligated to collect
                    Indirect Taxes from you. We will not collect, and you will not pay, any Indirect Tax for which you
                    furnish us a properly completed exemption certificate or a direct payment permit certificate for which
                    we may claim an available exemption from such Indirect Tax. All payments made by you to us under this
                    Agreement will be made free and clear of any deduction or withholding, as may be required by law. If
                    any such deduction or withholding (including but not limited to cross-border withholding taxes) is
                    required on any payment, you will pay such additional amounts as are necessary so that the net amount
                    received by us is equal to the amount then due and payable under this Agreement. We will provide you
                    with such tax forms as are reasonably requested in order to reduce or eliminate the amount of any
            withholding or deduction for taxes in respect of payments made under this Agreement.</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '14pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>6.
            Temporary Suspension.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>6.1
                    Generally. We may suspend your or any End User’s right to access or use any portion or all of the
            Service Offerings immediately upon notice to you if we determine:</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(a)
                    your or an End User’s use of the Service Offerings (i) poses a security risk to the Service Offerings
                    or any third party, (ii) could adversely impact our systems, the Service Offerings or the systems or
                    Content of any other HireForceOne customer, (iii) could subject us, our affiliates, or any third party
            to liability, or (iv) could be fraudulent;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(b)
            you are, or any End User is, in breach of this Agreement;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(c)
            you are in breach of your payment obligations under Section 5; or</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(d)
                    you have ceased to operate in the ordinary course, made an assignment for the benefit of creditors or
                    similar disposition of your assets, or become the subject of any bankruptcy, reorganization,
            liquidation, dissolution or similar proceeding.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>6.2
                    Effect of Suspension. If we suspend your right to access or use any portion or all of the Service
            Offerings:</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(a)
            you remain responsible for all fees and charges you incur during the period of suspension; and</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(b)
                    you will not be entitled to any service credits under the Service Level Agreements for any period of
            suspension.</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '14pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>7.
            Term; Termination.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>7.1
                    Term. The term of this Agreement will commence on the Effective Date and will remain in effect until
                    terminated under this Section 7. Any notice of termination of this Agreement by either party to the
            other must include a Termination Date that complies with the notice periods in Section 7.2.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>7.2
            Termination.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(a)
                    Termination for Convenience. You may terminate this Agreement for any reason by providing us notice and
                    closing your account for all Services for which we provide an account closing mechanism. We may
            terminate this Agreement for any reason by providing you at least 30 days’ advance notice.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(b)
            Termination for Cause.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(i)
                    By Either Party. Either party may terminate this Agreement for cause if the other party is in material
                    breach of this Agreement and the material breach remains uncured for a period of 30 days from receipt
            of notice by the other party. No later than the Termination Date, you will close your account.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(ii)
                    By Us. We may also terminate this Agreement immediately upon notice to you (A) for cause if we have the
                    right to suspend under Section 6, (B) if our relationship with a third-party partner who provides
                    software or other technology we use to provide the Service Offerings expires, terminates or requires us
                    to change the way we provide the software or other technology as part of the Services, or (C) in order
            to comply with the law or requests of governmental entities.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>7.3
            Effect of Termination.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(a)
            Generally. Upon the Termination Date:</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(i)
            except as provided in Section 7.3(b), all your rights under this Agreement immediately terminate;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(ii)
                    you remain responsible for all fees and charges you have incurred through the Termination Date and are
                    responsible for any fees and charges you incur during the post-termination period described in Section
            7.3(b);</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(iii)
                    you will immediately return or, if instructed by us, destroy all HireForceOne Content in your
            possession; and</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(iv)
                    Sections 4.1, 5, 7.3, 8 (except the license granted to you in Section 8.3), 9, 10, 11, 13 and 14 will
            continue to apply in accordance with their terms.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(b)
                    Post-Termination. Unless we terminate your use of the Service Offerings pursuant to Section 7.2(b),
            during the 30 days following the Termination Date:</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(i)
                    we will not take action to remove from the HireForceOne systems any of Your Content as a result of the
            termination; and</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(ii)
                    we will allow you to retrieve Your Content from the Services only if you have paid all amounts due
            under this Agreement.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>For
                    any use of the Services after the Termination Date, the terms of this Agreement will apply and you will
            pay the applicable fees at the rates under Section 5.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '14pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>8.
            Proprietary Rights.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>8.1
                    Your Content. Except as provided in this Section 8, we obtain no rights under this Agreement from you
                    (or your licensors) to Your Content. You consent to our use of Your Content to provide the Service
            Offerings to you and any End Users.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>8.2
                    Adequate Rights. You represent and warrant to us that: (a) you or your licensors own all right, title,
                    and interest in and to Your Content and Suggestions; (b) you have all rights in Your Content and
                    Suggestions necessary to grant the rights contemplated by this Agreement; and (c) none of Your Content
            or End Users’ use of Your Content or the Service Offerings will violate the Acceptable Use Policy.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>8.3
                    Service Offerings License. We or our licensors own all right, title, and interest in and to the Service
                    Offerings, and all related technology and intellectual property rights. Subject to the terms of this
                    Agreement, we grant you a limited, revocable, non-exclusive, non-sublicensable, non-transferrable
                    license to do the following: (a) access and use the Services solely in accordance with this Agreement;
                    and (b) copy and use the HireForceOne Content solely in connection with your permitted use of the
                    Services. Except as provided in this Section 8.3, you obtain no rights under this Agreement from us,
                    our affiliates or our licensors to the Service Offerings, including any related intellectual property
                    rights. Some HireForceOne Content and Third-Party Content may be provided to you under a separate
                    license, such as the Apache License, Version 2.0, or other open source license. In the event of a
                    conflict between this Agreement and any separate license, the separate license will prevail with
                    respect to the HireForceOne Content or Third-Party Content that is the subject of such separate
            license.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>8.4
                    License Restrictions. Neither you nor any End User will use the Service Offerings in any manner or for
                    any purpose other than as expressly permitted by this Agreement. Neither you nor any End User will, or
                    will attempt to (a) modify, distribute, alter, tamper with, repair, or otherwise create derivative
                    works of any Content included in the Service Offerings (except to the extent Content included in the
                    Service Offerings is provided to you under a separate license that expressly permits the creation of
                    derivative works), (b) reverse engineer, disassemble, or decompile the Service Offerings or apply any
                    other process or procedure to derive the source code of any software included in the Service Offerings
                    (except to the extent applicable law doesn’t allow this restriction), (c) access or use the Service
                    Offerings in a way intended to avoid incurring fees or exceeding usage limits or quotas, or (d) resell
                    or sublicense the Service Offerings. You may only use the HireForceOne Marks in accordance with the
                    Trademark Use Guidelines. You will not misrepresent or embellish the relationship between us and you
                    (including by expressing or implying that we support, sponsor, endorse, or contribute to you or your
                    business endeavors). You will not imply any relationship or affiliation between us and you except as
            expressly permitted by this Agreement.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>8.5
                    Suggestions. If you provide any Suggestions to us or our affiliates, we and our affiliates will be
                    entitled to use the Suggestions without restriction. You hereby irrevocably assign to us all right,
                    title, and interest in and to the Suggestions and agree to provide us any assistance we require to
            document, perfect, and maintain our rights in the Suggestions.</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '14pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>9.
            Indemnification.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>9.1
                    General. You will defend, indemnify, and hold harmless us, our affiliates and licensors, and each of
                    their respective employees, officers, directors, and representatives from and against any Losses
                    arising out of or relating to any third-party claim concerning: (a) your or any End Users’ use of the
                    Service Offerings (including any activities under your HireForceOne account and use by your employees
                    and personnel); (b) breach of this Agreement or violation of applicable law by you, End Users or Your
                    Content; or (c) a dispute between you and any End User. You will reimburse us for reasonable attorneys’
                    fees, as well as our employees’ and contractors’ time and materials spent responding to any third party
                    subpoena or other compulsory legal order or process associated with third party claims described in (a)
            through (c) above at our then-current hourly rates.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>9.2
            Intellectual Property.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(a)
                    Subject to the limitations in this Section 9, HireForceOne will defend you and your employees,
                    officers, and directors against any third-party claim alleging that the Services infringe or
                    misappropriate that third party’s intellectual property rights, and will pay the amount of any adverse
            final judgment or settlement.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(b)
                    Subject to the limitations in this Section 9, you will defend us, our affiliates, and their
                    respective employees, officers, and directors against any third-party claim alleging that any of Your
                    Content infringes or misappropriates that third party’s intellectual property rights, and will pay the
            amount of any adverse final judgment or settlement.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(c)
                    Neither party will have obligations or liability under this Section 9.2 arising from infringement by
                    combinations of the Services or Your Content, as applicable, with any other product, service, software,
                    data, content or method. In addition, we will have no obligations or liability arising from
                    your or any End User’s use of the Services after we have notified you to discontinue such
                    use. The remedies provided in this Section 9.2 are the sole and exclusive remedies for any third-party
                    claims of infringement or misappropriation of intellectual property rights by the Services or by Your
            Content.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(d)
                    For any claim covered by Section 9.2(a), we will, at its election, either: (i) procure the
                    rights to use that portion of the Services alleged to be infringing; (ii) replace the alleged
                    infringing portion of the Services with a non-infringing alternative; (iii) modify the alleged
                    infringing portion of the Services to make it non-infringing; or (iv) terminate the allegedly
            infringing portion of the Services or this Agreement.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>9.3
                    Process. The obligations under this Section 9 will apply only if the party seeking defense or
                    indemnity: (a) gives the other party prompt written notice of the claim; (b) permits the other party to
                    control the defense and settlement of the claim; and (c) reasonably cooperates with the other party (at
                    the other party’s expense) in the defense and settlement of the claim. In no event will a party agree
                    to any settlement of any claim that involves any commitment, other than the payment of money, without
            the written consent of the other party.</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '14pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>10.
            Disclaimers.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>THE
                    SERVICE OFFERINGS ARE PROVIDED “AS IS.” EXCEPT TO THE EXTENT PROHIBITED BY LAW, OR TO THE EXTENT ANY
                    STATUTORY RIGHTS APPLY THAT CANNOT BE EXCLUDED, LIMITED OR WAIVED, WE AND OUR AFFILIATES AND LICENSORS
                    (A) MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE
                    REGARDING THE SERVICE OFFERINGS OR THE THIRD-PARTY CONTENT, AND (B) DISCLAIM ALL WARRANTIES, INCLUDING
                    ANY IMPLIED OR EXPRESS WARRANTIES (I) OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A
                    PARTICULAR PURPOSE, NON-INFRINGEMENT, OR QUIET ENJOYMENT, (II) ARISING OUT OF ANY COURSE OF DEALING OR
                    USAGE OF TRADE, (III) THAT THE SERVICE OFFERINGS OR THIRD-PARTY CONTENT WILL BE UNINTERRUPTED, ERROR
                    FREE OR FREE OF HARMFUL COMPONENTS, AND (IV) THAT ANY CONTENT WILL BE SECURE OR NOT OTHERWISE LOST OR
            ALTERED.</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '14pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>11.
            Limitations of Liability.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>WE
                    AND OUR AFFILIATES AND LICENSORS WILL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL,
                    SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, REVENUES,
                    CUSTOMERS, OPPORTUNITIES, GOODWILL, USE, OR DATA), EVEN IF A PARTY HAS BEEN ADVISED OF THE POSSIBILITY
                    OF SUCH DAMAGES. FURTHER, NEITHER WE NOR ANY OF OUR AFFILIATES OR LICENSORS WILL BE RESPONSIBLE FOR ANY
                    COMPENSATION, REIMBURSEMENT, OR DAMAGES ARISING IN CONNECTION WITH: (A) YOUR INABILITY TO USE THE
                    SERVICES, INCLUDING AS A RESULT OF ANY (I) TERMINATION OR SUSPENSION OF THIS AGREEMENT OR YOUR USE OF
                    OR ACCESS TO THE SERVICE OFFERINGS, (II) OUR DISCONTINUATION OF ANY OR ALL OF THE SERVICE OFFERINGS,
                    OR, (III) WITHOUT LIMITING ANY OBLIGATIONS UNDER THE SERVICE LEVEL AGREEMENTS, ANY UNANTICIPATED OR
                    UNSCHEDULED DOWNTIME OF ALL OR A PORTION OF THE SERVICES FOR ANY REASON; (B) THE COST OF PROCUREMENT OF
                    SUBSTITUTE GOODS OR SERVICES; (C) ANY INVESTMENTS, EXPENDITURES, OR COMMITMENTS BY YOU IN CONNECTION
                    WITH THIS AGREEMENT OR YOUR USE OF OR ACCESS TO THE SERVICE OFFERINGS; OR (D) ANY UNAUTHORIZED ACCESS
                    TO, ALTERATION OF, OR THE DELETION, DESTRUCTION, DAMAGE, LOSS OR FAILURE TO STORE ANY OF YOUR CONTENT
                    OR OTHER DATA. IN ANY CASE, EXCEPT FOR PAYMENT OBLIGATIONS UNDER SECTION 9.2, OUR AND OUR AFFILIATES’
                    AND LICENSORS’ AGGREGATE LIABILITY UNDER THIS AGREEMENT WILL NOT EXCEED THE AMOUNT YOU ACTUALLY PAY US
                    UNDER THIS AGREEMENT FOR THE SERVICE THAT GAVE RISE TO THE CLAIM DURING THE 12 MONTHS BEFORE THE
                    LIABILITY AROSE. THE LIMITATIONS IN THIS SECTION 11 APPLY ONLY TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '14pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>12.
            Modifications to the Agreement.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>We
                    may modify this Agreement (including any Policies) at any time by posting a revised version on the
                    HireForceOne.org Site or by otherwise notifying you in accordance with Section 13.10; provided,
                    however, that we will provide at least 90 days’ advance notice in accordance with Section 13.10 for
                    adverse changes to any Service Level Agreement. Subject to the 90 day advance notice requirement with
                    respect to adverse changes to Service Level Agreements, the modified terms will become effective upon
                    posting or, if we notify you by email, as stated in the email message. By continuing to use the Service
                    Offerings after the effective date of any modifications to this Agreement, you agree to be bound by the
                    modified terms. It is your responsibility to check the HireForceOne Site regularly for modifications
            to this Agreement. We last modified this Agreement on the date listed at the end of this Agreement.</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '14pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>13.
            Miscellaneous.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>13.1
                    Assignment. You will not assign or otherwise transfer this Agreement or any of your rights and
                    obligations under this Agreement, without our prior written consent. Any assignment or transfer in
                    violation of this Section 13.1 will be void. We may assign this Agreement without your consent (a) in
                    connection with a merger, acquisition or sale of all or substantially all of our assets, or (b) to any
                    Affiliate or as part of a corporate reorganization; and effective upon such assignment, the assignee is
                    deemed substituted for us as a party to this Agreement and we are fully released
                    from all of its obligations and duties to perform under this Agreement. Subject to the foregoing, this
                    Agreement will be binding upon, and inure to the benefit of the parties and their respective permitted
            successors and assigns.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>13.2
                    Entire Agreement. This Agreement incorporates the Policies by reference and is the entire agreement
                    between you and us regarding the subject matter of this Agreement. This Agreement supersedes all prior
                    or contemporaneous representations, understandings, agreements, or communications between you and us,
                    whether written or verbal, regarding the subject matter of this Agreement (but does not supersede prior
                    commitments to purchase Services). We will not be bound by, and specifically object to, any term,
                    condition or other provision that is different from or in addition to the provisions of this Agreement
                    (whether or not it would materially alter this Agreement) including for example, any term, condition or
                    other provision (a) submitted by you in any order, receipt, acceptance, confirmation, correspondence or
                    other document, (b) related to any online registration, response to any Request for Bid, Request for
                    Proposal, Request for Information, or other questionnaire, or (c) related to any invoicing process that
                    you submit or require us to complete. If the terms of this document are inconsistent with the terms
                    contained in any Policy, the terms contained in this document will control, except that the Service
            Terms will control over this document.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>13.3
                    Force Majeure. We and our affiliates will not be liable for any delay or failure to perform any
                    obligation under this Agreement where the delay or failure results from any cause beyond our reasonable
                    control, including acts of God, labor disputes or other industrial disturbances, electrical or power
                    outages, utilities or other telecommunications failures, earthquake, storms or other elements of
            nature, blockages, embargoes, riots, acts or orders of government, acts of terrorism, or war.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>13.4
                    Governing Law. The Governing Laws, without reference to conflict of law rules, govern this Agreement
                    and any dispute of any sort that might arise between you and us. The United Nations Convention for the
            International Sale of Goods does not apply to this Agreement.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>13.5
                    Disputes. Any dispute or claim relating in any way to your use of the Service Offerings, or to any
                    products or services sold or distributed by us will be adjudicated in the Governing Courts,
                    and you consent to exclusive jurisdiction and venue in the Governing Courts. Any such dispute will be
                    resolved by binding arbitration as provided in this Section 13.5, rather than in court, except that you
                    may assert claims in small claims court if your claims qualify. Before litigation, you will first
                    attempt to get your concern addressed via arbitration. There is no judge or jury in arbitration, and
                    court review of an arbitration award is limited. However, an arbitrator can award on an individual
                    basis the same damages and relief as a court (including injunctive and declaratory relief or statutory
                    damages), and must follow the terms of this Agreement as a court would. To begin an arbitration
                    proceeding, you must send a letter requesting arbitration and describing your claim to our
                    headquarter address. We will reimburse those fees for claims totaling less than $10,000 unless the
                    arbitrator determines the claims are frivolous. We will not seek attorneys' fees and costs in
                    arbitration unless the arbitrator determines the claims are frivolous. You may choose to have the
                    arbitration conducted by telephone, based on written submissions, or at a mutually agreed location. We
                    and you agree that any dispute resolution proceedings will be conducted only on an individual basis and
                    not in a class, consolidated or representative action. If for any reason a claim proceeds in court
                    rather than in arbitration we and you waive any right to a jury trial. Notwithstanding the foregoing we
                    and you both agree that you or we may bring suit in court to enjoin infringement or other misuse of
            intellectual property rights.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>13.6
                    Trade Compliance. In connection with this Agreement, each party will comply with all applicable import,
                    re-import, sanctions, anti-boycott, export, and re-export control laws and regulations. For clarity,
                    you are solely responsible for compliance related to the manner in which you choose to use the Service
                    Offerings, including your transfer and processing of Your Content, the provision of Your Content to End
                    Users, and the region in which any of the foregoing occur. You represent and warrant that you and your
                    financial institutions, or any party that owns or controls you or your financial institutions, are not
                    subject to sanctions or otherwise designated on any list of prohibited or restricted parties, including
                    but not limited to the lists maintained by the United Nations Security Council, the U.S. Government
                    (e.g., the Specially Designated Nationals List and Foreign Sanctions Evaders List of the U.S.
                    Department of Treasury, and the Entity List of the U.S. Department of Commerce), the European Union or
            its Member States, or other applicable government authority.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>13.7
                    Independent Contractors; Non-Exclusive Rights. We and you are independent contractors, and this
                    Agreement will not be construed to create a partnership, joint venture, agency, or employment
                    relationship. Neither party, nor any of their respective affiliates, is an agent of the other for any
                    purpose or has the authority to bind the other. Both parties reserve the right (a) to develop or have
                    developed for it products, services, concepts, systems, or techniques that are similar to or compete
                    with the products, services, concepts, systems, or techniques developed or contemplated by the other
                    party, and (b) to assist third party developers or systems integrators who may offer products or
            services which compete with the other party’s products or services.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>13.8
                    Language. All communications and notices made or given pursuant to this Agreement must be in the
                    English language. If we provide a translation of the English language version of this Agreement, the
            English language version of the Agreement will control if there is any conflict.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>13.9
                    Confidentiality and Publicity. You may use HireForceOne Confidential Information only in connection
                    with your use of the Service Offerings as permitted under this Agreement. You will not disclose
                    HireForceOne Confidential Information during the Term or at any time during the 5-year period
                    following the end of the Term. You will take all reasonable measures to avoid disclosure, dissemination
                    or unauthorized use of HireForceOne Confidential Information, including, at a minimum, those measures
                    you take to protect your own confidential information of a similar nature. You will not issue any press
                    release or make any other public communication with respect to this Agreement or your use of the
            Service Offerings.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>13.10
            Notice.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(a)
                    To You. We may provide any notice to you under this Agreement by: (i) posting a notice on the
                    HireForceOne Site; or (ii) sending a message to the email address then associated with your account.
                    Notices we provide by posting on the HireForceOne Site will be effective upon posting and notices we
                    provide by email will be effective when we send the email. It is your responsibility to keep your email
                    address current. You will be deemed to have received any email sent to the email address then
            associated with your account when we send the email, whether or not you actually receive the email.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>(b)
                    To Us. To give us notice under this Agreement, you must contact us by mail (benevolentears@HireForceOne.org)
                    or personal delivery, overnight courier or registered or certified mail or Email,
                    as applicable, listed for the applicable Contracting Party in Section 14
                    below. We may update the Email or address for notices to us by posting a notice on the
                    HireForceOne Site. Notices provided by personal delivery will be effective immediately. Notices
                    provided by Email transmission or overnight courier will be effective one business day after they
                    are sent. Notices provided registered or certified mail will be effective three business days after
            they are sent.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>13.11
                    No Third-Party Beneficiaries. Except as set forth in Section 9, this Agreement does not create any
            third-party beneficiary rights in any individual or entity that is not a party to this Agreement.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>13.12
                    No Waivers. The failure by us to enforce any provision of this Agreement will not constitute a present
                    or future waiver of such provision nor limit our right to enforce such provision at a later time. All
            waivers by us must be in writing to be effective.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>13.13
                    Severability. If any portion of this Agreement is held to be invalid or unenforceable, the remaining
                    portions of this Agreement will remain in full force and effect. Any invalid or unenforceable portions
                    will be interpreted to effect and intent of the original portion. If such construction is not possible,
                    the invalid or unenforceable portion will be severed from this Agreement but the rest of the Agreement
            will remain in full force and effect.</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '14pt' }}><span style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', color: '#1b232f' }}>14.
            Definitions.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Acceptable
            Use Policy” means the policy located at the HireForceOne Site (and any successor or related locations designated by us), as it may be updated by us from time to
            time.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Account
                    Country” is the country associated with your account. If you have provided a valid tax registration
                    number for your account, then your Account Country is the country associated with your tax
                    registration. If you have not provided a valid tax registration, then your Account Country is the
                    country where your billing address is located, except if your credit card or bank account is issued in
                    a different country and your contact address is also in that country, then your Account Country is that
            different country.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Account
                    Information” means information about you that you provide to us in connection with the creation or
                    administration of your HireForceOne account. For example, Account Information includes names,
                    usernames, phone numbers, email addresses and billing information associated with your HireForceOne
            account.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“API”
            means an application program interface.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“HireForceOne Confidential Information” means all nonpublic information disclosed by us, our affiliates, business
                    partners or our or their respective employees, contractors or agents that is designated as confidential
                    or that, given the nature of the information or circumstances surrounding its disclosure, reasonably
                    should be understood to be confidential. HireForceOne Confidential Information includes: (a) nonpublic
                    information relating to our or our affiliates or business partners’ technology, customers, business
                    plans, promotional and marketing activities, finances and other business affairs; (b) third-party
                    information that we are obligated to keep confidential; and (c) the nature, content and existence of
                    any discussions or negotiations between you and us or our affiliates. HireForceOne Confidential
                    Information does not include any information that: (i) is or becomes publicly available without breach
                    of this Agreement; (ii) can be shown by documentation to have been known to you at the time of your
                    receipt from us; (iii) is received from a third party who did not acquire or disclose the same by a
                    wrongful or tortious act; or (iv) can be shown by documentation to have been independently developed by
            you without reference to the HireForceOne Confidential Information.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“
                    HireForceOne Content” means Content we or any of our affiliates make available in connection with the Services or
                    on the HireForceOne Site to allow access to and use of the Services, including APIs; WSDLs;
                    Documentation; sample code; software libraries; command line tools; proofs of concept; templates; and
                    other related technology (including any of the foregoing that are provided by our personnel).
            HireForceOne Content does not include the Services or Third-Party Content.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>"Contracting Party" means the party identified in the table below, based on your Account Country. If
                    you change your Account Country to one identified to a different Contracting Party below,
                    you agree that this Agreement is then assigned to the new Contracting Party under Section
            13.1 without any further action required by either party.</span><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>&nbsp;</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“
                    HireForceOne Marks” means any trademarks, service marks, service or trade names, logos, and other designations of
            us and our affiliates that we may make available to you in connection with this Agreement.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“
                    HireForceOne Site” means http://www.HireForceOne.org&nbsp; (and any successor or related site designated by us), as may
            be updated by us from time to time.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Content”
            means software (including machine images), data, text, audio, video or images.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Documentation”
                    means the user guides and admin guides (in each case exclusive of content referenced via hyperlink) for
                    the Services located at the HireForceOne Site (and any successor or related locations designated by
            us), as such user guides and admin guides may be updated by us from time to time.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“End
                    User” means any individual or entity that directly or indirectly through another user: (a) accesses or
                    uses Your Content; or (b) otherwise accesses or uses the Service Offerings under your account. The term
                    “End User” does not include individuals or entities when they are accessing or using the Services or
            any Content under their own HireForceOne account, rather than under your account.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>"Governing
                    Law” and “Governing Courts” mean, for each Contracting Party, the laws and courts set
            forth the court of Antwerp, Belgium. </span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Indirect
                    Taxes” means applicable taxes and duties, including, without limitation, VAT, Service Tax, GST, excise
            taxes, sales and transactions taxes, and gross receipts tax.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Losses”
                    means any claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys’
            fees).</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Policies”
                    means the Acceptable Use Policy, Privacy Policy, the Site Terms, the Service Terms, the Trademark Use
                    Guidelines, all restrictions described in the HireForceOne Content and on the HireForceOne Site, and
                    any other policy or terms referenced in or incorporated into this Agreement, but does not include
            whitepapers or other marketing materials referenced on the HireForceOne Site.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Privacy
            Policy” means the privacy policy located at the HireForceOne Site and it may be updated by us from time to time.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Service”
                    means each of the services made available by us or our affiliates, including those web services
            described in the Service Terms. Services do not include Third-Party Content.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Service
                    Attributes” means Service usage data related to your account, such as resource identifiers, metadata
            tags, security and access roles, rules, usage policies, permissions, usage statistics and analytics.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Service
                      Level Agreement” means all service level agreements that we offer with respect to the Services and post
                      on the HireForceOne Site, as they may be updated by us from time to time. The service level agreements
            we offer with respect to the Services are located at the HireForceOne Site</span>
                    <span style={{ fontFamily: 'Helvetica', color: '#262626' }}> (and any successor or related locations designated by
            us), as may be updated us from time to time.</span>
                </p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Service
                    Offerings” means the Services (including associated APIs), the HireForceOne Content, the HireForceOne
                    Marks, and any other product or service provided by us under this Agreement. Service Offerings do not
            include Third-Party Content.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Service
            Terms” means the rights and restrictions for particular Services located at the HireForceOne Site</span>
                    <span style={{ fontFamily: 'Helvetica', color: '#262626' }}> (and any successor or related locations designated by
            us), as may be updated by us from time to time.</span>
                </p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Site
            Terms” means the terms of use located at the HireForceOne Site </span>
                    <span style={{ fontFamily: 'Helvetica', color: '#262626' }}> (and any successor or related locations designated by
            us), as may be updated by us from time to time.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Suggestions”
            means all suggested improvements to the Service Offerings that you provide to us.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Term”
            means the term of this Agreement described in Section 7.1.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Termination
                    Date” means the effective date of termination provided in accordance with Section 7, in a notice from
            one party to the other.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Third-Party
                    Content” means Content made available to you by any third party on the HireForceOne Site or in
            conjunction with the Services.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Trademark
            Use Guidelines” means the guidelines and trademark license located at the HireForceOne Site</span>
                    <span style={{ fontFamily: 'Helvetica', color: '#262626' }}> (and any successor or related locations designated by
            us), as they may be updated by us from time to time.</span></p>
                <p style={{ marginTop: '0pt', marginBottom: '0pt', widows: 0, orphans: 0, fontSize: '12pt' }}><span style={{ fontFamily: 'Helvetica', color: '#262626' }}>“Your
                    Content” means Content that you or any End User transfers to us for processing, storage or hosting by
                    the Services in connection with your HireForceOne account and any computational results that you or
            any End User derive from the foregoing through their use of the Services.</span></p>
            </div>
        );
    }
}

export default LicenseText;
