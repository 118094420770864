import { FIELD_ERROR_STATUS_COLOR, FIELD_ERROR_STATUS_SHADOW } from "./constants";
import {
    LEVEL_COLORS, LEVEL_LIGHT_COLORS,
    REQUIREMENT_COEFFICIENT_COLORS, REQUIREMENT_COEFFICIENT_LIGHT_COLORS
} from "./skillsConstants";

const SIZE = {
    XS: "xs",
    SM: "sm",
    MD: "md"
}

const DEFAULT_SIZE = SIZE.MD;

const SELECT_HEIGHT = {
    [SIZE.XS]: "32px",
    [SIZE.SM]: "34px",
    [SIZE.MD]: "38px"
}

const SELECT_FONT_SIZE = {
    [SIZE.XS]: "14px",
    [SIZE.SM]: "14px",
    [SIZE.MD]: "16px"
}

const SELECT_VALUE_HEIGHT = {
    [SIZE.XS]: "30px",
    [SIZE.SM]: "32px",
    [SIZE.MD]: "36px"
}

const SELECT_VALUE_PADDING = {
    [SIZE.XS]: "2px 8px",
    [SIZE.SM]: "2px 8px",
    [SIZE.MD]: "6px 12px"
}

const OPTION_DEFAULT_STYLES = {
    cursor: 'pointer',
    paddingTop: '0',
    paddingBottom: '0'
}

const SINGLE_VALUE_DEFAULT_STYLES = {
    height: '21px',
    padding: '3px 0'
}

export const SELECT_DEFAULT_STYLES = {
    container: (styles, state) => ({
        ...styles,
        height: state.isMulti ? null : SELECT_HEIGHT[state.selectProps.sizeType || DEFAULT_SIZE]
    }),
    control: (styles, state) => ({
        ...styles,
        boxShadow: state.isFocused ?
            state.selectProps.status ?
                '0 0 0 0.2rem ' + FIELD_ERROR_STATUS_SHADOW[state.selectProps.status]
                : '0 0 0 0.2rem rgba(137, 170, 17, 0.25)'
            : 'none',
        lineHeight: '1',
        cursor: 'pointer',
        border: state.selectProps.status ? '1px solid ' + FIELD_ERROR_STATUS_COLOR[state.selectProps.status] : state.isFocused ? '1px solid #b8da9d' : "1px solid #ced4da",
        ":hover": {
            border: state.selectProps.status ? '1px solid ' + FIELD_ERROR_STATUS_COLOR[state.selectProps.status] : state.isFocused ? '1px solid #b8da9d' : "1px solid #ced4da",
        },
        fontSize: SELECT_FONT_SIZE[state.selectProps.sizeType || DEFAULT_SIZE],
        minHeight: SELECT_HEIGHT[state.selectProps.sizeType || DEFAULT_SIZE],
        height: state.isMulti ? null : SELECT_HEIGHT[state.selectProps.sizeType || DEFAULT_SIZE]
    }),
    valueContainer: (styles, state) => ({
        ...styles,
        padding: SELECT_VALUE_PADDING[state.selectProps.sizeType || DEFAULT_SIZE],
        height: state.isMulti ? null : SELECT_VALUE_HEIGHT[state.selectProps.sizeType || DEFAULT_SIZE]
    }),
    placeholder: styles => ({
        ...styles,
        color: '#6c757d',
        marginLeft: '4px',
        marginRight: '2px'
    }),
    clearIndicator: styles => ({
        ...styles,
        padding: '4px',
        cursor: 'pointer'
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        padding: '4px',
        cursor: 'pointer',
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none'
    }),
    singleValue: styles => ({
        ...styles,
        ...SINGLE_VALUE_DEFAULT_STYLES
    }),
    option: (provided, state) => ({
        ...provided,
        ...OPTION_DEFAULT_STYLES,
        backgroundColor: state.isSelected ? '#89aa11' : 'white',
        ":hover": {
            backgroundColor: state.isSelected ? '#89aa11' : 'rgba(137, 170, 17, 0.25)'
        },
        ":active": {
            backgroundColor: state.isSelected ? '#89aa11' : 'rgba(137, 170, 17, 0.25)'
        },
    }),
    menu: styles => ({
        ...styles,
        marginTop: '1px',
        marginBottom: '0',
        zIndex: 1000
    }),
    menuList: styles => ({
        ...styles,
        paddingTop: '2px',
        paddingBottom: '2px'
    })
};

export const SELECT_LEVELS_STYLES = {
    ...SELECT_DEFAULT_STYLES,
    option: (provided, state) => ({
        ...provided,
        ...OPTION_DEFAULT_STYLES,
        backgroundColor: state.isSelected ? LEVEL_COLORS[state.data.value] : 'white',
        ":hover": {
            backgroundColor: state.isSelected ? LEVEL_COLORS[state.data.value] : LEVEL_LIGHT_COLORS[state.data.value]
        },
        color: state.isSelected ? 'white' : LEVEL_COLORS[state.data.value],
        fontWeight: 500,
    }),
    singleValue: (styles, state) => ({
        ...styles,
        ...SINGLE_VALUE_DEFAULT_STYLES,
        color: LEVEL_COLORS[state.data.value],
        fontWeight: 500,
    }),
}

export const SELECT_EAGERNESS_STYLES = {
    ...SELECT_DEFAULT_STYLES,
    option: (provided, state) => ({
        ...provided,
        ...OPTION_DEFAULT_STYLES,
        backgroundColor: 'transparent',
        ":hover": {
            backgroundColor: 'transparent'
        },
        color: "#212529"
    }),
};

export const SELECT_REQUIREMENT_COEFFICIENTS_STYLES = {
    ...SELECT_DEFAULT_STYLES,
    option: (provided, state) => ({
        ...provided,
        ...OPTION_DEFAULT_STYLES,
        backgroundColor: state.isSelected ? REQUIREMENT_COEFFICIENT_COLORS[state.data.value] : 'white',
        ":hover": {
            backgroundColor: state.isSelected ? REQUIREMENT_COEFFICIENT_COLORS[state.data.value] : REQUIREMENT_COEFFICIENT_LIGHT_COLORS[state.data.value]
        },
        color: state.isSelected ? 'white' : REQUIREMENT_COEFFICIENT_COLORS[state.data.value],
        fontWeight: 500,
    }),
    singleValue: (styles, state) => ({
        ...styles,
        ...SINGLE_VALUE_DEFAULT_STYLES,
        color: REQUIREMENT_COEFFICIENT_COLORS[state.data.value],
        fontWeight: 500,
    }),
}


export const SELECT_SYSTEM_NODE_STYLES = {
    ...SELECT_DEFAULT_STYLES,
    valueContainer: (styles, state) => ({
        ...styles,
        padding: "0",
        paddingLeft: '12px',
        height: state.isMulti ? null : SELECT_VALUE_HEIGHT[state.selectProps.sizeType || DEFAULT_SIZE]
    }),
    option: (provided, state) => ({
        ...provided,
        ...OPTION_DEFAULT_STYLES,
        backgroundColor: state.isSelected ? "#e2e2e2" : 'white',
        ":hover": {
            backgroundColor: state.isSelected ? "#e2e2e2" : "#f1f1f1"
        },
        paddingTop: '5px',
        paddingBottom: '5px'
    }),
}


export const SELECT_NODE_STATUS_STYLES = {
    ...SELECT_DEFAULT_STYLES,
    valueContainer: (styles, state) => ({
        ...styles,
        padding: "0",
        paddingLeft: "0",
        height: state.isMulti ? null : SELECT_VALUE_HEIGHT[state.selectProps.sizeType || DEFAULT_SIZE]
    }),
    option: (provided, state) => ({
        ...provided,
        ...OPTION_DEFAULT_STYLES,
        backgroundColor: state.isSelected ? "#e2e2e2" : 'white',
        ":hover": {
            backgroundColor: state.isSelected ? "#e2e2e2" : "#f1f1f1"
        },
        paddingTop: '5px',
        paddingBottom: '5px',
        color: null,
    }),
    multiValueRemove: styles => ({
        ...styles,
        paddingLeft: "0",
        paddingRight: "0"
    }),
    placeholder: styles => ({
        ...styles,
        color: '#495057',
        marginLeft: '16px',
        marginRight: '2px'
    }),
}

export const SELECT_MULTI_STYLES = {
    ...SELECT_DEFAULT_STYLES,
    multiValueRemove: styles => ({
        ...styles,
        paddingLeft: "0",
        paddingRight: "0"
    }),
}