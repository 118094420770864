import { connect } from 'react-redux';
import { getTranslation } from '../../../../../reducers/common/localization';
import VideoModal from './VideoModal';
import { onCloseVideoModal, getIsVideoModalOpen } from '../../../../../reducers/quickfind/jobsComparison';

const mapStateToProps = state => ({
	translation: getTranslation(state),
	isOpen: getIsVideoModalOpen(state)
});

const mapDispatchToProps = dispatch => ({
	onCancel: () => dispatch(onCloseVideoModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoModal);
