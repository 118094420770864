import React, { Component } from "react";

class Movie extends Component {
    render() {
        return (
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 26.5 35.6"
                enableBackground="new 0 0 26.5 35.6"
                xmlSpace="preserve"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#4B98C7"
                    d="M7.7,19.4c0-0.8-0.7-1.5-1.5-1.5c-0.9,0-1.5,0.7-1.5,1.5
	c0,0.9,0.7,1.5,1.5,1.5C7,20.9,7.7,20.2,7.7,19.4L7.7,19.4z M13.8,2.1c-1.5,0-2.8,1.2-2.8,2.7c0,1.5,1.2,2.8,2.8,2.8
	c1.5,0,2.8-1.2,2.8-2.8C16.5,3.3,15.3,2.1,13.8,2.1L13.8,2.1z M13.8,1c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8
	c2.1,0,3.8-1.7,3.8-3.8C17.6,2.7,15.9,1,13.8,1L13.8,1z M4.6,3.3c-1.3,0-2.3,1-2.3,2.3c0,1.3,1,2.3,2.3,2.3c1.3,0,2.4-1,2.4-2.3
	C6.9,4.3,5.9,3.3,4.6,3.3L4.6,3.3z M4.6,2.5c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1
	C7.7,3.9,6.3,2.5,4.6,2.5L4.6,2.5z M24.8,11l-4.9,3v6.1l4.9,3.1h1.2V11H24.8z M9.1,27.5l-4,7H3.4L8.3,26v-0.7h0.4h2.5h0.4V26
	l4.9,8.5h-1.8l-4-7v7H9.1V27.5z M3.6,10.1h14.7v14h-6.4h-1H9.2h-1H3.6c-1.2,0-2.2-1-2.2-2.2v-9.5C1.4,11.1,2.4,10.1,3.6,10.1
	L3.6,10.1z"
                />
            </svg>
        );
    }
}

export default Movie;
