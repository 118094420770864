import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import { onUpdateDepartmentsListCurrentPage } from "./departmentsList";

//- Actions
export const fetchCreateDepartmentSuccess = createAction("FETCH_CREATE_DEPARTMENT_SUCCESS");
export const fetchCreateDepartmentFailed = createAction("FETCH_CREATE_DEPARTMENT_FAILED");

export const onCreateSuccess = () => (dispatch) => {
    dispatch(fetchCreateDepartmentSuccess());
    dispatch(onUpdateDepartmentsListCurrentPage());
};

export const fetchCreateDepartment = createAction(
    "FETCH_CREATE_DEPARTMENT",
    (data) => Api.createDepartment(data),
    () => ({
        apiCall: true,
        onSuccess: onCreateSuccess,
        onFail: fetchCreateDepartmentFailed,
    })
);

export const onFetchCreateDepartment = (data) => (dispatch) => {
    dispatch(fetchCreateDepartment(data));
};

export const fetchEditDepartmentSuccess = createAction("FETCH_EDIT_DEPARTMENT_SUCCESS");
export const fetchEditDepartmentFailed = createAction("FETCH_EDIT_DEPARTMENT_FAILED");

export const onEditSuccess = (data) => (dispatch) => {
    dispatch(fetchEditDepartmentSuccess());
    dispatch(onUpdateDepartmentsListCurrentPage());
    if (data.refetchDepartment) {
        dispatch(fetchGetDepartmentData({ departmentId: data.departmentId }));
    }
};

export const fetchEditDepartment = createAction(
    "FETCH_EDIT_DEPARTMENT",
    (data) => Api.editDepartment(data),
    (data) => ({
        apiCall: true,
        onSuccess: () => onEditSuccess(data),
        onFail: fetchEditDepartmentFailed,
    })
);

export const onFetchEditDepartment = (data) => (dispatch) => {
    dispatch(fetchEditDepartment(data));
};

export const removeDepartmentSuccess = createAction("REMOVE_DEPARTMENT_SUCCESS");
export const removeDepartmentFailed = createAction("REMOVE_DEPARTMENT_FAILED");

export const onDeleteDepartmentSuccess = () => (dispatch) => {
    dispatch(removeDepartmentSuccess());
    dispatch(onUpdateDepartmentsListCurrentPage());
};

export const removeDepartment = createAction(
    "REMOVE_DEPARTMENT",
    (data) => Api.deleteDepartment(data),
    () => ({
        apiCall: true,
        onSuccess: onDeleteDepartmentSuccess,
        onFail: removeDepartmentFailed,
    })
);

export const fetchGetDepartmentDataFailed = createAction("FETCH_GET_DEPARTMENTS_DATA_FAILED");
export const fetchGetDepartmentDataSuccess = createAction("FETCH_GET_DEPARTMENTS_DATA_SUCCESS");

export const onGetDepartmentDataSuccess = (data) => (dispatch) => {
    dispatch(fetchGetDepartmentDataSuccess(data));
};

export const fetchGetDepartmentData = createAction(
    "FETCH_GET_DEPARTMENT_DATA",
    (data) => Api.getDepartmentData(data),
    () => ({
        apiCall: true,
        onSuccess: onGetDepartmentDataSuccess,
        onFail: fetchGetDepartmentDataFailed,
    })
);

export const addEmployeeToDepartmentFailed = createAction("ADD_EMPLOYEE_TO_DEPARTMENT_FAILED");
export const addEmployeeToDepartmentSuccess = createAction("ADD_EMPLOYEE_TO_DEPARTMENT_SUCCESS");

export const onAddEmployeeToDepartmentSuccess = (departmentId) => (dispatch) => {
    dispatch(addEmployeeToDepartmentSuccess());
    dispatch(fetchGetDepartmentData({ departmentId }));
};

export const addEmployeeToDepartment = createAction(
    "ADD_EMPLOYEE_TO_DEPARTMENT",
    (data) => Api.addEmployeeToDepartment(data),
    (data) => ({
        apiCall: true,
        onSuccess: () => onAddEmployeeToDepartmentSuccess(data.departmentId),
        onFail: addEmployeeToDepartmentFailed,
    })
);

export const removeEmployeeFromDepartmentFailed = createAction(
    "REMOVE_EMPLOYEE_FROM_DEPARTMENT_FAILED"
);
export const removeEmployeeFromDepartmentSuccess = createAction(
    "REMOVE_EMPLOYEE_FROM_DEPARTMENT_SUCCESS"
);

export const onRemoveEmployeeFromDepartmentSuccess = (departmentId) => (dispatch) => {
    dispatch(removeEmployeeFromDepartmentSuccess());
    dispatch(fetchGetDepartmentData({ departmentId }));
};

export const removeEmployeeFromDepartment = createAction(
    "REMOVE_EMPLOYEE_FROM_DEPARTMENT",
    (data) => Api.removeEmployeeFromDepartment(data),
    (data) => ({
        apiCall: true,
        onSuccess: () => onRemoveEmployeeFromDepartmentSuccess(data.departmentId),
        onFail: removeEmployeeFromDepartmentFailed,
    })
);

//- State
const initialState = {
    error: null,
    fetching: false,
    data: [],
    fetchingEditDepartment: false,
    fetchingCreateDepartment: false,
    fetchingDeleteDepartment: false,
    fetchingAddEmployeeToDepartment: false,
    fetchingRemoveEmployeeFromDepartment: false,
};

//- Reducers
export default handleActions(
    {
        FETCH_GET_DEPARTMENT_DATA: (state) => {
            return { ...state, data: null, error: null, fetching: true };
        },
        FETCH_GET_DEPARTMENTS_DATA_FAILED: (state, action) => {
            return { ...state, data: null, error: action.payload, fetching: false };
        },
        FETCH_GET_DEPARTMENTS_DATA_SUCCESS: (state, action) => {
            return { ...state, data: [action.payload], error: null, fetching: false };
        },
        FETCH_CREATE_DEPARTMENT: (state) => {
            return { ...state, error: null, fetchingCreateDepartment: true };
        },
        FETCH_CREATE_DEPARTMENT_SUCCESS: (state) => {
            return { ...state, error: null, fetchingCreateDepartment: false };
        },
        FETCH_CREATE_DEPARTMENT_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetchingCreateDepartment: false };
        },
        FETCH_EDIT_DEPARTMENT: (state) => {
            return { ...state, error: null, fetchingEditDepartment: true };
        },
        FETCH_EDIT_DEPARTMENT_SUCCESS: (state) => {
            return { ...state, error: null, fetchingEditDepartment: false };
        },
        FETCH_EDIT_DEPARTMENT_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetchingEditDepartment: false };
        },
        REMOVE_DEPARTMENT: (state) => {
            return { ...state, error: null, fetchingDeleteDepartment: true };
        },
        REMOVE_DEPARTMENT_SUCCESS: (state) => {
            return { ...state, error: null, fetchingDeleteDepartment: false };
        },
        REMOVE_DEPARTMENT_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetchingDeleteDepartment: false };
        },
        ADD_EMPLOYEE_TO_DEPARTMENT: (state) => {
            return { ...state, error: null, fetchingAddEmployeeToDepartment: true };
        },
        ADD_EMPLOYEE_TO_DEPARTMENT_SUCCESS: (state) => {
            return { ...state, error: null, fetchingAddEmployeeToDepartment: false };
        },
        ADD_EMPLOYEE_TO_DEPARTMENT_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetchingAddEmployeeToDepartment: false };
        },
        REMOVE_EMPLOYEE_FROM_DEPARTMENT: (state) => {
            return { ...state, error: null, fetchingRemoveEmployeeFromDepartment: true };
        },
        REMOVE_EMPLOYEE_FROM_DEPARTMENT_SUCCESS: (state) => {
            return { ...state, error: null, fetchingRemoveEmployeeFromDepartment: false };
        },
        REMOVE_EMPLOYEE_FROM_DEPARTMENT_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetchingRemoveEmployeeFromDepartment: false };
        },
        UPDATE_DEPARTMENT_MEDIA_FAIL: (state, action) => {
            return { ...state, error: action.payload };
        },
    },
    initialState
);

//- Selectors
export const getDepartmentsState = (state) => state.departments;

export const getDepartmentData = (state) => getDepartmentsState(state).data;

export const getDepartmentDataFetchingStatus = (state) => getDepartmentsState(state).fetching;
