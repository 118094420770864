import React from "react";
import { getBezierPath, getMarkerEnd, getEdgeCenter } from "react-flow-renderer";

export default function CustomEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    arrowHeadType,
    markerEndId,
}) {
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const edgeCenter = getEdgeCenter({ sourceX, sourceY, targetX, targetY });

    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const { helperLabel, isActiveElem } = data;

    return (
        <>
            <path
                id={id}
                style={style}
                className="home-page__edge-path"
                stroke={`url(#${isActiveElem ? "activeEdgeGradient" : "inactiveEdgeGradient"})`}
                d={edgePath}
                markerEnd={markerEnd}
            />
            <defs>
                <linearGradient id="activeEdgeGradient">
                    <stop stopColor="black" offset="20%" />
                    <stop stopColor="#75b444" offset="50%" />
                    <stop offset="75%" stopColor="black" />
                </linearGradient>
                <linearGradient id="inactiveEdgeGradient">
                    <stop stopColor="black" offset="20%" />
                    <stop stopColor="white" offset="50%" />
                    <stop offset="75%" stopColor="black" />
                </linearGradient>

                <filter x="0" y="0" width="1" height="1" id="activeHelperBg">
                    <feFlood floodColor="black" result="bg" />
                    <feMerge>
                        <feMergeNode in="bg" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
                <filter x="0" y="0" width="1" height="1" r="1" id="inactiveHelperBg">
                    <feFlood floodColor="lime" result="bg" />
                    <feMerge>
                        <feMergeNode in="bg" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>

            {helperLabel && (
                <>
                    {!isActiveElem && (
                        <circle
                            cx={edgeCenter[0]}
                            cy={edgeCenter[1]}
                            r="12"
                            className="home-page__edge-notifications-bg"
                        ></circle>
                    )}
                    <text
                        className={`home-page__edge-helper ${
                            isActiveElem
                                ? "home-page__edge-helper--active"
                                : "home-page__edge-helper--inactive"
                        }`}
                        dy={!isActiveElem ? ".3em" : "20"}
                        x={edgeCenter[0]}
                        y={edgeCenter[1]}
                    >
                        {helperLabel.split("/n").map((line, i) => (
                            <tspan
                                key={line}
                                dy={isActiveElem ? i && 20 : ".3em"}
                                x={edgeCenter[0]}
                            >
                                {line}
                            </tspan>
                        ))}
                    </text>
                </>
            )}
        </>
    );
}
