import { connect } from "react-redux";
import { getTranslation } from "../../../../reducers/common/localization";
import { getOrganizationsJobsState } from "../../../../reducers/organization/organizations";

import JobsLifecycleLine from "./JobsLifecycleLine";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        jobs: getOrganizationsJobsState(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsLifecycleLine);
