import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import { setRequestStatusSubmit, setRequestStatusFailed, setRequestStatusSuccess } from '../common/requestStatus';
import { REQUEST_SUCCESS_MESSAGE } from '../../constants/requestStatusConstants';

//- Actions
export const fetchRestoreAccountSuccess = () => (dispatch) => {
    dispatch(setRequestStatusSuccess(REQUEST_SUCCESS_MESSAGE.RESTORE_ACCOUNT));
    dispatch(setAccountToRestoreEmail(""));
}
export const onFetchRestoreAccount = createAction(
    'FETCH_RESTORE_ACCOUNT',
    (email) => Api.restoreAccount(email),
    () => ({
        apiCall: true,
        onSuccess: fetchRestoreAccountSuccess,
        onFail: setRequestStatusFailed,
        ignoreUnauthorized: true,
        ignoreForbidden: true
    })
);

export const fetchRestoreAccount = (email) => (dispatch) => {
    dispatch(setRequestStatusSubmit());
    dispatch(onFetchRestoreAccount(email));
};

export const setAccountToRestoreEmail = createAction("SET_ACCOUNT_TO_RESET_EMAIL");

//- State
const initialState = {
    email: ""
};

//- Reducers
export default handleActions({
    SET_ACCOUNT_TO_RESET_EMAIL: (state, action) => {
        return { ...state, email: action.payload };
    }
}, initialState);

//- Selectors
export const getRestoreAccountState = state => state.restoreAccount;

export const getAccountToRestoreEmail = state => getRestoreAccountState(state).email;
