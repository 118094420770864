import { connect } from "react-redux";
import RichMediaModal from "./RichMediaModal";
import {
    MODAL_RICH_MEDIA,
    getActiveModalType,
    hideModal,
} from "../../../../reducers/common/activeModal";
import { getTranslation } from "../../../../reducers/common/localization";
import { getActiveJobId } from "../../../../reducers/applicant/applicantJobs";

const mapStateToProps = (state) => {
    return {
        isOpen: getActiveModalType(state) === MODAL_RICH_MEDIA,
        translation: getTranslation(state),
        activeJobId: getActiveJobId(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseModal: () => dispatch(hideModal(MODAL_RICH_MEDIA)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RichMediaModal);
