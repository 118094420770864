import React, { Component } from "react";
import PropTypes from "prop-types";
import { ROUTES, USER_ROLE } from "../../../constants/constants";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import SkillsMap from "../skills/skillsMap/SkillsMap.container";
import { SKILLS_MAP_MODE } from "../skills/skillsMap/SkillsMap";
import Legend from "../skills/legend/Legend.container";
import { getStaticSkillsTree } from "../../../utils/getStaticSkillsTree";
import { Button } from "reactstrap";

class JobProfile extends Component {
    componentDidMount() {
        const jobHash = this.props.location.pathname.split("/")[2];

        if (jobHash) {
            this.props.onMount(jobHash);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isAuthorized !== this.props.isAuthorized) {
            this.props.history.push(ROUTES.LOGIN);
        }
    }

    copyToClipboard = async (elementId) => {
        const element = document.getElementById(elementId);
        const elementText = element.href;
        await navigator.clipboard.writeText(elementText);
    };

    render() {
        const { translation, jobData, isAuthorized, userRole, loading } = this.props;
        const skillsTree = getStaticSkillsTree(jobData);

        const subject = `${!_.isEmpty(jobData) ? jobData.profile.titles.en : null}${
            !_.isEmpty(jobData) && jobData.internalReference
                ? `- ${jobData.internalReference}`
                : null
        }`;

        if (loading) {
            return (
                <div className="loader-wrapper">
                    <h6 className="text-center">
                        {_.get(translation, "actions.fetching_message")}
                    </h6>
                </div>
            );
        }

        return (
            <div className="root-container">
                <h4 className="page-header">{_.get(translation, "job_profile.page_label")}</h4>
                <h5 className="page-header">
                    {!_.isEmpty(jobData)
                        ? `${jobData.organization.name} - ${jobData.profile.titles.en}`
                        : null}
                </h5>
                {skillsTree ? (
                    <div className="skills-map-container">
                        <SkillsMap skillsTree={skillsTree} mode={SKILLS_MAP_MODE.JOB_VIEW} />
                        <Legend />
                        {
                            // todo: not sure this is the right condition to display
                            isAuthorized && userRole === USER_ROLE.EMPLOYEE && (
                                <div className="info-wrapper">
                                    <h6>
                                        {_.get(translation, "job_profile.to_avoid_tooltip")}
                                        <i>{_.get(translation, "shared.again_and_again")}</i>
                                        {_.get(translation, "job_profile.to_avoid_tooltip_part2")}
                                    </h6>
                                    <div>
                                        <h6>
                                            {_.get(translation, "job_profile.use_the_below_link")}
                                            <a id="static_link" href={window.location.href}>
                                                <b>
                                                    <i>Link</i>
                                                </b>
                                            </a>
                                        </h6>
                                        <Button
                                            color="primary"
                                            onClick={() => this.copyToClipboard("static_link")}
                                        >
                                            {_.get(translation, "shared.copy_link")}
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                        {
                            <div className="generated-info-wrapper">
                                <h6 className="contact-me">
                                    {_.get(translation, "job_profile.to_apply_the_job")}
                                    {!_.isEmpty(jobData) ? (
                                        <i>
                                            <a
                                                href={`mailto:${
                                                    jobData.hireManager.email
                                                }?Subject=${encodeURIComponent(
                                                    `${_.get(
                                                        translation,
                                                        "job_profile.job_offer"
                                                    )} (${subject}) ${_.get(
                                                        translation,
                                                        "job_profile.attention"
                                                    )}`
                                                )}`}
                                            >
                                                {_.get(translation, "job_profile.hit_me")}
                                            </a>
                                        </i>
                                    ) : null}
                                </h6>
                                <h6>
                                    {"Generated by\u2009"}
                                    <b>{"HireForceOne\u2122 -"}</b>
                                    <i>
                                        <a href="http://www.HireForceOne.com" target="_blank">
                                            {`\u2009${_.get(
                                                translation,
                                                "job_profile.register_label"
                                            )}`}
                                        </a>
                                    </i>
                                </h6>
                            </div>
                        }
                    </div>
                ) : (
                    <h5 className="page-header">{_.get(translation, "shared.no_data_label")}</h5>
                )}
            </div>
        );
    }
}

JobProfile.propTypes = {
    translation: PropTypes.object.isRequired,
    jobData: PropTypes.object,
    isAuthorized: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    userRole: PropTypes.string,
    onMount: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default withRouter(JobProfile);
