import React from "react";
import _ from "lodash";
import { EMPLOYEES_COUNT_OPTIONS } from "../../../../constants/constants";
import { FormGroup, Col, Button, Form } from "reactstrap";
import { FormUtils } from "../../../../utils/formUtils";
import { withRouter } from "react-router";

class CompanyProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            internetDomain: "",
            vatNumber: "",
            companyCode: "",
            employeeCount: null,
            financialData: "",
            countryCode: null,
            district: "",
            city: "",
            postal: "",
            street: "",
        };
    }

    componentDidMount() {
        this.props.onMount();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                ...this.props.data,
                ...this.props.data.address,
            });
        }
    }

    render() {
        return <div className="company-registration">{this.renderContent()}</div>;
    }

    renderContent() {
        if (this.props.success) {
            return (
                <div className="success-registration-message">
                    <h6 className="text-center">
                        {_.get(
                            this.props.translation,
                            "company_registration_page.success_registration_message"
                        )}
                    </h6>
                    <h6 className="text-center">
                        {_.get(this.props.translation, "registration.success_registration_message")}
                    </h6>
                </div>
            );
        }

        return (
            <>
                <h4 className="text-center">
                    {_.get(this.props.translation, "company_registration_page.page_label")}
                </h4>
                <Form onSubmit={(e) => this.handleEditCompanyMetadata(e)}>
                    <h5 className="page-subsection-header">
                        {_.get(this.props.translation, "organization.organization_label")}
                    </h5>
                    {this.renderCompanyFormGroups()}
                    <FormGroup row className="d-flex justify-content-end">
                        <Col sm="auto">
                            <Button color="primary" type="submit">
                                {_.get(
                                    this.props.translation,
                                    "company_profile_page.submit_button"
                                )}
                            </Button>
                        </Col>
                    </FormGroup>
                </Form>
            </>
        );
    }

    renderCompanyFormGroups() {
        return (
            <>
                {this.renderOrganizationDataFormGroups()}
                <h6>{_.get(this.props.translation, "shared.address_label")}</h6>
                {this.renderOrganizationAddressFormGroup()}
            </>
        );
    }

    renderOrganizationDataFormGroups() {
        let formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField,
            onSelectChange: this.handleChangeSelectField,
            translation: this.props.translation,
        };

        let params = [
            {
                fieldName: "name",
                title: _.get(this.props.translation, "organization.org_name_label"),
                isRequired: true,
            },
            {
                fieldName: "internetDomain",
                title: _.get(this.props.translation, "organization.internet_domain_label"),
                isRequired: true,
                hintText: _.get(this.props.translation, "organization.internet_domain_hint"),
            },
            {
                fieldName: "vatNumber",
                title: _.get(this.props.translation, "organization.vat_number_label"),
            },
            {
                fieldName: "companyCode",
                title: _.get(this.props.translation, "organization.company_code_label"),
                hintText: _.get(this.props.translation, "organization.company_code_hint"),
            },
            {
                fieldName: "employeeCount",
                title: _.get(this.props.translation, "organization.employee_count_label"),
                type: "select",
                options: EMPLOYEES_COUNT_OPTIONS,
                placeholder: _.get(
                    this.props.translation,
                    "organization.employee_count_placeholder"
                ),
                isRequired: true,
            },
            {
                fieldName: "financialData",
                title: _.get(this.props.translation, "organization.financial_data_label"),
                type: "textarea",
                placeholder: _.get(
                    this.props.translation,
                    "organization.financial_data_placeholder"
                ),
                isRequired: true,
                hintText: _.get(this.props.translation, "organization.financial_data_hint"),
            },
        ];

        return _.map(params, (fieldParams) => FormUtils.renderFormGroup(fieldParams, formParams));
    }

    renderOrganizationAddressFormGroup() {
        let formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField,
        };

        let params = [
            {
                fieldName: "city",
                title: _.get(this.props.translation, "shared.city_label"),
                isRequired: true,
            },
            {
                fieldName: "district",
                title: _.get(this.props.translation, "shared.district_label"),
                isRequired: false,
            },
            {
                fieldName: "postal",
                title: _.get(this.props.translation, "shared.postal_label"),
                isRequired: true,
            },
            {
                fieldName: "street",
                title: _.get(this.props.translation, "shared.street_label"),
                isRequired: true,
            },
        ];

        return (
            <>
                {_.map(params, (fieldParams) => FormUtils.renderFormGroup(fieldParams, formParams))}
            </>
        );
    }

    handleEditCompanyMetadata = (e) => {
        e.preventDefault();
        const data = {
            ...this.props.data,
            ...this.state,
            address: {
                countryCode: this.state.countryCode,
                city: this.state.city,
                district: this.state.district,
                street: this.state.street,
                postal: this.state.postal,
            },
        };
        this.props.onEditCompanyData(data);
    };

    handleChangeField = (fieldName) => (e) => {
        this.setState({ [fieldName]: e.target.value });
    };

    handleChangeSelectField = (fieldName) => (selectedOption) => {
        this.setState({ [fieldName]: selectedOption.value });
    };

    handleChangeCountry = (countryCode) => {
        this.setState({ countryCode: countryCode });
    };

    handleChangeContinent = (region) => {
        this.setState({ region });
    };
}

export default withRouter(CompanyProfile);
