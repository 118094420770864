import { connect } from 'react-redux';
import OpenJobDetails from './OpenJobDetails';
import { getTranslation } from '../../../../reducers/common/localization';
import {
    fetchGetOpenJobData,
    getOpenJobData,
    getOpenJobFetchingStatus,
    getOpenJobSkillsDataTree
} from '../../../../reducers/job/openJob';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        data: getOpenJobData(state),
        skillsTree: getOpenJobSkillsDataTree(state),
        loading: getOpenJobFetchingStatus(state)
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onMount: (jobId) => {
            dispatch(fetchGetOpenJobData(jobId));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenJobDetails);
