import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import _ from 'lodash';
import './VideoModal.scss';

class VideoModal extends Component {
	render() {
		const { isOpen, onCancel, translation } = this.props;
		return (
			<Modal
				className="video-modal"
				isOpen={isOpen}
				toggle={onCancel}
				centered
			>
				<ModalBody>
					<video className="video-player" controls />
				</ModalBody>
				<ModalFooter>
					<Button
						color="secondary"
						onClick={onCancel}
					>
						{_.get(translation, 'actions.cancel_button')}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

VideoModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	translation: PropTypes.object.isRequired,
};

export default VideoModal;
