import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import _ from "lodash";
import "./InvitationsManagementLifecycle.scss";

const lifeCycleMilestones = [
    { title: "Reached Top-x" },
    { title: "Interviewed?" },
    { title: "Feedback sent?" },
    { title: "Hired?" },
];
class InvitationsManagementLifecycle extends Component {
    render() {
        const { translation } = this.props;

        const canBeClosed = !this.props.appliedApplicants.filter(
            (applicant) => applicant.applicantMetaData.hired && applicant.feedback.length
        ).length;

        return (
            <div className="lifecycle-line-container">
                <h5>
                    {_.replace(
                        _.get(translation, `invitations_management_page.workflow_label`),
                        "{x}",
                        2
                    )}
                </h5>
                <div>
                    <div className="lifecycle-line">
                        {lifeCycleMilestones.map(({ title, main }, index) => {
                            return (
                                <div key={title} className="lifecycle-line-data-wrapper">
                                    {index !== 0 && (
                                        <div className="arrow-container">
                                            <div className="milestone divider" />
                                            <div className="arrow right" />
                                        </div>
                                    )}
                                    <small className={`milestone card`}>{`${title}`}</small>
                                </div>
                            );
                        })}
                    </div>
                    <Button
                        color="primary"
                        disabled={canBeClosed}
                        onClick={() => this.props.onCloseJob(this.props.jobId)}
                    >
                        {_.get(translation, "invitations_management_page.close_job_label")}
                    </Button>
                </div>
            </div>
        );
    }
}

InvitationsManagementLifecycle.propTypes = {
    translation: PropTypes.object.isRequired,
};

export default InvitationsManagementLifecycle;
