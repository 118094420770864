import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import _ from 'lodash';

class RemoveEmployeeFromDepartmentModal extends Component {
	render() {
		const { isOpen, onClose, translation, selectedEmployee, removeEmployeeFromDepartment, department } = this.props;

		if (_.isEmpty(selectedEmployee)) {
			return null;
		}

		return (
			<Modal
				isOpen={isOpen}
				toggle={onClose}
			>
				<ModalHeader>
					{_.get(translation, 'departments.remove_employee_from_department_title')}
				</ModalHeader>
				<ModalBody>
					<h6>{_.get(translation, 'departments.remove_department_name_label').replace('{name}', `${selectedEmployee.firstName}`)}</h6>
					<h6>{_.get(translation, 'departments.remove_department_name_label').replace('{name}', `${selectedEmployee.lastName}`)}</h6>
					<h6>{_.get(translation, 'departments.remove_department_name_label').replace('{name}', `${selectedEmployee.email}`)}</h6>
					<h6>{_.get(translation, 'departments.remove_department_name_label').replace('{name}', `${selectedEmployee.roles.join(", ")}`)}</h6>
				</ModalBody>
				<ModalFooter className="content-space-between">
					<Button
						color="secondary"
						onClick={onClose}
					>
						{_.get(translation, 'actions.cancel_button')}
					</Button>
					<Button
						color="primary"
						onClick={() => {
							const data = {
								departmentId: department.id,
								staffId: selectedEmployee.id
							};

							removeEmployeeFromDepartment(data);
							onClose();
						}}
					>
						{_.get(translation, "actions.remove_button")}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

RemoveEmployeeFromDepartmentModal.propTypes = {
	translation: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	removeEmployeeFromDepartment: PropTypes.func.isRequired,
	selectedEmployee: PropTypes.object,
	department: PropTypes.object,
};

export default RemoveEmployeeFromDepartmentModal;
