import React, { Component } from "react";

class Youtube extends Component {
    render() {
        return (
            <svg viewBox="0 0 48 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>Youtube</title>
                <defs></defs>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g
                        id="Social-Icons---Isolated"
                        transform="translate(-164.000000, -510.000000)"
                        fill="#FF0002"
                    >
                        <path
                            d="M183.044,533.269916 L183.0425,519.687742 L196.0115,526.502244 L183.044,533.269916 Z M211.52,517.334163 C211.52,517.334163 211.0505,514.003199 209.612,512.536366 C207.7865,510.610299 205.7405,510.601235 204.803,510.489448 C198.086,510 188.0105,510 188.0105,510 L187.9895,510 C187.9895,510 177.914,510 171.197,510.489448 C170.258,510.601235 168.2135,510.610299 166.3865,512.536366 C164.948,514.003199 164.48,517.334163 164.48,517.334163 C164.48,517.334163 164,521.246723 164,525.157773 L164,528.82561 C164,532.73817 164.48,536.64922 164.48,536.64922 C164.48,536.64922 164.948,539.980184 166.3865,541.447016 C168.2135,543.373084 170.612,543.312658 171.68,543.513574 C175.52,543.885191 188,544 188,544 C188,544 198.086,543.984894 204.803,543.495446 C205.7405,543.382148 207.7865,543.373084 209.612,541.447016 C211.0505,539.980184 211.52,536.64922 211.52,536.64922 C211.52,536.64922 212,532.73817 212,528.82561 L212,525.157773 C212,521.246723 211.52,517.334163 211.52,517.334163 L211.52,517.334163 Z"
                            id="Youtube"
                        ></path>
                    </g>
                </g>
            </svg>
        );
    }
}

export default Youtube;
