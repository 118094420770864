import React, { Component } from "react";
import _ from "lodash";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default class DeleteAccountModal extends Component {
    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen}>
                    <ModalHeader>
                        {_.get(
                            this.props.translation,
                            "personal_settings_page.delete_profile_btn_label"
                        )}
                    </ModalHeader>
                    <ModalBody>
                        {_.get(
                            this.props.translation,
                            "personal_settings_page.delete_profile_warning"
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => this.props.onDeleteApplicantAccount()}
                        >
                            {_.get(
                                this.props.translation,
                                "personal_settings_page.delete_profile_btn_label"
                            )}
                        </Button>{" "}
                        <Button color="secondary" onClick={() => this.props.onClose()}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
