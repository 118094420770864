const LANGUAGE_ID = "hf1_lang";
const LOGIN_TOKEN_ID = "hf1_login_token";
const USER_ID = "hf1_user_id";
const LAST_USED_SUB_ROLE_ID = "hf1_last_used_sub_role";
const LAST_USED_GROUP_ID = "hf1_last_used_group";
const IS_FIRST_LOGIN = "hf1_is_first_login";
const IS_WELCOME_PAGE_READ = "hf1_is_welcome_page_read";

export class StorageManager {
    static setLanguage(language) {
        localStorage.setItem(LANGUAGE_ID, language);
    }

    static getLanguage() {
        return localStorage.getItem(LANGUAGE_ID);
    }

    static setLoginToken(token) {
        localStorage.setItem(LOGIN_TOKEN_ID, token);
    }

    static getLoginToken() {
        return localStorage.getItem(LOGIN_TOKEN_ID);
    }

    static removeLoginToken() {
        localStorage.removeItem(LOGIN_TOKEN_ID);
    }

    static setUserId(id) {
        localStorage.setItem(USER_ID, id);
    }

    static getUserId() {
        return localStorage.getItem(USER_ID);
    }

    static removeUserId() {
        localStorage.removeItem(USER_ID);
    }

    static setLastUsedSubRole(subRole) {
        localStorage.setItem(LAST_USED_SUB_ROLE_ID, subRole);
    }

    static getLastUsedSubRole() {
        return localStorage.getItem(LAST_USED_SUB_ROLE_ID);
    }

    static removeLastUsedSubRole() {
        localStorage.removeItem(LAST_USED_SUB_ROLE_ID);
    }

    static setLastUsedGroup(group) {
        localStorage.setItem(LAST_USED_GROUP_ID, group);
    }

    static getLastUsedGroup() {
        return localStorage.getItem(LAST_USED_GROUP_ID);
    }

    static removeLastUsedGroup() {
        localStorage.removeItem(LAST_USED_GROUP_ID);
    }

    static setIsFirstLogin(isFirstLogin) {
        localStorage.setItem(IS_FIRST_LOGIN, isFirstLogin);
    }

    static getIsFirstLogin() {
        return localStorage.getItem(IS_FIRST_LOGIN);
    }

    static removeIsFirstLogin() {
        localStorage.removeItem(IS_FIRST_LOGIN);
    }

    static setIsWelcomePageRead(isWelcomePageRead) {
        localStorage.setItem(IS_WELCOME_PAGE_READ, isWelcomePageRead);
    }

    static getIsWelcomePageRead() {
        return localStorage.getItem(IS_WELCOME_PAGE_READ);
    }

    static removeIsWelcomePageRead() {
        localStorage.removeItem(IS_WELCOME_PAGE_READ);
    }

    static removeUserData() {
        this.removeUserId();
        this.removeLastUsedSubRole();
        this.removeLastUsedGroup();
        this.removeIsFirstLogin();
        this.removeIsWelcomePageRead();
    }
}