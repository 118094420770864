import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';

//- Actions
export const fetchGetOpenJobsListFailed = createAction('FETCH_GET_OPEN_JOBS_LIST_FAILED');
export const fetchGetOpenJobsListSuccess = createAction('FETCH_GET_OPEN_JOBS_LIST_SUCCESS');
export const fetchGetOpenJobsList = createAction(
    'FETCH_GET_OPEN_JOBS_LIST',
    () => Api.getOpenJobsList(),
    () => ({
        apiCall: true,
        onSuccess: fetchGetOpenJobsListSuccess,
        onFail: fetchGetOpenJobsListFailed
    })
);

//- State
const initialState = {
    jobsList: null,
    error: null,
    fetching: false
};

//- Reducers
export default handleActions({

    FETCH_GET_OPEN_JOBS_LIST: (state) => {
        return { ...state, error: null, fetching: true };
    },
    FETCH_GET_OPEN_JOBS_LIST_FAILED: (state, action) => {
        return { ...state, jobsList: null, error: action.payload, fetching: false };
    },
    FETCH_GET_OPEN_JOBS_LIST_SUCCESS: (state, action) => {
        return { ...state, jobsList: action.payload.content, error: null, fetching: false };
    },

}, initialState);

//- Selectors
export const getOpenJobsState = state => state.openJobs;

export const getOpenJobsListData = state => getOpenJobsState(state).jobsList;

export const getOpenJobsListFetchingStatus = state => getOpenJobsState(state).fetching;
