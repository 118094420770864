import { connect } from 'react-redux';
import ApplicantProfile from './ApplicantProfile';
import { getTranslation } from '../../../reducers/common/localization';
import { getLoginAuthorizeStatus } from '../../../reducers/authorization/login';
import { getUserRole } from '../../../reducers/user/userRole';
import {
	fetchStaticApplicantViewData,
	getApplicantData,
	getFetchingApplicantDataLoading
} from '../../../reducers/staticViews/staticViews';

const mapStateToProps = state => {
	return {
		translation: getTranslation(state),
		isAuthorized: getLoginAuthorizeStatus(state),
		userRole: getUserRole(state),
		applicantData: getApplicantData(state),
		loading: getFetchingApplicantDataLoading(state)
	};
};

const mapDispatchToProps = dispatch => ({
	onMount: (applicantHash) => dispatch(fetchStaticApplicantViewData(applicantHash))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantProfile);
