import { connect } from "react-redux";
import AddRelationWithNodeModal from "./AddRelationWithNodeModal";
import { getTranslation } from "../../../../../../reducers/common/localization";
import {
    getActiveModalType,
    MODAL_ADD_RELATION_WITH_PROFILE,
    hideModal,
} from "../../../../../../reducers/common/activeModal";
import { NODE_TYPE } from "../../../../../../constants/systemNodesConstants";
import {
    getAddedRelatedProfilesData,
    onAddRelationsWithProfiles,
} from "../../../../../../reducers/admin/relatedProfiles";
import {
    getSystemNodesToAddData,
    onSetNodesTypeAndFetchSystemNodesToAddData,
    onResetSystemNodesToAddData,
    getSelectedSystemNodesToAdd,
    onSelectSystemNodeToAdd,
    onRemoveSelectedSystemNodeToAdd,
    getSystemNodesToAddFetchingStatus,
} from "../../../../../../reducers/admin/systemNodesToAdd";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_ADD_RELATION_WITH_PROFILE,
        nodesType: NODE_TYPE.PROFILE,
        nodesList: getSystemNodesToAddData(state),
        loading: getSystemNodesToAddFetchingStatus(state),
        addedNodes: getAddedRelatedProfilesData(state),
        selectedNodesToAdd: getSelectedSystemNodesToAdd(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(hideModal());
        },
        onSetNodesTypeAndFetchNodesData: (nodesType) => {
            dispatch(onSetNodesTypeAndFetchSystemNodesToAddData(nodesType));
        },
        onResetNodesData: () => {
            dispatch(onResetSystemNodesToAddData());
        },
        onSelectNodeToAdd: (nodeId) => {
            dispatch(onSelectSystemNodeToAdd(nodeId));
        },
        onRemoveSelectedNodeToAdd: (nodeId) => {
            dispatch(onRemoveSelectedSystemNodeToAdd(nodeId));
        },
        onSubmitSelectedNodesToAdd: (data) => {
            dispatch(onAddRelationsWithProfiles(data));
        },
    };
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddRelationWithNodeModal));
