import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import _ from 'lodash';
import { getApplicantSkillsData } from '../applicant/applicantSkills';
import { getJobSkillsData } from '../job/jobSkills';

//- Actions
export const fetchGetSuggestedProfilesFailed = createAction('FETCH_GET_SUGGESTED_PROFILES_FAILED');
export const fetchGetSuggestedProfilesSuccess = createAction('FETCH_GET_SUGGESTED_PROFILES_SUCCESS');
export const onFetchGetSuggestedProfilesSuccess = (data) => (dispatch) => {
    let formattedProfiles = _.map(data.content, (profile) => {
        return {
            id: profile.id,
            title: profile.titles.en,
            description: profile.description
        }
    });
    dispatch(fetchGetSuggestedProfilesSuccess(formattedProfiles));
}
// TODO: (BE) don't suggest profiles that user already have on the backend
export const fetchGetSuggestedProfiles = createAction(
    'FETCH_GET_SUGGESTED_PROFILES',
    (skills) => Api.getProfilesBySkills(_.map(skills, (skill) => skill.id)),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSuggestedProfilesSuccess,
        onFail: fetchGetSuggestedProfilesFailed
    })
);
export const onFetchGetSuggestedProfilesForApplicant = () => (dispatch, getState) => {
    let skills = getApplicantSkillsData(getState());
    dispatch(fetchGetSuggestedProfiles(skills))
}
export const onFetchGetSuggestedProfilesForJob = () => (dispatch, getState) => {
    let skills = getJobSkillsData(getState());
    dispatch(fetchGetSuggestedProfiles(skills))
}

export const cleanSuggestedProfiles = createAction('CLEAN_SUGGESTED_PROFILES');

//- State
const initialState = {
    data: null,
    error: null,
    fetching: false
};

//- Reducers
export default handleActions({

    FETCH_GET_SUGGESTED_PROFILES: (state) => {
        return { ...state, data: null, error: null, fetching: true };
    },
    FETCH_GET_SUGGESTED_PROFILES_FAILED: (state, action) => {
        return { ...state, data: null, error: action.payload, fetching: false };
    },
    FETCH_GET_SUGGESTED_PROFILES_SUCCESS: (state, action) => {
        return { ...state, data: action.payload, error: null, fetching: false };
    },
    CLEAN_SUGGESTED_PROFILES: (state) => {
        return { ...state, data: null };
    },
}, initialState);

//- Selectors
export const getSuggestedProfilesState = state => state.suggestedProfiles;

export const getSuggestedProfilesData = state => getSuggestedProfilesState(state).data;

export const getSuggestedProfilesFetchingStatus = state => getSuggestedProfilesState(state).fetching;
