import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import _ from 'lodash';

class DescriptionModal extends Component {
	render() {
		const { isOpen, onCancel, translation } = this.props;

		return (
			<Modal
				isOpen={isOpen}
				toggle={onCancel}
				centered
			>
				<ModalBody>
					<div>
						Description
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						color="secondary"
						onClick={onCancel}
					>
						{_.get(translation, 'actions.cancel_button')}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

DescriptionModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	translation: PropTypes.object.isRequired,
};

export default DescriptionModal;
