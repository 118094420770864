import React, { Component } from "react";

class RichMediaIcon extends Component {
    render() {
        return (
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 50 50"
                xmlSpace="preserve"
            >
                <g>
                    <linearGradient
                        id="SVGID_1_"
                        gradientUnits="userSpaceOnUse"
                        x1="3.6517"
                        y1="23.3153"
                        x2="43.1369"
                        y2="23.3153"
                    >
                        <stop offset="0" stopColor="#9D15ED" />
                        <stop offset="1" stopColor="#061C77" />
                    </linearGradient>
                    <path
                        className="st0"
                        d="M9.2,39.4c-3.5-2.6-5.9-6.8-5.5-11.2c0.5-5.7,5.1-7.9,9.7-10c2.5-1.1,4.8-3,6.2-5.3c2-3.1,3.7-6.4,7.3-7.7
		c3.4-1.2,7.4-0.8,10.1,1.8c1.9,1.8,2.5,4.3,2.9,6.8c0.2,1.6,0.1,3.3,0.5,5c0.4,1.8,1.5,3,2.3,4.5c1.3,2.4-0.4,3.8-2.2,5.1
		c-2.1,1.6-2.2,3.9-3.1,6.2c-0.9,2.3-2.8,4.3-5.1,5.2c-3.1,1.2-6.6-0.2-9.8,0.6c-1.9,0.5-3.8,1.5-5.9,1.5C13.9,42,11.4,41,9.2,39.4z
		"
                    />
                    <linearGradient
                        id="SVGID_2_"
                        gradientUnits="userSpaceOnUse"
                        x1="12.4322"
                        y1="13.1389"
                        x2="16.3972"
                        y2="9.8119"
                    >
                        <stop offset="4.522613e-02" stopColor="#9D15ED" />
                        <stop offset="1" stopColor="#061C77" />
                    </linearGradient>
                    <circle className="st1" cx="14.4" cy="11.5" r="2.6" />
                    <linearGradient
                        id="SVGID_3_"
                        gradientUnits="userSpaceOnUse"
                        x1="40.3827"
                        y1="34.0827"
                        x2="43.5248"
                        y2="31.4462"
                    >
                        <stop offset="4.522613e-02" stopColor="#9D15ED" />
                        <stop offset="1" stopColor="#061C77" />
                    </linearGradient>
                    <circle className="st2" cx="42" cy="32.8" r="2.1" />
                    <linearGradient
                        id="SVGID_4_"
                        gradientUnits="userSpaceOnUse"
                        x1="24.9428"
                        y1="44.9435"
                        x2="26.7382"
                        y2="43.4369"
                    >
                        <stop offset="4.522613e-02" stopColor="#9D15ED" />
                        <stop offset="1" stopColor="#061C77" />
                    </linearGradient>
                    <circle className="st3" cx="25.8" cy="44.2" r="1.2" />
                    <linearGradient
                        id="SVGID_5_"
                        gradientUnits="userSpaceOnUse"
                        x1="43.1068"
                        y1="38.5958"
                        x2="44.9023"
                        y2="37.0893"
                    >
                        <stop offset="4.522613e-02" stopColor="#9D15ED" />
                        <stop offset="1" stopColor="#061C77" />
                    </linearGradient>
                    <circle className="st4" cx="44" cy="37.8" r="1.2" />
                    <linearGradient
                        id="SVGID_6_"
                        gradientUnits="userSpaceOnUse"
                        x1="44.2787"
                        y1="15.9884"
                        x2="46.0742"
                        y2="14.4819"
                    >
                        <stop offset="4.522613e-02" stopColor="#9D15ED" />
                        <stop offset="1" stopColor="#061C77" />
                    </linearGradient>
                    <circle className="st5" cx="45.2" cy="15.2" r="1.2" />
                    <linearGradient
                        id="SVGID_7_"
                        gradientUnits="userSpaceOnUse"
                        x1="7.6576"
                        y1="10.8126"
                        x2="9.4531"
                        y2="9.3061"
                    >
                        <stop offset="4.522613e-02" stopColor="#9D15ED" />
                        <stop offset="1" stopColor="#061C77" />
                    </linearGradient>
                    <circle className="st6" cx="8.6" cy="10.1" r="1.2" />
                </g>
                <g>
                    <path
                        className="st84"
                        d="M22.8,22.8c-0.4-0.2-0.9,0-0.9,0.5v5.6c0,0.5,0.5,0.7,0.9,0.5l4.7-2.8c0.4-0.2,0.4-0.8,0-1L22.8,22.8z
		 M23,27.9v-3.5l2.9,1.7L23,27.9z"
                    />
                    <path
                        className="st84"
                        d="M30.7,19.2c-3.7-0.6-8.9-0.6-12.6,0c-1.5,0.2-2.7,1.4-2.9,2.9c-0.5,2.8-0.5,5.3,0,8.1
		c0.3,1.5,1.4,2.7,2.9,2.9c1.9,0.3,4.1,0.4,6.3,0.4c2.2,0,4.4-0.1,6.3-0.4c1.5-0.2,2.7-1.4,2.9-2.9c0.5-2.8,0.5-5.3,0-8.1
		C33.4,20.6,32.2,19.4,30.7,19.2L30.7,19.2z M32.4,30c-0.2,1-0.9,1.8-1.9,1.9c-3.6,0.5-8.6,0.5-12.3,0c-1-0.1-1.7-0.9-1.9-1.9
		c-0.5-2.7-0.5-5,0-7.7c0.2-1,0.9-1.8,1.9-1.9c1.8-0.3,4-0.4,6.1-0.4c2.2,0,4.3,0.1,6.1,0.4c1,0.1,1.7,0.9,1.9,1.9
		C32.9,25,32.9,27.3,32.4,30L32.4,30z"
                    />
                </g>
            </svg>
        );
    }
}

export default RichMediaIcon;
