import { createAction, handleActions } from 'redux-actions';
import { SORTING_MODE } from '../../constants/candidatesComparisonConstants';
import { Api } from '../../api/api';

//- Actions
export const setSortingMode = createAction('SET_SORTING_MODE');
export const setCombinedSortingRatio = createAction('SET_COMBINED_SORTING_RATIO');
export const fetchApplicantsByIdsSuccess = createAction('FETCH_APPLICANTS_BY_IDS_SUCCESS');
export const fetchApplicantsByIdsFailed = createAction('FETCH_APPLICANTS_BY_IDS_FAILED');

export const fetchApplicantsByIds = createAction(
	'FETCH_APPLICANTS_BY_IDS',
	(applicantsIds) => Api.getApplicantsByIds(applicantsIds),
	() => ({
		apiCall: true,
		onSuccess: fetchApplicantsByIdsSuccess,
		onFail: fetchApplicantsByIdsFailed
	})
);

export const onFetchApplicantsByIds = (applicantsIds) => (dispatch) => {
	dispatch(fetchApplicantsByIds(applicantsIds));
};

//- State
const initialState = {
	sortingMode: SORTING_MODE.DATE,
	combinedSortingRatio: '0',
	applicantsData: [],
	error: null,
	fetchingApplicantsByIds: false,
};

//- Reducer
export default handleActions({
	SET_SORTING_MODE: (state, action) => {
		return { ...state, sortingMode: action.payload };
	},
	SET_COMBINED_SORTING_RATIO: (state, action) => {
		return { ...state, combinedSortingRatio: action.payload };
	},
	FETCH_APPLICANTS_BY_IDS: (state) => {
		return { ...state, applicantsData: [], error: null, fetchingApplicantsByIds: true };
	},
	FETCH_APPLICANTS_BY_IDS_SUCCESS: (state, action) => {
		return { ...state, applicantsData: action.payload.content, error: null, fetchingApplicantsByIds: false };
	},
	FETCH_APPLICANTS_BY_IDS_FAILED: (state, action) => {
		return { ...state, applicantsData: [], error: action.payload, fetchingApplicantsByIds: false };
	},
}, initialState);

//- Selectors
export const getApplicantsComparisonState = state => state.applicantsComparison;

export const getApplicantsComparisonSortingMode = state => getApplicantsComparisonState(state).sortingMode;

export const getApplicantsComparisonSortingRatio = state => getApplicantsComparisonState(state).combinedSortingRatio;

export const getApplicantsData = state => getApplicantsComparisonState(state).applicantsData;

export const getFetchingApplicantsByIds = state => getApplicantsComparisonState(state).fetchingApplicantsByIds;
