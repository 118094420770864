import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SkillComparisonPopover.scss';
import _ from "lodash";
import {
    UncontrolledPopover,
    PopoverBody
} from 'reactstrap';
import { LEVEL_CLASS_NAME, LEVEL_TRANSLATION_LABEL } from '../../../../constants/skillsConstants';

class SkillComparisonPopover extends Component {
    render() {
        if (!this.props.data) {
            return null;
        }

        return (
            <UncontrolledPopover
                className="skill-comparison-popover"
                target={this.props.target}
                placement={this.props.placement || "top"}
                trigger={this.props.trigger || "hover"}
                boundariesElement="window"
            >
                <PopoverBody>
                    <div className="skill-title">{this.props.data.title}</div>
                    {
                        !this.props.data.isSecondary ?
                            <div>
                                {_.get(this.props.translation, `skill_properties.job_required_level_label`)}
                                {" "}
                                <span
                                    className={`required-level ${LEVEL_CLASS_NAME[this.props.data.requiredData.level]}`}
                                >
                                    {_.get(this.props.translation, `skill_properties.levels_values.${LEVEL_TRANSLATION_LABEL[this.props.data.requiredData.level]}`)}
                                </span>
                            </div>
                            : null
                    }
                    <div>
                        {_.get(this.props.translation, `skill_properties.applicant_actual_level_label`)}
                        {" "}
                        <span
                            className={`actual-level ${this.props.data.actualData ? LEVEL_CLASS_NAME[this.props.data.actualData.level] : "missing-skill"}`}
                        >
                            {
                                this.props.data.actualData ?
                                    _.get(this.props.translation, `skill_properties.levels_values.${LEVEL_TRANSLATION_LABEL[this.props.data.actualData.level]}`)
                                    : "---"
                            }
                        </span>

                    </div>
                </PopoverBody>
            </UncontrolledPopover>
        );
    }
}

SkillComparisonPopover.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.object,
    target: PropTypes.string.isRequired,
    placement: PropTypes.string,
    trigger: PropTypes.string
};

export default SkillComparisonPopover;
