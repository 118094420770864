import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './HardSkillsComparison.scss';
import { SkillsUtils } from '../../../utils/skillsUtils';
import { SKILLS_MAP_MODE } from '../skills/skillsMap/SkillsMap';
import SkillsMap from '../skills/skillsMap/SkillsMap.container';

class HardSkillsComparison extends Component {
  render() {
    const { data, skills } = this.props;

    const skillFromTagInput = _.map(skills, (skill) => ({
      id: skill.id,
      title: skill.name,
      categoryId: skill.category.id,
      categoryTitle: skill.category.titles.en,
      level: skill.level,
    }));

    const applicantOrJobSkills = _.map(data.skills, (skill) => ({
      id: skill.skill.id,
      title: skill.skill.titles.en,
      categoryId: skill.skill.category.id,
      categoryTitle: skill.skill.category.titles.en,
      level: skill.level,
    }));

    const comparedSkills = SkillsUtils.createComparisonSkillsTree(skillFromTagInput, applicantOrJobSkills);

    if (_.isEmpty(data)) {
      return null;
    }

    return (
      <div className="hard-skills-compare">
        <div className="skills-map-container">
          <SkillsMap
            skillsTree={comparedSkills}
            mode={SKILLS_MAP_MODE.COMPARISON}
            imageSource=""
            userId={data.id}
            showSecondarySkills={false}
          />
        </div>
      </div>
    );
  }
}

HardSkillsComparison.propTypes = {
  data: PropTypes.object.isRequired,
  skills: PropTypes.array.isRequired,
};

export default HardSkillsComparison;

