import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import _ from "lodash";
import { getApplicantSelectedProfilesData } from "../applicant/applicantProfiles";
import { getJobSelectedProfilesData } from "../job/jobProfiles";

//- Actions
export const fetchGetSuggestedClustersFailed = createAction("FETCH_GET_SUGGESTED_CLUSTERS_FAILED");
export const fetchGetSuggestedClustersSuccess = createAction(
    "FETCH_GET_SUGGESTED_CLUSTERS_SUCCESS"
);
export const onFetchGetSuggestedClustersSuccess = (data) => (dispatch) => {
    let formattedClusters = _.map(data.content, (cluster) => {
        return {
            categoryId: cluster.id,
            categoryTitle: cluster.titles.en,
            skills: cluster.userSkills.length && cluster.userSkills,
        };
    });
    dispatch(fetchGetSuggestedClustersSuccess(formattedClusters));
};
export const onFetchGetSuggestedClusters = createAction(
    "FETCH_GET_SUGGESTED_CLUSTERS",
    // TODO: (BE) exclude clusters that the user has already added
    (profileIds) => Api.getClustersByProfiles(profileIds),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSuggestedClustersSuccess,
        onFail: fetchGetSuggestedClustersFailed,
    })
);
export const fetchGetSuggestedClusters = (profileIds) => (dispatch, getState) => {
    if (profileIds && profileIds.length > 0) {
        dispatch(onFetchGetSuggestedClusters(profileIds));
    } else {
        dispatch(cleanSuggestedClusters());
    }
};

export const onFetchGetSuggestedClustersForApplicant = () => (dispatch, getState) => {
    let profiles = getApplicantSelectedProfilesData(getState());
    dispatch(fetchGetSuggestedClusters(profiles));
};
export const onFetchGetSuggestedClustersForJob = () => (dispatch, getState) => {
    let profiles = getJobSelectedProfilesData(getState());
    dispatch(fetchGetSuggestedClusters(profiles));
};

export const cleanSuggestedClusters = createAction("CLEAN_SUGGESTED_CLUSTERS");

//- State
const initialState = {
    data: null,
    error: null,
    fetching: false,
};

//- Reducers
export default handleActions(
    {
        FETCH_GET_SUGGESTED_CLUSTERS: (state) => {
            return { ...state, /*data: null,*/ error: null, fetching: true };
        },
        FETCH_GET_SUGGESTED_CLUSTERS_FAILED: (state, action) => {
            return { ...state, data: null, error: action.payload, fetching: false };
        },
        FETCH_GET_SUGGESTED_CLUSTERS_SUCCESS: (state, action) => {
            return { ...state, data: action.payload, error: null, fetching: false };
        },
        CLEAN_SUGGESTED_CLUSTERS: (state) => {
            return { ...state, data: null };
        },
    },
    initialState
);

//- Selectors
export const getSuggestedClustersState = (state) => state.suggestedClusters;

export const getSuggestedClustersData = (state) => getSuggestedClustersState(state).data;

export const getSuggestedClustersFetchingStatus = (state) =>
    getSuggestedClustersState(state).fetching;
