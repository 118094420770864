import { connect } from 'react-redux';
import RemoveSkillModal from "./RemoveSkillModal";
import { getTranslation } from '../../../../reducers/common/localization';
import { getActiveModalType, MODAL_REMOVE_SKILL, hideModal } from '../../../../reducers/common/activeModal';

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_REMOVE_SKILL
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(hideModal());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveSkillModal);
