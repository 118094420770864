import { connect } from 'react-redux';
import StaffList from './StaffList';
import { getTranslation } from '../../../../reducers/common/localization';
import {
    getInitialStaffList,
    getStaffListData,
    getStaffListFetchingStatus
} from '../../../../reducers/organization/staffList';
import {
    MODAL_ADD_STAFF_MEMBER,
    MODAL_CHANGE_ANCHOR_STAFF_MEMBER,
    MODAL_REMOVE_STAFF_MEMBER,
    MODAL_RESEND_STAFF_MEMBER_ACTIVATION,
    showModal
} from '../../../../reducers/common/activeModal';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        data: getStaffListData(state),
        loading: getStaffListFetchingStatus(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getInitialStaffList: () => dispatch(getInitialStaffList()),
        onAddStaffMember: () => dispatch(showModal(MODAL_ADD_STAFF_MEMBER)),
        onRemoveStaffMember: () => dispatch(showModal(MODAL_REMOVE_STAFF_MEMBER)),
        onResendStaffMemberActivation: () => dispatch(showModal(MODAL_RESEND_STAFF_MEMBER_ACTIVATION)),
        onChangeAnchor: () => dispatch(showModal(MODAL_CHANGE_ANCHOR_STAFF_MEMBER))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffList);
