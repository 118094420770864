import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import _ from "lodash";
import RichMedia from "../richMedia/RichMedia.container";
import Icon from "../../../common/icon/Icon";

class RichMediaModal extends Component {
    render() {
        const { onCloseModal, isOpen, translation } = this.props;

        return (
            <Modal centered isOpen={isOpen} toggle={onCloseModal} className="rich-media">
                <ModalHeader className="w-100">
                    <div className="d-flex flex-row justify-content-between">
                        <h5 className="text-center">
                            {_.get(translation, "shared.rich_media_label")}
                        </h5>
                        <Icon type="cancel" onClick={onCloseModal} />
                    </div>
                </ModalHeader>
                <ModalBody>
                    <RichMedia />
                </ModalBody>
            </Modal>
        );
    }
}

export default RichMediaModal;
