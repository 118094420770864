import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './BrainChainCheckMark.scss';
import _ from "lodash";
import {
    UncontrolledPopover,
    PopoverBody,
    Button
} from 'reactstrap';
import Icon from '../icon/Icon';

const BRAINCHAIN_CHECK_TYPE = {
    NO_INFO: "NO_INFO",
    OK: "OK",
    NOT_OK: "NOT_OK"
}

const BRAINCHAIN_CHECK_CLASS_NAME = {
    [BRAINCHAIN_CHECK_TYPE.NO_INFO]: "no-info",
    [BRAINCHAIN_CHECK_TYPE.OK]: "ok",
    [BRAINCHAIN_CHECK_TYPE.NOT_OK]: "not-ok"
}

const BRAINCHAIN_CHECK_LABEL = {
    [BRAINCHAIN_CHECK_TYPE.NO_INFO]: "applicant_not_in_brainchain_label",
    [BRAINCHAIN_CHECK_TYPE.OK]: "brainchain_checked_label",
    [BRAINCHAIN_CHECK_TYPE.NOT_OK]: "not_ok_in_brainchain_label"
}

class BrainChainCheckMark extends Component {
    constructor(props) {
        super(props);
        this.componentRef = React.createRef()
    }

    render() {
        if (!this.props.userId) {
            return null;
        }

        let checkType = _.isNil(this.props.qualityCheck) ? BRAINCHAIN_CHECK_TYPE.NO_INFO : this.props.qualityCheck ? BRAINCHAIN_CHECK_TYPE.OK : BRAINCHAIN_CHECK_TYPE.NOT_OK;
        let labelArray = _.get(this.props.translation, 'candidate.' + BRAINCHAIN_CHECK_LABEL[checkType]).split('{BrainChain}');

        return (
            <>
                <div className="brainchain-check-mark" ref={this.componentRef}>
                    <Icon
                        className={`brainchain-icon ${BRAINCHAIN_CHECK_CLASS_NAME[checkType]}`}
                        type="brainchain"
                        size={this.props.size || "lg"}
                    />
                </div>
                <UncontrolledPopover
                    className="brainchain-check-mark-popover"
                    target={this.componentRef}
                    placement={"top"}
                    trigger={"hover"}
                    boundariesElement="window"
                >
                    <PopoverBody>
                        <div>
                            <span>{labelArray[0]}</span>
                            <a href="https://www.HireForceOne.com" rel="noopener noreferrer" target="_blank">HireForceOne</a>
                            <span>{labelArray[1]}</span>
                        </div>
                        {
                            checkType === BRAINCHAIN_CHECK_TYPE.NO_INFO ?
                                <Button className="send-invitation-button" color="secondary" size="sm">
                                    {_.get(this.props.translation, 'candidate.send_invitation_to_applicant_label')}
                                </Button>
                                : null
                        }
                    </PopoverBody>
                </UncontrolledPopover>
            </>
        );
    }
}

BrainChainCheckMark.propTypes = {
    translation: PropTypes.object.isRequired,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    qualityCheck: PropTypes.bool,
    size: PropTypes.string,
    onSendInvitation: PropTypes.func.isRequired
};

export default BrainChainCheckMark;
