import { connect } from 'react-redux';
import BrainChainCheckMark from "./BrainChainCheckMark";
import { getTranslation } from "../../../reducers/common/localization";
import { onSendInvitationToBrainChain } from "../../../reducers/brainChain/brainChainData";

const mapStateToProps = state => {
    return {
        translation: getTranslation(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSendInvitation: (userId) => dispatch(onSendInvitationToBrainChain(userId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrainChainCheckMark);
