
import React, { Component } from 'react';

class BrainChain extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 48 48">
                <g className="icon-background" fill="none">
                    <path d="M37.44,17.68a6.35,6.35,0,1,0-.06,12.69,6.35,6.35,0,1,0,.06-12.69Z" />
                    <path d="M10.67,17.68a6.35,6.35,0,1,0,.06,12.69A6.19,6.19,0,0,0,17,24,6.25,6.25,0,0,0,10.67,17.68Z" />
                </g>
                <path fill="#040707" d="M36.74,33.64a10.42,10.42,0,0,1-4.09-1.22,19.19,19.19,0,0,0-9.54-2.29,20.46,20.46,0,0,0-7.87,2.35A10,10,0,0,1,7.12,33a9.63,9.63,0,1,1,7.42-17.78,24.58,24.58,0,0,0,7.58,2.31,20,20,0,0,0,10.53-1.93c3-1.38,6-1.71,9.06-.19a9.06,9.06,0,0,1,5.27,8A9.64,9.64,0,0,1,36.74,33.64Zm.7-16a6.35,6.35,0,1,0-.06,12.69,6.35,6.35,0,1,0,.06-12.69ZM10.73,30.37A6.19,6.19,0,0,0,17,24a6.25,6.25,0,0,0-6.34-6.33,6.35,6.35,0,1,0,.06,12.69Z" />
            </svg>
        );
    }
}

export default BrainChain;
