import { createAction, handleActions } from "redux-actions";
import _ from "lodash";
import { onFetchGetCurrentClusterSkillsByProfilesForJob } from "../skillsAndClusters/currentClusterSkills";
import { fetchGetSuggestedClusters } from "../skillsAndClusters/suggestedClusters";

//- Actions
export const updateSelectedProfiles = createAction("UPDATE_SELECTED_PROFILES");
export const onChangeSelectedProfile = (profileId) => (dispatch, getState) => {
    let selectedProfiles = _.cloneDeep(getJobSelectedProfilesData(getState())) || [];

    if (selectedProfiles.includes(profileId)) {
        selectedProfiles = selectedProfiles.filter((elem) => elem !== profileId);
    } else {
        selectedProfiles.push(profileId);
    }

    dispatch(updateSelectedProfiles(selectedProfiles));
    dispatch(onFetchGetCurrentClusterSkillsByProfilesForJob(selectedProfiles));
    dispatch(fetchGetSuggestedClusters(selectedProfiles));
};

export const updateProfiles = createAction("UPDATE_PROFILES");
export const onRemoveUserProfile = (profileId) => (dispatch, getState) => {
    let userProfiles = _.cloneDeep(getJobProfilesData(getState())) || [];

    userProfiles = userProfiles.filter((elem) => elem.id !== profileId);
    dispatch(updateProfiles(userProfiles));

    let selectedProfiles = _.cloneDeep(getJobSelectedProfilesData(getState()));
    if (selectedProfiles && selectedProfiles.length !== 0) {
        selectedProfiles = selectedProfiles.filter((elem) => elem !== profileId);

        dispatch(updateSelectedProfiles(selectedProfiles));
        dispatch(onFetchGetCurrentClusterSkillsByProfilesForJob(selectedProfiles));
        dispatch(fetchGetSuggestedClusters(selectedProfiles));
    }
};

export const onAddUserProfile = (profile) => (dispatch, getState) => {
    let userProfiles = _.cloneDeep(getJobProfilesData(getState())) || [];
    userProfiles.push(profile);
    dispatch(updateProfiles(userProfiles));
    // dispatch(updateProfiles([profile]));
};

export const initProfiles = createAction("INIT_PROFILES");
export const initEmptyProfiles = () => (dispatch) => {
    dispatch(initProfiles([]));
};

//- State
const initialState = {
    profilesData: null,
    selectedProfiles: null,
};

//- Reducers
export default handleActions(
    {
        UPDATE_SELECTED_PROFILES: (state, action) => {
            return { ...state, selectedProfiles: action.payload };
        },
        UPDATE_PROFILES: (state, action) => {
            return { ...state, profilesData: action.payload };
        },
        INIT_PROFILES: (state, action) => {
            return { ...state, profilesData: action.payload };
        },
    },
    initialState
);

//- Selectors
export const getJobProfilesState = (state) => state.jobProfiles;

export const getJobProfilesData = (state) => getJobProfilesState(state).profilesData;

export const getJobSelectedProfilesData = (state) => getJobProfilesState(state).selectedProfiles;
