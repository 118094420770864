import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import _ from "lodash";
import { onFetchGetCurrentClusterSkillsByProfilesForApplicant } from "../skillsAndClusters/currentClusterSkills";
import { fetchGetSuggestedClusters } from "../skillsAndClusters/suggestedClusters";
import { onSaveApplicantSkills } from "../applicant/applicantSkills";

//- Actions
export const fetchGetApplicantProfilesFailed = createAction("FETCH_GET_APPLICANT_PROFILES_FAILED");
export const fetchGetApplicantProfilesSuccess = createAction(
    "FETCH_GET_APPLICANT_PROFILES_SUCCESS"
);
export const onFetchGetApplicantProfilesSuccess = (profiles) => (dispatch) => {
    let formattedProfiles = _.map(profiles, (profile) => {
        return {
            id: profile.id,
            title: profile.alternativeTitle,
            description: profile.description,
            alternativeTitles: profile.alternativeTitles,
        };
    });
    dispatch(fetchGetApplicantProfilesSuccess(formattedProfiles));
};
export const fetchGetApplicantProfiles = createAction(
    "FETCH_GET_APPLICANT_PROFILES",
    () => Api.getApplicantProfiles(),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetApplicantProfilesSuccess,
        onFail: fetchGetApplicantProfilesFailed,
    })
);

export const updateSelectedProfiles = createAction("UPDATE_SELECTED_PROFILES");
export const onChangeSelectedProfile = (profileId, onMount) => (dispatch, getState) => {
    let selectedProfiles = _.cloneDeep(getApplicantSelectedProfilesData(getState())) || [];

    if (selectedProfiles.includes(profileId) && !onMount) {
        selectedProfiles = selectedProfiles.filter((elem) => elem !== profileId);
    } else {
        selectedProfiles.push(profileId);
    }

    dispatch(updateSelectedProfiles(selectedProfiles));
    dispatch(onFetchGetCurrentClusterSkillsByProfilesForApplicant(selectedProfiles));
    dispatch(fetchGetSuggestedClusters(selectedProfiles));
};

export const updateProfiles = createAction("UPDATE_PROFILES");
export const updateAlternativeTitles = createAction("UPDATE_ALTERNATIVE_TITLES");
export const onRemoveApplicantProfile = (profileId) => (dispatch, getState) => {
    let userProfiles = _.cloneDeep(getApplicantProfilesData(getState())) || [];

    userProfiles = userProfiles.filter((elem) => elem.id !== profileId);
    dispatch(updateProfiles(userProfiles));
    dispatch(onSaveApplicantProfiles());

    let selectedProfiles = _.cloneDeep(getApplicantSelectedProfilesData(getState()));
    if (selectedProfiles && selectedProfiles.length !== 0) {
        selectedProfiles = selectedProfiles.filter((elem) => elem !== profileId);

        dispatch(updateSelectedProfiles(selectedProfiles));
        dispatch(onFetchGetCurrentClusterSkillsByProfilesForApplicant(selectedProfiles));
        dispatch(fetchGetSuggestedClusters(selectedProfiles));
    }
};

export const onAddApplicantProfile = (profile, altTitles, searchValue) => (dispatch, getState) => {
    let userProfiles = _.cloneDeep(getApplicantProfilesData(getState())) || [];
    userProfiles.push(profile);
    dispatch(updateProfiles(userProfiles));
    dispatch(updateAlternativeTitles(altTitles));
    dispatch(onSaveApplicantProfiles(searchValue));
};

export const onSaveSkillProfile = createAction(
    "FETCH_SAVE_SKILL_PROFILE",
    (profileId, title) => {
        return Api.addProfileToUser(profileId, title);
    },
    () => ({
        apiCall: true,
        onSuccess: onFetchAddApplicantProfilesSuccess,
        onFail: () => {},
    })
);

// TODO: (BE) it will be better to have one request to update profiles
// TODO: sync add/remove requests (fetchGetApplicantProfiles duplication)
export const fetchAddApplicantProfilesFailed = createAction("FETCH_ADD_APPLICANT_PROFILES_FAILED");
export const fetchAddApplicantProfilesSuccess = createAction(
    "FETCH_ADD_APPLICANT_PROFILES_SUCCESS"
);
export const onFetchAddApplicantProfilesSuccess = () => (dispatch) => {
    dispatch(fetchAddApplicantProfilesSuccess());
    dispatch(fetchGetApplicantProfiles());
    // dispatch(onSaveApplicantSkills());
};
export const fetchAddApplicantProfiles = createAction(
    "FETCH_ADD_APPLICANT_PROFILES",
    (data, altTitles, searchValue) => Api.addApplicantProfiles(data, altTitles, searchValue),
    () => ({
        apiCall: true,
        onSuccess: onFetchAddApplicantProfilesSuccess,
        onFail: fetchAddApplicantProfilesFailed,
    })
);
export const fetchRemoveApplicantProfilesFailed = createAction(
    "FETCH_REMOVE_APPLICANT_PROFILES_FAILED"
);
export const fetchRemoveApplicantProfilesSuccess = createAction(
    "FETCH_REMOVE_APPLICANT_PROFILES_SUCCESS"
);
export const onFetchRemoveApplicantProfilesSuccess = () => (dispatch) => {
    dispatch(fetchRemoveApplicantProfilesSuccess());
    dispatch(fetchGetApplicantProfiles());
};
export const fetchRemoveApplicantProfiles = createAction(
    "FETCH_REMOVE_APPLICANT_PROFILES",
    (data) => Api.removeApplicantProfiles(data),
    () => ({
        apiCall: true,
        onSuccess: onFetchRemoveApplicantProfilesSuccess,
        onFail: fetchRemoveApplicantProfilesFailed,
    })
);

export const onSaveApplicantProfiles = (searchValue) => (dispatch, getState) => {
    let initialProfilesData = _.cloneDeep(getApplicantInitialProfilesData(getState()));
    let profilesData = _.cloneDeep(getApplicantProfilesData(getState()));
    let altTitles = getApplicantAlternativeProfilesData(getState());

    let toDelete = [];
    let toInsert = [];

    _.map(initialProfilesData, (profile) => {
        if (!profilesData || !profilesData.find((elem) => elem.id === profile.id)) {
            toDelete.push(profile.id);
        }
    });

    _.map(profilesData, (profile) => {
        if (!initialProfilesData || !initialProfilesData.find((elem) => elem.id === profile.id)) {
            toInsert.push({ id: profile.id });
        }
    });

    if (toInsert.length > 0) {
        dispatch(fetchAddApplicantProfiles(toInsert, altTitles, searchValue));
    }
    if (toDelete.length > 0) {
        dispatch(fetchRemoveApplicantProfiles(toDelete));
    }
};

//- State
const initialState = {
    initialProfilesData: null,
    profilesData: null,
    selectedProfiles: null,
    error: null,
    fetching: false,
    fetchingAddProfiles: false,
    fetchingRemoveProfiles: false,
    alternativeTitles: null,
};

//- Reducers
export default handleActions(
    {
        FETCH_GET_APPLICANT_PROFILES: (state) => {
            return { ...state, error: null, fetching: true };
        },
        FETCH_GET_APPLICANT_PROFILES_FAILED: (state, action) => {
            return {
                ...state,
                profilesData: null,
                initialProfilesData: null,
                error: action.payload,
                fetching: false,
            };
        },
        FETCH_GET_APPLICANT_PROFILES_SUCCESS: (state, action) => {
            return {
                ...state,
                profilesData: action.payload,
                initialProfilesData: action.payload,
                error: null,
                fetching: false,
            };
        },
        FETCH_ADD_APPLICANT_PROFILES: (state) => {
            return { ...state, error: null, fetchingAddProfiles: true };
        },
        FETCH_ADD_APPLICANT_PROFILES_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetchingAddProfiles: false };
        },
        FETCH_ADD_APPLICANT_PROFILES_SUCCESS: (state) => {
            return { ...state, error: null, fetchingAddProfiles: false };
        },
        FETCH_REMOVE_APPLICANT_PROFILES: (state) => {
            return { ...state, error: null, fetchingRemoveProfiles: true };
        },
        FETCH_REMOVE_APPLICANT_PROFILES_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetchingRemoveProfiles: false };
        },
        FETCH_REMOVE_APPLICANT_PROFILES_SUCCESS: (state) => {
            return { ...state, error: null, fetchingRemoveProfiles: false };
        },
        UPDATE_SELECTED_PROFILES: (state, action) => {
            return { ...state, selectedProfiles: action.payload };
        },
        UPDATE_PROFILES: (state, action) => {
            return { ...state, profilesData: action.payload };
        },
        UPDATE_ALTERNATIVE_TITLES: (state, action) => {
            return { ...state, alternativeTitles: action.payload };
        },
    },
    initialState
);

//- Selectors
export const getApplicantProfilesState = (state) => state.applicantProfiles;

export const getApplicantProfilesData = (state) => getApplicantProfilesState(state).profilesData;

export const getApplicantAlternativeProfilesData = (state) =>
    getApplicantProfilesState(state).alternativeTitles;

export const getApplicantInitialProfilesData = (state) =>
    getApplicantProfilesState(state).initialProfilesData;

export const getApplicantProfilesFetchingStatus = (state) =>
    getApplicantProfilesState(state).fetching;

export const getApplicantProfilesInitialFetchingStatus = (state) =>
    getApplicantProfilesFetchingStatus(state) && _.isNil(getApplicantInitialProfilesData(state));

export const getApplicantAddProfilesFetchingStatus = (state) =>
    getApplicantProfilesState(state).fetchingAddProfiles;

export const getApplicantRemoveProfilesFetchingStatus = (state) =>
    getApplicantProfilesState(state).fetchingRemoveProfiles;

export const getApplicantSaveProfilesFetchingStatus = (state) =>
    getApplicantAddProfilesFetchingStatus(state) || getApplicantRemoveProfilesFetchingStatus(state);

export const getApplicantSelectedProfilesData = (state) =>
    getApplicantProfilesState(state).selectedProfiles;
