import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';

export const setJobComparisonModalVisibility = createAction('SET_JOB_COMPARISON_MODAL_VISIBILITY');
export const fetchJobByIdSuccess = createAction('FETCH_JOB_BY_ID_SUCCESS');
export const fetchJobByIdFailed = createAction('FETCH_JOB_BY_ID_FAILED');
export const setSelectedJobId = createAction('SET_SELECTED_JOB_ID');

export const fetchJobById = createAction(
	'FETCH_JOB_BY_ID',
	(jobId) => Api.getJobById(jobId),
	() => ({
		apiCall: true,
		onSuccess: fetchJobByIdSuccess,
		onFail: fetchJobByIdFailed
	})
);

export const onFetchJobById = (jobId) => (dispatch) => {
	dispatch(fetchJobById(jobId));
};

export const onOpenJobQuickCompareModal = (jobId) => (dispatch) => {
	dispatch(setJobComparisonModalVisibility(true));
	dispatch(setSelectedJobId(jobId));
};

export const onCloseJobQuickCompareModal = () => (dispatch) => {
	dispatch(setJobComparisonModalVisibility(false));
	dispatch(setSelectedJobId(''));
};

//- State
const initialState = {
	selectedJobId: '',
	isOpen: false,
	jobData: {},
	error: null,
	fetchingJobById: false,
};

//- Reducer
export default handleActions({
	SET_JOB_COMPARISON_MODAL_VISIBILITY: (state, action) => {
		return { ...state, isOpen: action.payload };
	},
	SET_SELECTED_JOB_ID: (state, action) => {
		return { ...state, selectedJobId: action.payload };
	},
	FETCH_JOB_BY_ID: (state) => {
		return { ...state, jobData: {}, error: null, fetchingJobById: true };
	},
	FETCH_JOB_BY_ID_SUCCESS: (state, action) => {
		return { ...state, jobData: action.payload, error: null, fetchingJobById: false };
	},
	FETCH_JOB_BY_ID_FAILED: (state, action) => {
		return { ...state, jobData: null, error: action.payload, fetchingJobById: false };
	},
}, initialState);

export const getJobComparisonState = state => state.jobComparison;

export const getJobComparisonModalVisibility = state => getJobComparisonState(state).isOpen;

export const getFetchingJobById = state => getJobComparisonState(state).fetchingJobById;

export const getSelectedJobId = state => getJobComparisonState(state).selectedJobId;

export const getJobData = state => getJobComparisonState(state).jobData;
