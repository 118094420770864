import React, { Component } from "react";
import "./CustomSelect.scss";
import _ from "lodash";
import { default as ReactSelect } from "react-select";
import { SELECT_DEFAULT_STYLES } from "../../../../constants/selectStyles";

const SELECTED_VALUE = "SELECTED_VALUE";

class CustomSelect extends Component {
    render() {
        let isValueSelected = !_.isNil(this.props.value);

        if (this.props.isMulti && Array.isArray(this.props.value)) {
            isValueSelected = this.props.value.length > 0;
        }

        return (
            <div className={`${this.props.containerClassName || ""} hf1-custom-select`}>
                <ReactSelect
                    isDisabled={this.props.disabled}
                    {...this.props}
                    className={`${this.props.className || ""} react-select`}
                    isSearchable={
                        !_.isNil(this.props.isSearchable) ? this.props.isSearchable : false
                    }
                    styles={this.props.styles || SELECT_DEFAULT_STYLES}
                ></ReactSelect>
                <input
                    className="hf1-input-required"
                    type="text"
                    value={isValueSelected ? SELECTED_VALUE : ""}
                    tabIndex={-1}
                    autoComplete="off"
                    required={this.props.isRequired}
                    disabled={this.props.isDisabled}
                    onChange={() => {}}
                ></input>
            </div>
        );
    }
}

export default CustomSelect;
