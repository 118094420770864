import React, { Component } from 'react';
import './TablePagination.scss';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import _ from 'lodash';

const MAX_PAGE_ITEMS_NUMBER = 7;
const CENTER_ITEM_INDEX = MAX_PAGE_ITEMS_NUMBER % 2 === 0 ? MAX_PAGE_ITEMS_NUMBER / 2 : (MAX_PAGE_ITEMS_NUMBER - 1) / 2;

const PAGINATION_TYPE = {
    FIRST_PAGES: "FIRST_PAGES",
    LAST_PAGES: "LAST_PAGES",
    CENTER_PAGES: "CENTER_PAGES",
};

class TablePagination extends Component {
    constructor(props) {
        super(props);

        this.state = {
            current: null,
            total: null
        };
    }

    componentDidMount() {
        if (!_.isNil(this.props.current) && !_.isNil(this.props.total)) {
            this.setState({
                current: this.props.current,
                total: this.props.total
            })
        }
    }

    componentDidUpdate(prevProps) {
        if ((!_.isNil(this.props.current) && this.props.current !== prevProps.current && this.state.current !== this.props.current) ||
            (!_.isNil(this.props.total) && this.props.total !== prevProps.total && this.state.total !== this.props.total)) {
            this.setState({
                current: this.props.current,
                total: this.props.total
            })
        }
    }

    render() {
        if (_.isNil(this.state.current) || _.isNil(this.state.total) || this.state.total <= 1) {
            return null;
        }

        return (
            <div className='table-pagination'>
                <Pagination>
                    {
                        this.state.current !== 0 ?
                            <PaginationItem disabled={this.props.loading}>
                                <PaginationLink first onClick={this.handlePageClick(0)} />
                            </PaginationItem>
                            : null
                    }
                    {
                        this.state.current !== 0 ?
                            <PaginationItem disabled={this.props.loading}>
                                <PaginationLink previous onClick={this.handlePageClick(this.state.current - 1)} />
                            </PaginationItem>
                            : null
                    }
                    {this.getPaginationItems()}
                    {
                        this.state.current !== this.state.total - 1 ?
                            <PaginationItem disabled={this.props.loading}>
                                <PaginationLink next onClick={this.handlePageClick(this.state.current + 1)} />
                            </PaginationItem>
                            : null
                    }
                    {
                        this.state.current !== this.state.total - 1 ?
                            <PaginationItem disabled={this.props.loading}>
                                <PaginationLink last onClick={this.handlePageClick(this.state.total - 1)} />
                            </PaginationItem>
                            : null
                    }
                </Pagination>
            </div>
        );
    }

    getPaginationItems = () => {
        let items = [];
        const minIndex = 0;
        const maxIndex = this.state.total - 1;
        let from = minIndex;
        let to = maxIndex;
        let type = PAGINATION_TYPE.FIRST_PAGES;

        if (this.state.total > MAX_PAGE_ITEMS_NUMBER) {
            if (this.state.current <= CENTER_ITEM_INDEX) {
                from = 0;
                to = MAX_PAGE_ITEMS_NUMBER - 1;
            } else if (this.state.current >= maxIndex - CENTER_ITEM_INDEX) {
                from = maxIndex - MAX_PAGE_ITEMS_NUMBER + 1;
                to = maxIndex;
                type = PAGINATION_TYPE.LAST_PAGES;
            } else {
                from = this.state.current - CENTER_ITEM_INDEX;
                to = this.state.current + (MAX_PAGE_ITEMS_NUMBER - 1 - CENTER_ITEM_INDEX);
                type = PAGINATION_TYPE.CENTER_PAGES;
            }
        }

        if ((type === PAGINATION_TYPE.LAST_PAGES || type === PAGINATION_TYPE.CENTER_PAGES) && this.state.total > MAX_PAGE_ITEMS_NUMBER) {
            items.push(
                <PaginationItem disabled key={"pagination-item-first-items-placeholder"}>
                    <PaginationLink>
                        ...
                    </PaginationLink>
                </PaginationItem>
            )
        }

        for (let i = from; i <= to; i++) {
            let active = this.state.current === i;

            items.push(
                <PaginationItem active={active} key={"pagination-item-" + i} disabled={this.props.loading}>
                    <PaginationLink onClick={this.handlePageClick(i)}>
                        {i + 1}
                    </PaginationLink>
                </PaginationItem>
            )
        }

        if ((type === PAGINATION_TYPE.FIRST_PAGES || type === PAGINATION_TYPE.CENTER_PAGES) && this.state.total > MAX_PAGE_ITEMS_NUMBER) {
            items.push(
                <PaginationItem disabled key={"pagination-item-last-items-placeholder"}>
                    <PaginationLink>
                        ...
                    </PaginationLink>
                </PaginationItem>
            )
        }

        return items;
    }

    handlePageClick = (id) => () => {
        id = parseInt(id, 10);
        if (Number.isInteger(id) && this.state.current !== id) {
            this.props.getDataForPage(id);
            this.setState({ current: id });
        }
    };
}

TablePagination.propTypes = {
    total: PropTypes.number,
    current: PropTypes.number,
    loading: PropTypes.bool,
    getDataForPage: PropTypes.func.isRequired,
};

export default TablePagination;
