import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button, Table } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../../constants/constants';
import moment from 'moment';
import Icon from '../../../common/icon/Icon';

class OpenJobs extends Component {
    componentDidMount() {
        this.props.onMount();
    }

    render() {
        return (
            <div className="open-jobs">
                <h4 className="page-hea der">{_.get(this.props.translation, "open_jobs_page.page_label")}</h4>
                <div className="page-hint">{_.get(this.props.translation, "open_jobs_page.page_hint")}</div>
                <div className="buttons-container">
                    <Button color="primary" tag={Link} to={ROUTES.CREATE_NEW_JOB}>
                        {_.get(this.props.translation, 'open_jobs_page.create_new_job_button')}
                    </Button>
                </div>
                {/* TODO: add pagination, sorting by columns (ASC/DESC) */}
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        if (this.props.loading) {
            return (
                <h6 className="text-center">{_.get(this.props.translation, "actions.fetching_message")}</h6>
            );
        }

        if (!this.props.jobsList || this.props.jobsList.length === 0) {
            return (
                <h6 className="text-center">{_.get(this.props.translation, "job.no_data_label")}</h6>
            );
        }

        return (
            <Table className="clickable-rows">
                <thead>
                    <tr>
                        <th>{_.get(this.props.translation, "job.job_title_label")}</th>
                        <th>{_.get(this.props.translation, "job.start_date_label")}</th>
                        <th>{_.get(this.props.translation, "job.deadline_label")}</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderJobsData()}
                </tbody>
            </Table>
        );
    }

    renderJobsData() {
        return _.map(this.props.jobsList, (jobData) => (
            <tr key={jobData.id}>
                <td onClick={this.onSelectJob(jobData.id)}>{jobData.title}</td>
                <td onClick={this.onSelectJob(jobData.id)}>{moment(jobData.startDate).format("L")}</td>
                <td onClick={this.onSelectJob(jobData.id)}>{moment(jobData.deadline).format("L")}</td>
                <td className="table-cell-with-icon" onClick={this.onSelectJob(jobData.id)} title={_.get(this.props.translation, 'actions.show_details_label')}>
                    <Icon type="visibility" color="secondary" />
                </td>
                <td className="table-cell-with-icon" onClick={this.onCompareCandidates(jobData.id)} title={_.get(this.props.translation, 'open_jobs_page.compare_candidates_button')}>
                    <Icon type="compare" color="secondary" />
                </td>
            </tr>
        ));
    }

    onSelectJob = (jobId) => () => {
        this.props.history.push(`${ROUTES.OPEN_JOB_DETAILS}?id=${jobId}`);
    }

    onCompareCandidates = (jobId) => (e) => {
        e.stopPropagation();
        this.props.history.push(`${ROUTES.OPEN_JOB_COMPARISON}?id=${jobId}`);
    }
}

OpenJobs.propTypes = {
    translation: PropTypes.object.isRequired,
    jobsList: PropTypes.array,
    loading: PropTypes.bool,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onMount: PropTypes.func.isRequired,
}

export default withRouter(OpenJobs);
