import React, { Component } from "react";
import _ from "lodash";
import { Container, Input, Media, Alert, Button } from "reactstrap";
import "./RichMedia.scss";
import Icon from "../../../common/icon/Icon";
import Dropzone from "react-dropzone";
import {
    allowedMediaForEachRole,
    allowedMediaOwnerForEachRole,
    allowedMediaTypeForEachMediaSource,
    ROUTES,
    USER_ROLE,
    USER_SUB_ROLE,
} from "../../../../constants/constants";
import SimpleTooltip from "../../../common/tooltip/SimpleTooltip";
import { getFormData } from "../../../../utils/formData";

class RichMedia extends Component {
    state = {
        choosenTab: "",
        toolTipOpened: "",
        choosenMedia: "",
        inputFiles: [],
        err: "",
        selectedDropDownItem: null,
    };

    componentDidMount() {
        if (this.props.userRole !== USER_ROLE.APPLICANT) {
            this.props.onGetDepartmentsList();
            this.props.onGetOrganizationsList();
        }
        this.setState({
            choosenTab:
                this.props.location?.state?.activeMediaSource ||
                allowedMediaOwnerForEachRole[this.props.userSubRole || this.props.userRole][0],
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.userRole !== USER_ROLE.APPLICANT) {
            if (prevProps.userDepts !== this.props.userDepts) {
                this.setState(
                    {
                        selectedDropDownItem: this.props.userDepts[0],
                    },
                    () =>
                        this.props.userSubRole !== USER_SUB_ROLE.HM
                            ? this.props.onFetchTeamsRelatedToDept(
                                  this.state.selectedDropDownItem.id
                              )
                            : null
                );
            }

            if (
                prevProps.userTeams !== this.props.userTeams &&
                this.state.choosenTab === "teams" &&
                !_.isEmpty(this.state.selectedDropDownItem)
            ) {
                this.setState({
                    selectedDropDownItem: this.props.userTeams.filter(
                        (extTeam) => extTeam.id === this.state.selectedDropDownItem.id
                    )[0],
                });
            }
        }
    }

    renderHeader() {
        return (
            <div
                className={`d-flex flex-row rich-media__org-box justify-content-${
                    allowedMediaOwnerForEachRole[this.props.userSubRole || this.props.userRole]
                        .length > 1
                        ? "between"
                        : "center"
                }`}
            >
                {allowedMediaOwnerForEachRole[
                    this.props.userSubRole || this.props.userRole
                ].includes("teams") && this.renderDropdown("Teams", this.props.userTeams, "teams")}
                {allowedMediaOwnerForEachRole[
                    this.props.userSubRole || this.props.userRole
                ].includes("departments") &&
                    this.renderDropdown("Departments", this.props.userDepts, "departments")}
                {allowedMediaOwnerForEachRole[
                    this.props.userSubRole || this.props.userRole
                ].includes("corporate") && (
                    <div
                        className={`rich-media__content d-flex flex-column rich-media__selector-box  ${
                            this.state.choosenTab === "corporate"
                                ? "rich-media__selector-box--active"
                                : ""
                        }`}
                        onClick={() => {
                            this.setState({
                                err: "",
                                choosenTab: "corporate",
                            });
                        }}
                    >
                        <b>Corporate</b>
                        {this.props.userOrganization?.name}
                    </div>
                )}
                {allowedMediaOwnerForEachRole[
                    this.props.userSubRole || this.props.userRole
                ].includes("myProfile") && (
                    <div
                        className={`rich-media__content rich-media__selector-box  ${
                            this.state.choosenTab === "myProfile"
                                ? "rich-media__selector-box--active"
                                : ""
                        }`}
                        onClick={() => {
                            this.setState({
                                err: "",
                                choosenTab: "myProfile",
                            });
                        }}
                    >
                        <span className="text-center rich-media__tab-title">My Profile</span>
                        {this.props.userData.profilePictureFileURI && (
                            <Media
                                className="w-100"
                                src={this.props.userData.profilePictureFileURI}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }

    renderSelectorBox(title, tooltipText, iconType, id) {
        return (
            <div
                className={`d-flex flex-column rich-media__selector-box ${
                    this.state.choosenMedia === id ? "rich-media__selector-box--active" : ""
                }`}
                onClick={() => {
                    this.setState({
                        err: "",
                        choosenMedia: id,
                        inputFiles: [],
                    });
                }}
            >
                <div className="d-flex flex-row justify-content-between w-100">
                    <Icon type={iconType} />
                    {tooltipText && (
                        <>
                            <Icon type="info" color="blue" id={`${id}-tooltip`} />
                            <SimpleTooltip placement="right" target={`${id}-tooltip`}>
                                {tooltipText}
                            </SimpleTooltip>
                        </>
                    )}
                </div>
                <span>{title}</span>
            </div>
        );
    }

    renderMediaTypeSelector() {
        return (
            <div className="d-flex flex-column pr-3">
                {allowedMediaForEachRole[this.props.userSubRole || this.props.userRole].includes(
                    "audioFile"
                ) &&
                    this.renderSelectorBox(
                        _.get(this.props.translation, "rich_media.audio_label"),
                        _.get(
                            this.props.translation,
                            `rich_media.${(() => {
                                if (this.props.userRole === USER_ROLE.APPLICANT) {
                                    if (
                                        this.props.location.pathname === ROUTES.JOBS ||
                                        this.props.location.pathname === ROUTES.APPLICANT_ACCOUNT
                                    ) {
                                        return `applicant_${this.props.location.pathname.toLowerCase()}_video/pic/audio`;
                                    }
                                } else if (this.props.userRole === USER_ROLE.EMPLOYEE) {
                                    if (this.props.userSubRole === USER_SUB_ROLE.ANCHOR) {
                                        return "anchor_audio_hint";
                                    }
                                }
                            })()}`
                        ),
                        "audio_message",
                        "audioFile"
                    )}
                {allowedMediaForEachRole[this.props.userSubRole || this.props.userRole].includes(
                    "cvFile"
                ) &&
                    this.renderSelectorBox(
                        _.get(
                            this.props.translation,
                            `rich_media.${(() => {
                                if (this.props.userRole === USER_ROLE.APPLICANT) {
                                    if (this.props.location.pathname === ROUTES.APPLICANT_ACCOUNT) {
                                        return "applicant_/profile_letter_label";
                                    } else if (this.props.location.pathname === ROUTES.JOBS) {
                                        return "applicant_/jobs_letter_label";
                                    }
                                } else if (this.props.userRole === USER_ROLE.EMPLOYEE) {
                                    return "employee_letter_label";
                                }
                            })()}`
                        ),
                        _.get(
                            this.props.translation,
                            `rich_media.${(() => {
                                if (this.props.userRole === USER_ROLE.APPLICANT) {
                                    if (this.props.location.pathname === ROUTES.JOBS) {
                                        return `applicant_${this.props.location.pathname.toLowerCase()}_video/pic/audio`;
                                    } else if (
                                        this.props.location.pathname === ROUTES.APPLICANT_ACCOUNT
                                    ) {
                                        return "";
                                    }
                                } else if (this.props.userRole === USER_ROLE.EMPLOYEE) {
                                    if (this.props.userSubRole === USER_SUB_ROLE.ANCHOR) {
                                        return "anchor_letter_hint";
                                    }
                                }
                            })()}`
                        ),
                        "cv",
                        "cvFile"
                    )}
                {allowedMediaForEachRole[this.props.userSubRole || this.props.userRole].includes(
                    "pictureFiles"
                ) &&
                    this.renderSelectorBox(
                        _.get(this.props.translation, "rich_media.moments_label"),
                        _.get(
                            this.props.translation,
                            `rich_media.${(() => {
                                if (this.props.userRole === USER_ROLE.APPLICANT) {
                                    if (
                                        this.props.location.pathname === ROUTES.JOBS ||
                                        this.props.location.pathname === ROUTES.APPLICANT_ACCOUNT
                                    ) {
                                        return `applicant_${this.props.location.pathname.toLowerCase()}_video/pic/audio`;
                                    }
                                } else if (this.props.userRole === USER_ROLE.EMPLOYEE) {
                                    if (this.props.userSubRole === USER_SUB_ROLE.ANCHOR) {
                                        return "anchor_photos_hint";
                                    }
                                }
                            })()}`
                        ),
                        "photo",
                        "pictureFiles"
                    )}
                {allowedMediaForEachRole[this.props.userSubRole || this.props.userRole].includes(
                    "profilePictureFile"
                ) &&
                    this.renderSelectorBox(
                        _.get(
                            this.props.translation,
                            `rich_media.${this.props.userRole.toLowerCase()}_avatar_label`
                        ) + " (*)",
                        _.get(
                            this.props.translation,
                            `rich_media.${(() => {
                                if (this.props.userRole === USER_ROLE.APPLICANT) {
                                    if (this.props.location.pathname === ROUTES.APPLICANT_ACCOUNT) {
                                        return "applicant_/applicant-account_avatar";
                                    }
                                } else if (this.props.userRole === USER_ROLE.EMPLOYEE) {
                                    if (this.props.userSubRole === USER_SUB_ROLE.ANCHOR) {
                                        return "anchor_avatar_hint";
                                    }
                                }
                            })()}`
                        ),
                        "photo",
                        "profilePictureFile"
                    )}
                {allowedMediaForEachRole[this.props.userSubRole || this.props.userRole].includes(
                    "videoFile"
                ) &&
                    this.renderSelectorBox(
                        _.get(this.props.translation, "rich_media.aura_label"),
                        _.get(
                            this.props.translation,
                            `rich_media.${(() => {
                                if (this.props.userRole === USER_ROLE.APPLICANT) {
                                    if (
                                        this.props.location.pathname === ROUTES.JOBS ||
                                        this.props.location.pathname === ROUTES.APPLICANT_ACCOUNT
                                    ) {
                                        return `applicant_${this.props.location.pathname.toLowerCase()}_video/pic/audio`;
                                    }
                                } else if (this.props.userRole === USER_ROLE.EMPLOYEE) {
                                    if (this.props.userSubRole === USER_SUB_ROLE.ANCHOR) {
                                        return "anchor_video_hint";
                                    }
                                }
                            })()}`
                        ),
                        "video_message",
                        "videoFile"
                    )}
            </div>
        );
    }

    renderDropdown(title, content, id) {
        return (
            <div
                className={`d-flex flex-column rich-media__selector-box ${
                    this.state.choosenTab === id ? "rich-media__selector-box--active" : ""
                }`}
                onClick={() => {
                    this.setState({
                        err: "",
                        choosenTab: id,
                        inputFiles: [],
                    });
                }}
            >
                <span>{title}</span>
                {!!content.length && (
                    <Input
                        type="select"
                        name={title}
                        id={id}
                        onChange={(e) => {
                            this.setState(
                                {
                                    choosenTab: id,
                                    selectedDropDownItem: JSON.parse(e.target.value),
                                },
                                () => {
                                    if (
                                        this.state.choosenTab === "departments" &&
                                        this.props.userSubRole !== USER_SUB_ROLE.HM
                                    )
                                        this.props.onFetchTeamsRelatedToDept(
                                            this.state.selectedDropDownItem.id
                                        );
                                }
                            );
                        }}
                    >
                        {content.map((item) => (
                            <option
                                key={item.id}
                                id={item.id}
                                itemInfo={item}
                                value={JSON.stringify(item)}
                                selected={this.state.selectedDropDownItem?.id === item.id}
                            >
                                {item.name}
                            </option>
                        ))}
                    </Input>
                )}
            </div>
        );
    }

    renderMediaUploader() {
        return (
            <div className="w-75">
                <div className="mb-3">
                    <div className="d-flex flex-column justify-content-between">
                        <b className="align-self-center">Uploaded Files: </b>
                        <div className="mt-3">
                            {this.state.choosenMedia === "audioFile" &&
                                (() => {
                                    let data;
                                    switch (this.state.choosenTab) {
                                        case "myProfile":
                                            data = this.props.userData.audioFile;

                                            break;
                                        case "corporate":
                                            data = this.props.userOrganization.audioFile;

                                            break;

                                        case "departments":
                                            data = this.state.selectedDropDownItem?.audioFile;

                                            break;
                                        case "teams":
                                            data = this.state.selectedDropDownItem?.audioFile;

                                            break;
                                        default:
                                            break;
                                    }
                                    return (
                                        !_.isEmpty(data) && (
                                            <div className="d-flex flex-row">
                                                <div className="d-flex flex-column">
                                                    <audio controls>
                                                        <source src={data.fileURI} />{" "}
                                                    </audio>
                                                    <span>{data.fileName}</span>
                                                </div>
                                                <Icon
                                                    type="cancel"
                                                    className="ml-3"
                                                    onClick={() => this.handleDeleteFile(data.id)}
                                                />
                                            </div>
                                        )
                                    );
                                })()}
                            {this.state.choosenMedia === "cvFile" &&
                                (() => {
                                    let data;
                                    switch (this.state.choosenTab) {
                                        case "myProfile":
                                            data = this.props.userData.cvFile;

                                            break;
                                        case "corporate":
                                            data = this.props.userOrganization.cvFile;

                                            break;

                                        case "departments":
                                            data = this.state.selectedDropDownItem?.cvFile;

                                            break;
                                        case "teams":
                                            data = this.state.selectedDropDownItem?.cvFile;

                                            break;
                                        default:
                                            break;
                                    }
                                    return (
                                        !_.isEmpty(data) && (
                                            <div className="d-flex flex-row">
                                                <a href={data.fileURI} className="d-flex flex-row">
                                                    <Icon type="document" className="mr-3" />
                                                </a>
                                                <span>{data.fileName}</span>
                                                <Icon
                                                    type="cancel"
                                                    className="ml-3"
                                                    onClick={() => this.handleDeleteFile(data.id)}
                                                />
                                            </div>
                                        )
                                    );
                                })()}

                            {this.state.choosenMedia === "profilePictureFile" &&
                                (() => {
                                    let data;
                                    switch (this.state.choosenTab) {
                                        case "myProfile":
                                            data = this.props.userData.profilePictureFile;

                                            break;
                                        case "corporate":
                                            data = this.props.userOrganization.profilePictureFile;

                                            break;

                                        case "departments":
                                            data =
                                                this.state.selectedDropDownItem?.profilePictureFile;

                                            break;

                                        case "teams":
                                            data =
                                                this.state.selectedDropDownItem?.profilePictureFile;

                                            break;
                                        default:
                                            break;
                                    }
                                    return (
                                        !_.isEmpty(data) && (
                                            <div className="d-flex flex-row rich-media__media-container">
                                                <a
                                                    download
                                                    href={`/api/files/${
                                                        data.fileURI.split("/api/files/")[1]
                                                    }`}
                                                >
                                                    <img
                                                        src={data.fileURI}
                                                        className="w-100 mb-3"
                                                        alt=""
                                                    />
                                                </a>
                                                <Icon
                                                    type="cancel"
                                                    className="ml-3"
                                                    onClick={() => this.handleDeleteFile(data.id)}
                                                />
                                            </div>
                                        )
                                    );
                                })()}
                            {this.state.choosenMedia === "pictureFiles" &&
                                (() => {
                                    let data;
                                    switch (this.state.choosenTab) {
                                        case "myProfile":
                                            data = this.props.userData.pictureFiles;

                                            break;
                                        case "corporate":
                                            data = this.props.userOrganization.pictureFiles;

                                            break;

                                        case "departments":
                                            data = this.state.selectedDropDownItem?.pictureFiles;

                                            break;
                                        case "teams":
                                            data = this.state.selectedDropDownItem?.pictureFiles;

                                            break;
                                        default:
                                            break;
                                    }
                                    return (
                                        !_.isEmpty(data) &&
                                        data.map((item) => (
                                            <div className="d-flex flex-row rich-media__media-container">
                                                <a
                                                    href={`/api/files/${
                                                        item.fileURI.split("/api/files/")[1]
                                                    }`}
                                                    download={true}
                                                >
                                                    <Media
                                                        src={item.fileURI}
                                                        className="w-100 mb-3"
                                                    />
                                                </a>
                                                <Icon
                                                    type="cancel"
                                                    className="ml-3"
                                                    onClick={() => this.handleDeleteFile(item.id)}
                                                />
                                            </div>
                                        ))
                                    );
                                })()}
                            {this.state.choosenMedia === "videoFile" &&
                                (() => {
                                    let data;
                                    switch (this.state.choosenTab) {
                                        case "myProfile":
                                            data = this.props.userData.videoFile;

                                            break;
                                        case "corporate":
                                            data = this.props.userOrganization.videoFile;

                                            break;

                                        case "departments":
                                            data = this.state.selectedDropDownItem?.videoFile;

                                            break;
                                        case "teams":
                                            data = this.state.selectedDropDownItem?.videoFile;

                                            break;
                                        default:
                                            break;
                                    }
                                    return (
                                        !_.isEmpty(data) && (
                                            <div className="d-flex flex-row">
                                                <div className="d-flex flex-column">
                                                    <video
                                                        className="rich-media__media-item"
                                                        src={data.fileURI}
                                                        controls
                                                        width="200"
                                                    />
                                                    <span>{data.fileName}</span>
                                                </div>
                                                <Icon
                                                    type="cancel"
                                                    className="ml-3"
                                                    onClick={() => this.handleDeleteFile(data.id)}
                                                />
                                            </div>
                                        )
                                    );
                                })()}
                        </div>
                    </div>
                </div>
                <Dropzone
                    onDrop={(acceptedFiles) => {
                        if (
                            allowedMediaTypeForEachMediaSource[this.state.choosenMedia].includes(
                                acceptedFiles[0].type
                            )
                        )
                            this.state.choosenMedia === "pictureFiles"
                                ? this.setState({
                                      err: "",
                                      inputFiles: [...this.state.inputFiles, ...acceptedFiles],
                                  })
                                : this.setState({ err: "", inputFiles: [...acceptedFiles] });
                        else this.setState({ err: "Wrong file Type" });
                    }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <section className="d-flex flex-column align-items-center">
                            <div
                                {...getRootProps()}
                                className="d-flex flex-column p-5 mb-3 border rounded"
                            >
                                <input
                                    {...getInputProps()}
                                    accept={(() => {
                                        return allowedMediaTypeForEachMediaSource[
                                            this.state.choosenMedia
                                        ];
                                    })()}
                                    maxFiles={(() => {
                                        if (this.state.choosenMedia === "pictureFiles") return 10;
                                        else return 1;
                                    })()}
                                />
                                <p>
                                    Drag 'n' drop some files here, or{" "}
                                    <i>
                                        <b>click anywhere to select files</b>
                                    </i>
                                </p>
                            </div>
                            {!!this.state.inputFiles.length && (
                                <>
                                    <b className="mb-3">Files to upload: </b>
                                    {this.state.inputFiles.map((file) => (
                                        <div className="d-flex flex-row justify-content-between mb-5">
                                            <span>{file.name}</span>
                                            <Icon
                                                type="cancel"
                                                className="ml-3"
                                                onClick={() => {
                                                    this.setState({
                                                        inputFiles: this.state.inputFiles.filter(
                                                            (oldFile) => oldFile.name !== file.name
                                                        ),
                                                    });
                                                }}
                                            />
                                        </div>
                                    ))}
                                    <Button
                                        color="primary mb-3"
                                        onClick={() =>
                                            this.handleSubmitFiles(this.state.inputFiles)
                                        }
                                    >
                                        Submit
                                    </Button>
                                </>
                            )}
                        </section>
                    )}
                </Dropzone>
            </div>
        );
    }

    handleSubmitFiles(e) {
        const formData = getFormData({
            [this.state.choosenMedia]: this.state.choosenMedia === "pictureFiles" ? e : e[0],
        });

        switch (this.state.choosenTab) {
            case "corporate":
                this.props.onUpdateOrgMedia(formData);
                break;
            case "departments":
                this.props.onUpdateDepartmentsMedia(this.state.selectedDropDownItem.id, formData);
                break;
            case "myProfile":
                this.props.approveUserMedia(formData);
                break;
            case "teams":
                this.props.onUpdateTeamsMedia(this.state.selectedDropDownItem.id, formData);
                break;
            default:
                break;
        }

        this.setState({ inputFiles: [] });
    }

    handleDeleteFile(fileId) {
        switch (this.state.choosenTab) {
            case "corporate":
                this.props.onDeleteOrgMedia(fileId);
                break;
            case "departments":
                this.props.onDeleteDepartmentMedia(this.state.selectedDropDownItem.id, fileId);
                break;
            case "myProfile":
                this.props.onDeleteUserMedia(fileId);
                break;
            case "teams":
                this.props.onDeleteTeamsMedia(this.state.selectedDropDownItem.id, fileId);
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <Container className="d-flex flex-column">
                <b className="text-center mt-3">
                    {_.get(this.props.translation, "rich_media.file_upload_hint")}
                </b>
                {(this.props.err || this.state.err) && (
                    <Alert color="danger" className="mt-3">
                        {this.props.err || this.state.err}
                    </Alert>
                )}
                <div className="border p-3">
                    {this.renderHeader()}
                    <div className="d-flex flex-row justify-content-between pt-3 pr-3 border-top">
                        <div className="border-right">{this.renderMediaTypeSelector()}</div>
                        {!!this.state.choosenMedia.length && this.renderMediaUploader()}
                    </div>
                </div>
            </Container>
        );
    }
}

RichMedia.propTypes = {};

export default RichMedia;
