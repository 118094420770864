import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './QuickfindJob.scss';
import QuickfindForm from '../../common/quickfindForm/QuickfindForm.container';
import _ from 'lodash';
import {Button, Label} from 'reactstrap';
import SetQuickfindSkillLevelModal
	from '../../common/modals/setQuickfindSkillLevelModal/SetQuickfindSkillLevelModal.container';
import JobItem from '../../common/jobItem/JobItem.container';
import Icon from '../../common/icon/Icon';
import JobQuickComparison from '../jobQuickComparison/JobQuickComparison.container';
import { ROUTES } from '../../../constants/constants';

class QuickfindJob extends Component {
	render() {
		const { translation, selectedSkillId, skills, jobs, selectedJobsIds, loading, history } = this.props;

		const selectedSkill = _.find(skills, (elem) => elem.id === selectedSkillId);

		if (loading) {
			return (
				<div className="loading-wrapper">
					<h6 className="text-center">{_.get(translation, "actions.fetching_message")}</h6>
				</div>
			)
		}

		return (
			<>
				<div className="job-quick-find">
					<QuickfindForm isQuickfindJob />
					<div className="job-pull">
						<h5 className="page-subsection-header">{_.get(translation, 'quickfind_job_page.job_pull_label')}</h5>
						<div className="balance-icon-description-wrapper">
							<h6>{_.get(translation, 'shared.the')}</h6>
							<Icon
								type="compare"
								color="secondary"
								className="compare-icon"
							/>
							<h6>{_.get(translation, 'quickfind_job_page.balance_icon_description')}</h6>
						</div>
						<h6>{_.get(translation, 'quickfind_job_page.job_pull_subheader_label')}</h6>
						<div className="jobs-wrapper">
							{
								!!jobs.length
									? jobs.map(job =>
										<JobItem key={job.id} job={job} />
									) : (
										<Label className="no-jobs-message">
											{_.get(translation, "quickfind_job_page.no_jobs_message")}
										</Label>
									)
							}
						</div>
						<div className="compare-button-wrapper">
							<Button
								color="primary"
								disabled={!selectedJobsIds.length}
								onClick={() => history.push(ROUTES.JOBS_QUICKFIND_COMPARISON)}
							>
								{_.get(translation, 'actions.compare_selected_jobs')}
							</Button>
						</div>
					</div>
				</div>
				<SetQuickfindSkillLevelModal
					id={selectedSkillId}
					level={selectedSkill ? selectedSkill.level : null}
				/>
				<JobQuickComparison />
			</>
		);
	}
}

QuickfindJob.propTypes = {
	translation: PropTypes.object.isRequired,
	selectedSkillId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	skills: PropTypes.array.isRequired,
	jobs: PropTypes.array,
	selectedJobsIds: PropTypes.array,
	loading: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(QuickfindJob);
