import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './ApplicantsComparison.scss';
import { Alert, Col } from "reactstrap";
import ApplicantsStack from './components/applicantsStack/ApplicantsStack.container';
import UserPhoto from '../../common/userPhoto/UserPhotoQuickfind.container';
import Icon from '../../common/icon/Icon';
import Legend from '../../common/skills/legend/Legend.container';
import { LEGEND_TYPE } from '../../common/skills/legend/Legend';
import { SkillsUtils } from '../../../utils/skillsUtils';
import { APPLICANT_INFO_MODAL_TAB_TYPE } from '../applicantQuickfindInfoModal/ApplicantQuickfindInfoModal';
import ApplicantQuickfindInfoModal from '../applicantQuickfindInfoModal/ApplicantQuickfindInfoModal.container';
import HardSkillComparisonControls
	from '../../common/hardSkillComparisonControls/HardSkillComparisonControls.container';
import QuickfindMultipleHardSkillComparison
	from "../../common/quickfindMultipleHardSkillComparison/QuickfindMultipleHardSkillComparison";

class ApplicantsComparison extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isShowAllSkills: false,
			crossJobInfoType: null,
			isModalVisible: false,
			selectedApplicant: {},
			enabledApplicantsIdsToCompare: []
		}
	}

	componentDidMount() {
		const { selectedCandidatesIds, onFetchApplicantsByIds } = this.props;

		if (selectedCandidatesIds.length) {
			onFetchApplicantsByIds(selectedCandidatesIds);
			this.setState({ enabledApplicantsIdsToCompare: selectedCandidatesIds });
		}
	}

	onDisableApplicant = (isUserSelected) => (applicantId) => {
		const { enabledApplicantsIdsToCompare } = this.state;
		if (isUserSelected) {
			const updatedApplicantsIds = _.filter(enabledApplicantsIdsToCompare, id => id !== applicantId);
			this.setState({ enabledApplicantsIdsToCompare: updatedApplicantsIds });
		} else {
			this.setState({ enabledApplicantsIdsToCompare: [...enabledApplicantsIdsToCompare, applicantId] });
		}
	};

	handleOpenInfoModal = (type, applicant) => () => {
		this.setState({ isModalVisible: true, crossJobInfoType: type , selectedApplicant: applicant });
	};

	handleAllSkillsVisibility = (value) => () => {
		this.setState({ isShowAllSkills: value });
	};

	render() {
		const { translation, applicantsData, skills, loading, selectedCandidatesIds } = this.props;

		const { isShowAllSkills, crossJobInfoType, isModalVisible, selectedApplicant, enabledApplicantsIdsToCompare } = this.state;

		const applicantsDataToCompare = _.filter(
			applicantsData,
			(applicant) =>
				_.includes(selectedCandidatesIds, applicant.id) && _.includes(enabledApplicantsIdsToCompare, applicant.id)
		);

		const applicantsToShowInStack = _.filter(applicantsData, (applicant) => _.includes(selectedCandidatesIds, applicant.id));

		const formattedApplicantsData = _.map(applicantsDataToCompare, (applicant) => {
			return {
				userId: applicant.id,
				user: {
					accountCompleted: applicant.accountCompleted,
					address: applicant.address,
					alias: applicant.alias,
					currentJob: applicant.currentJob,
					email: applicant.email,
					id: applicant.id,
					role: applicant.role

				},
				skills: _.map(applicant.skills, (skill) => {
					return {
						id: skill.skill.id,
						title: skill.skill.titles.en,
						categoryId: skill.skill.category.id,
						categoryTitle: skill.skill.category.titles.en,
						level: skill.level,
					}
				}),
			};
		});

		const jobSkills = _.map(skills, (skill) => ({
			id: skill.id,
			title: skill.name,
			categoryId: skill.category.id,
			categoryTitle: skill.category.titles.en,
			level: skill.level,
		}));

		const applicantsSkills = _.map(applicantsDataToCompare, (applicant) => ({
			skills: _.map(applicant.skills, (skill) => {
				return {
					id: skill.skill.id,
					title: skill.skill.titles.en,
					categoryId: skill.skill.category.id,
					categoryTitle: skill.skill.category.titles.en,
					level: skill.level,
				}
			}),
		}));

		const comparedSkills = _.map(applicantsSkills, (skill) => SkillsUtils.createComparisonSkillsTree(jobSkills, skill.skills));

		const jobSkillsTree = SkillsUtils.createSkillsTree(jobSkills);

		const jobClusters = _.orderBy(jobSkillsTree, ["categoryTitle"]);

		if (loading) {
			return (
				<div className="loading-wrapper">
					<h6 className="text-center">{_.get(translation, "actions.fetching_message")}</h6>
				</div>
			)
		}

		return (
			<>
				<div className="applicants-comparison">
					<h4 className="page-header">{_.get(translation, "applicants_comparison_page.page_label")}</h4>
					<div className="applicants-comparison-content-container">
						<Col md="3" className="content-column candidates-stack-column">
							<div className="applicants-stack-wrapper">
								<ApplicantsStack
									applicantsData={applicantsToShowInStack}
									onDisableApplicant={this.onDisableApplicant}
									enabledApplicantsIdsToCompare={enabledApplicantsIdsToCompare}
								/>
							</div>
						</Col>
						<Col className="content-column details-column">
							<div className="details-wrapper">
								<div className="dashboard-comparison">
									{
										applicantsDataToCompare.length ? (
											<>
												<div className="applicants-info">
													<div className="applicants-info-col job-requirements">
														<h6 className="text-center">
															{_.get(translation, 'job.job_requirements_label')}
														</h6>
													</div>
													{
														_.map(formattedApplicantsData, (applicant) => {
															const isUserSelected = _.includes(enabledApplicantsIdsToCompare, applicant.userId);

															return (
																<div className="applicants-info-col applicant-info" key={applicant.userId}>
																	<div className="applicant-controls">
																		<div className="buttons-group">
																			<Icon
																				type="movie"
																				size="lg"
																				onClick={this.handleOpenInfoModal(APPLICANT_INFO_MODAL_TAB_TYPE.MOVIE, applicant)}
																			/>
																		</div>
																		<UserPhoto
																			userId={applicant.userId}
																			imageSource=""
																			size="sm"
																		/>
																		<div className="buttons-group">
																			<Icon
																				type="cv"
																				size="lg"
																				onClick={this.handleOpenInfoModal(APPLICANT_INFO_MODAL_TAB_TYPE.CV, applicant)}
																			/>
																		</div>
																	</div>
																	<div className="applicant-name">
																		{`${applicant.user.alias.firstName} ${applicant.user.alias.lastName}`}
																		<br/>
																		{applicant.user.currentJob}
																	</div>
																	<Icon
																		className="remove-applicant-from-comparison"
																		type="cancel"
																		size="sm"
																		color="danger"
																		onClick={() => this.onDisableApplicant(isUserSelected)(applicant.userId)}
																		title={_.get(translation, 'applicants_comparison_page.remove_applicant_from_comparison_label')}
																	/>
																</div>
															);
														})
													}
												</div>
												<HardSkillComparisonControls
													isShowAllSkills={isShowAllSkills}
													handleAllSkillsVisibility={this.handleAllSkillsVisibility}
												/>
												<QuickfindMultipleHardSkillComparison
													isShowAllSkills={isShowAllSkills}
													comparedSkills={comparedSkills}
													clusters={jobClusters}
												/>
												<Legend type={LEGEND_TYPE.COMPARISON_REVERSED} />
											</>
										) : (
											<Alert>
												{_.get(translation, 'applicants_comparison_page.select_applicant_hint')}
											</Alert>
										)
									}
								</div>
							</div>
						</Col>
					</div>
				</div>
				<ApplicantQuickfindInfoModal
					activeTab={crossJobInfoType}
					isOpen={isModalVisible}
					onCancel={() => this.setState({ isModalVisible: false, crossJobInfoType: null })}
					data={selectedApplicant}
				/>
			</>
		)
	}
}

ApplicantsComparison.propTypes = {
	translation: PropTypes.object.isRequired,
	applicantsData: PropTypes.array.isRequired,
	onFetchApplicantsByIds: PropTypes.func.isRequired,
	skills: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	selectedCandidatesIds: PropTypes.array.isRequired
};


export default ApplicantsComparison;
