import React, { Component } from 'react';

class PlusOutline extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 18 18">
                <circle
                    cx="9"
                    cy="9"
                    r="8"
                    fill="#ffffff"
                    strokeWidth="2"
                />
                <line
                    x1="4"
                    y1="9"
                    x2="14"
                    y2="9"
                    strokeWidth="2"
                />
                <line
                    x1="9"
                    y1="4"
                    x2="9"
                    y2="14"
                    strokeWidth="2"
                />
            </svg>
        );
    }
}

export default PlusOutline;
