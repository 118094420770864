
import React, { Component } from 'react';

class EmailDelete extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 60 60">
                <polygon fill="#feca57" points="0,5 0,44 28,44 56,44 56,5 " />
                <path fill="#f4b537" d="M30.965,27.107c-1.637,1.462-4.292,1.462-5.93,0l-2.087-1.843C16.419,31.091,0,44,0,44h21.607 h12.787H56c0,0-16.419-12.909-22.948-18.736L30.965,27.107z" />
                <path fill="#fcd386" d="M0,5l25.035,22.107c1.637,1.462,4.292,1.462,5.93,0L56,5H0z" />
                <circle fill="#ff6b6a" cx="46" cy="41" r="12" />
                <path fill="#FFFFFF" d="M47.414,41l3.536-3.536c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0L46,39.586
                    l-3.536-3.536c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L44.586,41l-3.536,3.536c-0.391,0.391-0.391,1.023,0,1.414
                    c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293L46,42.414l3.536,3.536c0.195,0.195,0.451,0.293,0.707,0.293
                    s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L47.414,41z"/>
            </svg>
        );
    }
}

export default EmailDelete;
