import React, { Component } from "react";
import PropTypes from "prop-types";
import "./SystemNodeStatus.scss";
import _ from "lodash";
import {
    NODE_STATUS_CLASS_NAME,
    NODE_STATUS_LABEL,
    NODE_STATUS_SHORT_LABEL,
    NODE_TYPE_SHORT_LABEL,
} from "../../../../constants/systemNodesConstants";
import { NODE_TYPE } from "../../../../constants/skillsConstants";
import { getClassNameByType } from "../../../../helper/nodeHelper";

class SystemNodeStatus extends Component {
    render() {
        return (
            <div className={this.getClassName(this.props.statusDisabled)} title={this.props.title || this.getTitle()}>
                {this.props.text || this.getText()}
            </div>
        );
    }

    getClassName(statusDisabled) {
        let className = "system-node-status";

        if (this.props.status && !statusDisabled) {
            className += " system-node-status-" + NODE_STATUS_CLASS_NAME[this.props.status];
        }

        if (this.props.size) {
            className += " system-node-status-size-" + this.props.size;
        }

        className += " " + (this.props.className || "");

        if (!statusDisabled) {
            return className;
        }

        return getClassNameByType(this.props.type , className);
    }


    getTitle() {
        if (this.props.status) {
            return _.get(this.props.translation, NODE_STATUS_LABEL[this.props.status]);
        }
    }

    getText() {
        if (this.props.type) {
            return _.get(this.props.translation, NODE_TYPE_SHORT_LABEL[this.props.type]);
        } else if (this.props.status) {
            return _.get(this.props.translation, NODE_STATUS_SHORT_LABEL[this.props.status]);
        }
    }
}

SystemNodeStatus.defaultProps = {
    size: "md",
    className: "",
};

SystemNodeStatus.propTypes = {
    translation: PropTypes.object.isRequired,
    className: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
    statusDisabled: PropTypes.bool,
};

export default SystemNodeStatus;
