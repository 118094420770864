import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import _ from "lodash";
import { NODE_TYPE, NODE_STATUS, NODE_SOURCE } from "../../constants/systemNodesConstants";

//- Actions
export const fetchGetSystemNodeInfoFailed = createAction("FETCH_GET_CURRENT_NODE_DATA_FAILED");
export const fetchGetSystemNodeInfoSuccess = createAction("FETCH_GET_CURRENT_NODE_DATA_SUCCESS");
export const updateCurrentNodeData = createAction("FETCH_UPDATE_CURRENT_NODE_DATA");
export const onFetchGetSystemNodeInfoSuccess = (data) => (dispatch) => {
    let formattedData = {
        ...data,
        id: data.id,
        title: data.titles.en,
        status: data.resourceStatus,
        source: data.resourceOwner,
        domain: data.domain ? data.domain : null,
        type: data.resourceType,
        synonyms: data.synonyms,
    };

    switch (formattedData.type) {
        case NODE_TYPE.SKILL:
            formattedData.cluster = data.category
                ? {
                    id: data.category.id,
                    title: data.category.titles.en,
                    source:
                        data.category.resourceOwner === NODE_SOURCE.HF1
                            ? NODE_SOURCE.REFERENCE_MODEL
                            : data.category.resourceOwner,
                    status: NODE_STATUS.REFERENCE_MODEL,
                    type: data.category.resourceType,
                }
                : null;
            formattedData.dependentSkills = data.dependentSkills ? data.dependentSkills : null;
            break;

        case NODE_TYPE.PROFILE:
            formattedData.description = data.description;
            break;

        default:
            break;
    }

    dispatch(fetchGetSystemNodeInfoSuccess(formattedData));
};

export const fetchAddSkillDependency = createAction(
    "FETCH_ADD_SKILL_TO_SKILL",
    (skillId, skillToAddId) => Api.addSkillDependency(skillId, skillToAddId),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSystemNodeInfoSuccess,
        onFail: fetchGetSystemNodeInfoFailed,
    }),
);
export const fetchGetSystemNodeInfo = createAction(
    "FETCH_GET_CURRENT_NODE_DATA",
    (nodeId, nodeType) => Api.getSystemNodeData(nodeId, nodeType),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSystemNodeInfoSuccess,
        onFail: fetchGetSystemNodeInfoFailed,
    }),
);

export const fetchRemoveSkillDependency = createAction(
    "FETCH_REMOVE_SKILL_RELATION",
    (skillId, skillToAddId) => Api.removeSkillDependency(skillId, skillToAddId),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSystemNodeInfoSuccess,
        onFail: fetchGetSystemNodeInfoFailed,
    }),
);

export const onFetchHandleNodeDomainListSuccess = (data) => (dispatch) => {
    dispatch(fetchGetSystemNodeInfoSuccess(data));
};

export const onFetchHandleNodeDomainListFailed = (data) => (dispatch) => {
    dispatch(fetchGetSystemNodeInfoFailed(data));
};

export const fetchHandleNodeDomainList = createAction(
    "FETCH_HANDLE_DOMAIN_NODE",
    (nodeId, domainTitle, isRemove, nodeType) => {
        if (nodeType === NODE_TYPE.CLUSTER || nodeType === NODE_TYPE.DOMAIN) {
            return Api.addDomainToCluster(nodeId, domainTitle, isRemove);
        } else if (nodeType === NODE_TYPE.PROFILE) {
            return Api.addDomainToProfile(nodeId, domainTitle, isRemove);
        }
    },
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSystemNodeInfoSuccess,
        onFail: onFetchHandleNodeDomainListFailed,
    }),
);

export const fetchGetSystemNodeGraphDataFailed = createAction(
    "FETCH_GET_CURRENT_NODE_GRAPH_DATA_FAILED",
);
export const fetchGetSystemNodeGraphDataSuccess = createAction(
    "FETCH_GET_CURRENT_NODE_GRAPH_DATA_SUCCESS",
);
export const onFetchGetSystemNodeGraphDataSuccess = (data) => (dispatch, getState) => {
    let editorData = [];
    let currentNodeId = getSystemCurrentNodeId(getState());

    let nodesData = _.map(data.nodes, (node) => {
        return {
            group: "nodes",
            data: {
                id: node.id,
                title: node.titles.en,
                // TODO: remove hardcoded values
                // source: node.resourceOwner === NODE_SOURCE.HF1 ? NODE_SOURCE.REFERENCE_MODEL : node.resourceOwner,
                status: NODE_STATUS.REFERENCE_MODEL,
                source: _.sample(["Admin", "User", "O*Net", "VDAB"]),
                type: node.resourceType,
            },
            classes: [_.toLower(node.resourceType)],
            selected: currentNodeId === node.id,
        };
    });

    let edgesData = _.map(data.edges, (edgeData) => {
        return {
            group: "edges",
            // TODO: approved or not
            data: edgeData,
            classes: ["edge"],
        };
    });

    editorData = _.concat(nodesData, edgesData);
    dispatch(fetchGetSystemNodeGraphDataSuccess(editorData));
};
export const fetchGetSystemNodeGraphData = createAction(
    "FETCH_GET_CURRENT_NODE_GRAPH_DATA",
    (nodeId) => Api.getSystemNodeGraphData(nodeId),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSystemNodeGraphDataSuccess,
        onFail: fetchGetSystemNodeGraphDataFailed,
    }),
);


export const onSetCurrentSystemNode = (nodeId, nodeType) => (dispatch) => {
    if (nodeId) {
        dispatch(graphNodeSelected(nodeId));
        dispatch(setCurrentSystemNode(nodeId));
        dispatch(fetchGetSystemNodeInfo(nodeId, nodeType));
        dispatch(fetchGetSystemNodeGraphData(nodeId));
    } else {
        dispatch(clearCurrentSystemNodeData());
    }
};

export const clearCurrentSystemNodeData = createAction("CLEAR_CURRENT_SYSTEM_NODE_DATA");
export const setCurrentSystemNode = createAction("SET_CURRENT_SYSTEM_NODE");
export const updateSelectNodeInGraph = createAction("UPDATE_SELECTED_NODE_IN_GRAPH");
export const graphNodeSelected = createAction("GRAPH_NODE_SELECTED");
export const dataUpdatingStarted = createAction("DATA_UPDATING_STARTED");

export const onSetCurrentNodeWithoutGraph = (nodeId, restype) => (dispatch, getState) => {
    if (nodeId !== null) {
        //dispatch(setCurrentSystemNode(nodeId));
        dispatch(fetchGetSystemNodeInfo(nodeId, restype));
        //dispatch(updateSelectNodeInGraph(nodeId));
    }

    dispatch(graphNodeSelected(nodeId));
};

export const fetchAddClusterToSkill = createAction(
    "FETCH_ADD_CLUSTER_TO_SKILL",
    (nodeId, clusterId) => Api.addClusterToSkill(nodeId, clusterId),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSystemNodeInfoSuccess,
        onFail: fetchGetSystemNodeInfoFailed,
    }),
);

export const fetchRemoveClusterFromSkill = createAction(
    "FETCH_REMOVE_CLUSTER_FROM_SKILL",
    (nodeId, clusterId) => Api.removeClusterFromSkill(nodeId, clusterId),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSystemNodeInfoSuccess,
        onFail: fetchGetSystemNodeInfoFailed,
    }),
);

export const fetchUpdateCurrentNode = createAction(
    "FETCH_UPDATE_CURRENT_NODE",
    (nodeId, nodeData) => Api.updateNode(nodeId, nodeData),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSystemNodeInfoSuccess,
        onFail: fetchGetSystemNodeInfoFailed,
    }),
);

export const fetchAddSynonymToNode = createAction(
    "FETCH_ADD_SYNONYM",
    (nodeId, language, value) => Api.addSynonym(nodeId, language, value),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSystemNodeInfoSuccess,
        onFail: fetchGetSystemNodeInfoFailed,
    }),
);

//- State
const initialState = {
    currentNodeId: null,
    currentNodeData: null,
    currentNodeGraphData: null,
    error: null,
    fetching: false,
    fetchingGraphData: false,
};

//- Reducers
export default handleActions(
    {
        FETCH_GET_CURRENT_NODE_DATA: (state) => {
            return { ...state, currentNodeData: null, error: null, saving: false, fetching: true };
        },
        FETCH_GET_CURRENT_NODE_DATA_FAILED: (state, action) => {
            return { ...state, currentNodeData: null, error: action.payload, saving: false, fetching: false };
        },
        FETCH_UPDATE_CURRENT_NODE_DATA: (state, action) => {
            return {
                ...state,
                currentNodeData: {
                    ...action.payload,
                    source: action.payload.resourceOwner,
                    type: action.payload.resourceType,
                    cluster: {
                        id: action.payload.category?.id,
                        title: action.payload.category?.titles.en,
                        source: action.payload.category?.resourceOwner,
                        status: action.payload.category?.resourceStatus,
                        type: action.payload.category?.resourceType,
                    },
                },
            };
        },

        UPDATE_SELECTED_NODE_IN_GRAPH: (state, action) => {
            return {
                ...state,
                currentNodeGraphData: state.currentNodeGraphData.map((node) => {
                    return node.data.id == action.payload
                        ? { ...node, selected: true }
                        : { ...node, selected: false };
                }),
            };
        },
        FETCH_GET_CURRENT_NODE_DATA_SUCCESS: (state, action) => {
            return {
                ...state,
                currentNodeData: {
                    source: action.payload.resourceOwner,
                    type: action.payload.resourceType,
                    ...action.payload,
                },
                error: null,
                saving: false,
                fetching: false,
            };
        },
        FETCH_GET_CURRENT_NODE_GRAPH_DATA: (state, action) => {
            return {
                ...state,
                currentNodeGraphData: null,
                error: null,
                fetchingGraphData: true,
            };
        },
        FETCH_GET_CURRENT_NODE_GRAPH_DATA_FAILED: (state, action) => {
            return {
                ...state,
                currentNodeGraphData: null,
                error: action.payload,
                fetchingGraphData: false,
            };
        },
        FETCH_GET_CURRENT_NODE_GRAPH_DATA_SUCCESS: (state, action) => {
            return {
                ...state,
                currentNodeGraphData: action.payload,
                error: null,
                saving: false,
                fetchingGraphData: false,
            };
        },
        SET_CURRENT_SYSTEM_NODE: (state, action) => {
            return { ...state, currentNodeId: action.payload };
        },
        GRAPH_NODE_SELECTED: (state, action) => {
            return { ...state, selectedGraphNode: action.payload };
        },
        CLEAR_CURRENT_SYSTEM_NODE_DATA: () => {
            return initialState;
        },
        DATA_UPDATING_STARTED: (state, action) => {
            return { ...state, saving: true };
        },
    },
    initialState,
);

//- Selectors
export const getSystemCurrentNodeState = (state) => state.systemCurrentNode;

export const getSystemCurrentNodeId = (state) => getSystemCurrentNodeState(state).currentNodeId;

export const getSystemCurrentNodeData = (state) => getSystemCurrentNodeState(state).currentNodeData;

export const getSystemCurrentNodeDataFetchingStatus = (state) =>
    getSystemCurrentNodeState(state).fetching;

export const getSystemCurrentNodeGraphData = (state) =>
    getSystemCurrentNodeState(state).currentNodeGraphData;

export const getSystemCurrentNodeGraphDataFetchingStatus = (state) =>
    getSystemCurrentNodeState(state).fetchingGraphData;

export const getSystemCurrentNodeTitles = (state) => getSystemCurrentNodeData(state)?.titles;

export const getSystemCurrentNodeParentCluster = (state) => {
    let data = getSystemCurrentNodeData(state);
    if (data) {
        return data.cluster;
    }
};

export const getCurrentNodeSynonyms = (state) => getSystemCurrentNodeData(state).synonyms;
