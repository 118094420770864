import { connect } from "react-redux";
import RequestStatus from "./RequestStatus";
import _ from "lodash";
import { getTranslation } from "../../../reducers/common/localization";
import { getBlackListError, getBlackListLoading } from "../../../reducers/jobCompass/blackList";
import {
	getJobCompassSettingsError,
	getJobCompassSettingsLoading
} from "../../../reducers/jobCompass/jobCompassSettings";
import {
	clearJobCompassError,
	getJobCompassProfilesError,
	getJobCompassProfilesLoading
} from "../../../reducers/jobCompass/jobCompassProfiles";
import { getSearchFutureProfilesError } from "../../../reducers/profiles/searchFutureProfiles";

const mapStateToProps = state => {
	const error = getBlackListError(state)
		|| getJobCompassProfilesError(state)
		|| getJobCompassSettingsError(state)
		|| getSearchFutureProfilesError(state);

	return {
		translation: getTranslation(state),
		loading: getBlackListLoading(state) || getJobCompassSettingsLoading(state) || getJobCompassProfilesLoading(state),
		success: false,
		error: !_.isEmpty(error),
		errorMessage: !_.isEmpty(error) ? error.message || error.statusText : null
	};
};


const mapDispatchToProps = dispatch => {
	return {
		onResetRequestStatus: () => dispatch(clearJobCompassError()),
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(RequestStatus);
