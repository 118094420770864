import { connect } from "react-redux";
import { getTranslation } from "../../../reducers/common/localization";
import InvitationsManagement from "./InvitationsManagement";
import {
    MODAL_INVITATIONS_SEND_FEEDBACK,
    MODAL_INVITATIONS_TOP_X_COMPARE,
    showModal,
} from "../../../reducers/common/activeModal";

import {
    handleInterviewedDate,
    fetchGetJobApplicants,
    getApplicantsAppliedToJob,
    fetchPutPrivateNodeJobApplicant,
    fetchPutHiredJobApplicant,
} from "../../../reducers/organization/organizations";

const mapStateToProps = (state) => ({
    translation: getTranslation(state),
    applicantsAppliedToJob: getApplicantsAppliedToJob(state),
});

const mapDispatchToProps = (dispatch) => ({
    onOpenSendFeedbackModal: () => {
        dispatch(showModal(MODAL_INVITATIONS_SEND_FEEDBACK));
    },
    onOpenTopXCompareModal: () => {
        dispatch(showModal(MODAL_INVITATIONS_TOP_X_COMPARE));
    },

    onMount: (data) => {
        dispatch(fetchGetJobApplicants(data));
    },

    onAddInterviewDate: (jobId, date) => dispatch(handleInterviewedDate(jobId, date, false)),
    onRemoveInterviewDate: (jobId, date) => dispatch(handleInterviewedDate(jobId, date, true)),

    onPutPrivateNote: (jobId, privateNote) =>
        dispatch(fetchPutPrivateNodeJobApplicant(jobId, privateNote)),

    onPutHiredJobApplicant: (jobId, isHired) => dispatch(fetchPutHiredJobApplicant(jobId, isHired)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationsManagement);
