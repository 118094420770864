import { connect } from "react-redux";
import {
    CREATE_NODE_MODAL,
    getActiveModalType,
    getNodeTypeToCreate,
    hideModal,
} from "../../../../reducers/common/activeModal";
import { fetchCreateNewNode } from "../../../../reducers/admin/systemModel";
import { getTranslation, getTranslationsList } from "../../../../reducers/common/localization";
import CreateNodeModal from "./createNodeModal";
import {
    fetchGetSkillClustersByDomain,
    getDomains,
    getSC,
} from "../../../../reducers/admin/domainsAndSC";
import { fetchGetSkillsNodesToAdd } from "../../../../reducers/admin/systemNodesToAdd";

const mapStateToProps = (state) => ({
    isOpen: getActiveModalType(state) === CREATE_NODE_MODAL,
    translation: getTranslation(state),
    nodeType: getNodeTypeToCreate(state),
    languageList: getTranslationsList(state),
    domainList: getDomains(state),
    skillClusterList: getSC(state),
});

const mapDispatchToProps = (dispatch) => ({
    onGetSkillClustersByDomain: (domainTitle) =>
        dispatch(fetchGetSkillClustersByDomain(domainTitle)),
    onClose: () => dispatch(hideModal(CREATE_NODE_MODAL)),
    onCreateNewNode: (node) => dispatch(fetchCreateNewNode(node)),
    onChooseSc: (skillClusterId) =>
        dispatch(
            fetchGetSkillsNodesToAdd(skillClusterId, {
                nodesType: "SKILL",
                related: true,
            })
        ),
    onChooseDomain: (domainTitle) => dispatch(fetchGetSkillClustersByDomain(domainTitle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateNodeModal);
