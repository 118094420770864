import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Label, Modal, ModalFooter, ModalBody } from 'reactstrap';
import _ from 'lodash';
import CustomSelect from '../../inputs/customSelect/CustomSelect';
import { SELECT_LEVELS_STYLES } from '../../../../constants/selectStyles';
import { LEVEL_OPTIONS, LEVEL_TRANSLATION_LABEL } from '../../../../constants/skillsConstants';

class SetQuickfindSkillLevelModal extends Component {
	render() {
		const {
			translation,
			isOpen,
			onClose,
			id,
			updateSelectedSkillProperty,
			level,
			setSelectedSkillId
		} = this.props;

		let options = LEVEL_OPTIONS.map((level) => {
			return {
				value: level,
				label: _.get(this.props.translation, `skill_properties.levels_values.${LEVEL_TRANSLATION_LABEL[level]}_with_short_range`)
			}
		});

		return (
			<Modal
				centered
				isOpen={isOpen}
				toggle={() => {
					onClose();
					setSelectedSkillId("");
				}}
			>
				<ModalBody>
					<Label>
						{_.get(translation, 'applicant_quickfind_page.skill_level_label')}
					</Label>
					<CustomSelect
						value={level ? _.find(options, option => option.value === level) : null}
						options={options}
						styles={SELECT_LEVELS_STYLES}
						placeholder={_.get(this.props.translation, 'skill_properties.level_select_placeholder')}
						isSearchable={false}
						menuPosition="fixed"
						onChange={(option) => {
							updateSelectedSkillProperty(id, option);
							onClose();
							setSelectedSkillId("");
						}}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						color="primary"
						onClick={() => {
							onClose();
							setSelectedSkillId("");
						}}
					>
						{_.get(translation, 'actions.cancel_button')}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

SetQuickfindSkillLevelModal.propTypes = {
	translation: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	updateSelectedSkillProperty: PropTypes.func.isRequired,
	setSelectedSkillId: PropTypes.func,
	level: PropTypes.number,
};

export default SetQuickfindSkillLevelModal;
