import { connect } from "react-redux";
import { getTranslation } from "../../../reducers/common/localization";
import {
    clean2FARequiredStatus,
    cleanLoginError,
    getIs2FARequired,
    getLoginError,
    getLoginFetchingStatus,
} from "../../../reducers/authorization/login";
import DFALoginModal from "./DFALoginModal";

const mapStateToProps = (state) => ({
    isOpen: getIs2FARequired(state),
    translation: getTranslation(state),
    fetching: getLoginFetchingStatus(state),
    error: getLoginError(state),
});
const mapDispatchToProps = (dispatch) => ({
    onCancel: () => {
        dispatch(clean2FARequiredStatus());
        dispatch(cleanLoginError());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DFALoginModal);
