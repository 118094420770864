import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './OpenJobDetails.scss';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Utils } from '../../../../utils/utils';
import { ROUTES } from '../../../../constants/constants';
import { SKILLS_MAP_MODE } from '../../../common/skills/skillsMap/SkillsMap';
import SkillsMap from '../../../common/skills/skillsMap/SkillsMap.container';
import Legend from '../../../common/skills/legend/Legend.container';
import { JOB_IMAGE_PLACEHOLDER } from '../../../../constants/imagesConstants';

class OpenJobDetails extends Component {
    componentDidMount() {
        const jobId = Utils.getParamsFromLocationSearch(this.props.location.search).id;

        if (jobId) {
            this.props.onMount(jobId);
        } else {
            this.props.history.push(ROUTES.OPEN_JOBS);
        }
    }

    render() {
        return (
            <div className="open-job-details">
                <h4 className="page-header">{_.get(this.props.translation, "open_job_details_page.page_label")}</h4>
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        if (this.props.loading) {
            return (
                <h6 className="text-center">{_.get(this.props.translation, "actions.fetching_message")}</h6>
            )
        }

        if (this.props.data) {
            return (
                <>
                    <h6 className="page-subheader">
                        {_.get(this.props.translation, "shared.title_label")}
                        {": "}
                        {this.props.data.title}
                    </h6>
                    {/* TODO: remove hardcoded values */}
                    <div>Job Common info</div>
                    <Legend />
                    <SkillsMap
                        skillsTree={this.props.skillsTree}
                        mode={SKILLS_MAP_MODE.JOB_VIEW}
                        imageSource={JOB_IMAGE_PLACEHOLDER}
                    />
                </>
            )
        } else {
            return null;
        }
    }
}

OpenJobDetails.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.object,
    skillsTree: PropTypes.array,
    loading: PropTypes.bool,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onMount: PropTypes.func.isRequired,
}

export default withRouter(OpenJobDetails);
