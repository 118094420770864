import { connect } from 'react-redux';
import BackLink from "./BackLink";
import { getTranslation } from "../../../../reducers/common/localization";

const mapStateToProps = state => {
    return {
        translation: getTranslation(state)
    };
};

export default connect(mapStateToProps)(BackLink);