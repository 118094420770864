import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './JobCompass.scss';
import _ from 'lodash';
import UserPhoto from '../../common/userPhoto/UserPhoto.container';
import { USER_PHOTO_MODE } from '../../common/userPhoto/UserPhoto';
import Icon from '../../common/icon/Icon';
import {
    UncontrolledPopover, PopoverBody, ListGroup, ListGroupItem,
    Col, Input, InputGroup, InputGroupAddon,
    Button, CustomInput, Badge
} from 'reactstrap';
import { JOB_IMAGE_PLACEHOLDER } from '../../../constants/imagesConstants';
import { NODE_DOMAIN_LIST, NODE_DOMAIN_LABEL, NODE_DOMAIN_DESCRIPTION } from '../../../constants/systemNodesConstants';
import { Utils } from '../../../utils/utils';
import AlternativeNowBlackListModal from './alternativeNowBlackListModal/AlternativeNowBlackListModal.container';
import RequestStatus from "../../common/requestStatus/JobCompassRequestStatus.container";

const JOB_COMPASS_SIZE = 900;
const USER_PHOTO_SIZE = 120;
const MAX_TOTAL = 99;
const PROFILE_WIDTH = 180;
const PROFILE_HEIGHT = 56;
const SKILL_WIDTH = 120;
const SKILL_HEIGHT = 32;
const ALTERNATIVE_CIRCLE_RADIUS = 230;

const ALTERNATIVE_JOB_ANGLE_BY_NUMBER = {
    1: [180],
    2: [135, 225],
    3: [180, 135, 225],
    4: [135, 225, 90, 270],
    5: [180, 90, 270, 135, 225],
};

const FUTURE_JOB_ANGLE_BY_NUMBER = {
    1: [0],
    2: [30, 330],
    3: [0, 45, 315],
    4: [20, 340, 60, 300],
    5: [0, 35, 325, 65, 295],
};

const FUTURE_JOB_SKILLS_POSITION_BY_JOBS_AND_SKILLS_NUMBER = {
    1: {
        1: [[0.6]],
        2: [
            [0.42, 0.73],
        ]
    },
    2: {
        1: [[0.6], [0.6]],
        2: [
            [0.33, 0.66],
            [0.33, 0.66],
        ]
    },
    3: {
        1: [[0.6], [0.6], [0.6]],
        2: [
            [0.42, 0.73],
            [0.33, 0.66],
            [0.33, 0.66],
        ]
    },
    4: {
        1: [[0.6], [0.6], [0.6], [0.6]],
        2: [
            [0.45, 0.73],
            [0.45, 0.73],
            [0.33, 0.66],
            [0.33, 0.66],
        ]
    },
    5: {
        1: [[0.6], [0.6], [0.6], [0.5], [0.5]],
        2: [
            [0.42, 0.73],
            [0.38, 0.66],
            [0.38, 0.66],
            [0.33, 0.66],
            [0.33, 0.66],
        ]
    },
};

class JobCompass extends Component {
    constructor(props) {
        super(props);

        this.state = {
            domains: [],
            matchingNumber: 0,
            findJobsByPreferences: false,
            shareWithFutureEmployees: false,
            futureJobsSearchValue: ""
        };

        this.compassNeedleRef = React.createRef();
        this.angle = 0;
        this.prevAngle = 0;
    }

    componentDidMount() {
        this.props.onGetJobCompassSettings();
        this.props.onGetBlackList();
        this.props.onGetAlternativeProfiles();
        this.props.onGetCurrentProfiles();
        this.props.onGetSelectedFutureProfiles();
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.jobCompassSettings, this.props.jobCompassSettings)) {
            this.setState({
                domains: this.props.jobCompassSettings.domains,
                matchingNumber: this.props.jobCompassSettings.matchingNumber,
                findJobsByPreferences: this.props.jobCompassSettings.findJobsByPreferences,
                shareWithFutureEmployees: this.props.jobCompassSettings.shareWithFutureEmployees,
            });
        }
    }

    handleMouseEnterToProfile = (angle) => (e) => {
        let elem = this.compassNeedleRef.current;
        let delta = this.prevAngle - angle;

        if (Math.abs(delta) >= 180) {
            this.angle += Math.sign(delta) * (360 - Math.abs(delta));
        } else {
            this.angle -= delta;
        }
        this.prevAngle = angle;

        elem.style.transform = `rotate(${this.angle}deg)`;
    };

    render() {
        return (
          <div className="job-compass">
              <RequestStatus />
              <h4 className="page-header">{_.get(this.props.translation, "job_compass_page.page_label")}</h4>
              {this.renderContent()}
              <AlternativeNowBlackListModal />
          </div>
        );
    }

    renderContent() {
        return (
            <div className="jc-content-container">
                <Col className="left-col">
                    <h6 className="text-center text-bold">{_.get(this.props.translation, "job_compass_page.alternative_now_setting_label")}</h6>
                    <Button
                      color="primary"
                      onClick={() => this.props.onSetJobCompassSettings({
                          domains: this.state.domains,
                          matchingNumber: this.state.matchingNumber,
                          findJobsByPreferences: this.state.findJobsByPreferences,
                          shareWithFutureEmployees: this.state.shareWithFutureEmployees,
                      })}
                    >
                        {_.get(this.props.translation, "actions.save_button")}
                    </Button>
                    <div className="jc-menu alternative-settings">
                        {this.renderDomainsSelect()}
                        <div className="jc-menu-divider" />
                        {this.renderAlternativeNowMatching()}
                        <div className="jc-menu-divider" />
                        {this.renderAlternativeNowBlackListInfo()}
                        <div className="jc-menu-divider" />
                        <div className="jc-menu common-settings">
                            {this.renderFindJobsByPreferencesCheckbox()}
                            <div className="jc-menu-divider" />
                            {this.renderShareDataSelect()}
                        </div>
                    </div>
                </Col>
                <Col sm="auto" className="job-compass-col">
                    {this.renderJobCompassComponent()}
                </Col>
                <Col className="right-col">
                    <h6 className="text-center text-bold">{_.get(this.props.translation, "job_compass_page.possible_future_settings_label")}</h6>
                    {this.renderPossibleFutureJobsMenu()}
                </Col>
            </div>
        )
    }

    renderJobCompassComponent() {
        return (
            <svg className="job-compass-component" viewBox="-30 -10 960 910">
                <path className="jc-background" d="M450,875C215.28,875,25,684.72,25,450S215.28,25,450,25,875,215.28,875,450,684.72,875,450,875Z" />
                <g>
                    <path className="jc-border" d="M450,0C201.47,0,0,201.47,0,450S201.47,900,450,900,900,698.53,900,450,698.53,0,450,0Zm0,843.06c-217.08,0-393.06-176-393.06-393.06S232.92,56.94,450,56.94,843.06,232.92,843.06,450,667.08,843.06,450,843.06Z" />
                    <path className="jc-border-shadow" d="M450,33C219.68,33,33,219.68,33,450S219.68,867,450,867,867,680.32,867,450,680.32,33,450,33Zm0,810.09c-217.08,0-393.06-176-393.06-393.06S232.92,56.94,450,56.94,843.06,232.92,843.06,450,667.08,843.06,450,843.06Z" />
                    <path className="jc-border-inside-shadow" d="M450,50C229.09,50,50,229.09,50,450S229.09,850,450,850,850,670.91,850,450,670.91,50,450,50Zm0,772.53C244.26,822.53,77.47,655.74,77.47,450S244.26,77.47,450,77.47,822.53,244.26,822.53,450,655.74,822.53,450,822.53Z" />
                </g>
                <path className="jc-inside-circle" d="M450,200c-138.07,0-250,111.93-250,250S311.93,700,450,700,700,588.07,700,450,588.07,200,450,200Zm0,461.54c-116.83,0-211.54-94.71-211.54-211.54S333.17,238.46,450,238.46,661.54,333.17,661.54,450,566.83,661.54,450,661.54Z" />
                <g className="jc-small-arrows">
                    <g className="jc-arrows-dark-sides">
                        <path d="M496,404,625,625,404.9,495.1,275,275Z" />
                        <path d="M450,377.69,625,275,522.31,450H450V377.69" />
                        <path d="M450,522.31,275,625,377.69,450H450v72.31" />
                    </g>
                    <g className="jc-arrows-light-sides">
                        <path d="M522.31,450,625,625h0L450,450Z" />
                        <path d="M377.69,450,275,275h0L450,450Z" />
                        <path d="M450,522.31,275,625h0L450,450Z" />
                        <path d="M450,377.69,625,275h0L450,450Z" />
                    </g>
                </g>
                <g className="jc-big-arrows">
                    <g className="jc-arrows-dark-sides">
                        <path d="M450,496.71,87.5,450,450,403.29,812.5,450Z" />
                        <path d="M491.38,491.38,450,812.5,408.62,491.38,450,450l41.38,41.38" />
                        <path d="M408.62,408.62,450,87.5l41.38,321.12L450,450l-41.38-41.38" />
                    </g>
                    <g className="jc-arrows-light-sides">
                        <path d="M408.62,491.38,87.5,450H450Z" />
                        <path d="M491.38,408.62,812.5,450H450Z" />
                        <path d="M408.62,408.62,450,87.5V450Z" />
                        <path d="M491.38,491.38,450,812.5V450Z" />
                    </g>
                </g>
                {this.props.selectedFutureProfiles.length && this.renderFutureJobsConnectors()}
                {this.props.alternativeProfiles.length && this.renderAlternativeJobsConnectors()}
                {this.renderNeedle()}
                {this.props.alternativeProfiles.length && this.renderAlternativeJobs()}
                {this.props.selectedFutureProfiles.length && this.renderFutureJobs()}
                {this.renderUserPhoto()}
                {this.props.currentProfiles.length && this.renderCurrentJob()}
            </svg>
        )
    }

    renderNeedle() {
        return (
            <g className="jc-needle" ref={this.compassNeedleRef}>
                <path className="jc-needle-north-side" d="M407.5,450,450,770l42.5-320L450,130Z" />
                <path className="jc-needle-south-side" d="M407.5,450h85L450,770Z" />
                <path className="jc-needle-shadow" d="M450,770V130l42.5,320Z" />
                <path className="jc-needle-point" d="M472.5,450A22.5,22.5,0,1,1,450,427.37,22.56,22.56,0,0,1,472.5,450Z" />
            </g>
        )
    }

    renderUserPhoto() {
        return (
            <g>
                <foreignObject
                    height={USER_PHOTO_SIZE}
                    width={USER_PHOTO_SIZE}
                    x={JOB_COMPASS_SIZE / 2 - USER_PHOTO_SIZE / 2}
                    y={JOB_COMPASS_SIZE / 2 - USER_PHOTO_SIZE / 2}
                >
                    <UserPhoto
                        mode={USER_PHOTO_MODE.VIEW}
                        userId={this.props.userId}
                        imageSource={this.props.imageSource}
                        size="lg"
                    />
                </foreignObject>
            </g>
        )
    }

    renderCurrentJob() {
        let title = this.props.currentProfiles[0].profile.titles.en;
        let total = this.props.currentProfiles[0].jobs.length;

        return (
            <g>
                <foreignObject
                    height={PROFILE_HEIGHT}
                    width={PROFILE_WIDTH}
                    x={JOB_COMPASS_SIZE / 2 - PROFILE_WIDTH / 2}
                    y={JOB_COMPASS_SIZE / 2 + 35}
                >
                    <div className="jc-profile-wrapper">
                        <div className="jc-profile current" id="current-job" title={title}>
                            <div className="jc-profile-title">
                                {title}
                            </div>
                            <div
                                className="jc-profile-jobs-total"
                                title={_.replace(_.get(this.props.translation, 'job_compass_page.available_jobs_number_label'), "{jobsNumber}", total)}
                            >
                                {total > MAX_TOTAL ? MAX_TOTAL + "+" : total}
                            </div>
                        </div>
                    </div>
                </foreignObject>
                {this.renderJobsPopover(this.props.currentProfiles[0].jobs, "current-job")}
            </g>
        )
    }

    renderAlternativeJobs() {
        const length = this.props.alternativeProfiles.length;
        return (
            <g>
                {
                    _.map(this.props.alternativeProfiles, (item, index) => {
                        let angle = ALTERNATIVE_JOB_ANGLE_BY_NUMBER[length][index];
                        let jobPoint = {
                            x: Math.cos((angle - 90) * Math.PI / 180) * ALTERNATIVE_CIRCLE_RADIUS,
                            y: Math.sin((angle - 90) * Math.PI / 180) * ALTERNATIVE_CIRCLE_RADIUS
                        };
                        return (
                            <g key={item.profile.id}>
                                <foreignObject
                                    height={PROFILE_HEIGHT}
                                    width={PROFILE_WIDTH}
                                    x={JOB_COMPASS_SIZE / 2 + jobPoint.x - PROFILE_WIDTH / 2}
                                    y={JOB_COMPASS_SIZE / 2 + jobPoint.y - PROFILE_HEIGHT / 2}
                                    key={item.profile.id}
                                >
                                    <div className="jc-profile-wrapper">
                                        <div
                                          className="jc-profile alternative"
                                          onMouseEnter={this.handleMouseEnterToProfile(angle)}
                                          id={"alt-job-" + item.profile.id}
                                          title={item.profile.titles.en}
                                        >
                                            <div className="jc-profile-title">
                                                {item.profile.titles.en}
                                            </div>
                                            <div
                                                className="jc-profile-jobs-total"
                                                title={_.replace(_.get(this.props.translation, 'job_compass_page.available_jobs_number_label'), "{jobsNumber}", item.jobs.length)}
                                            >
                                                {item.jobs.length > MAX_TOTAL ? MAX_TOTAL + "+" : item.jobs.length}
                                            </div>
                                            <Icon
                                                className="jc-profile-remove-icon"
                                                type="minus_outline"
                                                size="sm"
                                                color="danger"
                                                title={_.get(this.props.translation, "job_compass_page.remove_alternative_profile_label")}
                                                onClick={this.onHideAlternativeJob(item.profile.id)}
                                            />
                                        </div>
                                    </div>
                                </foreignObject>
                                {this.renderJobsPopover(item.jobs, "alt-job-" + item.profile.id)}
                            </g>
                        )
                    })
                }
            </g>
        )
    }

    renderFutureJobs() {
        const profilesLength = this.props.selectedFutureProfiles.length;
        return (
            <g>
                {
                    _.map(this.props.selectedFutureProfiles, (item, index) => {
                        let jobsCircleRadius = JOB_COMPASS_SIZE / 2 - 25;
                        let angle = FUTURE_JOB_ANGLE_BY_NUMBER[profilesLength][index];
                        let jobPoint = {
                            x: Math.cos((angle - 90) * Math.PI / 180) * jobsCircleRadius,
                            y: Math.sin((angle - 90) * Math.PI / 180) * jobsCircleRadius
                        };

                        return (
                            <g key={item.profile.profile.id}>
                                <foreignObject
                                    height={PROFILE_HEIGHT}
                                    width={PROFILE_WIDTH}
                                    x={JOB_COMPASS_SIZE / 2 + jobPoint.x - PROFILE_WIDTH / 2}
                                    y={JOB_COMPASS_SIZE / 2 + jobPoint.y - PROFILE_HEIGHT / 2}
                                >
                                    <div className="jc-profile-wrapper">
                                        <div className="jc-profile future" onMouseEnter={this.handleMouseEnterToProfile(angle)} id={"future-job-" + item.profile.profile.id} title={item.profile.profile.titles.en} >
                                            <div className="jc-profile-title">
                                                {item.profile.profile.titles.en}
                                            </div>
                                            <div
                                                className="jc-profile-jobs-total"
                                                title={_.replace(_.get(this.props.translation, 'job_compass_page.available_jobs_number_label'), "{jobsNumber}", item.jobs.length)}
                                            >
                                                {item.jobs.length > MAX_TOTAL ? MAX_TOTAL + "+" : item.jobs.length}
                                            </div>
                                            <Icon
                                                className="jc-profile-remove-icon"
                                                type="minus_outline"
                                                size="sm"
                                                color="danger"
                                                title={_.get(this.props.translation, "job_compass_page.remove_future_profile_label")}
                                                onClick={this.onHideFutureJob(item.profile.profile.id)}
                                            />
                                            <Icon
                                                className="jc-profile-compare-icon"
                                                type="right_arrow_outlined"
                                                size="sm"
                                                color="info"
                                                title={_.get(this.props.translation, "job_compass_page.compare_profile_label")}
                                            // onClick={this.onCompareWithFutureProfile(job.profileId)}
                                            />
                                        </div>
                                    </div>
                                </foreignObject>
                                {
                                    _.map(item.profile.missingSkills, (skill, skillIndex) => {
                                        let skillsNumber = item.profile.missingSkills.length;
                                        let skillPoint = {
                                            x: jobPoint.x * FUTURE_JOB_SKILLS_POSITION_BY_JOBS_AND_SKILLS_NUMBER[profilesLength][skillsNumber][index][skillIndex],
                                            y: jobPoint.y * FUTURE_JOB_SKILLS_POSITION_BY_JOBS_AND_SKILLS_NUMBER[profilesLength][skillsNumber][index][skillIndex],
                                        };
                                        return (
                                            <foreignObject
                                                height={SKILL_HEIGHT}
                                                width={SKILL_WIDTH}
                                                x={JOB_COMPASS_SIZE / 2 + skillPoint.x - SKILL_WIDTH / 2}
                                                y={JOB_COMPASS_SIZE / 2 + skillPoint.y - SKILL_HEIGHT / 2}
                                                key={skillIndex}
                                            >
                                                <div className="jc-skill-wrapper">
                                                    <div className={`jc-skill ${skill.isBroadening ? "broadening" : ""}`}>
                                                        <div className="jc-skill-title" title={skill.titles.en}>
                                                            {skill.titles.en}
                                                        </div>
                                                    </div>
                                                </div>
                                            </foreignObject>
                                        )
                                    })
                                }
                                {this.renderJobsPopover(item.job, "future-job-" + item.profile.profile.id)}
                            </g>
                        )
                    })
                }
            </g>
        )
    }

    renderAlternativeJobsConnectors() {
        const length = this.props.alternativeProfiles.length;
        return (
            <g>
                {
                    _.map(this.props.alternativeProfiles, (item, index) => {
                        let angle = ALTERNATIVE_JOB_ANGLE_BY_NUMBER[length][index];
                        let jobPoint = {
                            x: Math.cos((angle - 90) * Math.PI / 180) * ALTERNATIVE_CIRCLE_RADIUS,
                            y: Math.sin((angle - 90) * Math.PI / 180) * ALTERNATIVE_CIRCLE_RADIUS
                        };
                        let point = { x: JOB_COMPASS_SIZE / 2 + jobPoint.x, y: JOB_COMPASS_SIZE / 2 + jobPoint.y };
                        let fromPoint = { x: JOB_COMPASS_SIZE / 2, y: JOB_COMPASS_SIZE / 2 };
                        return (
                            <path
                                key={`${point.x}_${point.y}`}
                                className="jc-connector alternative-job"
                                d={`M ${fromPoint.x} ${fromPoint.y} L ${point.x} ${point.y}`}
                                fill="none"
                                strokeWidth="4"
                            />
                        )
                    })
                }
            </g>
        )
    }

    renderFutureJobsConnectors() {
        const profilesLength = this.props.selectedFutureProfiles.length;
        return (
            <g>
                {
                    _.map(this.props.selectedFutureProfiles, (item, index) => {
                        let jobsCircleRadius = JOB_COMPASS_SIZE / 2 - 25;
                        let angle = FUTURE_JOB_ANGLE_BY_NUMBER[profilesLength][index];
                        let jobPoint = {
                            x: Math.cos((angle - 90) * Math.PI / 180) * jobsCircleRadius,
                            y: Math.sin((angle - 90) * Math.PI / 180) * jobsCircleRadius
                        };
                        let point = { x: JOB_COMPASS_SIZE / 2 + jobPoint.x, y: JOB_COMPASS_SIZE / 2 + jobPoint.y };
                        let fromPoint = { x: JOB_COMPASS_SIZE / 2, y: JOB_COMPASS_SIZE / 2 };

                        return (
                            <path
                                key={`${point.x}_${point.y}`}
                                className="jc-connector future-job"
                                d={`M ${fromPoint.x} ${fromPoint.y} L ${point.x} ${point.y}`}
                                fill="none"
                                strokeWidth="4"
                            />
                        )
                    })
                }
            </g>
        )
    }

    renderJobsPopover(data, target) { // todo: needs to fix
        if (!data || data.length === 0) {
            return null;
        }
        return (
            <UncontrolledPopover
                target={target}
                placement="top"
                trigger="hover"
                boundariesElement="window"
                className="profile-jobs-popover"
            >
                <PopoverBody>
                    <ListGroup className="jobs-items-container" flush>
                        {this.getJobsListGroupItems(data)}
                    </ListGroup>
                </PopoverBody>
            </UncontrolledPopover>
        )
    }

    getJobsListGroupItems(data) {
        return _.map(data, (job) => {
            return (
                <ListGroupItem key={job.id}>
                    <div className="item-content-container">
                        <UserPhoto
                            // TODO: add correct imageSource
                            imageSource={JOB_IMAGE_PLACEHOLDER}
                            size="sm"
                        />
                        <div className="text-container">
                            <h6>{job.title}</h6>
                            <div>{job.companyName + ", " + _.get(this.props.translation, `countries.${job.countryCode}`)}</div>
                        </div>
                    </div>
                    {/* TODO: add onClick handler */}
                    <Icon
                        type="right_arrow_outlined"
                        color="info"
                        onClick={() => { }}
                        title={_.get(this.props.translation, "job.job_details_label")}
                    />
                </ListGroupItem>
            )
        })
    }

    renderDomainsSelect() {
        return (
            <div className="domains-select">
                <h6 className="text-center">{_.get(this.props.translation, "job_compass_page.domains_select_label")}</h6>
                <div className="domains-items-container">
                    {
                        _.map(NODE_DOMAIN_LIST, (domain, id) => {
                            let isSelected = _.includes(this.state.domains, domain);
                            return (
                                <div
                                    key={id}
                                    className={`domain-item ${isSelected ? "selected" : ""}`}
                                    title={_.get(this.props.translation, NODE_DOMAIN_LABEL[domain]) + ": " + _.get(this.props.translation, NODE_DOMAIN_DESCRIPTION[domain])}
                                    onClick={this.onDomainClick(domain, isSelected)}
                                >
                                    {_.get(this.props.translation, NODE_DOMAIN_LABEL[domain])}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    renderAlternativeNowMatching() {
        return (
            <div className="alternative-now-matching">
                <h6 className="text-center text-pre-wrap">
                    {Utils.getTagsFromStringWithStyles(_.get(this.props.translation, "job_compass_page.alternative_now_matching_label"))}
                </h6>
                <Input
                    className="alt-job-matching-input"
                    value={this.state.matchingNumber}
                    type="range"
                    step="5"
                    onChange={(e) => this.setState({ matchingNumber: +e.target.value })}
                />
                <h6 className="alt-job-matching-value">
                    <div>{`${this.state.matchingNumber}%`}</div>
                </h6>
            </div>
        )
    }

    renderAlternativeNowBlackListInfo() {
        return (
            <div className="alternative-now-black-list-info">
                <h6>
                    {
                        this.props.blackList && this.props.blackList.length > 0 ?
                            _.replace(_.get(this.props.translation, 'job_compass_page.hidden_alternative_profiles_number_label'), "{profilesNumber}", this.props.blackList.length)
                            :
                            _.get(this.props.translation, "job_compass_page.no_hidden_alternative_profiles_label")
                    }
                </h6>
                <Icon
                    type="right_arrow_outlined"
                    size="sm"
                    color="info"
                    title={_.get(this.props.translation, "actions.show_details_label")}
                    onClick={this.onShowAlternativeNowBlackList}
                />
            </div>
        )
    }

    renderPossibleFutureJobsMenu() {
        return (
            <div className="jc-menu possible-future-jobs-menu">
                <h6 className="text-center">{_.get(this.props.translation, "job_compass_page.discover_jobs_label")}</h6>
                <InputGroup className="search-future-profiles-input-group">
                    <Input
                        placeholder={_.get(this.props.translation, 'job_compass_page.discover_jobs_placeholder')}
                        value={this.state.futureJobsSearchValue}
                        onChange={this.handleChangeField("futureJobsSearchValue")}
                        onKeyPress={this.onFutureJobsSearchKeyPress}
                    />
                    <InputGroupAddon addonType="append">
                        <Button
                            className="icon-button"
                            onClick={this.onSubmitFutureJobsSearch}
                            title={_.get(this.props.translation, 'actions.search_label')}
                        >
                            <Icon type="search" color="light" />
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
                <div className="possible-future-jobs-list">
                    {this.renderProfilesSearchResults()}
                </div>
                <h6 className="text-center">{_.get(this.props.translation, "job_compass_page.skills_legend_label")}</h6>
                <div className="legend-container">
                    <div className="jc-skill" id="legend-deepening-skill">
                        <div className="jc-skill-title">
                            {_.get(this.props.translation, "job_compass_page.deepening_skill_label")}
                        </div>
                    </div>
                    <div className="jc-skill broadening" id="legend-broadening-skill">
                        <div className="jc-skill-title">
                            {_.get(this.props.translation, "job_compass_page.broadening_skill_label")}
                        </div>
                    </div>
                </div>
                <UncontrolledPopover
                    target="legend-deepening-skill"
                    placement="top"
                    trigger="hover"
                    boundariesElement="window"
                    className="jc-skills-legend-popover"
                >
                    <PopoverBody>
                        {_.get(this.props.translation, "job_compass_page.deepening_skill_description")}
                    </PopoverBody>
                </UncontrolledPopover>
                <UncontrolledPopover
                    target="legend-broadening-skill"
                    placement="top"
                    trigger="hover"
                    boundariesElement="window"
                    className="jc-skills-legend-popover"
                >
                    <PopoverBody>
                        {_.get(this.props.translation, "job_compass_page.broadening_skill_description")}
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
        )
    }

    renderFindJobsByPreferencesCheckbox() {
        return (
            <div className="find-jobs-by-preferences">
                <h6 className="text-center">{_.get(this.props.translation, "job_compass_page.find_jobs_by_preferences_label")}</h6>
                <div className="find-jobs-by-preferences-switch-container">
                    <h6>{_.get(this.props.translation, "shared.no_label")}</h6>
                    <CustomInput
                        id="find-jobs-by-preferences-switch"
                        type="switch"
                        checked={this.state.findJobsByPreferences}
                        value={this.state.findJobsByPreferences}
                        onChange={this.handleChangeFlag("findJobsByPreferences")}
                    />
                    <h6>{_.get(this.props.translation, "shared.yes_label")}</h6>
                </div>
            </div>
        )
    }

    renderShareDataSelect() {
        return (
            <div className="share-data-select">
                <h6 className="text-center">
                    {Utils.getTagsFromStringWithStyles(_.get(this.props.translation, "job_compass_page.share_possible_future_data_label"))}
                </h6>
                <div className="share-data-switch-container">
                    <h6>{_.get(this.props.translation, "shared.no_label")}</h6>
                    <CustomInput
                        id="share-data-switch"
                        type="switch"
                        checked={this.state.shareWithFutureEmployees}
                        value={this.state.shareWithFutureEmployees}
                        onChange={this.handleChangeFlag("shareWithFutureEmployees")}
                    />
                    <h6>{_.get(this.props.translation, "shared.yes_label")}</h6>
                </div>
            </div>
        )
    }

    renderProfilesSearchResults() {
        if (this.props.loadingSearchProfiles) {
            return <div className="text-center">{_.get(this.props.translation, 'actions.fetching_message')}</div>
        }

        let isMaxFutureJob = this.props.selectedFutureProfiles && this.props.selectedFutureProfiles.length === 5;

        return (
            <ListGroup className="search-profile-items-container" flush>
                {
                    _.map(this.props.searchProfiles, (profile) => {
                        return (
                            <ListGroupItem key={profile.id} className="search-profile-item">
                                <div className="profile-data-container" title={profile.description}>
                                    <h6>{profile.title}</h6>
                                    <div className="profile-badges-container">
                                        <Badge className="skills-number-badge" color="light">
                                            {Utils.getTagsFromStringWithStyles(
                                                _.replace(_.get(this.props.translation, 'job_compass_page.missing_skills_label'), "{skillsNumber}", profile.missingSkillsNumber)
                                            )}
                                        </Badge>
                                        <Badge className="jobs-number-badge" color="light">
                                            {Utils.getTagsFromStringWithStyles(
                                                _.replace(_.get(this.props.translation, 'job_compass_page.available_jobs_number_short_label'), "{jobsNumber}", profile.jobsNumber)
                                            )}
                                        </Badge>
                                    </div>
                                </div>
                                <div className="profile-icons">
                                    {
                                        this.props.selectedFutureProfiles && this.props.selectedFutureProfiles.find((elem) => elem.profile.profile.id === profile.id) ?
                                            null
                                            :
                                            <Icon
                                                className="future-profile-add-icon"
                                                type="plus_outline"
                                                color={isMaxFutureJob ? "secondary" : "success"}
                                                size="sm"
                                                title={
                                                    isMaxFutureJob ?
                                                        _.get(this.props.translation, 'job_compass_page.max_future_profile_label')
                                                        :
                                                        _.get(this.props.translation, 'job_compass_page.add_profile_label')
                                                }
                                                onClick={!isMaxFutureJob ? this.onAddFutureJob(profile.id) : null}
                                            />
                                    }
                                    <Icon
                                        className="future-profile-compare-icon"
                                        type="right_arrow_outlined"
                                        size="sm"
                                        color="info"
                                        title={_.get(this.props.translation, "job_compass_page.compare_profile_label")}
                                    // onClick={this.onHideFutureJob(job.profileId)}
                                    />
                                </div>
                            </ListGroupItem>
                        )
                    })
                }
            </ListGroup>
        )
    }

    onFutureJobsSearchKeyPress = (e) => {
        if (e.key === "Enter") {
            this.props.onGetSearchProfiles(this.state.futureJobsSearchValue);
        }
    };

    onSubmitFutureJobsSearch = () => {
        this.props.onGetSearchProfiles(this.state.futureJobsSearchValue);
    };

    handleChangeField = (fieldName) => (e) => {
        this.setState({ [fieldName]: e.target.value });
    };

    handleChangeFlag = (fieldName) => () => {
        this.setState({ [fieldName]: !this.state[fieldName] })
    };

    onDomainClick = (domain, isSelected) => () => {
        let data = _.cloneDeep(this.state.domains);
        if (isSelected) {
            data = _.filter(data, (elem) => elem !== domain);
        } else {
            data.push(domain);
        }

        this.setState({ domains: data });
    };

    onHideAlternativeJob = (profileId) => () => {
        this.props.onAddProfileToBlackList(profileId);
    };

    onHideFutureJob = (profileId) => () => {
        this.props.onRemoveProfileFromFutureProfiles(profileId);
    };

    onAddFutureJob = (profileId) => () => {
        this.props.onAddFutureProfile(profileId);
    };

    onShowAlternativeNowBlackList = () => {
        this.props.onShowAlternativeNowBlackList();
    }

}

JobCompass.propTypes = {
    translation: PropTypes.object.isRequired,
    onGetSearchProfiles: PropTypes.func.isRequired,
    onShowAlternativeNowBlackList: PropTypes.func.isRequired,
    onGetJobCompassSettings: PropTypes.func.isRequired,
    onSetJobCompassSettings: PropTypes.func.isRequired,
    onGetBlackList: PropTypes.func.isRequired,
    onAddProfileToBlackList: PropTypes.func.isRequired,
    onGetAlternativeProfiles: PropTypes.func.isRequired,
    onAddFutureProfile: PropTypes.func.isRequired,
    onGetCurrentProfiles: PropTypes.func.isRequired,
    onGetSelectedFutureProfiles: PropTypes.func.isRequired,
    onRemoveProfileFromFutureProfiles: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    jobCompassSettings: PropTypes.object.isRequired,
    blackList: PropTypes.array.isRequired,
    alternativeProfiles: PropTypes.array.isRequired,
    selectedFutureProfiles: PropTypes.array.isRequired,
};

export default JobCompass;
