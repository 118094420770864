import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "./PostJobSecondStep.scss";
import { Button } from "reactstrap";
import AddJobRequiredSkillsModal from "../../../../common/modals/addJobRequiredSkillsModal/AddJobRequiredSkillsModal.container";
import NoProfilesModal from "../../../../common/modals/noProfilesModal/NoProfilesModal.container";
import SkillsWithoutLevelModal from "../../../../common/modals/skillsWithoutLevelModal/SkillsWithoutLevelModal.container";
import SkillsMap from "../../../../common/skills/skillsMap/SkillsMap.container";
import { SKILLS_MAP_MODE } from "../../../../common/skills/skillsMap/SkillsMap";
import { getStaticSkillsTree } from "../../../../../utils/getStaticSkillsTree";
import { JobStaticlink } from "../../../../common/JobStaticLink";

class PostJobSecondStep extends Component {
  componentDidUpdate(prevProps) {
        if (!!this.props.jobToEdit && prevProps.jobToEdit !== this.props.jobToEdit) {
            this.props.onMount(this.props.jobToEdit.hash);
        }
    }
    render() {
        const {
            translation,
            onAddJobRequiredSkillsJob,
            userData,
            skillsTree,
            imageSource,
            userId,
            currentSkillId,
            jobTitle,
            internalReference,
            errors,
            jobData,
        } = this.props;

        const jobDataFields = [
            {
                fieldName: "jobDescription",
                title: _.get(translation, `post_job.second_step.job_description_label`),
                type: "file",
                accept: ".txt, .doc",
                innerHtml: true,
                multiple: true,
            },
        ];

        const readOnlySkillsTree = getStaticSkillsTree(jobData);

        return this.props.readOnly ? (
            <>
                <SkillsMap skillsTree={readOnlySkillsTree} mode={SKILLS_MAP_MODE.JOB_VIEW} />
                {jobData && <JobStaticlink hash={jobData.hash} />}
            </>
        ) : (
            <div className="post-job-page-wrapper">
                <h5 className="page-subsection-header">{`${_.get(
                    translation,
                    "post_job.second_step.tab_label"
                )}${jobTitle ? ` ${_.get(translation, "shared.for_label")} ${jobTitle}` : ""}${
                    internalReference
                        ? ` (${_.get(
                              translation,
                              "post_job.second_step.int_ref_short_label"
                          )} ${internalReference})`
                        : ""
                }`}</h5>
                <h6>{_.get(translation, "post_job.second_step.placeholder_under_title")}</h6>
                {!skillsTree?.length ? (
                    <>
                        {(errors.skills || errors.profiles) && (
                            <div style={{ color: "orange" }}>
                                {errors.skills || errors.profiles}
                            </div>
                        )}
                        <Button
                            size="xs"
                            color="primary"
                            onClick={onAddJobRequiredSkillsJob}
                            className="skill-button"
                        >
                            {_.get(translation, "post_job.second_step.setup_skills_buton_label")}
                        </Button>
                    </>
                ) : null}
                {skillsTree?.length ? (
                    <>
                        <SkillsMap
                            skillsTree={skillsTree}
                            mode={SKILLS_MAP_MODE.SKILLS_VIEW}
                            imageSource={imageSource}
                            userId={userId}
                            onSelectSkill={this.onSelectSkill}
                            onRemoveClick={() => {}}
                            onNotesClick={() => {}}
                            onRemoveSkill={() => {}}
                            currentSkillId={currentSkillId}
                            translation={translation}
                            centered
                        />
                        <Button
                            size="xs"
                            color="primary"
                            onClick={onAddJobRequiredSkillsJob}
                            className="skill-button"
                        >
                            {_.get(translation, "post_job.second_step.edit_skills_buton_label")}
                        </Button>
                    </>
                ) : null}
                <Button color="primary" size="xs" className="skill-button">
                    {_.get(translation, "post_job.save_button_label")}
                </Button>
                <div className="fields-wrapper">
                    {_.map(jobDataFields, (params) =>
                        this.props.renderInlineSelectFormGroup(params)
                    )}
                </div>

                <AddJobRequiredSkillsModal userId={userData.id} />
                <NoProfilesModal cbAfterClose={onAddJobRequiredSkillsJob} />
                <SkillsWithoutLevelModal
                    data={_.filter(this.props.skills, (skill) => _.isNil(skill.level))}
                    cbAfterClose={onAddJobRequiredSkillsJob}
                />
            </div>
        );
    }
}

PostJobSecondStep.propTypes = {
    translation: PropTypes.object.isRequired,
    renderInlineSelectFormGroup: PropTypes.func.isRequired,
};

export default PostJobSecondStep;
