import React, { Component } from "react";
import _ from "lodash";
import QRCode from "react-qr-code";
import {
    Modal,
    ModalBody,
    Button,
    Col,
    FormGroup,
    Label,
    Input,
    ModalFooter,
    Collapse,
} from "reactstrap";
import Icon from "../icon/Icon";

export default class DFAModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkCode: "",
            isLinksCollapseOpen: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen && !this.props.isOpen && !nextProps.is2FAEnabled) {
            this.props.onGenerate2FASecret();
        }
    }

    checkCodeIsInvalid = () => this.state.checkCode.length < 6 && this.state.checkCode.length > 0;

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="two-fa-settings-modal-container">
                    <ModalBody className="d-flex flex-column">
                        <h5 className="text-center two-fa-settings-label">
                            {_.get(this.props.translation, "personal_settings_page.dfa_label")}
                        </h5>
                        {!this.props.is2FAEnabled && (
                            <>
                                <h6>
                                    {_.get(
                                        this.props.translation,
                                        "personal_settings_page.dfa_subtitle"
                                    )}
                                </h6>
                                <span>
                                    {_.get(
                                        this.props.translation,
                                        "personal_settings_page.dfa_tip"
                                    )}
                                </span>
                                {this.renderLinks()}
                                {this.props.qrCodeData && (
                                    <div className="qr-code-container">
                                        <QRCode
                                            value={`otpauth://totp/hf1:${this.props.userEmail}?secret=${this.props.qrCodeData.secret}&issuer=hf1`}
                                        />
                                    </div>
                                )}
                                <h6>
                                    {_.get(
                                        this.props.translation,
                                        "personal_settings_page.dfa_hint"
                                    )}
                                </h6>
                                <FormGroup row>
                                    <Label sm={4}>
                                        {_.get(
                                            this.props.translation,
                                            "personal_settings_page.verification_code_label"
                                        )}
                                    </Label>
                                    <Col sm={8}>
                                        <Input
                                            value={this.state.checkCode}
                                            onChange={(e) =>
                                                this.setState({ checkCode: e.target.value })
                                            }
                                            type="text"
                                            maxLength="6"
                                            placeholder="_ _ _ _ _ _"
                                            invalid={this.checkCodeIsInvalid()}
                                        />
                                    </Col>
                                </FormGroup>
                            </>
                        )}
                        {this.props.is2FAEnabled && (
                            <span>
                                {_.get(
                                    this.props.translation,
                                    "personal_settings_page.disable_2fa_warning"
                                )}
                            </span>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.onSubmit()}>
                            {_.get(
                                this.props.translation,
                                `personal_settings_page.btn_${
                                    this.props.is2FAEnabled ? "disable" : "enable"
                                }_2fa`
                            )}
                        </Button>
                        <Button color="secondary" onClick={this.props.onClose}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderLinks = () => {
        let iconName = "keyboard_arrow_" + (this.state.isLinksCollapseOpen ? "down" : "right");
        return (
            <div className="links-container">
                <div className="links-title-container olive-green">
                    <h6 onClick={this.toggleLinks}>
                        {_.get(this.props.translation, "security_settings.links_label")}
                    </h6>
                    <Icon
                        onClick={this.toggleLinks}
                        className="links-title-icon"
                        type="arrow_right"
                    />
                </div>
                <div className="links-details-container">
                    <Collapse isOpen={this.state.isLinksCollapseOpen}>
                        <div className="links-container">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                            >
                                {_.get(
                                    this.props.translation,
                                    "security_settings.google_authenticator_android_label"
                                )}
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://itunes.apple.com/us/app/google-authenticator/id388497605"
                            >
                                {_.get(
                                    this.props.translation,
                                    "security_settings.google_authenticator_ios_label"
                                )}
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://play.google.com/store/apps/details?id=org.fedorahosted.freeotp"
                            >
                                {_.get(
                                    this.props.translation,
                                    "security_settings.free_otp_android_label"
                                )}
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://itunes.apple.com/ru/app/freeotp-authenticator/id872559395"
                            >
                                {_.get(
                                    this.props.translation,
                                    "security_settings.free_otp_ios_label"
                                )}
                            </a>
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    };

    toggleLinks = () => {
        this.setState({ isLinksCollapseOpen: !this.state.isLinksCollapseOpen });
    };

    onSubmit() {
        if (this.props.is2FAEnabled) {
            this.props.onDisable2FA();
        } else {
            if (!_.isNil(this.state.checkCode) && this.state.checkCode.length === 6) {
                this.props.onEnable2FA(this.state.checkCode);
            } else {
                this.props.onEnable2FA();
            }
        }
    }
}
