import React, { Component } from 'react';

class Feedback extends Component {
	render() {
		return (
			<svg width="128" height="128" viewBox="0 0 128 128">
					<path fill="#1d75b8" d="M126.03175,23.0913v-.00012a1.694,1.694,0,0,0-1.394-1.17444l-9.04778-1.31192-4.04869-8.19837a1.726,1.726,0,0,0-3.09558.00086l-4.044,8.20009-9.04778,1.31722a1.72629,1.72629,0,0,0-.95558,2.94454l6.549,6.3803L99.404,40.26137a1.69349,1.69349,0,0,0,.68678,1.688,1.71678,1.71678,0,0,0,1.81843.13058l8.091-4.25658,8.09418,4.25275a1.7259,1.7259,0,0,0,2.50349-1.82016l-1.54816-9.01129,6.54577-6.38363A1.69418,1.69418,0,0,0,126.03175,23.0913Z"/>
					<path fill="#1d75b8" d="M54.95051,31.24922l-1.54323,9.0119a1.69362,1.69362,0,0,0,.687,1.68774,1.71593,1.71593,0,0,0,1.81769.13107l8.092-4.25658,8.09393,4.25251a1.72572,1.72572,0,0,0,2.50349-1.81979l-1.54791-9.01166,6.54528-6.38363a1.72593,1.72593,0,0,0-.95681-2.944l-9.04852-1.31192L65.545,12.40646a1.7261,1.7261,0,0,0-3.09558.00062l-4.04425,8.20034-9.04778,1.31722a1.72619,1.72619,0,0,0-.95558,2.94454Z"/>
					<path fill="#1d75b8" d="M32.64477,21.91674l-9.048-1.31192L19.5483,12.40646a1.726,1.726,0,0,0-3.09558.00086l-4.044,8.20009-9.048,1.31722a1.72622,1.72622,0,0,0-.95558,2.94454l6.549,6.38043L7.41061,40.26137a1.73364,1.73364,0,0,0,1.69612,2.02176h.00049a1.72231,1.72231,0,0,0,.8091-.2032l8.09122-4.25633,8.09418,4.25251a1.72584,1.72584,0,0,0,2.50349-1.82078L27.057,31.24441l6.54577-6.38363a1.72633,1.72633,0,0,0-.958-2.944Z"/>
					<path fill="#2d3e50" d="M95.81357,80.26779,70.679,72.13466,68.76023,51.58545a4.08882,4.08882,0,0,0-1.21214-3.03425l-.05-.05031a4.33542,4.33542,0,0,0-7.48017,2.95819L59.864,91.56471l-.31181,4.33023-6.56474-5.78556c-.19876-.19768-4.94831-4.852-9.84307-1.84536l-.00986.00649a1.12951,1.12951,0,0,0-.29436,1.64457c3.12748,4.10624,15.13841,19.16413,15.13841,19.16413a19.52621,19.52621,0,0,0,15.02192,7.34853l8.69063.12615A13.34243,13.34243,0,0,0,94.956,105.85829L99.18782,84.895A4.85037,4.85037,0,0,0,95.81357,80.26779Z"/>
			</svg>
		);
	}
}

export default Feedback;
