
import React, { Component } from 'react';

class AudioMessage extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 -2 512 512">
                <path d="m448 508.800781-148.59375-92.800781h-267.40625c-17.671875 0-32-14.328125-32-32v-352c0-17.671875 14.328125-32 32-32h448c17.671875 0 32 14.328125 32 32v352c0 17.671875-14.328125 32-32 32h-32zm0 0" fill="#ffda6b" />
                <path d="m218.734375 332.671875-64-10.65625c-15.429687-2.574219-26.734375-15.925781-26.734375-31.566406v-46.402344h32v46.402344l64 10.65625v-33.105469h32v33.121094c-.003906 9.402344-4.144531 18.332031-11.320312 24.410156-7.179688 6.074219-16.667969 8.6875-25.945313 7.140625zm0 0" fill="#ed564a" />
                <g fill="#fff">
                    <path d="m416 96h32v32h-32zm0 0" />
                    <path d="m416 160h32v32h-32zm0 0" />
                    <path d="m416 224h32v32h-32zm0 0" />
                    <path d="m416 288h32v32h-32zm0 0" />
                </g>
                <path d="m336 103.710938-256 48v96.578124l256 48zm0 0" fill="#ff7761" />
                <path d="m80 248.289062 256 48v-48l-256-48zm0 0" fill="#fc6454" />
                <path d="m80 128c8.835938 0 16 7.164062 16 16v112c0 8.835938-7.164062 16-16 16s-16-7.164062-16-16v-112c0-8.835938 7.164062-16 16-16zm0 0" fill="#ed564a" />
                <path d="m336 80c8.835938 0 16 7.164062 16 16v208c0 8.835938-7.164062 16-16 16s-16-7.164062-16-16v-208c0-8.835938 7.164062-16 16-16zm0 0" fill="#ed564a" />
            </svg>
        );
    }
}

export default AudioMessage;
