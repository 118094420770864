import { connect } from "react-redux";
import JobBall from "./JobBall";
import { getTranslation } from "../../../../../reducers/common/localization";
import { getActiveJobId, setActiveJobId } from "../../../../../reducers/applicant/applicantJobs";
import { putFavoriteJobApplicant, getUserJobsState } from "../../../../../reducers/user/userData";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        activeJobId: getActiveJobId(state),
        userJobs: getUserJobsState(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveJobId: (id) => dispatch(setActiveJobId(id)),
        makeJobFavorite: (jobId, isFavorite) =>
            dispatch(putFavoriteJobApplicant(jobId, isFavorite)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobBall);
