import React, { Component } from "react";
import _ from "lodash";
import "./JobDetails.scss";
import RichMediaModal from "./RichMediaModal.container";
import Icon from "../../../common/icon/Icon";

class JobDetails extends Component {
    handleChange = ({ target: { value, name } }) => {
        this.props.setParentStateValue(name, value);
    };

    render() {
        const { translation, activeJobId, userJobs } = this.props;
        const selectedJob = userJobs.find((job) => job.job.id === activeJobId) || null;

        return (
            selectedJob && (
                <>
                    <div className="job-details-block core">
                        <h6>{_.get(translation, "applicant_jobs_page.core_label")}</h6>
                        <div className="sub-section">
                            <div className="sub-title">
                                {_.get(translation, "shared.company_label")}
                            </div>
                            <div>{selectedJob.company}</div>
                        </div>
                        {selectedJob.location && (
                            <div className="sub-section">
                                <div className="sub-title">
                                    {_.get(translation, "shared.location")}
                                </div>
                                <div>{`${selectedJob.location.region} / ${selectedJob.location.countryCode} / ${selectedJob.location.city}`}</div>
                            </div>
                        )}
                        <div className="sub-section">
                            <div className="sub-title">
                                {_.get(translation, "job.job_title_label")}
                            </div>
                            <div>{selectedJob.jobTitle}</div>
                        </div>
                        {selectedJob.salary && (
                            <div className="sub-section">
                                <div className="sub-title">
                                    {_.get(translation, "shared.indicative_salary")}
                                </div>
                                <div>{`${selectedJob.salary.amount}${selectedJob.salary.currency}`}</div>
                            </div>
                        )}
                        <div className="sub-section">
                            <div className="sub-title">
                                {_.get(translation, "applicant_jobs_page.number_of_applied_label")}
                            </div>
                            <div>{selectedJob.appliedAmount}</div>
                        </div>
                    </div>
                    <div className="job-details-block dates">
                        <h6>{_.get(translation, "shared.dates_label")}</h6>
                        <div className="sub-section">
                            <div className="sub-title">
                                {_.get(translation, "applicant_jobs_page.deadline_label")}
                            </div>
                            <div>{selectedJob.deadline}</div>
                        </div>
                        <div className="sub-section">
                            <div className="sub-title">
                                {_.get(translation, "applicant_jobs_page.my_application_label")}
                            </div>
                            <div>{selectedJob.appliedDate}</div>
                        </div>
                        <div className="sub-section">
                            <div className="sub-title">
                                {_.get(translation, "applicant_jobs_page.planned_start_label")}
                            </div>
                            <div>{selectedJob.startDate}</div>
                        </div>
                    </div>
                    <div className="job-details-block rich-media">
                        <h6>{_.get(translation, "shared.rich_media_label")}</h6>
                        <div className="sub-section d-flex justify-content-center border-0">
                            <Icon
                                type="rich_media"
                                color="primary"
                                size="xxl"
                                onClick={() => this.props.openRichMediaModal()}
                            >
                                {_.get(translation, "shared.open_rich_media_label")}
                            </Icon>
                        </div>
                    </div>
                    <div className="job-details-block status">
                        <h6>{_.get(translation, "shared.status_label")}</h6>
                        <div>{selectedJob.status}</div>
                        {/* TODO: add statuses  */}
                    </div>
                    <RichMediaModal />
                </>
            )
        );
    }
}

JobDetails.propTypes = {};

export default JobDetails;
