import { connect } from 'react-redux';
import TablePagination from "./TablePagination";
import {
    getRelatedSkillsCurrentPage,
    getRelatedSkillsTotalPages,
    getRelatedSkillsFetchingStatus,
    onFetchGetRelatedSkillsByPage
} from '../../../reducers/admin/relatedSkills';

const mapStateToProps = state => {
    return {
        current: getRelatedSkillsCurrentPage(state),
        total: getRelatedSkillsTotalPages(state),
        loading: getRelatedSkillsFetchingStatus(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getDataForPage: (page) => {
            dispatch(onFetchGetRelatedSkillsByPage(page));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePagination);
