import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import _ from 'lodash';
import { NODE_SORTING, NODE_TYPE, NODE_STATUS } from '../../constants/systemNodesConstants';

//- Actions
export const fetchGetClosestClustersFailed = createAction('FETCH_GET_CLOSEST_CLUSTERS_DATA_FAILED');
export const fetchGetClosestClustersSuccess = createAction('FETCH_GET_CLOSEST_CLUSTERS_DATA_SUCCESS');
export const onFetchGetClosestClustersSuccess = (data) => (dispatch) => {
    data.content = _.map(data.content, (cluster, id) => {
        return {
            id: cluster.id,
            categoryId: cluster.id,
            categoryTitle: cluster.titles.en,
            // TODO: remove hardcoded values
            type: NODE_TYPE.CLUSTER,
            status: NODE_STATUS.REFERENCE_MODEL,
            source: _.sample(["Admin", "User", "O*Net", "VDAB"])
        }
    });
    dispatch(fetchGetClosestClustersSuccess(data));
}
export const fetchGetClosestClusters = createAction(
    'FETCH_GET_CLOSEST_CLUSTERS_DATA',
    (params, page) => Api.getClosestClusters(params, page),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetClosestClustersSuccess,
        onFail: fetchGetClosestClustersFailed
    })
);

export const onFetchGetClosestClusters = (params = {}) => (dispatch, getState) => {
    let nameFilter = getClosestClustersNameFilter(getState());
    let typeFilter = getClosestClustersTypeFilter(getState());
    let sourceFilter = getClosestClustersSourceFilter(getState());
    let sortingMode = getClosestClustersSortingMode(getState());
    let listParams = {
        nameFilter: !_.isUndefined(params.nameFilter) ? params.nameFilter : nameFilter,
        typeFilter: !_.isUndefined(params.typeFilter) ? params.typeFilter : typeFilter,
        sourceFilter: !_.isUndefined(params.sourceFilter) ? params.sourceFilter : sourceFilter,
        sortingMode: !_.isUndefined(params.sortingMode) ? params.sortingMode : sortingMode
    }
    dispatch(fetchGetClosestClusters(listParams, params.page));
}

export const onFetchGetClosestClustersByPage = (page) => (dispatch) => {
    dispatch(onFetchGetClosestClusters({ page: page }));
}

export const updateClosestClustersNameFilter = createAction('UPDATE_CLOSEST_CLUSTERS_NAME_FILTER');
export const updateClosestClustersTypeFilter = createAction('UPDATE_CLOSEST_CLUSTERS_TYPE_FILTER');
export const updateClosestClustersSourceFilter = createAction('UPDATE_CLOSEST_CLUSTERS_SOURCE_FILTER');
export const updateClosestClustersTypeAndSourceFilter = (typeFilter, sourceFilter) => (dispatch) => {
    dispatch(updateClosestClustersTypeFilter(typeFilter));
    dispatch(updateClosestClustersSourceFilter(sourceFilter));
    let params = {
        typeFilter: typeFilter,
        sourceFilter: sourceFilter,
    }
    dispatch(onFetchGetClosestClusters(params));
}
export const updateClosestClustersSortingMode = createAction('UPDATE_CLOSEST_CLUSTERS_SORTING');
export const onUpdateClosestClustersSortingMode = (sortingMode) => (dispatch) => {
    dispatch(updateClosestClustersSortingMode(sortingMode));
    dispatch(onFetchGetClosestClusters({ sortingMode: sortingMode }));
}

export const updateAddedClosestClusters = createAction('UPDATE_ADDED_CLOSEST_CLUSTERS');
export const onAddRelationsWithClusters = (nodesToAdd) => (dispatch, getState) => {
    let addedNodes = getAddedClosestClustersData(getState()) || [];
    dispatch(updateAddedClosestClusters(_.concat(addedNodes, nodesToAdd)));
}

export const addRelationWithCluster = (nodeId) => (dispatch, getState) => {
    let data = getClosestClustersData(getState());
    let addedNodes = _.cloneDeep(getAddedClosestClustersData(getState())) || [];
    let nodeToAdd = _.find(data, (node) => node.id === nodeId);
    addedNodes.push(nodeToAdd);
    dispatch(updateAddedClosestClusters(addedNodes));
}
export const undoAddRelationWithCluster = (nodeId) => (dispatch, getState) => {
    let addedNodes = getAddedClosestClustersData(getState()) || [];
    addedNodes = _.filter(addedNodes, (node) => node.id !== nodeId);
    dispatch(updateAddedClosestClusters(addedNodes));
}

export const updateRemovedClosestClusters = createAction('UPDATE_REMOVED_CLOSEST_CLUSTERS');
export const removeRelationWithCluster = (nodeId) => (dispatch, getState) => {
    let data = getClosestClustersData(getState());
    let removedNodes = _.cloneDeep(getRemovedClosestClustersData(getState())) || [];
    let nodeToRemove = _.find(data, (node) => node.id === nodeId);
    removedNodes.push(nodeToRemove);
    dispatch(updateRemovedClosestClusters(removedNodes));
}
export const undoRemoveRelationWithCluster = (nodeId) => (dispatch, getState) => {
    let removedNodes = _.cloneDeep(getRemovedClosestClustersData(getState())) || [];
    removedNodes = _.filter(removedNodes, (node) => node.id !== nodeId);
    dispatch(updateRemovedClosestClusters(removedNodes));
}

//- State
const initialState = {
    data: null,
    currentPage: null,
    totalPages: null,
    addedClusters: null,
    removedClusters: null,
    error: null,
    fetching: false,
    nameFilter: "",
    typeFilter: null,
    sourceFilter: null,
    sortingMode: NODE_SORTING.NAME,
};

//- Reducers
export default handleActions({

    FETCH_GET_CLOSEST_CLUSTERS_DATA: (state) => {
        return { ...state, data: null, currentPage: null, totalPages: null, error: null, fetching: true };
    },
    FETCH_GET_CLOSEST_CLUSTERS_DATA_FAILED: (state, action) => {
        return { ...state, data: null, currentPage: null, totalPages: null, error: action.payload, fetching: false };
    },
    FETCH_GET_CLOSEST_CLUSTERS_DATA_SUCCESS: (state, action) => {
        return { ...state, data: action.payload.content, currentPage: action.payload.number, totalPages: action.payload.totalPages, error: null, fetching: false };
    },
    UPDATE_ADDED_CLOSEST_CLUSTERS: (state, action) => {
        return { ...state, addedClusters: action.payload };
    },
    UPDATE_REMOVED_CLOSEST_CLUSTERS: (state, action) => {
        return { ...state, removedClusters: action.payload };
    },
    UPDATE_CLOSEST_CLUSTERS_NAME_FILTER: (state, action) => {
        return { ...state, nameFilter: action.payload };
    },
    UPDATE_CLOSEST_CLUSTERS_TYPE_FILTER: (state, action) => {
        return { ...state, typeFilter: action.payload };
    },
    UPDATE_CLOSEST_CLUSTERS_SOURCE_FILTER: (state, action) => {
        return { ...state, sourceFilter: action.payload };
    },
    UPDATE_CLOSEST_CLUSTERS_SORTING: (state, action) => {
        return { ...state, sortingMode: action.payload };
    },
}, initialState);

//- Selectors
export const getClosestClustersState = state => state.closestClusters;

export const getClosestClustersData = state => getClosestClustersState(state).data;

export const getClosestClustersCurrentPage = state => getClosestClustersState(state).currentPage;

export const getClosestClustersTotalPages = state => getClosestClustersState(state).totalPages;

export const getClosestClustersFetchingStatus = state => getClosestClustersState(state).fetching;

export const getAddedClosestClustersData = state => getClosestClustersState(state).addedClusters;

export const getRemovedClosestClustersData = state => getClosestClustersState(state).removedClusters;

export const getClosestClustersNameFilter = state => getClosestClustersState(state).nameFilter;

export const getClosestClustersTypeFilter = state => getClosestClustersState(state).typeFilter;

export const getClosestClustersSourceFilter = state => getClosestClustersState(state).sourceFilter;

export const getClosestClustersSortingMode = state => getClosestClustersState(state).sortingMode;
