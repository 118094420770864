// list of all clusters (to select new cluster for the Skill)
import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import _ from "lodash";
import { NODE_SORTING } from "../../constants/systemNodesConstants";

//- Actions
export const fetchGetAllClustersDataFailed = createAction("FETCH_GET_ALL_CLUSTERS_DATA_FAILED");
export const fetchGetAllClustersDataSuccess = createAction("FETCH_GET_ALL_CLUSTERS_DATA_SUCCESS");
export const onFetchGetAllClustersDataSuccess = (data) => (dispatch) => {
    data.content = _.map(data.content, (node) => {
        return {
            id: node.id,
            title: node.titles.en,
            type: node.resourceType,
            source: node.resourceOwner,
            domain: node.domain,
        };
    });
    dispatch(fetchGetAllClustersDataSuccess(data));
};
export const fetchGetAllClustersData = createAction(
    "FETCH_GET_ALL_CLUSTERS_DATA",
    (params, page) => Api.getAllClusters(params, page),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetAllClustersDataSuccess,
        onFail: fetchGetAllClustersDataFailed,
    })
);

export const onFetchGetAllClustersData =
    (params = {}) =>
    (dispatch, getState) => {
        let nameFilter = getAllClustersNameFilter(getState());
        let sourceFilter = getAllClustersSourceFilter(getState());
        let statusFilter = getAllClustersStatusFilter(getState());
        let domainFilter = getAllClustersDomainFilter(getState());
        let sortingMode = getAllClustersSortingMode(getState());
        let listParams = {
            nameFilter: !_.isUndefined(params.nameFilter) ? params.nameFilter : nameFilter,
            sourceFilter: !_.isUndefined(params.sourceFilter) ? params.sourceFilter : sourceFilter,
            statusFilter: !_.isUndefined(params.statusFilter) ? params.statusFilter : statusFilter,
            domainFilter: !_.isUndefined(params.domainFilter) ? params.domainFilter : domainFilter,
            sortingMode: !_.isUndefined(params.sortingMode) ? params.sortingMode : sortingMode,
        };
        dispatch(fetchGetAllClustersData(listParams, params.page));
    };

export const onFetchGetAllClustersDataByPage = (page) => (dispatch) => {
    dispatch(onFetchGetAllClustersData({ page: page }));
};

export const updateAllClustersNameFilter = createAction("UPDATE_ALL_CLUSTERS_NAME_FILTER");
export const updateAllClustersSourceFilter = createAction("UPDATE_ALL_CLUSTERS_SOURCE_FILTER");
export const onUpdateAllClustersSourceFilter = (sourceFilter) => (dispatch) => {
    dispatch(updateAllClustersSourceFilter(sourceFilter));
    let params = {
        sourceFilter: sourceFilter,
    };
    dispatch(onFetchGetAllClustersData(params));
};
export const updateAllClustersStatusFilter = createAction("UPDATE_ALL_CLUSTERS_STATUS_FILTER");
export const onUpdateAllClustersStatusFilter = (statusFilter) => (dispatch) => {
    dispatch(updateAllClustersStatusFilter(statusFilter));
    let params = {
        statusFilter: statusFilter,
    };
    dispatch(onFetchGetAllClustersData(params));
};
export const updateAllClustersDomainFilter = createAction("UPDATE_ALL_CLUSTERS_DOMAIN_FILTER");
export const onUpdateAllClustersDomainFilter = (domainFilter) => (dispatch) => {
    dispatch(updateAllClustersDomainFilter(domainFilter));
    let params = {
        domainFilter: domainFilter,
    };
    dispatch(onFetchGetAllClustersData(params));
};
export const updateAllClustersSortingMode = createAction("UPDATE_ALL_CLUSTERS_SORTING");
export const onUpdateAllClustersSortingMode = (sortingMode) => (dispatch) => {
    dispatch(updateAllClustersSortingMode(sortingMode));
    dispatch(onFetchGetAllClustersData({ sortingMode: sortingMode }));
};

export const onResetAllClustersData = createAction("RESET_ALL_CLUSTERS_DATA");

export const updateNewParentCluster = createAction("UPDATE_NEW_PARENT_CLUSTER");

//- State
const initialState = {
    data: null,
    currentPage: null,
    totalPages: null,
    error: null,
    fetching: false,
    nameFilter: "",
    sourceFilter: null,
    statusFilter: null,
    domainFilter: null,
    sortingMode: NODE_SORTING.NAME,
    newParentCluster: null,
};

//- Reducers
export default handleActions(
    {
        FETCH_GET_ALL_CLUSTERS_DATA: (state) => {
            return {
                ...state,
                data: null,
                currentPage: null,
                totalPages: null,
                error: null,
                fetching: true,
            };
        },
        FETCH_GET_ALL_CLUSTERS_DATA_FAILED: (state, action) => {
            return {
                ...state,
                data: null,
                currentPage: null,
                totalPages: null,
                error: action.payload,
                fetching: false,
            };
        },
        FETCH_GET_ALL_CLUSTERS_DATA_SUCCESS: (state, action) => {
            return {
                ...state,
                data: action.payload.content,
                currentPage: action.payload.number,
                totalPages: action.payload.totalPages,
                error: null,
                fetching: false,
            };
        },
        UPDATE_ALL_CLUSTERS_NAME_FILTER: (state, action) => {
            return { ...state, nameFilter: action.payload };
        },
        UPDATE_ALL_CLUSTERS_SOURCE_FILTER: (state, action) => {
            return { ...state, sourceFilter: action.payload };
        },
        UPDATE_ALL_CLUSTERS_STATUS_FILTER: (state, action) => {
            return { ...state, statusFilter: action.payload };
        },
        UPDATE_ALL_CLUSTERS_DOMAIN_FILTER: (state, action) => {
            return { ...state, domainFilter: action.payload };
        },
        UPDATE_ALL_CLUSTERS_SORTING: (state, action) => {
            return { ...state, sortingMode: action.payload };
        },
        RESET_ALL_CLUSTERS_DATA: () => {
            return { ...initialState };
        },
        UPDATE_NEW_PARENT_CLUSTER: (state, action) => {
            return { ...state, newParentCluster: action.payload };
        },
    },
    initialState
);

//- Selectors
export const getClustersState = (state) => state.clusters;

export const getAllClustersData = (state) => getClustersState(state).data;

export const getAllClustersCurrentPage = (state) => getClustersState(state).currentPage;

export const getAllClustersTotalPages = (state) => getClustersState(state).totalPages;

export const getAllClustersFetchingStatus = (state) => getClustersState(state).fetching;

export const getAllClustersNameFilter = (state) => getClustersState(state).nameFilter;

export const getAllClustersSourceFilter = (state) => getClustersState(state).sourceFilter;

export const getAllClustersStatusFilter = (state) => getClustersState(state).statusFilter;

export const getAllClustersDomainFilter = (state) => getClustersState(state).domainFilter;

export const getAllClustersSortingMode = (state) => getClustersState(state).sortingMode;

export const getNewParentCluster = (state) => getClustersState(state).newParentCluster;
