
import React, { Component } from 'react';

class Folder extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M9.6,2.4H2.4C1.1,2.4,0,3.5,0,4.8l0,14.4c0,1.3,1.1,2.4,2.4,2.4h19.2c1.3,0,2.4-1.1,2.4-2.4v-12c0-1.3-1.1-2.4-2.4-2.4H12 L9.6,2.4z" />
            </svg>
        );
    }
}

export default Folder;
