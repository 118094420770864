import React from "react";

class CompanyProfileIcon extends React.Component {
    render() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 128"
            >
                <defs></defs>
                <title>b</title>
                <rect fill="#1d75b8" x="17.3217" y="72.78424" width="10.22311" height="10.22311" />
                <rect fill="#1d75b8" x="35.38662" y="72.78424" width="10.22319" height="10.22311" />
                <rect fill="#1d75b8" x="17.3217" y="91.22494" width="10.22311" height="10.22311" />
                <rect fill="#1d75b8" x="35.38662" y="91.22494" width="10.22319" height="10.22311" />
                <rect fill="#1d75b8" x="96.07009" y="91.22575" width="10.22311" height="10.22311" />
                <rect fill="#1d75b8" x="96.07009" y="72.78506" width="10.22311" height="10.22311" />
                <rect fill="#1d75b8" x="40.34807" y="36.89214" width="10.22319" height="10.22319" />
                <rect fill="#1d75b8" x="58.41308" y="36.89214" width="10.22311" height="10.22319" />
                <path
                    fill="#2d3e50"
                    d="M83.33,50.59988V43.20744h-.015V4.36587L25.57924,26.30193v24.2977L2.0199,50.59873v73.03392l123.96019.00147V50.59988ZM53.9647,116.68639l-44.998-.00057V57.54589l23.55942.001v-.00106l21.43854.0009Zm22.40352-73.479h-.17471v73.47928L58.596,116.68647v-63.771l-26.06982-.00082V31.09391l43.84206-16.6572Zm42.665,73.47986L83.33,116.6868V57.54671h35.70323Z"
                />
            </svg>
        );
    }
}

export default CompanyProfileIcon;
