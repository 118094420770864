import { connect } from "react-redux";
import PotentialClustersModal from "./PotentialClustersModal";
import { getTranslation } from "../../../../reducers/common/localization";
import {
    getActiveModalType,
    POTENTIAL_CLUSTERS_MODAL,
    hideModal,
} from "../../../../reducers/common/activeModal";
import {
    clearCurrentSkillAndCluster,
    fetchAddApplicantSkills,
    fetchGetProfilesByClusterId,
    getProfilesByClusterId,
    onSaveApplicantSkills,
} from "../../../../reducers/applicant/applicantSkills";
import { onAddUserProfile } from "../../../../reducers/job/jobProfiles";
import {
    fetchGetApplicantProfiles,
    onSaveSkillProfile,
} from "../../../../reducers/applicant/applicantProfiles";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === POTENTIAL_CLUSTERS_MODAL,
        profilesByClusterId: getProfilesByClusterId(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClose: () => {
            dispatch(hideModal());
        },
        onSaveChanges: (profileId, title) => {
            dispatch(onSaveSkillProfile(profileId, title));
            dispatch(onSaveApplicantSkills(profileId));
        },
        getProfilesByClusterId: (clusterId) => dispatch(fetchGetProfilesByClusterId(clusterId)),
        onСlearCurrentSkillAndCluster: () => dispatch(clearCurrentSkillAndCluster()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PotentialClustersModal);
