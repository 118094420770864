import { NODE_TYPE } from "../constants/skillsConstants";

export const getClassNameByType = (type, className = "") => {
    switch (type) {
        case NODE_TYPE.SKILL:
            return className + " skill-node";
        case NODE_TYPE.CLUSTER:
            return className + " skill-cluster-node";
        case NODE_TYPE.SKILL_CLUSTER:
            return className + " skill-cluster-node";
        case NODE_TYPE.DOMAIN:
            return className + " domain-node";
        case NODE_TYPE.PROFILE:
            return className + " profile-node";
        default:
            return className;
    }
};
