import { connect } from 'react-redux';
import OpenJobs from './OpenJobs';
import { getTranslation } from '../../../../reducers/common/localization';
import { fetchGetOpenJobsList, getOpenJobsListData, getOpenJobsListFetchingStatus } from '../../../../reducers/job/openJobs';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        jobsList: getOpenJobsListData(state),
        loading: getOpenJobsListFetchingStatus(state)
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onMount: () => dispatch(fetchGetOpenJobsList())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenJobs);
