import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "./PostJobThirdStep.scss";
import { Button, Spinner } from "reactstrap";
import { StorageManager } from "../../../../../utils/storageManager";
import {
    COMMUTE_TIME_OPTION,
    COMMUTE_TIME_OPTION_TRANSLATION,
    USER_SUB_ROLE,
} from "../../../../../constants/constants";

const timeSelectorConfig = [
    {
        label: COMMUTE_TIME_OPTION_TRANSLATION.with_no_time_restrictions,
        value: 0,
    },
    {
        label: COMMUTE_TIME_OPTION_TRANSLATION[COMMUTE_TIME_OPTION.MIN_1],
        value: 60000,
    },
    {
        label: COMMUTE_TIME_OPTION_TRANSLATION[COMMUTE_TIME_OPTION.MIN_5],
        value: 300000,
    },
    {
        label: COMMUTE_TIME_OPTION_TRANSLATION[COMMUTE_TIME_OPTION.MIN_10],
        value: 600000,
    },
    {
        label: COMMUTE_TIME_OPTION_TRANSLATION[COMMUTE_TIME_OPTION.MIN_30],
        value: 1800000,
    },
    {
        label: COMMUTE_TIME_OPTION_TRANSLATION[COMMUTE_TIME_OPTION.HOUR_1],
        value: 3600000,
    },
    {
        label: COMMUTE_TIME_OPTION_TRANSLATION[COMMUTE_TIME_OPTION.HOUR_4],
        value: 14400000,
    },
];

class PostJobSecondStep extends Component {
    postJob = (postJobData, taskData) => {
        const { validate } = this.props;

        const data = { ...postJobData };
        if (taskData) {
            data.job = { ...data.job, task: { ...taskData } };
        }


        validate((errors) => {
            if (_.isEmpty(errors)) {
                const formData = new FormData();
                for (const prop in data) {
                    if (data[prop] instanceof Array) {
                        data[prop].forEach((item) => {
                            formData.append(prop, item);
                        });
                    } else {
                        const json = JSON.stringify(data[prop]);
                        formData.append(
                            prop,
                            new Blob([json], {
                                type: "application/json",
                            })
                        );
                    }
                }
                !this.props.isEditMode
                    ? this.props.onPostJob(formData)
                    : this.props.onEditJob(this.props.hash, formData);
            }
        }, !taskData && ["timer", "question"]);
    };

    render() {
        const {
            applicationDeadline,
            isTravelRequired,
            travelImpact,
            homeWorkingAllowed,
            homeWorkingImpact,
            maxSalary,
            topX,
            topY,
            expectedStart,
            contractType,
            assistHRM,
            assistBO,
            countryCode,
            city,
            district,
            salaryCurrency,
            workRegime,
            internalReference,
            situationContext,
            question,
            officeMedia,
            bossMedia,
            skills,
            profiles,
            jobDescription,
            jobTitle,
            timer,
            deepScreeningAmount,
            isPosting,
        } = this.props;


        const postJobData = {
            officeMedia,
            bossMedia,
            jobDescription,
            job: {
                jobTitle,
                internalReference,
                deepScreeningAmount,
                deadline: applicationDeadline,
                expectedImpactOfTravel: isTravelRequired ? travelImpact : 0,
                percentageOfHomeWorking: homeWorkingAllowed ? homeWorkingImpact : 0,
                salary: { amount: maxSalary, currency: salaryCurrency },
                topAmount: topX,
                amountToHire: topY,
                startDate: expectedStart,
                contractType,
                workRegime,
                profileId: profiles?.length ? profiles[0].id : null,
                skills: skills?.map(({ id, level, requirementCoefficient }) => ({
                    skillId: id,
                    level,
                    requirementCoefficient,
                })),
                hireManagerId: assistHRM?.id,
                businessOwnerId: assistBO?.id,

                location: {
                    countryCode,
                    city,
                    district,
                    street: "",
                    postal: "",
                },
            },
        };
        const jobTaskData = {
            timer,
            question,
            situationContext,
        };
        const { translation } = this.props;

        const stepFields = [
            {
                fieldName: "situationContext",
                title: _.get(translation, "post_job.third_step.situation_field_title"),
                type: "textarea",
            },
            {
                fieldName: "question",
                title: _.get(translation, "post_job.third_step.challenge_in_context_field_title"),
                type: "textarea",
                isRequired: true,
            },
            {
                fieldName: "timer",
                title: _.get(translation, `post_job.third_step.challenge_timer_label`),
                type: "select",
                options: timeSelectorConfig.map(({ value, label }) => ({
                    label: _.get(translation, label),
                    title: _.get(translation, label),
                    value,
                })),
            },
        ];

        const loggedInUserIsBo = StorageManager.getLastUsedSubRole() === USER_SUB_ROLE.BO;
        return (
            <div className="post-job-page-wrapper">
                <h5 className="page-subsection-header">
                    {_.get(translation, "post_job.third_step.the_idea_label")}
                </h5>
                <h6
                    dangerouslySetInnerHTML={{
                        __html: _.get(translation, "post_job.third_step.the_idea_info"),
                    }}
                />
                <h5 className="page-subsection-header">
                    {_.get(translation, "post_job.third_step.problem_desc")}
                </h5>
                <div className="problem-description-fields-wrapper">
                    {_.map(stepFields, (params) => this.props.renderInlineSelectFormGroup(params))}
                </div>
                {loggedInUserIsBo &&
                    (isPosting ? (
                        <Spinner className="spinner" size="xs" />
                    ) : (
                        <>
                            <div className="delimiter" />
                            <div className="post-buttons-wrapper">
                                <Button
                                    color="primary"
                                    disabled={this.props.isReadOnly}
                                    onClick={() => this.postJob(postJobData)}
                                >
                                    {_.get(
                                        translation,
                                        `post_job.third_step.${
                                            this.props.isEditMode ? "edit" : "post"
                                        }_without_challenge`
                                    )}
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => this.postJob(postJobData, jobTaskData)}
                                    disabled={!situationContext && !question}
                                >
                                    {_.get(translation, "post_job.third_step.post_with_challenge")}
                                </Button>
                            </div>
                        </>
                    ))}
            </div>
        );
    }
}

PostJobSecondStep.propTypes = {
    translation: PropTypes.object.isRequired,
    handleChangeFileField: PropTypes.func.isRequired,
    renderInlineSelectFormGroup: PropTypes.func.isRequired,
};

export default PostJobSecondStep;
