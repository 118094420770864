import React, { Component } from "react";
import PropTypes from "prop-types";
import "./RelatedNodes.scss";
import _ from "lodash";
import { Table, Col, FormGroup, Button, Badge } from "reactstrap";
import { NODE_TYPE, NODE_DOMAIN_LABEL } from "../../../../../constants/systemNodesConstants";
import SystemNodeStatus from "../../systemNodeStatus/SystemNodeStatus.container";
import Icon from "../../../../common/icon/Icon";
import RelatedSkillsPagination from "../../../../common/tablePagination/RelatedSkillsPagination.container";
import RelatedProfilesPagination from "../../../../common/tablePagination/RelatedProfilesPagination.container";
import BlockLoader from "../../../../common/blockLoader/BlockLoader.container";
import AddRelationWithSkillModal from "./addRelationBetweenNodesModal/AddRelationWithSkillModal.container";
import AddRelationWithProfileModal from "./addRelationBetweenNodesModal/AddRelationWithProfileModal.container";
import RelatedProfilesListFilters from "../../nodesFilters/RelatedProfilesListFilters.container";
import RelatedSkillsListFilters from "../../nodesFilters/RelatedSkillsListFilters.container";
import SystemNodeSource from "../../systemNodeSource/SystemNodeSource.container";

const TITLE_TRANSLATION_BY_NODE_TYPE = {
    [NODE_TYPE.PROFILE]: "system_nodes.related_profiles_label",
    [NODE_TYPE.SKILL]: "system_nodes.dependent_skills_label",
};

const REMOVE_RELATIONS_TRANSLATION_BY_NODE_TYPE = {
    [NODE_TYPE.PROFILE]: "system_nodes.remove_relations_with_next_profiles_label",
    [NODE_TYPE.SKILL]: "system_nodes.remove_relations_with_next_skills_label",
};

const ADD_RELATIONS_TRANSLATION_BY_NODE_TYPE = {
    [NODE_TYPE.PROFILE]: "system_nodes.add_relations_with_next_profiles_label",
    [NODE_TYPE.SKILL]: "system_nodes.add_relations_with_next_skills_label",
};

const NODES_GROUP = {
    RELATED_NODES: "RELATED_NODES",
    NODES_TO_REMOVE: "NODES_TO_REMOVE",
    NODES_TO_ADD: "NODES_TO_ADD",
};

class RelatedNodes extends Component {

    componentDidMount() {
        if (this.props.onMount && !this.props.addRelationsOnly) {
            this.props.onMount();
        }
    }


    renderTitleAndControls() {
        const { t } = this.props;

        if (this.props.addRelationsOnly) {
            return (
                <FormGroup row>
                    <Col>
                        <h5 className="page-subsection-header">
                            {_.get(
                                this.props.translation,
                                TITLE_TRANSLATION_BY_NODE_TYPE[this.props.nodesType],
                            )}
                        </h5>
                    </Col>
                    <Col sm="auto">
                        <Button className="add-node-relation-button"
                                color="primary"
                                size="sm"
                                onClick={this.props.onShowAddRelationModal}>
                            {_.get(this.props.translation, "actions.add_label")}
                        </Button>
                    </Col>
                </FormGroup>
            );
        } else {
            return (
                <>
                    <FormGroup row>
                        <Col>
                            <h5 className="page-subsection-header">
                                {t("Add_Nodes_dependent_on_this_Skill")}
                            </h5>
                        </Col>
                        <Col sm="auto">
                            <Button className="add-node-relation-button"
                                    color="primary"
                                    size="sm"
                                    onClick={this.props.onShowAddRelationModal}>
                                {_.get(this.props.translation, "actions.add_label")}
                            </Button>
                        </Col>
                    </FormGroup>
                </>
            );
        }
    }

    renderNodesData(nodesGroup) {
        let nodesList = this.props.nodesList;

        if (nodesGroup === NODES_GROUP.NODES_TO_REMOVE) {
            nodesList = this.props.removedNodes;
        } else if (nodesGroup === NODES_GROUP.NODES_TO_ADD) {
            nodesList = this.props.addedNodes;
        }

        return _.map(nodesList, (nodeData) => {
            let nodeStatus = nodeData.status;
            let nodeType = nodeData.type;
            let rowClassName = "related-node-row";
            let isNodeRemoved =
                nodesGroup === NODES_GROUP.RELATED_NODES
                    ? _.find(
                        this.props.removedNodes,
                        (removedNode) => removedNode.id === nodeData.id,
                    )
                    : null;

            switch (nodesGroup) {
                case NODES_GROUP.RELATED_NODES:
                    rowClassName += isNodeRemoved ? " removed-node" : "";
                    break;

                case NODES_GROUP.NODES_TO_REMOVE:
                    rowClassName += " removed-node";
                    break;

                case NODES_GROUP.NODES_TO_ADD:
                    rowClassName += " added-node";
                    break;

                default:
                    break;
            }

            return (
                <tr key={nodeData.id} className={rowClassName}>
                    {/* TODO: add details popup */}
                    <td className="related-node-type-cell" onClick={this.onSelectNode(nodeData.id)}>
                        <SystemNodeStatus type={nodeType} status={nodeStatus} />
                    </td>
                    <td className="related-node-name-cell" onClick={this.onSelectNode(nodeData.id)}>
                        <div className="related-node-name-cell-content">
                            <div className="node-name">{nodeData.title}</div>
                            {nodeData.type === NODE_TYPE.SKILL && !!nodeData.cluster ? (
                                <div className="node-parent-cluster" title={nodeData.cluster.title}>
                                    <i>
                                        {_.get(
                                            this.props.translation,
                                            "system_nodes.skill_cluster_label",
                                        ) + ": "}
                                    </i>
                                    <Badge className="node-parent-cluster-badge" color="light">
                                        {nodeData.cluster.title}
                                    </Badge>
                                </div>
                            ) : null}
                            <div
                                className="node-domain"
                                title={_.get(
                                    this.props.translation,
                                    NODE_DOMAIN_LABEL[nodeData.domain],
                                )}
                            >
                                <i>
                                    {_.get(this.props.translation, "system_nodes.domain_label") +
                                    ": "}
                                </i>
                                <Badge className="node-domain-badge" color="light">
                                    {_.get(
                                        this.props.translation,
                                        NODE_DOMAIN_LABEL[nodeData.domain],
                                    )}
                                </Badge>
                            </div>
                        </div>
                    </td>
                    <td className="system-node-source-cell">
                        <SystemNodeSource source={nodeData.source} />
                    </td>
                    {nodesGroup === NODES_GROUP.RELATED_NODES ? (
                        <td
                            className="related-node-action-cell table-cell-with-icon"
                            title={
                                isNodeRemoved
                                    ? _.get(
                                        this.props.translation,
                                        "system_nodes.undo_remove_relation_label",
                                    )
                                    : _.get(
                                        this.props.translation,
                                        "system_nodes.remove_relation_label",
                                    )
                            }
                            onClick={
                                isNodeRemoved
                                    ? this.onUndoRemoveRelation(nodeData.id)
                                    : this.onRemoveRelation(nodeData.id)
                            }
                        >
                            <Icon type={isNodeRemoved ? "undo" : "delete"} color="secondary" />
                        </td>
                    ) : null}
                    {nodesGroup === NODES_GROUP.NODES_TO_REMOVE ? (
                        <td
                            className="related-node-action-cell table-cell-with-icon"
                            title={_.get(
                                this.props.translation,
                                "system_nodes.undo_remove_relation_label",
                            )}
                            onClick={this.onUndoRemoveRelation(nodeData.id)}
                        >
                            <Icon type="undo" color="secondary" />
                        </td>
                    ) : null}
                    {nodesGroup === NODES_GROUP.NODES_TO_ADD ? (
                        <td
                            className="related-node-action-cell table-cell-with-icon"
                            title={_.get(
                                this.props.translation,
                                "system_nodes.undo_add_relation_label",
                            )}
                            onClick={this.onUndoAddRelation(nodeData.id)}
                        >
                            <Icon type="undo" color="secondary" />
                        </td>
                    ) : null}
                </tr>
            );
        });
    }

    renderRemovedNodes() {
        return (
            <div className="related-nodes-table-container removed-nodes">
                <Table className="clickable-rows">
                    <tbody>{this.renderNodesData(NODES_GROUP.NODES_TO_REMOVE)}</tbody>
                </Table>
            </div>
        );
    }

    renderAddedNodes() {
        return (
            <div className="related-nodes-table-container added-nodes">
                <Table className="clickable-rows">
                    <tbody>{this.renderNodesData(NODES_GROUP.NODES_TO_ADD)}</tbody>
                </Table>
            </div>
        );
    }

    onSelectNode = (nodeId) => () => {
        // TODO: add handler (show modal?)
        // this.props.onSelectNode(nodeId);
    };

    onRemoveRelation = (nodeId) => () => {
        this.props.onRemoveRelation(nodeId);
    };

    onUndoRemoveRelation = (nodeId) => () => {
        this.props.onUndoRemoveRelation(nodeId);
    };

    onUndoAddRelation = (nodeId) => () => {
        this.props.onUndoAddRelation(nodeId);
    };

    render() {
        return (
            <div className="related-nodes">
                {this.renderTitleAndControls()}
                {!this.props.addRelationsOnly ? (
                    <div>
                        {!this.props.loading &&
                        (!this.props.nodesList || this.props.nodesList.length === 0) ? (
                            <h6 className="text-center">
                                {_.get(
                                    this.props.translation,
                                    "model_management_page.no_data_label",
                                )}
                            </h6>
                        ) : null}
                        <BlockLoader loading={this.props.loading} />
                    </div>
                ) : null}
                {this.props.nodesType === NODE_TYPE.SKILL && !_.isEmpty(this.props.currentNode) && (
                    <div className="align-items-center">
                        {this.props.currentNode.dependentSkills &&
                        this.props.currentNode.dependentSkills.map((dependentSkill) => {
                            return (
                                <Badge className="node-domain-badge ml-3 align-self-center d-inline-flex flex-row mb-3"
                                       color="light">
                                        <span className="align-self-center">
                                            {dependentSkill.titles.en}
                                        </span>
                                    <Icon type="cancel"
                                          color="danger"
                                          className="ml-2"
                                          onClick={(e) => {
                                              this.props.onRemoveDependency(
                                                  this.props.currentNode.id,
                                                  dependentSkill.id,
                                              );
                                          }} />
                                </Badge>
                            );
                        })}
                    </div>
                )}
             {/*   {(() => {
                    switch (this.props.nodesType) {
                        case NODE_TYPE.SKILL:
                            return <RelatedSkillsPagination />;
                        case NODE_TYPE.PROFILE:
                            return <RelatedProfilesPagination />;
                        default:
                            return null;
                    }
                })()}*/}
                {this.props.removedNodes && this.props.removedNodes.length > 0 ? (
                    <div>
                        <h6 className="text-center">
                            {_.get(
                                this.props.translation,
                                REMOVE_RELATIONS_TRANSLATION_BY_NODE_TYPE[this.props.nodesType],
                            )}
                        </h6>
                        {this.renderRemovedNodes()}
                    </div>
                ) : null}
                {this.props.addedNodes && this.props.addedNodes.length > 0 ? (
                    <div>
                        <h6 className="text-center">
                            {_.get(
                                this.props.translation,
                                ADD_RELATIONS_TRANSLATION_BY_NODE_TYPE[this.props.nodesType],
                            )}
                        </h6>
                        {this.renderAddedNodes()}
                    </div>
                ) : null}
                {(() => {
                    switch (this.props.nodesType) {
                        case NODE_TYPE.SKILL:
                            return (
                                <AddRelationWithSkillModal showAllNodes={this.props.addRelationsOnly} />
                            );
                        case NODE_TYPE.PROFILE:
                            return (
                                <AddRelationWithProfileModal showAllNodes={this.props.addRelationsOnly} />
                            );
                        default:
                            return null;
                    }
                })()}
            </div>
        );
    }

}

RelatedNodes.propTypes = {
    // TODO: props
    translation: PropTypes.object.isRequired,
    nodesType: PropTypes.string,
    nodesList: PropTypes.array,
    loading: PropTypes.bool,
    addRelationsOnly: PropTypes.bool,
    removedNodes: PropTypes.array,
    addedNodes: PropTypes.array,
    onMount: PropTypes.func.isRequired,
    onRemoveRelation: PropTypes.func.isRequired,
    onUndoRemoveRelation: PropTypes.func.isRequired,
    onShowAddRelationModal: PropTypes.func.isRequired,
    onUndoAddRelation: PropTypes.func.isRequired,
};

export default RelatedNodes;
