import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import _ from 'lodash';
import { FormUtils } from '../../../../../utils/formUtils';

class DepartmentsDetailsEditModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			email: ""
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { selectedDepartment } = this.props;
		if (!_.isEmpty(selectedDepartment) && !_.isEqual({
			email: selectedDepartment.email,
			name: selectedDepartment.name
		}, prevState)) {
			this.setState({ name: selectedDepartment.name, email: selectedDepartment.email })
		}
	}

	closeModal = () => {
		const { onClose } = this.props;

		this.setState({ name: "", email: "" });
		onClose();
	};

	handleChangeField = (fieldName) => (e) => {
		this.setState({ [fieldName]: e.target.value });
	};

	checkIsSubmitDisabled = () => {
		const { name, email } = this.state;
		const { selectedDepartment, isEditDepartment } = this.props;

		if (!name || !email) {
			return true;
		} else if (
			!_.isEmpty(selectedDepartment)
			&& isEditDepartment
			&& _.isEqual(this.state, { name: selectedDepartment.name, email: selectedDepartment.email })
		) {
			return true;
		} else return false;
	};

	onSubmit = (e) => {
		e.preventDefault();

		const { onEditDepartment, selectedDepartment } = this.props;

		const { name, email,  } = this.state;

		const data = {
			name,
			email,
		};

		onEditDepartment({ data, departmentId: selectedDepartment.id, refetchDepartment: true });

		this.closeModal();
	};

	render() {
		const { isOpen, onClose, translation } = this.props;

		const formParams = {
			data: this.state,
			onFieldChange: this.handleChangeField
		};

		const params = [
			{
				fieldName: "name",
				title: _.get(translation, 'departments.department_name_label'),
				isRequired: true
			},
			{
				fieldName: "email",
				title: _.get(translation, 'departments.department_email_label'),
				type: 'email',
				isRequired: true
			},
		];

		return (
			<Modal
				isOpen={isOpen}
				toggle={onClose}
			>
				<ModalHeader>
					{_.get(translation, 'departments.edit_department_label')}
				</ModalHeader>
				<ModalBody>
					<Form id="edit-department-modal-form" onSubmit={this.onSubmit}>
						{_.map(params, (fieldParams) => FormUtils.renderFormGroup(fieldParams, formParams))}
					</Form>
				</ModalBody>
				<ModalFooter className="content-space-between">
					<Button
						color="secondary"
						onClick={this.closeModal}
					>
						{_.get(translation, 'actions.cancel_button')}
					</Button>
					<Button
						disabled={this.checkIsSubmitDisabled()}
						color="primary"
						form="edit-department-modal-form"
						type="submit"
					>
						{_.get(translation, "actions.edit_label")}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

DepartmentsDetailsEditModal.propTypes = {
	translation: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onEditDepartment: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	selectedDepartment: PropTypes.object,
};

export default DepartmentsDetailsEditModal;
