import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import _ from 'lodash';
import { NODE_SORTING, NODE_TYPE, NODE_STATUS, NODE_DOMAIN_LIST } from '../../constants/systemNodesConstants';
import { getSystemCurrentNodeId } from './systemCurrentNode';

//- Actions
export const fetchGetRelatedProfilesFailed = createAction('FETCH_GET_RELATED_PROFILES_DATA_FAILED');
export const fetchGetRelatedProfilesSuccess = createAction('FETCH_GET_RELATED_PROFILES_DATA_SUCCESS');
export const onFetchGetRelatedProfilesSuccess = (data) => (dispatch) => {
    data.content = _.map(data.content, (profile) => {
        return {
            id: profile.id,
            title: profile.titles.en,
            description: profile.description,
            type: profile.resourceType,
            // TODO: remove hardcoded values
            status: NODE_STATUS.REFERENCE_MODEL,
            source: _.sample(["Admin", "User", "O*Net", "VDAB"]),
            domain: _.sample(NODE_DOMAIN_LIST)
        }
    });
    dispatch(fetchGetRelatedProfilesSuccess(data));
}
export const fetchGetRelatedProfiles = createAction(
    'FETCH_GET_RELATED_PROFILES_DATA',
    (nodeId, params, page) => Api.getRelatedNodes(nodeId, params, page),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetRelatedProfilesSuccess,
        onFail: fetchGetRelatedProfilesFailed
    })
);

export const onFetchGetRelatedProfiles = (params = {}) => (dispatch, getState) => {
    let nodeId = getSystemCurrentNodeId(getState());
    let nameFilter = getRelatedProfilesNameFilter(getState());
    let sourceFilter = getRelatedProfilesSourceFilter(getState());
    let statusFilter = getRelatedProfilesStatusFilter(getState());
    let domainFilter = getRelatedProfilesDomainFilter(getState());
    let sortingMode = getRelatedProfilesSortingMode(getState());
    let listParams = {
        nodeType: NODE_TYPE.PROFILE,
        nameFilter: !_.isUndefined(params.nameFilter) ? params.nameFilter : nameFilter,
        sourceFilter: !_.isUndefined(params.sourceFilter) ? params.sourceFilter : sourceFilter,
        statusFilter: !_.isUndefined(params.statusFilter) ? params.statusFilter : statusFilter,
        domainFilter: !_.isUndefined(params.domainFilter) ? params.domainFilter : domainFilter,
        sortingMode: !_.isUndefined(params.sortingMode) ? params.sortingMode : sortingMode
    }
    dispatch(fetchGetRelatedProfiles(nodeId, listParams, params.page));
}

export const onFetchGetRelatedProfilesByPage = (page) => (dispatch) => {
    dispatch(onFetchGetRelatedProfiles({ page: page }));
}

export const updateRelatedProfilesNameFilter = createAction('UPDATE_RELATED_PROFILES_NAME_FILTER');
export const updateRelatedProfilesSourceFilter = createAction('UPDATE_RELATED_PROFILES_SOURCE_FILTER');
export const onUpdateRelatedProfilesSourceFilter = (sourceFilter) => (dispatch) => {
    dispatch(updateRelatedProfilesSourceFilter(sourceFilter));
    let params = {
        sourceFilter: sourceFilter,
    }
    dispatch(onFetchGetRelatedProfiles(params));
}
export const updateRelatedProfilesStatusFilter = createAction('UPDATE_RELATED_PROFILES_STATUS_FILTER');
export const onUpdateRelatedProfilesStatusFilter = (statusFilter) => (dispatch) => {
    dispatch(updateRelatedProfilesStatusFilter(statusFilter));
    let params = {
        statusFilter: statusFilter,
    }
    dispatch(onFetchGetRelatedProfiles(params));
}
export const updateRelatedProfilesDomainFilter = createAction('UPDATE_RELATED_PROFILES_DOMAIN_FILTER');
export const onUpdateRelatedProfilesDomainFilter = (domainFilter) => (dispatch) => {
    dispatch(updateRelatedProfilesDomainFilter(domainFilter));
    let params = {
        domainFilter: domainFilter,
    }
    dispatch(onFetchGetRelatedProfiles(params));
}
export const updateRelatedProfilesSortingMode = createAction('UPDATE_RELATED_PROFILES_SORTING');
export const onUpdateRelatedProfilesSortingMode = (sortingMode) => (dispatch) => {
    dispatch(updateRelatedProfilesSortingMode(sortingMode));
    dispatch(onFetchGetRelatedProfiles({ sortingMode: sortingMode }));
}

export const updateAddedRelatedProfiles = createAction('UPDATE_ADDED_RELATED_PROFILES');
export const onAddRelationsWithProfiles = (nodesToAdd) => (dispatch, getState) => {
    if (!_.isNil(nodesToAdd)) {
        let addedNodes = getAddedRelatedProfilesData(getState()) || [];
        dispatch(updateAddedRelatedProfiles(_.concat(addedNodes, nodesToAdd)));
    }
}

export const resetAddedRelatedProfiles = createAction('RESET_ADDED_RELATED_PROFILES');
export const resetRemovedRelatedProfiles = createAction('RESET_REMOVED_RELATED_PROFILES');

export const addRelationWithProfile = (nodeId) => (dispatch, getState) => {
    let data = getRelatedProfilesData(getState());
    let addedNodes = _.cloneDeep(getAddedRelatedProfilesData(getState())) || [];
    let nodeToAdd = _.find(data, (node) => node.id === nodeId);
    addedNodes.push(nodeToAdd);
    dispatch(updateAddedRelatedProfiles(addedNodes));
}
export const undoAddRelationWithProfile = (nodeId) => (dispatch, getState) => {
    let addedNodes = getAddedRelatedProfilesData(getState()) || [];
    addedNodes = _.filter(addedNodes, (node) => node.id !== nodeId);
    dispatch(updateAddedRelatedProfiles(addedNodes));
}

export const updateRemovedRelatedProfiles = createAction('UPDATE_REMOVED_RELATED_PROFILES');
export const removeRelationWithProfile = (nodeId) => (dispatch, getState) => {
    let data = getRelatedProfilesData(getState());
    let removedNodes = _.cloneDeep(getRemovedRelatedProfilesData(getState())) || [];
    let nodeToRemove = _.find(data, (node) => node.id === nodeId);
    removedNodes.push(nodeToRemove);
    dispatch(updateRemovedRelatedProfiles(removedNodes));
}
export const undoRemoveRelationWithProfile = (nodeId) => (dispatch, getState) => {
    let removedNodes = _.cloneDeep(getRemovedRelatedProfilesData(getState())) || [];
    removedNodes = _.filter(removedNodes, (node) => node.id !== nodeId);
    dispatch(updateRemovedRelatedProfiles(removedNodes));
}

//- State
const initialState = {
    data: null,
    currentPage: null,
    totalPages: null,
    addedProfiles: null,
    removedProfiles: null,
    error: null,
    fetching: false,
    nameFilter: "",
    sourceFilter: null,
    statusFilter: null,
    domainFilter: null,
    sortingMode: NODE_SORTING.NAME,
};

//- Reducers
export default handleActions({

    FETCH_GET_RELATED_PROFILES_DATA: (state) => {
        return { ...state, data: null, currentPage: null, totalPages: null, error: null, fetching: true };
    },
    FETCH_GET_RELATED_PROFILES_DATA_FAILED: (state, action) => {
        return { ...state, data: null, currentPage: null, totalPages: null, error: action.payload, fetching: false };
    },
    FETCH_GET_RELATED_PROFILES_DATA_SUCCESS: (state, action) => {
        return { ...state, data: action.payload.content, currentPage: action.payload.number, totalPages: action.payload.totalPages, error: null, fetching: false };
    },
    UPDATE_ADDED_RELATED_PROFILES: (state, action) => {
        return { ...state, addedProfiles: action.payload };
    },
    UPDATE_REMOVED_RELATED_PROFILES: (state, action) => {
        return { ...state, removedProfiles: action.payload };
    },
    UPDATE_RELATED_PROFILES_NAME_FILTER: (state, action) => {
        return { ...state, nameFilter: action.payload };
    },
    UPDATE_RELATED_PROFILES_SOURCE_FILTER: (state, action) => {
        return { ...state, sourceFilter: action.payload };
    },
    UPDATE_RELATED_PROFILES_STATUS_FILTER: (state, action) => {
        return { ...state, statusFilter: action.payload };
    },
    UPDATE_RELATED_PROFILES_DOMAIN_FILTER: (state, action) => {
        return { ...state, domainFilter: action.payload };
    },
    UPDATE_RELATED_PROFILES_SORTING: (state, action) => {
        return { ...state, sortingMode: action.payload };
    },
    RESET_ADDED_RELATED_PROFILES: (state) => {
        return { ...state, addedProfiles: null };
    },
    RESET_REMOVED_RELATED_PROFILES: (state) => {
        return { ...state, removedProfiles: null };
    },
}, initialState);

//- Selectors
export const getRelatedProfilesState = state => state.relatedProfiles;

export const getRelatedProfilesData = state => getRelatedProfilesState(state).data;

export const getRelatedProfilesCurrentPage = state => getRelatedProfilesState(state).currentPage;

export const getRelatedProfilesTotalPages = state => getRelatedProfilesState(state).totalPages;

export const getRelatedProfilesFetchingStatus = state => getRelatedProfilesState(state).fetching;

export const getAddedRelatedProfilesData = state => getRelatedProfilesState(state).addedProfiles;

export const getRemovedRelatedProfilesData = state => getRelatedProfilesState(state).removedProfiles;

export const getRelatedProfilesNameFilter = state => getRelatedProfilesState(state).nameFilter;

export const getRelatedProfilesSourceFilter = state => getRelatedProfilesState(state).sourceFilter;

export const getRelatedProfilesStatusFilter = state => getRelatedProfilesState(state).statusFilter;

export const getRelatedProfilesDomainFilter = state => getRelatedProfilesState(state).domainFilter;

export const getRelatedProfilesSortingMode = state => getRelatedProfilesState(state).sortingMode;
