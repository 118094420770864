import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CandidateNotesModal.scss';
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    FormGroup
} from 'reactstrap';
import _ from "lodash";
import Icon from '../../../../../common/icon/Icon';
import CandidateModalHeader from '../candidateModalHeader/CandidateModalHeader.container';

class CandidateNotesModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            note: "",
            audioNote: null
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.userId && this.props.userId !== prevProps.userId) {
            this.setState({ note: this.props.note });
        }
    }

    // TODO: (BE) add audio note
    render() {
        if (!this.props.userId) {
            return null;
        }
        return (
            <Modal isOpen={this.props.isOpen} className="candidate-notes-modal" toggle={this.onDiscardChanges}>
                <CandidateModalHeader toggle={this.onDiscardChanges} />
                <ModalBody>
                    {this.renderModalBody()}
                </ModalBody>
                <ModalFooter className="content-space-between">
                    <Button
                        color="danger"
                        onClick={this.onDiscardChanges}
                    >
                        {_.get(this.props.translation, 'actions.discard_button')}
                    </Button>
                    <Button
                        color="primary"
                        onClick={this.onSaveChanges}
                    >
                        {_.get(this.props.translation, 'actions.save_button')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalBody() {
        return (
            <>
                <h6>{_.get(this.props.translation, 'candidates_comparison_page.write_note_about_candidate_label')}</h6>
                <FormGroup>
                    <Input
                        type="textarea"
                        value={this.state.note}
                        onChange={this.handleChangeNote}
                        placeholder={_.get(this.props.translation, 'candidates_comparison_page.note_about_candidate_input_placeholder_label')}
                    />
                </FormGroup>
                <h6>{_.get(this.props.translation, 'candidates_comparison_page.record_note_about_candidate_label')}</h6>
                <div className="audio-note-container">
                    {/* TODO: add the ability to record audio note */}
                    <audio className="audio-player" controls></audio>
                    {
                        this.state.audioNote ?
                            <Icon
                                className="audio-control-icon"
                                type="delete"
                                title={_.get(this.props.translation, 'job_and_candidates.delete_record_label')}
                                onClick={() => { this.setState({ audioNote: null }) }}
                            />
                            :
                            <Icon
                                className="audio-control-icon"
                                type="record"
                                title={_.get(this.props.translation, 'job_and_candidates.record_audio_label')}
                                onClick={() => { this.setState({ audioNote: "audio-stream" }) }}
                            />
                    }
                </div>
            </>
        )
    }


    handleChangeNote = (e) => {
        this.setState({ note: e.target.value });
    }

    onDiscardChanges = () => {
        this.setState({ note: "" });
        this.props.onCancel();
    }

    onSaveChanges = () => {
        this.setState({ note: "" });
        this.props.onUpdateCandidateNotes(this.props.userId, this.state.note);
        // TODO: close modal on success
        this.props.onCancel();
    }
}

CandidateNotesModal.propTypes = {
    translation: PropTypes.object.isRequired,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    note: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onUpdateCandidateNotes: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default CandidateNotesModal;
