import React, { Component } from 'react';

class Job extends Component {
	render() {
		return (
			<svg height="512" viewBox="0 0 512 512" width="512">
				<g>
					<g>
						<path d="m80.917 141.418c17.079 7.786 33.709-5.187 33.709-21.935v-66.808c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v66.809c0 7.907-8.761 13.126-16.666 5.517-2.923-2.898-7.641-2.898-10.566.006-2.938 2.92-2.954 7.668-.034 10.606.354.356 3.602 3.546 8.557 5.805z"/>
						<path d="m286.029 143.766h.033s22.333-.1 28.488-.1c16.542 0 30-13.458 30-30 0-10.422-5.345-19.616-13.435-24.995 3.704-4.589 5.928-10.419 5.928-16.761 0-14.741-11.993-26.734-26.734-26.734h-24.281c-4.142 0-7.5 3.357-7.5 7.5v38.491.062 45.037c.001 4.129 3.348 7.5 7.501 7.5zm7.5-83.591h16.781c6.47 0 11.734 5.264 11.734 11.734s-5.264 11.734-11.734 11.734c-1.774 0-5.105.01-8.744.022h-8.036v-23.49zm0 38.522c2.511-.01 5.352-.021 8.031-.031h12.99c8.271 0 15 6.729 15 15s-6.729 15-15 15c-3.833 0-13.927.038-21.021.067z"/>
						<path d="m200.774 143.766c27.182 0 49.295-22.113 49.295-49.295s-22.114-49.296-49.295-49.296-49.295 22.114-49.295 49.296 22.114 49.295 49.295 49.295zm0-83.591c18.911 0 34.295 15.385 34.295 34.296 0 18.91-15.385 34.295-34.295 34.295s-34.295-15.385-34.295-34.295c0-18.911 15.385-34.296 34.295-34.296z"/>
						<path d="m190.327 347.69h-142.437c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h142.437c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z"/>
						<path d="m190.327 391.845h-142.437c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h142.437c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z"/>
						<path d="m190.327 435.999h-142.437c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h142.437c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z"/>
						<path d="m376.905 347.69h-142.436c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h142.437c4.142 0 7.5-3.357 7.5-7.5s-3.359-7.5-7.501-7.5z"/>
						<path d="m376.905 391.845h-142.436c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h142.437c4.142 0 7.5-3.357 7.5-7.5s-3.359-7.5-7.501-7.5z"/>
						<path d="m376.905 435.999h-142.436c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h142.437c4.142 0 7.5-3.357 7.5-7.5s-3.359-7.5-7.501-7.5z"/>
						<path d="m183.784 241.7h-129.351c-7.743 0-14.042 6.3-14.042 14.043v31.069c0 7.743 6.299 14.042 14.042 14.042h129.352c7.743 0 14.042-6.299 14.042-14.042v-31.069c0-7.743-6.3-14.043-14.043-14.043zm-.957 44.155h-127.437v-29.155h127.437z"/>
						<path d="m370.363 241.7h-129.352c-7.743 0-14.042 6.3-14.042 14.043v31.069c0 7.743 6.299 14.042 14.042 14.042h14.474c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-13.517v-29.154h127.437v29.154h-78.857c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h79.815c7.743 0 14.042-6.299 14.042-14.042v-31.069c0-7.743-6.299-14.043-14.042-14.043z"/>
						<path d="m479.877 261.345h-55.082v-229.128c0-17.713-14.41-32.123-32.123-32.123h-360.549c-17.713 0-32.123 14.41-32.123 32.123v447.558c0 17.713 14.41 32.123 32.123 32.123h426.207c.35 0 .635.056 2.568-.12 28.061-2.563 51.102-22.724 51.102-50.902v-167.408c0-17.713-14.41-32.123-32.123-32.123zm-447.754 235.553c-9.442 0-17.123-7.682-17.123-17.123v-290.22h15.359c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-15.359v-142.338c0-9.441 7.681-17.123 17.123-17.123h360.549c9.442 0 17.123 7.682 17.123 17.123v142.338h-344.374c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h344.374v271.321c0 13.998 5.665 26.756 14.871 36.021h-392.543zm464.877-36.022c0 19.907-16.195 36.103-36.103 36.103-.358 0-1.763-.047-2.133-.068-19.048-1.107-33.97-16.936-33.97-36.034v-184.532h55.082c9.442 0 17.123 7.682 17.123 17.123v167.408z"/>
					</g>
				</g>
			</svg>
		);
	}
}

export default Job;
