import React, { Component } from "react";
import PropTypes from "prop-types";
import "./ModelManagement.scss";
import _ from "lodash";
import SystemNodeStatus from "./systemNodeStatus/SystemNodeStatus.container";
import {
    NODE_STATUS,
    NODE_STATUS_LABEL,
    NODE_TYPE,
    NODE_TYPE_LABEL,
    NODE_TYPE_SHORT_LABEL,
    NODE_DOMAIN_LABEL,
} from "../../../constants/systemNodesConstants";
import SystemNodesPagination from "../../common/tablePagination/SystemNodesPagination.container";
import BlockLoader from "../../common/blockLoader/BlockLoader.container";
import Icon from "../../common/icon/Icon";
import { Table, Button, Col, Badge } from "reactstrap";
import NodesGraph from "./nodesGraph/NodesGraph.container";
import SystemNodeDetails from "./systemNodeDetails/SystemNodeDetails.container";
import CreateSystemNode from "./systemNodeDetails/CreateSystemNode.container";
import SystemNodesListFilters from "./nodesFilters/SystemNodesListFilters.container";
import SystemNodeSource from "./systemNodeSource/SystemNodeSource.container";
import CreateNodeModal from "./createNodeModal/createNodeModal.container";
import {
    onFetchGetSystemNodesList, onUpdateSystemNodesDomainFilter, onUpdateSystemNodesSortingMode,
    onUpdateSystemNodesSourceFilter,
    onUpdateSystemNodesStatusFilter,
    onUpdateSystemNodesTypeFilter,
    refreshSystemNodesExternalSources,
    updateSystemNodesNameFilter,
} from "../../../reducers/admin/systemModel";
import { fetchGetDomainList } from "../../../reducers/admin/domainsAndSC";
import { connect } from "react-redux";
import NodesFilters from "./nodesFilters/NodesFilters";

// TODO: remove hardcoded values
class ModelManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            actionType: null,
            nodeTypeToCreate: null,
        };
    }

    componentDidMount() {
        this.props.onMount();
        this.props.fetchList();
    }

    onSelectNode = (nodeId, nodeType) => () => {
        // TODO: fix logic (show warning or smth else)
        this.onClearNodeToAdd();
        this.props.onSelectNode(nodeId, nodeType);
    };

    onCreateNodeClick = (nodeType) => () => {
        this.setState({ nodeTypeToCreate: nodeType });
    };

    onClearNodeToAdd = () => {
        this.setState({ nodeTypeToCreate: null });
    };


    renderNodesGraphAndList() {
        if (this.props.currentNodeId) {
            return (
                <>
                    <div className="nodes-graph-content">
                        <Button className="button-with-icon button-with-icon-right back-to-list-button"
                                onClick={this.onSelectNode(null)}
                                size="sm">
                            {_.get(this.props.translation, "actions.back_button")}
                            <Icon type="arrow_right" color="light" size="xs" />
                        </Button>
                        <NodesGraph />
                    </div>
                    <div className="nodes-legend">
                        <div className="legend-item">
                            <SystemNodeStatus className="legend-item-profile"
                                              status={NODE_STATUS.REFERENCE_MODEL}
                                              text={_.get(
                                                  this.props.translation,
                                                  "system_nodes.profile_short_label",
                                              )} />
                            <b>{_.get(this.props.translation, "system_nodes.profile_label")}</b>
                        </div>
                        <hr className="legend-connector legend-connector__p-d" />
                        <div className="legend-item">
                            <SystemNodeStatus className="legend-item-domain"
                                              status={NODE_STATUS.REFERENCE_MODEL}
                                              text={_.get(
                                                  this.props.translation,
                                                  "system_nodes.domain_short_label",
                                              )} />
                            <b>{_.get(this.props.translation, "system_nodes.domain_label")}</b>
                        </div>
                        <hr className="legend-connector legend-connector__d-sc" />
                        <div className="legend-item">
                            <SystemNodeStatus className="legend-item-sc"
                                              status={NODE_STATUS.REFERENCE_MODEL}
                                              text={_.get(
                                                  this.props.translation,
                                                  "system_nodes.cluster_short_label",
                                              )} />
                            <b>
                                {_.get(this.props.translation, "system_nodes.skill_cluster_label")}
                            </b>
                        </div>
                        <hr className="legend-connector legend-connector__sc-s" />

                        <div className="legend-item">
                            <SystemNodeStatus className="legend-item-skill"
                                              status={NODE_STATUS.REFERENCE_MODEL}
                                              text={_.get(
                                                  this.props.translation,
                                                  "system_nodes.skill_short_label",
                                              )} />
                            <b>{_.get(this.props.translation, "system_nodes.skill_label")}</b>
                        </div>
                        <div className="d-flex flex-column w-50">
                            <hr className="legend-connector legend-connector__s-s w-100" />
                            <span className="legend-connector__subtitle">
                                {_.get(this.props.translation, "system_nodes.dependency_label")}
                            </span>
                        </div>
                        <div className="legend-connector__arrow">{">"}</div>
                        <div className="legend-item">
                            <SystemNodeStatus className="legend-item-skill"
                                              status={NODE_STATUS.REFERENCE_MODEL}
                                              text={_.get(
                                                  this.props.translation,
                                                  "system_nodes.skill_short_label",
                                              )} />
                            <b>{_.get(this.props.translation, "system_nodes.skill_label")}</b>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <SystemNodesListFilters />
                    <div className="nodes-list-content">
                        {this.renderNodesList()}
                    </div>
                </>
            );
        }
    }

    renderNodeManagement() {
        if (this.props.selectedGraphNode === null || typeof this.props.selectedGraphNode === "undefined") {
            return null
        }

        if (this.state.nodeTypeToCreate) {
            const nodeDate = {
                type: this.state.nodeTypeToCreate,
                status: NODE_STATUS.REFERENCE_MODEL,
                source: "Admin",
            };

            return <CreateSystemNode currentNodeData={nodeDate}
                                     onDiscardCreation={this.onClearNodeToAdd} />;
        } else {
            return <SystemNodeDetails />;
        }
    }

    renderNodesActions() {
        if (!this.props.currentNodeId) {
            return (
                <div className="nodes-actions">
                    <h5 className="text-center">
                        {_.get(this.props.translation, "model_management_page.manage_nodes_label")}
                    </h5>
                    <div className="nodes-actions-buttons">
                        <Button color="primary"
                                onClick={() => this.props.showCreateNodeModal(NODE_TYPE.PROFILE)}>
                            {_.get(
                                this.props.translation,
                                "model_management_page.create_new_profile_label",
                            )}
                        </Button>
                        <Button color="primary"
                                onClick={() => this.props.showCreateNodeModal(NODE_TYPE.CLUSTER)}>
                            {_.get(
                                this.props.translation,
                                "model_management_page.create_new_cluster_label",
                            )}
                        </Button>
                        <Button color="primary"
                                onClick={() => this.props.showCreateNodeModal(NODE_TYPE.SKILL)}>
                            {_.get(
                                this.props.translation,
                                "model_management_page.create_new_skill_label",
                            )}
                        </Button>
                        <Button color="primary"
                                onClick={() => this.props.showCreateNodeModal(NODE_TYPE.DOMAIN)}>
                            {_.get(
                                this.props.translation,
                                "model_management_page.create_new_domain_label",
                            )}
                        </Button>
                        <CreateNodeModal />
                    </div>
                </div>
            );
        }
    }

    renderNodesList() {
        return (
            <>
                <div className="nodes-list">
                    {this.renderTable()}
                    <BlockLoader loading={this.props.nodesListLoading} />
                </div>
                <SystemNodesPagination />
            </>
        );
    }

    renderTable() {
        if (this.props.nodesListLoading) {
            return null;
        }

        if (!this.props.nodesList || this.props.nodesList.length === 0) {
            return (
                <h6 className="text-center">
                    {_.get(this.props.translation, "model_management_page.no_data_label")}
                </h6>
            );
        }

        return (
            <Table className="clickable-rows">
                <tbody>{this.renderNodesData()}</tbody>
            </Table>
        );
    }

    renderNodesData() {
        return this.props.nodesList.map(nodeData => {
            return (
                <tr key={nodeData.id}
                    onClick={this.onSelectNode(nodeData.id, nodeData.type)}>
                    <td className="system-node-type-cell">
                        <SystemNodeStatus status={nodeData.status}
                                          statusDisabled={true}
                                          type={nodeData.type} />
                    </td>
                    <td className="system-node-name-cell">
                        <div className="system-node-name-cell-content">
                            <div className="node-name">{nodeData.title}</div>
                            {nodeData.type === NODE_TYPE.SKILL && !!nodeData.cluster ? (
                                <div className="node-parent-cluster" title={nodeData.cluster.title}>
                                    <i>
                                        {_.get(
                                            this.props.translation,
                                            "system_nodes.skill_cluster_label",
                                        ) + ": "}
                                    </i>
                                    <Badge className="node-parent-cluster-badge" color="light">
                                        {nodeData.cluster.title}
                                    </Badge>
                                </div>
                            ) : null}
                            {nodeData.domain && (<div className="node-domain"
                                                      title={_.get(
                                                          this.props.translation,
                                                          NODE_DOMAIN_LABEL[nodeData.domain],
                                                      )}>
                                    <i>
                                        {_.get(
                                            this.props.translation,
                                            "system_nodes.domain_label",
                                        ) + ": "}
                                    </i>
                                    {nodeData.domain.map((domain, i) => {
                                        return (
                                            <Badge key={i}
                                                   className="node-domain-badge ml-3" color="light">
                                                {domain.titles.en}
                                            </Badge>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </td>
                    <td className="system-node-source-cell">
                        <SystemNodeSource source={nodeData.source} />
                    </td>
                    <td className="system-node-action-cell table-cell-with-icon"
                        title={_.get(this.props.translation, "actions.show_details_label")}>
                        <Icon type="visibility" color="secondary" />
                    </td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div className="model-management">
                <h4 className="page-header">
                    {_.get(this.props.translation, "model_management_page.page_label")}
                </h4>
                <div className="model-management-content">
                    <Col sm="7" className="model-management-nodes-graph-and-list-col">
                        <div className="model-management-nodes-graph-and-list">
                            {this.renderNodesGraphAndList()}
                        </div>
                    </Col>
                    <Col sm="5"
                         className="model-management-nodes-details-col">
                        <div className="model-management-nodes-details">
                            {this.state.nodeTypeToCreate || this.props.currentNodeId ? this.renderNodeManagement() : this.renderNodesActions()}
                        </div>
                    </Col>
                </div>
            </div>
        );
    }

    z;
}

ModelManagement.propTypes = {
    // TODO: refactor
    translation: PropTypes.object.isRequired,
    nodesList: PropTypes.array,
    currentNodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedGraphNode: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.any]),
    onMount: PropTypes.func.isRequired,
    onSelectNode: PropTypes.func.isRequired,
    onGetNodesList: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {

        fetchList: (typeFilter) => {
            dispatch(onUpdateSystemNodesTypeFilter(typeFilter));
        },
    };
};

export default connect(null, mapDispatchToProps)(ModelManagement);
