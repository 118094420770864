import { connect } from 'react-redux';
import RouteView from './RouteView';
import { getTranslation } from '../../../../reducers/common/localization';
import { getServerErrorStatus } from '../../../../reducers/common/errors';
import { getUserDataLoading, getUserId } from '../../../../reducers/user/userData';
import { getLocationDataFetchingStatus } from '../../../../reducers/common/location';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        isServerError: getServerErrorStatus(state),
        loadingApp: getUserDataLoading(state) || getLocationDataFetchingStatus(state),
        userId: getUserId(state)
    };
};


const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteView);
