import React, { Component } from "react";
import ReactFlow from "react-flow-renderer";
import PropTypes from "prop-types";
import _ from "lodash";
import { USER_ROLE, ROUTES, USER_SUB_ROLE } from "../../../../constants/constants";
import WelcomePage from "../welcomePage/WelcomePage.container";
import nodeType from "./Nodes/LinksNodes";
import edgeType from "./Nodes/EdgeNode";
import SpawnPatterns from "./Nodes/SpawnPatterns";
import { withRouter } from "react-router";

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            elements: [],
            layerWidth: window.innerWidth * 0.8,
            layerHeight: window.innerHeight * 0.83 * 0.6,
        };
    }

    calculateNodesPosition() {
        const currentWindowWidth = window.innerWidth * 0.88;
        const currentWindowHeight = window.innerHeight * 0.83 * 0.6;

        this.setState({ layerWidth: currentWindowWidth, layerHeight: currentWindowHeight }, () => {
            this.updateNodes();
        });
    }

    onChangeSubRole() {
        if (this.props.userRole) {
            const nodes = this.renderPageLinks().map((preNode, i) => {
                return {
                    data: { ...preNode },
                    id: preNode.id,
                    position: {
                        x: this.state.layerWidth * SpawnPatterns[i].x,
                        y: this.state.layerHeight * SpawnPatterns[i].y,
                    },
                    className: "home-page__node",
                    isHidden: false,
                    type: "nodeType",
                };
            });

            const edges = nodes
                .filter((node) => !node.data.isMainNode)
                .map((nodeToEdges) => {
                    return {
                        id: `${nodes.filter((node) => node.data.isMainNode)[0].id}-${
                            nodeToEdges.id
                        }`,
                        source: nodes.filter((node) => node.data.isMainNode)[0].data.id,
                        target: nodeToEdges.id,
                        className: "home-page__edge home-page__edge--inactive",
                        animated: false,
                        type: "edgeType",
                        activeHelperLabel: nodeToEdges.data.helperLabel,
                        inactiveHelperLabel: "10",
                        isHidden: false,
                        isConnectionLine: true,
                        data: { helperLabel: "10", isActiveElem: false },
                    };
                });

            const initElements = [...nodes, ...edges].map((node) =>
                localStorage.getItem(node.id) && !node.isConnectionLine
                    ? {
                          ...node,
                          position: {
                              x:
                                  JSON.parse(localStorage.getItem(node.id)).xPos *
                                  this.state.layerWidth,
                              y:
                                  JSON.parse(localStorage.getItem(node.id)).yPos *
                                  this.state.layerHeight,
                          },
                      }
                    : { ...node }
            );

            this.setState({ elements: initElements }, () => this.updateNodes());
            window.addEventListener("resize", () => this.calculateNodesPosition());
        }
    }

    componentDidMount() {
        this.onChangeSubRole();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userSubRole !== this.props.userSubRole) this.onChangeSubRole();
    }

    highLightConnectionLine(elements, hoveredElementId) {
        return elements.map((element) => {
            const isActiveLine =
                element.target === hoveredElementId || element.source === hoveredElementId;
            return element.isConnectionLine
                ? {
                      ...element,
                      className: `home-page__edge ${
                          isActiveLine ? "home-page__edge--active" : "home-page__edge--inactive"
                      }`,
                      data: {
                          helperLabel: isActiveLine
                              ? element.activeHelperLabel
                              : element.inactiveHelperLabel,
                          isActiveElem: isActiveLine,
                      },
                  }
                : { ...element };
        });
    }
    updateNodes() {
        this.setState({
            elements: this.state.elements.map((element) =>
                !!localStorage.getItem(element.id) && !element.isConnectionLine
                    ? {
                          ...element,
                          position: {
                              x:
                                  JSON.parse(localStorage.getItem(element.id)).xPos *
                                  this.state.layerWidth,
                              y:
                                  JSON.parse(localStorage.getItem(element.id)).yPos *
                                  this.state.layerHeight,
                          },
                      }
                    : { ...element }
            ),
        });
    }

    saveBubblePos({ id, position }) {
        localStorage.setItem(
            id,
            JSON.stringify({
                xPos:
                    position.x <= 0
                        ? 50 / this.state.layerWidth
                        : position.x > this.state.layerWidth
                        ? 1
                        : position.x / this.state.layerWidth,
                yPos:
                    position.y <= 0
                        ? 50 / this.state.layerHeight
                        : position.y > this.state.layerHeight
                        ? 1
                        : position.y / this.state.layerHeight,
            })
        );

        this.updateNodes();
    }

    onNodeMouseLeave(elements) {
        return elements.map((element) => {
            return element.isConnectionLine
                ? {
                      ...element,
                      className: "home-page__edge home-page__edge--inactive",
                      data: { helperLabel: element.inactiveHelperLabel, isActiveElem: false },
                  }
                : { ...element };
        });
    }

    render() {
        if (
            (this.props.userRole === USER_ROLE.APPLICANT ||
                this.props.userRole === USER_ROLE.EMPLOYEE) &&
            this.props.isFirstLogin &&
            !this.props.isWelcomePageRead
        ) {
            return <WelcomePage />;
        }

        return (
            <div className="home-page">
                <h2 className="page-header home-page__header">
                    {_.get(this.props.translation, "home_page.page_label")}
                </h2>
                <ReactFlow
                    elements={this.state.elements}
                    panOnScrollMode="vertical"
                    style={{ width: "100%", height: "88.5vh" }}
                    nodeTypes={{ nodeType }}
                    edgeTypes={{ edgeType }}
                    nodesConnectable={false}
                    paneMoveable={false}
                    selectNodesOnDrag={false}
                    zoomOnDoubleClick={false}
                    zoomOnScroll={false}
                    panOnScroll={false}
                    elementsSelectable={false}
                    onNodeMouseEnter={(node) => {
                        this.setState({
                            elements: this.highLightConnectionLine(
                                this.state.elements,
                                node.target.id
                            ),
                        });
                    }}
                    onNodeMouseLeave={() =>
                        this.setState({ elements: this.onNodeMouseLeave(this.state.elements) })
                    }
                    onElementClick={(e, node) => {
                        this.props.history.push(node.data.to);
                    }}
                    onNodeDragStop={(e, node) => {
                        this.saveBubblePos(node);
                    }}
                />
            </div>
        );
    }

    renderPageLinks() {
        switch (this.props.userRole) {
            case USER_ROLE.SYS_ADMIN:
                return [
                    {
                        to: ROUTES.MODEL_MANAGEMENT,
                        key: ROUTES.MODEL_MANAGEMENT,
                        title: _.get(this.props.translation, "model_management_page.menu_label"),
                        id: "modelManagementNode",
                        isMainNode: true,
                        iconType: "model_management",
                    },
                    {
                        to: ROUTES.PERSONAL_SETTINGS,
                        key: ROUTES.PERSONAL_SETTINGS,
                        title: _.get(this.props.translation, "personal_settings_page.page_label"),
                        id: "personal_setings_node",
                        isMainNode: false,
                        iconType: "my_profile",
                    },
                ];

            case USER_ROLE.APPLICANT:
                // TODO: add isAccountFinished check (FE checks or flag from BE)
                if (this.props.isAccountFinished) {
                    return [
                        {
                            to: ROUTES.APPLICANT_SKILLS,
                            key: ROUTES.APPLICANT_SKILLS,
                            title: _.get(
                                this.props.translation,
                                "applicant_skills_page.page_label"
                            ),
                            id: "applicantSkillsNode",
                            isMainNode: true,
                            helperLabel: _.get(
                                this.props.translation,
                                "applicant_skills_page.page_sublabel"
                            ),
                        },
                        {
                            to: ROUTES.EDIT_APPLICANT_SKILLS,
                            key: ROUTES.EDIT_APPLICANT_SKILLS,
                            title: _.get(
                                this.props.translation,
                                "edit_applicant_skills_page.page_label"
                            ),
                            id: "editApplicantSkillsNode",
                            isMainNode: false,
                            helperLabel: _.get(
                                this.props.translation,
                                "edit_applicant_skills_page.page_sublabel"
                            ),
                            iconType: "job_passport",
                        },
                        {
                            to: ROUTES.JOB_COMPASS,
                            key: ROUTES.JOB_COMPASS,
                            title: _.get(this.props.translation, "job_compass_page.page_label"),
                            id: "jobCompassNode",
                            isMainNode: false,
                            helperLabel: _.get(
                                this.props.translation,
                                "job_compass_page.page_sublabel"
                            ),
                            iconType: "job_compass",
                        },
                    ];
                } else {
                    return [
                        {
                            to: ROUTES.APPLICANT_ACCOUNT,
                            key: ROUTES.APPLICANT_ACCOUNT,
                            title: _.get(
                                this.props.translation,
                                "applicant_account_page.page_label"
                            ),
                            id: "accountNode",
                            isMainNode: true,
                            helperLabel: _.get(
                                this.props.translation,
                                "applicant_account_page.page_sublabel"
                            ),
                            iconType: "my_profile",
                        },
                        {
                            to: ROUTES.EDIT_APPLICANT_SKILLS,
                            key: ROUTES.EDIT_APPLICANT_SKILLS,
                            title: _.get(
                                this.props.translation,
                                "edit_applicant_skills_page.page_label_short"
                            ),
                            id: "editApplicantSkillsNode",
                            isMainNode: false,
                            helperLabel: _.get(
                                this.props.translation,
                                "edit_applicant_skills_page.page_label"
                            ),
                            iconType: "job_passport",
                        },
                        {
                            to: ROUTES.SKILLS_JOURNEY,
                            key: ROUTES.SKILLS_JOURNEY,
                            title: _.get(this.props.translation, "skills_journey_page.page_label"),
                            id: "skillsJourneyNode",
                            isMainNode: false,
                            helperLabel: _.get(
                                this.props.translation,
                                "skills_journey_page.page_sublabel"
                            ),
                            iconType: "skills_journey",
                        },
                        {
                            to: ROUTES.QUICKFIND_JOB,
                            key: ROUTES.QUICKFIND_JOB,
                            title: _.get(this.props.translation, "quickfind_job_page.page_label"),
                            id: "quickfindJobNode",
                            isMainNode: false,
                            helperLabel: _.get(
                                this.props.translation,
                                "quickfind_job_page.page_sublabel"
                            ),
                            iconType: "job_discovery",
                        },
                        {
                            to: ROUTES.JOB_COMPASS,
                            key: ROUTES.JOB_COMPASS,
                            title: _.get(this.props.translation, "job_compass_page.page_label"),
                            id: "jobCompassNode",
                            isMainNode: false,
                            helperLabel: _.get(
                                this.props.translation,
                                "job_compass_page.page_sublabel"
                            ),
                            iconType: "job_compass",
                        },
                        {
                            to: ROUTES.JOBS,
                            key: ROUTES.JOBS,
                            title: _.get(this.props.translation, "jobs_page.page_label"),
                            id: "ongoingApplicationsNode",
                            isMainNode: false,
                            helperLabel: _.get(this.props.translation, "jobs_page.page_sublabel"),
                            iconType: "sand_watches",
                        },
                    ];
                }

            case USER_ROLE.EMPLOYEE:
                switch (this.props.userSubRole) {
                    case USER_SUB_ROLE.ANCHOR:
                        return [
                            {
                                to: ROUTES.STAFF_AND_DEPARTMENTS,
                                key: ROUTES.STAFF_AND_DEPARTMENTS,
                                title: _.get(
                                    this.props.translation,
                                    "staff_and_departments_page.page_label"
                                ),
                                id: "staffAndDepartmentNode",
                                isMainNode: false,
                                helperLabel: _.get(
                                    this.props.translation,
                                    "staff_and_departments_page.page_sublabel"
                                ),
                                iconType: "staff_and_departments",
                            },
                            {
                                to: ROUTES.COMPANY_PROFILE,
                                key: ROUTES.COMPANY_PROFILE,
                                title: _.get(
                                    this.props.translation,
                                    "company_profile_page.page_label"
                                ),
                                id: "companyProfile",
                                isMainNode: true,
                                helperLabel: _.get(
                                    this.props.translation,
                                    "company_profile_page.page_sublabel"
                                ),
                                iconType: "company_profile",
                            },
                            {
                                to: ROUTES.CORPORATE_IDENTITY,
                                key: ROUTES.CORPORATE_IDENTITY,
                                title: _.get(
                                    this.props.translation,
                                    "corporate_identity_page.page_label"
                                ),
                                id: "corporateIdentity",
                                isMainNode: false,
                                helperLabel: _.get(
                                    this.props.translation,
                                    "corporate_identity_page.page_sublabel"
                                ),
                                iconType: "company_profile",
                            },
                            {
                                to: ROUTES.MY_PROFILE,
                                key: ROUTES.MY_PROFILE,
                                title: _.get(this.props.translation, "my_profile_page.label"),
                                id: "myProfilePAge",
                                isMainNode: false,
                                helperLabel: _.get(
                                    this.props.translation,
                                    "financial_page.subtitle"
                                ),
                                iconType: "my_profile",
                            },
                        ];
                    case USER_SUB_ROLE.HM:
                        return [
                            {
                                to: ROUTES.MY_PROFILE,
                                key: ROUTES.MY_PROFILE,
                                title: _.get(this.props.translation, "my_profile_page.label"),
                                id: "myProfilePAge",
                                isMainNode: true,
                                helperLabel: _.get(
                                    this.props.translation,
                                    "financial_page.subtitle"
                                ),
                                iconType: "my_profile",
                            },
                            {
                                to: ROUTES.APPLICANT_QUICKFIND,
                                key: ROUTES.APPLICANT_QUICKFIND,
                                title: _.get(
                                    this.props.translation,
                                    "applicant_quickfind_page.page_label"
                                ),
                                id: "applicantQuickFindNode",
                                isMainNode: false,
                                iconType: "people_discovery",
                                helperLabel: _.get(
                                    this.props.translation,
                                    "applicant_quickfind_page.page_sublabel"
                                ),
                            },
                            {
                                to: ROUTES.DEPARTMENTS_PROFILE,
                                key: ROUTES.DEPARTMENTS_PROFILE,
                                title: _.get(this.props.translation, "departments_profile.label"),
                                id: "departmentsProfileNode",
                                isMainNode: false,
                                iconType: "staff_and_departments",
                                helperLabel: _.get(
                                    this.props.translation,
                                    "departments_profile.sublabel"
                                ),
                            },
                            {
                                to: ROUTES.JOBS,
                                key: ROUTES.JOBS,
                                title: _.get(this.props.translation, "jobs_page.page_label"),
                                id: "ongoingApplicationsNode",
                                isMainNode: false,
                                helperLabel: _.get(
                                    this.props.translation,
                                    "jobs_page.page_hr_sublabel"
                                ),
                                iconType: "job_discovery",
                            },
                        ];
                    case USER_SUB_ROLE.BO:
                        return [
                            {
                                to: ROUTES.MY_PROFILE,
                                key: ROUTES.MY_PROFILE,
                                title: _.get(this.props.translation, "my_profile_page.label"),
                                id: "myProfilePAge",
                                isMainNode: true,
                                helperLabel: _.get(
                                    this.props.translation,
                                    "financial_page.subtitle"
                                ),
                                iconType: "my_profile",
                            },
                            {
                                to: ROUTES.JOBS,
                                key: ROUTES.JOBS,
                                title: _.get(this.props.translation, "jobs_page.page_label"),
                                id: "ongoingApplicationsNode",
                                isMainNode: false,
                                helperLabel: _.get(
                                    this.props.translation,
                                    "jobs_page.page_sublabel"
                                ),
                                iconType: "sand_watches",
                            },
                            {
                                to: ROUTES.APPLICANT_QUICKFIND,
                                key: ROUTES.APPLICANT_QUICKFIND,
                                title: _.get(
                                    this.props.translation,
                                    "applicant_quickfind_page.page_title"
                                ),
                                id: "applicantQuickFindNode",
                                isMainNode: false,
                                iconType: "people_discovery",
                                helperLabel: _.get(
                                    this.props.translation,
                                    "applicant_quickfind_page.page_sublabel"
                                ),
                            },
                            {
                                to: ROUTES.DEPARTMENTS_PROFILE,
                                key: ROUTES.DEPARTMENTS_PROFILE,
                                title: _.get(this.props.translation, "departments_profile.label"),
                                id: "departmentsProfileNode",
                                isMainNode: false,
                                iconType: "staff_and_departments",
                                helperLabel: _.get(
                                    this.props.translation,
                                    "departments_profile.sublabel"
                                ),
                            },
                            {
                                to: ROUTES.TEAM_PROFILE,
                                key: ROUTES.TEAM_PROFILE,
                                title: _.get(this.props.translation, "team_profile.label"),
                                id: "teamProfileNode",
                                isMainNode: false,
                                iconType: "staff_and_departments",
                                helperLabel: _.get(this.props.translation, "team_profile_sublabel"),
                            },
                        ];

                    case "NO_SUB_ROLES":
                        return [
                            {
                                to: ROUTES.MY_PROFILE,
                                key: ROUTES.MY_PROFILE,
                                title: _.get(this.props.translation, "my_profile_page.label"),
                                id: "myProfilePAge",
                                isMainNode: true,
                                helperLabel: _.get(
                                    this.props.translation,
                                    "financial_page.subtitle"
                                ),
                                iconType: "my_profile",
                            },
                        ];

                    default:
                        break;
                }
                break;

            default:
                break;
        }
    }
}

HomePage.propTypes = {
    translation: PropTypes.object.isRequired,
    userRole: PropTypes.string,
    userSubRole: PropTypes.string,
    isFirstLogin: PropTypes.bool,
    isWelcomePageRead: PropTypes.bool,
};

export default withRouter(HomePage);
