import React, { Component } from "react";

class RedNotification extends Component {
    render() {
        const { number, x, y, rx, ry, r, numberSize } = this.props;
        return (
            <svg height="16" width="16">
                <circle cx="8" cy="8" r="8" fill="#ffffff" />

                <circle cx={rx || "8"} cy={ry || "8"} r={r || "7"} fill="#bf222b" />
                <text
                    x={`${x || 43}%`}
                    y={`${y || 47}%`}
                    textAnchor="middle"
                    stroke="#ffffff"
                    fontSize={`${numberSize || 9}px`}
                    strokeWidth="1px"
                >
                    {number}
                </text>
            </svg>
        );
    }
}

export default RedNotification;
