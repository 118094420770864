import { createAction, handleActions } from "redux-actions";

//- Modal types
export const MODAL_SKILL_NOTES = "MODAL_SKILL_NOTES";
export const MODAL_NO_PROFILES = "MODAL_NO_PROFILES";
export const MODAL_SKILLS_WITHOUT_LEVEL = "MODAL_SKILLS_WITHOUT_LEVEL";
export const MODAL_REMOVE_SKILL = "MODAL_REMOVE_SKILL";
export const MODAL_REMOVE_CLUSTER = "MODAL_REMOVE_CLUSTER";
export const MODAL_CANDIDATE_NOTES = "MODAL_CANDIDATE_NOTES";
export const MODAL_CANDIDATES_GROUP = "MODAL_CANDIDATES_GROUP";
export const MODAL_CANDIDATE_CROSS_JOB_INFO = "MODAL_CANDIDATE_CROSS_JOB_INFO";
export const MODAL_CANDIDATE_JOB_SPECIFIC_INFO = "MODAL_CANDIDATE_JOB_SPECIFIC_INFO";
export const MODAL_CANDIDATE_ESSENCE_INFO = "MODAL_CANDIDATE_ESSENCE_INFO";
export const MODAL_ADD_RELATION_WITH_SKILL = "MODAL_ADD_RELATION_WITH_SKILL";
export const MODAL_ADD_RELATION_WITH_CLUSTER = "MODAL_ADD_RELATION_WITH_CLUSTER";
export const MODAL_ADD_RELATION_WITH_PROFILE = "MODAL_ADD_RELATION_WITH_PROFILE";
export const MODAL_CHANGE_PARENT_CLUSTER = "MODAL_CHANGE_PARENT_CLUSTER";
export const MODAL_LICENSE_AGREEMENT = "MODAL_LICENSE_AGREEMENT";
export const MODAL_REGISTER_ORG_BY_ANCHOR = "MODAL_REGISTER_ORG_BY_ANCHOR";
export const MODAL_ADD_STAFF_MEMBER = "MODAL_ADD_STAFF_MEMBER";
export const MODAL_REMOVE_STAFF_MEMBER = "MODAL_REMOVE_STAFF_MEMBER";
export const MODAL_RESEND_STAFF_MEMBER_ACTIVATION = "MODAL_RESEND_STAFF_MEMBER_ACTIVATION";
export const MODAL_CHANGE_ANCHOR_STAFF_MEMBER = "MODAL_CHANGE_ANCHOR_STAFF_MEMBER";
export const MODAL_ADD_DEPARTMENT = "MODAL_ADD_DEPARTMENT";
export const MODAL_EDIT_DEPARTMENT = "MODAL_EDIT_DEPARTMENT";
export const MODAL_ADD_EMPLOYEE_TO_DEPARTMENT = "MODAL_ADD_EMPLOYEE_TO_DEPARTMENT";
export const MODAL_REMOVE_EMPLOYEE_FROM_DEPARTMENT = "MODAL_REMOVE_EMPLOYEE_FROM_DEPARTMENT";
export const MODAL_REMOVE_DEPARTMENT = "MODAL_REMOVE_DEPARTMENT";
export const MODAL_ADD_PAST_JOB_META_DATA = "MODAL_ADD_PAST_JOB_META_DATA";
export const MODAL_QUICKFIND_SET_SKILL_LEVEL = "MODAL_QUICKFIND_SET_SKILL_LEVEL";
export const MODAL_ALTERNATIVE_NOW_BLACK_LIST = "MODAL_ALTERNATIVE_NOW_BLACK_LIST";
export const MODAL_INVITATIONS_SEND_FEEDBACK = "MODAL_INVITATIONS_SEND_FEEDBACK";
export const MODAL_INVITATIONS_TOP_X_COMPARE = "MODAL_INVITATIONS_TOP_X_COMPARE";
export const MODAL_ADD_JOB_REQUIRED_SKILLS = "MODAL_ADD_JOB_REQUIRED_SKILLS";
export const MODAL_RICH_MEDIA = "MODAL_RICH_MEDIA";
export const MODAL_JOB_EDIT = "MODAL_JOB_EDIT";
export const MODAL_APPLICANT_APPLIES_JOB = "MODAL_APPLICANT_APPLIES_JOB";
export const DOUBLE_FA_MODAL = "DOUBLE_FA_MODAL";
export const DELETE_APPLICANT_ACCOUNT_MODAL = "DELETE_APPLICANT_ACCOUNT_MODAL";
export const CUSTOM_SKILL_LEVEL_MODAL = "SELECT_ONE_PROFILE_MODAL";
export const POTENTIAL_CLUSTERS_MODAL = "POTENTIAL_CLUSTERS_MODAL";
export const CREATE_NODE_MODAL = "CREATE_NODE_MODAL";

//- Actions
export const showModal = createAction("MODAL_SHOW", (modalType, jobAppId) => ({
    modalType,
    jobAppId,
}));

export const showCreateNodeModal = createAction("MODAL_SHOW_CREATE_NODE", (nodeType) => ({
    nodeType,
}));

export const hideModal = () => (dispatch, getState) => {
    let activeModalType = getActiveModalType(getState());
    if (activeModalType) {
        dispatch({ type: "MODAL_HIDE" });
    }
};

//- State
export const initialState = {
    modalType: null,
    activeJobApplicationId: null,
    activeJobId: null,
    nodeTypeToCreate: null,
};

//- Reducers
export default handleActions(
    {
        MODAL_SHOW: (state, action) => {
            return {
                modalType: action.payload.modalType,
                activeJobApplicationId:
                    action.payload.modalType === MODAL_INVITATIONS_SEND_FEEDBACK
                        ? action.payload.jobAppId
                        : "",
                activeJobId:
                    action.payload.modalType === MODAL_JOB_EDIT ||
                    action.payload.modalType === MODAL_APPLICANT_APPLIES_JOB
                        ? action.payload.jobAppId
                        : null,
            };
        },
        MODAL_SHOW_CREATE_NODE: (state, action) => {
            return {
                modalType: CREATE_NODE_MODAL,
                nodeTypeToCreate: action.payload.nodeType,
            };
        },
        MODAL_HIDE: () => initialState,
    },
    initialState
);

//- Selectors
export const getActiveModalState = (state) => state.activeModal;

export const getActiveModalType = (state) => {
    let activeModalData = getActiveModalState(state);

    if (!activeModalData) {
        return null;
    } else {
        return activeModalData.modalType;
    }
};

export const getActiveJobAppId = (state) => state.activeModal.activeJobApplicationId;
export const getActiveJobId = (state) => state.activeModal.activeJobId;
export const getNodeTypeToCreate = (state) => getActiveModalState(state).nodeTypeToCreate;
