export const USER_ROLE = {
    SYS_ADMIN: "SYS_ADMIN",
    EMPLOYEE: "EMPLOYEE",
    APPLICANT: "APPLICANT",
};

export const NO_SUB_ROLES = "NO_SUB_ROLES";

export const USER_SUB_ROLE = {
    ANCHOR: "Anchor",
    HM: "HRManager",
    BO: "BusinessOwner",
};

export const CORPORATE_CONSTANTS = {
    ORGANIZATION: "organization",
    DEPARTMENT: "department",
    TEAM: "team",
};

export const monthArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export const TABLE_STATUSES = {
    UNPUBLISHED: "unpublished",
    CREATED: "created",
    COMPLETED: "completed",
    APPROVED: "approved",
    PRE_FILTERING: "pre_filtering",
    DEEP_SCREENING: "deep_screening",
    INVITATIONS: "invitations",
    CLOSED: "closed",
    PUBLISHED: "published",
};

export const TABLE_STATUSES_LABELS = {
    [TABLE_STATUSES.CREATED]: "Created",
    [TABLE_STATUSES.COMPLETED]: "Completed",
    [TABLE_STATUSES.APPROVED]: "Approved",
    [TABLE_STATUSES.PRE_FILTERING]: "Pre Filtering",
    [TABLE_STATUSES.DEEP_SCREENING]: "Deep Screening",
    [TABLE_STATUSES.INVITATIONS]: "Invitations",
    [TABLE_STATUSES.CLOSED]: "Closed",
};

export const allowedMediaForEachRole = {
    [USER_SUB_ROLE.ANCHOR]: [
        "audioFile",
        "cvFile",
        "pictureFiles",
        "profilePictureFile",
        "videoFile",
    ],
    [USER_SUB_ROLE.HM]: ["audioFile", "cvFile", "pictureFiles", "videoFile"],
    [USER_SUB_ROLE.BO]: ["audioFile", "cvFile", "pictureFiles", "videoFile"],
    [USER_ROLE.APPLICANT]: [
        "audioFile",
        "cvFile",
        "pictureFiles",
        "profilePictureFile",
        "videoFile",
    ],
};

export const allowedMediaTypeForEachMediaSource = {
    audioFile: "audio/mpeg, audio/mp4, audio/vnd.wav, audio/m4a",
    cvFile: "application/pdf,application/vnd.apple.pages,application/msword,application/rtf,text/plain,.doc, .docx",
    pictureFiles: "image/png, image/jpeg",
    profilePictureFile: "image/png, image/jpeg",
    videoFile:
        "video/mpeg, video/mp4, video/ogg, video/quicktime, video/webm, video/x-m4v, video/ms-asf, video/x-ms-wmv, video/x-msvideo",
};

export const allowedMediaOwnerForEachRole = {
    [USER_SUB_ROLE.ANCHOR]: ["corporate"],
    [USER_SUB_ROLE.HM]: ["departments"],
    [USER_SUB_ROLE.BO]: ["departments", "teams"],
    [USER_ROLE.APPLICANT]: ["myProfile"],
};

export const requiredMediaForRole = {
    [USER_SUB_ROLE.ANCHOR]: [],
    [USER_SUB_ROLE.HM]: [],
    [USER_SUB_ROLE.BO]: [],
    [USER_ROLE.APPLICANT]: [],
};

export const tableConfig = {
    defaultTables: [TABLE_STATUSES.UNPUBLISHED, TABLE_STATUSES.PUBLISHED, TABLE_STATUSES.CLOSED],
    lifecycleStatuses: {
        [TABLE_STATUSES.UNPUBLISHED]: [
            TABLE_STATUSES.CREATED,
            TABLE_STATUSES.COMPLETED,
            TABLE_STATUSES.APPROVED,
        ],
        [TABLE_STATUSES.PUBLISHED]: [
            TABLE_STATUSES.PUBLISHED,
            TABLE_STATUSES.PRE_FILTERING,
            TABLE_STATUSES.DEEP_SCREENING,
            TABLE_STATUSES.INVITATIONS,
        ],
        [TABLE_STATUSES.CLOSED]: [TABLE_STATUSES.CLOSED],
    },
    [TABLE_STATUSES.UNPUBLISHED]: [
        "reference",
        "title",
        "department",
        "bo",
        "hrm",
        "approvedByBo",
        "approvedByHrm",
        "status",
        "nextStep",
    ],
    // [TABLE_STATUSES.CREATED]: [
    //     "reference",
    //     "title",
    //     "department",
    //     "bo",
    //     "hrm",
    //     "approvedByBo",
    //     "approvedByHrm",
    //     "nextStep",
    // ],
    [TABLE_STATUSES.PUBLISHED]: [
        "reference",
        "title",
        "department",
        "bo",
        "hrm",
        "status",
        "nextStep",
    ],
    [TABLE_STATUSES.CLOSED]: [
        "reference",
        "title",
        "department",
        "bo",
        "hrm",
        "status",
        "deadline",
        "finalComparison",
    ],
};

export const USER_ROLE_TRANSLATION = {
    [USER_ROLE.SYS_ADMIN]: "roles.admin_label",
    [USER_ROLE.EMPLOYEE]: "roles.employee_label",
    [USER_ROLE.APPLICANT]: "roles.applicant_label",
};

export const USER_SUB_ROLE_TRANSLATION = {
    [USER_SUB_ROLE.ANCHOR]: "roles.anchor_label",
    [USER_SUB_ROLE.HM]: "roles.hm_label",
    [USER_SUB_ROLE.BO]: "roles.bo_label",
};

export const ROUTES = {
    HOME: "/home",
    SUPPORT: "/support",
    LOGIN: "/login",
    ACCOUNT: "/account",
    APPLICANT_SKILLS: "/skills",
    EDIT_APPLICANT_SKILLS: "/edit-skills",
    CREATE_NEW_JOB: "/create-new-job",
    OPEN_JOBS: "/open-jobs",
    OPEN_JOB_DETAILS: "/open-job-details",
    OPEN_JOB_COMPARISON: "/open-job-comparison",
    MODEL_MANAGEMENT: "/model-management",
    CREATE_ACCOUNT: "/create-account",
    APPLICANT_REGISTRATION: "/applicant-registration",
    COMPANY_REGISTRATION: "/company-registration",
    ACTIVATE_ACCOUNT: "/activation",
    RESTORE_ACCOUNT: "/login/restore",
    RESET_PASSWORD: "/resetPassword",
    RESEND_ACTIVATION: "/resend-activation",
    APPLICANT_ACCOUNT: "/applicant-account",
    JOB_COMPASS: "/job-compass",
    SKILLS_JOURNEY: "/skills-journey",
    STAFF_AND_DEPARTMENTS: "/staff-and-departments",
    DEPARTMENT_DETAILS: "/department",
    APPLICANT_QUICKFIND: "/applicant-quickfind",
    APPLICANTS_QUICKFIND_COMPARISON: "/applicants-quickfind-comparison",
    QUICKFIND_JOB: "/quickfind-job",
    JOBS_QUICKFIND_COMPARISON: "/jobs-quickfind-comparison",
    POST_JOB: "/post-job",
    CHOOSE_JOB_SKILLS: "/choose-job-skills",
    APPLICANT_PROFILE: "/applicant-profile",
    JOB_PROFILE: "/job-profile",
    JOBS: "/jobs",
    INVITATIONS_MANAGEMENT: "/invitations-management",
    COMPANY_PROFILE: "/company-profile",
    CORPORATE_IDENTITY: "/corporate-identity",
    PERSONAL_SETTINGS: "/personal-settings",
    CORPORATE_PROFILE: "/corporate-profile",
    DEPARTMENTS_PROFILE: "/departments-profile",
    TEAM_PROFILE: "/team-profile",
    FINANCIALS: "/financials",
    MY_PROFILE: "/my-profile",
};

export const UNAUTHORIZED_ROUTES = [
    ROUTES.LOGIN,
    ROUTES.CREATE_ACCOUNT,
    ROUTES.APPLICANT_REGISTRATION,
    ROUTES.COMPANY_REGISTRATION,
    ROUTES.RESTORE_ACCOUNT,
    ROUTES.SUPPORT,
    ROUTES.RESEND_ACTIVATION,
];

export const ROUTES_ACCESSIBLE_VIA_EMAIL = [ROUTES.ACTIVATE_ACCOUNT, ROUTES.RESET_PASSWORD];

export const COMMON_AUTHORIZED_ROUTES = [ROUTES.ACCOUNT];

export const ROUTES_VISIBLE_TO_ALL = [ROUTES.APPLICANT_PROFILE, ROUTES.JOB_PROFILE];

export const SYS_ADMIN_ROUTES = [ROUTES.MODEL_MANAGEMENT];

export const APPLICANT_ROUTES = [
    ROUTES.APPLICANT_SKILLS,
    ROUTES.EDIT_APPLICANT_SKILLS,
    ROUTES.APPLICANT_ACCOUNT,
    ROUTES.SKILLS_JOURNEY,
    ROUTES.QUICKFIND_JOB,
    ROUTES.JOBS_QUICKFIND_COMPARISON,
    ROUTES.JOB_COMPASS,
    ROUTES.JOBS,
    ROUTES.INVITATIONS_MANAGEMENT,
    ROUTES.PERSONAL_SETTINGS,
];

export const EMPLOYEE_ROUTES_BY_SUB_ROLE = {
    [USER_SUB_ROLE.ANCHOR]: [
        ROUTES.STAFF_AND_DEPARTMENTS,
        ROUTES.DEPARTMENT_DETAILS,
        ROUTES.CHOOSE_JOB_SKILLS,
        ROUTES.CREATE_NEW_JOB,
        ROUTES.COMPANY_PROFILE,
        ROUTES.PERSONAL_SETTINGS,
        ROUTES.CORPORATE_PROFILE,
        ROUTES.DEPARTMENTS_PROFILE,
        ROUTES.CORPORATE_IDENTITY,
        ROUTES.CORPORATE_IDENTITY,
        ROUTES.FINANCIALS,
        // TODO: rename applicant account into common account for subroles
        ROUTES.MY_PROFILE,
    ],
    [USER_SUB_ROLE.HM]: [
        ROUTES.CREATE_NEW_JOB,
        ROUTES.OPEN_JOBS,
        ROUTES.OPEN_JOB_DETAILS,
        ROUTES.OPEN_JOB_COMPARISON,
        ROUTES.APPLICANT_QUICKFIND,
        ROUTES.APPLICANTS_QUICKFIND_COMPARISON,
        ROUTES.POST_JOB,
        ROUTES.CHOOSE_JOB_SKILLS,
        ROUTES.JOBS,
        ROUTES.INVITATIONS_MANAGEMENT,
        ROUTES.CORPORATE_IDENTITY,
        ROUTES.PERSONAL_SETTINGS,
        ROUTES.DEPARTMENTS_PROFILE,
        ROUTES.MY_PROFILE,
    ],
    [USER_SUB_ROLE.BO]: [
        ROUTES.JOBS,
        ROUTES.INVITATIONS_MANAGEMENT,
        ROUTES.POST_JOB,
        ROUTES.CHOOSE_JOB_SKILLS,
        ROUTES.PERSONAL_SETTINGS,
        ROUTES.TEAM_PROFILE,
        ROUTES.DEPARTMENTS_PROFILE,
        ROUTES.MY_PROFILE,
        ROUTES.APPLICANT_QUICKFIND,
    ],

    [NO_SUB_ROLES]: [ROUTES.JOBS, ROUTES.INVITATIONS_MANAGEMENT, ROUTES.CHOOSE_JOB_SKILLS],
};

export const FIELD_ERROR_STATUS = {
    ERROR: "error",
    WARNING: "warning",
    INFO: "info",
    SUCCESS: "success",
    CHANGED: "changed",
};

export const FIELD_ERROR_STATUS_COLOR = {
    [FIELD_ERROR_STATUS.ERROR]: "#d64021",
    [FIELD_ERROR_STATUS.WARNING]: "#ffc107",
    [FIELD_ERROR_STATUS.SUCCESS]: "#28a745",
    [FIELD_ERROR_STATUS.CHANGED]: "#007bff",
};

export const FIELD_ERROR_STATUS_SHADOW = {
    [FIELD_ERROR_STATUS.ERROR]: "rgba(214, 63, 33, 0.25)",
    [FIELD_ERROR_STATUS.WARNING]: "rgba(255, 193, 7, 0.25)",
    [FIELD_ERROR_STATUS.SUCCESS]: "rgb(40, 167, 69, 0.25)",
    [FIELD_ERROR_STATUS.CHANGED]: "rgba(0, 123, 255, 0.25)",
};

export const REQUEST_ERROR = {
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
};

export const DATE_FORMAT = "YYYY-MM-DD";

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 32;

export const LOCATION_MAP = {
    AFRICA: [
        "AO",
        "BF",
        "BI",
        "BJ",
        "BW",
        "CD",
        "CF",
        "CG",
        "CI",
        "CM",
        "CV",
        "DJ",
        "DZ",
        "EG",
        "EH",
        "ER",
        "ET",
        "GA",
        "GH",
        "GM",
        "GN",
        "GQ",
        "GW",
        "KE",
        "KM",
        "LR",
        "LS",
        "LY",
        "MA",
        "MG",
        "ML",
        "MR",
        "MU",
        "MW",
        "MZ",
        "NA",
        "NE",
        "NG",
        "RE",
        "RW",
        "SC",
        "SD",
        "SH",
        "SL",
        "SN",
        "SO",
        "SS",
        "ST",
        "SZ",
        "TD",
        "TG",
        "TN",
        "TZ",
        "UG",
        "YT",
        "ZA",
        "ZM",
        "ZW",
    ],
    ANTARCTICA: ["AQ", "BV", "GS", "HM", "TF"],
    ASIA: [
        "AE",
        "AF",
        "AM",
        "AZ",
        "BD",
        "BH",
        "BN",
        "BT",
        "CC",
        "CN",
        "CX",
        "CY",
        "GE",
        "HK",
        "ID",
        "IL",
        "IN",
        "IO",
        "IQ",
        "IR",
        "JO",
        "JP",
        "KG",
        "KH",
        "KP",
        "KR",
        "KW",
        "KZ",
        "LA",
        "LB",
        "LK",
        "MM",
        "MN",
        "MO",
        "MV",
        "MY",
        "NP",
        "OM",
        "PH",
        "PK",
        "PS",
        "QA",
        "SA",
        "SG",
        "SY",
        "TH",
        "TJ",
        "TL",
        "TM",
        "TW",
        "UZ",
        "VN",
        "YE",
    ],
    EUROPE: [
        "AD",
        "AL",
        "AT",
        "AX",
        "BA",
        "BE",
        "BG",
        "BY",
        "CH",
        "CZ",
        "DE",
        "DK",
        "EE",
        "ES",
        "FI",
        "FO",
        "FR",
        "GB",
        "GG",
        "GI",
        "GR",
        "HR",
        "HU",
        "IE",
        "IM",
        "IS",
        "IT",
        "JE",
        "LI",
        "LT",
        "LU",
        "LV",
        "MC",
        "MD",
        "ME",
        "MK",
        "MT",
        "NL",
        "NO",
        "PL",
        "PT",
        "RO",
        "RS",
        "RU",
        "SE",
        "SI",
        "SJ",
        "SK",
        "SM",
        "TR",
        "UA",
        "VA",
    ],
    NORTH_AMERICA: [
        "AG",
        "AI",
        "AW",
        "BB",
        "BL",
        "BM",
        "BQ",
        "BS",
        "BZ",
        "CA",
        "CR",
        "CU",
        "CW",
        "DM",
        "DO",
        "GD",
        "GL",
        "GP",
        "GT",
        "HN",
        "HT",
        "JM",
        "KN",
        "KY",
        "LC",
        "MF",
        "MQ",
        "MS",
        "MX",
        "NI",
        "PA",
        "PM",
        "PR",
        "SV",
        "SX",
        "TC",
        "TT",
        "US",
        "VC",
        "VG",
        "VI",
    ],
    OCEANIA: [
        "AS",
        "AU",
        "CK",
        "FJ",
        "FM",
        "GU",
        "KI",
        "MH",
        "MP",
        "NC",
        "NF",
        "NR",
        "NU",
        "NZ",
        "PF",
        "PG",
        "PN",
        "PW",
        "SB",
        "TK",
        "TO",
        "TV",
        "UM",
        "VU",
        "WF",
        "WS",
    ],
    SOUTH_AMERICA: [
        "AR",
        "BO",
        "BR",
        "CL",
        "CO",
        "EC",
        "FK",
        "GF",
        "GY",
        "PE",
        "PY",
        "SR",
        "UY",
        "VE",
    ],
};

export const EXPOSE_NID_OPTION = {
    EXPOSE_FULL: "EXPOSE_FULL",
    EXPOSE_SHORT: "EXPOSE_SHORT",
    NOT_EXPOSE: "NOT_EXPOSE",
};

export const DISTURBING_TIME_OPTION = {
    ANY_TIME: "ANY_TIME",
    EARLY: "EARLY",
    MORNING: "MORNING",
    LUNCH_BREAK: "LUNCH_BREAK",
    AFTERNOON: "AFTERNOON",
    EVENING: "EVENING",
    MAIL_ONLY: "MAIL_ONLY",
};

export const CURRENT_SITUATION_OPTION = {
    ACTIVE: "ACTIVE",
    PASSIVE: "PASSIVE",
    EMPLOYED_BUT_OPEN_FOR_BETTER_JOB: "EMPLOYED_BUT_OPEN_FOR_BETTER_JOB",
    EMPLOYED_BUT_LOOKING_FOR_NEXT_STEP: "EMPLOYED_BUT_LOOKING_FOR_NEXT_STEP",
    EMPLOYED_BUT_LOOKING_FOR_EASIER_JOB: "EMPLOYED_BUT_LOOKING_FOR_EASIER_JOB",
    EMPLOYED: "EMPLOYED",
    RETIRED: "RETIRED",
};

export const JOB_LEVEL_OPTION = {
    NINJA: "NINJA",
    SENIOR: "SENIOR",
    MEDIOR: "MEDIOR",
    JUNIOR: "JUNIOR",
};

export const NOTIFICATIONS_FREQUENCY_OPTION = {
    DAILY: "DAILY",
    WEEKLY: "WEEKLY",
    MONTHLY: "MONTHLY",
};

export const NOTIFY_BY_OPTION = {
    PHONE: "PHONE",
    EMAIL: "EMAIL",
    TWITTER: "TWITTER",
    LINKEDIN: "LINKEDIN",
    FACEBOOK: "FACEBOOK",
};

export const CURRENCY = {
    USD: "USD",
    EUR: "EUR",
};

export const CURRENCY_SIGN = {
    [CURRENCY.USD]: "$",
    [CURRENCY.EUR]: "€",
};

export const currenciesOptions = [
    { value: CURRENCY.USD, label: CURRENCY_SIGN[CURRENCY.USD] },
    { value: CURRENCY.EUR, label: CURRENCY_SIGN[CURRENCY.EUR] },
];

export const workRegimeOptions = {
    HALFTIME: "Halftime",
    FULLTIME: "Fulltime",
};

export const FOOD_OPTION = {
    HEALTHY: "HEALTHY",
    VEGAN: "VEGAN",
    ALLERGY_CONSCIOUS: "ALLERGY_CONSCIOUS",
};

export const COMMUTE_TIME_OPTION = {
    MIN_1: "ONE_MIN",
    MIN_5: "FIVE_MIN",
    MIN_10: "TEN_MIN",
    MIN_30: "THIRTY_MIN",
    HOUR_1: "ONE_HOUR",
    HOUR_2: "TWO_HOUR",
    HOUR_3: "THREE_HOUR",
    HOUR_4: "FOUR_HOUR",
};

export const COMMUTE_TIME_OPTION_TRANSLATION = {
    with_no_time_restrictions: "shared.with_no_time_restrictions",
    [COMMUTE_TIME_OPTION.MIN_1]: "shared.1_min_label",
    [COMMUTE_TIME_OPTION.MIN_5]: "shared.5_min_label",
    [COMMUTE_TIME_OPTION.MIN_10]: "shared.10_min_label",
    [COMMUTE_TIME_OPTION.MIN_30]: "shared.30_min_label",
    [COMMUTE_TIME_OPTION.HOUR_1]: "shared.1_hour_label",
    [COMMUTE_TIME_OPTION.HOUR_2]: "shared.2_hour_label",
    [COMMUTE_TIME_OPTION.HOUR_3]: "shared.3_hour_label",
    [COMMUTE_TIME_OPTION.HOUR_4]: "shared.4_hour_label",
};

export const COMMUTE_OPTION = {
    PREFER_HOMEWORK: "PREFER_HOMEWORK",
    MAX_COMMUTE_TIME: "MAX_COMMUTE_TIME",
    FULLTIME_HOMEWORK: "FULLTIME_HOMEWORK",
    RELOCATION: "RELOCATION",
};

export const COMMUTE_OPTION_SHORT_TRANSLATION = {
    [COMMUTE_OPTION.PREFER_HOMEWORK]: "commute.prefer_homework_short_label",
    [COMMUTE_OPTION.MAX_COMMUTE_TIME]: "shared.max_commute_time_label",
    [COMMUTE_OPTION.FULLTIME_HOMEWORK]: "commute.fulltime_homework_short_label",
    [COMMUTE_OPTION.RELOCATION]: "commute.relocation_short_label",
};

export const START_WORK_PERIOD_OPTION = {
    ASAP: "ASAP",
    FEW_MONTHS: "FEW_MONTHS",
    YEAR: "YEAR",
    FOR_GOOD_OPPORTUNITY: "FOR_GOOD_OPPORTUNITY",
};

export const START_WORK_PERIOD_OPTION_TRANSLATION = {
    [START_WORK_PERIOD_OPTION.ASAP]: "start_to_work.asap_label",
    [START_WORK_PERIOD_OPTION.FEW_MONTHS]: "start_to_work.in_few_months_label",
    [START_WORK_PERIOD_OPTION.YEAR]: "start_to_work.in_year_label",
    [START_WORK_PERIOD_OPTION.FOR_GOOD_OPPORTUNITY]: "start_to_work.for_good_opportunity_label",
};

export const EMPLOYEES_COUNT_OPTIONS = [
    {
        value: "ONE_TO_TEN",
        label: "1-10",
    },
    {
        value: "TEN_TO_FIFTY",
        label: "10-50",
    },
    {
        value: "FIFTY_TO_TWO_HUNDRED",
        label: "50-200",
    },
    {
        value: "TWO_HUNDRED_TO_FIVE_HUNDRED",
        label: "200-500",
    },
    {
        value: "FIVE_HUNDRED_TO_ONE_THOUSAND",
        label: "500-1000",
    },
    {
        value: "ONE_THOUSAND_TO_FIVE_THOUSAND",
        label: "1000-5000",
    },
    {
        value: "FIVE_THOUSAND_TO_TEN_THOUSAND",
        label: "5000-10000",
    },
    {
        value: "TEN_THOUSAND_PLUS",
        label: "10000+",
    },
];

export const DISTANCE_UNITS = [
    { value: "km", label: "km" },
    { value: "m", label: "miles" },
];

export const DISTANCE = {
    KM: "KILOMETER",
    MILES: "MILE",
};

export const PREFERRED_CONTRACT_TYPE = {
    FREELANCE: "FREELANCE",
    EMPLOYEE: "EMPLOYEE",
    NO_PREFERENCE: "NO_PREFERENCE",
};

export const PROFILE_TYPES = {
    ALTERNATIVE: "ALTERNATIVE",
    FUTURE: "FUTURE",
};
