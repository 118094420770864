import { connect } from "react-redux";
import NodesFilters from "./NodesFilters";
import { getTranslation } from "../../../../reducers/common/localization";
import {
    getRelatedProfilesNameFilter,
    getRelatedProfilesSourceFilter,
    getRelatedProfilesStatusFilter,
    getRelatedProfilesDomainFilter,
    onFetchGetRelatedProfiles,
    updateRelatedProfilesNameFilter,
    onUpdateRelatedProfilesSourceFilter,
    onUpdateRelatedProfilesStatusFilter,
    onUpdateRelatedProfilesDomainFilter,
    onUpdateRelatedProfilesSortingMode,
    getRelatedProfilesSortingMode,
} from "../../../../reducers/admin/relatedProfiles";
import {
    getSystemNodesExternalSources,
    getSystemNodesExternalSourcesFetchingStatus,
    refreshSystemNodesExternalSources,
} from "../../../../reducers/admin/systemModel";
import { fetchGetDomainList, getDomains, getSC } from "../../../../reducers/admin/domainsAndSC";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        disableTypeFilter: true,
        nodeNameFilter: getRelatedProfilesNameFilter(state),
        nodeSourceFilter: getRelatedProfilesSourceFilter(state),
        nodeStatusFilter: getRelatedProfilesStatusFilter(state),
        nodeDomainFilter: getRelatedProfilesDomainFilter(state),
        sortingMode: getRelatedProfilesSortingMode(state),
        externalSources: getSystemNodesExternalSources(state),
        loadingExternalSources: getSystemNodesExternalSourcesFetchingStatus(state),
        domains: getDomains(state),
        skill_clusters: getSC(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onMount: () => {
            dispatch(refreshSystemNodesExternalSources());
            dispatch(fetchGetDomainList());
        },
        onGetNodesList: () => dispatch(onFetchGetRelatedProfiles()),
        onUpdateNodesNameFilter: (filter) => dispatch(updateRelatedProfilesNameFilter(filter)),
        onUpdateNodesSourceFilter: (filter) =>
            dispatch(onUpdateRelatedProfilesSourceFilter(filter)),
        onUpdateNodesStatusFilter: (filter) =>
            dispatch(onUpdateRelatedProfilesStatusFilter(filter)),
        onUpdateNodesDomainFilter: (filter) =>
            dispatch(onUpdateRelatedProfilesDomainFilter(filter)),
        onUpdateNodesSortingMode: (sortingMode) =>
            dispatch(onUpdateRelatedProfilesSortingMode(sortingMode)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NodesFilters);
