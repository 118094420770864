import { connect } from "react-redux";
import EditApplicantSkills from "./EditApplicantSkills";
import { getTranslation } from "../../../reducers/common/localization";
import {
    fetchGetApplicantSkills,
    getApplicantSkillsInitialFetchingStatus,
    getApplicantSkillsData,
    getApplicantSaveSkillsFetchingStatus,
    onSaveApplicantSkills,
    getApplicantCurrentClusterId,
} from "../../../reducers/applicant/applicantSkills";
import {
    fetchGetApplicantProfiles,
    getApplicantProfilesInitialFetchingStatus,
    onSaveApplicantProfiles,
    getApplicantSaveProfilesFetchingStatus,
    getApplicantSelectedProfilesData,
} from "../../../reducers/applicant/applicantProfiles";
import {
    showModal,
    MODAL_SKILLS_WITHOUT_LEVEL,
} from "../../../reducers/common/activeModal";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        loading:
            getApplicantSkillsInitialFetchingStatus(state) ||
            getApplicantProfilesInitialFetchingStatus(state),
        skills: getApplicantSkillsData(state),
        loadingSaveChanges:
            getApplicantSaveProfilesFetchingStatus(state) ||
            getApplicantSaveSkillsFetchingStatus(state),
        currentClusterId: getApplicantCurrentClusterId(state),
        selectedProfiles: getApplicantSelectedProfilesData(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onMount: () => {
            dispatch(fetchGetApplicantSkills(true));
            dispatch(fetchGetApplicantProfiles());
        },
        onShowSkillsWithoutLevelModal: () => dispatch(showModal(MODAL_SKILLS_WITHOUT_LEVEL)),
        onSaveChanges: () => {
            dispatch(onSaveApplicantProfiles());
            dispatch(onSaveApplicantSkills());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditApplicantSkills);
