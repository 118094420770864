import React, { Component } from "react";
import PropTypes from "prop-types";
import "./CreateNewJob.scss";
import _ from "lodash";
import { Button, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import JobSkillsSetup from "../../../common/skills/skillsSetup/JobSkillsSetup.container";
import NoProfilesModal from "../../../common/modals/noProfilesModal/NoProfilesModal.container";
import SkillsWithoutLevelModal from "../../../common/modals/skillsWithoutLevelModal/SkillsWithoutLevelModal.container";

const CREATE_NEW_JOB_STEP = {
    COMMON_INFO: "COMMON_INFO",
    SKILLS_AND_PROFILES: "SKILLS_AND_PROFILES",
    FINISH: "FINISH",
};

class CreateNewJob extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: CREATE_NEW_JOB_STEP.SKILLS_AND_PROFILES,
        };
    }

    componentDidMount() {
        this.props.onMount();
    }

    render() {
        return (
            <div className="create-new-job">
                <h4 className="page-header">
                    {_.get(this.props.translation, "create_new_job_page.page_label")}
                </h4>
                <div className="buttons-container submit-new-job-container">
                    <Button color="primary" onClick={this.onPostNewJob}>
                        {_.get(this.props.translation, "create_new_job_page.post_job_button")}
                    </Button>
                </div>
                {this.renderContent()}
                <NoProfilesModal />
                <SkillsWithoutLevelModal
                    data={_.filter(this.props.skills, (skill) => _.isNil(skill.level))}
                />
            </div>
        );
    }

    renderContent() {
        return (
            <>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={
                                this.state.activeTab === CREATE_NEW_JOB_STEP.COMMON_INFO
                                    ? "active"
                                    : ""
                            }
                            onClick={this.handleChangeTab(CREATE_NEW_JOB_STEP.COMMON_INFO)}
                        >
                            {/* TODO: remove hardcoded values */}
                            Common info
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={
                                this.state.activeTab === CREATE_NEW_JOB_STEP.SKILLS_AND_PROFILES
                                    ? "active"
                                    : ""
                            }
                            onClick={this.handleChangeTab(CREATE_NEW_JOB_STEP.SKILLS_AND_PROFILES)}
                        >
                            {_.get(
                                this.props.translation,
                                "create_new_job_page.profiles_and_skills_sub_page_label"
                            )}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={
                                this.state.activeTab === CREATE_NEW_JOB_STEP.FINISH ? "active" : ""
                            }
                            onClick={this.handleChangeTab(CREATE_NEW_JOB_STEP.FINISH)}
                        >
                            {/* TODO: remove hardcoded values */}
                            Finish
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={CREATE_NEW_JOB_STEP.COMMON_INFO}>
                        {/* TODO: remove hardcoded values */}
                        Common info
                    </TabPane>
                    <TabPane tabId={CREATE_NEW_JOB_STEP.SKILLS_AND_PROFILES}>
                        <JobSkillsSetup />
                    </TabPane>
                    <TabPane tabId={CREATE_NEW_JOB_STEP.FINISH}>
                        {/* TODO: remove hardcoded values */}
                        Finish
                    </TabPane>
                </TabContent>
            </>
        );
    }

    handleChangeTab = (tab) => () => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    };

    onPostNewJob = () => {
        if (this.props.skills && this.props.skills.find((elem) => _.isNil(elem.level))) {
            this.props.onShowSkillsWithoutLevelModal();
            return;
        } else if (!this.props.profiles || this.props.profiles.length === 0) {
            this.props.onShowNoProfilesModal();
            return;
        } else {
            this.props.onPostNewJob();
        }
    };
}

CreateNewJob.propTypes = {
    translation: PropTypes.object.isRequired,
    profiles: PropTypes.array,
    skills: PropTypes.array,
    onMount: PropTypes.func.isRequired,
    onShowSkillsWithoutLevelModal: PropTypes.func.isRequired,
    onShowNoProfilesModal: PropTypes.func.isRequired,
    onPostNewJob: PropTypes.func.isRequired,
};

export default CreateNewJob;
