import { connect } from "react-redux";
import CustomSkillLevelModal from "./CustomSkillLevelModal";
import { getTranslation } from "../../../../reducers/common/localization";
import {
    getActiveModalType,
    CUSTOM_SKILL_LEVEL_MODAL,
    hideModal,
} from "../../../../reducers/common/activeModal";
import {
    fetchUpdateSkillVersionList,
    getApplicantCurrentSkillData,
    onSaveApplicantSkills,
} from "../../../../reducers/applicant/applicantSkills";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === CUSTOM_SKILL_LEVEL_MODAL,
        currentSkill: getApplicantCurrentSkillData(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClose: () => {
            dispatch(hideModal());
        },
        onAddNewVersion: (skillId, versionTitle) => {
            dispatch(fetchUpdateSkillVersionList(skillId, versionTitle));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomSkillLevelModal);
