import React, { Component } from "react";

class PeopleDiscovery extends Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <defs></defs>
                <title>b</title>
                <path
                    fill="#2e79bd"
                    d="M85.68371,32.77985a21.68356,21.68356,0,1,0,6.36866,15.35241A21.65717,21.65717,0,0,0,85.68371,32.77985ZM57.72238,51.16464a3.03453,3.03453,0,1,1,3.03453-3.03453A3.03491,3.03491,0,0,1,57.72238,51.16464Zm12.6132,0A3.03453,3.03453,0,1,1,73.368,48.13011,3.03353,3.03353,0,0,1,70.33559,51.16464Zm12.61106,0a3.03453,3.03453,0,1,1,3.03453-3.03453A3.03491,3.03491,0,0,1,82.94665,51.16464Z"
                />
                <path
                    fill="#2d3e50"
                    d="M116.36927,47.10077a46.13409,46.13409,0,0,0-92.26.89666c0,.24824.00643,1.79974.015,4.2201-.39377,2.52948-2.28553,4.62241-4.04033,6.35367-1.575,1.55579-6.22956,5.95137-6.86086,6.72605a7.82316,7.82316,0,0,0-1.44236,2.48883c-.51788,1.58146.29746,2.35187,1.55792,3.13724a25.70723,25.70723,0,0,0,2.81626,1.47447c.91377.42586,1.8404.82176,2.76489,1.22194a6.723,6.723,0,0,1,1.47661.68695l.21613.25895s3.745,24.67855,3.7707,24.90753a8.549,8.549,0,0,0,2.339,5.47843c2.69855,2.47171,6.96786,1.88747,10.33195,1.88106q3.60486-.00964,7.20968-.015h.48579c.122.45368.80464,11.57743,1.23906,17.74279a1.69788,1.69788,0,0,0,2.02445,1.54509l54.01377-10.745a3.66689,3.66689,0,0,0,2.95535-3.59735c.00214-2.60439.00214-9.80764.00214-9.80764a90.21684,90.21684,0,0,1,7.63554-35.706A46.0517,46.0517,0,0,0,116.36927,47.10077ZM92.49536,70.292a31.30333,31.30333,0,0,1-17.058,8.77831q-1.41882.23111-2.8783.336v3.93546h.83245l3.43258-.00213.244,18.81708a6.73247,6.73247,0,1,1-13.46493.0107L63.357,83.33751h4.75509v-3.929A31.391,31.391,0,1,1,92.49536,70.292Z"
                />
            </svg>
        );
    }
}

export default PeopleDiscovery;
