
import { /*createAction,*/ handleActions } from 'redux-actions';
// import { Api } from '../../api/api';

//- Actions
export const onSendInvitationToBrainChain = (userId) => dispatch => {
    // TODO: (BE) add the ability to invite someone to BrainChain
}

//- State
const initialState = {
};

//- Reducers
export default handleActions({

}, initialState);

//- Selectors
export const getBrainChainDataState = state => state.brainChainData;
