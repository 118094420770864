import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import Slider from "rc-slider";
import { Alert, Tooltip } from "reactstrap";
import moment from "moment";
import { Timeline } from "../Timeline/Timeline";
import Icon from "../../../../common/icon/Icon";
import SkillNotesModal from "../../../../common/modals/skillNotesModal/SkillNotesModal.container";
import { SKILL_PROPERTY } from "../../../../../constants/skillsConstants";
import { getMsFromTimeString, getShareOfTotal } from "../../utils";

const yearInMs = moment.duration(1, "years").asMilliseconds();

class SkillsTimeline extends Timeline {
    constructor(props) {
        super(props);
        this.state = {
            addSkillNoteTooltipOpen: false,
        };
    }
    createMarksFromSkill({ skillLifecycle: { forgottenAt, acquiredAt }, notes }) {
        const skillLifeDuration =
            (new Date(forgottenAt).getTime() || new Date().getTime()) -
            new Date(acquiredAt).getTime();

        const marks = {
            0: {
                style: { minWidth: "80px", fontWeight: 700 },
                label: moment(acquiredAt).format("YYYY MM DD"),
            },
            100: {
                style: { minWidth: "80px", fontWeight: 700 },
                label: moment(forgottenAt || new Date().getTime()).format("YYYY MM DD"),
            },
        };
        if (notes) {
            notes.forEach((note) => {
                if (note.appearedOn <= (forgottenAt || new Date().toISOString())) {
                    const noteAppereanceTimeShareOfTotal = getShareOfTotal(
                        getMsFromTimeString(note.appearedOn),
                        getMsFromTimeString(acquiredAt),
                        yearInMs,
                        skillLifeDuration,
                        true
                    );
                    if (
                        noteAppereanceTimeShareOfTotal >= 0 ||
                        noteAppereanceTimeShareOfTotal <= 100
                    ) {
                        marks[noteAppereanceTimeShareOfTotal] = {
                            label: moment(note.appearedOn).format("YYYY-MM-DD"),
                        };
                    }
                }
            });
        }
        return {
            marks,
            skillLifeDuration,
        };
    }
    onAddNewNote = ({ text, appearedOn }) => {
        let notes = _.cloneDeep(this.props.currentSkillData.notes) || [];
        notes.push({
            appearedOn: moment(appearedOn).toISOString(),
            text,
        });
        this.props.updateSkillProperty(SKILL_PROPERTY.NOTES, notes);
        this.props.onSaveSkillsNotes();
    };
    render() {
        const {
            selectedDateOnTimeline,
            currentSkillData,
            translation,
            onAddSkillNoteClick,
        } = this.props;
        const { addSkillNoteTooltipOpen } = this.state;
        const { title, notes } = currentSkillData || {};
        const { marks, skillLifeDuration } = currentSkillData?.skillLifecycle
            ? this.createMarksFromSkill(currentSkillData)
            : {};
        return currentSkillData ? (
            <>
                <Slider
                    marks={marks}
                    onChange={(value) =>
                        this.onChangeHandler(
                            value,
                            skillLifeDuration,
                            new Date(currentSkillData?.skillLifecycle?.acquiredAt).getTime()
                        )
                    }
                    handle={(props) =>
                        this.customHandle(
                            props,
                            skillLifeDuration,
                            new Date(currentSkillData?.skillLifecycle?.acquiredAt).getTime(),
                            "skill-timeline-tooltip"
                        )
                    }
                    step={0.01}
                    vertical
                    defaultValue={100}
                    value={this.getValueFromSelectedDate(
                        selectedDateOnTimeline,
                        skillLifeDuration,
                        new Date(currentSkillData?.skillLifecycle?.acquiredAt).getTime()
                    )}
                    style={{ margin: "10px 40px" }}
                    trackStyle={{ backgroundColor: "#40dfcf" }}
                />
                <Icon
                    type="plus_outline"
                    color="success"
                    size="ms"
                    title={_.get(translation, "skills_journey_page.add_skill_note")}
                    id="addSkillNote"
                    onClick={onAddSkillNoteClick}
                />
                <Tooltip
                    placement="left"
                    isOpen={addSkillNoteTooltipOpen}
                    target="addSkillNote"
                    toggle={() =>
                        this.setState({ addSkillNoteTooltipOpen: !addSkillNoteTooltipOpen })
                    }
                >
                    {_.get(translation, "skills_journey_page.add_skill_note")}
                </Tooltip>
                <SkillNotesModal
                    skillTitle={currentSkillData && title}
                    notes={currentSkillData && notes}
                    onAddNewNote={this.onAddNewNote}
                    forgottenAt={currentSkillData?.skillLifecycle?.forgottenAt}
                    acquiredAt={currentSkillData?.skillLifecycle?.acquiredAt}
                />
            </>
        ) : (
            <Alert color="info" hidden={false} style={{ marginBottom: 0, alignSelf: "center" }}>
                {_.get(translation, "skills_journey_page.skill_timeline_fallback")}
            </Alert>
        );
    }
}

SkillsTimeline.propTypes = {
    skillsTree: PropTypes.array.isRequired,
    selectedDateOnTimeline: PropTypes.number.isRequired,
    currentSkillData: PropTypes.array,
    translation: PropTypes.object,
    onAddSkillNoteClick: PropTypes.func,
};

export default SkillsTimeline;
