import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Login.scss";
import { Button, Label, Input, FormGroup, Form, Alert, Col, Row } from "reactstrap";
import _ from "lodash";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants/constants";
import DFALoginModal from "./DFALoginModal.container";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
        };
    }

    componentWillUnmount() {
        this.props.onUnmount();
    }

    render() {
        return (
            <div className="login">
                <Form onSubmit={this.onLogIn} className="login-form">
                    <h4 className="text-center">
                        {_.get(this.props.translation, "login_page.welcome_label")}
                    </h4>
                    <FormGroup>
                        <Label for="login-email">
                            {_.get(this.props.translation, "person_data.email_label")}
                        </Label>
                        <Input
                            type="email"
                            value={this.state.email}
                            onChange={this.handleInputChange("email")}
                            required
                            placeholder={_.get(this.props.translation, "person_data.email_label")}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="login-password">
                            {_.get(this.props.translation, "person_data.password_label")}
                        </Label>
                        <Input
                            type="password"
                            value={this.state.password}
                            onChange={this.handleInputChange("password")}
                            required
                            placeholder={_.get(
                                this.props.translation,
                                "person_data.password_label"
                            )}
                        />
                    </FormGroup>
                    <Button type="submit" color="primary" block>
                        {_.get(this.props.translation, "login_page.login_button")}
                    </Button>
                </Form>
                <Row className="additional-actions small-text">
                    <Col sm="6" className="additional-action-column">
                        <Link to={ROUTES.RESTORE_ACCOUNT} onClick={this.onForgotPassword}>
                            {_.get(this.props.translation, "login_page.forgot_password_label")}
                        </Link>
                    </Col>
                    <Col sm="6" className="additional-action-column text-right">
                        <div>{_.get(this.props.translation, "login_page.no_account_label")}</div>
                        <Link to={ROUTES.CREATE_ACCOUNT}>
                            {_.get(this.props.translation, "login_page.create_account_link_label")}
                        </Link>
                    </Col>
                </Row>
                {this.props.fetching ? (
                    <Alert color="warning" className="fetching-label">
                        {_.get(this.props.translation, "actions.fetching_message")}
                    </Alert>
                ) : null}
                {this.props.error ? (
                    <Alert color="danger" className="error-label">
                        {_.get(this.props.translation, "errors." + this.props.error.type) ||
                            _.get(this.props.translation, "errors.DEFAULT_ERROR")}
                    </Alert>
                ) : null}
                <DFALoginModal onSubmit={this.onLogInWithVerification} />
            </div>
        );
    }

    handleInputChange = (field) => (e) => {
        this.setState({ [field]: e.target.value });
    };

    onForgotPassword = () => {
        this.props.onForgotPassword(this.state.email);
    };

    onLogIn = (e) => {
        e.preventDefault();

        let data = {
            username: this.state.email,
            password: this.state.password,
        };

        this.props.onLogIn(data);
    };

    onLogInWithVerification = (verificationCode) => {
        let data = {
            username: this.state.email,
            password: this.state.password,
            totpcode: verificationCode,
        };

        this.props.onLogIn(data);
    };
}

Login.propTypes = {
    translation: PropTypes.object.isRequired,
    error: PropTypes.object,
    fetching: PropTypes.bool.isRequired,
    onLogIn: PropTypes.func.isRequired,
    onForgotPassword: PropTypes.func.isRequired,
    onUnmount: PropTypes.func.isRequired,
};

export default Login;
