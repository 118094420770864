import React, { Component } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import _ from "lodash";

const deptOptions = ["IT", "Restaraunt", "Buildings", "Car service"];

class JobsFilter extends Component {
    handleChange({ type, checked, name, value }) {
        const { setParentStateValue } = this.props;
        const realValue = type === "checkbox" ? checked : value;
        setParentStateValue("filtersData", name, realValue);
    }

    render() {
        const {
            translation,
            filtersData: { dept, jobTitleAndRef, includeClosedJobs },
            resetFilters,
        } = this.props;

        return (
            <div className="jobs-filter-container">
                <Form onChange={({ target }) => this.handleChange(target)}>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Input
                                    type="select"
                                    name="dept"
                                    id="dept"
                                    value={dept}
                                    placeholder="test"
                                    defaultValue={_.get(translation, "shared.departments_label")}
                                >
                                    <option
                                        hidden
                                        value={_.get(translation, "shared.departments_label")}
                                    >
                                        {_.get(translation, "shared.departments_label")}
                                    </option>
                                    {deptOptions.map((dept, i) => (
                                        <option key={i}>{dept}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    name="jobTitleAndRef"
                                    id="jobTitleAndRef"
                                    value={jobTitleAndRef}
                                    placeholder={_.get(
                                        translation,
                                        "jobs_page.job_title_ref_label"
                                    )}
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            value={includeClosedJobs}
                                            type="checkbox"
                                            id="includeClosedJobs"
                                            name="includeClosedJobs"
                                        />
                                        {_.get(translation, "jobs_page.include_closed_job_label")}
                                    </Label>
                                </FormGroup>
                            </FormGroup>
                        </Col>
                        <div style={{ width: "1px", backgroundColor: "#8aaa10" }}></div>
                        <Col md="4">
                            <div className="jobs-filters-buttons-container">
                                <div className="jobs-filter-button">
                                    <Button style={{ width: "100%" }} color="primary">
                                        {_.get(translation, "shared.filter_label")}
                                    </Button>
                                </div>
                                <div className="jobs-filter-button">
                                    <Button
                                        style={{ width: "100%" }}
                                        color="danger"
                                        onClick={() => resetFilters()}
                                    >
                                        {_.get(translation, "shared.reset_filter_label")}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

JobsFilter.propTypes = {};

export default JobsFilter;
