import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
	Modal,
	ModalBody,
	ModalFooter,
	Button,
	Label
} from 'reactstrap';
import './ChangeAnchor.scss';
import CustomSelect from "../../../../common/inputs/customSelect/CustomSelect";

class ChangeAnchor extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedEmployee: null,
		};
	}

	componentDidMount() {
		this.props.getAllEmployees();
	}

	render() {
		const { translation, isOpen, onClose, employees, onChangeAnchor } = this.props;

		const { selectedEmployee } = this.state;

		return (
			<Modal
				isOpen={isOpen}
				toggle={onClose}
			>
				<ModalBody>
					<h6 className="change-anchor-title">{_.get(translation, 'staff.change_anchor.modal_title')}</h6>
					<h6>{`(${_.get(translation, 'staff.change_anchor.modal_text')})`}</h6>
					<div className="employee-dropdown">
						<Label>
							{_.get(translation, 'shared.employee')}
						</Label>
						<CustomSelect
							value={selectedEmployee ? _.find(employees, option => option.value === selectedEmployee.value) : null}
							options={employees}
							onChange={(option) => this.setState({ selectedEmployee: option })}
						/>
					</div>
				</ModalBody>
				<ModalFooter className="content-space-between">
					<Button
						color="secondary"
						onClick={onClose}
					>
						{_.get(translation, 'actions.cancel_button')}
					</Button>
					<Button
						color="primary"
						disabled={_.isEmpty(selectedEmployee)}
						onClick={() => {
							onChangeAnchor(selectedEmployee.data.id);
						}}
					>
						{_.get(translation, "staff.change_anchor.button")}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

ChangeAnchor.propTypes = {
	translation: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	getAllEmployees: PropTypes.func.isRequired,
	onChangeAnchor: PropTypes.func.isRequired,
	employees: PropTypes.array.isRequired,
};

export default ChangeAnchor;
