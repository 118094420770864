import React, { Component } from "react";
import PropTypes from "prop-types";
import "./EditApplicantSkills.scss";
import _ from "lodash";
import { Button, Spinner } from "reactstrap";
import ApplicantSkillsSetup from "../../common/skills/skillsSetup/ApplicantSkillsSetup.container";
import SkillsWithoutLevelModal from "../../common/modals/skillsWithoutLevelModal/SkillsWithoutLevelModal.container";

class EditApplicantSkills extends Component {
    componentDidMount() {
        this.props.onMount();
    }

    render() {
        return (
            <div className="edit-applicant-skills">
                <h4 className="page-header">
                    {_.get(this.props.translation, "edit_applicant_skills_page.page_label")}
                </h4>
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        if (this.props.loading) {
            return (
                <h6 className="text-center">
                    {_.get(this.props.translation, "actions.fetching_message")}
                </h6>
            );
        }

        return (
            <>
                <div className="buttons-container save-changes-container">
                    {this.props.loadingSaveChanges ? (
                        <div className="loading-save-changes-container">
                            <Spinner
                                size="sm"
                                color="secondary"
                                title={_.get(this.props.translation, "actions.fetching_message")}
                            />
                        </div>
                    ) : null}
                    <Button color="primary" onClick={this.onSaveChanges}>
                        {_.get(this.props.translation, "actions.save_button")}
                    </Button>
                </div>
                <ApplicantSkillsSetup />
                <SkillsWithoutLevelModal
                    data={_.filter(this.props.skills, (skill) => _.isNil(skill.level))}
                />
            </>
        );
    }

    onSaveChanges = () => {
        this.props.onSaveChanges();
        // if (this.props.skills && this.props.skills.find((elem) => _.isNil(elem.level))) {
        //     this.props.onShowSkillsWithoutLevelModal();
        //     return;
        // } else {
        //  this.props.onSaveChanges();
        // }
    };
}

EditApplicantSkills.propTypes = {
    translation: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    skills: PropTypes.array,
    loadingSaveChanges: PropTypes.bool,
    onMount: PropTypes.func.isRequired,
    onShowSkillsWithoutLevelModal: PropTypes.func.isRequired,
    onSaveChanges: PropTypes.func.isRequired,
};

export default EditApplicantSkills;
