import { connect } from 'react-redux';
import JobProfile from './JobProfile';
import { getTranslation } from '../../../reducers/common/localization';
import { getLoginAuthorizeStatus } from '../../../reducers/authorization/login';
import { getUserRole } from '../../../reducers/user/userRole';
import {
	fetchStaticJobViewData,
	getFetchingJobDataLoading,
	getJobData
} from '../../../reducers/staticViews/staticViews';

const mapStateToProps = state => {
	return {
		translation: getTranslation(state),
		isAuthorized: getLoginAuthorizeStatus(state),
		userRole: getUserRole(state),
		jobData: getJobData(state),
		loading: getFetchingJobDataLoading(state)
	};
};

const mapDispatchToProps = dispatch => ({
	onMount: (hash) => dispatch(fetchStaticJobViewData(hash))
});

export default connect(mapStateToProps, mapDispatchToProps)(JobProfile);
