import { StorageManager } from "../utils/storageManager";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export const API_URL = process.env.REACT_APP_API_URL;

const secureApi = axios.create({
    baseURL: API_URL,
});

secureApi.interceptors.request.use(config => {
    const token = StorageManager.getLoginToken();

    config.headers = {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    return config;
}, error => Promise.reject(error));

secureApi.interceptors.response.use(res => {
    return res;
}, (error) => {
    console.log("api error", error.response);
    if (error.response?.status === 401) {
        console.log("Error 401");
    }
});

export default secureApi;
