import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './HardSkillsComparison.scss';
import _ from 'lodash';
import { Alert } from 'reactstrap';
import { Utils } from '../../../../../utils/utils';
import Icon from '../../../../common/icon/Icon';
import { CANDIDATE_GROUP } from '../../../../../constants/candidatesComparisonConstants';
import UserPhoto from '../../../../common/userPhoto/UserPhoto.container';
import { USER_PHOTO_MODE } from '../../../../common/userPhoto/UserPhoto';
import BrainChainCheckMark from '../../../../common/brainChainCheckMark/BrainChainCheckMark.container';
import Legend from '../../../../common/skills/legend/Legend.container';
import SkillsMap from '../../../../common/skills/skillsMap/SkillsMap.container';
import { SKILLS_MAP_MODE } from '../../../../common/skills/skillsMap/SkillsMap';
import { LEGEND_TYPE } from '../../../../common/skills/legend/Legend';


class HardSkillsComparison extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowAllSkills: false
        }
    }

    render() {
        if (this.props.loading) {
            return (
                <h6 className="text-center">{_.get(this.props.translation, "actions.fetching_message")}</h6>
            )
        }

        if (!this.props.loading && !this.props.data) {
            return (
                <div className="select-candidate-hint">
                    <Alert>
                        {_.get(this.props.translation, 'candidates_comparison_page.select_candidate_hint')}
                    </Alert>
                </div>
            )
        }

        return (
            <div className="hard-skills-comparison">
                {this.renderCandidateInfo()}
                {this.renderSkillsMapControls()}
                <div className="skills-map-container">
                    <SkillsMap
                        skillsTree={this.props.comparedSkills}
                        mode={SKILLS_MAP_MODE.COMPARISON}
                        userId={this.props.userId}
                        // TODO: remove hardcoded values
                        imageSource=""
                        showSecondarySkills={this.state.isShowAllSkills}
                    />
                </div>
                <Legend type={LEGEND_TYPE.COMPARISON} />
            </div>
        );
    }

    renderCandidateInfo() {
        return (
            <div className="candidate-info">
                <div className="buttons-group">
                    {
                        this.props.data.applicantMetaData.groupId === CANDIDATE_GROUP.TOP_X ?
                            <Icon
                                className="top-x-icon"
                                type="best_mark"
                                size="lg"
                                title={_.replace(_.get(this.props.translation, 'candidates_comparison_page.top_x_candidate_label'), "{x}", this.props.plannedTopAmount)}
                            />
                            :
                            <Icon
                                className="top-x-icon"
                                type="best_mark_outline"
                                size="lg"
                                onClick={this.handleAddToTopXGroup}
                                title={_.replace(_.get(this.props.translation, 'candidates_comparison_page.add_to_top_x_label'), "{x}", this.props.plannedTopAmount)}
                            />
                    }
                    {
                        this.props.data.applicantMetaData.favorite ?
                            <Icon
                                className="favorite-icon"
                                type="star"
                                size="lg"
                                title={_.get(this.props.translation, 'candidates_comparison_page.remove_from_favorite_label')}
                                onClick={this.handleRemoveFromFavorite}
                            />
                            :
                            <Icon
                                className="favorite-icon"
                                type="star_outline"
                                size="lg"
                                title={_.get(this.props.translation, 'candidates_comparison_page.add_to_favorite_label')}
                                onClick={this.handleAddToFavorite}
                            />
                    }
                </div>
                <UserPhoto
                    mode={USER_PHOTO_MODE.COMPARISON}
                    userId={this.props.userId}
                    // TODO: remove hardcoded values
                    imageSource=""
                    isDraggable
                    size="sm"
                />
                <div className="candidate-name">
                    <h5>{`${this.getUserName()}, ${this.props.data.user.currentJob}`}</h5>
                </div>
                <div className="buttons-group">
                    <BrainChainCheckMark
                        userId={this.props.userId}
                        qualityCheck={this.props.data.user.qualityCheck}
                    />
                    <Icon
                        className="candidate-notes-icon"
                        type={this.props.data.applicantMetaData.notes ? "microphone" : "microphone_outline"}
                        size="lg"
                        onClick={this.handleCandidateNotesClick}
                        title={_.get(this.props.translation, 'candidates_comparison_page.notes_about_candidate_label')}
                    />
                </div>
            </div>
        )
    }

    renderSkillsMapControls() {
        return (
            <div className="skills-map-controls">
                <div className="controls-title">
                    {_.get(this.props.translation, 'actions.show_label')}
                </div>
                <div className="control-button" onClick={this.toggleSkillsShowingMode(false)}>
                    <Icon type={`radio${this.state.isShowAllSkills ? "_blank" : ""}`} size="sm" />
                    {_.get(this.props.translation, 'job.required_skills_job_label')}
                </div>
                <div className="control-button" onClick={this.toggleSkillsShowingMode(true)} >
                    <Icon type={`radio${this.state.isShowAllSkills ? "" : "_blank"}`} size="sm" />
                    {_.get(this.props.translation, 'job.all_skills_candidate_label')}
                </div>
            </div>
        );
    }

    toggleSkillsShowingMode = (isShow) => () => {
        if (this.state.isShowAllSkills !== isShow) {
            this.setState({ isShowAllSkills: !this.state.isShowAllSkills });
        }
    }

    getUserName = () => {
        if (this.props.anonymityMode) {
            return Utils.getUserAnonymousNameByUUID(this.props.userId);
        } else {
            return this.props.data.user.alias.firstName + " " + this.props.data.user.alias.lastName;
        }
    }

    handleRemoveFromFavorite = () => {
        this.props.onRemoveFromFavorite(this.props.userId);
    }

    handleAddToFavorite = () => {
        this.props.onAddToFavorite(this.props.userId);
    }

    handleCandidateNotesClick = () => {
        this.props.onCandidateNotesClick(this.props.userId);
    }

    handleAddToTopXGroup = () => {
        this.props.onAddToTopXGroup(this.props.userId);
    }
}

HardSkillsComparison.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.object,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    comparedSkills: PropTypes.array,
    loading: PropTypes.bool,
    anonymityMode: PropTypes.bool,
    plannedTopAmount: PropTypes.number,
    onAddToFavorite: PropTypes.func.isRequired,
    onRemoveFromFavorite: PropTypes.func.isRequired,
    onAddToTopXGroup: PropTypes.func.isRequired,
    onCandidateNotesClick: PropTypes.func.isRequired,
}

export default HardSkillsComparison;
