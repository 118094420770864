import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Skill.scss";
import _ from "lodash";
import Icon from "../../icon/Icon";
import { EAGERNESS_ICON, EAGERNESS_TRANSLATION_LABEL } from "../../../../constants/skillsConstants";

const ICONS_POSITIONS = ["topLeft", "topRight", "bottomRight", "bottomLeft"];

class Skill extends Component {
    render() {
        return (
            <div
                id={this.props.id}
                className={this.getClassName()}
                title={this.props.title}
                onClick={this.props.onClick ? this.onClick : null}
            >
                <div className="skill-text-container">
                    <span>{this.props.text}</span>
                </div>
                {this.renderIcons()}
            </div>
        );
    }

    getClassName() {
        let className = "skill";

        if (!_.isNil(this.props.onClick)) {
            className += " skill-clickable";
        }

        if (this.props.isSelected) {
            className += " skill-selected";
        }

        if (this.props.isTextOverflowEllipsis) {
            className += " skill-text-overflow-ellipsis";
        }

        className += " " + (this.props.className || "");

        return className;
    }

    renderIcons() {
        return _.map(ICONS_POSITIONS, (position) => {
            if (this.props.iconsPositions[position]) {
                return this.renderIcon(this.props.iconsPositions[position], position);
            } else {
                return null;
            }
        });
    }

    renderIcon(icon, position) {
        switch (icon) {
            case "eagerness":
                return this.renderEagernessIcon(position);

            case "remove":
                return this.renderRemoveIcon(position);

            case "add":
                return this.renderAddIcon(position);

            case "notes":
                return this.renderNotesIcon(position);

            case "not_moderated":
                return this.renderNotModeratedIcon(position);

            case "cluster":
                return this.renderClusterIcon(position);

            case "cluster_add":
                return this.renderAddClusterIcon(position);

            case "info":
                return this.renderInfoIcon(position);

            default:
                break;
        }
    }

    renderEagernessIcon(position) {
        if (this.props.eagerness) {
            return (
                <Icon
                    key="eagerness"
                    className={_.kebabCase(position)}
                    type={EAGERNESS_ICON[this.props.eagerness]}
                    size="xs"
                    title={_.get(
                        this.props.translation,
                        "skill_properties.eagerness_values." +
                            EAGERNESS_TRANSLATION_LABEL[this.props.eagerness]
                    )}
                />
            );
        }
    }

    renderRemoveIcon(position) {
        return (
            <Icon
                key="remove"
                className={_.kebabCase(position)}
                type="minus_outline"
                size="xs"
                color="danger"
                title={_.get(this.props.iconsTitle, "remove")}
                onClick={
                    this.props.isAutocompleteSkill && this.props.onRemoveClick
                        ? () => this.props.onRemoveClick(this.props.id)
                        : this.props.onRemoveClick
                        ? this.onRemoveClick
                        : null
                }
            />
        );
    }

    renderAddIcon(position) {
        return (
            <Icon
                key="add"
                className={_.kebabCase(position)}
                type="plus_outline"
                size="xs"
                color="success"
                title={_.get(this.props.iconsTitle, "add")}
                onClick={this.props.onAddClick ? this.onAddClick : null}
            />
        );
    }

    renderNotesIcon(position) {
        if (!_.isNil(this.props.notesNumber)) {
            return this.props.notesNumber > 0 ? (
                <Icon
                    key="notes"
                    className={`${_.kebabCase(position)} notes-icon`}
                    type="notes"
                    size="xs"
                    number={this.props.notesNumber}
                    id={_.get(this.props.iconsIds, "notes")}
                    title={_.get(this.props.iconsTitle, "notes")}
                    onClick={this.props.onNotesClick ? this.onNotesClick : null}
                />
            ) : null;
        }
    }

    renderNotModeratedIcon(position) {
        if (this.props.isNotModerated) {
            return (
                <Icon
                    key="not_moderated"
                    className={_.kebabCase(position)}
                    type="not_moderated"
                    size="xs"
                    title={_.get(this.props.translation, "system_nodes.not_moderated_skill_label")}
                />
            );
        }
    }

    renderClusterIcon(position) {
        return (
            <Icon
                key="cluster"
                className={_.kebabCase(position)}
                type="folder"
                size="xs"
                color="secondary"
            />
        );
    }

    renderAddClusterIcon(position) {
        return (
            <Icon
                key="cluster_add"
                className={_.kebabCase(position)}
                type="folder_add"
                size="xs"
                color="success"
            />
        );
    }

    renderInfoIcon(position) {
        return (
            <Icon
                className={`${_.kebabCase(position)} info-icon`}
                key="info"
                type="info"
                color="info"
                onClick={this.props.onInfoClick ? this.onInfoClick : null}
                title={_.get(this.props.iconsTitle, "info")}
            />
        );
    }

    onClick = (e) => {
        e.stopPropagation();
        this.props.onClick();
    };

    onAddClick = (e) => {
        e.stopPropagation();
        this.props.onAddClick();
    };

    onInfoClick = (e) => {
        e.stopPropagation();
        this.props.onInfoClick();
    };

    onRemoveClick = (e) => {
        e.stopPropagation();
        this.props.onRemoveClick();
    };

    onNotesClick = (e) => {
        e.stopPropagation();
        this.props.onNotesClick();
    };
}

Skill.defaultProps = {
    iconsPositions: {},
};

Skill.propTypes = {
    translation: PropTypes.object.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    isTextOverflowEllipsis: PropTypes.bool,
    isSelected: PropTypes.bool,
    isNotModerated: PropTypes.bool,
    iconsPositions: PropTypes.object,
    iconsTitle: PropTypes.object,
    eagerness: PropTypes.number,
    notesNumber: PropTypes.number,
    onAddClick: PropTypes.func,
    onInfoClick: PropTypes.func,
    onRemoveClick: PropTypes.func,
    onNotesClick: PropTypes.func,
    isAutocompleteSkill: PropTypes.bool,
};

export default Skill;
