/*
    When you add any other then default "en" language make sure you've added:
    1) import of language: import "./translation_code"
    2) import of moment locale : import "moment/locale/momentLocale_code"
    3) language with it's momentLocale to json (or just momentLocale: 'en'), it will be used for dates locale
    c.f. example for "ru"
*/
import en from './translation_en';
import nl from './translation_nl';
import fr from './translation_fr';
import de from './translation_de';
import es from './translation_es';

import 'moment/locale/en-gb';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/es';

export const translations = {
    en: {
        label: 'English',
        translation: en,
        momentLocale: 'en-gb'
    },
    nl: {
        label: "Nederlands",
        translation: nl,
        momentLocale: 'nl'
    },
    fr: {
        label: "Français",
        translation: fr,
        momentLocale: 'fr'
    },
    de: {
        label: "Deutsch",
        translation: de,
        momentLocale: 'de'
    },
    es: {
        label: "Español",
        translation: es,
        momentLocale: 'es'
    }
};