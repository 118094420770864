import React, { Component } from "react";

class JobCompass extends Component {
    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 128"
            >
                <defs></defs>
                <title>x</title>
                <path
                    fill="#2d3e50"
                    d="M107.53246,20.4688a61.56255,61.56255,0,0,0-87.06264,87.06252A61.56254,61.56254,0,0,0,107.53246,20.4688ZM97.98858,97.98768A48.10211,48.10211,0,0,1,29.96192,29.961l.0006-.00064a48.10214,48.10214,0,0,1,68.02606,68.0273Z"
                />
                <path
                    fill="#2e79bd"
                    d="M85.34223,42.65712l-.01463-.0151a1.5833,1.5833,0,0,0-1.83173-.35776L58.67605,53.64432a13.60905,13.60905,0,0,0-5.061,5.08345L42.27237,83.77061a1.44764,1.44764,0,0,0,1.96824,1.95121L69.272,74.38433a13.61192,13.61192,0,0,0,5.08309-5.06075L85.72229,44.48765a1.58214,1.58214,0,0,0-.36492-1.81591Z"
                />
            </svg>
        );
    }
}

export default JobCompass;
