import React, { Component } from "react";
import PropTypes from "prop-types";
import "./ApplicantSkills.scss";
import _ from "lodash";
import Legend from "../../common/skills/legend/Legend.container";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants/constants";
import SkillsMap from "../../common/skills/skillsMap/SkillsMap.container";
import { SKILLS_MAP_MODE } from "../../common/skills/skillsMap/SkillsMap";

class ApplicantSkills extends Component {
    componentDidMount() {
        this.props.onMount();
    }

    render() {
        return (
            <div className="applicant-skills">
                <h4 className="page-header">
                    {_.get(this.props.translation, "applicant_skills_page.page_label")}
                </h4>
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        if (this.props.loading) {
            return (
                <h6 className="text-center">
                    {_.get(this.props.translation, "actions.fetching_message")}
                </h6>
            );
        }

        return (
            <>
                <div className="buttons-container">
                    <Button color="primary" tag={Link} to={ROUTES.EDIT_APPLICANT_SKILLS}>
                        {_.get(this.props.translation, "actions.edit_label")}
                    </Button>
                </div>
                <Legend />
                <SkillsMap
                    skillsTree={this.props.skillsTree}
                    mode={SKILLS_MAP_MODE.APPLICANT_VIEW}
                    imageSource={this.props.imageSource}
                    userId={this.props.userId}
                />
            </>
        );
    }
}

ApplicantSkills.propTypes = {
    translation: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    skillsTree: PropTypes.array,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageSource: PropTypes.string,
    onMount: PropTypes.func.isRequired,
};

export default ApplicantSkills;
