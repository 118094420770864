import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon/Icon';
import './JobItem.scss';
import Skill from '../skills/skill/Skill.container';
import { Label } from 'reactstrap';

class JobItem extends Component {
	render() {
		const { job, onJobClick, selectedJobsIds, onOpen } = this.props;

		const jobInfo = `${job.location.city}, ${job.location.countryCode}`
			.split(", ").filter(Boolean).join(", ");

		const isSelected = selectedJobsIds.includes(job.id);

		return (
			<div className="job-wrapper">
				<div className={`job-icon-wrapper ${isSelected ? "selected" : ""}`}>
					<Icon
						type="job"
						color="secondary"
						onClick={() => onJobClick(job.id)}
					/>
				</div>
				<div className="compare-icon-wrapper">
					<Icon
						type="compare"
						color="secondary"
						className="compare-icon"
						onClick={() => onOpen(job.id)}
					/>
				</div>
				<Label className="job-info">
					{jobInfo}
				</Label>
				<Label className="job-title">
					{job.title}
				</Label>
				<div className="job-skills-wrapper">
					{job.skills && !!job.skills.length && job.skills.map(skill => (
						<Skill
							key={skill.skill.id}
							id={"skill-map-skill-" + skill.skill.id}
							text={skill.skill.titles.en}
							isTextOverflowEllipsis
						/>
					))}
				</div>
			</div>
		)
	}
}

JobItem.propTypes = {
	job: PropTypes.object.isRequired,
	onJobClick: PropTypes.func.isRequired,
	onOpen: PropTypes.func.isRequired,
	selectedJobsIds: PropTypes.array
};

export default JobItem;
