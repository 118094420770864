import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
import { translations } from "../../translations";
import { StorageManager } from "../../utils/storageManager";
import moment from 'moment';

//- Actions
export const setSelectedLanguage = createAction('SELECTED_LANGUAGE_SET', language => language);

export const getDefaultLanguage = () => {
    let newLanguage = StorageManager.getLanguage();
    if (_.isNil(newLanguage)) {
        let topLanguage = window.navigator.language;
        let languages = window.navigator.languages;
        if (_.isArray(languages)) {
            newLanguage = _.find(languages, language => _.includes(_.keys(translations), language.substring(0, 2))).substring(0, 2) || DEFAULT_LANGUAGE;
        } else if (!_.isNil(topLanguage) && _.includes(_.keys(translations), topLanguage.substring(0, 2))) {
            newLanguage = topLanguage.substring(0, 2);
        } else {
            newLanguage = DEFAULT_LANGUAGE;
        }
    }
    return newLanguage;
};

export const changeLanguage = (language) => (dispatch) => {
    dispatch(setSelectedLanguage(language));
    StorageManager.setLanguage(language);
};

const DEFAULT_LANGUAGE = 'en';
const DEFAULT_MOMENT_LOCALE = 'en-gb';

//- State
const initialState = {
    fetching: false,
    error: null,
    selectedLanguage: DEFAULT_LANGUAGE,
    translation: null,
    translations
};

//- Reducers
export default handleActions({
    SELECTED_LANGUAGE_SET: (state, action) => {
        let languageCode = action.payload;
        if (_.has(state.translations, languageCode)) {
            let locale = state.translations[languageCode].momentLocale;
            _.includes(moment.locales(), locale) ? moment.locale(locale) : moment.locale(DEFAULT_MOMENT_LOCALE);
        } else {
            languageCode = DEFAULT_LANGUAGE;
            moment.locale(DEFAULT_MOMENT_LOCALE);
        }

        return { ...state, selectedLanguage: languageCode };
    }
}, initialState);

//- Selectors
export const getLocalizationState = state => state.localization;

export const getSelectedLanguage = state => getLocalizationState(state).selectedLanguage;

export const loadingTranslation = state => getLocalizationState(state).fetching;

export const getTranslations = state => getLocalizationState(state).translations;

export const getSelectedTranslation = state => _.isNil(getTranslations(state)) || !_.has(getTranslations(state), getSelectedLanguage(state)) ? null : getTranslations(state)[getSelectedLanguage(state)];

export const getTranslation = state => _.isNil(getSelectedTranslation(state)) ? null : getSelectedTranslation(state).translation;

export const getTranslationsList = state => {
    return _.chain(getTranslations(state))
        .map((translation, key) => ({ value: key, label: key.toUpperCase(), title: translation.label }))
        .value();
};