import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import _ from "lodash";

//- Actions
export const fetchGetSearchProfilesFailed = createAction("FETCH_GET_SEARCH_PROFILES_FAILED");
export const fetchGetSearchProfilesSuccess = createAction("FETCH_GET_SEARCH_PROFILES_SUCCESS");
export const onFetchGetSearchProfilesSuccess = (data) => (dispatch) => {
    let formattedProfiles = _.map(data, (profile) => {
        return {
            ...profile,
            id: profile.id,
            title: profile.alternativeTitle,
        };
    });
    dispatch(fetchGetSearchProfilesSuccess(formattedProfiles));
};
//  TODO: (BE) add search Profiles by Skill name And by Profiles name from filter string to backend
export const fetchGetSearchProfiles = createAction(
    "FETCH_GET_SEARCH_PROFILES",
    (filter) => Api.getSearchProfiles(filter),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSearchProfilesSuccess,
        onFail: fetchGetSearchProfilesFailed,
    })
);

export const cleanSearchProfiles = createAction("CLEAN_SEARCH_PROFILES");

//- State
const initialState = {
    data: null,
    error: null,
    fetching: false,
};

//- Reducers
export default handleActions(
    {
        FETCH_GET_SEARCH_PROFILES: (state) => {
            return { ...state, data: null, error: null, fetching: true };
        },
        FETCH_GET_SEARCH_PROFILES_FAILED: (state, action) => {
            return { ...state, data: null, error: action.payload, fetching: false };
        },
        FETCH_GET_SEARCH_PROFILES_SUCCESS: (state, action) => {
            return { ...state, data: action.payload, error: null, fetching: false };
        },
        CLEAN_SEARCH_PROFILES: (state) => {
            return { ...state, data: null };
        },
    },
    initialState
);

//- Selectors
export const getSearchProfilesState = (state) => state.searchProfiles;

export const getSearchProfilesData = (state) => getSearchProfilesState(state).data;

export const getSearchProfilesFetchingStatus = (state) => getSearchProfilesState(state).fetching;
