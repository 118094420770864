import { connect } from 'react-redux';
import RemoveEmployeeFromDepartmentModal from './RemoveEmployeeFromDepartmentModal';
import { getTranslation } from '../../../../../reducers/common/localization';
import {
	getActiveModalType,
	hideModal,
	MODAL_REMOVE_EMPLOYEE_FROM_DEPARTMENT
} from '../../../../../reducers/common/activeModal';
import { removeEmployeeFromDepartment } from '../../../../../reducers/organization/departments';

const mapStateToProps = state => ({
	translation: getTranslation(state),
	isOpen: getActiveModalType(state) === MODAL_REMOVE_EMPLOYEE_FROM_DEPARTMENT,
});

const mapDispatchToProps = dispatch => ({
	onClose: () => dispatch(hideModal()),
	removeEmployeeFromDepartment: (data) => dispatch(removeEmployeeFromDepartment(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveEmployeeFromDepartmentModal);
