import { connect } from "react-redux";
import RelatedNodes from "./RelatedNodes";
import { getTranslation } from "../../../../../reducers/common/localization";
import { NODE_TYPE } from "../../../../../constants/systemNodesConstants";
import {
    onFetchGetRelatedSkills,
    getRelatedSkillsData,
    getRelatedSkillsFetchingStatus,
    removeRelationWithSkill,
    getRemovedRelatedSkillsData,
    undoRemoveRelationWithSkill,
    undoAddRelationWithSkill,
    getAddedRelatedSkillsData,
} from "../../../../../reducers/admin/relatedSkills";
import {
    showModal,
    MODAL_ADD_RELATION_WITH_SKILL,
} from "../../../../../reducers/common/activeModal";
import {
    fetchRemoveSkillDependency,
    getSystemCurrentNodeData,
} from "../../../../../reducers/admin/systemCurrentNode";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        nodesType: NODE_TYPE.SKILL,
        nodesList: getRelatedSkillsData(state),
        loading: getRelatedSkillsFetchingStatus(state),
        removedNodes: getRemovedRelatedSkillsData(state),
        addedNodes: getAddedRelatedSkillsData(state),
        currentNode: getSystemCurrentNodeData(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onMount: () => dispatch(onFetchGetRelatedSkills()),
        onRemoveRelation: (nodeId) => dispatch(removeRelationWithSkill(nodeId)),
        onUndoRemoveRelation: (nodeId) => dispatch(undoRemoveRelationWithSkill(nodeId)),
        onShowAddRelationModal: () => dispatch(showModal(MODAL_ADD_RELATION_WITH_SKILL)),
        onUndoAddRelation: (nodeId) => dispatch(undoAddRelationWithSkill(nodeId)),
        onRemoveDependency: (skillId, skillIdToRemove) =>
            dispatch(fetchRemoveSkillDependency(skillId, skillIdToRemove)),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RelatedNodes));
