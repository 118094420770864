import { connect } from 'react-redux';
import CandidateModalHeader from "./CandidateModalHeader";
import { getTranslation } from "../../../../../../reducers/common/localization";
import {
    getCandidateIdForActiveModal,
    getOpenJobAnonymityMode,
    getCandidateDataForActiveModal,
    getOpenJobPlannedTopCandidatesAmount,
    onAddCandidateToFavorite,
    onRemoveCandidateFromFavorite,
    onAddCandidateToTopXGroup
} from '../../../../../../reducers/job/openJob';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        userId: getCandidateIdForActiveModal(state),
        anonymityMode: getOpenJobAnonymityMode(state),
        userData: getCandidateDataForActiveModal(state),
        plannedTopAmount: getOpenJobPlannedTopCandidatesAmount(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAddToFavorite: (userId) => dispatch(onAddCandidateToFavorite(userId)),
        onRemoveFromFavorite: (userId) => dispatch(onRemoveCandidateFromFavorite(userId)),
        onAddToTopXGroup: (userId) => dispatch(onAddCandidateToTopXGroup(userId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateModalHeader);
