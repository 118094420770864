import { connect } from "react-redux";
import { getActiveJobId } from "../../../../reducers/applicant/applicantJobs";
import { getTranslation } from "../../../../reducers/common/localization";
import JobDetails from "./JobDetails";
import { showModal, MODAL_RICH_MEDIA } from "../../../../reducers/common/activeModal";
import { getUserJobsState } from "../../../../reducers/user/userData";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        activeJobId: getActiveJobId(state),
        userJobs: getUserJobsState(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openRichMediaModal: () => dispatch(showModal(MODAL_RICH_MEDIA)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDetails);
