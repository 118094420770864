import { createAction, handleActions } from 'redux-actions';

//- Actions
export const setRequestStatusSubmit = createAction('SET_REQUEST_STATUS_SUBMIT');
export const setRequestStatusFailed = createAction('SET_REQUEST_STATUS_FAILED');
export const setRequestStatusSuccess = createAction('SET_REQUEST_STATUS_SUCCESS');
export const resetRequestStatusData = createAction('RESET_REQUEST_STATUS_DATA');

export const onSetRequestStatusSuccess = (successMessage) => (data) => (dispatch) => {
    dispatch(setRequestStatusSuccess(successMessage));
};

//- State
const initialState = {
    fetching: false,
    success: false,
    successMessage: null,
    error: false,
    errorMessage: null,
};

//- Reducers
export default handleActions({

    SET_REQUEST_STATUS_SUBMIT: (state) => {
        return { ...state, fetching: true, success: false, successMessage: null, error: false, errorMessage: null };
    },
    SET_REQUEST_STATUS_FAILED: (state, action) => {
        return { ...state, fetching: false, success: false, successMessage: null, error: true, errorMessage: action.payload.message };
    },
    SET_REQUEST_STATUS_SUCCESS: (state, action) => {
        return { ...state, fetching: false, success: true, successMessage: action.payload, error: false, errorMessage: null };
    },
    RESET_REQUEST_STATUS_DATA: () => {
        return initialState;
    },

}, initialState);

//- Selectors
export const getRequestStatusState = state => state.requestStatus;

export const getRequestFetchingStatus = state => getRequestStatusState(state).fetching;

export const getRequestSuccessStatus = state => getRequestStatusState(state).success;

export const getRequestSuccessMessage = state => getRequestStatusState(state).successMessage;

export const getRequestErrorStatus = state => getRequestStatusState(state).error;

export const getRequestErrorMessage = state => getRequestStatusState(state).errorMessage;
