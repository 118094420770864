import { connect } from "react-redux";
import NodesFilters from "./NodesFilters";
import { getTranslation } from "../../../../reducers/common/localization";
import {
    getSystemNodesNameFilter,
    getSystemNodesTypeFilter,
    getSystemNodesSourceFilter,
    getSystemNodesStatusFilter,
    getSystemNodesDomainFilter,
    refreshSystemNodesExternalSources,
    onFetchGetSystemNodesList,
    updateSystemNodesNameFilter,
    onUpdateSystemNodesTypeFilter,
    onUpdateSystemNodesSourceFilter,
    onUpdateSystemNodesStatusFilter,
    onUpdateSystemNodesDomainFilter,
    onUpdateSystemNodesSortingMode,
    getSystemNodesSortingMode,
    getSystemNodesExternalSources,
    getSystemNodesExternalSourcesFetchingStatus,
} from "../../../../reducers/admin/systemModel";
import { fetchGetDomainList, getDomains, getSC } from "../../../../reducers/admin/domainsAndSC";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        nodeNameFilter: getSystemNodesNameFilter(state),
        nodeTypeFilter: getSystemNodesTypeFilter(state),
        nodeSourceFilter: getSystemNodesSourceFilter(state),
        nodeStatusFilter: getSystemNodesStatusFilter(state),
        nodeDomainFilter: getSystemNodesDomainFilter(state),
        sortingMode: getSystemNodesSortingMode(state),
        externalSources: getSystemNodesExternalSources(state),
        loadingExternalSources: getSystemNodesExternalSourcesFetchingStatus(state),
        domains: getDomains(state),
        skill_clusters: getSC(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onMount: () => {
            dispatch(refreshSystemNodesExternalSources());
            dispatch(fetchGetDomainList());
        },
        onGetNodesList: () => dispatch(onFetchGetSystemNodesList()),
        onUpdateNodesNameFilter: (filter) => dispatch(updateSystemNodesNameFilter(filter)),
        onUpdateNodesTypeFilter: (typeFilter) => {
            dispatch(onUpdateSystemNodesTypeFilter(typeFilter))
        },
        onUpdateNodesSourceFilter: (filter) => dispatch(onUpdateSystemNodesSourceFilter(filter)),
        onUpdateNodesStatusFilter: (filter) => dispatch(onUpdateSystemNodesStatusFilter(filter)),
        onUpdateNodesDomainFilter: (filter) => dispatch(onUpdateSystemNodesDomainFilter(filter)),
        onUpdateNodesSortingMode: (sortingMode) =>
            dispatch(onUpdateSystemNodesSortingMode(sortingMode)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NodesFilters);
