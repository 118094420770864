import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ActivateApplicantAccount.scss';
import _ from 'lodash';
import { FormGroup, Label, Input, Col, Button, Form } from 'reactstrap';
import ConfirmPasswordFormGroups from '../../../common/formGroups/confirmPasswordFormGroups/ConfirmPasswordFormGroups.container';
import { Utils } from '../../../../utils/utils';

class ActivateApplicantAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: "",
            passwordConfirm: ""
        }
    }

    render() {
        if (_.isNil(this.props.data)) {
            return null;
        }

        return (
            <div className="applicant-account-activation">
                <h4 className="text-center applicant-account-activation-header">{_.get(this.props.translation, 'activate_account_page.page_label')}</h4>
                <h6 className="applicant-account-activation-hint">{_.get(this.props.translation, 'activate_account_page.activate_applicant_account_hint')}</h6>
                <Form onSubmit={this.onSubmitActivation}>
                    <FormGroup row>
                        <Label sm="3">{_.get(this.props.translation, 'person_data.email_label')}</Label>
                        <Col sm="9">
                            <Input
                                type="email"
                                defaultValue={this.props.data.email}
                                disabled
                            >
                            </Input>
                        </Col>
                    </FormGroup>
                    <ConfirmPasswordFormGroups
                        password={this.state.password}
                        passwordConfirm={this.state.passwordConfirm}
                        handleChangeField={this.handleChangeField}
                    />
                    <div className="buttons-container form-buttons">
                        <Button
                            color="primary"
                            type="submit"
                            disabled={this.isSubmitFormDisabled()}
                        >
                            {_.get(this.props.translation, 'activate_account_page.submit_button')}
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }

    handleChangeField = (fieldName) => (e) => {
        this.setState({ [fieldName]: e.target.value });
    }

    isSubmitFormDisabled = () => {
        return _.isEmpty(this.state.password) || _.isEmpty(this.state.passwordConfirm) ||
            !Utils.isValidPassword(this.state.password) ||
            this.state.password !== this.state.passwordConfirm;
    }

    onSubmitActivation = (e) => {
        e.preventDefault();

        let data = {
            password: this.state.password
        };

        this.props.onActivateAccount(data, this.props.token);
    }

}

ActivateApplicantAccount.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.object,
    token: PropTypes.string.isRequired,
    onActivateAccount: PropTypes.func.isRequired
}

export default ActivateApplicantAccount;
