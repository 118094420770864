import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './RequestStatus.scss';
import _ from 'lodash';
import { Alert } from 'reactstrap';
import { REQUEST_SUCCESS_MESSAGE, REQUEST_SUCCESS_MESSAGE_TRANSLATION } from '../../../constants/requestStatusConstants';

const DEFAULT_MESSAGE = {
    LOADING: "actions.fetching_message",
    SUCCESS: "actions.success_message",
    ERROR: "errors.DEFAULT_ERROR",
}

class RequestStatus extends Component {
    render() {
        return (
            <>
                {
                    this.props.loading ?
                        <div className="request-loading-overlay"></div>
                        : null
                }
                {this.renderMessage()}
            </>
        );
    }

    renderMessage() {
        if (this.props.loading) {
            return (
                <Alert color="warning" className="requests-status-alert fetching-status">
                    {_.get(this.props.translation, DEFAULT_MESSAGE.LOADING)}
                </Alert>
            );
        } else if (this.props.success) {
            if (this.props.successMessage === REQUEST_SUCCESS_MESSAGE.NO_MESSAGE) {
                return null;
            }

            return (
                <Alert color="success" className="requests-status-alert success-status" toggle={this.props.onResetRequestStatus}>
                    {_.get(this.props.translation, REQUEST_SUCCESS_MESSAGE_TRANSLATION[this.props.successMessage] || DEFAULT_MESSAGE.SUCCESS)}
                </Alert>
            );
        } else if (this.props.error) {
            return (
                <Alert color="danger" className="requests-status-alert error-status" toggle={this.props.onResetRequestStatus}>
                    {/*{_.get(this.props.translation, "errors")[this.props.errorMessage] || _.get(this.props.translation, DEFAULT_MESSAGE.ERROR)}*/}
                    {`${this.props.errorMessage}`}
                </Alert>
            );
        }
    }
}


RequestStatus.propTypes = {
    translation: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    successMessage: PropTypes.string,
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    onResetRequestStatus: PropTypes.func.isRequired
};

export default RequestStatus;
