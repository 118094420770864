import { connect } from "react-redux";
import {
    fetchGenerate2FASecret,
    getQRCodeData,
    is2FAEnable,
    onDisable2FA,
    onEnable2FA,
} from "../../../reducers/authorization/security";
import {
    DOUBLE_FA_MODAL,
    getActiveModalType,
    hideModal,
} from "../../../reducers/common/activeModal";
import { getTranslation } from "../../../reducers/common/localization";
import { getUserEmail } from "../../../reducers/user/userData";
import DFAModal from "./DFAModal";

const mapStateToProps = (state) => ({
    translation: getTranslation(state),
    isOpen: getActiveModalType(state) === DOUBLE_FA_MODAL,
    qrCodeData: getQRCodeData(state),
    modalType: getActiveModalType(state),
    userEmail: getUserEmail(state),
    is2FAEnabled: is2FAEnable(state),
});

const mapDispatchToProps = (dispatch) => ({
    onEnable2FA: (checkCode) => {
        dispatch(onEnable2FA(checkCode));
        dispatch(hideModal(DOUBLE_FA_MODAL));
    },
    onGenerate2FASecret: () => dispatch(fetchGenerate2FASecret()),
    onClose: () => dispatch(hideModal(DOUBLE_FA_MODAL)),
    onDisable2FA: () => {
        dispatch(onDisable2FA());
        dispatch(hideModal(DOUBLE_FA_MODAL));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DFAModal);
