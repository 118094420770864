import { connect } from 'react-redux';
import DashboardComparison from './DashboardComparison';
import { getTranslation } from '../../../../../reducers/common/localization';
import {
    getOpenJobAnonymityMode,
    onAddCandidateToFavorite,
    onRemoveCandidateFromFavorite,
    setCandidateForActiveModal,
    onAddCandidateToTopXGroup,
    getOpenJobPlannedTopCandidatesAmount,
    getOpenJobSkillsDataTree,
    getOpenJobComparingMode,
    getOpenJobFFMData,
} from '../../../../../reducers/job/openJob';
import {
    getSelectedCandidatesAndJobComparedSkillsTree,
    getSelectedCandidatesData,
    getSelectedCandidatesFetchingStatus,
    onSelectCandidate,
    getSelectedCandidateFFMData
} from '../../../../../reducers/job/selectedCandidates';
import { showModal, MODAL_CANDIDATE_NOTES, MODAL_CANDIDATE_CROSS_JOB_INFO } from '../../../../../reducers/common/activeModal';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        candidatesData: getSelectedCandidatesData(state),
        jobSkillsTree: getOpenJobSkillsDataTree(state),
        comparedSkills: getSelectedCandidatesAndJobComparedSkillsTree(state),
        jobFFMData: getOpenJobFFMData(state),
        candidatesFFMData: getSelectedCandidateFFMData(state),
        loading: getSelectedCandidatesFetchingStatus(state),
        anonymityMode: getOpenJobAnonymityMode(state),
        plannedTopAmount: getOpenJobPlannedTopCandidatesAmount(state),
        comparingMode: getOpenJobComparingMode(state),
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onAddToFavorite: (userId) => dispatch(onAddCandidateToFavorite(userId)),
        onRemoveFromFavorite: (userId) => dispatch(onRemoveCandidateFromFavorite(userId)),
        onAddToTopXGroup: (userId) => dispatch(onAddCandidateToTopXGroup(userId)),
        onCandidateNotesClick: (userId) => {
            dispatch(setCandidateForActiveModal(userId));
            dispatch(showModal(MODAL_CANDIDATE_NOTES));
        },
        onDeselectCandidate: (userId) => dispatch(onSelectCandidate(userId)),
        onShowCrossJobInfoModal: (userId) => {
            dispatch(setCandidateForActiveModal(userId));
            dispatch(showModal(MODAL_CANDIDATE_CROSS_JOB_INFO));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComparison);
