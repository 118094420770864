import React, { Component } from 'react';

class ExpandLess extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
            </svg>
        );
    }
}

export default ExpandLess;
