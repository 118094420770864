import { connect } from "react-redux";
import PostJobFirstStep from "./PostJobFirstStep";
import { getTranslation } from "../../../../../reducers/common/localization";
import {
    fetchGetStaffList,
    getStaffListData,
} from "../../../../../reducers/organization/staffList";
import { getUserData } from "../../../../../reducers/user/userData";
import { getLocationData } from "../../../../../reducers/common/location";
import {
    MODAL_ADD_JOB_REQUIRED_SKILLS,
    showModal,
} from "../../../../../reducers/common/activeModal";
import {
    fetchPostOrganizationJob,
} from "../../../../../reducers/job/organizationJobs";
import { getJobSkillsData, getJobSkillsDataTree } from "../../../../../reducers/job/jobSkills";
import { getJobProfilesData } from "../../../../../reducers/job/jobProfiles";

const mapStateToProps = (state) => ({
    translation: getTranslation(state),
    staffList: getStaffListData(state),
    userData: getUserData(state),
    locationData: getLocationData(state),
    skills: getJobSkillsData(state),
    skillsTree: getJobSkillsDataTree(state),
    profiles: getJobProfilesData(state),
});

const mapDispatchToProps = (dispatch) => ({
    onMount: () => {
        dispatch(fetchGetStaffList());
    },
    onPostJob: (data) => dispatch(fetchPostOrganizationJob(data)),
    onAddJobRequiredSkillsJob: () => dispatch(showModal(MODAL_ADD_JOB_REQUIRED_SKILLS)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostJobFirstStep);
