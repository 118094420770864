import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './RestoreAccount.scss';
import _ from 'lodash';
import BackLink from '../../../common/buttons/backLink/BackLink.container';
import { ROUTES } from '../../../../constants/constants';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { Link } from 'react-router-dom';

class RestoreAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
        };
    }

    componentDidMount() {
        this.setState({ email: this.props.email });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.email !== this.props.email) {
            this.setState({ email: this.props.email });
        }
    }

    render() {
        return (
            <div className="restore-account">
                <div className="back-link-container">
                    <BackLink to={ROUTES.LOGIN} label={_.get(this.props.translation, 'login_page.back_to_login_page_label')} />
                </div>
                <div className="restore-account-form">
                    <h5 className="text-center">{_.get(this.props.translation, 'restore_account_page.page_label')}</h5>
                    <Form onSubmit={this.onRestoreAccount}>
                        <FormGroup>
                            <Input
                                type="email"
                                value={this.state.email}
                                onChange={this.handleChangeEmail}
                                required
                                placeholder={_.get(this.props.translation, 'person_data.email_label')}
                            />
                        </FormGroup>
                        <Button type="submit" block color="primary" disabled={_.isEmpty(this.state.email)}>
                            {_.get(this.props.translation, 'restore_account_page.submit_button')}
                        </Button>
                    </Form>
                </div>
                <div className="help-message small-text">
                    {_.get(this.props.translation, 'restore_account_page.link_to_help_text')}
                    <Link to={ROUTES.SUPPORT}>{_.get(this.props.translation, 'restore_account_page.link_to_help')}</Link>
                    {_.get(this.props.translation, 'restore_account_page.link_to_help_end')}
                </div>
            </div>
        );
    }

    handleChangeEmail = (e) => {
        this.setState({ email: e.target.value });
    };

    onRestoreAccount = (e) => {
        e.preventDefault();

        this.props.onRestoreAccount({ email: this.state.email });
    }
}

RestoreAccount.propTypes = {
    translation: PropTypes.object.isRequired,
    email: PropTypes.string,
    onRestoreAccount: PropTypes.func.isRequired
};

export default RestoreAccount;
