import { connect } from 'react-redux';
import UserPhoto  from './UserPhoto';
import { getSelectedStatus, onCandidatePress } from '../../../reducers/quickfind/quickfind';

const mapStateToProps = (state, { userId }) => {
	return {
		isUserSelected: getSelectedStatus(state, userId),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onPhotoClick: (userId) => dispatch(onCandidatePress(userId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPhoto);
