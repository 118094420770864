import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import './CandidateItem.scss';
import { USER_PHOTO_MODE } from '../../common/userPhoto/UserPhoto';
import UserPhoto from '../../common/userPhoto/UserPhotoQuickfind.container';
import Skill from '../../common/skills/skill/Skill.container';
import Icon from '../../common/icon/Icon';

class CandidateItem extends Component {
	render() {
		const { applicant, onOpenModal } = this.props;

		const candidateInfo = `${applicant.address.city}, ${applicant.address.countryCode}`
			.split(", ").filter(Boolean).join(", ");

		return (
			<div className="candidate-wrapper">
				<UserPhoto
					mode={USER_PHOTO_MODE.SELECTION}
					userId={applicant.id}
					imageSource=""
					isClickable
				/>
				<div
					className="compare-icon-wrapper"
					onClick={() => onOpenModal(applicant.id)}
				>
					<Icon
						type="compare"
						color="secondary"
						className="compare-icon"
					/>
				</div>
				<Label className="candidate-job">
					{applicant.currentJob}
				</Label>
				<Label className="candidate-info">
					{candidateInfo}
				</Label>
				<div className="candidate-skills-wrapper">
						{!!applicant.skills.length && applicant.skills.map(skill => (
							<Skill
								key={skill.skill.id}
								id={"skill-map-skill-" + skill.skill.id}
								text={skill.skill.titles.en}
								isTextOverflowEllipsis
							/>
						))}
				</div>
			</div>
		);
	}
}

CandidateItem.propTypes = {
	applicant: PropTypes.object.isRequired,
	onOpenModal: PropTypes.func.isRequired,
};

export default CandidateItem;
