import { connect } from 'react-redux';
import { getTranslation } from '../../../reducers/common/localization';
import ApplicantsComparison from './ApplicantsComparison';
import { getSelectedCandidatesIds, getSelectedSkills } from '../../../reducers/quickfind/quickfind';
import { getApplicantsData, onFetchApplicantsByIds, getFetchingApplicantsByIds } from '../../../reducers/quickfind/applicantsComparison';

const mapStateToProps = state => {
	return {
		translation: getTranslation(state),
		selectedCandidatesIds: getSelectedCandidatesIds(state),
		applicantsData: getApplicantsData(state),
		skills: getSelectedSkills(state),
		loading: getFetchingApplicantsByIds(state)
	};
};

const mapDispatchToProps = dispatch => ({
	onFetchApplicantsByIds: (applicantsIds) => dispatch(onFetchApplicantsByIds(applicantsIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantsComparison);
