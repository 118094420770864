import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";
import _ from "lodash";
import { ResizableBox } from "react-resizable";
import "./SkillsJourney.scss";
import Icon from "../../common/icon/Icon";
import SkillsMap, { SKILLS_MAP_MODE } from "../../common/skills/skillsMap/SkillsMap";
import JobTimeline from "./Timelines/JobTimeline/JobTimeline";
import AddNewPastJobModal from "../../common/modals/addNewPastJobModal/AddNewPastJobModal.container";
import Legend from "../../common/skills/legend/Legend.container";
import { LEGEND_TYPE } from "../../common/skills/legend/Legend";
import SkillsTimeline from "./Timelines/SkillsTimeline/SkillsTimeline.container";
import LQGraph from "./LQGraph/LQGraph";
import JobsDescription from "./JobsDescription/JobsDescription";
import DraggableLines from "./DraggableLines/DraggableLines";
import { getMsFromTimeString } from "./utils";

class SkillsJourney extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedJobsOnTimeline: null,
            selectedDateOnTimeline: new Date().getTime(),
            addPastJobTooltipOpen: false,
            jobTimelineHandlerTop: null,
            jobTimelineSliderHeight: 0,
            jobSliderContainerTopOffset: 0,
            jobTimelineContainerSizes: {},
            jobTimelineData: {},
            dateInPercentOfLQWidth: 100,
            centralContainerSizes: {},
            skillsMapContainerSizes: {},
        };

        this.centralContainerRef = React.createRef();
        this.skillsMapContainerRef = React.createRef();
    }

    componentDidMount() {
        const { onMount } = this.props;
        onMount();

        if (this.centralContainerRef.current) {
            const { width, height } = this.centralContainerRef.current.getBoundingClientRect();
            this.setState({ centralContainerSizes: { width, height } });
        }
        if (this.skillsMapContainerRef.current) {
            const { width, height } = this.skillsMapContainerRef.current.getBoundingClientRect();
            this.setState({ skillsMapContainerSizes: { width, height } });
        }
    }

    getValueFromSelectedDate(date, timelineDuration, timelineStarted) {
        return ((date - timelineStarted) / timelineDuration) * 100;
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            selectedDateOnTimeline,
            jobTimelineData: { careerDuration, careerStarted },
            jobSliderContainerTopOffset,
            jobTimelineSliderHeight,
        } = this.state;
        const { currentSkillData, onСlearCurrentSkillAndCluster, pastJobs } = this.props;

        if (prevState.selectedDateOnTimeline !== selectedDateOnTimeline) {
            if (currentSkillData) {
                if (
                    (currentSkillData.skillLifecycle.forgottenAt &&
                        selectedDateOnTimeline >
                            new Date(currentSkillData.skillLifecycle.forgottenAt).getTime()) ||
                    selectedDateOnTimeline <
                        new Date(currentSkillData.skillLifecycle.acquiredAt).getTime()
                ) {
                    onСlearCurrentSkillAndCluster();
                }
            }
            const value = this.getValueFromSelectedDate(
                selectedDateOnTimeline,
                careerDuration,
                careerStarted
            );
            if (value >= 0) {
                const jobsOnCurrentDate = pastJobs.filter(
                    (job) =>
                        getMsFromTimeString(job.startedAt) <= selectedDateOnTimeline &&
                        (getMsFromTimeString(job.endedAt) || new Date().getTime()) >=
                            selectedDateOnTimeline
                );
                this.setState({
                    jobTimelineHandlerTop:
                        (Math.abs(value - 100) * jobTimelineSliderHeight) / 100 +
                        jobSliderContainerTopOffset,
                });
                this.setState({ selectedJobsOnTimeline: jobsOnCurrentDate });
            }
        }
    }

    getFilteredSkillsTree(selectedDateOnTimeline) {
        return this.props.skillsTree
            .map((category) => {
                const filteredCategory = category.children.filter((skill) => {
                    return (
                        selectedDateOnTimeline >=
                            getMsFromTimeString(skill.skillLifecycle.acquiredAt) &&
                        (skill.skillLifecycle.forgottenAt
                            ? selectedDateOnTimeline <=
                              getMsFromTimeString(skill.skillLifecycle.forgottenAt)
                            : true)
                    );
                });
                return filteredCategory.length ? { ...category, children: filteredCategory } : null;
            })
            .filter((category) => !!category);
    }

    onSelectSkill = (skillId, categoryId) => () => {
        this.props.onSelectSkill(skillId, categoryId);
    };

    render() {
        const {
            selectedJobsOnTimeline,
            addPastJobTooltipOpen,
            selectedDateOnTimeline,
            jobTimelineHandlerTop,
            jobTimelineSliderHeight,
            jobSliderContainerTopOffset,
            jobTimelineData,
            jobTimelineContainerSizes,
            dateInPercentOfLQWidth,
            centralContainerSizes,
            skillsMapContainerSizes,
        } = this.state;
        const {
            translation,
            onAddPastJobClick,
            onAddSkillNoteClick,
            skillsTree,
            imageSource,
            userId,
            currentSkillId,
            currentSkillData,
            pastJobs,
            lqGraphData,
        } = this.props;
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <h4 className="page-header" style={{ textAlign: "center" }}>
                    {_.get(translation, "skills_journey_page.page_label")}
                </h4>
                <div style={{ display: "flex", height: "100%" }}>
                    <div className="skill-journey-block-container left">
                        <div
                            className="handler-dark-block"
                            style={{ top: jobTimelineHandlerTop - 17 }}
                        />
                        {/* 17 - half of size of 'plus' button */}
                        <h5 style={{ alignSelf: "center" }}>
                            {_.get(translation, "skills_journey_page.job_timeline_label")}
                        </h5>
                        {pastJobs?.length ? (
                            <JobTimeline
                                translation={translation}
                                selectedDateOnTimeline={selectedDateOnTimeline}
                                jobTimelineSliderHeight={jobTimelineSliderHeight}
                                jobSliderContainerTopOffset={jobSliderContainerTopOffset}
                                jobTimelineContainerSizes={jobTimelineContainerSizes}
                                setParentStateValue={(key, value) =>
                                    this.setState({ [key]: value })
                                }
                                jobTimelineData={jobTimelineData}
                                pastJobs={pastJobs}
                            />
                        ) : (
                            _.get(translation, "skills_journey_page.job_timeline_fallback")
                        )}
                        <Icon
                            type="plus_outline"
                            color="success"
                            size="xl"
                            title={_.get(translation, "skills_journey_page.add_past_job")}
                            id="addPastJobButton"
                            onClick={onAddPastJobClick}
                        />
                        <Tooltip
                            placement="right"
                            isOpen={addPastJobTooltipOpen}
                            target="addPastJobButton"
                            toggle={() =>
                                this.setState({ addPastJobTooltipOpen: !addPastJobTooltipOpen })
                            }
                        >
                            {_.get(translation, "skills_journey_page.add_past_job")}
                        </Tooltip>
                    </div>
                    <div
                        className="skill-journey-block-container center"
                        style={{ position: "relative", marginBottom: "10px" }}
                        ref={this.centralContainerRef}
                    >
                        {selectedDateOnTimeline && (
                            <div
                                className="skills-map-component-container"
                                ref={this.skillsMapContainerRef}
                            >
                                <ResizableBox
                                    style={{ position: "unset" }}
                                    width={
                                        centralContainerSizes.width - 60 > 530
                                            ? 530
                                            : centralContainerSizes.width - 60
                                    }
                                    height={300}
                                    axis="y"
                                    draggableOpts={{}}
                                    minConstraints={[200, 200]}
                                    maxConstraints={[750, 1000]}
                                    onResize={() => {
                                        this.setState({
                                            skillsMapContainerSizes: this.skillsMapContainerRef.current.getBoundingClientRect(),
                                        });
                                    }}
                                    handle={
                                        <span>
                                            <DraggableLines
                                                height={skillsMapContainerSizes.height + 15}
                                            />
                                        </span>
                                    }
                                >
                                    <>
                                        <h5>
                                            {_.get(
                                                translation,
                                                "skills_management.skills_map_label"
                                            )}
                                        </h5>
                                        <Legend vertical type={LEGEND_TYPE.SKILLS_VIEW} />
                                        <SkillsMap
                                            skillsTree={
                                                skillsTree &&
                                                this.getFilteredSkillsTree(selectedDateOnTimeline)
                                            }
                                            mode={SKILLS_MAP_MODE.SKILLS_VIEW}
                                            imageSource={imageSource}
                                            userId={userId}
                                            onSelectSkill={this.onSelectSkill}
                                            onRemoveClick={() => {}}
                                            onNotesClick={() => {}}
                                            onRemoveSkill={() => {}}
                                            currentSkillId={currentSkillId}
                                            translation={translation}
                                        />
                                    </>
                                </ResizableBox>
                            </div>
                        )}

                        {lqGraphData && (
                            <LQGraph
                                translation={translation}
                                selectedDateOnTimeline={selectedDateOnTimeline}
                                dateInPercentOfLQWidth={dateInPercentOfLQWidth}
                                setParentStateValue={(key, value) =>
                                    this.setState({ [key]: value })
                                }
                                lqGraphData={lqGraphData}
                            />
                        )}
                    </div>
                    <div className="skill-journey-block-container right">
                        <h5>{_.get(translation, "skills_journey_page.skill_timeline_label")}</h5>
                        {currentSkillData && <h6>{currentSkillData.title}</h6>}
                        <div className="skill-timeline-container">
                            <div className="inner-block-container">
                                <SkillsTimeline
                                    selectedDateOnTimeline={selectedDateOnTimeline}
                                    currentSkillData={currentSkillData}
                                    jobTimelineSliderHeight={jobTimelineSliderHeight}
                                    jobSliderContainerTopOffset={jobSliderContainerTopOffset}
                                    jobTimelineContainerSizes={jobTimelineContainerSizes}
                                    translation={translation}
                                    setParentStateValue={(key, value) =>
                                        this.setState({ [key]: value })
                                    }
                                    onAddSkillNoteClick={onAddSkillNoteClick}
                                />
                            </div>
                        </div>
                        <div className="description-container">
                            <JobsDescription
                                jobs={selectedJobsOnTimeline}
                                translation={translation}
                            />
                        </div>
                    </div>
                    <AddNewPastJobModal />
                </div>
            </div>
        );
    }
}

SkillsJourney.propTypes = {
    translation: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    onMount: PropTypes.func.isRequired,
    imageSource: PropTypes.string,
    // probably required
    skills: PropTypes.array,
    skillsTree: PropTypes.array,
    currentSkillId: PropTypes.number,
    currentSkillData: PropTypes.object,
    onAddPastJobClick: PropTypes.func.isRequired,
    onAddSkillNoteClick: PropTypes.func.isRequired,
    onSelectSkill: PropTypes.func.isRequired,
    onСlearCurrentSkillAndCluster: PropTypes.func.isRequired,
};

export default SkillsJourney;
