import React, { Component } from "react";

class Phone extends Component {
    render() {
        return (
            <svg
                version="1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                enableBackground="new 0 0 48 48"
            >
                <path
                    fill="#F57C00"
                    d="M40,41H8c-2.2,0-4-1.8-4-4V11c0-2.2,1.8-4,4-4h32c2.2,0,4,1.8,4,4v26C44,39.2,42.2,41,40,41z"
                />
                <circle fill="#FFF9C4" cx="35" cy="16" r="3" />
                <polygon fill="#942A09" points="20,16 9,32 31,32" />
                <polygon fill="#BF360C" points="31,22 23,32 39,32" />
            </svg>
        );
    }
}

export default Phone;
