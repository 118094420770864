import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import TagComponent from '../tagComponent/TagComponent.container';

class AutocompleteTagInput extends Component {
	render() {

		const {
			suggestions,
			tags,
			onAddition,
			onDelete,
			onSearchSkills,
			onCleanSearchSkills,
			placeholder
		} = this.props;

		return (
			<ReactTags
				tags={tags}
				placeholderText={placeholder || ''}
				suggestions={suggestions}
				onDelete={() => {}}
				onAddition={onAddition}
				onInput={value => {
					if (value) {
						onSearchSkills(value);
					} else {
						onCleanSearchSkills();
					}
				}}
				tagComponent={props => <TagComponent {...props} onDeleteSkill={onDelete} />}
			/>
		);
	}
}

AutocompleteTagInput.propTypes = {
	tags: PropTypes.array.isRequired,
	suggestions: PropTypes.array.isRequired,
	onDelete: PropTypes.func.isRequired,
	onAddition: PropTypes.func.isRequired,
	onSearchSkills: PropTypes.func.isRequired,
	onCleanSearchSkills: PropTypes.func.isRequired,
	placeholder: PropTypes.string
};

export default AutocompleteTagInput;

