import { connect } from 'react-redux';
import ResendStaffMemberActivationModal from "./ResendStaffMemberActivationModal";
import { getTranslation } from '../../../../../reducers/common/localization';
import { getActiveModalType, MODAL_RESEND_STAFF_MEMBER_ACTIVATION, hideModal } from '../../../../../reducers/common/activeModal';
import { onFetchResendStaffMemberActivation } from '../../../../../reducers/organization/staffMembers';

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_RESEND_STAFF_MEMBER_ACTIVATION
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => dispatch(hideModal()),
        onResendStaffMemberActivation: (data) => dispatch(onFetchResendStaffMemberActivation(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResendStaffMemberActivationModal);
