import { connect } from "react-redux";
import ModelManagement from "./ModelManagement";
import { getTranslation } from "../../../reducers/common/localization";
import {
    getSystemNodesList,
    getSystemNodesListFetchingStatus,
    onFetchGetSystemNodesList,
    onFetchGetSystemNodesExternalSourceSuccess,
} from "../../../reducers/admin/systemModel";
import {
    getSystemCurrentNodeId, getSystemCurrentNodeState,
    onSetCurrentSystemNode,
} from "../../../reducers/admin/systemCurrentNode";
import { fetchGetAllSkillClusters, fetchGetDomainList } from "../../../reducers/admin/domainsAndSC";
import { showCreateNodeModal } from "../../../reducers/common/activeModal";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        nodesList: getSystemNodesList(state),
        nodesListLoading: getSystemNodesListFetchingStatus(state),
        currentNodeId: getSystemCurrentNodeId(state),
        selectedGraphNode: state.systemCurrentNode.selectedGraphNode,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onMount: () => {
            // dispatch(onFetchGetSystemNodesList());
            // dispatch(fetchGetAllSkillClusters());
            dispatch(onFetchGetSystemNodesExternalSourceSuccess());
            dispatch(fetchGetDomainList());
        },
        onSelectNode: (nodeId, nodeType) => dispatch(onSetCurrentSystemNode(nodeId, nodeType)),
        onGetNodesList: () => dispatch(onFetchGetSystemNodesList()),
        showCreateNodeModal: (nodeType) => dispatch(showCreateNodeModal(nodeType)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelManagement);
