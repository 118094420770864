import { connect } from "react-redux";
import { getTranslation } from "../../../reducers/common/localization";
import { getDepartmentsListData } from "../../../reducers/organization/departmentsList";
import { getOrganizationsListState } from "../../../reducers/organization/organizations";
import { getAllBOTeams } from "../../../reducers/organization/teams";
import {
    getUserData,
    getUserDataError,
    getUserDataFormatted,
    updateUserData,
} from "../../../reducers/user/userData";
import { getUserCurrentSubRole } from "../../../reducers/user/userRole";
import EmployeeProfile from "./EmployeeProfile";

export const mapStateToProps = (state) => ({
    translation: getTranslation(state),
    userSubRole: getUserCurrentSubRole(state),
    userDataNotFormatted: getUserData(state),
    userData: getUserDataFormatted(state),
    userOrganization: getOrganizationsListState(state),
    userDepts: getDepartmentsListData(state),
    userTeams: getAllBOTeams(state),
    err: getUserDataError(state),
});

export const mapDispatchToProps = (dispatch) => ({
    onUpdateUser: (data) => dispatch(updateUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProfile);
