import { createAction, handleActions } from 'redux-actions';
import { Api } from "../../api/api";
import { onUpdateStaffListCurrentPage } from './staffList';
import { logout } from '../authorization/login';

//- Actions
export const fetchRegisterStaffMemberSuccess = createAction('FETCH_REGISTER_STAFF_SUCCESS');
export const fetchRegisterStaffMemberFailed = createAction('FETCH_REGISTER_STAFF_FAILED');

export const onCreateSuccess = () => dispatch => {
  dispatch(fetchRegisterStaffMemberSuccess());
  dispatch(onUpdateStaffListCurrentPage());
};

export const fetchRegisterStaffMember = createAction(
  'FETCH_REGISTER_STAFF',
  (data) => Api.registerStaffMember(data),
  () => ({
    apiCall: true,
    onSuccess: onCreateSuccess,
    onFail: fetchRegisterStaffMemberFailed,
  })
);

export const onFetchRegisterStaffMember = (data) => dispatch => {
    dispatch(fetchRegisterStaffMember(data));
};

export const fetchRemoveStaffMemberSuccess = createAction('FETCH_REMOVE_STAFF_SUCCESS');
export const fetchRemoveStaffMemberFailed = createAction('FETCH_REMOVE_STAFF_FAILED');

export const onRemoveStaffMemberSuccess = () => dispatch => {
    dispatch(fetchRemoveStaffMemberSuccess());
    dispatch(onUpdateStaffListCurrentPage());
};

export const fetchRemoveStaffMember = createAction(
    'FETCH_REMOVE_STAFF_MEMBER',
    (data) => Api.removeStaffMember(data),
    () => ({
        apiCall: true,
        onSuccess: onRemoveStaffMemberSuccess,
        onFail: fetchRemoveStaffMemberFailed,
    })
);

export const onFetchRemoveStaffMember = (data) => dispatch => {
    dispatch(fetchRemoveStaffMember(data));
};

export const fetchResendStaffMemberActivationSuccess = createAction('FETCH_RESEND_STAFF_MEMBER_ACTIVATION_SUCCESS');
export const fetchResendStaffMemberActivationFailed = createAction('FETCH_RESEND_STAFF_MEMBER_ACTIVATION_FAILED');

export const onResendStaffMemberActivationSuccess = () => dispatch => {
  dispatch(fetchResendStaffMemberActivationSuccess());
};

export const fetchResendStaffMemberActivation = createAction(
    'FETCH_RESEND_STAFF_MEMBER_ACTIVATION',
    (data) => Api.resendActivation(data),
    () => ({
        apiCall: true,
        onSuccess: onResendStaffMemberActivationSuccess,
        onFail: fetchResendStaffMemberActivationFailed,
    })
);

export const onFetchResendStaffMemberActivation = (data) => dispatch => {
  dispatch(fetchResendStaffMemberActivation(data));
};

export const changeAnchorFailed = createAction('CHANGE_ANCHOR_FAILED');
export const changeAnchorSuccess = createAction('CHANGE_ANCHOR_SUCCESS');

export const onChangeAnchorSuccess = () => (dispatch) => {
  dispatch(changeAnchorSuccess());
  dispatch(logout());
};

export const changeAnchor = createAction(
  'CHANGE_ANCHOR',
  (userId) => Api.changeAnchor(userId),
  () => ({
    apiCall: true,
    onSuccess: onChangeAnchorSuccess,
    onFail: changeAnchorFailed,
  })
);



//- State
const initialState = {
  fetchingStaffMembers: false,
  fetchingRemoveStaffMembers: false,
  changeAnchorLoading: false,
  error: null,
};

//- Reducers
export default handleActions({
  FETCH_REGISTER_STAFF: (state) => ({ ...state, error: null, fetchingStaffMembers: true }),
  FETCH_REGISTER_STAFF_FAILED: (state, action) => ({ ...state, error: action.payload, fetchingStaffMembers: false }),
  FETCH_REGISTER_STAFF_SUCCESS: (state) => ({ ...state, error: null, fetchingStaffMembers: false }),
  FETCH_REMOVE_STAFF_MEMBER: (state) => ({ ...state, error: null, fetchingRemoveStaffMembers: true }),
  FETCH_REMOVE_STAFF_FAILED: (state, action) => ({ ...state, error: action.payload, fetchingRemoveStaffMembers: false }),
  FETCH_REMOVE_STAFF_SUCCESS: (state) => ({ ...state, error: null, fetchingRemoveStaffMembers: false }),
}, initialState);
