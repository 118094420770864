import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import _ from 'lodash';

//- Actions
export const fetchGetStaffListFailed = createAction('FETCH_GET_STAFF_LIST_FAILED');
export const fetchGetStaffListSuccess = createAction('FETCH_GET_STAFF_LIST_SUCCESS');
export const onGetStaffListSuccess = (data) => (dispatch) => {
    let isEmpty = data.empty;
    let currentPage = data.number;
    if ((!isEmpty && currentPage !== 0) || currentPage === 0) {
        dispatch(fetchGetStaffListSuccess(data));
    } else {
        dispatch(fetchGetStaffList(currentPage - 1));
    }
};

export const fetchGetStaffList = createAction(
    'FETCH_GET_STAFF_LIST',
    (data) => Api.getStaffList(data),
    () => ({
        apiCall: true,
        onSuccess: onGetStaffListSuccess,
        onFail: fetchGetStaffListFailed,
    })
);

export const getInitialStaffList = () => (dispatch) => {
    dispatch(fetchGetStaffList());
};

export const onUpdateStaffListCurrentPage = () => (dispatch, getState) => {
    const currentPage = getStaffListCurrentPage(getState());
    dispatch(fetchGetStaffList({ page: currentPage }));
};

export const onClickStaffListPaginationItem = (page) => (dispatch) => {
    dispatch(fetchGetStaffList({ page }));
};

export const fetchGetEmployeesFailed = createAction('FETCH_GET_EMPLOYEES_FAILED');
export const fetchGetEmployeesSuccess = createAction('FETCH_GET_EMPLOYEES_SUCCESS');

export const onGetEmployeesSuccess = (data) => (dispatch) => {
    const employees = _.filter(data.content, employee => employee.active);
    const employeeOptions = _.map(employees, (employee) => {
        return {
            value: employee.id,
            label: `${employee.firstName} ${employee.lastName} (${employee.email})`,
            data: employee
        }
    });
    dispatch(fetchGetEmployeesSuccess(employeeOptions));
};

export const fetchGetEmployees = createAction(
  'FETCH_GET_EMPLOYEES',
  (data) => Api.getStaffList(data),
  () => ({
      apiCall: true,
      onSuccess: onGetEmployeesSuccess,
      onFail: fetchGetEmployeesFailed,
  })
);

export const getAllEmployees = () => (dispatch) => {
    dispatch(fetchGetEmployees({ showAllEmployees: true }));
};

//- State
const initialState = {
    data: [],
    employees: [],
    fetching: false,
    fetchingEmployees: false,
    error: null,
    currentPage: null,
    totalPages: null,
};

//- Reducers
export default handleActions({
    FETCH_GET_STAFF_LIST: (state) => {
        return { ...state, error: null, fetching: true };
    },
    FETCH_GET_STAFF_LIST_FAILED: (state, action) => {
        return { ...state, error: action.payload, fetching: false };
    },
    FETCH_GET_STAFF_LIST_SUCCESS: (state, action) => {
        return { ...state, data: action.payload.content, currentPage: action.payload.number, totalPages: action.payload.totalPages, error: null, fetching: false };
    },
    FETCH_GET_EMPLOYEES: (state) => {
        return { ...state, error: null, fetchingEmployees: true };
    },
    FETCH_GET_EMPLOYEES_FAILED: (state, action) => {
        return { ...state, error: action.payload, fetchingEmployees: false };
    },
    FETCH_GET_EMPLOYEES_SUCCESS: (state, action) => {
        return { ...state, employees: action.payload, error: null, fetchingEmployees: false };
    },
}, initialState);

//- Selectors
export const getStaffListState = state => state.staffList;

export const getStaffListData = state => getStaffListState(state).data;

export const getEmployeesData = state => getStaffListState(state).employees;

export const getStaffListFetchingStatus = state => getStaffListState(state).fetching;

export const getStaffListCurrentPage = state => getStaffListState(state).currentPage;

export const getStaffListTotalPages = state => getStaffListState(state).totalPages;
