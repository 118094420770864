import React, { Component } from "react";
import PropTypes from "prop-types";
import "./SystemNodeDetails.scss";
import _ from "lodash";
import {
    Button,
    Form,
    FormGroup,
    Col,
    Input,
    Label,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Badge,
    Container,
} from "reactstrap";
import { NODE_TYPE, NODE_DOMAIN_LABEL } from "../../../../constants/systemNodesConstants";
import SystemNodeStatus from "../systemNodeStatus/SystemNodeStatus.container";
import Icon from "../../../common/icon/Icon";
import RelatedSkills from "./relatedNodes/RelatedSkills.container";
import SystemNodeSource from "../systemNodeSource/SystemNodeSource.container";
import ChangeParentClusterModal from "./changeParentClusterModal/ChangeParentClusterModal.container";
import { LICENSE_TYPES } from "../../../../constants/skillsConstants";

// import ClosestSkills from './closestNodes/ClosestSkills.container';
// import ClosestClusters from './closestNodes/ClosestClusters.container';

export const SYSTEM_NODE_DETAILS_MODE = {
    CREATE: "CREATE",
    EDIT: "EDIT",
};

const CREATE_NODE_TITLE = {
    [NODE_TYPE.PROFILE]: "model_management_page.create_new_profile_label",
    [NODE_TYPE.CLUSTER]: "model_management_page.create_new_cluster_label",
    [NODE_TYPE.SKILL]: "model_management_page.create_new_skill_label",
};

class SystemNodeDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            domain: null,
            description: "",
            cluster: null,
            isDataLoaded: false,
            isDomainListOpen: false,
            isSkillListOpen: false,
            metadata: {
                license: "",
                versions: [],
                status: null,
            },
            synonyms: {},
            newSynonym: {},
            titles: {},
            newVersion: "",
            originalData: {},
        };
    }

    componentDidUpdate(prevProps) {
        if (!_.isNil(this.props.currentNodeData) && !_.isEqual(this.props.currentNodeData, prevProps.currentNodeData)) {


            const data = {
                title: this.props.currentNodeData.title,
                domain: this.props.currentNodeData.domain || "",
                description: this.props.currentNodeData.description || "",
                cluster: this.props.currentNodeData.cluster,
                isDataLoaded: true,
                titles: this.props.currentNodeData.titles || {},
                synonyms: this.props.currentNodeData.synonyms || {},
                metadata: {
                    status: this.props.currentNodeData.resourceStatus || "",
                    license: this.props.currentNodeData.license || "",
                    versions: this.props.currentNodeData.versions || [],
                },
            };

            this.props.languageList.forEach(({ value }) => data.titles[value] = this.props.currentNodeData.titles[value] || "");


            this.setState({ ...data, originalData: _.cloneDeep(data) });
        }

        if (this.props.currentNodeData &&
            this.props.currentNodeData.category &&
            !_.isEqual(this.props.currentNodeData.cluster, prevProps.currentNodeData.cluster)) {
            this.setState({
                cluster: this.props.currentNodeData.cluster,
            });
        }
    }

    componentWillUnmount() {
        if (this.props.currentNodeData && this.props.currentNodeData.type === NODE_TYPE.SKILL) {
            this.props.onResetParentClusterData();
        }
        this.props.onResetNodesToAddData();
        if (this.props.mode === SYSTEM_NODE_DETAILS_MODE.EDIT) {
            this.props.onResetNodesToRemoveData();
        }
    }


    renderContent() {
        let nodeType = this.props.currentNodeData.type;

        return (
            <>
                <Form>
                    <FormGroup row>
                        <Label sm={4}>
                            {_.get(this.props.translation, "system_nodes.node_name_label") + "*"}
                        </Label>
                        <Col sm={8}>
                            <Input className={
                                this.isFieldChanged("title") ? "input-field-changed" : ""
                            }
                                   required
                                   value={this.state.title}
                                   placeholder={_.get(
                                       this.props.translation,
                                       "system_nodes.node_name_label",
                                   )}
                                   onChange={this.handleChangeTitle} />
                        </Col>
                    </FormGroup>
                    {this.props.currentNodeData.type !== NODE_TYPE.SKILL &&
                    this.props.currentNodeData.type !== NODE_TYPE.DOMAIN && (
                        <FormGroup row>
                            <Label sm={4}>
                                {_.get(this.props.translation, "system_nodes.domain_label") +
                                "*"}
                            </Label>
                            <div className="w-50 d-flex flex-row">
                                <div className="align-items-center">
                                    {this.props.currentNodeData.domains &&
                                    this.props.currentNodeData.domains.map((domain, i) => {
                                        return (
                                            <Badge key={i}
                                                   className="node-domain-badge ml-3 align-self-center d-inline-flex flex-row mb-3"
                                                   color="light">
                                                        <span className="align-self-center">
                                                            {_.get(
                                                                this.props.translation,
                                                                NODE_DOMAIN_LABEL[domain.titles.en],
                                                            )}
                                                        </span>
                                                <Icon type="cancel"
                                                      color="danger"
                                                      className="ml-2"
                                                      onClick={(e) => {
                                                          this.props.onHandleNodeDomainList(
                                                              this.props.currentNodeData.id,
                                                              domain.titles.en,
                                                              true,
                                                              this.props.currentNodeData.type,
                                                          );
                                                      }}
                                                />
                                            </Badge>
                                        );
                                    })}
                                </div>
                                {this.props.currentNodeData.type !== NODE_TYPE.SKILL &&
                                this.props.currentNodeData.type !== NODE_TYPE.DOMAIN && (
                                    <div className="ml-3">{this.renderDomainSelect()}</div>
                                )}
                                {this.props.currentNodeData?.type === NODE_TYPE.DOMAIN && <div className="ml-3"></div>}
                            </div>
                        </FormGroup>
                    )}
                    <FormGroup row className="system-node-source-row">
                        <Label sm={4}>
                            {_.get(this.props.translation, "system_nodes.source_label") + "*"}
                        </Label>
                        <Col sm={8}>
                            <SystemNodeSource source={this.props.currentNodeData.source} />
                        </Col>
                    </FormGroup>
                    {nodeType !== NODE_TYPE.DOMAIN ? (
                        <FormGroup row>
                            <Label sm={4}>
                                {_.get(
                                    this.props.translation,
                                    "system_nodes.node_description_label",
                                )}
                            </Label>
                            <Col sm={8}>
                                <Input className={"description-textarea" +
                                (this.isFieldChanged("description")
                                    ? " input-field-changed"
                                    : "")}
                                       required
                                       type="textarea"
                                       value={this.state.description}
                                       placeholder={_.get(
                                           this.props.translation,
                                           "system_nodes.node_description_label",
                                       )}
                                       onChange={this.handleChangeDescription}
                                />
                            </Col>
                        </FormGroup>
                    ) : null}
                </Form>
                {nodeType === NODE_TYPE.SKILL ? (
                    <FormGroup row>
                        <Label sm={4}>
                            {_.get(this.props.translation, "system_nodes.skill_cluster_label") +
                            "*"}
                        </Label>
                        <Col sm={8}
                             className="col-input-with-right-icon d-flex flex-row justify-content-between">
                            <div className="">
                                {this.props.currentNodeData.clusters?.map((cluster, i) => (
                                    <InputGroup key={i}
                                                className={
                                                    this.isClusterChanged()
                                                        ? "input-group-field-changed"
                                                        : null}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText className="input-group-text-item-with-icon">
                                                <SystemNodeStatus status={cluster.resourceStatus}
                                                                  type={cluster.resourceType}
                                                                  size="sm" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input required
                                               disabled
                                               value={cluster.titles.en || ""}
                                               onChange={() => {
                                               }} />
                                        <Icon className="align-self-center"
                                              type="cancel"
                                              onClick={() =>
                                                  this.props.onRemoveClusterFromSKill(
                                                      this.props.currentNodeData.id,
                                                      cluster.id,
                                                  )
                                              } />
                                    </InputGroup>
                                ))}
                            </div>
                            <Icon type="add"
                                  onClick={this.onChangeParentClusterClick} />
                        </Col>
                    </FormGroup>
                ) : null}
                <div>
                    <FormGroup row>
                        <Col>
                            <h5 className="page-subsection-header">
                                {_.get(this.props.translation, "system_nodes.node_translations_label")}
                            </h5>
                        </Col>
                    </FormGroup>
                    <Container>

                        {this.props.languageList.map(({ title, value, id }) => (
                            <div className="mb-3" key={id}>
                                <div>{title}</div>
                                <Input
                                    value={this.state.titles[value]}
                                    onChange={(e) =>
                                        this.setState({
                                            titles: {
                                                ...this.state.titles,
                                                [value]: e.target.value,
                                            },
                                        })
                                    }
                                />
                            </div>
                        ))}
                    </Container>
                </div>
                {(nodeType === NODE_TYPE.SKILL || nodeType === NODE_TYPE.CLUSTER) && (
                    <>
                        <FormGroup row>
                            <Col>
                                <h5 className="page-subsection-header">
                                    {_.get(
                                        this.props.translation,
                                        "system_nodes.node_synonyms_label",
                                    )}
                                </h5>
                            </Col>
                            <Col sm="auto">
                                <Button color="primary"
                                        size="sm"
                                        onClick={this.handleAddSynonymsGroup}>
                                    {_.get(this.props.translation, "actions.add_label")}
                                </Button>
                            </Col>
                        </FormGroup>
                        {this.props.languageList.map((lang, i) => {
                            return (
                                <div key={i}
                                     className="synonyms-items-container d-flex flex-column align-items-start">
                                    <b className="synonyms-items-lang-label">{lang.label} </b>
                                    <div>
                                        {this.state.synonyms && this.state.synonyms[lang.value]?.map((synonym, i) => (
                                            <div key={i} className="d-flex flex-row align-items-center">
                                                <span className="synonyms-item">{synonym}</span>
                                                <Icon type="cancel"
                                                      onClick={() =>
                                                          this.setState({
                                                              synonyms: {
                                                                  ...this.state.synonyms,
                                                                  [lang.value]:
                                                                      this.state.synonyms[
                                                                          lang.value
                                                                          ].filter(
                                                                          (stateSynonym) =>
                                                                              stateSynonym !==
                                                                              synonym,
                                                                      ),
                                                              },
                                                          })
                                                      } />
                                            </div>
                                        ))}
                                        <input className="synonyms-item-content-editable"
                                               onChange={(e) =>
                                                   this.setState({
                                                       newSynonym: {
                                                           ...this.state.newSynonym,
                                                           [lang.value]: [e.target.value],
                                                       },
                                                   })
                                               }
                                               value={this.state.newSynonym[lang.value]} />
                                        <button className="synonyms-item-add-icon"
                                                onClick={() => {
                                                    let newSynonymValue = [];

                                                    if (!!this.state.synonyms[lang.value]) {
                                                        newSynonymValue = [
                                                            ...this.state.synonyms[lang.value],
                                                        ];
                                                    }

                                                    newSynonymValue = [
                                                        ...newSynonymValue,
                                                        ...this.state.newSynonym[lang.value],
                                                    ];

                                                    this.setState({
                                                        synonyms: {
                                                            ...this.state.synonyms,
                                                            [lang.value]: newSynonymValue,
                                                        },
                                                    });
                                                }}>
                                            {_.get(
                                                this.props.translation,
                                                "system_nodes.add_synonym",
                                            )}
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                    </>
                )}
                {/* {nodeType === NODE_TYPE.PROFILE || nodeType === NODE_TYPE.CLUSTER ? (
                    <RelatedProfiles
                        addRelationsOnly={this.props.mode === SYSTEM_NODE_DETAILS_MODE.CREATE}
                    />
                ) : null} */}
                {nodeType === NODE_TYPE.SKILL &&
                <RelatedSkills addRelationsOnly={this.props.mode === SYSTEM_NODE_DETAILS_MODE.CREATE} />}

                <div className="mt-3">
                    <h5 className="page-subsection-header">
                        {_.get(this.props.translation, "system_nodes.metadata_label")}
                    </h5>
                    {this.renderMetadataContent()}
                </div>

                {/* TODO: refactor */}
                {/* {
                    nodeType === NODE_TYPE.CLUSTER ?
                        // TODO:
                        // nodeSource === NODE_SOURCE.HF1 ?
                        //     <div>Closest User Clusters (Cluster)</div>
                        //     :
                        //     <div>Closest User and HF1 Clusters (Cluster)</div>
                        <ClosestClusters />
                        : null
                }
                {
                    nodeType === NODE_TYPE.SKILL ?
                        // TODO:
                        // nodeSource === NODE_SOURCE.HF1 ?
                        //     <div>Closest User Skills (Skill)</div>
                        //     :
                        //     <div>Closest User and HF1 Skills (Skill)</div>
                        <ClosestSkills />
                        : null
                } */}
            </>
        );
    }

    renderDomainSelect() {
        let options = [
            ..._.map(this.props.domainList, (domain) => {
                return {
                    value: domain.titles.en,
                    label: domain.titles.en,
                    description: domain.titles.en,
                };
            }),
        ];

        if (!!this.props.currentNodeData.domains) {
            options = options.reduce((newOptions, domain) => {
                if (
                    this.props.currentNodeData.domains &&
                    !this.props.currentNodeData.domains.some((el) => el.titles.en === domain.value)
                )
                    newOptions.push(domain);
                return newOptions;
            }, []);
        }

        return (
            <Dropdown isOpen={this.state.isDomainListOpen}
                      toggle={() => this.setState({ isDomainListOpen: !this.state.isDomainListOpen })}>
                <DropdownToggle className="model-management__add-btn">
                    <Icon type="plus_outline" />
                </DropdownToggle>
                <DropdownMenu>
                    {options.map((option) => {
                        return (
                            <DropdownItem value={option.value}
                                          key={option.value}
                                          onClick={(e) => {
                                              this.props.onHandleNodeDomainList(
                                                  this.props.currentNodeData.id,
                                                  e.target.value,
                                                  false,
                                                  this.props.currentNodeData.type,
                                              );
                                          }}>
                                {option.label}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        );
    }

    renderMetadataContent() {
        let nodeType = this.props.currentNodeData.type;

        return (
            <div className="container">
                {nodeType === NODE_TYPE.SKILL && <>
                    <div className="mt-3">
                        <span>{_.get(this.props.translation, "system_nodes.license_label")}</span>
                        <Input type="select"
                               value={this.state.metadata.license}
                               onChange={(e) =>
                                   this.setState({
                                       metadata: {
                                           ...this.state.metadata,
                                           license: e.target.value,
                                       },
                                   })
                               }>
                            {LICENSE_TYPES.map((x, i) =>
                                <option key={i} value={x.value}>
                                    {x.text}
                                </option>,
                            )}
                        </Input>
                    </div>

                    <div className="mt-3">
                        <span>{_.get(this.props.translation, "system_nodes.versions_label")}</span>
                        {this.state.metadata.versions?.map((version, i) => (
                            <Badge key={i}
                                   className="node-domain-badge ml-3 align-self-center d-inline-flex flex-row mb-3"
                                   color="light">
                                <span className="align-self-center">{version}</span>
                                <Icon
                                    type="cancel"
                                    color="danger"
                                    className="ml-2"
                                    onClick={(e) => {
                                        this.setState({
                                            metadata: {
                                                ...this.state.metadata,
                                                versions: this.state.metadata.versions.filter(
                                                    (stateVersion) => stateVersion !== version,
                                                ),
                                            },
                                        });
                                    }} />
                            </Badge>
                        ))}
                        <div className="d-flex flex-row">
                            <Input value={this.state.newVersion} onChange={(e) =>
                                this.setState({
                                    newVersion: e.target.value,
                                })
                            } />
                            <Button color="success"
                                    onClick={() =>
                                        this.setState({
                                            metadata: {
                                                ...this.state.metadata,
                                                versions: [
                                                    ...this.state.metadata.versions,
                                                    this.state.newVersion,
                                                ],
                                            },
                                        })
                                    }>
                                {_.get(this.props.translation, "actions.add_label")}
                            </Button>
                        </div>
                    </div>
                </>}
                <div className="mt-3 d-flex flex-column">
                    <span>{_.get(this.props.translation, "system_nodes.status_label")}</span>
                    <Input type="select"
                           onChange={(e) =>
                               this.setState({
                                   metadata: {
                                       ...this.state.metadata,
                                       status: e.target.value,
                                   },
                               })
                           }>
                        <option value="VALIDATED">
                            Validated
                        </option>
                        <option value="NOT_VALIDATED">
                            Not Validated
                        </option>
                        <option value="REJECTED">
                            Rejected
                        </option>
                        <option value="PENDING">
                            Pending
                        </option>
                    </Input>
                </div>
            </div>
        );
    }

    renderSkillRelationContent() {
        let options = this.props.skillList
            .map((skill) => ({
                value: skill.title,
                id: skill.id,
            }))
            .filter((option) => option.id !== this.props.currentNodeData.id);
        if (!!this.props.currentNodeData.relatedSkills) {
            options = options.reduce((newOptions, skill) => {
                if (
                    this.props.currentNodeData.relatedSkills &&
                    !this.props.currentNodeData.relatedSkills.some((el) => el.id === skill.id)
                )
                    newOptions.push(skill);
                return newOptions;
            }, []);
        }

        return (
            <Dropdown isOpen={this.state.isSkillListOpen}
                      toggle={() => this.setState({ isSkillListOpen: !this.state.isSkillListOpen })}>
                <DropdownToggle className="model-management__add-btn">
                    <Icon type="plus_outline" />
                </DropdownToggle>
                <DropdownMenu>
                    {options.map((option) => {
                        return (
                            <DropdownItem value={option.id}
                                          key={option.id}
                                          onClick={(e) => {
                                              this.props.onAddSkillToSKill(
                                                  this.props.currentNodeData.id,
                                                  option.id,
                                              );
                                          }}>
                                {option.value}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        );
    }

    handleChangeTitle = (e) => {
        this.setState({ title: e.target.value });
    };

    handleChangeDescription = (e) => {
        this.setState({ description: e.target.value });
    };

    handleChangeNodeDomain = (selectedOption) => {
        this.setState({ domain: selectedOption.value });
    };

    isFieldChanged = (fieldName) => {
        return (
            this.props.mode === SYSTEM_NODE_DETAILS_MODE.EDIT &&
            this.state.isDataLoaded &&
            !_.isEqual(this.props.currentNodeData[fieldName], this.state[fieldName])
        );
    };

    isClusterChanged = () => {
        return this.props.mode === SYSTEM_NODE_DETAILS_MODE.EDIT && !_.isNil(this.props.newCluster);
    };

    onBackButtonClick = () => {
        if (this.props.mode === SYSTEM_NODE_DETAILS_MODE.EDIT) {
            this.props.onSelectNode(null);
            this.props.onResetNodesToRemoveData();
        } else {
            this.props.onDiscardCreation();
        }
        if (this.props.currentNodeData.type === NODE_TYPE.SKILL) {
            this.props.onResetParentClusterData();
        }

        this.props.onResetNodesToAddData();
    };

    onChangeParentClusterClick = () => {
        this.props.onOpenChangeParentClusterModal();
    };

    handleDiscardClick = () => {
        if (this.props.mode === SYSTEM_NODE_DETAILS_MODE.EDIT) {
            const newSynonym = {};
            const metadata = {
                license: "",
                versions: [],
                status: null,
            };

            this.props.languageList.forEach(({ value }) => newSynonym[value] = "");

            this.setState({ ..._.cloneDeep(this.state.originalData), newSynonym, metadata, newVersion: "" });

            return;
        } else {
            this.props.onDiscardCreation();
        }

        if (this.props.currentNodeData.type === NODE_TYPE.SKILL) {
            this.props.onResetParentClusterData();
        }
        this.props.onResetNodesToAddData();
    };

    onSaveChangesNodeClick = () => {
        this.props.onSaveChanges(this.props.currentNodeData.id, {
            clusterIds: this.props.currentNodeData.clusters?.map((cluster) => cluster.id),
            resourceStatus: this.state.metadata.status,
            versions: this.state.metadata.versions,
            license: this.state.metadata.license,
            resourceOwner: this.props.currentNodeData.source,
            resourceType: this.props.currentNodeData.type,
            titles: this.state.titles,
            metadata: this.state.metadata,
            description: this.state.description,
            synonyms: this.state.synonyms,
        });
    };

    render() {
        if (this.props.loading) {
            return (
                <h6 className="text-center">
                    {_.get(this.props.translation, "actions.fetching_message")}
                </h6>
            );
        }

        if (this.props.saving) {
            return (
                <h6 className="text-center">
                    {_.get(this.props.translation, "Saving")}
                </h6>
            );
        }

        if (this.props.mode === SYSTEM_NODE_DETAILS_MODE.EDIT && !this.props.currentNodeData) {
            return null;
        }

        let nodeStatus = this.props.currentNodeData.status;
        let nodeType = this.props.currentNodeData.type;

        return (
            <>
                <div className="system-node-details">
                    <div className="nodes-management-header">
                        <div className="current-node-header">
                            <div className="current-node-name">
                                <SystemNodeStatus status={nodeStatus} type={nodeType} />
                                {this.props.mode === SYSTEM_NODE_DETAILS_MODE.EDIT ? (
                                    <h5 title={this.props.currentNodeData.title}>
                                        {this.props.currentNodeData.title}
                                    </h5>
                                ) : (
                                    <h5>
                                        {_.get(this.props.translation, CREATE_NODE_TITLE[nodeType])}
                                    </h5>
                                )}
                            </div>
                            <Button className="button-with-icon button-with-icon-right back-to-list-button"
                                    onClick={this.onBackButtonClick}
                                    size="sm">
                                {_.get(this.props.translation, "actions.back_button")}
                                <Icon type="arrow_right" color="light" size="xs" />
                            </Button>
                        </div>
                    </div>
                    <div className="current-node-details-content">
                        {this.renderContent()}
                    </div>
                    <div className="nodes-management-footer">
                        <div className="current-node-controls">
                            {/* TODO: move to node cemetery, move to node reference actions */}
                            {/*
                            {this.props.mode === SYSTEM_NODE_DETAILS_MODE.EDIT ? (
                                <Button color="danger"
                                        onClick={this.onDeleteNodeClick}>
                                    {_.get(
                                        this.props.translation,
                                        "system_nodes.delete_node_label",
                                    )}
                                </Button>
                            ) : null}
*/}
                            <Button color="warning"
                                    onClick={this.handleDiscardClick}>
                                {_.get(this.props.translation, "actions.discard_button")}
                            </Button>
                            <Button color="primary" onClick={this.onSaveChangesNodeClick}>
                                {_.get(this.props.translation, "actions.save_button")}
                            </Button>
                        </div>
                    </div>
                </div>
                {nodeType === NODE_TYPE.SKILL ? <ChangeParentClusterModal /> : null}
            </>
        );
    }

}

SystemNodeDetails.propTypes = {
    // TODO: props
    translation: PropTypes.object.isRequired,
    currentNodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currentNodeData: PropTypes.object,
    loading: PropTypes.bool,
    saving: PropTypes.bool,
    onSelectNode: PropTypes.func,
    onDiscardCreation: PropTypes.func,
    onResetNodesToAddData: PropTypes.func,
    onResetNodesToRemoveData: PropTypes.func,
};

export default SystemNodeDetails;
