import _ from "lodash";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Alert } from "reactstrap";
import { DROPZONE_TABS } from "../../employee/postJob/PostJob";
import Icon from "../icon/Icon";

const CompanyRichMedia = (props) => {
    const [officeMedia, setOfficeMedia] = useState([]);
    const [bossMedia, setBossMedia] = useState([]);
    const [activeTab, setActiveTab] = useState("");

    const handleDrop = (acceptedFiles) => {
        switch (activeTab) {
            case DROPZONE_TABS.OFFICE:
                setOfficeMedia([
                    ...officeMedia,
                    ...acceptedFiles.map((file) => ({
                        file,
                        name: file.name,
                        uri: URL.createObjectURL(file),
                        type: file.type,
                    })),
                ]);
                break;
            case DROPZONE_TABS.BOSS:
                setBossMedia([
                    ...bossMedia,
                    ...acceptedFiles.map((file) => ({
                        file,
                        name: file.name,
                        uri: URL.createObjectURL(file),
                        type: file.type,
                    })),
                ]);
                break;
            default:
                break;
        }
    };

    const reduceFiles = (fileUri) => {
        switch (activeTab) {
            case DROPZONE_TABS.OFFICE:
                setOfficeMedia(officeMedia.filter((media) => media.uri !== fileUri));
                break;
            case DROPZONE_TABS.BOSS:
                setBossMedia(bossMedia.filter((media) => media.uri !== fileUri));
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        setBossMedia([]);
        setOfficeMedia([]);

        if (props.companyFiles.workMediaUris)
            Promise.all(
                props.companyFiles.workMediaUris.map((url) =>
                    fetch(url)
                        .then((r) => r.blob())
                        .then((blob) => {
                            const file = new File([blob], "temp.jpg", { type: "image/jpg" });
                            return {
                                file,
                                name: file.name,
                                uri: URL.createObjectURL(file),
                                type: file.type,
                            };
                        })
                )
            ).then((files) => setOfficeMedia(files));

        if (props.companyFiles.bossMediaUris)
            Promise.all(
                props.companyFiles.bossMediaUris.map((url) =>
                    fetch(url)
                        .then((r) => r.blob())
                        .then((blob) => {
                            const file = new File([blob], "temp.jpg", { type: "image/jpg" });
                            return {
                                file,
                                name: file.name,
                                uri: URL.createObjectURL(file),
                                type: file.type,
                            };
                        })
                )
            ).then((files) => setBossMedia(files));
    }, [props.companyFiles]);

    useEffect(() => {
        props.onSetActiveTab(activeTab);
    }, [activeTab]);

    return (
        <div className="App">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={`${activeTab === DROPZONE_TABS.OFFICE ? "active" : ""}`}
                        onClick={() => {
                            setActiveTab(DROPZONE_TABS.OFFICE);
                        }}
                    >
                        {_.get(props.translation, "corporate_identity_page.office_media_uploader")}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === DROPZONE_TABS.BOSS ? "active" : ""}`}
                        onClick={() => {
                            setActiveTab(DROPZONE_TABS.BOSS);
                        }}
                    >
                        {_.get(props.translation, "corporate_identity_page.boss_media_uploader")}
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId={DROPZONE_TABS.OFFICE}>
                    <Dropzone
                        onDrop={handleDrop}
                        accept="image/*, video/*"
                        minSize={1024}
                        maxSize={3072000}
                    >
                        {({
                            getRootProps,
                            getInputProps,
                            isDragActive,
                            isDragAccept,
                            isDragReject,
                        }) => {
                            const additionalClass = isDragAccept
                                ? "accept"
                                : isDragReject
                                ? "reject"
                                : "";

                            return (
                                <div
                                    {...getRootProps({
                                        className: `dropzone ${additionalClass}`,
                                    })}
                                >
                                    <input {...getInputProps()} />
                                    <span>{isDragActive ? "📂" : "📁"}</span>
                                    <p>
                                        {_.get(
                                            props.translation,
                                            "company_rich_media.drag_and_drop"
                                        )}
                                    </p>
                                </div>
                            );
                        }}
                    </Dropzone>
                    <div>
                        <strong>
                            {_.get(props.translation, "company_rich_media.files_label")}
                        </strong>
                        <ul className="w-50">
                            {officeMedia instanceof Array && !!officeMedia.length ? (
                                officeMedia.map((fileName, index) => (
                                    <li key={index} className="d-flex flex-row">
                                        {fileName.name.match(/\.(jpg|gif|png)$/) != null ? (
                                            <img className="w-50" src={fileName.uri} alt="" />
                                        ) : (
                                            <video className="w-50" src={fileName.uri} alt="" />
                                        )}
                                        <Icon
                                            type="cancel"
                                            color="red-path"
                                            onClick={() => {
                                                reduceFiles(fileName.uri);
                                            }}
                                        />
                                    </li>
                                ))
                            ) : (
                                <li>{officeMedia}</li>
                            )}
                        </ul>
                    </div>
                </TabPane>
                <TabPane tabId={DROPZONE_TABS.BOSS}>
                    <Dropzone
                        onDrop={handleDrop}
                        accept="image/*, video/*"
                        minSize={1024}
                        maxSize={3072000}
                    >
                        {({
                            getRootProps,
                            getInputProps,
                            isDragActive,
                            isDragAccept,
                            isDragReject,
                        }) => {
                            const additionalClass = isDragAccept
                                ? "accept"
                                : isDragReject
                                ? "reject"
                                : "";

                            return (
                                <div
                                    {...getRootProps({
                                        className: `dropzone ${additionalClass}`,
                                    })}
                                >
                                    <input {...getInputProps()} />
                                    <span>{isDragActive ? "📂" : "📁"}</span>
                                    <p>
                                        {_.get(
                                            props.translation,
                                            "company_rich_media.drag_and_drop"
                                        )}
                                    </p>
                                </div>
                            );
                        }}
                    </Dropzone>
                    <div>
                        <strong>
                            {_.get(props.translation, "company_rich_media.files_label")}
                        </strong>
                        <ul className="w-50">
                            {bossMedia instanceof Array && !!bossMedia.length ? (
                                bossMedia.map((fileName, index) => (
                                    <li key={index} className="d-flex flex-row">
                                        {fileName.name.match(/\.(jpg|gif|png)$/) != null ? (
                                            <img className="w-50" src={fileName.uri} alt="" />
                                        ) : (
                                            <video className="w-50" src={fileName.uri} alt="" />
                                        )}
                                        <Icon
                                            type="cancel"
                                            color="red-path"
                                            onClick={() => {
                                                reduceFiles(fileName.uri);
                                            }}
                                        />
                                    </li>
                                ))
                            ) : (
                                <li>{bossMedia}</li>
                            )}
                        </ul>
                    </div>
                </TabPane>
            </TabContent>
            <div className="d-flex flex-row w-50 align-items-center mt-3">
                <Button
                    className="mx-3"
                    color="success"
                    disabled={!bossMedia.length && !officeMedia.length}
                    onClick={() => {
                        props.addMedia({
                            officeMedia: officeMedia.map((media) => media.file),
                            bossMedia: bossMedia.map((media) => media.file),
                        });
                    }}
                >
                    {_.get(props.translation, "company_rich_media.update_label")}
                </Button>
                <Alert color="success" className="mx-3" isOpen={!!props.companyFiles.isUpdated}>
                    {_.get(props.translation, "company_rich_media.media_updated")}
                </Alert>
            </div>
        </div>
    );
};

export default CompanyRichMedia;
