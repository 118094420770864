import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
    XAxis,
    CartesianGrid,
    YAxis,
    Tooltip as ChartTooltip,
    AreaChart,
    Area,
    ResponsiveContainer,
} from "recharts";
import { getMsFromTimeString, getYearInMs } from "../utils";

class LQGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customizedDotCoords: { cy: 0, cx: 0 },
        };
    }
    getValueFromSelectedDate(date, timelineDuration, timelineStarted) {
        return ((date - timelineStarted) / timelineDuration) * 100;
    }

    getLQGraphMarks() {
        const { lqGraphData, translation } = this.props;
        return Object.keys(lqGraphData).map((year) => ({
            [_.get(translation, "skills_journey_page.lq_score_label")]: lqGraphData[year],
            year,
        }));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedDateOnTimeline !== this.props.selectedDateOnTimeline) {
            const { selectedDateOnTimeline } = this.props;
            const lqGraphYears = Object.keys(this.props.lqGraphData);
            const duration =
                (lqGraphYears[lqGraphYears.length - 1] - lqGraphYears[0]) * getYearInMs();
            const percentOfLQWidth = this.getValueFromSelectedDate(
                selectedDateOnTimeline,
                duration,
                getMsFromTimeString(lqGraphYears[0])
            );
            this.props.setParentStateValue("dateInPercentOfLQWidth", percentOfLQWidth);
        }
    }

    render() {
        const { translation, dateInPercentOfLQWidth } = this.props;
        const percentOfLQWidth = `${Math.round(dateInPercentOfLQWidth)}%`;
        return (
            <div
                className="inner-block-container"
                style={{ position: "relative", marginTop: "10px" }}
            >
                <h5>{_.get(translation, "skills_journey_page.lq_graph_label")}</h5>
                <ResponsiveContainer width="90%" height="80%">
                    <AreaChart
                        data={this.getLQGraphMarks()}
                        margin={{ top: 5, right: 20, left: 20, bottom: 50 }}
                    >
                        <defs>
                            <linearGradient id="chartGradient" x1="0" y1="0" x2="1" y2="0">
                                <stop
                                    offset={percentOfLQWidth}
                                    stopColor="#3ee0d04d"
                                    stopOpacity={1}
                                />
                                <stop offset="0" stopColor="#cce5ff" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey={_.get(translation, "shared.year_label").toLowerCase()}
                            angle={45}
                            dy={20}
                            interval={0}
                        />
                        <YAxis />
                        <ChartTooltip />
                        <Area
                            type="monotone"
                            dataKey={_.get(translation, "skills_journey_page.lq_score_label")}
                            stroke="#40dfcf"
                            fillOpacity={1}
                            fill="url(#chartGradient)"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

LQGraph.propTypes = {
    selectedDateOnTimeline: PropTypes.number.isRequired,
    setParentStateValue: PropTypes.func.isRequired,
    translation: PropTypes.object.isRequired,
    dateInPercentOfLQWidth: PropTypes.number.isRequired,
};

export default LQGraph;
