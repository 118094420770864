import _ from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";
import {
    Container,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Button,
    Input,
    Alert,
} from "reactstrap";
import Icon from "../icon/Icon";
import DeleteAccountModal from "./DeleteAccountModal.container";
import DFAModal from "./DFAModal.container";
import "./PersonalSettings.scss";

class PersonalSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: "emailPassword",
            oldPassword: "",
            newPassword: "",
            confirmedPassword: "",
            error: "",
            email: "",
        };
    }

    generateStatus() {
        if (this.props.is2FAEnable) {
            return (
                <>
                    <Icon type="checkbox" />
                    <h5 className="ml-3">
                        {_.get(this.props.translation, "personal_settings_page.2fa_enabled_label")}
                    </h5>
                </>
            );
        }
        return (
            <>
                <Icon type="cancel" color="red-path" />
                <h5 className="ml-3">
                    {_.get(this.props.translation, "personal_settings_page.2fa_disabled_label")}
                </h5>
            </>
        );
    }

    componentDidMount() {
        this.props.onMount();
    }

    componentWillUnmount() {
        this.props.onUnmount();
    }

    render() {
        return (
            <Container className="d-flex flex-column mt-3">
                <div className="align-self-center">
                    <h3>
                        {_.get(
                            this.props.translation,
                            `personal_settings_page.${this.state.activeTab}_section_label`
                        )}
                    </h3>
                </div>
                <div className="d-flex flex-row">
                    <Nav tabs className="d-flex flex-column border-0">
                        <b className="personal-settings__title">
                            {_.get(this.props.translation, "personal_settings_page.page_title")}
                        </b>

                        {/* <NavItem>
                            <NavLink
                                className={`personal-settings__tab ${
                                    this.state.activeTab === "personalData"
                                        ? "personal-settings__tab--active"
                                        : ""
                                }`}
                                onClick={() => {
                                    this.props.history.push("/applicant-account");
                                }}
                            >
                                {_.get(
                                    this.props.translation,
                                    "personal_settings_page.personal_data_label"
                                )}
                            </NavLink>
                        </NavItem> */}
                        <NavItem>
                            <NavLink
                                className={`personal-settings__tab ${
                                    this.state.activeTab === "emailPassword"
                                        ? "personal-settings__tab--active"
                                        : ""
                                }`}
                                onClick={() => {
                                    this.setState({
                                        activeTab: "emailPassword",
                                    });
                                }}
                            >
                                {_.get(
                                    this.props.translation,
                                    "personal_settings_page.email_and_password_label"
                                )}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={`personal-settings__tab ${
                                    this.state.activeTab === "notifications"
                                        ? "personal-settings__tab--active"
                                        : ""
                                }`}
                                onClick={() => {
                                    this.setState({
                                        activeTab: "notifications",
                                    });
                                }}
                            >
                                {_.get(
                                    this.props.translation,
                                    "personal_settings_page.notifications_label"
                                )}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={`personal-settings__tab ${
                                    this.state.activeTab === "security"
                                        ? "personal-settings__tab--active"
                                        : ""
                                }`}
                                onClick={() => {
                                    this.setState({
                                        activeTab: "security",
                                    });
                                }}
                            >
                                {_.get(
                                    this.props.translation,
                                    "personal_settings_page.security_label"
                                )}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={`personal-settings__tab ${
                                    this.state.activeTab === "deleteProfile"
                                        ? "personal-settings__tab--active"
                                        : ""
                                }`}
                                onClick={() => {
                                    this.setState({
                                        activeTab: "deleteProfile",
                                    });
                                }}
                            >
                                {_.get(
                                    this.props.translation,
                                    "personal_settings_page.delete_profile_title"
                                )}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} className="w-75 p-5 container">
                        <TabPane tabId="emailPassword" className="w-75">
                            <div className="d-flex flex-column mb-3">
                                <h3 className="align-self-center">
                                    {_.get(
                                        this.props.translation,
                                        "personal_settings_page.change_email_label"
                                    )}
                                </h3>
                            </div>
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-row mb-3">
                                    <b className="mr-5 align-self-center w-50">
                                        {_.get(
                                            this.props.translation,
                                            "personal_settings_page.email_label"
                                        )}
                                    </b>
                                    <Input
                                        placeholder="New email"
                                        onInput={(event) => {
                                            this.setState({ email: event.target.value });
                                        }}
                                    />
                                    {!!this.props.error && (
                                        <Alert color="danger"> {this.props.error} </Alert>
                                    )}
                                </div>
                                <Button
                                    color="primary"
                                    className="mt-3 align-self-end"
                                    onClick={() => {
                                        this.setState({ error: "" });
                                        this.props.onChangeEmail({ email: this.state.email });
                                    }}
                                >
                                    {_.get(
                                        this.props.translation,
                                        "personal_settings_page.update_email_label"
                                    )}
                                </Button>
                            </div>
                            <div className="d-flex flex-column">
                                <h3 className="align-self-center mb-3">
                                    {_.get(
                                        this.props.translation,
                                        "personal_settings_page.change_password_label"
                                    )}
                                </h3>
                            </div>
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-row mb-3">
                                    <b className="mr-5 align-self-center w-50">
                                        {_.get(
                                            this.props.translation,
                                            "personal_settings_page.old_password_label"
                                        )}
                                    </b>
                                    <Input
                                        placeholder={_.get(
                                            this.props.translation,
                                            "personal_settings_page.old_password_label"
                                        )}
                                        onInput={(event) => {
                                            this.setState({ oldPassword: event.target.value });
                                        }}
                                    />
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <b className="mr-5 align-self-center w-50">
                                        {_.get(
                                            this.props.translation,
                                            "personal_settings_page.new_password_label"
                                        )}
                                    </b>
                                    <Input
                                        placeholder={_.get(
                                            this.props.translation,
                                            "personal_settings_page.new_password_label"
                                        )}
                                        onInput={(event) => {
                                            this.setState({ newPassword: event.target.value });
                                        }}
                                    />
                                </div>
                                <div className="d-flex flex-row mb-3">
                                    <b className="mr-5 align-self-center w-50">
                                        {_.get(
                                            this.props.translation,
                                            "personal_settings_page.confirm_password_label"
                                        )}
                                    </b>
                                    <Input
                                        placeholder={_.get(
                                            this.props.translation,
                                            "personal_settings_page.confirm_password_label"
                                        )}
                                        onInput={(event) => {
                                            this.setState({
                                                confirmedPassword: event.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                {!!this.state.error.length && (
                                    <Alert color="danger"> {this.state.error} </Alert>
                                )}
                                <Button
                                    color="primary"
                                    className="mt-3 align-self-end"
                                    onClick={() => {
                                        if (this.state.oldPassword === this.state.newPassword) {
                                            this.setState({
                                                error: "Old and new passwords are same",
                                            });
                                        } else if (
                                            this.state.newPassword !== this.state.confirmedPassword
                                        ) {
                                            this.setState({
                                                error: "Confirmed password differs with new",
                                            });
                                        } else {
                                            this.setState({ error: "" });
                                            this.props.onChangePassword({
                                                oldPassword: this.state.oldPassword,
                                                newPassword: this.state.newPassword,
                                            });
                                        }
                                    }}
                                >
                                    {_.get(
                                        this.props.translation,
                                        "personal_settings_page.update_password_label"
                                    )}
                                </Button>
                            </div>
                        </TabPane>
                        <TabPane tabId="notifications">
                            <div className="d-flex flex-column">
                                <div>
                                    <div className="d-flex flex-column">
                                        <div className="d-flex flex-row mt-3">
                                            <span>
                                                {_.get(
                                                    this.props.translation,
                                                    "personal_settings_page.notifications_applied_job"
                                                )}
                                            </span>
                                            <Input type="checkbox" />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="d-flex flex-column">
                                        {/* <span>Additional label to be added</span> */}
                                        <div className="d-flex flex-row">
                                            <span>
                                                {_.get(
                                                    this.props.translation,
                                                    "personal_settings_page.notifications_current_job"
                                                )}
                                            </span>
                                            <Input type="checkbox" />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="d-flex flex-column">
                                        {/* <span>Additional label to be added</span> */}
                                        <div className="d-flex flex-row">
                                            <span>
                                                {_.get(
                                                    this.props.translation,
                                                    "personal_settings_page.notifications_job_compass_job"
                                                )}
                                            </span>
                                            <Input type="checkbox" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="security">
                            <div className="d-flex flex-column p-5">
                                <div className="d-flex flex-row">
                                    <b className="mr-3">
                                        {_.get(
                                            this.props.translation,
                                            "personal_settings_page.current_status_label"
                                        )}
                                    </b>
                                    {this.generateStatus()}
                                </div>
                                <Button
                                    onClick={() => this.props.openDFAModal()}
                                    color="primary"
                                    className="align-self-end mt-5"
                                >
                                    {_.get(
                                        this.props.translation,
                                        `personal_settings_page.btn_${
                                            this.props.is2FAEnable ? "disable" : "enable"
                                        }_2fa`
                                    )}
                                </Button>
                            </div>
                        </TabPane>
                        <TabPane tabId="deleteProfile">
                            <div className="d-flex flex-column p-3">
                                <span>
                                    {_.get(
                                        this.props.translation,
                                        "personal_settings_page.delete_profile_warning"
                                    )}
                                </span>
                                <Button
                                    color="success"
                                    onClick={() => this.props.openDeleteAccountModal()}
                                    className="align-self-end mt-3 btn btn-primary"
                                >
                                    {_.get(
                                        this.props.translation,
                                        "personal_settings_page.delete_profile_btn_label"
                                    )}
                                </Button>
                            </div>
                        </TabPane>
                    </TabContent>
                    <DFAModal />
                    <DeleteAccountModal />
                </div>
            </Container>
        );
    }
}

export default withRouter(PersonalSettings);
