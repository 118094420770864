import { createAction, handleActions } from 'redux-actions';
import { getOpenJobId, getOpenJobViewMode, getOpenJobSkillsData } from './openJob';
import { Api } from '../../api/api';
import { SELECTION_MODE, VIEW_MODE } from '../../constants/candidatesComparisonConstants';
import _ from 'lodash';
import { SkillsUtils } from '../../utils/skillsUtils';

//- Actions
export const onSelectCandidate = (userId) => (dispatch, getState) => {
    let selectionMode = getCandidatesSelectionMode(getState());

    switch (selectionMode) {
        case SELECTION_MODE.SINGLE:
            let currentCandidateId = getCurrentCandidateId(getState());
            if (userId !== currentCandidateId) {
                dispatch(onFetchGetCurrentCandidateData(userId));
                dispatch(updateCurrentCandidateId(userId));
            };
            break;

        case SELECTION_MODE.MULTIPLE:
            let selectedCandidatesIds = _.cloneDeep(getSelectedCandidatesIds(getState())) || [];
            if (_.includes(selectedCandidatesIds, userId)) {
                selectedCandidatesIds = selectedCandidatesIds.filter((elem) => elem !== userId);
                let selectedCandidatesData = _.cloneDeep(getSelectedCandidatesData(getState())) || [];
                selectedCandidatesData = selectedCandidatesData.filter((elem) => elem.user.id !== userId);
                dispatch(updateSelectedCandidatesData(selectedCandidatesData));
                dispatch(updateSelectedCandidatesIds(selectedCandidatesIds));
            } else {
                selectedCandidatesIds.push(userId);
                dispatch(onFetchGetSelectedCandidatesData(selectedCandidatesIds));
                dispatch(updateSelectedCandidatesIds(selectedCandidatesIds));
            }
            break;

        default:
            break;
    }
}

export const fetchGetCurrentCandidateDataFailed = createAction('FETCH_GET_CURRENT_CANDIDATE_DATA_FAILED');
export const fetchGetCurrentCandidateDataSuccess = createAction('FETCH_GET_CURRENT_CANDIDATE_DATA_SUCCESS');
export const onFetchGetCurrentCandidateDataSuccess = (dataArray) => (dispatch) => {
    let candidateData = dataArray.content[0];
    let formattedData = {
        userId: candidateData.userId,
        applicantMetaData: candidateData.applicantMetaData,
        user: candidateData.user,
        skills: _.map(candidateData.skills, (skill) => {
            // TODO: remove hardcoded values
            if (skill.skill.id === 476) {
                skill.version = {
                    ratio: 1,
                    value: "v.9"
                }
            }
            return {
                id: skill.skill.id,
                title: skill.skill.titles.en,
                categoryId: skill.skill.category.id,
                categoryTitle: skill.skill.category.titles.en,
                level: skill.level,
                eagerness: skill.eagerness,
                notes: skill.notes,
                version: skill.version,
                spokenLevel: skill.spokenLevel,
                understandingLevel: skill.understandingLevel
            }
        }),
        ffmData: [_.random(1, 5), _.random(1, 5), _.random(1, 5), _.random(1, 5), _.random(1, 5)]
    };

    dispatch(fetchGetCurrentCandidateDataSuccess(formattedData));
}
export const fetchGetCurrentCandidateData = createAction(
    'FETCH_GET_CURRENT_CANDIDATE_DATA',
    (jobId, userId) => Api.getCandidatesDataForJob(jobId, [userId]),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetCurrentCandidateDataSuccess,
        onFail: fetchGetCurrentCandidateDataFailed
    })
);
export const onFetchGetCurrentCandidateData = (userId) => (dispatch, getState) => {
    let jobId = getOpenJobId(getState());
    dispatch(fetchGetCurrentCandidateData(jobId, userId));
}
export const fetchRefreshCurrentCandidateData = createAction(
    'FETCH_REFRESH_CURRENT_CANDIDATE_DATA',
    (jobId, userId) => Api.getCandidatesDataForJob(jobId, [userId]),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetCurrentCandidateDataSuccess,
        onFail: fetchGetCurrentCandidateDataFailed
    })
);
export const refreshCurrentCandidateData = () => (dispatch, getState) => {
    let jobId = getOpenJobId(getState());
    let userId = getCurrentCandidateId(getState());
    dispatch(fetchRefreshCurrentCandidateData(jobId, userId));
}

export const updateCurrentCandidateId = createAction('UPDATE_CURRENT_CANDIDATE_ID');
export const updateCurrentCandidateData = createAction('UPDATE_CURRENT_CANDIDATE_DATA');

export const fetchGetSelectedCandidatesDataFailed = createAction('FETCH_GET_SELECTED_CANDIDATES_DATA_FAILED');
export const fetchGetSelectedCandidatesDataSuccess = createAction('FETCH_GET_SELECTED_CANDIDATES_DATA_SUCCESS');
export const onFetchGetSelectedCandidatesDataSuccess = (dataArray) => (dispatch) => {
    let formattedArray = _.map(dataArray.content, (data) => {
        return {
            userId: data.userId,
            applicantMetaData: data.applicantMetaData,
            user: data.user,
            skills: _.map(data.skills, (skill) => {
                // TODO: remove hardcoded values
                if (skill.skill.id === 476) {
                    skill.version = {
                        ratio: 1,
                        value: "v.9"
                    }
                }
                return {
                    id: skill.skill.id,
                    title: skill.skill.titles.en,
                    categoryId: skill.skill.category.id,
                    categoryTitle: skill.skill.category.titles.en,
                    level: skill.level,
                    eagerness: skill.eagerness,
                    notes: skill.notes,
                    version: skill.version,
                    spokenLevel: skill.spokenLevel,
                    understandingLevel: skill.understandingLevel
                }
            }),
            ffmData: [_.random(1, 5), _.random(1, 5), _.random(1, 5), _.random(1, 5), _.random(1, 5)]
        };
    })

    dispatch(fetchGetSelectedCandidatesDataSuccess(formattedArray));
}
export const fetchGetSelectedCandidatesData = createAction(
    'FETCH_GET_SELECTED_CANDIDATES_DATA',
    (jobId, userIds) => Api.getCandidatesDataForJob(jobId, userIds),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSelectedCandidatesDataSuccess,
        onFail: fetchGetSelectedCandidatesDataFailed
    })
);
export const onFetchGetSelectedCandidatesData = (userIds) => (dispatch, getState) => {
    let jobId = getOpenJobId(getState());
    dispatch(fetchGetSelectedCandidatesData(jobId, userIds));
}
export const fetchRefreshSelectedCandidatesData = createAction(
    'FETCH_REFRESH_SELECTED_CANDIDATES_DATA',
    (jobId, userIds) => Api.getCandidatesDataForJob(jobId, userIds),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSelectedCandidatesDataSuccess,
        onFail: fetchGetSelectedCandidatesDataFailed
    })
);
export const refreshSelectedCandidatesData = () => (dispatch, getState) => {
    let jobId = getOpenJobId(getState());
    let usersIds = getSelectedCandidatesIds(getState());
    dispatch(fetchRefreshSelectedCandidatesData(jobId, usersIds));
}

export const updateSelectedCandidatesIds = createAction('UPDATE_SELECTED_CANDIDATES_IDS');
export const updateSelectedCandidatesData = createAction('UPDATE_SELECTED_CANDIDATES_DATA');

export const removeCandidateFromCurrentAndSelected = (userId) => dispatch => {
    dispatch(removeCandidateFromCurrent(userId));
    dispatch(removeCandidateFromSelected(userId));
}

export const removeCandidateFromCurrent = (userId) => (dispatch, getState) => {
    let currentCandidateId = getCurrentCandidateId(getState());
    if (userId === currentCandidateId) {
        dispatch(updateCurrentCandidateId(null));
        dispatch(updateCurrentCandidateData(null));
    };
}

export const removeCandidateFromSelected = (userId) => (dispatch, getState) => {
    let selectedCandidatesIds = _.cloneDeep(getSelectedCandidatesIds(getState())) || [];
    if (_.includes(selectedCandidatesIds, userId)) {
        selectedCandidatesIds = selectedCandidatesIds.filter((elem) => elem !== userId);
        let selectedCandidatesData = _.cloneDeep(getSelectedCandidatesData(getState())) || [];
        selectedCandidatesData = selectedCandidatesData.filter((elem) => elem.user.id !== userId);
        dispatch(updateSelectedCandidatesIds(selectedCandidatesIds));
        dispatch(updateSelectedCandidatesData(selectedCandidatesData));
    }
}

//- State
const initialState = {
    currentCandidateId: null,
    currentCandidateData: null,
    fetchingCurrentCandidate: false,
    selectedCandidatesIds: null,
    selectedCandidatesData: null,
    fetchingSelectedCandidates: false,
    error: null
};

//- Reducers
export default handleActions({

    FETCH_GET_CURRENT_CANDIDATE_DATA: (state) => {
        return { ...state, currentCandidateData: null, error: null, fetchingCurrentCandidate: true };
    },
    FETCH_GET_CURRENT_CANDIDATE_DATA_FAILED: (state, action) => {
        return { ...state, currentCandidateData: null, error: action.payload, fetchingCurrentCandidate: false };
    },
    FETCH_GET_CURRENT_CANDIDATE_DATA_SUCCESS: (state, action) => {
        return { ...state, currentCandidateData: action.payload, error: null, fetchingCurrentCandidate: false };
    },
    FETCH_GET_SELECTED_CANDIDATES_DATA: (state) => {
        return { ...state, selectedCandidatesData: null, error: null, fetchingSelectedCandidates: true };
    },
    FETCH_GET_SELECTED_CANDIDATES_DATA_FAILED: (state, action) => {
        return { ...state, selectedCandidatesData: null, error: action.payload, fetchingSelectedCandidates: false };
    },
    FETCH_GET_SELECTED_CANDIDATES_DATA_SUCCESS: (state, action) => {
        return { ...state, selectedCandidatesData: action.payload, error: null, fetchingSelectedCandidates: false };
    },
    UPDATE_CURRENT_CANDIDATE_ID: (state, action) => {
        return { ...state, currentCandidateId: action.payload };
    },
    UPDATE_CURRENT_CANDIDATE_DATA: (state, action) => {
        return { ...state, currentCandidateData: action.payload };
    },
    UPDATE_SELECTED_CANDIDATES_IDS: (state, action) => {
        return { ...state, selectedCandidatesIds: action.payload };
    },
    UPDATE_SELECTED_CANDIDATES_DATA: (state, action) => {
        return { ...state, selectedCandidatesData: action.payload };
    },

}, initialState);


//- Selectors
export const getSelectedCandidatesState = state => state.selectedCandidates;

export const getCandidatesSelectionMode = state => {
    let viewMode = getOpenJobViewMode(state);

    if (viewMode === VIEW_MODE.COMPARING_DASHBOARD) {
        return SELECTION_MODE.MULTIPLE;
    } else {
        return SELECTION_MODE.SINGLE;
    }
}

export const getCurrentCandidateFetchingStatus = state => getSelectedCandidatesState(state).fetchingCurrentCandidate;

export const getCurrentCandidateId = state => getSelectedCandidatesState(state).currentCandidateId;

export const getCurrentCandidateData = state => getSelectedCandidatesState(state).currentCandidateData;

export const getCurrentCandidateSkills = state => {
    let data = getCurrentCandidateData(state);
    if (data) {
        return data.skills;
    }
}

export const getSelectedCandidatesFetchingStatus = state => getSelectedCandidatesState(state).fetchingSelectedCandidates;

export const getSelectedCandidatesIds = state => getSelectedCandidatesState(state).selectedCandidatesIds;

export const getSelectedCandidatesData = state => getSelectedCandidatesState(state).selectedCandidatesData;

export const getCandidateSelectedStatus = (state, userId) => {
    let selectionMode = getCandidatesSelectionMode(state);

    switch (selectionMode) {
        case SELECTION_MODE.SINGLE:
            let currentCandidateId = getCurrentCandidateId(state);
            return userId === currentCandidateId;

        case SELECTION_MODE.MULTIPLE:
            let selectedCandidatesIds = getSelectedCandidatesIds(state);
            return _.includes(selectedCandidatesIds, userId);

        default:
            break;
    }
}

export const getCandidateAndJobComparedSkillsTree = state => {
    let jobSkills = getOpenJobSkillsData(state);
    let candidateSkills = getCurrentCandidateSkills(state);

    return SkillsUtils.createComparisonSkillsTree(jobSkills, candidateSkills);
}

export const getCurrentCandidateFFMData = state => {
    let data = getCurrentCandidateData(state);

    if (data) {
        return data.ffmData;
    }
}

export const getSelectedCandidatesAndJobComparedSkillsTree = state => {
    let jobSkills = getOpenJobSkillsData(state);
    let candidatesData = getSelectedCandidatesData(state);

    return _.map(candidatesData, (candidateData) => SkillsUtils.createComparisonSkillsTree(jobSkills, candidateData.skills));
}

export const getSelectedCandidateFFMData = state => {
    let data = getSelectedCandidatesData(state);

    if (data) {
        return _.map(data, (elem) => elem.ffmData);
    }
}
