import React, { memo, useEffect, useRef } from "react";
import { Handle } from "react-flow-renderer";
import bgChromeBubbleVideo from "../../../../../video/432.webm";
import bgSafariBubbleVideo from "../../../../../video/432.hevc.mp4";
import _ from "lodash";
import Icon from "../../../../common/icon/Icon";

const style = {
    zIndex: -9999,
    opacity: 0,
    top: "40%",
    left: "60%",
    pointerEvents: "none",
    isHidden: true,
};

export default memo(({ data }) => {
    const video = useRef(null);

    useEffect(() => {
        video.current.currentTime = Math.floor(_.random(1, 10, 1));
    }, []);

    const randomizeTime = () => {
        video.current.currentTime = Math.floor(_.random(1, 10, 1));
    };

    return (
        <div id={data.id}>
            <div className="home-page__video-container">
                <video
                    ref={video}
                    loop
                    muted
                    id="video"
                    autoPlay
                    playsInline
                    onLoadedData={navigator.userAgent.includes("Chrome") ? null : randomizeTime}
                >
                    <source src={bgChromeBubbleVideo} type="video/webm" />
                    <source src={bgSafariBubbleVideo} type="video/mp4" codecs="hvc1" />
                </video>
            </div>
            <div
                id={data.id}
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    opacity: 0,
                    zIndex: -3,
                    top: 0,
                    left: 0,
                }}
            ></div>
            <Handle type="target" position="top" style={style} />
            <div className="home-page__node-content-wrapper">
                <div className="home-page__icon-wrapper">
                    <Icon type={data.iconType} className="icon-centered" size="xxl" />
                </div>
                {data.title.split("/n").map((line, i) => (
                    <span className="home-page__node-link">{line}</span>
                ))}
            </div>
            <Handle type="source" position="top" style={style} />
        </div>
    );
});
