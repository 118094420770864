
import React, { Component } from 'react';

class Test extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="-53 1 511 511.99998">
                <path d="m373.027344 69.8125h-339.941406c-17.996094 0-32.585938 14.585938-32.585938 32.582031v377.019531c0 17.996094 14.589844 32.585938 32.585938 32.585938h339.941406c17.996094 0 32.582031-14.589844 32.582031-32.585938v-377.019531c0-17.996093-14.585937-32.582031-32.582031-32.582031zm0 0" fill="#49c3e0" />
                <path d="m63.136719 69.8125v37.652344c0 26.808594 21.808593 48.621094 48.617187 48.621094h182.601563c26.808593 0 48.621093-21.8125 48.621093-48.621094v-37.652344zm0 0" fill="#00a5c6" />
                <path d="m41.492188 104.683594h323.125v355.476562h-323.125zm0 0" fill="#efedee" />
                <path d="m100.742188 220.785156c-1.988282 0-3.996094-.429687-5.902344-1.304687-5.046875-2.320313-8.183594-7.214844-8.183594-12.773438v-13.09375c0-4.265625 3.457031-7.726562 7.726562-7.726562 4.269532 0 7.726563 3.460937 7.726563 7.726562v10.082031c10.160156-8.316406 26.246094-19.003906 48.292969-26.355468 4.046875-1.347656 8.421875.839844 9.773437 4.890625 1.347657 4.046875-.839843 8.421875-4.886719 9.773437-21.445312 7.144532-36.554687 17.746094-45.453124 25.378906-2.617188 2.242188-5.828126 3.402344-9.09375 3.402344zm0 0" fill="#3ac7b4" />
                <path d="m311.730469 206.585938h-101.113281c-4.265626 0-7.726563-3.457032-7.726563-7.726563 0-4.265625 3.460937-7.726563 7.726563-7.726563h101.113281c4.265625 0 7.726562 3.460938 7.726562 7.726563 0 4.269531-3.460937 7.726563-7.726562 7.726563zm0 0" fill="#3a484a" />
                <path d="m100.742188 284.226562c-1.988282 0-3.996094-.429687-5.902344-1.308593-5.046875-2.320313-8.183594-7.210938-8.183594-12.769531v-13.09375c0-4.269532 3.457031-7.726563 7.726562-7.726563 4.269532 0 7.726563 3.457031 7.726563 7.726563v10.082031c10.160156-8.316407 26.246094-19.003907 48.292969-26.355469 4.046875-1.351562 8.421875.839844 9.773437 4.886719 1.347657 4.046875-.839843 8.425781-4.886719 9.773437-21.445312 7.148438-36.554687 17.75-45.453124 25.378906-2.617188 2.246094-5.828126 3.40625-9.09375 3.40625zm0 0" fill="#3ac7b4" />
                <path d="m311.730469 270.027344h-101.113281c-4.265626 0-7.726563-3.460938-7.726563-7.726563s3.460937-7.726562 7.726563-7.726562h101.113281c4.265625 0 7.726562 3.460937 7.726562 7.726562s-3.460937 7.726563-7.726562 7.726563zm0 0" fill="#3a484a" />
                <path d="m100.742188 347.667969c-1.988282 0-3.996094-.429688-5.902344-1.308594-5.046875-2.320313-8.183594-7.214844-8.183594-12.769531v-13.097656c0-4.265626 3.457031-7.726563 7.726562-7.726563 4.269532 0 7.726563 3.460937 7.726563 7.726563v10.085937c10.160156-8.316406 26.246094-19.007813 48.292969-26.355469 4.046875-1.351562 8.421875.835938 9.773437 4.886719 1.347657 4.046875-.839843 8.421875-4.886719 9.773437-21.445312 7.148438-36.554687 17.746094-45.453124 25.378907-2.617188 2.246093-5.828126 3.40625-9.09375 3.40625zm0 0" fill="#3ac7b4" />
                <path d="m311.730469 333.46875h-101.113281c-4.265626 0-7.726563-3.460938-7.726563-7.726562 0-4.269532 3.460937-7.726563 7.726563-7.726563h101.113281c4.265625 0 7.726562 3.457031 7.726562 7.726563 0 4.265624-3.460937 7.726562-7.726562 7.726562zm0 0" fill="#3a484a" />
                <path d="m100.742188 411.105469c-1.988282 0-3.996094-.429688-5.902344-1.308594-5.046875-2.316406-8.183594-7.210937-8.183594-12.769531v-13.09375c0-4.265625 3.457031-7.726563 7.726562-7.726563 4.269532 0 7.726563 3.460938 7.726563 7.726563v10.082031c10.160156-8.316406 26.246094-19.003906 48.292969-26.355469 4.046875-1.347656 8.421875.839844 9.773437 4.886719 1.347657 4.050781-.839843 8.425781-4.886719 9.773437-21.445312 7.148438-36.554687 17.75-45.453124 25.382813-2.617188 2.242187-5.828126 3.402344-9.09375 3.402344zm0 0" fill="#3ac7b4" />
                <path d="m311.730469 396.90625h-101.113281c-4.265626 0-7.726563-3.457031-7.726563-7.726562 0-4.265626 3.460937-7.726563 7.726563-7.726563h101.113281c4.265625 0 7.726562 3.460937 7.726562 7.726563 0 4.269531-3.460937 7.726562-7.726562 7.726562zm0 0" fill="#3a484a" />
                <path d="m63.136719 104.683594v2.78125c0 26.808594 21.808593 48.621094 48.617187 48.621094h182.601563c26.808593 0 48.621093-21.8125 48.621093-48.621094v-2.78125zm0 0" fill="#d4d2d3" />
                <path d="m294.355469 43.332031h-50.453125c.046875-.800781.078125-1.601562.078125-2.410156 0-22.601563-18.324219-40.921875-40.925781-40.921875-22.601563 0-40.921876 18.320312-40.921876 40.925781 0 .808594.027344 1.609375.074219 2.40625h-50.453125c-9.78125 0-17.714844 7.929688-17.714844 17.714844v46.417969c0 9.78125 7.933594 17.714844 17.714844 17.714844h182.601563c9.78125 0 17.714843-7.933594 17.714843-17.714844v-46.417969c0-9.785156-7.929687-17.714844-17.714843-17.714844zm0 0" fill="#bab8b9" />
                <path d="m216.46875 40.925781c0 7.40625-6.007812 13.410157-13.414062 13.410157s-13.410157-6.003907-13.410157-13.410157c0-7.410156 6.003907-13.414062 13.410157-13.414062s13.414062 6.003906 13.414062 13.414062zm0 0" fill="#d4d2d3" />
                <g fill="#3a484a">
                    <path d="m137.945312 94.078125c-4.265624 0-7.726562-3.460937-7.726562-7.726563v-11.785156c0-4.265625 3.460938-7.726562 7.726562-7.726562 4.269532 0 7.726563 3.460937 7.726563 7.726562v11.785156c0 4.265626-3.457031 7.726563-7.726563 7.726563zm0 0" />
                    <path d="m268.164062 94.078125c-4.269531 0-7.726562-3.460937-7.726562-7.726563v-11.785156c0-4.265625 3.457031-7.726562 7.726562-7.726562 4.269532 0 7.726563 3.460937 7.726563 7.726562v11.785156c0 4.265626-3.457031 7.726563-7.726563 7.726563zm0 0" />
                    <path d="m203.054688 100.367188c-8.492188 0-16.46875-3.554688-21.886719-9.75-2.808594-3.214844-2.480469-8.09375.734375-10.902344 3.210937-2.808594 8.09375-2.480469 10.902344.730468 2.480468 2.839844 6.214843 4.46875 10.25 4.46875 4.035156 0 7.769531-1.628906 10.25-4.46875 2.808593-3.210937 7.691406-3.539062 10.902343-.730468 3.214844 2.808594 3.542969 7.6875.734375 10.902344-5.414062 6.195312-13.390625 9.75-21.886718 9.75zm0 0" />
                </g>
            </svg>
        );
    }
}

export default Test;
