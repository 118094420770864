import { StorageManager } from "../utils/storageManager";

export const API_URL = process.env.REACT_APP_API_URL;

export const apiAuth = {
    post(path, data) {
        let token = StorageManager.getLoginToken();

        return fetch(`${API_URL}${path}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(data),
        });
    },
    put(path, data) {
        let token = StorageManager.getLoginToken();

        return fetch(`${API_URL}${path}`, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
    },
    delete(path, data) {
        let token = StorageManager.getLoginToken();

        return fetch(`${API_URL}${path}`, {
            method: "Delete",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
    },
    get(path) {
        let token = StorageManager.getLoginToken();

        return fetch(`${API_URL}${path}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },

    multiPartPost(path, data) {
        let token = StorageManager.getLoginToken();
        const headers = new Headers();
        headers.append("Authorization", "Bearer " + token);
        const requestOptions = {
            method: "POST",
            headers,
            body: data,
            redirect: "follow",
        };
        return fetch(`${API_URL}${path}`, requestOptions);
    },
};

export const api = {
    get(path) {
        return fetch(`${API_URL}${path}`);
    },
    post(path, data) {
        return fetch(`${API_URL}${path}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
    },

    postForm(path, data) {
        console.log("form post ", path);

        return fetch(`${API_URL}${path}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: data,
        });
    },
};

