import { connect } from "react-redux";
import PostJobThirdStep from "./PostJobThirdStep";
import { getTranslation } from "../../../../../reducers/common/localization";
import {
    fetchPostOrganizationJob,
    fetchUpdateOrganizationJob,
    getPostJobLoading,
} from "../../../../../reducers/job/organizationJobs";
import { getJobSkillsData, getJobSkillsDataTree } from "../../../../../reducers/job/jobSkills";
import { getJobProfilesData } from "../../../../../reducers/job/jobProfiles";

const mapStateToProps = (state) => ({
    translation: getTranslation(state),
    skills: getJobSkillsData(state),
    skillsTree: getJobSkillsDataTree(state),
    profiles: getJobProfilesData(state),
    isPosting: getPostJobLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    onPostJob: (data) => dispatch(fetchPostOrganizationJob(data)),
    onEditJob: (jobId, data) => dispatch(fetchUpdateOrganizationJob(jobId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostJobThirdStep);
