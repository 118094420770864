
import React, { Component } from 'react';

class EmailExpire extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 60 60">
                <polygon fill="#feca57" points="0,5 0,44 28,44 56,44 56,5 " />
                <path fill="#f4b537" d="M30.965,27.107c-1.637,1.462-4.292,1.462-5.93,0l-2.087-1.843C16.419,31.091,0,44,0,44h21.607 h12.787H56c0,0-16.419-12.909-22.948-18.736L30.965,27.107z" />
                <path fill="#fcd386" d="M0,5l25.035,22.107c1.637,1.462,4.292,1.462,5.93,0L56,5H0z" />
                <circle fill="#76C9B0" cx="46" cy="41" r="11" />
                <path fill="#FFFFFF" d="M52,40h-5v-5c0-0.552-0.447-1-1-1s-1,0.448-1,1v6c0,0.552,0.447,1,1,1h6c0.553,0,1-0.448,1-1 S52.553,40,52,40z" />
            </svg>
        );
    }
}

export default EmailExpire;
