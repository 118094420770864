import { connect } from 'react-redux';
import ApplicantsStack from './ApplicantsStack';
import { getTranslation } from '../../../../../reducers/common/localization';
import {
	getApplicantsComparisonSortingMode,
	getApplicantsComparisonSortingRatio,
	setSortingMode,
	setCombinedSortingRatio
} from '../../../../../reducers/quickfind/applicantsComparison';
import { onCandidatePress } from '../../../../../reducers/quickfind/quickfind';

const mapStateToProps = state => {
	return {
		translation: getTranslation(state),
		sortingMode: getApplicantsComparisonSortingMode(state),
		combinedSortingRatio: getApplicantsComparisonSortingRatio(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onChangeSortingMode: (value) => dispatch(setSortingMode(value)),
		onChangeCombinedSortingRatio: (ratio) => dispatch(setCombinedSortingRatio(ratio)),
		onRemoveApplicantClick: (userId) => dispatch(onCandidatePress(userId)),
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantsStack);
