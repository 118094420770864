import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './ApplicantsStack.scss';
import Icon from '../../../../common/icon/Icon';
import UserPhoto from '../../../../common/userPhoto/UserPhoto';
import { USER_PHOTO_MODE } from '../../../../common/userPhoto/UserPhoto';
import { Collapse } from 'reactstrap';
import {
	SORTING_MODE,
	SORTING_MODE_ICON,
	SORTING_MODE_TITLE,
	SORTING_MODES_LIST
} from '../../../../../constants/candidatesComparisonConstants';

class ApplicantsStack extends Component {
	render() {
		const {
			translation,
			sortingMode,
			onChangeSortingMode,
			applicantsData,
			combinedSortingRatio,
			onChangeCombinedSortingRatio,
			onRemoveApplicantClick,
			enabledApplicantsIdsToCompare,
			onDisableApplicant
		} = this.props;

		return (
			<div className="applicants-stack">
				<h6 className="text-center">{_.get(translation, "applicants_comparison_page.applicants_stack_label")}</h6>
				<div className="sorting-menu">
					<h6>
						{_.get(translation, 'actions.sort_by_label')}
					</h6>
					<div className="sorting-menu-buttons">
						{
							_.map(SORTING_MODES_LIST, (mode) => {
								return (
									<div
										key={mode}
										className={`sorting-menu-button ${sortingMode === mode ? "active" : ''}`}
										onClick={() => onChangeSortingMode(mode)}
										title={_.get(translation, SORTING_MODE_TITLE[mode])}
									>
										<Icon type={`${SORTING_MODE_ICON[mode]}${sortingMode !== mode ? "_outline" : ""}`} />
									</div>
								)
							})
						}
					</div>
				</div>
				<Collapse className="hs-ss-ratio-collapse" isOpen={sortingMode === SORTING_MODE.COMBINED}>
					<div className="hs-ss-ratio-container">
						<Icon type="gear" size="sm" title={_.get(translation, "job_and_candidates.hard_skills_label")} />
						<input
							className="hs-ss-ratio-input"
							type="range"
							min="-5"
							max="5"
							value={combinedSortingRatio}
							onChange={(e) => onChangeCombinedSortingRatio(e.target.value)}
						/>
						<Icon type="brain" size="sm" title={_.get(translation, "job_and_candidates.soft_skills_label")} />
					</div>
				</Collapse>
				<div className="applicants-list">
				{
					_.map(applicantsData, (applicant) => {
						const isUserSelected = _.includes(enabledApplicantsIdsToCompare, applicant.id);

						return (
							<div key={applicant.id} className="applicant-card">
								<div className="applicant-photo-container">
									<UserPhoto
										mode={USER_PHOTO_MODE.COMPARISON}
										userId={applicant.id}
										imageSource=""
										size="lg"
										isClickable
										isUserSelected={isUserSelected}
										onPhotoClick={onDisableApplicant(isUserSelected)}
									/>
									<Icon
										className="remove-from-stack"
										type="cancel"
										size="sm"
										color="danger"
										onClick={() => onRemoveApplicantClick(applicant.id)}
										title={_.get(translation, 'applicants_comparison_page.remove_applicant_from_stack_label')}
									/>
								</div>
								<div className={`applicant-name`}>
									{`${applicant.alias.firstName} ${applicant.alias.lastName}`}
								</div>
							</div>
						)
					})
				}
				</div>
			</div>
		);
	}
}

ApplicantsStack.propTypes = {
	translation: PropTypes.object.isRequired,
	sortingMode: PropTypes.string,
	applicantsData: PropTypes.array.isRequired,
	combinedSortingRatio: PropTypes.string,
	onChangeSortingMode: PropTypes.func.isRequired,
	onDisableApplicant: PropTypes.func.isRequired,
	enabledApplicantsIdsToCompare: PropTypes.array.isRequired,
	onRemoveApplicantClick: PropTypes.func.isRequired,
	onChangeCombinedSortingRatio: PropTypes.func.isRequired,
};


export default ApplicantsStack;
