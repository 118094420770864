import React, { Component } from "react";

export default class DepartmentsProfile extends Component {
    render() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 128"
            >
                <defs></defs>
                <title>v</title>
                <rect fill="#1d75b8" x="15.4763" y="72.32086" width="9.68149" height="9.68149" />
                <rect fill="#1d75b8" x="32.58414" y="72.32086" width="9.68157" height="9.68149" />
                <rect fill="#1d75b8" x="15.4763" y="89.78457" width="9.68149" height="9.68149" />
                <rect fill="#1d75b8" x="32.58414" y="89.78457" width="9.68157" height="9.68149" />
                <rect fill="#1d75b8" x="37.28273" y="38.33033" width="9.68157" height="9.68157" />
                <rect fill="#1d75b8" x="54.39065" y="38.33033" width="9.68149" height="9.68157" />
                <path
                    fill="#2d3e50"
                    d="M54.56153,53.50719H29.88033V32.84007L71.39251,17.06335V44.3105h-.16506V98.55074a9.62494,9.62494,0,0,1,1.43533-.87648c1.46083-.72406,3.3408-1.537,5.3224-2.33732V44.3105L77.97254,7.52374,23.30038,28.30522V51.30966H.98186v69.16582h64.9994v-.07621c0-.21592.05078-3.04866.26671-6.50374H54.56153Zm-4.38234,60.38834H7.56181V57.88961H50.17919Z"
                />
                <path
                    fill="#2d3e50"
                    d="M125.92054,105.8632a5.80889,5.80889,0,0,0-3.12736-4.21271c-5.43886-2.71133-17.3754-6.718-17.3754-6.718H104.408l-3.26621,4.35761,3.06215,21.18619h22.81418A126.32884,126.32884,0,0,0,125.92054,105.8632Z"
                />
                <path
                    fill="#2d3e50"
                    d="M109.28967,82.32581l1.64365-8.47209A11.34159,11.34159,0,0,0,99.69695,60.98378H97.74457A11.34158,11.34158,0,0,0,86.50828,73.85372l1.64365,8.47209a10.767,10.767,0,0,0,21.13773,0Z"
                />
                <path
                    fill="#2d3e50"
                    d="M92.02374,94.93246s-11.93647,4.00671-17.37532,6.718A5.809,5.809,0,0,0,71.521,105.8632a126.34044,126.34044,0,0,0-1.09752,14.61306H93.23764l3.06215-21.18619-3.26621-4.35761Z"
                />
            </svg>
        );
    }
}
