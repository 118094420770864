import { connect } from 'react-redux';
import { getTranslation } from '../../../reducers/common/localization';
import QuickfindJob from './QuickfindJob'
import {
	getSelectedSkillId,
	getSelectedSkills,
	getJobs,
	getSelectedJobsIds,
	getFetchJobsLoading
} from '../../../reducers/quickfind/quickfind';

const mapStateToProps = state => {
	return {
		translation: getTranslation(state),
		skills: getSelectedSkills(state),
		selectedSkillId: getSelectedSkillId(state),
		jobs: getJobs(state),
		selectedJobsIds: getSelectedJobsIds(state),
		loading: getFetchJobsLoading(state)
	};
};

export default connect(mapStateToProps, null)(QuickfindJob)
