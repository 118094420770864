import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import _ from 'lodash';
import { getApplicantSelectedProfilesData } from '../applicant/applicantProfiles';
import { getApplicantCurrentClusterId } from '../applicant/applicantSkills';
import { getJobSelectedProfilesData } from '../job/jobProfiles';
import { getJobCurrentClusterId } from '../job/jobSkills';

//- Actions
export const fetchGetCurrentClusterSkillsFailed = createAction('FETCH_GET_CURRENT_CLUSTER_SKILLS_FAILED');
export const fetchGetCurrentClusterSkillsSuccess = createAction('FETCH_GET_CURRENT_CLUSTER_SKILLS_SUCCESS');
export const onFetchGetCurrentClusterSkillsSuccess = (data) => (dispatch) => {
    let formattedSkills = _.map(data.content, (skill) => {
        return {
            id: skill.id,
            title: skill.titles.en,
            clusters: skill.clusters
                ? skill.clusters.map((cluster) => ({
                    clusterId: cluster.id,
                    clusterTitle: cluster.titles.en,
                }))
                : [],
        }
    });
    dispatch(fetchGetCurrentClusterSkillsSuccess(formattedSkills));
}
export const fetchGetCurrentClusterSkills = createAction(
    'FETCH_GET_CURRENT_CLUSTER_SKILLS',
    (clusterId, profileIds) => Api.getSkillsByClusterAndProfiles(clusterId, profileIds),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetCurrentClusterSkillsSuccess,
        onFail: fetchGetCurrentClusterSkillsFailed
    })
);
export const onFetchGetCurrentClusterSkillsForApplicant = (clusterId) => (dispatch, getState) => {
    let selectedProfiles = getApplicantSelectedProfilesData(getState());
    dispatch(fetchGetCurrentClusterSkills(clusterId, selectedProfiles));
}
export const onFetchGetCurrentClusterSkillsByProfilesForApplicant = (selectedProfiles) => (dispatch, getState) => {
    let clusterId = getApplicantCurrentClusterId(getState());
    if (clusterId) {
        dispatch(fetchGetCurrentClusterSkills(clusterId, selectedProfiles));
    }
}
export const onFetchGetCurrentClusterSkillsForJob = (clusterId) => (dispatch, getState) => {
    let selectedProfiles = getJobSelectedProfilesData(getState());
    dispatch(fetchGetCurrentClusterSkills(clusterId, selectedProfiles));
}
export const onFetchGetCurrentClusterSkillsByProfilesForJob = (selectedProfiles) => (dispatch, getState) => {
    let clusterId = getJobCurrentClusterId(getState());
    if (clusterId) {
        dispatch(fetchGetCurrentClusterSkills(clusterId, selectedProfiles));
    }
}

export const cleanCurrentClusterSkills = createAction('CLEAN_CURRENT_CLUSTER_SKILLS');

//- State
const initialState = {
    data: null,
    error: null,
    fetching: false
};

//- Reducers
export default handleActions({

    FETCH_GET_CURRENT_CLUSTER_SKILLS: (state) => {
        return { ...state, data: null, error: null, fetching: true };
    },
    FETCH_GET_CURRENT_CLUSTER_SKILLS_FAILED: (state, action) => {
        return { ...state, data: null, error: action.payload, fetching: false };
    },
    FETCH_GET_CURRENT_CLUSTER_SKILLS_SUCCESS: (state, action) => {
        return { ...state, data: action.payload, error: null, fetching: false };
    },
    CLEAN_CURRENT_CLUSTER_SKILLS: (state) => {
        return { ...state, data: null };
    },
}, initialState);

//- Selectors
export const getCurrentClusterSkillsState = state => state.currentClusterSkills;

export const getCurrentClusterSkillsData = state => getCurrentClusterSkillsState(state).data;

export const getCurrentClusterSkillsFetchingStatus = state => getCurrentClusterSkillsState(state).fetching;
