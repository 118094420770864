import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import _ from 'lodash';
import { NODE_SORTING, NODE_TYPE, NODE_STATUS, NODE_DOMAIN_LIST } from '../../constants/systemNodesConstants';
import { getSystemCurrentNodeId } from './systemCurrentNode';

//- Actions
export const fetchGetRelatedSkillsFailed = createAction('FETCH_GET_RELATED_SKILLS_DATA_FAILED');
export const fetchGetRelatedSkillsSuccess = createAction('FETCH_GET_RELATED_SKILLS_DATA_SUCCESS');
export const onFetchGetRelatedSkillsSuccess = (data) => (dispatch, getState) => {
    data.content = _.map(data.content, (skill) => {
        return {
            id: skill.id,
            title: skill.titles.en,
            cluster: skill.category ? {
                id: skill.category.id,
                title: skill.category.titles.en,
            } : null,
            type: skill.resourceType,
            // TODO: remove hardcoded values 
            status: NODE_STATUS.REFERENCE_MODEL,
            source: _.sample(["Admin", "User", "O*Net", "VDAB"]),
            domain: _.sample(NODE_DOMAIN_LIST)
        }
    });
    dispatch(fetchGetRelatedSkillsSuccess(data));
}
export const fetchGetRelatedSkills = createAction(
    'FETCH_GET_RELATED_SKILLS_DATA',
    (nodeId, params, page) => Api.getRelatedNodes(nodeId, params, page),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetRelatedSkillsSuccess,
        onFail: fetchGetRelatedSkillsFailed
    })
);

export const onFetchGetRelatedSkills = (params = {}) => (dispatch, getState) => {
    let nodeId = getSystemCurrentNodeId(getState());
    let nameFilter = getRelatedSkillsNameFilter(getState());
    let sourceFilter = getRelatedSkillsSourceFilter(getState());
    let statusFilter = getRelatedSkillsStatusFilter(getState());
    let domainFilter = getRelatedSkillsDomainFilter(getState());
    let sortingMode = getRelatedSkillsSortingMode(getState());
    let listParams = {
        nodeType: NODE_TYPE.SKILL,
        nameFilter: !_.isUndefined(params.nameFilter) ? params.nameFilter : nameFilter,
        sourceFilter: !_.isUndefined(params.sourceFilter) ? params.sourceFilter : sourceFilter,
        statusFilter: !_.isUndefined(params.statusFilter) ? params.statusFilter : statusFilter,
        domainFilter: !_.isUndefined(params.domainFilter) ? params.domainFilter : domainFilter,
        sortingMode: !_.isUndefined(params.sortingMode) ? params.sortingMode : sortingMode
    }
    dispatch(fetchGetRelatedSkills(nodeId, listParams, params.page));
}

export const onFetchGetRelatedSkillsByPage = (page) => (dispatch) => {
    dispatch(onFetchGetRelatedSkills({ page: page }));
}

export const updateRelatedSkillsNameFilter = createAction('UPDATE_RELATED_SKILLS_NAME_FILTER');
export const updateRelatedSkillsSourceFilter = createAction('UPDATE_RELATED_SKILLS_SOURCE_FILTER');
export const onUpdateRelatedSkillsSourceFilter = (sourceFilter) => (dispatch) => {
    dispatch(updateRelatedSkillsSourceFilter(sourceFilter));
    let params = {
        sourceFilter: sourceFilter,
    }
    dispatch(onFetchGetRelatedSkills(params));
}
export const updateRelatedSkillsStatusFilter = createAction('UPDATE_RELATED_SKILLS_STATUS_FILTER');
export const onUpdateRelatedSkillsStatusFilter = (statusFilter) => (dispatch) => {
    dispatch(updateRelatedSkillsStatusFilter(statusFilter));
    let params = {
        statusFilter: statusFilter,
    }
    dispatch(onFetchGetRelatedSkills(params));
}
export const updateRelatedSkillsDomainFilter = createAction('UPDATE_RELATED_SKILLS_DOMAIN_FILTER');
export const onUpdateRelatedSkillsDomainFilter = (domainFilter) => (dispatch) => {
    dispatch(updateRelatedSkillsDomainFilter(domainFilter));
    let params = {
        domainFilter: domainFilter,
    }
    dispatch(onFetchGetRelatedSkills(params));
}
export const updateRelatedSkillsSortingMode = createAction('UPDATE_RELATED_SKILLS_SORTING');
export const onUpdateRelatedSkillsSortingMode = (sortingMode) => (dispatch) => {
    dispatch(updateRelatedSkillsSortingMode(sortingMode));
    dispatch(onFetchGetRelatedSkills({ sortingMode: sortingMode }));
}

export const updateAddedRelatedSkills = createAction('UPDATE_ADDED_RELATED_SKILLS');
export const onAddRelationsWithSkills = (nodesToAdd) => (dispatch, getState) => {
    if (!_.isNil(nodesToAdd)) {
        let addedNodes = getAddedRelatedSkillsData(getState()) || [];
        dispatch(updateAddedRelatedSkills(_.concat(addedNodes, nodesToAdd)));
    }
}

export const resetAddedRelatedSkills = createAction('RESET_ADDED_RELATED_SKILLS');
export const resetRemovedRelatedSkills = createAction('RESET_REMOVED_RELATED_SKILLS');

export const addRelationWithSkill = (nodeId) => (dispatch, getState) => {
    let data = getRelatedSkillsData(getState());
    let addedNodes = _.cloneDeep(getAddedRelatedSkillsData(getState())) || [];
    let nodeToAdd = _.find(data, (node) => node.id === nodeId);
    addedNodes.push(nodeToAdd);
    dispatch(updateAddedRelatedSkills(addedNodes));
}
export const undoAddRelationWithSkill = (nodeId) => (dispatch, getState) => {
    let addedNodes = getAddedRelatedSkillsData(getState()) || [];
    addedNodes = _.filter(addedNodes, (node) => node.id !== nodeId);
    dispatch(updateAddedRelatedSkills(addedNodes));
}

export const updateRemovedRelatedSkills = createAction('UPDATE_REMOVED_RELATED_SKILLS');
export const removeRelationWithSkill = (nodeId) => (dispatch, getState) => {
    let data = getRelatedSkillsData(getState());
    let removedNodes = _.cloneDeep(getRemovedRelatedSkillsData(getState())) || [];
    let nodeToRemove = _.find(data, (node) => node.id === nodeId);
    removedNodes.push(nodeToRemove);
    dispatch(updateRemovedRelatedSkills(removedNodes));
}
export const undoRemoveRelationWithSkill = (nodeId) => (dispatch, getState) => {
    let removedNodes = _.cloneDeep(getRemovedRelatedSkillsData(getState())) || [];
    removedNodes = _.filter(removedNodes, (node) => node.id !== nodeId);
    dispatch(updateRemovedRelatedSkills(removedNodes));
}

//- State
const initialState = {
    data: null,
    currentPage: null,
    totalPages: null,
    addedSkills: null,
    removedSkills: null,
    error: null,
    fetching: false,
    nameFilter: "",
    sourceFilter: null,
    statusFilter: null,
    domainFilter: null,
    sortingMode: NODE_SORTING.NAME,
};

//- Reducers
export default handleActions({

    FETCH_GET_RELATED_SKILLS_DATA: (state) => {
        return { ...state, data: null, currentPage: null, totalPages: null, error: null, fetching: true };
    },
    FETCH_GET_RELATED_SKILLS_DATA_FAILED: (state, action) => {
        return { ...state, data: null, currentPage: null, totalPages: null, error: action.payload, fetching: false };
    },
    FETCH_GET_RELATED_SKILLS_DATA_SUCCESS: (state, action) => {
        return { ...state, data: action.payload.content, currentPage: action.payload.number, totalPages: action.payload.totalPages, error: null, fetching: false };
    },
    UPDATE_ADDED_RELATED_SKILLS: (state, action) => {
        return { ...state, addedSkills: action.payload };
    },
    UPDATE_REMOVED_RELATED_SKILLS: (state, action) => {
        return { ...state, removedSkills: action.payload };
    },
    UPDATE_RELATED_SKILLS_NAME_FILTER: (state, action) => {
        return { ...state, nameFilter: action.payload };
    },
    UPDATE_RELATED_SKILLS_SOURCE_FILTER: (state, action) => {
        return { ...state, sourceFilter: action.payload };
    },
    UPDATE_RELATED_SKILLS_STATUS_FILTER: (state, action) => {
        return { ...state, statusFilter: action.payload };
    },
    UPDATE_RELATED_SKILLS_DOMAIN_FILTER: (state, action) => {
        return { ...state, domainFilter: action.payload };
    },
    UPDATE_RELATED_SKILLS_SORTING: (state, action) => {
        return { ...state, sortingMode: action.payload };
    },
    RESET_ADDED_RELATED_SKILLS: (state) => {
        return { ...state, addedSkills: null };
    },
    RESET_REMOVED_RELATED_SKILLS: (state) => {
        return { ...state, removedSkills: null };
    },
}, initialState);

//- Selectors
export const getRelatedSkillsState = state => state.relatedSkills;

export const getRelatedSkillsData = state => getRelatedSkillsState(state).data;

export const getRelatedSkillsCurrentPage = state => getRelatedSkillsState(state).currentPage;

export const getRelatedSkillsTotalPages = state => getRelatedSkillsState(state).totalPages;

export const getRelatedSkillsFetchingStatus = state => getRelatedSkillsState(state).fetching;

export const getAddedRelatedSkillsData = state => getRelatedSkillsState(state).addedSkills;

export const getRemovedRelatedSkillsData = state => getRelatedSkillsState(state).removedSkills;

export const getRelatedSkillsNameFilter = state => getRelatedSkillsState(state).nameFilter;

export const getRelatedSkillsSourceFilter = state => getRelatedSkillsState(state).sourceFilter;

export const getRelatedSkillsStatusFilter = state => getRelatedSkillsState(state).statusFilter;

export const getRelatedSkillsDomainFilter = state => getRelatedSkillsState(state).domainFilter;

export const getRelatedSkillsSortingMode = state => getRelatedSkillsState(state).sortingMode;
