import { connect } from "react-redux";
import { getTranslation } from "../../../reducers/common/localization";
import { getUserId } from "../../../reducers/user/userData";
import EmployeeJobs from "./EmployeeJobs";
import {
    getOrganizationsJobsState,
    fetchGetOrganizationJobs,
} from "../../../reducers/organization/organizations";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        userId: getUserId(state),
        jobs: getOrganizationsJobsState(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onMount: () => {
            dispatch(fetchGetOrganizationJobs());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeJobs);
