import React, { Component } from "react";
import PropTypes from "prop-types";
import "./CompanyRegistration.scss";
import _ from "lodash";
import BackLink from "../../../common/buttons/backLink/BackLink.container";
import { EMPLOYEES_COUNT_OPTIONS, ROUTES } from "../../../../constants/constants";
import { FormGroup, Label, Input, Col, Button, Form } from "reactstrap";
import { FormUtils } from "../../../../utils/formUtils";
import LicenseAgreementModal from "../../../common/modals/licenseAgreementModal/LicenseAgreementModal.container";
import ContinentAndCountryFormGroups from "../../../common/formGroups/continentAndCountryFormGroups/ContinentAndCountryFormGroups.container";
import { getCompanyPricingType } from "../../../../utils/pricingUtils";

class CompanyRegistration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAgreeWithLicense: false,
            name: "",
            internetDomain: "",
            vatNumber: "",
            companyCode: "",
            employeeCount: null,
            financialData: "",
            countryCode: null,
            district: "",
            city: "",
            postal: "",
            street: "",
            anchorFirstName: "",
            anchorLastName: "",
            anchorEmail: "",
            region: "",
        };
    }

    render() {
        return (
            <div className="company-registration">
                <div className="back-link-container">
                    <BackLink
                        to={ROUTES.LOGIN}
                        label={_.get(this.props.translation, "login_page.back_to_login_page_label")}
                    />
                </div>
                {this.renderContent()}
                <LicenseAgreementModal onAgree={this.handleAgree} />
            </div>
        );
    }

    renderContent() {
        if (this.props.success) {
            return (
                <div className="success-registration-message">
                    <h6 className="text-center">
                        {_.get(
                            this.props.translation,
                            "company_registration_page.success_registration_message"
                        )}
                    </h6>
                    <h6 className="text-center">
                        {_.get(this.props.translation, "registration.success_registration_message")}
                    </h6>
                </div>
            );
        }

        return (
            <>
                <h4 className="text-center">
                    {_.get(this.props.translation, "company_registration_page.page_label")}
                </h4>
                <Form onSubmit={this.onSubmitRegistrationForm}>
                    <h5 className="page-subsection-header">
                        {_.get(this.props.translation, "organization.organization_label")}
                    </h5>
                    {this.renderCompanyFormGroups()}
                    <h5 className="page-subsection-header">
                        {_.get(this.props.translation, "roles.anchor_label")}
                    </h5>
                    {this.renderAnchorFromGroups()}
                    <FormGroup row>
                        <Col>
                            <FormGroup check>
                                <Label>
                                    <Input
                                        type="checkbox"
                                        checked={this.state.isAgreeWithLicense}
                                        onChange={this.handleChangeAgreementFlag}
                                    />
                                    {_.get(this.props.translation, "license_agreement.agree_label")}{" "}
                                    <Button
                                        color="link"
                                        onClick={this.handleClickShowLicenseAgreement}
                                    >
                                        {_.get(
                                            this.props.translation,
                                            "license_agreement.agree_link"
                                        )}
                                    </Button>
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="auto">
                            <Button
                                color="primary"
                                type="submit"
                                disabled={!this.state.isAgreeWithLicense}
                            >
                                {_.get(
                                    this.props.translation,
                                    "company_registration_page.submit_button"
                                )}
                            </Button>
                        </Col>
                    </FormGroup>
                </Form>
            </>
        );
    }

    renderCompanyFormGroups() {
        return (
            <>
                {this.renderOrganizationDataFormGroups()}
                <h6>{_.get(this.props.translation, "shared.address_label")}</h6>
                {this.renderOrganizationAddressFormGroup()}
            </>
        );
    }

    renderOrganizationDataFormGroups() {
        let formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField,
            onSelectChange: this.handleChangeSelectField,
            translation: this.props.translation,
        };

        let params = [
            {
                fieldName: "name",
                title: _.get(this.props.translation, "organization.org_name_label"),
                isRequired: true,
            },
            {
                fieldName: "internetDomain",
                title: _.get(this.props.translation, "organization.internet_domain_label"),
                isRequired: true,
                hintText: _.get(this.props.translation, "organization.internet_domain_hint"),
            },
            {
                fieldName: "vatNumber",
                title: _.get(this.props.translation, "organization.vat_number_label"),
            },
            {
                fieldName: "companyCode",
                title: _.get(this.props.translation, "organization.company_code_label"),
                hintText: _.get(this.props.translation, "organization.company_code_hint"),
            },
            {
                fieldName: "employeeCount",
                title: _.get(this.props.translation, "organization.employee_count_label"),
                type: "select",
                options: EMPLOYEES_COUNT_OPTIONS,
                placeholder: _.get(
                    this.props.translation,
                    "organization.employee_count_placeholder"
                ),
                isRequired: true,
            },
            {
                fieldName: "financialData",
                title: _.get(this.props.translation, "organization.financial_data_label"),
                type: "textarea",
                placeholder: _.get(
                    this.props.translation,
                    "organization.financial_data_placeholder"
                ),
                isRequired: true,
                hintText: _.get(this.props.translation, "organization.financial_data_hint"),
            },
        ];

        return _.map(params, (fieldParams) => FormUtils.renderFormGroup(fieldParams, formParams));
    }

    renderOrganizationAddressFormGroup() {
        let formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField,
        };

        let params = [
            {
                fieldName: "city",
                title: _.get(this.props.translation, "shared.city_label"),
                isRequired: true,
            },
            {
                fieldName: "district",
                title: _.get(this.props.translation, "shared.district_label"),
                isRequired: false,
            },
            {
                fieldName: "postal",
                title: _.get(this.props.translation, "shared.postal_label"),
                isRequired: true,
            },
            {
                fieldName: "street",
                title: _.get(this.props.translation, "shared.street_label"),
                isRequired: true,
            },
        ];

        return (
            <>
                <ContinentAndCountryFormGroups
                    countryCode={this.state.countryCode}
                    handleChangeCountry={this.handleChangeCountry}
                    handleChangeContinent={this.handleChangeContinent}
                />
                {_.map(params, (fieldParams) => FormUtils.renderFormGroup(fieldParams, formParams))}
            </>
        );
    }

    renderAnchorFromGroups() {
        let formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField,
        };

        let params = [
            {
                fieldName: "anchorEmail",
                title: _.get(this.props.translation, "person_data.email_label"),
                type: "email",
                isRequired: true,
            },
            {
                fieldName: "anchorFirstName",
                title: _.get(this.props.translation, "person_data.first_name_label"),
                isRequired: true,
            },
            {
                fieldName: "anchorLastName",
                title: _.get(this.props.translation, "person_data.last_name_label"),
                isRequired: true,
            },
        ];

        return _.map(params, (fieldParams) => FormUtils.renderFormGroup(fieldParams, formParams));
    }

    handleChangeField = (fieldName) => (e) => {
        this.setState({ [fieldName]: e.target.value });
    };

    handleChangeSelectField = (fieldName) => (selectedOption) => {
        this.setState({ [fieldName]: selectedOption.value });
    };

    handleChangeCountry = (countryCode) => {
        this.setState({ countryCode: countryCode });
    };

    handleChangeContinent = (region) => {
        this.setState({ region });
    };

    handleChangeAgreementFlag = () => {
        this.setState({ isAgreeWithLicense: !this.state.isAgreeWithLicense });
    };

    handleAgree = () => {
        this.setState({ isAgreeWithLicense: true });
    };

    handleClickShowLicenseAgreement = () => {
        this.props.onShowLicenseAgreement();
    };

    onSubmitRegistrationForm = (e) => {
        e.preventDefault();
        this.onProceedRegistration();
    };

    onProceedRegistration = () => {
        const pricingType = getCompanyPricingType(this.state.employeeCount);

        const pricingData =
            this.props.pricingData && pricingType ? this.props.pricingData[pricingType] : null;

        const pricing = pricingData
            ? {
                  amount: pricingData.amount,
                  currency: pricingData.currency,
              }
            : {};

        let data = {
            name: this.state.name,
            internetDomain: this.state.internetDomain,
            vatNumber: this.state.vatNumber,
            companyCode: this.state.companyCode,
            employeeCount: this.state.employeeCount,
            financialData: this.state.financialData,
            region: this.state.region,
            pricing,
            address: {
                countryCode: this.state.countryCode,
                district: this.state.district,
                city: this.state.city,
                postal: this.state.postal,
                street: this.state.street,
            },
            anchorFirstName: this.state.anchorFirstName,
            anchorLastName: this.state.anchorLastName,
            anchorEmail: this.state.anchorEmail,
        };

        this.props.onRegister(data);
    };
}

CompanyRegistration.propTypes = {
    translation: PropTypes.object.isRequired,
    onOpenPricingModal: PropTypes.func.isRequired,
    onRegister: PropTypes.func.isRequired,
    onShowLicenseAgreement: PropTypes.func.isRequired,
};

export default CompanyRegistration;
