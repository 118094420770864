import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SkillNotesPopover.scss';
import _ from "lodash";
import {
    UncontrolledPopover,
    PopoverBody,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import moment from 'moment';

class SkillNotesPopover extends Component {
    render() {
        if (!this.props.data) {
            return null;
        }

        return (
            <UncontrolledPopover
                className="skill-notes-popover"
                target={this.props.target}
                placement={this.props.placement || "right"}
                trigger={this.props.trigger || "legacy"}
                boundariesElement="window"
            >
                <PopoverBody>
                    <ListGroup className="notes-items-container">
                        {this.getListGroupItems()}
                    </ListGroup>
                </PopoverBody>
            </UncontrolledPopover>
        );
    }

    getListGroupItems() {
        return _.map(this.props.data, (note, id) => {
            return (
                <ListGroupItem key={id}>
                    <div className="note-date">
                        {moment(note.date).format('LL')}
                    </div>
                    <div>
                        {note.text}
                    </div>
                </ListGroupItem>
            )
        })
    }
}

SkillNotesPopover.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.array,
    target: PropTypes.string.isRequired,
    placement: PropTypes.string,
    trigger: PropTypes.string
};

export default SkillNotesPopover;
