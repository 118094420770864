import { connect } from "react-redux";
import {
    onSaveApplicantSkills,
    updateApplicantSkillProperty,
} from "../../../../../reducers/applicant/applicantSkills";
import SkillsTimeline from "./SkillsTimeline";

const mapDispatchToProps = (dispatch) => {
    return {
        updateSkillProperty: (key, value, mergeObject) =>
            dispatch(updateApplicantSkillProperty(key, value, mergeObject)),
        onSaveSkillsNotes: () => {
            dispatch(onSaveApplicantSkills());
        },
    };
};

export default connect(null, mapDispatchToProps)(SkillsTimeline);
