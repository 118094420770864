import { connect } from 'react-redux';
import ActivateApplicantAccount from './ActivateApplicantAccount';
import { getTranslation } from '../../../../reducers/common/localization';
import {
    getAccountToActivateData,
    fetchActivateAccount,
} from '../../../../reducers/authorization/activation';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        data: getAccountToActivateData(state),
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onActivateAccount: (data, token) => dispatch(fetchActivateAccount(data, token)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateApplicantAccount);
