import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Alert, Col } from "reactstrap";
import "./JobsQuickfindComparison.scss";
import JobsStack from "./components/jobsStack/JobsStack.container";
import { SkillsUtils } from "../../../utils/skillsUtils";
import Icon from "../../common/icon/Icon";
import Legend from "../../common/skills/legend/Legend.container";
import { LEGEND_TYPE } from "../../common/skills/legend/Legend";
import HardSkillComparisonControls from "../../common/hardSkillComparisonControls/HardSkillComparisonControls.container";
import QuickfindMultipleHardSkillComparison from "../../common/quickfindMultipleHardSkillComparison/QuickfindMultipleHardSkillComparison";
import ButtonWithTooltip from "../../common/ButtonWithTooltip/ButtonWithTooltip";
import AudioModal from "./components/audioModal/AudioModal.container";
import VideoModal from "./components/videoModal/VideoModal.container";
import DescriptionModal from "./components/descriptionModal/DescriptionModal.container";
import ApplyMediaModal from "./ApplyMediaModal.container";

class JobsQuickfindComparison extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowAllSkills: false,
            enabledJobsIdsToCompare: [],
            jobsIdsToApply: [],
        };
    }

    componentDidMount() {
        const { selectedJobsIds, onFetchJobsByIds, onFetchJobsApplications } = this.props;

        if (selectedJobsIds.length) {
            onFetchJobsApplications();
            onFetchJobsByIds(selectedJobsIds);
            this.setState({ enabledJobsIdsToCompare: selectedJobsIds });
        }
    }

    onDisableJob = (isJobSelected, jobId) => () => {
        const { enabledJobsIdsToCompare } = this.state;
        if (isJobSelected) {
            const updatedJobsIds = _.filter(enabledJobsIdsToCompare, (id) => id !== jobId);
            this.setState({ enabledJobsIdsToCompare: updatedJobsIds });
        } else {
            this.setState({ enabledJobsIdsToCompare: [...enabledJobsIdsToCompare, jobId] });
        }
    };

    handleAllSkillsVisibility = (value) => () => {
        this.setState({ isShowAllSkills: value });
    };

    render() {
        const {
            translation,
            selectedJobsIds,
            skills,
            jobsData,
            loading,
            // onOpenApplyJobsModal,
            // onCloseApplyJobsModal,
            // isJobsApplyModalOpen,
            onOpenAudioModal,
            onOpenVideoModal,
            onOpenDescriptionModal,
            onApplyJobs,
            jobsApplicationsIds,
        } = this.props;

        const { enabledJobsIdsToCompare, isShowAllSkills } = this.state;

        if (loading) {
            return (
                <div className="loading-wrapper">
                    <h6 className="text-center">
                        {_.get(translation, "actions.fetching_message")}
                    </h6>
                </div>
            );
        }

        const jobsToShowInStack = _.filter(jobsData, (job) => _.includes(selectedJobsIds, job.id));

        const jobsDataToCompare = _.filter(
            jobsData,
            (job) =>
                _.includes(selectedJobsIds, job.id) && _.includes(enabledJobsIdsToCompare, job.id)
        );

        const formattedJobsData = _.map(jobsDataToCompare, (job) => {
            return {
                jobId: job.id,
                title: job.title,
                skills: _.map(job.skills, (skill) => {
                    return {
                        id: skill.skill.id,
                        title: skill.skill.titles.en,
                        categoryId: skill.skill.category.id,
                        categoryTitle: skill.skill.category.titles.en,
                        level: skill.level,
                    };
                }),
            };
        });

        const searchSkills = _.map(skills, (skill) => ({
            id: skill.id,
            title: skill.name,
            categoryId: skill.category.id,
            categoryTitle: skill.category.titles.en,
            level: skill.level,
        }));

        const jobsSkills = _.map(jobsDataToCompare, (job) => ({
            skills: _.map(job.skills, (skill) => {
                return {
                    id: skill.skill.id,
                    title: skill.skill.titles.en,
                    categoryId: skill.skill.category.id,
                    categoryTitle: skill.skill.category.titles.en,
                    level: skill.level,
                };
            }),
        }));

        const comparedSkills = _.map(jobsSkills, (skill) =>
            SkillsUtils.createComparisonSkillsTree(searchSkills, skill.skills)
        );

        const searchSkillsTree = SkillsUtils.createSkillsTree(searchSkills);

        const searchSkillsClusters = _.orderBy(searchSkillsTree, ["categoryTitle"]);

        const isFavorite = true; //todo: needs to integrate with back

        // const jobsIds = _.map(formattedJobsData, (job) => job.jobId);

        const notAppliedJobs = _.filter(
            formattedJobsData,
            (job) => !jobsApplicationsIds.includes(job.jobId)
        );

        return (
            <>
                <div className="jobs-comparison">
                    <h4 className="page-header">
                        {_.get(translation, "jobs_comparison_page.page_label")}
                    </h4>
                    <div className="jobs-comparison-content-container">
                        <Col md="3" className="content-column jobs-stack-column">
                            <div className="jobs-stack-wrapper">
                                <JobsStack
                                    enabledJobsIdsToCompare={enabledJobsIdsToCompare}
                                    jobsData={jobsToShowInStack}
                                    onDisableJob={this.onDisableJob}
                                />
                            </div>
                        </Col>
                        <Col className="content-column details-column">
                            <div className="details-wrapper">
                                <div className="dashboard-comparison">
                                    {jobsDataToCompare.length ? (
                                        <>
                                            <div className="jobs-info">
                                                <div className="jobs-info-col applicant-requirements">
                                                    <h6 className="text-center">
                                                        {_.get(
                                                            translation,
                                                            "jobs_comparison_page.applicant_requirements_label"
                                                        )}
                                                    </h6>
                                                </div>
                                                {_.map(formattedJobsData, (job) => {
                                                    const isJobSelected = _.includes(
                                                        enabledJobsIdsToCompare,
                                                        job.jobId
                                                    );

                                                    return (
                                                        <div
                                                            className="jobs-info-col job-info"
                                                            key={job.jobId}
                                                        >
                                                            <div className="job-controls">
                                                                <div className="buttons-group">
                                                                    {isFavorite ? (
                                                                        <Icon
                                                                            className="favorite-icon"
                                                                            type="star"
                                                                            size="lg"
                                                                            title={_.get(
                                                                                translation,
                                                                                "candidates_comparison_page.remove_from_favorite_label"
                                                                            )}
                                                                            onClick={() => {}}
                                                                        />
                                                                    ) : (
                                                                        <Icon
                                                                            className="favorite-icon"
                                                                            type="star_outline"
                                                                            size="lg"
                                                                            title={_.get(
                                                                                translation,
                                                                                "candidates_comparison_page.add_to_favorite_label"
                                                                            )}
                                                                            onClick={() => {}}
                                                                        />
                                                                    )}
                                                                    <Icon
                                                                        type="microphone"
                                                                        size="lg"
                                                                        onClick={onOpenAudioModal}
                                                                    />
                                                                </div>
                                                                <Icon
                                                                    className="job-icon"
                                                                    type="job"
                                                                    color="secondary"
                                                                    size="xxl"
                                                                />
                                                                <div className="buttons-group">
                                                                    <Icon
                                                                        type="text_message"
                                                                        size="lg"
                                                                        onClick={
                                                                            onOpenDescriptionModal
                                                                        }
                                                                    />
                                                                    <Icon
                                                                        type="movie"
                                                                        size="lg"
                                                                        onClick={onOpenVideoModal}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="job-title">
                                                                {job.title}
                                                            </div>
                                                            <div className="job-title">
                                                                Company Name
                                                            </div>
                                                            <Icon
                                                                className="remove-applicant-from-comparison"
                                                                type="cancel"
                                                                size="sm"
                                                                color="danger"
                                                                onClick={this.onDisableJob(
                                                                    isJobSelected,
                                                                    job.jobId
                                                                )}
                                                                title={_.get(
                                                                    translation,
                                                                    "jobs_comparison_page.remove_job_from_comparison_label"
                                                                )}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <HardSkillComparisonControls
                                                isShowAllSkills={isShowAllSkills}
                                                handleAllSkillsVisibility={
                                                    this.handleAllSkillsVisibility
                                                }
                                            />
                                            <QuickfindMultipleHardSkillComparison
                                                isShowAllSkills={isShowAllSkills}
                                                comparedSkills={comparedSkills}
                                                clusters={searchSkillsClusters}
                                            />
                                            <Legend type={LEGEND_TYPE.COMPARISON_REVERSED} />
                                            <div className="apply-buttons-wrapper">
                                                <div className="apply-buttons-label">
                                                    <h6 className="text-center">
                                                        {_.get(
                                                            translation,
                                                            "quickfind_job_page.apply_jobs_section_label"
                                                        )}
                                                    </h6>
                                                </div>
                                                {_.map(formattedJobsData, (job) => {
                                                    return !jobsApplicationsIds.includes(
                                                        job.jobId
                                                    ) ? (
                                                        <div
                                                            key={job.jobId}
                                                            className="button-wrapper"
                                                        >
                                                            <ButtonWithTooltip
                                                                onClick={() => {
                                                                    this.setState({
                                                                        jobsIdsToApply: [job.jobId],
                                                                    });
                                                                    this.props.onOpenApplyJobsModal(
                                                                        job.jobId
                                                                    );
                                                                }}
                                                                id={`Tooltip-${job.jobId}`}
                                                                buttonTitle={_.get(
                                                                    translation,
                                                                    "shared.apply"
                                                                )}
                                                                buttonTooltip={_.get(
                                                                    translation,
                                                                    "quickfind_job_page.apply_button_hint"
                                                                )}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            key={job.jobId}
                                                            className="button-wrapper"
                                                        >
                                                            {_.get(translation, "shared.applied")}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {notAppliedJobs.length > 1 && (
                                                <div className="apply-all">
                                                    <ButtonWithTooltip
                                                        onClick={() => {
                                                            const jobsIds = _.map(
                                                                notAppliedJobs,
                                                                (job) => job.jobId
                                                            );
                                                            this.setState(
                                                                { jobsIdsToApply: jobsIds },
                                                                () =>
                                                                    onApplyJobs(
                                                                        this.state.jobsIdsToApply
                                                                    )
                                                            );
                                                        }}
                                                        id="Apply-all"
                                                        buttonTitle={_.get(
                                                            translation,
                                                            "quickfind_job_page.apply_all_jobs"
                                                        )}
                                                        buttonTooltip={_.get(
                                                            translation,
                                                            "quickfind_job_page.apply_all_jobs"
                                                        )}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <Alert>
                                            {_.get(
                                                translation,
                                                "jobs_comparison_page.select_job_hint"
                                            )}
                                        </Alert>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>

                {/*<ApplyJobsModal*/}
                {/*	isOpen={isJobsApplyModalOpen}*/}
                {/*	onCancel={() => {*/}
                {/*		onCloseApplyJobsModal();*/}
                {/*		this.setState({ jobsIdsToApply: [] })*/}
                {/*	}}*/}
                {/*	jobsData={formattedJobsData}*/}
                {/*	jobsIdsToApply={jobsIdsToApply}*/}
                {/*/>*/}
                <ApplyMediaModal />
                <AudioModal />
                <VideoModal />
                <DescriptionModal />
            </>
        );
    }
}

JobsQuickfindComparison.propTypes = {
    translation: PropTypes.object.isRequired,
    selectedJobsIds: PropTypes.array.isRequired,
    skills: PropTypes.array.isRequired,
    jobsData: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    onFetchJobsByIds: PropTypes.func.isRequired,
    // onOpenApplyJobsModal: PropTypes.func.isRequired,
    // onCloseApplyJobsModal: PropTypes.func.isRequired,
    // isJobsApplyModalOpen: PropTypes.bool.isRequired,
    onApplyJobs: PropTypes.func.isRequired,
    onFetchJobsApplications: PropTypes.func.isRequired,
    onOpenAudioModal: PropTypes.func.isRequired,
    onOpenVideoModal: PropTypes.func.isRequired,
    onOpenDescriptionModal: PropTypes.func.isRequired,
    jobsApplicationsIds: PropTypes.array.isRequired,
};

export default JobsQuickfindComparison;
