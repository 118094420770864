import { connect } from 'react-redux';
import ApplicantRegistration from "./ApplicantRegistration";
import { getTranslation } from "../../../../reducers/common/localization";
import { showModal, MODAL_LICENSE_AGREEMENT } from '../../../../reducers/common/activeModal';
import { fetchRegisterApplicant } from '../../../../reducers/authorization/registration';
import { getRequestSuccessStatus } from '../../../../reducers/common/requestStatus';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        success: getRequestSuccessStatus(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowLicenseAgreement: () => {
            dispatch(showModal(MODAL_LICENSE_AGREEMENT));
        },
        onRegister: (data) => {
            dispatch(fetchRegisterApplicant(data));
        }
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ApplicantRegistration);