import React, { Component } from "react";
import PropTypes from "prop-types";
import "./RouteView.scss";
import _ from "lodash";
import { ROUTES, USER_ROLE } from "../../../../constants/constants";
import { Container, Spinner } from "reactstrap";
import PageNotFound from "../../errorPages/pageNotFound/PageNotFound.container";
import Login from "../../../authorization/login/Login.container";
import ServerError from "../../errorPages/serverError/ServerError.container";
import ApplicantSkills from "../../../applicant/applicantSkills/ApplicantSkills.container";
import EditApplicantSkills from "../../../applicant/editApplicantSkills/EditApplicantSkills.container";
import HomePage from "../../commonPages/homePage/HomePage.container";
import CreateNewJob from "../../../employee/job/createNewJob/CreateNewJob.container";
import OpenJobs from "../../../employee/job/openJobs/OpenJobs.container";
import OpenJobDetails from "../../../employee/job/openJobDetails/OpenJobDetails.container";
import CandidatesComparison from "../../../employee/job/candidatesComparison/CandidatesComparison.container";
import ModelManagement from "../../../admin/modelManagement/ModelManagement.container";
import SelectAccountType from "../../../authorization/registration/selectAccountType/SelectAccountType.container";
import ApplicantRegistration from "../../../authorization/registration/applicantRegistration/ApplicantRegistration.container";
import CompanyRegistration from "../../../authorization/registration/companyRegistration/CompanyRegistration.container";
import ActivateAccount from "../../../authorization/activation/activateAccount/ActivateAccount.container";
import RestoreAccount from "../../../authorization/restoreAccount/restoreAccount/RestoreAccount.container";
import ResetPassword from "../../../authorization/restoreAccount/resetPassword/ResetPassword.container";
import SupportPage from "../../commonPages/supportPage/SupportPage.container";
import ResendActivation from "../../../authorization/activation/resendActivation/ResendActivation.container";
import ApplicantAccount from "../../../applicant/applicantAccount/ApplicantAccount.container";
import StaffAndDepartments from "../../../anchor/staffAndDepartments/StaffAndDepartments.container";
import DepartmentDetails from "../../../anchor/department/departmentDetails/DepartmentDetails.container";
import SkillsJourney from "../../../applicant/skillsJourney/SkillsJourney.container";
import ApplicantQuickfind from "../../../employee/applicantQuickfind/ApplicantQuickfind.container";
import ApplicantsComparison from "../../../employee/applicantsComparison/ApplicantsComparison.container";
import QuickfindJob from "../../../applicant/quickfindJob/QuickfindJob.container";
import JobsQuickfindComparison from "../../../applicant/jobsQuickfindComparison/JobsQuickfindComparison.container";
import JobCompass from "../../../applicant/jobCompass/JobCompass.container";
import PostJob from "../../../employee/postJob/PostJob.container";
import ApplicantProfile from "../../../common/applicantProfile/ApplicantProfile.container";
import JobProfile from "../../../common/jobProfile/JobProfile.container";
import ApplicantJobs from "../../../applicant/applicantJobs/ApplicantJobs.container";
import EmployeeJobs from "../../../employee/employeeJobs/EmployeeJobs.container";
import InvitationsManagement from "../../../employee/invitationsManagement/InvitationsManagement.container";
import CompanyProfile from "../../../anchor/department/companyProfile/CompanyProfile.container";
import CorporateIdentity from "../../../employee/corporateIdentity/CorporateIdentity.container";
import PersonalSettings from "../../../common/personalSettings/PersonalSettings.container";
import EmployeeProfile from "../../../employee/profile/EmployeeProfile.container";
import DepartmentsList from "../../../anchor/department/departmentsList/DepartmentsList.container";
import TeamsList from "../../../anchor/teams/teamsList/TeamsList.container";

class RouteView extends Component {
    render() {
        if (this.props.isServerError) {
            return (
                <div className="page-container">
                    <ServerError />
                </div>
            );
        }

        if (this.props.loadingApp) {
            return (
                <div className="loading-app-container">
                    <Spinner
                        color="secondary"
                        title={_.get(this.props.translation, "actions.fetching_message")}
                    />
                </div>
            );
        }

        switch (this.props.route) {
            case ROUTES.HOME:
                return (
                    <div className="page-container">
                        <HomePage />
                    </div>
                );
            case ROUTES.LOGIN:
                return (
                    <div className="page-container">
                        <Login />
                    </div>
                );
            case ROUTES.CREATE_ACCOUNT:
                return (
                    <div className="page-container">
                        <SelectAccountType />
                    </div>
                );
            case ROUTES.APPLICANT_REGISTRATION:
                return (
                    <div className="page-container">
                        <ApplicantRegistration />
                    </div>
                );
            case ROUTES.COMPANY_REGISTRATION:
                return (
                    <div className="page-container">
                        <CompanyRegistration />
                    </div>
                );
            case ROUTES.CORPORATE_IDENTITY:
                return (
                    <div className="page-container">
                        <CorporateIdentity />
                    </div>
                );
            case ROUTES.ACTIVATE_ACCOUNT:
                return (
                    <div className="page-container">
                        <ActivateAccount />
                    </div>
                );
            case ROUTES.RESTORE_ACCOUNT:
                return (
                    <div className="page-container">
                        <RestoreAccount />
                    </div>
                );
            case ROUTES.RESET_PASSWORD:
                return (
                    <div className="page-container">
                        <ResetPassword />
                    </div>
                );
            case ROUTES.SUPPORT:
                return (
                    <div className="page-container">
                        <SupportPage />
                    </div>
                );
            case ROUTES.RESEND_ACTIVATION:
                return (
                    <div className="page-container">
                        <ResendActivation />
                    </div>
                );
            case ROUTES.MY_PROFILE:
                return (
                    <div className="page-container">
                        {/* TODO: add account page */}
                        <EmployeeProfile />
                    </div>
                );
            case ROUTES.APPLICANT_SKILLS:
                return (
                    <div className="page-container boxed-width">
                        <ApplicantSkills />
                    </div>
                );
            case ROUTES.EDIT_APPLICANT_SKILLS:
                return (
                    <div className="page-container">
                        <EditApplicantSkills />
                    </div>
                );
            case ROUTES.APPLICANT_ACCOUNT:
                return (
                    <div className="page-container">
                        <ApplicantAccount />
                    </div>
                );
            case ROUTES.CREATE_NEW_JOB:
                return (
                    <div className="page-container">
                        <CreateNewJob />
                    </div>
                );
            case ROUTES.OPEN_JOBS:
                return (
                    <div className="page-container boxed-width">
                        <OpenJobs />
                    </div>
                );
            case ROUTES.OPEN_JOB_DETAILS:
                return (
                    <div className="page-container boxed-width">
                        <OpenJobDetails />
                    </div>
                );
            case ROUTES.OPEN_JOB_COMPARISON:
                return (
                    <div className="page-container">
                        <CandidatesComparison />
                    </div>
                );
            case ROUTES.MODEL_MANAGEMENT:
                return (
                    <div className="page-container">
                        <ModelManagement />
                    </div>
                );
            case ROUTES.STAFF_AND_DEPARTMENTS:
                return (
                    <div className="page-container">
                        <StaffAndDepartments />
                    </div>
                );
            case ROUTES.DEPARTMENT_DETAILS:
                return (
                    <div className="page-container">
                        <DepartmentDetails />
                    </div>
                );
            case ROUTES.APPLICANT_PROFILE:
                return (
                    <div className="page-container">
                        <ApplicantProfile />
                    </div>
                );
            case ROUTES.JOB_PROFILE:
                return (
                    <div className="page-container">
                        <JobProfile />
                    </div>
                );
            case ROUTES.SKILLS_JOURNEY:
                return (
                    <div className="page-container">
                        <SkillsJourney />
                    </div>
                );
            case ROUTES.COMPANY_PROFILE:
                return (
                    <div className="page-container">
                        <CompanyProfile />
                    </div>
                );
            case ROUTES.APPLICANT_QUICKFIND:
                return (
                    <div className="page-container">
                        <ApplicantQuickfind />
                    </div>
                );
            case ROUTES.APPLICANTS_QUICKFIND_COMPARISON:
                return (
                    <div className="page-container">
                        <ApplicantsComparison />
                    </div>
                );
            case ROUTES.QUICKFIND_JOB:
                return (
                    <div className="page-container">
                        <QuickfindJob />
                    </div>
                );
            case ROUTES.JOBS_QUICKFIND_COMPARISON:
                return (
                    <div className="page-container">
                        <JobsQuickfindComparison />
                    </div>
                );
            case ROUTES.JOB_COMPASS:
                return (
                    <div className="page-container">
                        <JobCompass />
                    </div>
                );
            case ROUTES.POST_JOB:
                return (
                    <div className="page-container">
                        <PostJob />
                    </div>
                );
            case ROUTES.PERSONAL_SETTINGS:
                return (
                    <div className="page-container">
                        <PersonalSettings />
                    </div>
                );
            case ROUTES.INVITATIONS_MANAGEMENT:
                return (
                    <div className="page-container">
                        <InvitationsManagement />
                    </div>
                );
            case ROUTES.TEAM_PROFILE:
                return (
                    <div className="page-container">
                        <TeamsList />
                    </div>
                );
            case ROUTES.DEPARTMENTS_PROFILE:
                return (
                    <div className="page-container mx-auto">
                        <DepartmentsList />
                    </div>
                );
            case ROUTES.JOBS:
                if (this.props.role === USER_ROLE.APPLICANT) {
                    return (
                        <div className="page-container">
                            <ApplicantJobs />
                        </div>
                    );
                } else if (this.props.role === USER_ROLE.EMPLOYEE) {
                    return (
                        <div className="page-container">
                            <EmployeeJobs />
                        </div>
                    );
                }
                break;
            default:
                return (
                    <div className="page-container">
                        <PageNotFound />
                    </div>
                );
        }
    }
}

RouteView.propTypes = {
    translation: PropTypes.object.isRequired,
    isServerError: PropTypes.bool,
    loadingApp: PropTypes.bool,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RouteView;
