import { connect } from 'react-redux';
import TablePagination from "./TablePagination";
import {
    getStaffListCurrentPage,
    getStaffListTotalPages,
    getStaffListFetchingStatus,
    onClickStaffListPaginationItem,
} from '../../../reducers/organization/staffList';

const mapStateToProps = state => {
    return {
        current: getStaffListCurrentPage(state),
        total: getStaffListTotalPages(state),
        loading: getStaffListFetchingStatus(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getDataForPage: (page) => dispatch(onClickStaffListPaginationItem(page))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePagination);
