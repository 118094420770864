import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import { TABLE_STATUSES } from "../../constants/constants";
//- Actions
export const fetchGetOrganizationsListFailed = createAction("FETCH_GET_ORGANIZATIONS_LIST_FAILED");
export const fetchGetOrganizationsListSuccess = createAction(
    "FETCH_GET_ORGANIZATIONS_LIST_SUCCESS"
);
export const fetchGetOrganizationsJobsSuccess = createAction(
    "FETCH_GET_ORGANIZATIONS_JOBS_SUCCESS"
);

export const updateApplicant = createAction("UPDATE_JOB_APPLICANT");

export const setApplicantsAppliedToJob = createAction("SET_APPLICANTS_APPLIED_TO_JOB");

export const updateJobsAfterApproving = createAction("UPDATE_JOBS_AFTER_APPROVE");

export const onGetOrganizationsListSuccess = (data) => (dispatch) => {
    dispatch(fetchGetOrganizationsListSuccess(data));
};

export const fetchGetOrganizationsList = createAction(
    "FETCH_GET_ORGANIZATIONS_LIST",
    (data) => Api.getOrganization(data),
    () => ({
        apiCall: true,
        onSuccess: onGetOrganizationsListSuccess,
        onFail: fetchGetOrganizationsListFailed,
    })
);

export const onApproveBo = (data) => (dispatch) => {
    dispatch(updateJobsAfterApproving(data));
};

export const onApproveHrm = (data) => (dispatch) => {
    dispatch(updateJobsAfterApproving(data));
};

export const fetchApproveByBO = createAction(
    "FETCH_APPROVE_BY_BO",
    (data) => Api.approveByBo(data),
    () => ({ apiCall: true, onSuccess: onApproveBo, onFail: fetchGetOrganizationsListFailed })
);

export const fetchPublishJobs = createAction(
    "FETCH_PUBLISH_JOB",
    (data) => Api.publishJob(data),
    () => ({ apiCall: true, onSuccess: onPublishJob, onFail: fetchGetOrganizationsListFailed })
);

export const onPublishJob = (data) => (dispatch) => {
    dispatch(updateJobsAfterApproving(data));
};

export const fetchDeepScreeningJob = createAction(
    "FETCH_DEEP_SCREENING_JOB",
    (data) => Api.moveJobToDeepScreening(data),
    () => ({
        apiCall: true,
        onSuccess: onDeepScreeningJob,
        onFail: fetchGetOrganizationsListFailed,
    })
);

export const onDeepScreeningJob = (data) => (dispatch) => {
    dispatch(updateJobsAfterApproving(data));
};

export const fetchCloseJob = createAction(
    "FETCH_CLOSE_JOB",
    (data) => Api.closeJob(data),
    () => ({ apiCall: true, onSuccess: onCloseJobSuccess, onFail: fetchGetOrganizationsListFailed })
);

export const onCloseJobSuccess = (data) => (dispatch) => {
    dispatch(updateJobsAfterApproving(data));
};

export const fetchApproveByHRM = createAction(
    "FETCH_APPROVE_BY_HRM",
    (data) => Api.approveByHRM(data),
    () => ({ apiCall: true, onSuccess: onApproveHrm, onFail: fetchGetOrganizationsListFailed })
);

export const fetchPutHiredJobApplicant = createAction(
    "FETCH_PUT_HIRED_JOB_APPLICANT",
    (jobId, isHired) => Api.putHiredJobApplicant(jobId, isHired),
    () => ({
        apiCall: true,
        onSuccess: onPutHiredJobApplicant,
        onFail: fetchGetOrganizationsListFailed,
    })
);

export const onPutHiredJobApplicant = (data) => (dispatch) => {
    dispatch(updateApplicant(data));
};

export const fetchPutPrivateNodeJobApplicant = createAction(
    "FETCH_PUT_HIRED_JOB_APPLICANT",
    (jobId, privateNote) => Api.sendJobApplicantPrivateNote(jobId, privateNote),
    () => ({
        apiCall: true,
        onSuccess: onPutHiredJobApplicant,
        onFail: fetchGetOrganizationsListFailed,
    })
);

export const onPutPrivateNodeJobApplicant = (data) => (dispatch) => {
    dispatch(updateApplicant(data));
};

export const handleInterviewedDate = createAction(
    "FETCH_PUT_HIRED_JOB_APPLICANT",
    (jobId, date, toRemove) => Api.handleInterviewedDate(jobId, date, toRemove),
    () => ({
        apiCall: true,
        onSuccess: onHandleInterviewedDate,
        onFail: fetchGetOrganizationsListFailed,
    })
);

export const onHandleInterviewedDate = (data) => (dispatch) => {
    dispatch(updateApplicant(data));
};

export const onGetJobApplicants = (data) => (dispatch) => {
    dispatch(setApplicantsAppliedToJob(data));
};

export const updateCompanyMetadataState = createAction("UPDATE_COMPANY_METADATA");

export const onUpdateCompanyMetadata = (data) => (dispatch) => {
    dispatch(updateCompanyMetadataState(data));
};

export const fetchUpdateCompanyMetadata = createAction(
    "FETCH_UPDATE_COMPANY_METADATA",
    (data) => Api.updateCompanyMetadata(data),
    () => ({
        apiCall: true,
        onSuccess: onUpdateCompanyMetadata,
        onFail: fetchGetOrganizationsListFailed,
    })
);

export const fetchGetJobApplicants = createAction(
    "FETCH_GET_JOB_APPLICANTS",
    (data) => Api.getApplicantsAppliedToJob(data),
    () => ({
        apiCall: true,
        onSuccess: onGetJobApplicants,
        onFail: fetchGetOrganizationsListFailed,
    })
);

export const fetchMoveToInvitationsJob = createAction(
    "FETCH_MOVE_TO_INVITATIONS",
    (data) => Api.moveJobToInvitatons(data),
    () => ({ apiCall: true, onSuccess: onApproveHrm, onFail: fetchGetOrganizationsListFailed })
);

export const onMoveToInvitationsJobSuccess = (data) => (dispatch) => {
    dispatch(updateJobsAfterApproving(data));
};
export const onGetOrganizationsJobsSuccess = (data) => (dispatch) => {
    dispatch(fetchGetOrganizationsJobsSuccess(data.content));
};

export const sendFeedbackToJobApplicant = createAction(
    "SEND_FEEDBACK_TO_APPLICANT",
    (jobId, feedback) => Api.sendJobApplicantFeedback(jobId, feedback),
    () => ({
        apiCall: true,
        onSuccess: onSendFeedbackSuccess,
        onFail: fetchGetOrganizationsListFailed,
    })
);

export const onSendFeedbackSuccess = (data) => (dispatch) => {
    dispatch(updateApplicant(data));
};

export const fetchGetOrganizationJobs = createAction(
    "FETCH_GET_ORGANIZATIONS_JOBS",
    () => Api.getOrgJobs(),
    () => ({
        apiCall: true,
        onSuccess: onGetOrganizationsJobsSuccess,
        onFail: fetchGetOrganizationsListFailed,
    })
);

export const updateApplicantsToCompare = createAction("UPDATE_APPLICANTS_TO_COMPARE");

export const getApplicantsToCompareSuccess = (data) => (dispatch) => {
    dispatch(updateApplicantsToCompare(data));
};

export const fetchGetApplicantsToCompare = createAction(
    "FETCH_GET_APPLICANTS_TO_COMPARE",
    (jobId) => Api.fetchGetApplicantsToCompare(jobId),
    () => ({
        apiCall: true,
        onSuccess: getApplicantsToCompareSuccess,
        onFail: fetchGetOrganizationsListFailed,
    })
);

export const updateOrganizationMedia = createAction("UPDATE_ORGANIZATION_MEDIA");
export const updateOrganizationMediaSuccess = createAction("UPDATE_ORGANIZATION_MEDIA_SUCCESS");

export const onUpdateOrganizationMedia = (data) => (dispatch) => {
    dispatch(updateOrganizationMediaSuccess(data));
};

export const fetchUpdateOrganizationMedia = createAction(
    "FETCH_UPDATE_ORGANIZATION_MEDIA",
    (data) => Api.updateOrganizationMedia(data),
    () => ({
        apiCall: true,
        onSuccess: onUpdateOrganizationMedia,
        onFail: fetchGetOrganizationsListFailed,
    })
);

export const fetchDeleteOrgMedia = createAction(
    "FETCH_DELETE_ORG_MEDIA",
    (mediaId) => Api.deleteOrganizationMedia(mediaId),
    () => ({
        apiCall: true,
        onSuccess: onUpdateOrganizationMedia,
        onFail: fetchGetOrganizationsListFailed,
    })
);

//- State
const initialState = {
    data: {},
    fetching: false,
    error: null,
    orgsJobs: [],
    applicantsAppliedToJob: [],
    topApplicantsToCompare: [],
};

//- Reducers
export default handleActions(
    {
        FETCH_GET_ORGANIZATIONS_LIST: (state) => {
            return { ...state, error: null, fetching: true };
        },
        FETCH_GET_ORGANIZATIONS_LIST_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetching: false };
        },
        FETCH_GET_ORGANIZATIONS_LIST_SUCCESS: (state, action) => {
            return {
                ...state,
                data: action.payload,
                error: null,
                fetching: false,
            };
        },
        UPDATE_ORGANIZATION_MEDIA_SUCCESS: (state, action) => {
            return {
                ...state,
                data: { ...action.payload, isUpdated: true },
                error: null,
            };
        },
        UPDATE_JOBS_AFTER_APPROVE: (state, action) => {
            return {
                ...state,
                orgsJobs: state.orgsJobs.map((job) => {
                    return job.id === action.payload.id
                        ? {
                              ...action.payload,
                              reference: action.payload?.profile?.reference || "",
                              title: action.payload?.jobTitle,
                              department: action.payload.profile?.resourceDomain,
                              approvedByBo:
                                  !!action.payload.boApproved ||
                                  (action.payload.status.toLowerCase() !== TABLE_STATUSES.CREATED &&
                                      action.payload.status.toLowerCase() !==
                                          TABLE_STATUSES.COMPLETED),
                              approvedByHrm:
                                  !!action.payload.hrApproved ||
                                  (action.payload.status.toLowerCase() !== TABLE_STATUSES.CREATED &&
                                      action.payload.status.toLowerCase() !==
                                          TABLE_STATUSES.COMPLETED),
                              bo: job.businessOwner
                                  ? (job.businessOwner.lastName && job.businessOwner.firstName) ||
                                    job.businessOwner.email
                                  : "None",
                              hrm: job.hireManager
                                  ? (job.hireManager.lastName && job.hireManager.firstName) ||
                                    job.hireManager.email
                                  : "None",
                          }
                        : { ...job };
                }),
            };
        },

        UPDATE_APPLICANTS_TO_COMPARE: (state, action) => {
            return {
                ...state,
                topApplicantsToCompare: action.payload,
            };
        },
        FETCH_GET_ORGANIZATIONS_JOBS_SUCCESS: (state, action) => {
            return {
                ...state,
                orgsJobs: action.payload.map((job) => {
                    return {
                        ...job,
                        reference: job?.profile?.reference || "",
                        title: job.jobTitle,
                        department: job?.profile?.resourceDomain,
                        approvedByBo:
                            !!job.boApproved ||
                            (job.status.toLowerCase() !== TABLE_STATUSES.CREATED &&
                                job.status.toLowerCase() !== TABLE_STATUSES.COMPLETED),
                        approvedByHrm:
                            !!job.hrApproved ||
                            (job.status.toLowerCase() !== TABLE_STATUSES.CREATED &&
                                job.status.toLowerCase() !== TABLE_STATUSES.COMPLETED),
                        bo: job.businessOwner
                            ? (job.businessOwner.lastName && job.businessOwner.firstName) ||
                              job.businessOwner.email
                            : "None",
                        hrm: job.hireManager
                            ? (job.hireManager.lastName && job.hireManager.firstName) ||
                              job.hireManager.email
                            : "None",
                    };
                }),
            };
        },
        SET_APPLICANTS_APPLIED_TO_JOB: (state, action) => {
            return {
                ...state,
                applicantsAppliedToJob: action.payload.map((applicant) => ({
                    ...applicant,
                    firstName: applicant.applicantMetaData.firstName || "John",
                    lastName: applicant.applicantMetaData.lastName || "Mockleson",
                    feedback: applicant.feedback || "",
                })),
            };
        },
        UPDATE_COMPANY_METADATA: (state, action) => {
            return {
                ...state,
                data: action.payload,
            };
        },
        UPDATE_JOB_APPLICANT: (state, action) => {
            return {
                ...state,
                applicantsAppliedToJob: state.applicantsAppliedToJob.map((applicant) => {
                    return applicant.jobApplicationId === action.payload.jobApplicationId
                        ? {
                              ...applicant,
                              ...action.payload,
                              job: {
                                  ...applicant.job,
                                  skills: applicant.job.skills,
                              },
                          }
                        : { ...applicant };
                }),
            };
        },
    },
    initialState
);

//- Selectors
export const getOrganizationsListState = (state) => state.organizations.data;
export const getOrganizationsJobsState = (state) => state.organizations.orgsJobs;
export const getOrganizationsListError = (state) => state.organizations.error;
export const getApplicantsAppliedToJob = (state) => state.organizations.applicantsAppliedToJob;
export const getJobById = (state, jobId) =>
    state.organizations.orgsJobs.filter((orgJob) => orgJob.id === jobId)[0];
export const getApplicantsToCompare = (state) => state.organizations.topApplicantsToCompare;
