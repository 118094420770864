import { connect } from 'react-redux';
import RoutesManager from './RoutesManager';
import { getTranslation } from '../../../reducers/common/localization';
import { getLoginAuthorizeStatus, logout } from '../../../reducers/authorization/login';
import {
    getUserRole,
    getUserCurrentSubRole
} from '../../../reducers/user/userRole';
import { getUserId } from '../../../reducers/user/userData';
import { setForbiddenError } from '../../../reducers/common/errors';
import { resetRequestStatusData } from '../../../reducers/common/requestStatus';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        isAuthorized: getLoginAuthorizeStatus(state),
        userRole: getUserRole(state),
        userSubRole: getUserCurrentSubRole(state),
        userId: getUserId(state)
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onUserNotMatch: () => dispatch(setForbiddenError()),
        onUserMissing: () => dispatch(logout()),
        onResetRequestStatusData: () => dispatch(resetRequestStatusData()),
        onLogOut: () => dispatch(logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoutesManager);
