import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import _ from "lodash";
import { NODE_TYPE, NODE_SORTING } from "../../constants/systemNodesConstants";

//- Actions
export const fetchGetSystemNodesListFailed = createAction("FETCH_GET_SYSTEM_NODES_LIST_FAILED");
export const fetchGetSystemNodesListSuccess = createAction("FETCH_GET_SYSTEM_NODES_LIST_SUCCESS");
// TODO: remove hardcoded values
export const onFetchGetSystemNodesListSuccess = (data) => (dispatch) => {
    data.content = _.map(data.content, (elem) => {
        let formattedElem = {};
        formattedElem.status = elem.resourceStatus;
        formattedElem.source = elem.resourceOwner;
        formattedElem.domain = !!elem.domain ? elem.domain : null;
        formattedElem.type = elem.resourceType;
        formattedElem.id = elem.id;
        formattedElem.title = elem.titles.en;
        formattedElem.dependentSkills = elem.dependentSkills ? elem.dependentSkill : null;

        switch (formattedElem.type) {
            case NODE_TYPE.SKILL:
                if (elem.category) {
                    formattedElem.cluster = {
                        id: elem.category.id,
                        title: elem.category.titles.en,
                        source: elem.category.resourceOwner,
                        status: elem.category.resourceStatus,
                        type: elem.category.resourceType,
                    };
                } else {
                    formattedElem.cluster = null;
                }
                break;

            case NODE_TYPE.PROFILE:
                formattedElem.description = elem.description;
                break;

            default:
                break;
        }

        return formattedElem;
    });
    dispatch(fetchGetSystemNodesListSuccess(data));
};

export const updateNodeInNodesList = createAction("UPDATE_NODE_IN_NODES_LIST");

export const fetchGetSystemNodesList = createAction(
    "FETCH_GET_SYSTEM_NODES_LIST",
    (params, page) => Api.getSystemNodesList(params, page),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSystemNodesListSuccess,
        onFail: fetchGetSystemNodesListFailed,
    })
);

export const onFetchGetSystemNodesList =
    (params = {}) =>
    (dispatch, getState) => {
        let nameFilter = getSystemNodesNameFilter(getState());
        let typeFilter = getSystemNodesTypeFilter(getState());
        let sourceFilter = getSystemNodesSourceFilter(getState());
        let statusFilter = getSystemNodesStatusFilter(getState());
        let domainFilter = getSystemNodesDomainFilter(getState());
        let sortingMode = getSystemNodesSortingMode(getState());
        let listParams = {
            nameFilter: !_.isUndefined(params.nameFilter) ? params.nameFilter : nameFilter,
            typeFilter: !_.isUndefined(params.typeFilter) ? params.typeFilter : typeFilter,
            sourceFilter: !_.isUndefined(params.sourceFilter) ? params.sourceFilter : sourceFilter,
            statusFilter: !_.isUndefined(params.statusFilter) ? params.statusFilter : statusFilter,
            domainFilter: !_.isUndefined(params.domainFilter) ? params.domainFilter : domainFilter,
            sortingMode: !_.isUndefined(params.sortingMode) ? params.sortingMode : sortingMode,
        };

        dispatch(fetchGetSystemNodesList(listParams, params.page));
    };

export const onFetchGetSystemNodesListByPage = (page) => (dispatch) => {
    dispatch(onFetchGetSystemNodesList({ page: page }));
};

export const updateSystemNodesNameFilter = createAction("UPDATE_SYSTEM_NODES_NAME_FILTER");
export const updateSystemNodesTypeFilter = createAction("UPDATE_SYSTEM_NODES_TYPE_FILTER");
export const updateSystemNodesSourceFilter = createAction("UPDATE_SYSTEM_NODES_SOURCE_FILTER");
export const updateSystemNodesStatusFilter = createAction("UPDATE_SYSTEM_NODES_STATUS_FILTER");
export const updateSystemNodesDomainFilter = createAction("UPDATE_SYSTEM_NODES_DOMAIN_FILTER");

export const onUpdateSystemNodesTypeFilter = (typeFilter) => (dispatch) => {
    dispatch(updateSystemNodesTypeFilter(typeFilter));
    let params = {
        typeFilter: typeFilter,
    };
    dispatch(onFetchGetSystemNodesList(params));
};

export const onUpdateSystemNodesSourceFilter = (sourceFilter) => (dispatch) => {
    dispatch(updateSystemNodesSourceFilter(sourceFilter));
    let params = {
        sourceFilter: sourceFilter,
    };
    dispatch(onFetchGetSystemNodesList(params));
};
export const onUpdateSystemNodesStatusFilter = (statusFilter) => (dispatch) => {
    dispatch(updateSystemNodesStatusFilter(statusFilter));
    let params = {
        statusFilter: statusFilter,
    };
    dispatch(onFetchGetSystemNodesList(params));
};
export const onUpdateSystemNodesDomainFilter = (domainFilter) => (dispatch) => {
    dispatch(updateSystemNodesDomainFilter(domainFilter));
    let params = {
        domainFilter: domainFilter,
    };
    dispatch(onFetchGetSystemNodesList(params));
};
export const updateSystemNodesSortingMode = createAction("UPDATE_SYSTEM_NODES_SORTING");
export const onUpdateSystemNodesSortingMode = (sortingMode) => (dispatch) => {
    dispatch(updateSystemNodesSortingMode(sortingMode));
    dispatch(onFetchGetSystemNodesList({ sortingMode: sortingMode }));
};

export const fetchGetSystemNodesExternalSourceFailed = createAction(
    "FETCH_GET_SYSTEM_NODES_EXTERNAL_SOURCES_FAILED"
);
export const fetchGetSystemNodesExternalSourceSuccess = createAction(
    "FETCH_GET_SYSTEM_NODES_EXTERNAL_SOURCES_SUCCESS"
);
export const onFetchGetSystemNodesExternalSourceSuccess = (data) => (dispatch) => {
    // TODO: remove hardcoded values
    data = ["ONET", "HF1", "SE"];
    dispatch(fetchGetSystemNodesExternalSourceSuccess(data));
};
export const fetchGetSystemNodesExternalSources = createAction(
    "FETCH_GET_SYSTEM_NODES_EXTERNAL_SOURCES",
    () => Api.getSystemNodesExternalSources(),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSystemNodesExternalSourceSuccess,
        onFail: fetchGetSystemNodesExternalSourceFailed,
    })
);

export const refreshSystemNodesExternalSources = createAction(
    "REFRESH_SYSTEM_NODES_EXTERNAL_SOURCES",
    () => Api.getSystemNodesExternalSources(),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSystemNodesExternalSourceSuccess,
        onFail: fetchGetSystemNodesExternalSourceFailed,
    })
);

export const createNewNodeSuccess = createAction("CREATE_NEW_NODE_SUCCESS");

export const fetchCreateNewNode = createAction(
    "CREATE_NEW_NODE",
    (node) => Api.createNode(node),
    () => ({
        apiCall: true,
        onSuccess: createNewNodeSuccess,
        onFail: fetchGetSystemNodesExternalSourceFailed,
    })
);

//- State
const initialState = {
    nodesList: null,
    currentPage: null,
    totalPages: null,
    nameFilter: "",
    typeFilter: null,
    sourceFilter: null,
    statusFilter: null,
    domainFilter: null,
    sortingMode: NODE_SORTING.NAME,
    error: null,
    fetching: false,
    externalSources: null,
    externalSourcesFetching: false,
};

//- Reducers
export default handleActions(
    {
        FETCH_GET_SYSTEM_NODES_LIST: (state) => {
            return {
                ...state,
                nodesList: null,
                currentPage: null,
                totalPages: null,
                error: null,
                fetching: true,
            };
        },
        FETCH_GET_SYSTEM_NODES_LIST_FAILED: (state, action) => {
            return {
                ...state,
                nodesList: null,
                currentPage: null,
                totalPages: null,
                error: action.payload,
                fetching: false,
            };
        },

        FETCH_GET_SYSTEM_NODES_LIST_SUCCESS: (state, action) => {
            return {
                ...state,
                nodesList: action.payload.content,
                currentPage: action.payload.number,
                totalPages: action.payload.totalPages,
                error: null,
                fetching: false,
            };
        },
        UPDATE_SYSTEM_NODES_NAME_FILTER: (state, action) => {
            return { ...state, nameFilter: action.payload };
        },
        UPDATE_SYSTEM_NODES_TYPE_FILTER: (state, action) => {
            return { ...state, typeFilter: action.payload };
        },
        UPDATE_SYSTEM_NODES_SOURCE_FILTER: (state, action) => {
            return { ...state, sourceFilter: action.payload };
        },
        UPDATE_SYSTEM_NODES_STATUS_FILTER: (state, action) => {
            return { ...state, statusFilter: action.payload };
        },
        UPDATE_SYSTEM_NODES_DOMAIN_FILTER: (state, action) => {
            return { ...state, domainFilter: action.payload };
        },
        UPDATE_SYSTEM_NODES_SORTING: (state, action) => {
            return { ...state, sortingMode: action.payload };
        },
        FETCH_GET_SYSTEM_NODES_EXTERNAL_SOURCES: (state) => {
            return { ...state, externalSources: null, error: null, externalSourcesFetching: true };
        },
        FETCH_GET_SYSTEM_NODES_EXTERNAL_SOURCES_FAILED: (state, action) => {
            return {
                ...state,
                externalSources: null,
                error: action.payload,
                externalSourcesFetching: false,
            };
        },
        FETCH_GET_SYSTEM_NODES_EXTERNAL_SOURCES_SUCCESS: (state, action) => {
            return {
                ...state,
                externalSources: action.payload,
                error: null,
                externalSourcesFetching: false,
            };
        },
        REFRESH_SYSTEM_NODES_EXTERNAL_SOURCES: (state) => {
            return { ...state, error: null, externalSourcesFetching: false };
        },
    },
    initialState
);

//- Selectors
export const getSystemModelState = (state) => state.systemModel;

export const getSystemNodesList = (state) => getSystemModelState(state).nodesList;

export const getSkillNodesList = (state) =>
    getSystemNodesList(state)?.filter((node) => node.type === NODE_TYPE.SKILL);

export const getSystemNodesListCurrentPage = (state) => getSystemModelState(state).currentPage;

export const getSystemNodesListTotalPages = (state) => getSystemModelState(state).totalPages;

export const getSystemNodesListFetchingStatus = (state) => getSystemModelState(state).fetching;

export const getSystemNodesNameFilter = (state) => getSystemModelState(state).nameFilter;

export const getSystemNodesTypeFilter = (state) => getSystemModelState(state).typeFilter;

export const getSystemNodesSourceFilter = (state) => getSystemModelState(state).sourceFilter;

export const getSystemNodesStatusFilter = (state) => getSystemModelState(state).statusFilter;

export const getSystemNodesDomainFilter = (state) => getSystemModelState(state).domainFilter;

export const getSystemNodesSortingMode = (state) => getSystemModelState(state).sortingMode;

export const getSystemNodesExternalSources = (state) => getSystemModelState(state).externalSources;

export const getSystemNodesExternalSourcesFetchingStatus = (state) =>
    getSystemModelState(state).externalSourcesFetching;
