import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import _ from "lodash";

//- Actions
export const fetchSearchSkillsFailed = createAction("FETCH_SEARCH_SKILLS_FAILED");
export const fetchSearchSkillsSuccess = createAction("FETCH_SEARCH_SKILLS_SUCCESS");
export const cleanSearchSkills = createAction("CLEAN_SEARCH_SKILLS");
export const updateQuickfindSelectedCandidatesIds = createAction(
    "UPDATE_QUICKFIND_SELECTED_CANDIDATES_IDS"
);
export const updateSelectedJobsIds = createAction("UPDATE_SELECTED_JOBS_IDS");
export const updateSelectedSkills = createAction("UPDATE_SELECTED_SKILLS");
export const setSelectedSkillId = createAction("SET_SELECTED_SKILL_ID");
export const fetchQuickfindJobsFailed = createAction("FETCH_QUICKFIND_JOBS_FAILED");
export const fetchQuickfindJobsSuccess = createAction("FETCH_QUICKFIND_JOBS_SUCCESS");
export const fetchQuickfindApplicantsFailed = createAction("FETCH_QUICKFIND_APPLICANTS_FAILED");
export const fetchQuickfindApplicantsSuccess = createAction("FETCH_QUICKFIND_APPLICANTS_SUCCESS");
export const setQuickfindFormDistanceField = createAction("SET_QUICKFIND_FORM_DISTANCE_FIELD");
export const setQuickfindFormDistanceUnitField = createAction(
    "SET_QUICKFIND_FORM_DISTANCE_UNIT_FIELD"
);
export const setQuickfindFormCityField = createAction("SET_QUICKFIND_FORM_CITY_FIELD");
export const setQuickfindFormWorkRegimeField = createAction("SET_QUICKFIND_FORM_WORK_REGIME_FIELD");
export const setQuickfindFormExpectedWorkRegimeField = createAction(
    "SET_QUICKFIND_FORM_EXPECTED_WORK_REGIME_FIELD"
);
export const setQuickfindFormSalaryField = createAction("SET_QUICKFIND_FORM_SALARY_FIELD");
export const setQuickfindFormPreferredContractTypeField = createAction(
    "SET_QUICKFIND_FORM_CONTRACT_TYPE_FIELD"
);
export const setQuickfindFormCurrencyField = createAction("SET_QUICKFIND_FORM_CURRENCY_FIELD");

export const onFetchSearchSkillsSuccess = (data) => (dispatch) => {
    let formattedSkills = _.map(data.content, (skill) => {
        return {
            id: skill.id,
            name: skill.titles.en,
            category: skill.category,
        };
    });
    dispatch(fetchSearchSkillsSuccess(formattedSkills));
};

export const fetchSearchSkills = createAction(
    "FETCH_SEARCH_SKILLS",
    (filter) => Api.getSearchSkills(filter),
    () => ({
        apiCall: true,
        onSuccess: onFetchSearchSkillsSuccess,
        onFail: fetchSearchSkillsFailed,
    })
);

export const fetchQuickfindJobs = createAction(
    "FETCH_QUICKFIND_JOBS",
    (params) => Api.getQuickfindJobs(params),
    () => ({
        apiCall: true,
        onSuccess: fetchQuickfindJobsSuccess,
        onFail: fetchQuickfindJobsFailed,
    })
);

export const fetchQuickfindApplicants = createAction(
    "FETCH_QUICKFIND_APPLICANTS",
    (params) => Api.getQuickfindApplicants(params),
    () => ({
        apiCall: true,
        onSuccess: fetchQuickfindApplicantsSuccess,
        onFail: fetchQuickfindApplicantsFailed,
    })
);

export const onSearchSkills = (filter) => (dispatch) => {
    dispatch(fetchSearchSkills(filter));
};

export const onSearchJobs = (params) => (dispatch) => {
    dispatch(fetchQuickfindJobs(params));
};

export const onSearchApplicants = (params) => (dispatch) => {
    dispatch(fetchQuickfindApplicants(params));
};

export const onCandidatePress = (id) => (dispatch, getState) => {
    let selectedCandidatesIds = _.cloneDeep(getSelectedCandidatesIds(getState())) || [];
    if (_.includes(selectedCandidatesIds, id)) {
        selectedCandidatesIds = selectedCandidatesIds.filter((elem) => elem !== id);
        dispatch(updateQuickfindSelectedCandidatesIds(selectedCandidatesIds));
    } else {
        dispatch(updateQuickfindSelectedCandidatesIds([...selectedCandidatesIds, id]));
    }
};

export const onJobPress = (id) => (dispatch, getState) => {
    let selectedJobsIds = _.cloneDeep(getSelectedJobsIds(getState())) || [];
    if (_.includes(selectedJobsIds, id)) {
        selectedJobsIds = selectedJobsIds.filter((elem) => elem !== id);
        dispatch(updateSelectedJobsIds(selectedJobsIds));
    } else {
        dispatch(updateSelectedJobsIds([...selectedJobsIds, id]));
    }
};

export const onAddSkill = (skill) => (dispatch, getState) => {
    const selectedSkills = _.cloneDeep(getSelectedSkills(getState())) || [];
    const isSkillExists = _.some(selectedSkills, (elem) => elem.id === skill.id);
    const skillToAdd = { ...skill, level: 1 };
    if (!isSkillExists) {
        dispatch(updateSelectedSkills([...selectedSkills, skillToAdd]));
    }
};

export const onRemoveSkill = (id) => (dispatch, getState) => {
    let selectedSkills = _.cloneDeep(getSelectedSkills(getState())) || [];
    selectedSkills = selectedSkills.filter((elem) => elem.id !== id);
    dispatch(updateSelectedSkills(selectedSkills));
};

export const updateSelectedSkillProperty = (currentSkillId, option) => (dispatch, getState) => {
    let selectedSkills = _.cloneDeep(getSelectedSkills(getState())) || [];
    let skill = _.find(selectedSkills, (elem) => elem.id === currentSkillId);
    skill["level"] = option.value;
    dispatch(updateSelectedSkills(selectedSkills));
};

//- State
const initialState = {
    searchSkills: [],
    jobs: [],
    applicants: [],
    error: null,
    fetchingSkills: false,
    fetchingJobs: false,
    fetchingApplicants: false,
    selectedCandidatesIds: [],
    selectedJobsIds: [],
    selectedSkills: [],
    selectedSkillId: "",
    distance: "",
    distanceUnit: null,
    city: "",
    workRegime: 0,
    expectedWorkRegime: 100,
    salary: "",
    preferredContractType: null,
    currency: null,
};

//- Reducers
export default handleActions(
    {
        FETCH_SEARCH_SKILLS: (state) => {
            return { ...state, searchSkills: [], error: null, fetchingSkills: true };
        },
        FETCH_SEARCH_SKILLS_FAILED: (state, action) => {
            return { ...state, searchSkills: [], error: action.payload, fetchingSkills: false };
        },
        FETCH_SEARCH_SKILLS_SUCCESS: (state, action) => {
            return { ...state, searchSkills: action.payload, error: null, fetchingSkills: false };
        },
        FETCH_QUICKFIND_JOBS: (state) => {
            return { ...state, jobs: [], error: null, fetchingJobs: true };
        },
        FETCH_QUICKFIND_JOBS_FAILED: (state, action) => {
            return { ...state, jobs: [], error: action.payload, fetchingJobs: false };
        },
        FETCH_QUICKFIND_JOBS_SUCCESS: (state, action) => {
            return { ...state, jobs: action.payload.content, error: null, fetchingJobs: false };
        },
        FETCH_QUICKFIND_APPLICANTS: (state) => {
            return { ...state, applicants: [], error: null, fetchingApplicants: true };
        },
        FETCH_QUICKFIND_APPLICANTS_SUCCESS: (state, action) => {
            return {
                ...state,
                applicants: action.payload.content,
                error: null,
                fetchingApplicants: false,
            };
        },
        FETCH_QUICKFIND_APPLICANTS_FAILED: (state, action) => {
            return { ...state, applicants: [], error: action.payload, fetchingApplicants: false };
        },
        UPDATE_QUICKFIND_SELECTED_CANDIDATES_IDS: (state, action) => {
            return { ...state, selectedCandidatesIds: action.payload };
        },
        UPDATE_SELECTED_JOBS_IDS: (state, action) => {
            return { ...state, selectedJobsIds: action.payload };
        },
        UPDATE_SELECTED_SKILLS: (state, action) => {
            return { ...state, selectedSkills: action.payload };
        },
        SET_SELECTED_SKILL_ID: (state, action) => {
            return { ...state, selectedSkillId: action.payload };
        },
        CLEAN_SEARCH_SKILLS: (state) => {
            return { ...state, searchSkills: [] };
        },
        SET_QUICKFIND_FORM_DISTANCE_FIELD: (state, action) => {
            return { ...state, distance: action.payload };
        },
        SET_QUICKFIND_FORM_DISTANCE_UNIT_FIELD: (state, action) => {
            return { ...state, distanceUnit: action.payload };
        },
        SET_QUICKFIND_FORM_CITY_FIELD: (state, action) => {
            return { ...state, city: action.payload };
        },
        SET_QUICKFIND_FORM_WORK_REGIME_FIELD: (state, action) => {
            return { ...state, workRegime: action.payload };
        },
        SET_QUICKFIND_FORM_EXPECTED_WORK_REGIME_FIELD: (state, action) => {
            return { ...state, expectedWorkRegime: action.payload };
        },
        SET_QUICKFIND_FORM_SALARY_FIELD: (state, action) => {
            return { ...state, salary: action.payload };
        },
        SET_QUICKFIND_FORM_CONTRACT_TYPE_FIELD: (state, action) => {
            return { ...state, preferredContractType: action.payload };
        },
        SET_QUICKFIND_FORM_CURRENCY_FIELD: (state, action) => {
            return { ...state, currency: action.payload };
        },
    },
    initialState
);

//- Selectors
export const getQuickfindState = (state) => state.quickfind;

export const getSearchSkills = (state) => getQuickfindState(state).searchSkills;

export const getSelectedCandidatesIds = (state) => getQuickfindState(state).selectedCandidatesIds;

export const getSelectedJobsIds = (state) => getQuickfindState(state).selectedJobsIds;

export const getSelectedSkills = (state) => getQuickfindState(state).selectedSkills;

export const getSelectedSkillId = (state) => getQuickfindState(state).selectedSkillId;

export const getFetchApplicantsLoading = (state) => getQuickfindState(state).fetchingApplicants;

export const getApplicants = (state) => getQuickfindState(state).applicants;

export const getQuickfindFormValues = (state) => ({
    distance: getQuickfindState(state).distance,
    distanceUnit: getQuickfindState(state).distanceUnit,
    city: getQuickfindState(state).city,
    workRegime: getQuickfindState(state).workRegime,
    expectedWorkRegime: getQuickfindState(state).expectedWorkRegime,
    salary: getQuickfindState(state).salary,
    preferredContractType: getQuickfindState(state).preferredContractType,
    currency: getQuickfindState(state).currency,
});

export const getFetchJobsLoading = (state) => getQuickfindState(state).fetchingJobs;

export const getJobs = (state) => getQuickfindState(state).jobs;

export const getSelectedStatus = (state, userId) => {
    let selectedCandidatesIds = getSelectedCandidatesIds(state);
    return _.includes(selectedCandidatesIds, userId);
};
