import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Label, Modal, ModalBody, ModalFooter } from "reactstrap";
import _ from "lodash";
import CustomSelect from "../../../../common/inputs/customSelect/CustomSelect";
import { USER_SUB_ROLE } from "../../../../../constants/constants";
import "./AddEmployeeToDepartmentModal.scss";

class AddEmployeeToDepartmentModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRole: null,
            selectedEmployee: null,
        };
    }

    componentDidMount() {
        this.props.getAllEmployees();
        this.props.getInitialStaffList();
    }

    render() {
        const { isOpen, onClose, translation, employees, addEmployeeToDepartment, department } =
            this.props;

        const { selectedRole, selectedEmployee } = this.state;

        const rolesOptions = [
            { value: USER_SUB_ROLE.HM, label: _.get(translation, "roles.hm_label") },
            { value: USER_SUB_ROLE.BO, label: _.get(translation, "roles.bo_label") },
        ];
        return (
            <Modal isOpen={isOpen} toggle={onClose}>
                <ModalBody>
                    <div className="dropdown-wrapper with-bottom-margin">
                        <Label>{`${_.get(translation, "shared.role")}*`}</Label>
                        <CustomSelect
                            value={
                                selectedRole
                                    ? _.find(
                                          rolesOptions,
                                          (option) => option.value === selectedRole
                                      )
                                    : null
                            }
                            options={rolesOptions}
                            onChange={(option) => this.setState({ selectedRole: option.value })}
                        />
                    </div>
                    <div className="dropdown-wrapper">
                        <Label>{`${_.get(translation, "shared.employee")}*`}</Label>
                        <CustomSelect
                            value={
                                selectedEmployee
                                    ? _.find(
                                          employees,
                                          (option) => option.value === selectedEmployee.value
                                      )
                                    : null
                            }
                            options={employees}
                            onChange={(option) => this.setState({ selectedEmployee: option })}
                        />
                    </div>
                </ModalBody>
                <ModalFooter className="content-space-between">
                    <Button color="secondary" onClick={onClose}>
                        {_.get(translation, "actions.cancel_button")}
                    </Button>
                    <Button
                        color="primary"
                        disabled={!selectedRole || !selectedEmployee}
                        onClick={() => {
                            const data = {
                                employeeId: selectedEmployee.data.id,
                                role: selectedRole,
                            };

                            addEmployeeToDepartment({
                                departmentId: department[0].id,
                                data,
                                employeeId: this.state.selectedEmployee.data.id,
                            });
                            onClose();
                        }}
                    >
                        {_.get(translation, "actions.add_label")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

AddEmployeeToDepartmentModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    getAllEmployees: PropTypes.func.isRequired,
    addEmployeeToDepartment: PropTypes.func.isRequired,
    employees: PropTypes.array.isRequired,
    department: PropTypes.object,
};

export default AddEmployeeToDepartmentModal;
