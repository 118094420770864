import { connect } from 'react-redux';
import ActivateAccount from './ActivateAccount';
import { getTranslation } from '../../../../reducers/common/localization';
import {
    fetchAccountToActivateData,
    getAccountToActivateData,
    getAccountToActivateFetchingStatus,
    getAccountToActivateError
} from '../../../../reducers/authorization/activation';
import { getRequestSuccessStatus } from '../../../../reducers/common/requestStatus';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        data: getAccountToActivateData(state),
        loading: getAccountToActivateFetchingStatus(state),
        tokenError: getAccountToActivateError(state),
        activationSuccess: getRequestSuccessStatus(state)
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onGetAccountToActivateData: (token) => dispatch(fetchAccountToActivateData(token)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount);
