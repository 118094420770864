import { connect } from "react-redux";
import PostJobSecondStep from "./PostJobSecondStep";
import { getTranslation } from "../../../../../reducers/common/localization";
import { getStaffListData } from "../../../../../reducers/organization/staffList";
import { getUserData, getUserId } from "../../../../../reducers/user/userData";
import { getLocationData } from "../../../../../reducers/common/location";
import {
    MODAL_ADD_JOB_REQUIRED_SKILLS,
    showModal,
} from "../../../../../reducers/common/activeModal";
import { getJobSkillsData, getJobSkillsDataTree } from "../../../../../reducers/job/jobSkills";
import { getJobProfilesData } from "../../../../../reducers/job/jobProfiles";
import { getApplicantProfilesInitialFetchingStatus } from "../../../../../reducers/applicant/applicantProfiles";
import { getSearchProfilesFetchingStatus } from "../../../../../reducers/profiles/searchProfiles";
import {
    getApplicantCurrentSkillData,
    getApplicantCurrentSkillId,
    getApplicantSkillsLQGraphData,
} from "../../../../../reducers/applicant/applicantSkills";
import { getSuggestedProfilesFetchingStatus } from "../../../../../reducers/profiles/suggestedProfiles";
import { getApplicantPastJobsData } from "../../../../../reducers/applicant/applicantPastJobs";
import {
    fetchStaticJobViewData,
    getJobData,
} from "../../../../../reducers/staticViews/staticViews";

const mapStateToProps = (state) => ({
    translation: getTranslation(state),
    staffList: getStaffListData(state),
    userData: getUserData(state),
    locationData: getLocationData(state),
    skills: getJobSkillsData(state),
    skillsTree: getJobSkillsDataTree(state),
    profiles: getJobProfilesData(state),
    userId: getUserId(state),
    imageSource: "",
    loadingProfiles: getApplicantProfilesInitialFetchingStatus(state),
    loadingSearchProfiles: getSearchProfilesFetchingStatus(state),
    loadingSuggestedProfiles: getSuggestedProfilesFetchingStatus(state),
    lqGraphData: getApplicantSkillsLQGraphData(state),
    currentSkillId: getApplicantCurrentSkillId(state),
    currentSkillData: getApplicantCurrentSkillData(state),
    pastJobs: getApplicantPastJobsData(state),
    jobData: getJobData(state),
});

const mapDispatchToProps = (dispatch) => ({
    onMount: (hash) => dispatch(fetchStaticJobViewData(hash)),

    onAddJobRequiredSkillsJob: () => dispatch(showModal(MODAL_ADD_JOB_REQUIRED_SKILLS)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostJobSecondStep);
