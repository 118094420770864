import { connect } from 'react-redux';
import UserPhoto, { USER_PHOTO_MODE } from './UserPhoto';
import {
    getOpenJobAnonymityMode,
    onStartDragCandidate,
    onFinishDragCandidate
} from '../../../reducers/job/openJob';
import {
    onSelectCandidate,
    getCandidateSelectedStatus,
    getCandidatesSelectionMode
} from '../../../reducers/job/selectedCandidates';

const mapStateToProps = (state, { userId, mode }) => {
    if (mode === USER_PHOTO_MODE.COMPARISON) {
        return {
            isUserSelected: getCandidateSelectedStatus(state, userId),
            selectionMode: getCandidatesSelectionMode(state),
            anonymityMode: getOpenJobAnonymityMode(state)
        };
    } return {};
}

const mapDispatchToProps = (dispatch, { mode }) => {
    if (mode === USER_PHOTO_MODE.COMPARISON) {
        return {
            onPhotoClick: (userId) => dispatch(onSelectCandidate(userId)),
            onStartDragCandidate: () => dispatch(onStartDragCandidate()),
            onFinishDragCandidate: () => dispatch(onFinishDragCandidate()),
        };
    } return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPhoto);
