import React, { Component } from "react";
import PropTypes from "prop-types";
import "./JobBall.scss";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import Icon from "../../../../common/icon/Icon";
import Logo from "./../../../../../images/logo.png";
import RedCross from "./../../../../../images/red_cross.png";

class JobBall extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.componentRef = React.createRef();
    }

    calculateJobsPosition = () => {};

    render() {
        const {
            possibleFuture,
            id,
            activeJobId,
            setActiveJobId,
            jobTitle,
            company,
            marginTop,
            marginBottom,
            daysFromApply,
            daysToDeadline,
            feedback,
            disabled,
            jobAppId,
        } = this.props;

        const isActiveJob = !disabled && activeJobId === id;

        return (
            <>
                <span
                    className={`job-ball-container ${disabled ? "disabled" : ""}`}
                    style={
                        disabled
                            ? {}
                            : { marginTop: `${marginTop}rem`, marginBottom: `${marginBottom}rem` }
                    }
                >
                    <svg
                        height="50"
                        width="50"
                        ref={this.componentRef}
                        onClick={() => setActiveJobId(id)}
                    >
                        {possibleFuture && (
                            <circle className="job-circle possible-future" cx="25" cy="25" r="22" />
                        )}
                        <circle
                            className={`job-circle ${isActiveJob ? "active" : ""}`}
                            cx="25"
                            cy="25"
                            r="20"
                        />
                        <text
                            x="50%"
                            y="54%"
                            textAnchor="middle"
                            stroke="black"
                            fontSize="10px"
                            strokeWidth="0.6px"
                            letterSpacing="0.4px"
                        >{`${daysFromApply}/${daysToDeadline}`}</text>
                    </svg>
                    {!disabled && (
                        <>
                            <img className="company-logo" src={Logo} alt={company} />{" "}
                            {/* TODO: switch on company logo image */}
                            {feedback?.length && (
                                <Icon
                                    type="red_notification"
                                    number={feedback?.length}
                                    className="red-notification-icon"
                                    x={40}
                                    y={60}
                                    rx={10}
                                    ry={10}
                                    r={10}
                                    numberSize={13}
                                />
                            )}
                            {/* <Icon
                                color="danger"
                                type="clear"
                                onClick={() => console.log("remove from view")}
                                className="remove-from-view-icon"
                            /> */}
                            <img
                                className="remove-from-view-icon"
                                src={RedCross}
                                alt="Remove from view"
                            />
                            <Icon
                                type={`${this.props.favorite ? "" : "empty_"}heart`}
                                onClick={() =>
                                    this.props.makeJobFavorite(jobAppId, !this.props.favorite)
                                }
                                className="add-to-favorite-jobs"
                            />
                        </>
                    )}
                </span>
                {!disabled && (
                    <UncontrolledPopover
                        target={this.componentRef}
                        placement={"top"}
                        trigger={"hover"}
                        boundariesElement="window"
                    >
                        <PopoverBody>
                            <div>{`${jobTitle} / ${company}`}</div>
                        </PopoverBody>
                    </UncontrolledPopover>
                )}
            </>
        );
    }
}

JobBall.propTypes = {
    translation: PropTypes.object.isRequired,
};

export default JobBall;
