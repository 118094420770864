import { connect } from 'react-redux';
import ActivateEmployeeAccount from './ActivateEmployeeAccount';
import { getTranslation } from '../../../../reducers/common/localization';
import {
    getAccountToActivateData,
    fetchActivateAccount,
} from '../../../../reducers/authorization/activation';
import { showModal, MODAL_LICENSE_AGREEMENT } from '../../../../reducers/common/activeModal';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        data: getAccountToActivateData(state),
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onActivateAccount: (data, token) => dispatch(fetchActivateAccount(data, token)),
        onShowLicenseAgreement: () => {
            dispatch(showModal(MODAL_LICENSE_AGREEMENT));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateEmployeeAccount);
