import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import _ from 'lodash';
import { NODE_SORTING, NODE_TYPE, NODE_STATUS } from '../../constants/systemNodesConstants';

//- Actions
export const fetchGetClosestSkillsFailed = createAction('FETCH_GET_CLOSEST_SKILLS_DATA_FAILED');
export const fetchGetClosestSkillsSuccess = createAction('FETCH_GET_CLOSEST_SKILLS_DATA_SUCCESS');
export const onFetchGetClosestSkillsSuccess = (data) => (dispatch, getState) => {
    data.content = _.map(data.content, (skill, id) => {
        return {
            id: skill.id,
            skill: skill.titles.en,
            categoryId: skill.category.id,
            categoryTitle: skill.category.titles.en,
            // TODO: remove hardcoded values
            type: NODE_TYPE.SKILL,
            status: NODE_STATUS.REFERENCE_MODEL,
            source: _.sample(["Admin", "User", "O*Net", "VDAB"])
        }
    });
    dispatch(fetchGetClosestSkillsSuccess(data));
}
export const fetchGetClosestSkills = createAction(
    'FETCH_GET_CLOSEST_SKILLS_DATA',
    (params, page) => Api.getClosestSkills(params, page),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetClosestSkillsSuccess,
        onFail: fetchGetClosestSkillsFailed
    })
);

export const onFetchGetClosestSkills = (params = {}) => (dispatch, getState) => {
    let nameFilter = getClosestSkillsNameFilter(getState());
    let typeFilter = getClosestSkillsTypeFilter(getState());
    let sourceFilter = getClosestSkillsSourceFilter(getState());
    let sortingMode = getClosestSkillsSortingMode(getState());
    let listParams = {
        nameFilter: !_.isUndefined(params.nameFilter) ? params.nameFilter : nameFilter,
        typeFilter: !_.isUndefined(params.typeFilter) ? params.typeFilter : typeFilter,
        sourceFilter: !_.isUndefined(params.sourceFilter) ? params.sourceFilter : sourceFilter,
        sortingMode: !_.isUndefined(params.sortingMode) ? params.sortingMode : sortingMode
    }
    dispatch(fetchGetClosestSkills(listParams, params.page));
}

export const onFetchGetClosestSkillsByPage = (page) => (dispatch) => {
    dispatch(onFetchGetClosestSkills({ page: page }));
}

export const updateClosestSkillsNameFilter = createAction('UPDATE_CLOSEST_SKILLS_NAME_FILTER');
export const updateClosestSkillsTypeFilter = createAction('UPDATE_CLOSEST_SKILLS_TYPE_FILTER');
export const updateClosestSkillsSourceFilter = createAction('UPDATE_CLOSEST_SKILLS_SOURCE_FILTER');
export const updateClosestSkillsTypeAndSourceFilter = (typeFilter, sourceFilter) => (dispatch) => {
    dispatch(updateClosestSkillsTypeFilter(typeFilter));
    dispatch(updateClosestSkillsSourceFilter(sourceFilter));
    let params = {
        typeFilter: typeFilter,
        sourceFilter: sourceFilter,
    }
    dispatch(onFetchGetClosestSkills(params));
}
export const updateClosestSkillsSortingMode = createAction('UPDATE_CLOSEST_SKILLS_SORTING');
export const onUpdateClosestSkillsSortingMode = (sortingMode) => (dispatch) => {
    dispatch(updateClosestSkillsSortingMode(sortingMode));
    dispatch(onFetchGetClosestSkills({ sortingMode: sortingMode }));
}

export const updateAddedClosestSkills = createAction('UPDATE_ADDED_CLOSEST_SKILLS');
export const onAddRelationsWithSkills = (nodesToAdd) => (dispatch, getState) => {
    let addedNodes = getAddedClosestSkillsData(getState()) || [];
    dispatch(updateAddedClosestSkills(_.concat(addedNodes, nodesToAdd)));
}

export const addRelationWithSkill = (nodeId) => (dispatch, getState) => {
    let data = getClosestSkillsData(getState());
    let addedNodes = _.cloneDeep(getAddedClosestSkillsData(getState())) || [];
    let nodeToAdd = _.find(data, (node) => node.id === nodeId);
    addedNodes.push(nodeToAdd);
    dispatch(updateAddedClosestSkills(addedNodes));
}
export const undoAddRelationWithSkill = (nodeId) => (dispatch, getState) => {
    let addedNodes = getAddedClosestSkillsData(getState()) || [];
    addedNodes = _.filter(addedNodes, (node) => node.id !== nodeId);
    dispatch(updateAddedClosestSkills(addedNodes));
}

export const updateRemovedClosestSkills = createAction('UPDATE_REMOVED_CLOSEST_SKILLS');
export const removeRelationWithSkill = (nodeId) => (dispatch, getState) => {
    let data = getClosestSkillsData(getState());
    let removedNodes = _.cloneDeep(getRemovedClosestSkillsData(getState())) || [];
    let nodeToRemove = _.find(data, (node) => node.id === nodeId);
    removedNodes.push(nodeToRemove);
    dispatch(updateRemovedClosestSkills(removedNodes));
}
export const undoRemoveRelationWithSkill = (nodeId) => (dispatch, getState) => {
    let removedNodes = _.cloneDeep(getRemovedClosestSkillsData(getState())) || [];
    removedNodes = _.filter(removedNodes, (node) => node.id !== nodeId);
    dispatch(updateRemovedClosestSkills(removedNodes));
}

//- State
const initialState = {
    data: null,
    currentPage: null,
    totalPages: null,
    addedSkills: null,
    removedSkills: null,
    error: null,
    fetching: false,
    nameFilter: "",
    typeFilter: null,
    sourceFilter: null,
    sortingMode: NODE_SORTING.NAME,
};

//- Reducers
export default handleActions({

    FETCH_GET_CLOSEST_SKILLS_DATA: (state) => {
        return { ...state, data: null, currentPage: null, totalPages: null, error: null, fetching: true };
    },
    FETCH_GET_CLOSEST_SKILLS_DATA_FAILED: (state, action) => {
        return { ...state, data: null, currentPage: null, totalPages: null, error: action.payload, fetching: false };
    },
    FETCH_GET_CLOSEST_SKILLS_DATA_SUCCESS: (state, action) => {
        return { ...state, data: action.payload.content, currentPage: action.payload.number, totalPages: action.payload.totalPages, error: null, fetching: false };
    },
    UPDATE_ADDED_CLOSEST_SKILLS: (state, action) => {
        return { ...state, addedSkills: action.payload };
    },
    UPDATE_REMOVED_CLOSEST_SKILLS: (state, action) => {
        return { ...state, removedSkills: action.payload };
    },
    UPDATE_CLOSEST_SKILLS_NAME_FILTER: (state, action) => {
        return { ...state, nameFilter: action.payload };
    },
    UPDATE_CLOSEST_SKILLS_TYPE_FILTER: (state, action) => {
        return { ...state, typeFilter: action.payload };
    },
    UPDATE_CLOSEST_SKILLS_SOURCE_FILTER: (state, action) => {
        return { ...state, sourceFilter: action.payload };
    },
    UPDATE_CLOSEST_SKILLS_SORTING: (state, action) => {
        return { ...state, sortingMode: action.payload };
    },
}, initialState);

//- Selectors
export const getClosestSkillsState = state => state.closestSkills;

export const getClosestSkillsData = state => getClosestSkillsState(state).data;

export const getClosestSkillsCurrentPage = state => getClosestSkillsState(state).currentPage;

export const getClosestSkillsTotalPages = state => getClosestSkillsState(state).totalPages;

export const getClosestSkillsFetchingStatus = state => getClosestSkillsState(state).fetching;

export const getAddedClosestSkillsData = state => getClosestSkillsState(state).addedSkills;

export const getRemovedClosestSkillsData = state => getClosestSkillsState(state).removedSkills;

export const getClosestSkillsNameFilter = state => getClosestSkillsState(state).nameFilter;

export const getClosestSkillsTypeFilter = state => getClosestSkillsState(state).typeFilter;

export const getClosestSkillsSourceFilter = state => getClosestSkillsState(state).sourceFilter;

export const getClosestSkillsSortingMode = state => getClosestSkillsState(state).sortingMode;
