
import React, { Component } from 'react';

class FolderAdd extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24">
                <path className="icon-white-path" d="M11,7.2l-0.7-0.7L8.6,4.8H2.4v14.4h19.2v-12H11z M14.4,9.6h2.4V12h2.4v2.4h-2.4v2.4h-2.4v-2.4H12V12h2.4V9.6z" />
                <path d="M21.6,4.8H12L9.6,2.4H2.4C1.1,2.4,0,3.5,0,4.8l0,14.4c0,1.3,1.1,2.4,2.4,2.4h19.2c1.3,0,2.4-1.1,2.4-2.4v-12 C24,5.9,22.9,4.8,21.6,4.8z M21.6,19.2H2.4V4.8h6.2l1.7,1.7L11,7.2h10.6V19.2z" />
                <path d="M12,14.4h2.4v2.4h2.4v-2.4h2.4V12h-2.4V9.6h-2.4V12H12V14.4z" />
            </svg>
        );
    }
}

export default FolderAdd;
