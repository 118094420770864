import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import _ from "lodash";

class JobEditModal extends Component {
    render() {
        const { onClose, isOpen, translation } = this.props;

        return (
            <Modal centered isOpen={isOpen} toggle={onClose} className="compare-modal">
                <ModalHeader>
                    <span className="text-center">
                        {_.get(translation, "shared.rich_media_label")}
                    </span>
                </ModalHeader>
                <ModalBody></ModalBody>
            </Modal>
        );
    }
}

export default JobEditModal;
