import { connect } from 'react-redux';
import RestoreAccount from './RestoreAccount';
import { getTranslation } from '../../../../reducers/common/localization';
import { fetchRestoreAccount, getAccountToRestoreEmail } from '../../../../reducers/authorization/restoreAccount';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        email: getAccountToRestoreEmail(state)
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onRestoreAccount: (email) => dispatch(fetchRestoreAccount(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestoreAccount);
