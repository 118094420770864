import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CandidateStack.scss';
import _ from 'lodash';
import { Badge, FormGroup, Label, Input, Collapse } from 'reactstrap';
import { Utils } from '../../../../../utils/utils';
import Icon from '../../../../common/icon/Icon';
import {
    SORTING_MODE,
    CANDIDATE_GROUP,
    SELECTION_MODE,
    SORTING_MODE_TITLE,
    SORTING_MODES_LIST,
    SORTING_MODE_ICON
} from '../../../../../constants/candidatesComparisonConstants';
import UserPhoto from '../../../../common/userPhoto/UserPhoto.container';
import { USER_PHOTO_MODE } from '../../../../common/userPhoto/UserPhoto';
import BrainChainCheckMark from '../../../../common/brainChainCheckMark/BrainChainCheckMark.container';

class CandidateStack extends Component {
    render() {
        return (
            <div className="candidates-stack">
                <h6 className="text-center">{_.get(this.props.translation, "candidates_comparison_page.candidates_stack_label")}</h6>
                <div className="candidates-number">
                    <Badge pill>
                        {this.props.leftAmount}
                    </Badge>
                    {_.get(this.props.translation, 'candidates_comparison_page.candidates_left_number_label')}
                    <Badge pill>
                        {this.props.totalAmount}
                    </Badge>
                </div>
                <FormGroup check>
                    <Label sm="12" check>
                        <Input
                            type="checkbox"
                            checked={this.props.isPinTopAndFavs}
                            onChange={this.handleChangePinTopAndFavs}
                        />
                        {Utils.getTagsFromStringWithStyles(
                            _.get(this.props.translation, 'candidates_comparison_page.pin_top_and_favs_atop_label')
                        )}
                    </Label>
                </FormGroup>
                <div className="sorting-menu">
                    <h6>
                        {_.get(this.props.translation, 'actions.sort_by_label')}
                    </h6>
                    <div className="sorting-menu-buttons">
                        {
                            _.map(SORTING_MODES_LIST, (mode) => {
                                return (
                                    <div
                                        key={mode}
                                        className={`sorting-menu-button ${this.props.sortingMode === mode ? "active" : ''}`}
                                        onClick={this.handleChangeSortingMode(mode)}
                                        title={_.get(this.props.translation, SORTING_MODE_TITLE[mode])}
                                    >
                                        <Icon type={`${SORTING_MODE_ICON[mode]}${this.props.sortingMode !== mode ? "_outline" : ""}`} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <Collapse className="hs-ss-ratio-collapse" isOpen={this.props.sortingMode === SORTING_MODE.COMBINED}>
                    <div className="hs-ss-ratio-container">
                        <Icon type="gear" size="sm" title={_.get(this.props.translation, "job_and_candidates.hard_skills_label")} />
                        <input
                            className="hs-ss-ratio-input"
                            type="range"
                            min="-5"
                            max="5"
                            value={this.props.combinedSortingRatio}
                            onChange={this.handleChangeCombinedSortRatio}
                        />
                        <Icon type="brain" size="sm" title={_.get(this.props.translation, "job_and_candidates.soft_skills_label")} />
                    </div>
                </Collapse>
                <div className="candidates-list">
                    {this.renderCandidates()}
                </div>
                <FormGroup check>
                    <Label sm="12" check>
                        <Input
                            type="checkbox"
                            checked={this.props.anonymityMode}
                            onChange={this.handleChangeAnonymityMode}
                        />
                        {_.get(this.props.translation, 'job_and_candidates.anonymity_mode_label')}
                    </Label>
                </FormGroup>
            </div>
        );
    }

    renderCandidates() {
        if (this.props.loading && !this.props.data) {
            return (
                <h6 className="text-center">{_.get(this.props.translation, "actions.fetching_message")}</h6>
            )
        }

        if (!this.props.data || this.props.data.length === 0) {
            return (
                <h6 className="text-center">{_.get(this.props.translation, "candidate.no_candidates_label")}</h6>
            )
        }

        return _.map(this.props.data, (candidateData, id) => {
            return (
                <div key={id} className="candidate-card">
                    <div className="candidate-photo-container">
                        <UserPhoto
                            mode={USER_PHOTO_MODE.COMPARISON}
                            userId={candidateData.user.id}
                            // TODO: remove hardcoded values
                            imageSource=""
                            isDraggable
                            isClickable
                            size="lg"
                        />
                        {
                            candidateData.applicantMetaData.favorite ?
                                <Icon
                                    className="favorite-icon"
                                    type="star"
                                    size="lg"
                                    title={_.get(this.props.translation, 'candidates_comparison_page.remove_from_favorite_label')}
                                    onClick={this.handleRemoveFromFavorite(candidateData.user.id)}
                                />
                                :
                                <Icon
                                    className="favorite-icon"
                                    type="star_outline"
                                    size="lg"
                                    title={_.get(this.props.translation, 'candidates_comparison_page.add_to_favorite_label')}
                                    onClick={this.handleAddToFavorite(candidateData.user.id)}
                                />
                        }
                        {
                            candidateData.applicantMetaData.groupId === CANDIDATE_GROUP.TOP_X ?
                                <Icon
                                    className="top-x-icon"
                                    type="best_mark"
                                    size="lg"
                                    title={_.replace(_.get(this.props.translation, 'candidates_comparison_page.top_x_candidate_label'), "{x}", this.props.plannedTopAmount)}
                                />
                                :
                                null
                        }
                        <Icon
                            className="candidate-notes-icon"
                            type={candidateData.applicantMetaData.notes ? "microphone" : "microphone_outline"}
                            size="lg"
                            onClick={this.handleCandidateNotesClick(candidateData.user.id)}
                            title={_.get(this.props.translation, 'candidates_comparison_page.notes_about_candidate_label')}
                        />
                        <BrainChainCheckMark
                            userId={candidateData.user.id}
                            qualityCheck={candidateData.user.qualityCheck}
                        />
                    </div>
                    <div className={`candidate-name ${this.isCandidatesSelected(candidateData.user.id) ? "selected" : ""}`}>
                        {this.getUserName(candidateData)}
                    </div>
                </div>
            )
        });
    }

    getUserName = (candidateData) => {
        if (this.props.anonymityMode) {
            return Utils.getUserAnonymousNameByUUID(candidateData.user.id);
        } else {
            return candidateData.user.alias.firstName + " " + candidateData.user.alias.lastName;
        }
    }

    isCandidatesSelected = (userId) => {
        switch (this.props.selectionMode) {
            case SELECTION_MODE.SINGLE:
                return userId === this.props.currentCandidateId;

            case SELECTION_MODE.MULTIPLE:
                return _.includes(this.props.selectedCandidatesIds, userId);

            default:
                return false;
        }
    }

    handleChangePinTopAndFavs = () => {
        this.props.onUpdateOpenJobPinTopAndFavs(!this.props.isPinTopAndFavs);
    }

    handleChangeSortingMode = (mode) => () => {
        if (this.props.sortingMode !== mode) {
            this.props.onChangeSortingMode(mode);
        }
    }

    handleChangeCombinedSortRatio = (e) => {
        this.props.onChangeCombinedSortingRatio(parseInt(e.target.value));
    }

    handleChangeAnonymityMode = (e) => {
        this.props.onChangeAnonymityMode(!this.props.anonymityMode);
    }

    handleCandidateNotesClick = (userId) => () => {
        this.props.onCandidateNotesClick(userId);
    }

    handleRemoveFromFavorite = (userId) => () => {
        this.props.onRemoveFromFavorite(userId);
    }

    handleAddToFavorite = (userId) => () => {
        this.props.onAddToFavorite(userId);
    }
}

CandidateStack.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.array,
    loading: PropTypes.bool,
    leftAmount: PropTypes.number,
    totalAmount: PropTypes.number,
    isPinTopAndFavs: PropTypes.bool,
    sortingMode: PropTypes.string,
    combinedSortingRatio: PropTypes.number,
    anonymityMode: PropTypes.bool,
    plannedTopAmount: PropTypes.number,
    selectionMode: PropTypes.string,
    currentCandidateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedCandidatesIds: PropTypes.array,
    onUpdateOpenJobPinTopAndFavs: PropTypes.func.isRequired,
    onChangeSortingMode: PropTypes.func.isRequired,
    onChangeCombinedSortingRatio: PropTypes.func.isRequired,
    onCandidateNotesClick: PropTypes.func.isRequired,
    onRemoveFromFavorite: PropTypes.func.isRequired,
    onAddToFavorite: PropTypes.func.isRequired,
}

export default CandidateStack;
