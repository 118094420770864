import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';

//- Actions
export const fetchGetOrganizationPricingByAnchorFailed = createAction('FETCH_GET_ORG_PRICING_BY_ANCHOR_FAILED');
export const fetchGetOrganizationPricingByAnchorSuccess = createAction('FETCH_GET_ORG_PRICING_BY_ANCHOR_SUCCESS');
export const fetchGetOrganizationPricingByAnchor = createAction(
    'FETCH_GET_ORG_PRICING_BY_ANCHOR',
    (region) => Api.getOrgPricingByAnchor(region),
    () => ({
        apiCall: true,
        onSuccess: fetchGetOrganizationPricingByAnchorSuccess,
        onFail: fetchGetOrganizationPricingByAnchorFailed,
        ignoreUnauthorized: true,
        ignoreForbidden: true
    })
);

//- State
const initialState = {
    data: null,
    error: null,
    fetching: false
};

//- Reducers
export default handleActions({

    FETCH_GET_ORG_PRICING_BY_ANCHOR: (state) => {
        return { ...state, data: null, error: null, fetching: true };
    },
    FETCH_GET_ORG_PRICING_BY_ANCHOR_FAILED: (state, action) => {
        return { ...state, data: null, error: action.payload, fetching: false };
    },
    FETCH_GET_ORG_PRICING_BY_ANCHOR_SUCCESS: (state, action) => {
        return { ...state, data: action.payload, error: null, fetching: false };
    }

}, initialState);


//- Selectors
export const getOrganizationPricingState = state => state.organizationPricing;

export const getOrganizationPricingData = state => getOrganizationPricingState(state).data;

export const getOrganizationPricingFetchingStatus = state => getOrganizationPricingState(state).fetching;
