import { connect } from "react-redux";
import App from "./App";
import { getTranslation } from "../../reducers/common/localization";
import { fetchGetUserData } from "../../reducers/user/userData";
import { fetchGetDomainList } from "../../reducers/admin/domainsAndSC";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetUserData: () => {
            dispatch(fetchGetUserData());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
