import { connect } from 'react-redux';
import WelcomePage from './WelcomePage';
import { getTranslation } from "../../../../reducers/common/localization";
import { getUserRole } from '../../../../reducers/user/userRole';
import { setIsWelcomePageRead } from '../../../../reducers/authorization/login';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        userRole: getUserRole(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onReadWelcomePage: () => {
            dispatch(setIsWelcomePageRead(true));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
