import React, { Component } from "react";
import PropTypes from "prop-types";
import "./SelectAccountType.scss";
import _ from "lodash";
import BackLink from "../../../common/buttons/backLink/BackLink.container";
import { ROUTES } from "../../../../constants/constants";
import { Button, Card, CardDeck, CardText } from "reactstrap";
import { Link } from "react-router-dom";
import { Utils } from "../../../../utils/utils";

class SelectAccountType extends Component {
    render() {
        return (
            <div className="select-account-type">
                <div className="back-link-container">
                    <BackLink
                        to={ROUTES.LOGIN}
                        label={_.get(this.props.translation, "login_page.back_to_login_page_label")}
                    />
                </div>
                <div className="select-account-type-form">
                    <h4 className="text-center">
                        {_.get(this.props.translation, "create_account_page.select_account_label")}
                    </h4>
                    <CardDeck>
                        <Card body className="text-center">
                            <CardText>
                                {Utils.getTagsFromStringWithStyles(
                                    _.get(
                                        this.props.translation,
                                        "create_account_page.create_applicant_account_label"
                                    )
                                )}
                            </CardText>
                            <Button
                                className="card-button"
                                color="primary"
                                tag={Link}
                                to={ROUTES.APPLICANT_REGISTRATION}
                            >
                                {_.get(this.props.translation, "roles.applicant_label")}
                            </Button>
                        </Card>
                        <Card body className="text-center">
                            <CardText>
                                {Utils.getTagsFromStringWithStyles(
                                    _.get(
                                        this.props.translation,
                                        "create_account_page.create_organization_account_label"
                                    )
                                )}
                            </CardText>
                            <Button
                                className="card-button"
                                color="primary"
                                tag={Link}
                                to={ROUTES.COMPANY_REGISTRATION}
                            >
                                {_.get(this.props.translation, "organization.organization_label")}
                            </Button>
                        </Card>
                    </CardDeck>
                </div>
                <div className="page-hint">
                    {_.get(this.props.translation, "create_account_page.more_info_label") + " "}
                    <a
                        href="https://www.HireForceOne.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.HireForceOne.com
                    </a>
                </div>
            </div>
        );
    }
}

SelectAccountType.propTypes = {
    translation: PropTypes.object.isRequired,
};

export default SelectAccountType;
