import React, { Component } from "react";
import PropTypes from "prop-types";
import "./PendingApplication.scss";
import JobBall from "./jobBall/JobBall.container";
import moment from "moment";
import _ from "lodash";
import Legend from "./Legend/Legend.container";
import { Button } from "reactstrap";

class PendingApplication extends Component {
    sortJobsByDeadline = (jobs) => {
        const beforeDeadlineJobs = [...jobs].filter(
            ({ deadline }) => moment().diff(moment(deadline), "days") <= 0
        );
        const afterDeadlineJobs = [...jobs].filter(
            ({ deadline }) => moment().diff(moment(deadline), "days") > 0
        );
        beforeDeadlineJobs.sort((a, b) => new Date(b.deadline) - new Date(a.deadline));
        afterDeadlineJobs.sort((a, b) => new Date(b.deadline) - new Date(a.deadline));

        return [beforeDeadlineJobs, afterDeadlineJobs];
    };
    filterJobsBySearchValue = (jobs) => {
        const { searchValue } = this.props;
        return searchValue !== ""
            ? [...jobs].filter(
                  ({ jobTitle, company }) =>
                      jobTitle.toLowerCase().includes(searchValue) ||
                      company.toLowerCase().includes(searchValue)
              )
            : [...jobs];
    };

    getJobDaysToDeadline = (deadline) => moment(deadline).diff(moment(), "days");

    getJobDaysFromApply = (appliedDate) => moment().diff(moment(appliedDate), "days");

    generateJobBalls = (jobBalls) => {
        return jobBalls.map(
            (
                {
                    createdAt,
                    deadline,
                    id,
                    possibleFuture,
                    jobTitle,
                    company,
                    feedback,
                    favorite,
                    jobAppId,
                },
                index,
                array
            ) => {
                const daysToDeadline = this.getJobDaysToDeadline(deadline);
                const daysFromApply = this.getJobDaysFromApply(createdAt.split("T")[0]);
                let marginTop = (index % 3) * 3;
                let marginBottom = 2;
                if (
                    index !== 0 &&
                    daysToDeadline === this.getJobDaysToDeadline(array[index - 1].deadline)
                ) {
                    marginTop = ((index - 1) % 3) * 3;
                    if (index % 3 === 0) {
                        marginTop = -3;
                    }
                }

                if (daysToDeadline === 0) {
                    marginBottom = 0;
                }

                return (
                    <JobBall
                        favorite={favorite}
                        jobAppId={jobAppId}
                        daysToDeadline={daysToDeadline}
                        daysFromApply={daysFromApply}
                        marginTop={marginTop}
                        marginBottom={marginBottom}
                        id={id}
                        possibleFuture={possibleFuture}
                        jobTitle={jobTitle}
                        company={company}
                        key={jobAppId}
                        feedback={feedback}
                    />
                );
            }
        );
    };
    render() {
        const { translation } = this.props;
        const [beforeDeadlineJobs, afterDeadlineJobs] = this.sortJobsByDeadline(
            this.filterJobsBySearchValue(
                this.props.jobUsers.map((jobUser) => ({
                    ...jobUser.job,
                    jobAppId: jobUser.jobAppId,
                }))
            )
        );
        return (
            <div className="inner-block-container">
                <h6 className="applied-jobs-title">
                    {_.get(translation, "applicant_jobs_page.pending_applications_label")}
                </h6>
                <div className="pending-application-container">
                    <div className="balls-container before-deadline">
                        {this.generateJobBalls(beforeDeadlineJobs)}
                        <div className="deadline-line-container">
                            <div>
                                {_.get(translation, "applicant_jobs_page.job_deadline_label")}
                            </div>
                            <div className="deadline-line" />
                        </div>
                    </div>
                    <div className="balls-container after-deadline">
                        {this.generateJobBalls(afterDeadlineJobs)}
                    </div>
                </div>

                <Button
                    size="sm"
                    color="danger"
                    style={{ margin: "2rem 0" }}
                    onClick={() => console.log("reset")}
                >
                    {/* TODO: send request to remove field "removeFromView" for all jobs */}
                    {_.get(translation, "applicant_jobs_page.reset_removed_jobs_label")}
                </Button>
                <div className="inner-block-container">
                    <Legend />
                </div>
            </div>
        );
    }
}

PendingApplication.propTypes = {
    translation: PropTypes.object.isRequired,
};

export default PendingApplication;
