import React, { Component } from "react";
import { Handle } from "rc-slider";
import moment from "moment";

export class Timeline extends Component {
    customHandle(props, timelineDuration, timelineStarted, className) {
        const { value, dragging, index, ...restProps } = props;
        const selectedDate = moment(
            this.getDateFromTimeline(value, timelineDuration, timelineStarted)
        ).format("YYYY MM");
        return (
            <Handle value={value} {...restProps}>
                <div className="inner">
                    <div className={`slider-tooltip active ${className || ""}`}>
                        <span className="slider-tooltip-inner">{selectedDate}</span>
                    </div>
                </div>
            </Handle>
        );
    }

    onChangeHandler(value, careerDuration, careerStarted) {
        const { setParentStateValue } = this.props;
        setParentStateValue(
            "selectedDateOnTimeline",
            this.getDateFromTimeline(value, careerDuration, careerStarted)
        );
    }

    getValueFromSelectedDate(date, timelineDuration, timelineStarted) {
        const value = ((date - timelineStarted) / timelineDuration) * 100;
        return value;
    }

    getDateFromTimeline(value, timelineDuration, timelineStarted) {
        const timePassedFromCareerStarted = (timelineDuration * value) / 100;
        return timelineStarted + timePassedFromCareerStarted;
    }
}
