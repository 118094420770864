import { connect } from "react-redux";
import NodesGraph from "./NodesGraph";
import { getTranslation } from "../../../../reducers/common/localization";
import {
    getSystemCurrentNodeId,
    getSystemCurrentNodeGraphData,
    getSystemCurrentNodeGraphDataFetchingStatus,
    onSetCurrentSystemNode,
    onSetCurrentNodeWithoutGraph,
} from "../../../../reducers/admin/systemCurrentNode";
import PropTypes from "prop-types";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        currentNodeId: getSystemCurrentNodeId(state),
        data: getSystemCurrentNodeGraphData(state),
        loading: getSystemCurrentNodeGraphDataFetchingStatus(state),
        selectedGraphNode: state.selectedGraphNode,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectNode: (nodeId, source) => {
            dispatch(onSetCurrentNodeWithoutGraph(nodeId, source));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NodesGraph);

