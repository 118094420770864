import { createAction, handleActions } from 'redux-actions';
import { SkillsUtils } from '../../utils/skillsUtils';
import _ from 'lodash';
import { fetchGetSuggestedProfiles } from '../profiles/suggestedProfiles';
import { fetchGetSuggestedSkills } from '../skillsAndClusters/suggestedSkills';
import { onFetchGetCurrentClusterSkillsForJob, cleanCurrentClusterSkills } from '../skillsAndClusters/currentClusterSkills';
import { getSuggestedClustersData } from '../skillsAndClusters/suggestedClusters';
import { DEFAULT_REQUIREMENT_COEFFICIENT } from '../../constants/skillsConstants';

//- Actions
export const setCurrentSkill = createAction('SET_CURRENT_SKILL');
export const onChangeCurrentSkill = (skillId, clusterId) => (dispatch) => {
    dispatch(setCurrentSkill(skillId));
    dispatch(onSetCurrentCluster(clusterId));
}

export const setCurrentCluster = createAction('SET_CURRENT_CLUSTER');
export const onSetCurrentCluster = (clusterId, flags = {}) => (dispatch, getState) => {
    let currentClusterId = getJobCurrentClusterId(getState());
    if (currentClusterId !== clusterId) {
        if (flags.isSuggestion) {
            let suggestedClusters = _.cloneDeep(getSuggestedClustersData(getState()));
            let emptyClusters = _.cloneDeep(getJobEmptyClustersData(getState())) || [];
            let clusterData = _.find(suggestedClusters, (cluster) => cluster.categoryId === clusterId)
            delete clusterData.isSuggestion;
            emptyClusters.push(clusterData);
            dispatch(updateJobEmptyClusters(emptyClusters));
        }
        dispatch(setCurrentCluster(clusterId));
        if (flags.isNotModerated) {
            dispatch(cleanCurrentClusterSkills());
        } else {
            dispatch(onFetchGetCurrentClusterSkillsForJob(clusterId));
        }
    }
}
export const onChangeCurrentCluster = (clusterId, flags) => (dispatch) => {
    dispatch(setCurrentSkill(null));
    dispatch(onSetCurrentCluster(clusterId, flags));
}

export const clearCurrentSkillAndCluster = () => (dispatch) => {
    dispatch(setCurrentSkill(null));
    dispatch(setCurrentCluster(null));
}

export const updateJobSkills = createAction('UPDATE_JOB_SKILLS');

export const addJobSkill = (skillData) => (dispatch, getState) => {
    let skills = getJobSkillsData(getState());
    let resultSkills = [...skills, skillData];

    skillData.requirementCoefficient = DEFAULT_REQUIREMENT_COEFFICIENT;

    dispatch(setCurrentSkill(skillData.id));
    let currentClusterId = getJobCurrentClusterId(getState());
    if (currentClusterId !== skillData.categoryId) {
        dispatch(onSetCurrentCluster(skillData.categoryId));
    }

    let emptyClusters = getJobEmptyClustersData(getState());
    if (_.find(emptyClusters, (cluster) => !cluster.isNotModerated && cluster.categoryId === skillData.categoryId)) {
        emptyClusters = _.filter(emptyClusters, (cluster) => cluster.categoryId !== skillData.categoryId);
        dispatch(updateJobEmptyClusters(emptyClusters));
    }

    dispatch(fetchGetSuggestedProfiles(resultSkills));
    dispatch(fetchGetSuggestedSkills(resultSkills));
    dispatch(updateJobSkills(resultSkills));
}

export const removeJobSkill = (skillId) => (dispatch, getState) => {
    let skills = getJobSkillsData(getState());
    let resultSkills = _.filter(skills, (skill) => skill.id !== skillId);

    let currentSkillId = getJobCurrentSkillId(getState());
    if (currentSkillId === skillId) {
        dispatch(clearCurrentSkillAndCluster());
    }

    dispatch(fetchGetSuggestedProfiles(resultSkills));
    dispatch(fetchGetSuggestedSkills(resultSkills));
    dispatch(updateJobSkills(resultSkills));
}

export const removeJobCluster = (clusterId) => (dispatch, getState) => {
    let emptyClusters = getJobEmptyClustersData(getState());
    let clusterData = _.find(emptyClusters, (cluster) => cluster.categoryId === clusterId);
    if (clusterData) {
        emptyClusters = _.filter(emptyClusters, (cluster) => cluster.categoryId !== clusterId);
        dispatch(updateJobEmptyClusters(emptyClusters));
    }
    if (!clusterData || clusterData.isNotModerated) {
        let skills = getJobSkillsData(getState());
        let resultSkills = _.filter(skills, (skill) => skill.categoryId !== clusterId);

        dispatch(fetchGetSuggestedProfiles(resultSkills));
        dispatch(fetchGetSuggestedSkills(resultSkills));
        dispatch(updateJobSkills(resultSkills));
    }

    let currentClusterId = getJobCurrentClusterId(getState());
    if (currentClusterId === clusterId) {
        dispatch(clearCurrentSkillAndCluster());
    }
}

export const updateJobSkillProperty = (key, value, mergeObject) => (dispatch, getState) => {
    let resultSkills = _.cloneDeep(getJobSkillsData(getState()));
    let currentSkillId = getJobCurrentSkillId(getState());
    let skill = _.find(resultSkills, (elem) => elem.id === currentSkillId);

    if (mergeObject) {
        skill[key] = { ...skill[key], ...value };
    } else {
        skill[key] = value;
    }

    dispatch(updateJobSkills(resultSkills));
}

export const updateJobEmptyClusters = createAction('UPDATE_JOB_EMPTY_CLUSTERS');

export const addEmptyCluster = (cluster) => (dispatch, getState) => {
    let emptyClusters = _.cloneDeep(getJobEmptyClustersData(getState())) || [];
    emptyClusters.push(cluster);
    dispatch(updateJobEmptyClusters(emptyClusters));
    dispatch(onSetCurrentCluster(cluster.categoryId, { isNotModerated: cluster.isNotModerated }));
}

export const initSkills = createAction('INIT_SKILLS');
export const initEmptySkills = () => (dispatch) => {
    dispatch(initSkills([]));
}

//- State
const initialState = {
    skillsData: null,
    currentSkillId: null,
    currentClusterId: null,
    emptyClusters: null
};

//- Reducers
export default handleActions({

    SET_CURRENT_SKILL: (state, action) => {
        return { ...state, currentSkillId: action.payload };
    },
    SET_CURRENT_CLUSTER: (state, action) => {
        return { ...state, currentClusterId: action.payload };
    },
    UPDATE_JOB_SKILLS: (state, action) => {
        return { ...state, skillsData: action.payload };
    },
    UPDATE_JOB_EMPTY_CLUSTERS: (state, action) => {
        return { ...state, emptyClusters: action.payload };
    },
    INIT_SKILLS: (state, action) => {
        return { ...state, skillsData: action.payload };
    },

}, initialState);

//- Selectors
export const getJobSkillsState = state => state.jobSkills;

export const getJobSkillsData = state => getJobSkillsState(state).skillsData;

export const getJobSkillsDataTree = state => {
    let skills = getJobSkillsData(state);
    if (!skills) {
        return null;
    }

    let suggestedClusters = getSuggestedClustersData(state) || [];
    let emptyClusters = getJobEmptyClustersData(state) || [];
    suggestedClusters = _.filter(suggestedClusters, (cluster) => {
        return !_.find(emptyClusters, (emptyCluster) => emptyCluster.categoryId === cluster.categoryId)
            && !_.find(skills, (skill) => skill.categoryId === cluster.categoryId)
    });
    _.forEach(suggestedClusters, (cluster) => cluster.isSuggestion = true);

    return SkillsUtils.createSkillsTree(skills, _.concat(emptyClusters, suggestedClusters));
};

export const getJobCurrentSkillId = state => getJobSkillsState(state).currentSkillId;

export const getJobCurrentSkillData = state => {
    let data = getJobSkillsData(state);
    let id = getJobCurrentSkillId(state);
    let skill = _.find(data, (elem) => elem.id === id);
    return skill;
};

export const getJobCurrentClusterId = state => getJobSkillsState(state).currentClusterId;

export const getJobCurrentClusterData = state => {
    let data = getJobSkillsData(state);
    let clusterId = getJobCurrentClusterId(state);
    let skill = _.find(data, (elem) => elem.categoryId === clusterId);
    if (skill) {
        return {
            categoryId: skill.categoryId,
            categoryTitle: skill.categoryTitle,
        };
    } else {
        let emptyClusters = getJobEmptyClustersData(state);
        return _.find(emptyClusters, (elem) => elem.categoryId === clusterId);
    }
};

export const getJobEmptyClustersData = state => getJobSkillsState(state).emptyClusters;

export const getJobClustersData = state => {
    let emptyClusters = getJobEmptyClustersData(state) || [];
    let skills = getJobSkillsData(state);
    let clusters = [...emptyClusters];

    _.mapValues(_.groupBy(skills, "categoryId"), (value) => {
        let firstChild = value[0];
        let cluster = {
            categoryId: firstChild.categoryId,
            categoryTitle: firstChild.categoryTitle,
        };
        clusters.push(cluster);
    });

    return clusters;
}