import { connect } from 'react-redux';
import CandidateCrossJobInfoModal from "./CandidateCrossJobInfoModal";
import { getTranslation } from "../../../../../../reducers/common/localization";
import { getActiveModalType, hideModal, MODAL_CANDIDATE_CROSS_JOB_INFO } from '../../../../../../reducers/common/activeModal';
import { getCandidateDataForActiveModal } from '../../../../../../reducers/job/openJob';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_CANDIDATE_CROSS_JOB_INFO,
        data: getCandidateDataForActiveModal(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => dispatch(hideModal())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateCrossJobInfoModal);
