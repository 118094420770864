import React, { Component } from "react";
import PropTypes from "prop-types";
import "./NodesFilters.scss";
import _ from "lodash";
import {
    NODE_STATUS_LABEL,
    NODE_TYPE_LABEL,
    NODE_TYPE_SHORT_LABEL,
    NODE_TYPE_LIST,
    NODE_STATUS_LIST,
    NODE_SOURCE_LABEL,
    NODE_SOURCE_LIST,
    NODE_SORTING_LABEL,
    NODE_SORTING_LIST,
    NODE_TYPE,
} from "../../../../constants/systemNodesConstants";
import Icon from "../../../common/icon/Icon";
import { Button, Input, InputGroup, InputGroupAddon, Col, Row } from "reactstrap";
import { components } from "react-select";
import CustomSelect from "../../../common/inputs/customSelect/CustomSelect";
import { SELECT_NODE_STATUS_STYLES, SELECT_MULTI_STYLES } from "../../../../constants/selectStyles";

class NodesFilters extends Component {
    state = {
        domain: null,
    };

    componentDidMount() {
        if (this.props.onMount) {
            this.props.onMount();
        }
    }

    componentWillUnmount() {
        if (this.props.onUnmount) {
            this.props.onUnmount();
        }
    }

    renderContent() {
        if (this.props.loadingExternalSources) {
            return <h6>{_.get(this.props.translation, "actions.fetching_message")}</h6>;
        }
        if (this.props.disableTypeFilter) {
            return (
                <>
                    <div className="filters-group">
                        {this.props.currentNodeData &&
                        this.props.currentNodeData.type !== NODE_TYPE.SKILL && (
                            <>
                                <Col sm="6" className="filters-column">
                                    {this.renderStatusFilter()}
                                </Col>
                            </>
                        )}
                    </div>
                    <div className="filters-group">
                        {this.props.nodeRelation !== "Skill-Skill_cluster" && (
                            <>
                                <Col sm="6" className="filters-column">
                                    {this.renderDomainFilter()}
                                </Col>
                                <Col sm="6" className="filters-column">
                                    {this.renderSkillClusterFilter()}
                                </Col>
                            </>
                        )}
                        {this.props.currentNodeData &&
                        this.props.currentNodeData.type !== NODE_TYPE.SKILL && (
                            <Col sm="6" className="filters-column">
                                <Row className="filters-row">
                                    <Col>{this.renderNodesSortingMode()}</Col>
                                </Row>
                            </Col>
                        )}
                    </div>
                    <div className="filters-group search-group">
                        <Col sm="6" className="filters-column">
                            {this.renderSourceFilter()}
                        </Col>
                        <Col sm="6" className="filters-column">
                            {this.renderNodesSearch()}
                        </Col>
                    </div>
                </>
            );
        }

        return (
            <>
                <div className="filters-group">
                    <Col sm="6" className="filters-column">
                        {this.renderTypeFilter()}
                        {this.renderStatusFilter()}
                    </Col>
                    <Col sm="6" className="filters-column">
                        {this.renderSourceFilter()}
                        {this.renderDomainFilter()}
                    </Col>
                </div>
                <div className="filters-group search-group">
                    <Col sm="8" className="filters-column">
                        {this.renderNodesSearch()}
                    </Col>
                    <Col sm="4" className="filters-column">
                        {this.renderNodesSortingMode()}
                    </Col>
                </div>
            </>
        );
    }

    renderSkillClusterFilter() {
        return (
            <Row className="filters-row">
                <Col sm="4">
                    <h6 className="filter-label">
                        {_.get(this.props.translation, "system_nodes.cluster_short_label")}
                    </h6>
                </Col>
                <Col sm="8">{this.renderSkillCluserSelect()}</Col>
            </Row>
        );
    }

    renderSkillCluserSelect() {
        console.log("clusters", this.props.skill_clusters);
        console.log("domain", this.state.domain);

        const options = this.props.skill_clusters?.map((sc) => ({
            id: sc.id,
            value: sc.titles.en,
            label: sc.titles.en,
        }));

        return <CustomSelect className="filter-select select-system-node-type"
                             options={options}
                             styles={SELECT_MULTI_STYLES}
                             isSearchable={false}
                             menuPosition="fixed"
                             onChange={(e) => this.props.onChooseSc(e.id)}
                             placeholder={_.get(this.props.translation, "actions.all_label")}
                             isDisabled={!!this.props.currentNodeId}
                             hideSelectedOptions={false}
                             closeMenuOnSelect={false} />;

    }

    renderTypeFilter() {
        return (
            <Row className="filters-row">
                <Col sm="4">
                    <h6 className="filter-label">
                        {_.get(this.props.translation, "system_nodes.type_label")}
                    </h6>
                </Col>
                <Col sm="8">{this.renderNodeTypeSelect()}</Col>
            </Row>
        );
    }

    renderSourceFilter() {
        return (
            <Row className="filters-row">
                <Col sm="4">
                    <h6 className="filter-label">
                        {_.get(this.props.translation, "system_nodes.source_label")}
                    </h6>
                </Col>
                <Col sm="8">{this.renderNodeSourceSelect()}</Col>
            </Row>
        );
    }

    renderStatusFilter() {
        return (
            <Row className="filters-row">
                <Col sm="4">
                    <h6 className="filter-label">
                        {_.get(this.props.translation, "system_nodes.status_label")}
                    </h6>
                </Col>
                <Col sm="8">{this.renderNodeStatusSelect()}</Col>
            </Row>
        );
    }

    renderDomainFilter() {
        return (
            <Row className="filters-row">
                <Col sm="4">
                    <h6 className="filter-label">
                        {_.get(this.props.translation, "system_nodes.domain_short_label")}
                    </h6>
                </Col>
                <Col sm="8">{this.renderNodeDomainSelect()}</Col>
            </Row>
        );
    }

    renderNodesSearch() {
        return (
            <InputGroup className="search-nodes-input-group filters-row">
                <Input
                    placeholder={_.get(
                        this.props.translation,
                        `model_management_page.${
                            this.props.currentNodeData &&
                            this.props.currentNodeData.type === NODE_TYPE.SKILL
                                ? "skills_search_placeholder"
                                : "nodes_search_placeholder"
                        }`,
                    )}
                    value={this.props.nodeNameFilter}
                    onChange={this.onChangeSearchNodesText}
                    onKeyPress={this.onSearchNodesKeyPress}
                    disabled={!!this.props.currentNodeId}
                />
                <InputGroupAddon addonType="append">
                    <Button
                        className="icon-button"
                        onClick={this.onGetSearchNodes}
                        title={_.get(this.props.translation, "actions.search_label")}
                    >
                        <Icon type="search" color="light" />
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        );
    }

    renderNodesSortingMode() {
        let options = _.map(NODE_SORTING_LIST, (elem) => {
            return {
                value: elem,
                label: _.get(this.props.translation, NODE_SORTING_LABEL[elem]),
            };
        });

        const customSingleValue = (props) => {
            let { children } = props;

            return (
                <div>
                    <Icon type="sort" size="sm" />
                    <components.SingleValue className="select-single-value" {...props}>
                        {children}
                    </components.SingleValue>
                </div>
            );
        };

        return (
            <CustomSelect
                className="filter-select nodes-sorting-mode-select"
                value={options.find((elem) => elem.value === this.props.sortingMode)}
                options={options}
                isSearchable={false}
                menuPosition="fixed"
                components={{ SingleValue: customSingleValue }}
                onChange={this.onChangeNodesSortingMode}
            />
        );
    }

    renderNodeTypeSelect() {
        const customMultiValueLabel = (props) => {
            let { data } = props;

            return (
                <components.MultiValueLabel {...props}>
                    {data.labelShort}
                </components.MultiValueLabel>
            );
        };

        let options = [
            ...NODE_TYPE_LIST.map((type) => {
                return {
                    value: type,
                    label: `${_.get(this.props.translation, NODE_TYPE_SHORT_LABEL[type])} (${_.get(
                        this.props.translation,
                        NODE_TYPE_LABEL[type],
                    )})`,
                    labelShort: _.get(this.props.translation, NODE_TYPE_SHORT_LABEL[type]),
                };
            }),
        ];

        return (
            <CustomSelect className="filter-select select-system-node-type"
                          value={_.filter(options, (option) =>
                              _.includes(this.props.nodeTypeFilter, option.value),
                          )}
                          options={options}
                          styles={SELECT_MULTI_STYLES}
                          isSearchable={false}
                          menuPosition="fixed"
                          onChange={this.onChangeNodeType}
                          components={{ MultiValueLabel: customMultiValueLabel }}
                          placeholder={_.get(this.props.translation, "actions.all_label")}
                          isDisabled={!!this.props.currentNodeId}
                          isMulti
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false} />
        );
    }

    renderNodeSourceSelect() {
        let options = [
            ...NODE_SOURCE_LIST.map((type) => {
                return {
                    value: type,
                    label: _.get(this.props.translation, NODE_SOURCE_LABEL[type]),
                };
            }),
            ..._.map(this.props.externalSources, (source) => {
                return {
                    value: source,
                    label: source,
                };
            }),
        ];

        return (
            <CustomSelect
                className="filter-select select-system-node-type"
                value={_.filter(options, (option) =>
                    _.includes(this.props.nodeSourceFilter, option.value),
                )}
                options={options}
                styles={SELECT_MULTI_STYLES}
                isSearchable={false}
                menuPosition="fixed"
                onChange={this.onChangeNodeSource}
                isDisabled={!!this.props.currentNodeId}
                placeholder={_.get(this.props.translation, "actions.all_label")}
                isMulti
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
            />
        );
    }

    renderNodeStatusSelect() {
        let options = [
            ...NODE_STATUS_LIST.map((status) => {
                return {
                    value: status,
                    label: _.get(this.props.translation, NODE_STATUS_LABEL[status]),
                };
            }),
        ];

        return (
            <CustomSelect
                className="filter-select select-system-node-status"
                value={_.find(options, (option) => option.value === this.props.nodeStatusFilter)}
                options={options}
                styles={SELECT_NODE_STATUS_STYLES}
                isSearchable={false}
                menuPosition="fixed"
                onChange={this.onChangeNodeStatus}
                isDisabled={!!this.props.currentNodeId}
                placeholder={_.get(this.props.translation, "actions.all_label")}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
            />
        );
    }

    renderNodeDomainSelect() {

        const options = [
            {
                label: "All",
            },
            ...this.props.domains.map((domain) => ({
                id: domain.id,
                label: domain.titles.en,
                value: domain.titles.en,
            })),
        ];

        return (
            <CustomSelect className="filter-select select-system-node-domain"
                          value={_.find(options, (option) => option.value === this.props.nodeDomainFilter)}
                          options={options}
                          isSearchable={false}
                          menuPosition="fixed"
                          placeholder={_.get(this.props.translation, "actions.all_label")}
                          onChange={(domain) => {
                              this.setState({ domain });;
                              this.props.onChooseDomain(domain.value);
                          }}
                          isDisabled={!!this.props.currentNodeId} />
        );
    }

    onChangeNodeType = (selectedOptions) => {
        let values = _.map(selectedOptions, (option) => option.value);

        if (this.props.onUpdateNodesTypeFilter) {

            this.props.onUpdateNodesTypeFilter(values);
        }
    };

    onChangeNodeStatus = (selectedOption) => {
        let values = selectedOption.value;
        this.props.onUpdateNodesStatusFilter(values);
    };

    onChangeNodeSource = (selectedOptions) => {
        let values = _.map(selectedOptions, (option) => option.value);
        this.props.onUpdateNodesSourceFilter(values);
    };

    onChangeNodeDomain = (selectedOption) => {
        this.props.onUpdateNodesDomainFilter(selectedOption.value);
    };

    onChangeNodesSortingMode = (selectedOption) => {
        this.props.onUpdateNodesSortingMode(selectedOption.value);
    };

    onChangeSearchNodesText = (e) => {
        this.props.onUpdateNodesNameFilter(e.target.value);
    };

    onSearchNodesKeyPress = (e) => {
        if (e.key === "Enter") {
            this.props.onGetNodesList();
        }
    };

    onGetSearchNodes = () => {
        this.props.onGetNodesList();
    };

    render() {
        return <div className="nodes-filters">{this.renderContent()}</div>;
    }
}

NodesFilters.defaultProps = {
    disableTypeFilter: false,
};

NodesFilters.propTypes = {
    translation: PropTypes.object.isRequired,
    nodeNameFilter: PropTypes.string,
    nodeTypeFilter: PropTypes.array,
    nodeSourceFilter: PropTypes.array,
    nodeStatusFilter: PropTypes.array,
    nodeDomainFilter: PropTypes.string,
    sortingMode: PropTypes.string,
    externalSources: PropTypes.array,
    loadingExternalSources: PropTypes.bool,
    disableTypeFilter: PropTypes.bool,
    onMount: PropTypes.func,
    onUnmount: PropTypes.func,
    onGetNodesList: PropTypes.func.isRequired,
    onUpdateNodesNameFilter: PropTypes.func.isRequired,
    onUpdateNodesTypeFilter: PropTypes.func,
    onUpdateNodesSourceFilter: PropTypes.func.isRequired,
    onUpdateNodesStatusFilter: PropTypes.func.isRequired,
    onUpdateNodesDomainFilter: PropTypes.func.isRequired,
    onUpdateNodesSortingMode: PropTypes.func.isRequired,
};

export default NodesFilters;
