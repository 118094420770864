import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './JobStack.scss';
import Icon from '../../../../common/icon/Icon';

class JobsStack extends Component {
	render() {
		const { onRemoveJobClick, translation, enabledJobsIdsToCompare, jobsData, onDisableJob } = this.props;

		const isFavorite = true; // todo: needs to integrate

		return (
			<div className="jobs-stack">
				<h6 className="text-center">{_.get(translation, "jobs_comparison_page.jobs_stack_label")}</h6>
				<div className="jobs-list">
					{
						_.map(jobsData, (job) => {
							const isJobSelected = _.includes(enabledJobsIdsToCompare, job.id);

							return (
								<div key={job.id} className="job-card">
									<div className="job-icon-container">
										{
											isFavorite ?
												<Icon
													className="favorite-icon"
													type="star"
													size="sm"
													title={_.get(this.props.translation, 'candidates_comparison_page.remove_from_favorite_label')}
													onClick={() => {}}
												/>
												:
												<Icon
													className="favorite-icon"
													type="star_outline"
													size="sm"
													title={_.get(this.props.translation, 'candidates_comparison_page.add_to_favorite_label')}
													onClick={() => {}}
												/>
										}
										<div className={`job-icon-wrapper ${isJobSelected ? "selected" : ""}`}>
											<Icon
												type="job"
												color="secondary"
												onClick={onDisableJob(isJobSelected, job.id)}
											/>
										</div>
										<Icon
											className="remove-from-stack"
											type="cancel"
											size="sm"
											color="danger"
											onClick={() => onRemoveJobClick(job.id)}
											title={_.get(translation, 'jobs_comparison_page.remove_job_from_stack_label')}
										/>
									</div>
									<div className={`job-title`}>
										{job.title}
									</div>
									{/*todo: needs to integrate with backend*/}
									<div className={`job-title`}>
										Company name
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		)
	}
}

JobsStack.propTypes = {
	onRemoveJobClick: PropTypes.func.isRequired,
	onDisableJob: PropTypes.func.isRequired,
	enabledJobsIdsToCompare: PropTypes.array.isRequired,
	jobsData: PropTypes.array.isRequired
};

export default JobsStack;
