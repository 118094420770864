import React, { Component } from "react";
import PropTypes from "prop-types";
import "./SkillsSetup.scss";
import _ from "lodash";
import moment from "moment";
import {
    Button,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    ListGroup,
    ListGroupItem,
    Alert,
    UncontrolledPopover,
    PopoverBody,
    Collapse,
    Form,
    FormGroup,
    Label,
    UncontrolledAlert,
} from "reactstrap";
import { components } from "react-select";
import CustomSelect from "../../inputs/customSelect/CustomSelect";
import SkillsMap from "../../../common/skills/skillsMap/SkillsMap.container";
import Skill from "../skill/Skill.container";
import Legend from "../../../common/skills/legend/Legend.container";
import Icon from "../../icon/Icon";
import ClusterSkillsPopover from "../../popovers/clusterSkillsPopover/ClusterSkillsPopover.container";
import { Utils } from "../../../../utils/utils";
import { SKILLS_MAP_MODE } from "../../../common/skills/skillsMap/SkillsMap";
import {
    NODE_TYPE,
    SKILL_PROPERTY,
    LEVEL_CLASS_NAME,
    LEVEL_OPTIONS,
    LEVEL_TRANSLATION_LABEL,
    EAGERNESS_TRANSLATION_LABEL,
    EAGERNESS_ICON,
    EAGERNESS_OPTIONS,
    EAGERNESS_CLASS_NAME,
    LANGUAGE_LEVEL_OPTIONS,
    LANGUAGE_LEVEL_TRANSLATION_LABEL,
    VERSION_RATIO_OPTIONS,
    VERSION_RATIO_TRANSLATION_LABEL,
    REQUIREMENT_COEFFICIENT_OPTIONS,
    REQUIREMENT_COEFFICIENT_TRANSLATION_LABEL,
} from "../../../../constants/skillsConstants";
import {
    SELECT_LEVELS_STYLES,
    SELECT_EAGERNESS_STYLES,
    SELECT_REQUIREMENT_COEFFICIENTS_STYLES,
} from "../../../../constants/selectStyles";
import SkillNotesModal from "../../modals/skillNotesModal/SkillNotesModal.container";
import RemoveSkillModal from "../../modals/removeSkillModal/RemoveSkillModal.container";
import RemoveClusterModal from "../../modals/removeClusterModal/RemoveClusterModal.container";
import CustomSkillLevelModal from "../../modals/customSkillLevelModal/CustomSkillLevelModal.container";
import PotentialClustersModal from "../../modals/potentialClustersModal/PotentialClustersModal.container";

export const SKILLS_SETUP_MODE = {
    APPLICANT: "APPLICANT",
    JOB: "JOB",
};

const SKILLS_SETUP_MODE_TRANSLATION_LABEL = {
    [SKILLS_SETUP_MODE.APPLICANT]: "applicant",
    [SKILLS_SETUP_MODE.JOB]: "job",
};

const SKILLS_MAP_MODE_BY_SETUP_MODE = {
    [SKILLS_SETUP_MODE.APPLICANT]: SKILLS_MAP_MODE.APPLICANT_EDIT,
    [SKILLS_SETUP_MODE.JOB]: SKILLS_MAP_MODE.JOB_EDIT,
};

const HINT = {
    PROFILES: "PROFILES",
    SKILLS_MAP: "SKILLS_MAP",
    SKILLS_MAP_2: "SKILLS_MAP_2",
    SKILLS: "SKILLS",
    SELECT_SKILL: "SELECT_SKILL",
    DEFINE_NEW_SKILL: "DEFINE_NEW_SKILL",
};

class SkillsSetup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleHints: [
                HINT.PROFILES,
                HINT.SKILLS_MAP,
                HINT.SKILLS,
                HINT.SKILLS_MAP_2,
                HINT.SELECT_SKILL,
                HINT.DEFINE_NEW_SKILL,
            ],
            searchProfilesValue: "",
            searchSkillsValue: "",
            isOpenSemanticContext: true,
            isOpenSkillProperties: false,
            isOpenSearchAndPotentialSkills: true,
            isOpenDefineNewNode: false,
            defineNewNodeType: NODE_TYPE.SKILL,
            defineNewNodeName: "",
            isDefineNewNodeInCluster: "isDefineNewNodeInCluster",
            newSkillClusterId: "",
            skillToRemoveId: "",
            showRemoveSkillWarning: true,
            clusterToRemoveId: "",
            clusterToRemoveTitle: "",
            showRemoveClusterWarning: true,
        };
    }

    componentDidMount() {
        if (this.props.mode === SKILLS_SETUP_MODE.APPLICANT) {
            this.props.profiles &&
                this.props.profiles.map((profile) =>
                    this.props.onChangeSelectedProfile(profile.id, true)
                );

            const hints = [];
            Object.keys(HINT).forEach((key) => localStorage.getItem(key) && hints.push(key));
            let data = _.cloneDeep(this.state.visibleHints) || [];
            data = data.filter((elem) => hints.indexOf(elem) === -1);
            this.setState({ visibleHints: data });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentSkillId && this.props.currentSkillId !== prevProps.currentSkillId) {
            this.setState({ isOpenSkillProperties: true });
        }
    }

    componentWillUnmount() {
        if (this.props.mode === SKILLS_SETUP_MODE.APPLICANT) {
            this.props.onSaveChanges();
        }
    }

    render() {
        const staticApplicantLink =
            this.props.userId && !_.isEmpty(this.props.user)
                ? `${window.location.origin}/applicant-profile/${this.props.user.hash}`
                : null;

        const staticJobLink =
            this.props.user?.id && !_.isEmpty(this.props.user)
                ? `${window.location.origin}/job-profile/${this.props.user.hash}`
                : null;

        return (
            <div className="skills-setup">
                <div className="skills-setup-content">
                    <Col md="3" className="menu-column-container left">
                        {this.renderProfilesSettings()}
                    </Col>
                    <Col className="skills-map-column">
                        {this.renderSkillsMap(staticApplicantLink, staticJobLink)}
                    </Col>
                    <Col md="3" className="menu-column-container right">
                        {this.renderSkillSettings()}
                    </Col>
                </div>
                <SkillNotesModal
                    skillTitle={
                        this.props.currentSkillData ? this.props.currentSkillData.title : null
                    }
                    notes={this.props.currentSkillData ? this.props.currentSkillData.notes : null}
                    onAddNewNote={this.onAddNewNote}
                    onRemoveNote={this.onRemoveNote}
                />
                <RemoveSkillModal
                    data={_.find(
                        this.props.skills,
                        (skill) => skill.id === this.state.skillToRemoveId
                    )}
                    onNotShowAgain={this.onHideRemoveSkillWarning}
                    onRemove={this.onRemoveSkillSubmit}
                />
                <RemoveClusterModal
                    data={this.getClusterToRemoveData()}
                    onNotShowAgain={this.onHideRemoveClusterWarning}
                    onRemove={this.onRemoveClusterSubmit}
                />
                <CustomSkillLevelModal
                    currentSkillData={this.props.currentSkillData}
                    updateSkillProperty={this.props.updateSkillProperty}
                    onSaveChanges={this.props.onSaveChanges}
                />
                <PotentialClustersModal
                    currentSkillData={this.props.currentSkillData}
                    onSaveChanges={this.props.onSaveChanges}
                />
            </div>
        );
    }

    renderProfilesSettings() {
        return (
            <div className="profiles-settings-container">
                <h5 className="text-center">
                    {_.get(this.props.translation, "profiles_management.profiles_label")}
                    <Icon
                        className="hint-info-icon"
                        type="info"
                        color="info"
                        size="sm"
                        title={_.get(this.props.translation, "actions.about_label")}
                        id="profiles-info-icon"
                        hidden={this.state.visibleHints.includes(HINT.PROFILES)}
                    />
                    <UncontrolledPopover
                        placement="right"
                        target="profiles-info-icon"
                        trigger="legacy"
                    >
                        <PopoverBody>
                            {_.get(
                                this.props.translation,
                                `profiles_management.${
                                    SKILLS_SETUP_MODE_TRANSLATION_LABEL[this.props.mode]
                                }.profiles_hint`
                            )}
                        </PopoverBody>
                    </UncontrolledPopover>
                </h5>
                <Alert
                    color="success"
                    hidden={!this.state.visibleHints.includes(HINT.PROFILES)}
                    toggle={this.onCloseHint(HINT.PROFILES)}
                >
                    {_.get(
                        this.props.translation,
                        `profiles_management.${
                            SKILLS_SETUP_MODE_TRANSLATION_LABEL[this.props.mode]
                        }.profiles_hint`
                    )}
                </Alert>
                <div className="profile-card">
                    <h6 className="text-center">
                        {_.get(
                            this.props.translation,
                            `profiles_management.${
                                SKILLS_SETUP_MODE_TRANSLATION_LABEL[this.props.mode]
                            }.selected_profiles_label`
                        )}
                        <Icon
                            className="hint-info-icon"
                            type="info"
                            color="info"
                            size="sm"
                            title={_.get(this.props.translation, "actions.about_label")}
                            id="person-profiles-info-icon"
                        />
                        <UncontrolledPopover
                            placement="right"
                            target="person-profiles-info-icon"
                            trigger="legacy"
                        >
                            <PopoverBody>
                                {this.props.mode === SKILLS_SETUP_MODE.APPLICANT ? (
                                    <span>
                                        {_.get(
                                            this.props.translation,
                                            "profiles_management.selected_profiles_hint_part_1"
                                        )}
                                        <br />
                                        {_.get(
                                            this.props.translation,
                                            "profiles_management.selected_profiles_hint_part_2"
                                        )}
                                        <br />
                                    </span>
                                ) : null}
                                {_.get(
                                    this.props.translation,
                                    "profiles_management.selected_profiles_hint_part_3"
                                )}
                            </PopoverBody>
                        </UncontrolledPopover>
                    </h6>
                    {this.renderSelectedProfiles()}
                </div>
                <div className="profile-card">
                    <h6 className="text-center">
                        {_.get(
                            this.props.translation,
                            "profiles_management.potential_profiles_label"
                        )}
                    </h6>
                    <InputGroup className="search-profiles-input-group">
                        <Input
                            placeholder={_.get(
                                this.props.translation,
                                "profiles_management.potential_profiles_search_placeholder"
                            )}
                            value={this.state.searchProfilesValue}
                            onChange={this.onChangeSearchProfilesText}
                            onKeyPress={this.onSearchProfilesKeyPress}
                        />
                        <InputGroupAddon addonType="append">
                            <Button
                                className="icon-button"
                                onClick={this.onGetSearchProfiles}
                                title={_.get(this.props.translation, "actions.search_label")}
                                disabled={_.isEmpty(this.state.searchProfilesValue)}
                            >
                                <Icon type="search" color="light" />
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                    {!_.isEmpty(this.state.searchProfilesValue)
                        ? this.renderSearchProfiles()
                        : this.renderSuggestedProfiles()}
                </div>
            </div>
        );
    }

    renderSelectedProfiles() {
        if (this.props.loadingProfiles) {
            return (
                <div className="text-center">
                    {_.get(this.props.translation, "actions.fetching_message")}
                </div>
            );
        }

        if (!this.props.profiles) {
            return null;
        }

        if (this.props.profiles.length === 0) {
            return (
                <Alert className="overflow-auto" color="primary">
                    {Utils.getTagsFromStringWithStyles(
                        _.get(
                            this.props.translation,
                            "profiles_management.no_selected_profiles_hint_part_1"
                        )
                    )}{" "}
                    {_.get(
                        this.props.translation,
                        `profiles_management.${
                            SKILLS_SETUP_MODE_TRANSLATION_LABEL[this.props.mode]
                        }.no_selected_profiles_hint_part_2`
                    )}
                </Alert>
            );
        }

        let data = _.sortBy(this.props.profiles, ["title"]);
        let listItems = _.map(data, (profile) => {
            let isSelected =
                this.props.selectedProfiles && this.props.selectedProfiles.includes(profile.id);
            return (
                <ListGroupItem key={profile.id}>
                    <div className="profile-item-with-checkbox-container">
                        <Icon
                            type={`checkbox${!isSelected ? "_blank" : ""}`}
                            color="secondary"
                            title={_.get(
                                this.props.translation,
                                `profiles_management.${isSelected ? "de" : ""}select_profile_label`
                            )}
                            onClick={this.onChangeSelectedProfiles(profile.id)}
                        />
                        <div className="profile-item" title={profile.description}>
                            {profile.alternativeTitles && profile?.alternativeTitles.length
                                ? `${profile?.alternativeTitles.map((el) => ` ${el}`)}, ${
                                      profile.title
                                  }`
                                : profile.title}
                        </div>
                    </div>
                    <Icon
                        type="minus_outline"
                        color="danger"
                        size="sm"
                        title={_.get(
                            this.props.translation,
                            "profiles_management.remove_profile_label"
                        )}
                        onClick={this.onRemoveProfile(profile.id)}
                    />
                </ListGroupItem>
            );
        });

        return (
            <ListGroup className="profile-items-container" flush>
                {listItems}
            </ListGroup>
        );
    }

    renderSearchProfiles() {
        if (this.props.loadingSearchProfiles) {
            return (
                <div className="text-center">
                    {_.get(this.props.translation, "actions.fetching_message")}
                </div>
            );
        }

        if (this.props.searchProfiles) {
            if (this.props.searchProfiles.length > 0) {
                let data = this.props.searchProfiles;

                let listItems = data.map((profile) => {
                    return (
                        <ListGroupItem key={profile.id}>
                            <div className="profile-item">{profile.title}</div>
                            {this.props.profiles &&
                            this.props.profiles.find((elem) => elem.id === profile.id) ? null : (
                                <Icon
                                    type="plus_outline"
                                    color="success"
                                    size="sm"
                                    title={_.get(
                                        this.props.translation,
                                        "profiles_management.add_profile_label"
                                    )}
                                    onClick={this.onAddProfile(profile, profile.title)}
                                />
                            )}
                        </ListGroupItem>
                    );
                });

                return (
                    <ListGroup className="profile-items-container" flush>
                        {listItems}
                    </ListGroup>
                );
            } else {
                return (
                    <div className="text-center">
                        {_.get(
                            this.props.translation,
                            "profiles_management.potential_profiles_not_found_label"
                        )}
                    </div>
                );
            }
        }
    }

    renderSuggestedProfiles() {
        if (this.props.skills && this.props.skills.length > 0) {
            if (this.props.loadingSuggestedProfiles) {
                return (
                    <div className="text-center">
                        {_.get(this.props.translation, "actions.fetching_message")}
                    </div>
                );
            }

            if (!this.props.suggestedProfiles) {
                return null;
            }

            let listItems = _.map(this.props.suggestedProfiles, (profile) => {
                return (
                    <ListGroupItem key={profile.id}>
                        <div className="profile-item" title={profile.description}>
                            {profile.title}
                        </div>
                        {this.props.profiles &&
                        this.props.profiles.find((elem) => elem.id === profile.id) ? null : (
                            <Icon
                                type="plus_outline"
                                color="success"
                                size="sm"
                                title={_.get(
                                    this.props.translation,
                                    "profiles_management.add_profile_label"
                                )}
                                onClick={this.onAddProfile(profile)}
                            />
                        )}
                    </ListGroupItem>
                );
            });

            return (
                <ListGroup className="profile-items-container" flush>
                    {listItems}
                </ListGroup>
            );
        }
    }

    renderSkillsMap(staticApplicantLink, staticJobLink) {
        return (
            <div className="skills-map-container">
                <h5 className="text-center">
                    {_.get(this.props.translation, "skills_management.skills_map_label")}
                    <Icon
                        className="hint-info-icon"
                        type="info"
                        color="info"
                        size="sm"
                        title={_.get(this.props.translation, "actions.about_label")}
                        id="skills-map-info-icon"
                        hidden={this.state.visibleHints.includes(HINT.SKILLS_MAP)}
                    />
                    <UncontrolledPopover
                        placement="right"
                        target="skills-map-info-icon"
                        trigger="legacy"
                    >
                        <PopoverBody>
                            {_.get(
                                this.props.translation,
                                `skills_management.${
                                    SKILLS_SETUP_MODE_TRANSLATION_LABEL[this.props.mode]
                                }.skills_map_hint_part_1`
                            )}{" "}
                            {_.get(
                                this.props.translation,
                                "skills_management.skills_map_hint_part_2"
                            )}
                        </PopoverBody>
                    </UncontrolledPopover>
                </h5>
                <Alert
                    color="success"
                    hidden={!this.state.visibleHints.includes(HINT.SKILLS_MAP)}
                    toggle={this.onCloseHint(HINT.SKILLS_MAP)}
                >
                    {_.get(
                        this.props.translation,
                        `skills_management.${
                            SKILLS_SETUP_MODE_TRANSLATION_LABEL[this.props.mode]
                        }.skills_map_hint_part_1`
                    )}{" "}
                    {_.get(this.props.translation, "skills_management.skills_map_hint_part_2")}
                </Alert>
                <div className="skills-map-component-container">
                    <Alert
                        color="info"
                        hidden={!this.state.visibleHints.includes(HINT.SKILLS_MAP_2)}
                        toggle={this.onCloseHint(HINT.SKILLS_MAP_2)}
                    >
                        {_.get(
                            this.props.translation,
                            "skills_management.skills_map_skills_and_clusters_hint_part_1"
                        )}{" "}
                        {_.get(
                            this.props.translation,
                            `skills_management.${
                                SKILLS_SETUP_MODE_TRANSLATION_LABEL[this.props.mode]
                            }.skills_map_skills_and_clusters_hint_part_2`
                        )}{" "}
                        {_.get(
                            this.props.translation,
                            "skills_management.skills_map_skills_and_clusters_hint_part_3"
                        )}
                    </Alert>
                    <SkillsMap
                        skillsTree={this.props.skillsTree}
                        mode={SKILLS_MAP_MODE_BY_SETUP_MODE[this.props.mode]}
                        userId={this.props.userId}
                        imageSource={this.props.imageSource}
                        currentSkillId={this.props.currentSkillId}
                        currentClusterId={this.props.currentClusterId}
                        selectedProfiles={this.props.selectedProfiles}
                        onSelectSkill={this.onSelectSkill}
                        onRemoveSkill={this.onRemoveSkill}
                        onSelectCluster={this.onSelectCluster}
                        onRemoveCluster={this.onRemoveCluster}
                        onNotesClick={this.onNotesClick}
                    />
                </div>
                <Legend />
                {this.props.mode === "APPLICANT" ? (
                    <div className="info-wrapper">
                        <h6>
                            {_.get(this.props.translation, "job_profile.to_avoid_tooltip")}
                            <i>{_.get(this.props.translation, "shared.again_and_again")}</i>
                            {_.get(this.props.translation, "job_profile.to_avoid_tooltip_part2")}
                        </h6>
                        <div>
                            <h6>
                                {_.get(this.props.translation, "job_profile.use_the_below_link")}
                                <a id="static_link" href={staticApplicantLink}>
                                    <b>
                                        <i>Link</i>
                                    </b>
                                </a>
                            </h6>
                            <Button
                                color="primary"
                                onClick={() => this.copyToClipboard("static_link")}
                            >
                                {_.get(this.props.translation, "shared.copy_link")}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="info-wrapper">
                        <h6>
                            {_.get(this.props.translation, "job_profile.to_avoid_tooltip")}
                            <i>{_.get(this.props.translation, "shared.again_and_again")}</i>
                            {_.get(this.props.translation, "job_profile.to_avoid_tooltip_part2")}
                        </h6>
                        <div>
                            <h6>
                                {_.get(this.props.translation, "job_profile.use_the_below_link")}
                                <a id="static_link" href={staticJobLink}>
                                    <b>
                                        <i>Link</i>
                                    </b>
                                </a>
                            </h6>
                            <Button
                                color="primary"
                                onClick={() => this.copyToClipboard("static_link")}
                            >
                                {_.get(this.props.translation, "shared.copy_link")}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    renderSkillSettings() {
        return (
            <div className="skill-settings-container">
                <h5 className="text-center with-collapse">
                    {_.get(this.props.translation, "skills_management.skills_label")}
                    <Icon
                        className="hint-info-icon"
                        type="info"
                        color="info"
                        size="sm"
                        title={_.get(this.props.translation, "actions.about_label")}
                        id="skills-info-icon"
                        hidden={this.state.visibleHints.includes(HINT.SKILLS)}
                    />
                    <UncontrolledPopover
                        placement="right"
                        target="skills-info-icon"
                        trigger="legacy"
                    >
                        <PopoverBody>
                            {_.get(this.props.translation, "skills_management.skills_hint_part_1")}
                            <ul>
                                <li>
                                    {_.get(
                                        this.props.translation,
                                        "skills_management.skills_hint_part_2"
                                    )}
                                </li>
                                <li>
                                    {_.get(
                                        this.props.translation,
                                        "skills_management.skills_hint_part_3"
                                    )}
                                </li>
                                <li>
                                    {_.get(
                                        this.props.translation,
                                        "skills_management.skills_hint_part_4"
                                    )}
                                </li>
                            </ul>
                        </PopoverBody>
                    </UncontrolledPopover>
                </h5>
                <div className="skill-settings-content-container">
                    <Alert
                        color="success"
                        hidden={!this.state.visibleHints.includes(HINT.SKILLS)}
                        toggle={this.onCloseHint(HINT.SKILLS)}
                    >
                        {_.get(this.props.translation, "skills_management.skills_hint_part_1")}
                        <ul>
                            <li>
                                {_.get(
                                    this.props.translation,
                                    "skills_management.skills_hint_part_2"
                                )}
                            </li>
                            <li>
                                {_.get(
                                    this.props.translation,
                                    "skills_management.skills_hint_part_3"
                                )}
                            </li>
                            <li>
                                {_.get(
                                    this.props.translation,
                                    "skills_management.skills_hint_part_4"
                                )}
                            </li>
                        </ul>
                    </Alert>
                    {!this.props.currentSkillId && !this.props.currentClusterId ? (
                        <Alert
                            color="info"
                            hidden={!this.state.visibleHints.includes(HINT.SELECT_SKILL)}
                            toggle={this.onCloseHint(HINT.SELECT_SKILL)}
                        >
                            {_.get(this.props.translation, "skills_management.select_skill_hint")}
                        </Alert>
                    ) : null}
                    <div
                        className={`skill-settings-card semantic-context ${
                            this.state.isOpenSemanticContext ? "card-open" : "card-close"
                        } ${
                            !this.props.currentClusterSkills ||
                            this.props.currentClusterSkills.length === 0
                                ? "without-skills"
                                : ""
                        }`}
                        hidden={!this.props.currentSkillId && !this.props.currentClusterId}
                    >
                        <h6 className="text-center with-collapse">
                            {this.props.currentSkillId
                                ? _.get(
                                      this.props.translation,
                                      "skills_management.semantic_context_of_skill"
                                  )
                                : _.get(
                                      this.props.translation,
                                      "skills_management.semantic_context_of_cluster"
                                  )}
                            <Icon
                                type={`expand_${
                                    this.state.isOpenSemanticContext ? "less" : "more"
                                }`}
                                color="secondary"
                                onClick={this.toggleCollapse("isOpenSemanticContext")}
                            />
                        </h6>
                        <Collapse isOpen={this.state.isOpenSemanticContext}>
                            {this.renderBreadCrumbs()}
                            {this.renderCurrentClusterSkills()}
                        </Collapse>
                    </div>
                    <div
                        className={
                            "skill-settings-card skill-properties" +
                            (this.state.isOpenSkillProperties ? " card-open" : " card-close")
                        }
                        hidden={!this.props.currentSkillId}
                    >
                        <h6 className="text-center with-collapse">
                            {_.get(
                                this.props.translation,
                                "skills_management.skill_properties_label"
                            )}
                            <Icon
                                type={`expand_${
                                    this.state.isOpenSkillProperties ? "less" : "more"
                                }`}
                                color="secondary"
                                onClick={this.toggleCollapse("isOpenSkillProperties")}
                            />
                        </h6>
                        <Collapse isOpen={this.state.isOpenSkillProperties}>
                            {this.renderLevelsFormGroup()}
                            {this.props.mode === SKILLS_SETUP_MODE.APPLICANT
                                ? this.renderEagernessFormGroup()
                                : null}
                            {this.props.mode === SKILLS_SETUP_MODE.JOB
                                ? this.renderRequirementCoefficientFormGroup()
                                : null}
                            {/* {this.props.currentSkillData &&
                                this.props.mode !== SKILLS_SETUP_MODE.JOB &&
                                this.renderSkillLifeCycleFormGroup()} */}
                            {this.renderSkillMetadata()}
                            {this.props.mode !== SKILLS_SETUP_MODE.JOB &&
                                this.renderSkillNotesByMeFormGroup()}
                        </Collapse>
                    </div>
                    <div
                        className={
                            "skill-settings-card search-skills" +
                            (this.state.isOpenSearchAndPotentialSkills
                                ? " card-open"
                                : " card-close")
                        }
                    >
                        <h6 className="text-center with-collapse">
                            {_.get(
                                this.props.translation,
                                "skills_management.potential_clusters_and_skills_label"
                            )}
                            <Icon
                                type={`expand_${
                                    this.state.isOpenSearchAndPotentialSkills ? "less" : "more"
                                }`}
                                color="secondary"
                                onClick={this.toggleCollapse("isOpenSearchAndPotentialSkills")}
                            />
                            <Icon
                                className="hint-info-icon hint-info-icon-with-arrow"
                                type="info"
                                color="info"
                                size="sm"
                                title={_.get(this.props.translation, "actions.about_label")}
                                id="skills-info-second-icon"
                                hidden={this.state.visibleHints.includes(HINT.SELECT_SKILL)}
                            />
                            <UncontrolledPopover
                                placement="right"
                                target="skills-info-second-icon"
                                trigger="legacy"
                                boundariesElement="skills-info-second-icon"
                            >
                                <PopoverBody>
                                    {_.get(
                                        this.props.translation,
                                        "skills_management.potential_skill_and_cluster_hint.hint_part_1"
                                    )}
                                    <i>
                                        {_.get(
                                            this.props.translation,
                                            "skills_management.potential_skill_and_cluster_hint.skill"
                                        )}
                                    </i>{" "}
                                    {_.get(
                                        this.props.translation,
                                        "skills_management.potential_skill_and_cluster_hint.or"
                                    )}{" "}
                                    <i>
                                        {_.get(
                                            this.props.translation,
                                            "skills_management.potential_skill_and_cluster_hint.cluster"
                                        )}
                                    </i>{" "}
                                    {_.get(
                                        this.props.translation,
                                        "skills_management.potential_skill_and_cluster_hint.hint_part_2"
                                    )}
                                </PopoverBody>
                            </UncontrolledPopover>
                        </h6>
                        <Collapse isOpen={this.state.isOpenSearchAndPotentialSkills}>
                            <InputGroup className="search-skills-input-group">
                                <Input
                                    placeholder={_.get(
                                        this.props.translation,
                                        "skills_management.potential_clusters_and_skills_search_placeholder"
                                    )}
                                    value={this.state.searchSkillsValue}
                                    onChange={this.onChangeSearchSkillsText}
                                    onKeyPress={this.onSearchSkillsKeyPress}
                                />
                                <InputGroupAddon addonType="append">
                                    <Button
                                        className="icon-button"
                                        onClick={this.onGetSearchSkills}
                                        title={_.get(
                                            this.props.translation,
                                            "actions.search_label"
                                        )}
                                        disabled={_.isEmpty(this.state.searchSkillsValue)}
                                    >
                                        <Icon type="search" color="light" />
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                            {!_.isEmpty(this.state.searchSkillsValue)
                                ? this.renderSearchSkills()
                                : this.renderSuggestedSkills()}
                        </Collapse>
                    </div>
                    {this.renderDefineNewSkillCard()}
                </div>
            </div>
        );
    }

    renderDefineNewSkillCard() {
        const nodeTypeOptions = [
            {
                value: NODE_TYPE.SKILL,
                label: _.get(this.props.translation, "system_nodes.skill_label"),
            },
            {
                value: NODE_TYPE.CLUSTER,
                label: _.get(this.props.translation, "system_nodes.skill_cluster_label"),
            },
        ];
        let clustersOptions = _.map(this.props.skill_clusters, (cluster) => {
            return { value: cluster.id, label: cluster.titles.en };
        });
        clustersOptions = _.sortBy(clustersOptions, ["label"]);

        return (
            <div
                className={
                    "skill-settings-card define-new-skill" +
                    (this.state.isOpenDefineNewNode ? " card-open" : " card-close")
                }
            >
                <h6 className="text-center with-collapse">
                    {_.get(this.props.translation, "skills_management.define_new_skill_label")}
                    <Icon
                        type={`expand_${this.state.isOpenDefineNewNode ? "less" : "more"}`}
                        color="secondary"
                        onClick={this.toggleCollapse("isOpenDefineNewNode")}
                    />
                    <Icon
                        className="hint-info-icon hint-info-icon-with-arrow"
                        type="info"
                        color="info"
                        size="sm"
                        title={_.get(this.props.translation, "actions.about_label")}
                        id="new-skill-icon"
                        hidden={this.state.visibleHints.includes(HINT.DEFINE_NEW_SKILL)}
                    />
                    <UncontrolledPopover
                        placement="right"
                        target="new-skill-icon"
                        trigger="legacy"
                        boundariesElement="new-skill-icon"
                    >
                        <PopoverBody>
                            {_.get(
                                this.props.translation,
                                "skills_management.define_new_skill_hint_part_1"
                            )}{" "}
                            {_.get(
                                this.props.translation,
                                "skills_management.define_new_skill_hint_part_2"
                            )}
                        </PopoverBody>
                    </UncontrolledPopover>
                </h6>
                <Collapse isOpen={this.state.isOpenDefineNewNode}>
                    <Form>
                        <UncontrolledAlert
                            hidden={!this.state.visibleHints.includes(HINT.DEFINE_NEW_SKILL)}
                            toggle={this.onCloseHint(HINT.DEFINE_NEW_SKILL)}
                        >
                            {_.get(
                                this.props.translation,
                                "skills_management.define_new_skill_hint_part_1"
                            )}{" "}
                            {_.get(
                                this.props.translation,
                                "skills_management.define_new_skill_hint_part_2"
                            )}
                        </UncontrolledAlert>
                        <FormGroup row>
                            <Label sm="4">
                                {_.get(
                                    this.props.translation,
                                    "skills_management.define_new_label"
                                )}
                            </Label>
                            <Col sm="8">
                                <CustomSelect
                                    value={nodeTypeOptions.find(
                                        (elem) => elem.value === this.state.defineNewNodeType
                                    )}
                                    options={nodeTypeOptions}
                                    isSearchable={false}
                                    menuPosition="fixed"
                                    onChange={this.handleChangeSelectValue("defineNewNodeType")}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm="4">
                                {_.get(this.props.translation, "shared.name_label")}
                            </Label>
                            <Col sm="8">
                                <Input
                                    placeholder={_.get(this.props.translation, "shared.name_label")}
                                    value={this.state.defineNewNodeName}
                                    onChange={this.handleChange("defineNewNodeName")}
                                    required
                                />
                            </Col>
                        </FormGroup>
                        <Collapse
                            isOpen={
                                this.state.defineNewNodeType === NODE_TYPE.SKILL &&
                                this.state.isDefineNewNodeInCluster
                            }
                        >
                            <FormGroup row key="select">
                                <Label sm="4">
                                    {_.get(this.props.translation, "system_nodes.cluster_label")}
                                </Label>
                                <Col sm="8">
                                    <CustomSelect
                                        id={"define_new_skill_cluster"}
                                        value={clustersOptions.find(
                                            (elem) => elem.value === this.state.newSkillClusterId
                                        )}
                                        options={clustersOptions}
                                        placeholder={_.get(
                                            this.props.translation,
                                            "system_nodes.cluster_select_placeholder"
                                        )}
                                        isSearchable={false}
                                        menuPosition="fixed"
                                        onChange={this.handleChangeSelectValue("newSkillClusterId")}
                                        isRequired
                                    />
                                </Col>
                            </FormGroup>
                        </Collapse>
                        <Button
                            color="success"
                            block
                            disabled={
                                _.isEmpty(this.state.defineNewNodeName) ||
                                (this.state.defineNewNodeType === NODE_TYPE.SKILL &&
                                    this.state.isDefineNewNodeInCluster &&
                                    !this.state.newSkillClusterId)
                            }
                            onClick={this.onCreateCustomNode}
                        >
                            {_.get(this.props.translation, "actions.add_label")}
                        </Button>
                    </Form>
                </Collapse>
            </div>
        );
    }

    renderBreadCrumbs() {
        if (
            (!this.props.currentSkillId && !this.props.currentClusterId) ||
            !this.props.currentClusterData
        ) {
            return null;
        }

        if (!this.props.currentSkillId) {
            return (
                <div className="bread-crumbs-container">
                    <div className="bread-crumbs-element">
                        <Skill
                            className={LEVEL_CLASS_NAME.NO_LEVEL}
                            text={this.props.currentClusterData.categoryTitle}
                            title={this.props.currentClusterData.categoryTitle}
                            isSelected
                            isTextOverflowEllipsis
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="bread-crumbs-container">
                    <div className="bread-crumbs-element">
                        <Skill
                            text={this.props.currentSkillData.categoryTitle}
                            title={this.props.currentSkillData.categoryTitle}
                            isTextOverflowEllipsis
                        />
                        <Icon type="arrow_right" size="sm" />
                    </div>
                    <div className="bread-crumbs-element">
                        <Skill
                            className={
                                LEVEL_CLASS_NAME[this.props.currentSkillData.level] ||
                                LEVEL_CLASS_NAME.NO_LEVEL
                            }
                            text={this.props.currentSkillData.title}
                            title={this.props.currentSkillData.title}
                            isSelected
                            isTextOverflowEllipsis
                        />
                    </div>
                </div>
            );
        }
    }

    renderCurrentClusterSkills() {
        if (this.props.loadingCurrentClusterSkills) {
            return (
                <div className="current-cluster-skills-container">
                    <div className="text-center">
                        {_.get(this.props.translation, "actions.fetching_message")}
                    </div>
                </div>
            );
        }
        if (!this.props.currentClusterSkills || this.props.currentClusterSkills.length === 0) {
            return null;
        }

        let listItems = _.map(this.props.currentClusterSkills, (skill) => {
            let isContains = !!this.props.skills.find((elem) => elem.id === skill.id);
            let isCurrent = skill.id === this.props.currentSkillId;
            let level = isCurrent ? this.props?.currentSkillData?.level : null;

            return (
                <Skill
                    key={skill.id}
                    className={
                        (isContains ? "skill-added " : "skill-not-added ") +
                        (isCurrent ? LEVEL_CLASS_NAME[level] || LEVEL_CLASS_NAME.NO_LEVEL : "")
                    }
                    isTextOverflowEllipsis
                    text={skill.title}
                    level={level}
                    isSelected={isCurrent}
                    title={
                        isContains
                            ? _.replace(
                                  _.get(
                                      this.props.translation,
                                      "skills_management.select_skill_label"
                                  ),
                                  "{skillName}",
                                  skill.title
                              )
                            : _.replace(
                                  _.get(
                                      this.props.translation,
                                      "skills_management.add_skill_label"
                                  ),
                                  "{skillName}",
                                  skill.title
                              )
                    }
                    iconsTitle={{
                        add: _.replace(
                            _.get(this.props.translation, "skills_management.add_skill_label"),
                            "{skillName}",
                            skill.title
                        ),
                        remove: _.get(
                            this.props.translation,
                            "skills_management.remove_skill_label"
                        ),
                    }}
                    iconsPositions={{
                        topRight: isContains ? "remove" : "add",
                    }}
                    onClick={
                        isContains
                            ? this.onSelectSkill(skill.id, this.props.currentClusterId)
                            : this.onAddSkill(skill)
                    }
                    onRemoveClick={this.onRemoveSkill(skill.id)}
                />
            );
        });

        return <div className="current-cluster-skills-container">{listItems}</div>;
    }

    renderLevelsFormGroup = () => {
        if (this.props.currentSkillId && this.props.currentSkillData) {
            let skill = this.props.currentSkillData;

            let options = LEVEL_OPTIONS.map((level) => {
                return {
                    value: level,
                    label: _.get(
                        this.props.translation,
                        `skill_properties.levels_values.${LEVEL_TRANSLATION_LABEL[level]}_with_short_range`
                    ),
                };
            });

            return (
                <FormGroup row>
                    <Label sm="4">
                        {_.get(this.props.translation, "skill_properties.level_label") + "*"}
                    </Label>
                    <Col sm="8">
                        <CustomSelect
                            value={
                                skill.level
                                    ? _.find(options, (option) => option.value === skill.level)
                                    : null
                            }
                            options={options}
                            styles={SELECT_LEVELS_STYLES}
                            placeholder={_.get(
                                this.props.translation,
                                "skill_properties.level_select_placeholder"
                            )}
                            isSearchable={false}
                            menuPosition="fixed"
                            onChange={this.onUpdateSimpleSkillProperty(SKILL_PROPERTY.LEVEL)}
                        />
                    </Col>
                </FormGroup>
            );
        }
    };

    renderEagernessFormGroup = () => {
        if (this.props.currentSkillId && this.props.currentSkillData) {
            let skill = this.props.currentSkillData;

            const customSingleValue = ({ data }) => (
                <div className="input-select-eagerness-single-value">
                    <div className="input-select-eagerness-single-value-icon">
                        {data && data.value ? (
                            <Icon type={EAGERNESS_ICON[data.value]} size="xs" />
                        ) : null}
                    </div>
                    <span>{data.label}</span>
                </div>
            );

            const customOption = (props) => {
                let { data, isSelected } = props;

                return (
                    <div
                        className={`select-eagerness-custom-option ${
                            isSelected ? "select-eagerness-custom-option-selected" : ""
                        } ${EAGERNESS_CLASS_NAME[data.value]}`}
                    >
                        {data && data.value ? (
                            <div className="select-eagerness-custom-option-icon">
                                <Icon type={EAGERNESS_ICON[data.value]} size="xs" />
                            </div>
                        ) : null}
                        <components.Option {...props} />
                    </div>
                );
            };

            let options = [
                {
                    value: null,
                    label: _.get(
                        this.props.translation,
                        "skill_properties.eagerness_select_placeholder"
                    ),
                },
                ...EAGERNESS_OPTIONS.map((eagerness) => {
                    return {
                        value: eagerness,
                        label: _.get(
                            this.props.translation,
                            `skill_properties.eagerness_values.${EAGERNESS_TRANSLATION_LABEL[eagerness]}`
                        ),
                    };
                }),
            ];

            return (
                <FormGroup row>
                    <Label sm="4">
                        {_.get(this.props.translation, "skill_properties.eagerness_label")}
                    </Label>
                    <Col sm="8">
                        <CustomSelect
                            value={
                                skill.eagerness
                                    ? _.find(options, (option) => option.value === skill.eagerness)
                                    : null
                            }
                            options={options}
                            styles={SELECT_EAGERNESS_STYLES}
                            placeholder={_.get(
                                this.props.translation,
                                "skill_properties.eagerness_select_placeholder"
                            )}
                            isSearchable={false}
                            menuPosition="fixed"
                            components={{ SingleValue: customSingleValue, Option: customOption }}
                            onChange={this.onUpdateSimpleSkillProperty(SKILL_PROPERTY.EAGERNESS)}
                        />
                    </Col>
                </FormGroup>
            );
        }
    };

    renderRequirementCoefficientFormGroup = () => {
        if (this.props.currentSkillId && this.props.currentSkillData) {
            let skill = this.props.currentSkillData;

            let options = REQUIREMENT_COEFFICIENT_OPTIONS.map((requirementCoefficient) => {
                return {
                    value: requirementCoefficient,
                    label: _.get(
                        this.props.translation,
                        `skill_properties.requirement_coefficient_values.${REQUIREMENT_COEFFICIENT_TRANSLATION_LABEL[requirementCoefficient]}`
                    ),
                };
            });

            return (
                <FormGroup row>
                    <Label sm="4">
                        {_.get(
                            this.props.translation,
                            "skill_properties.requirement_coefficient_label"
                        ) + "*"}
                    </Label>
                    <Col sm="8">
                        <CustomSelect
                            value={
                                skill.requirementCoefficient
                                    ? _.find(
                                          options,
                                          (option) => option.value === skill.requirementCoefficient
                                      )
                                    : null
                            }
                            options={options}
                            styles={SELECT_REQUIREMENT_COEFFICIENTS_STYLES}
                            placeholder={_.get(
                                this.props.translation,
                                "skill_properties.requirement_coefficient_select_placeholder"
                            )}
                            isSearchable={false}
                            menuPosition="fixed"
                            onChange={this.onUpdateSimpleSkillProperty(
                                SKILL_PROPERTY.REQUIREMENT_COEFFICIENT
                            )}
                        />
                    </Col>
                </FormGroup>
            );
        }
    };

    renderSkillMetadata = () => {
        // TODO: (BE) add custom metadata to backend
        if (
            !this.props.currentSkillId ||
            !this.props.currentSkillData ||
            this.props.currentSkillData.isNotModerated
        ) {
            return null;
        }

        let result = [];

        // TODO: remove hardcoded values
        // if (this.state.currentNode has versions)
        if (this.props.currentSkillData.categoryId !== 666) {
            result.push(this.renderSkillVersions());
        }

        // TODO: remove hardcoded values
        // if (this.state.currentNode is language)
        if (this.props.currentSkillData.categoryId === 666) {
            result.push(this.renderLanguageMetadata());
        }

        return result;
    };

    renderSkillLifeCycleFormGroup() {
        const { acquiredAt, forgottenAt } = this.props.currentSkillData?.skillLifecycle ?? {};

        return (
            <>
                <FormGroup row>
                    <Col md="4">
                        <Label for="acquiredAt">
                            {_.get(this.props.translation, "skill_properties.acquired_at")}
                        </Label>
                    </Col>
                    <Col md="8">
                        <Input
                            type="month"
                            name="acquiredAt"
                            id="acquiredAt"
                            value={acquiredAt ? moment(acquiredAt).format("YYYY-MM") : null}
                            onChange={this.onUpdateLifeCycleSkillProperty("skillLifecycle")}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="4">
                        <Label for="forgottenAt">
                            {_.get(this.props.translation, "skill_properties.forgotten_at")}
                        </Label>
                    </Col>
                    <Col md="8">
                        <Input
                            type="month"
                            max={moment(forgottenAt).format("YYYY-MM")}
                            min={
                                acquiredAt
                                    ? moment(acquiredAt).format("YYYY-MM")
                                    : moment().format("YYYY-MM")
                            }
                            name="forgottenAt"
                            id="forgottenAt"
                            value={forgottenAt ? moment(forgottenAt).format("YYYY-MM") : null}
                            onChange={this.onUpdateLifeCycleSkillProperty("skillLifecycle")}
                        />
                    </Col>
                </FormGroup>
            </>
        );
    }

    renderLanguageMetadata = () => {
        let skill = this.props.currentSkillData;
        let spokenLevel = skill ? skill.spokenLevel : null;
        let understandingLevel = skill ? skill.understandingLevel : null;

        // TODO: remove hardcoded values
        switch (skill.id) {
            case 10000:
                spokenLevel = spokenLevel || 4;
                understandingLevel = understandingLevel || 4;
                break;

            case 10001:
                spokenLevel = spokenLevel || 2;
                understandingLevel = understandingLevel || 1;
                break;

            case 10002:
                spokenLevel = spokenLevel || 1;
                understandingLevel = understandingLevel || 2;
                break;

            default:
                break;
        }

        let levelsOptions = [
            {
                value: null,
                label: _.get(
                    this.props.translation,
                    "skill_properties.language_level_select_placeholder"
                ),
            },
            ...LANGUAGE_LEVEL_OPTIONS.map((level) => {
                return {
                    value: level,
                    label: _.get(
                        this.props.translation,
                        `skill_properties.language_levels_values.${LANGUAGE_LEVEL_TRANSLATION_LABEL[level]}`
                    ),
                };
            }),
        ];

        return [
            <FormGroup row key="skill-spoken-level">
                <Label sm="4">
                    {_.get(this.props.translation, "skill_properties.spoken_level_label")}
                </Label>
                <Col sm="8">
                    <CustomSelect
                        value={
                            spokenLevel
                                ? levelsOptions.find((elem) => elem.value === spokenLevel)
                                : null
                        }
                        options={levelsOptions}
                        placeholder={_.get(
                            this.props.translation,
                            "skill_properties.language_level_select_placeholder"
                        )}
                        isSearchable={false}
                        menuPosition="fixed"
                        onChange={this.onUpdateSimpleSkillProperty(SKILL_PROPERTY.SPOKEN_LEVEL)}
                    />
                </Col>
            </FormGroup>,
            <FormGroup row key="skill-understanding-level">
                <Label sm="4">
                    {_.get(this.props.translation, "skill_properties.understanding_level_label")}
                </Label>
                <Col sm="8">
                    <CustomSelect
                        value={
                            understandingLevel
                                ? levelsOptions.find((elem) => elem.value === understandingLevel)
                                : null
                        }
                        options={levelsOptions}
                        placeholder={_.get(
                            this.props.translation,
                            "skill_properties.language_level_select_placeholder"
                        )}
                        isSearchable={false}
                        menuPosition="fixed"
                        onChange={this.onUpdateSimpleSkillProperty(
                            SKILL_PROPERTY.UNDERSTANDING_LEVEL
                        )}
                    />
                </Col>
            </FormGroup>,
        ];
    };

    renderSkillVersions = () => {
        // TODO: add functionality - on save checks that the ratio and the version must be selected both, not just one
        // TODO: (BE) list of versions as part of the skills info
        // const VERSION_VALUES = [
        //     "v10",
        //     "v9",
        //     "v8",
        //     "v7",
        //     "v6",
        //     "v5",
        //     "v4",
        //     "v3",
        //     "v2",
        //     "v1",
        //     "Define New",
        // ];

        let skill = this.props.currentSkillData;
        let ratio = skill && skill.version ? skill.version.ratio : null;
        let version = skill && skill.version ? skill.version.value : null;

        let ratioOptions = [
            {
                value: null,
                label: _.get(
                    this.props.translation,
                    "skill_properties.version_ratio_select_placeholder"
                ),
            },
            ...VERSION_RATIO_OPTIONS.map((ratio) => {
                return {
                    value: ratio,
                    label: _.get(
                        this.props.translation,
                        `skill_properties.version_ratio_values.${VERSION_RATIO_TRANSLATION_LABEL[ratio]}`
                    ),
                };
            }),
        ];

        let versionOptions = [
            {
                value: null,
                label: _.get(
                    this.props.translation,
                    "skill_properties.version_value_select_placeholder"
                ),
            },
            ...(this.props.currentSkillData.versions
                ? this.props.currentSkillData.versions.map((version) => {
                      return { value: version, label: version };
                  })
                : []),
            { value: "Define New", label: "Define New" },
        ];

        return (
            <FormGroup row key="skill-version">
                <Label sm="4">
                    {_.get(this.props.translation, "skill_properties.version_label")}
                </Label>
                <Col className="skill-version-ratio-col">
                    <CustomSelect
                        value={ratio ? ratioOptions.find((elem) => elem.value === ratio) : null}
                        options={ratioOptions}
                        placeholder={_.get(
                            this.props.translation,
                            "skill_properties.version_ratio_select_placeholder"
                        )}
                        isSearchable={false}
                        menuPosition="fixed"
                        onChange={this.onUpdateSkillVersion("ratio")}
                    />
                </Col>
                <Col className="skill-version-value-col">
                    <CustomSelect
                        value={
                            version ? versionOptions.find((elem) => elem.value === version) : null
                        }
                        options={versionOptions}
                        placeholder={_.get(
                            this.props.translation,
                            "skill_properties.version_value_select_placeholder"
                        )}
                        isSearchable={false}
                        menuPosition="fixed"
                        onChange={this.onUpdateSkillVersion("value")}
                    />
                </Col>
            </FormGroup>
        );
    };

    renderSkillNotesByMeFormGroup() {
        if (this.props.currentSkillId && this.props.currentSkillData) {
            let notes = this.props.currentSkillData.notes;
            return (
                <FormGroup row>
                    <Label sm="4">
                        {_.get(this.props.translation, "skill_properties.notes_by_me_label")}
                    </Label>
                    <Label sm="8">
                        <span>
                            {_.get(
                                this.props.translation,
                                "skill_properties.notes_by_me_number_label_part_1"
                            )}
                        </span>
                        <Icon
                            className="notes-icon"
                            type="notes"
                            number={notes ? notes.length : 0}
                            title={_.get(
                                this.props.translation,
                                "skills_management.show_notes_label"
                            )}
                            onClick={this.onNotesClick(
                                this.props.currentSkillId,
                                this.props.currentClusterId
                            )}
                        />
                        <span>
                            {_.get(
                                this.props.translation,
                                "skill_properties.notes_by_me_number_label_part_2"
                            )}
                        </span>
                    </Label>
                </FormGroup>
            );
        }
    }

    renderSearchSkills() {
        if (this.props.loadingSearchSkillsAndClusters) {
            return (
                <div className="text-center">
                    {_.get(this.props.translation, "actions.fetching_message")}
                </div>
            );
        }

        if (this.props.searchSkills && this.props.searchClusters) {
            if (this.props.searchSkills.length > 0 || this.props.searchClusters.length > 0) {
                let listItems = [];

                _.map(this.props.searchSkills, (skill) => {
                    let isContains = !!this.props.skills.find((elem) => elem.id === skill.id);
                    let isCurrent = skill.id === this.props.currentSkillId;
                    let level = isCurrent ? this.props.currentSkillData.level : null;

                    listItems.push(
                        <ListGroupItem key={skill.id}>
                            <Skill
                                className={
                                    (isContains ? "skill-added " : "skill-not-added ") +
                                    (isCurrent
                                        ? LEVEL_CLASS_NAME[level] || LEVEL_CLASS_NAME.NO_LEVEL
                                        : "")
                                }
                                text={skill.title}
                                level={level}
                                isSelected={isCurrent}
                                title={
                                    isContains
                                        ? _.replace(
                                              _.get(
                                                  this.props.translation,
                                                  "skills_management.select_skill_label"
                                              ),
                                              "{skillName}",
                                              skill.title
                                          )
                                        : _.replace(
                                              _.get(
                                                  this.props.translation,
                                                  "skills_management.add_skill_label"
                                              ),
                                              "{skillName}",
                                              skill.title
                                          )
                                }
                                iconsPositions={{
                                    topRight: !isContains ? "add" : null,
                                }}
                                onClick={
                                    isContains
                                        ? this.onSelectSkill(skill.id, this.props.currentClusterId)
                                        : this.onAddSkill(skill)
                                }
                            />
                            {this.renderSemanticContext(skill)}
                        </ListGroupItem>
                    );
                });

                _.map(this.props.searchClusters, (cluster) => {
                    let isContains =
                        _.find(
                            this.props.emptyClusters,
                            (elem) => elem.categoryId === cluster.categoryId
                        ) ||
                        _.find(
                            this.props.skills,
                            (skill) => skill.categoryId === cluster.categoryId
                        );
                    let isCurrent = cluster.categoryId === this.props.currentClusterId;

                    let clusterSkills = _.map(cluster.skills, (skill) => {
                        skill.isContains = !!this.props.skills.find((elem) => elem.id === skill.id);
                        return skill;
                    });
                    listItems.push(
                        <ListGroupItem key={cluster.categoryId}>
                            <Skill
                                className={`cluster ${
                                    isContains ? "cluster-added " : "cluster-not-added "
                                }`}
                                id={`cluster-${cluster.categoryId}`}
                                text={cluster.categoryTitle}
                                isSelected={isCurrent}
                                title={
                                    isContains
                                        ? _.get(
                                              this.props.translation,
                                              "skills_management.select_cluster_label"
                                          )
                                        : _.get(
                                              this.props.translation,
                                              "skills_management.add_cluster_label"
                                          )
                                }
                                iconsPositions={{
                                    topRight: !isContains ? "cluster_add" : "cluster",
                                }}
                                onClick={
                                    isContains
                                        ? this.onSelectCluster(cluster.categoryId)
                                        : this.onAddEmptyCluster(cluster)
                                }
                            />
                            <ClusterSkillsPopover
                                target={`cluster-${cluster.categoryId}`}
                                data={clusterSkills}
                                onAddSkill={this.onAddSkill}
                            />
                            {this.renderSemanticContext(cluster, true)}
                        </ListGroupItem>
                    );
                });

                return (
                    <ListGroup className="search-items-container" flush>
                        {listItems}
                    </ListGroup>
                );
            } else {
                return (
                    <div className="text-center">
                        {_.get(
                            this.props.translation,
                            "skills_management.skill_or_clusters_not_found_label"
                        )}
                    </div>
                );
            }
        }
    }

    renderSuggestedSkills() {
        if (!this.props.skills || this.props.skills.length === 0) {
            return null;
        }

        if (this.props.loadingSuggestedSkills) {
            return (
                <div className="text-center">
                    {_.get(this.props.translation, "actions.fetching_message")}
                </div>
            );
        }

        if (!this.props.suggestedSkills) {
            return null;
        }
        let listItems = [];

        _.map(this.props.suggestedSkills, (skill) => {
            let isContains = !!this.props.skills.find((elem) => elem.id === skill.id);
            listItems.push(
                <ListGroupItem key={skill.id}>
                    <Skill
                        className={isContains ? "skill-added" : "skill-not-added"}
                        text={skill.title}
                        title={
                            !isContains
                                ? _.replace(
                                      _.get(
                                          this.props.translation,
                                          "skills_management.add_skill_label"
                                      ),
                                      "{skillName}",
                                      skill.title
                                  )
                                : skill.title
                        }
                        iconsPositions={{
                            topRight: !isContains ? "add" : null,
                        }}
                        onClick={
                            isContains
                                ? this.onSelectSkill(skill.id, this.props.currentClusterId)
                                : this.onAddSkill(skill)
                        }
                    />
                    {this.renderSemanticContext(skill)}
                </ListGroupItem>
            );
        });

        return (
            <ListGroup className="search-items-container" flush>
                {listItems}
            </ListGroup>
        );
    }

    renderSemanticContext = (obj, isCluster) => {
        return (
            <div className="node-semantic-context">
                {!isCluster ? (
                    <div className="cluster" title={obj.categoryTitle}>
                        {obj.categoryTitle}
                    </div>
                ) : null}
            </div>
        );
    };

    copyToClipboard = async (elementId) => {
        const element = document.getElementById(elementId);
        const elementText = element.href;
        await navigator.clipboard.writeText(elementText);
    };

    onCloseHint = (hint) => () => {
        localStorage.setItem(hint, hint);
        let data = _.cloneDeep(this.state.visibleHints) || [];
        data = data.filter((elem) => elem !== hint);
        this.setState({ visibleHints: data });
    };

    toggleCollapse = (key) => () => {
        this.setState({ [key]: !this.state[key] });
        key === "isOpenDefineNewNode" && !this.state[key] && this.props.onGetClustersList();
    };

    handleChange = (fieldName) => (e) => {
        this.setState({ [fieldName]: e.target.value });
    };

    handleChangeFlag = (key) => () => {
        this.setState({ [key]: !this.state[key] });
    };

    handleChangeSelectValue = (fieldName) => (selectedOption) => {
        this.setState({ [fieldName]: selectedOption.value });
    };

    onChangeSelectedProfiles = (id) => () => {
        this.props.onChangeSelectedProfile(id);
    };

    onRemoveProfile = (id) => () => {
        this.props.onRemoveProfile(id);
    };

    onAddProfile = (profile, altTitles) => () => {
        this.props.onAddProfile(profile, altTitles, this.state.searchProfilesValue);
        this.props.onChangeSelectedProfile(profile.id);
    };

    onChangeSearchProfilesText = (e) => {
        if (this.props.searchProfiles) {
            this.props.onCleanSearchProfiles();
        }
        this.setState({ searchProfilesValue: e.target.value });
        this.onGetSearchProfiles(e.target.value);
    };

    onSearchProfilesKeyPress = (e) => {
        if (e.key === "Enter") {
            this.props.onGetSearchProfiles(this.state.searchProfilesValue);
        }
    };

    onGetSearchProfiles = (value) => {
        value.length >= 3 && this.props.onGetSearchProfiles(value);
    };

    onChangeSearchSkillsText = (e) => {
        if (this.props.searchSkills) {
            this.props.onCleanSearchSkillsAndClusters();
        }
        this.setState({ searchSkillsValue: e.target.value });
    };

    onSearchSkillsKeyPress = (e) => {
        if (e.key === "Enter") {
            this.props.onGetSearchSkillsAndClusters(this.state.searchSkillsValue);
        }
    };

    onGetSearchSkills = () => {
        this.props.onGetSearchSkillsAndClusters(this.state.searchSkillsValue);
    };

    onSelectSkill = (skillId, categoryId) => () => {
        this.props.onSelectSkill(skillId, categoryId);
    };

    onAddSkill = (skillData) => () => {
        !this.props.currentClusterId && this.props.onShowPotentialClustersModal();
        this.props.onAddSkill(skillData, this.props.currentClusterId);
    };

    onRemoveSkill = (skillId) => () => {
        if (this.state.showRemoveSkillWarning) {
            this.setState({ skillToRemoveId: skillId });
            this.props.onShowRemoveSkillModal();
        } else {
            this.props.onRemoveSkill(skillId);
        }
    };

    onHideRemoveSkillWarning = () => {
        this.setState({ showRemoveSkillWarning: false });
    };

    onRemoveSkillSubmit = () => {
        this.props.onRemoveSkill(this.state.skillToRemoveId);
    };

    onAddEmptyCluster = (cluster) => () => {
        this.props.onAddEmptyCluster(cluster);
        this.props.onSelectCluster(cluster.categoryId);
    };

    onSelectCluster = (clusterId, flags) => () => {
        this.props.onSelectCluster(clusterId, flags);
    };

    onRemoveCluster = (clusterId, clusterTitle) => () => {
        let clusterToRemove = _.find(
            this.props.skillsTree,
            (elem) => elem.categoryId === clusterId
        );
        if (clusterToRemove && clusterToRemove.isNotModerated) {
            return;
        } else if (this.state.showRemoveClusterWarning) {
            this.setState({ clusterToRemoveId: clusterId, clusterToRemoveTitle: clusterTitle });
            this.props.onShowRemoveClusterModal();
        } else {
            this.props.onRemoveCluster(clusterId);
        }
    };

    getClusterToRemoveData = () => {
        return {
            categoryId: this.state.clusterToRemoveId,
            categoryTitle: this.state.clusterToRemoveTitle,
        };
    };

    onHideRemoveClusterWarning = () => {
        this.setState({ showRemoveClusterWarning: false });
    };

    onRemoveClusterSubmit = () => {
        this.props.onRemoveCluster(this.state.clusterToRemoveId);
    };

    onNotesClick = (skillId, categoryId) => () => {
        if (this.props.currentSkillId !== skillId) {
            this.props.onSelectSkill(skillId, categoryId);
        }
        this.props.onShowSkillNotesModal(skillId);
    };

    onAddNewNote = ({ text, id }) => {
        let notes = _.cloneDeep(this.props.currentSkillData.notes) || [];
        notes.push({
            date: moment().format("YYYY-MM-DD"),
            text,
            tempId: id,
        });
        this.props.updateSkillProperty(SKILL_PROPERTY.NOTES, notes);
    };

    onRemoveNote = (id) => {
        let notes = _.cloneDeep(this.props.currentSkillData.notes) || [];
        const filteredNotes = notes.filter((note) => note.tempId !== id);
        this.props.updateSkillProperty(SKILL_PROPERTY.NOTES, filteredNotes);
    };

    onUpdateSimpleSkillProperty = (key) => (selectedOption) => {
        this.props.updateSkillProperty(key, selectedOption.value);
    };

    onUpdateLifeCycleSkillProperty =
        (key) =>
        ({ target: { value, name } }) => {
            this.props.updateSkillProperty(key, { [name]: moment(value).toISOString() }, true);
        };

    onUpdateSkillVersion = (key) => (selectedOption) => {
        let value = {
            [key]: selectedOption.value,
        };
        if (key === "value" && value.value === "Define New") {
            this.props.onShowCustomSkillLevelModal();
        }
        this.props.updateSkillProperty(SKILL_PROPERTY.VERSION, value, true);
    };

    onCreateCustomNode = (e) => {
        e.preventDefault();
        switch (this.state.defineNewNodeType) {
            case NODE_TYPE.SKILL:
                let newSkill = {
                    title: this.state.defineNewNodeName,
                    isNotModerated: true,
                };
                let skillCluster = _.find(
                    this.props.skill_clusters,
                    (cluster) => cluster.id === this.state.newSkillClusterId
                );
                newSkill.categoryId = skillCluster.id;
                newSkill.categoryTitle = skillCluster.titles.en;
                newSkill.resourceType = "SKILL";
                this.props.AddNewSkill(newSkill);
                break;

            case NODE_TYPE.CLUSTER:
                let newCluster = {
                    titles: { en: this.state.defineNewNodeName },
                    resourceType: "SKILL_CLUSTER",
                };
                this.props.onAddNewCluster(newCluster);
                break;

            default:
                break;
        }
    };
}

SkillsSetup.propTypes = {
    translation: PropTypes.object.isRequired,
    mode: PropTypes.string.isRequired,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageSource: PropTypes.string,
    profiles: PropTypes.array,
    selectedProfiles: PropTypes.array,
    suggestedProfiles: PropTypes.array,
    searchProfiles: PropTypes.array,
    loadingProfiles: PropTypes.bool,
    loadingSearchProfiles: PropTypes.bool,
    loadingSuggestedProfiles: PropTypes.bool,
    skills: PropTypes.array,
    skillsTree: PropTypes.array,
    clusters: PropTypes.array,
    searchSkills: PropTypes.array,
    searchClusters: PropTypes.array,
    emptyClusters: PropTypes.array,
    suggestedSkills: PropTypes.array,
    currentSkillId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currentSkillData: PropTypes.object,
    currentClusterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currentClusterData: PropTypes.object,
    currentClusterSkills: PropTypes.array,
    loadingSuggestedSkills: PropTypes.bool,
    loadingSearchSkillsAndClusters: PropTypes.bool,
    loadingCurrentClusterSkills: PropTypes.bool,
    onChangeSelectedProfile: PropTypes.func.isRequired,
    onAddProfile: PropTypes.func.isRequired,
    onRemoveProfile: PropTypes.func.isRequired,
    onGetSearchProfiles: PropTypes.func.isRequired,
    onCleanSearchProfiles: PropTypes.func.isRequired,
    onGetSearchSkillsAndClusters: PropTypes.func.isRequired,
    onCleanSearchSkillsAndClusters: PropTypes.func.isRequired,
    onSelectSkill: PropTypes.func.isRequired,
    onSelectCluster: PropTypes.func.isRequired,
    onAddSkill: PropTypes.func.isRequired,
    onAddEmptyCluster: PropTypes.func.isRequired,
    onRemoveSkill: PropTypes.func.isRequired,
    onRemoveCluster: PropTypes.func.isRequired,
    updateSkillProperty: PropTypes.func.isRequired,
    onShowSkillNotesModal: PropTypes.func.isRequired,
    onShowRemoveSkillModal: PropTypes.func.isRequired,
    onShowRemoveClusterModal: PropTypes.func.isRequired,
    onShowPotentialCLustersModal: PropTypes.func.isRequired,
    onShowCustomSkillLevelModal: PropTypes.func.isRequired,
    user: PropTypes.object,
    AddNewSkill: PropTypes.func.isRequired,
};

export default SkillsSetup;
