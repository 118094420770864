import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { Button, Input, PopoverBody, Table, UncontrolledPopover } from "reactstrap";
import Icon from "../../common/icon/Icon";
import "./InvitationsManagement.scss";
import InvitationsManagementLifecycle from "./components/InvitationsManagementLifecycle/InvitationsManagementLifecycle.container";
import DatePicker from "react-datepicker";
import SendFeedbackModal from "./modals/sendFeedbackModal/SendFeedbackModal.container";
import TopXCompareModal from "./modals/topXCompareModal/TopXCompareModal.container";
import "react-datepicker/dist/react-datepicker.css";
import { monthArr } from "../../../constants/constants";

class InvitationsManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            applicants: [],
        };
    }

    componentDidMount() {
        this.props.onMount(this.props.location.state.job.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.applicantsAppliedToJob !== this.props.applicantsAppliedToJob) {
            this.setState({
                applicants: this.props.applicantsAppliedToJob,
            });
        }
    }

    render() {
        const {
            translation,
            location,
            onOpenSendFeedbackModal,
            onOpenTopXCompareModal,
        } = this.props;

        const {
            state: { job },
        } = location;

        const title = [
            job.title,
            job.reference,
            job.startDate
                ? `${_.get(translation, "invitations_management_page.planned_start_date")}: ${
                      job.startDate
                  }`
                : "",
        ]
            .filter(Boolean)
            .join(" - ");

        return (
            <>
                <div className="invitations-management">
                    <h4 className="page-header">{title}</h4>
                    <InvitationsManagementLifecycle jobId={job.id} />
                    <Table>
                        <thead>
                            <tr>
                                <th>
                                    <div className="table-header-row-item">
                                        <Icon type="im_person" />
                                        {_.get(
                                            translation,
                                            `invitations_management_page.name_label`
                                        )}
                                    </div>
                                </th>
                                <th>
                                    <div className="table-header-row-item">
                                        <Icon type="interviewed" />
                                        {_.get(
                                            translation,
                                            `invitations_management_page.interviewed_label`
                                        )}
                                    </div>
                                </th>
                                <th>
                                    <div className="table-header-row-item">
                                        <Icon type="feedback" />
                                        {_.get(
                                            translation,
                                            `invitations_management_page.feedback_label`
                                        )}
                                    </div>
                                </th>
                                <th>
                                    <div className="table-header-row-item">
                                        <Icon type="hired" size="lg" />
                                        {_.get(
                                            translation,
                                            `invitations_management_page.hired_label`
                                        )}
                                    </div>
                                </th>
                                <th>
                                    <div className="table-header-row-item">
                                        <Icon type="contact" />
                                        {_.get(
                                            translation,
                                            `invitations_management_page.contacts_label`
                                        )}
                                    </div>
                                </th>
                                <th>
                                    <div className="table-header-row-item">
                                        <Icon type="lock_person" />
                                        <div className="lock-person-info-icon-wrapper">
                                            {_.get(
                                                translation,
                                                `invitations_management_page.private_note_label`
                                            )}
                                            <Icon
                                                type="info"
                                                color="info"
                                                size="sm"
                                                id="lock-person-info-icon"
                                            />
                                            <UncontrolledPopover
                                                placement="top"
                                                target="lock-person-info-icon"
                                                trigger="legacy"
                                            >
                                                <PopoverBody>
                                                    <div>
                                                        The <i>private note</i> allows you to link
                                                        private notes to applicants, to keep track
                                                        of them <i>beyond</i> this job. 'Private' as
                                                        you are the <i>only</i> one who will be able
                                                        to consult these notes; no one else. They
                                                        can be used e.g. for: impressions you made
                                                        during the interview, or for retrieving
                                                        excellent applicants that... just missed
                                                        getting hired <i>this</i> time.
                                                    </div>
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                        </div>
                                    </div>
                                </th>
                                <th className="full-compare">
                                    <div className="table-header-row-item">
                                        <Icon type="im_compare" size="xl" />
                                        {_.get(
                                            translation,
                                            `invitations_management_page.side_by_side_comparison`
                                        )}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.applicants.map((applicant, index) => {
                                return (
                                    <tr key={applicant.userId} className="table-row">
                                        <td>
                                            <div className="table-row-item">
                                                <small>{`${applicant.firstName} ${applicant.lastName}`}</small>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="date-input-wrapper">
                                                {applicant.interviewed ? (
                                                    <>
                                                        {_.map(applicant.interviewed, (item) => (
                                                            <div
                                                                className="date-wrapper"
                                                                key={item.toLocaleString()}
                                                            >
                                                                <h6>{item.toLocaleString()}</h6>
                                                                <Icon
                                                                    className="remove-date"
                                                                    type="cancel"
                                                                    size="sm"
                                                                    color="danger"
                                                                    onClick={() =>
                                                                        this.props.onRemoveInterviewDate(
                                                                            applicant.jobApplicationId,
                                                                            item
                                                                        )
                                                                    }
                                                                    title={_.get(
                                                                        translation,
                                                                        "invitations_management_page.remove_date_label"
                                                                    )}
                                                                />
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <small>
                                                        {_.get(translation, `shared.no_label`)}
                                                    </small>
                                                )}
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    onChange={(day) => {
                                                        const date = new Date(day);
                                                        const dateString = `${
                                                            monthArr[date.getMonth()]
                                                        }-${("0" + date.getDate()).slice(
                                                            -2
                                                        )}-${date.getFullYear()}`;
                                                        this.props.onAddInterviewDate(
                                                            applicant.jobApplicationId,
                                                            dateString
                                                        );
                                                    }}
                                                    customInput={
                                                        <Icon
                                                            className="plus-outline-icon"
                                                            type="plus_outline"
                                                            color="success"
                                                        />
                                                    }
                                                />
                                            </div>
                                        </td>
                                        <td className="table-row-item">
                                            {applicant.feedback.length ? (
                                                <small>
                                                    {_.get(translation, `shared.yes_label`)}
                                                </small>
                                            ) : (
                                                <Button
                                                    color="primary"
                                                    onClick={() =>
                                                        onOpenSendFeedbackModal(
                                                            applicant.jobApplicationId
                                                        )
                                                    }
                                                >
                                                    {_.get(
                                                        translation,
                                                        "invitations_management_page.send_feedback"
                                                    )}
                                                </Button>
                                            )}
                                        </td>
                                        <td>
                                            <div className="custom-select-wrapper">
                                                <Input
                                                    type="select"
                                                    name="selectMulti"
                                                    id="exampleSelectMulti"
                                                    placeholder={_.get(
                                                        translation,
                                                        "invitations_management_page.choose"
                                                    )}
                                                    value={_.get(
                                                        this.props.translation,
                                                        `shared.${
                                                            applicant.applicantMetaData.hired
                                                                ? "yes"
                                                                : "no"
                                                        }_label`
                                                    )}
                                                    onChange={(e) =>
                                                        this.props.onPutHiredJobApplicant(
                                                            applicant.jobApplicationId,
                                                            e.target.value === "Yes"
                                                        )
                                                    }
                                                >
                                                    <option>
                                                        {_.get(
                                                            this.props.translation,
                                                            "shared.yes_label"
                                                        )}
                                                    </option>
                                                    <option>
                                                        {_.get(
                                                            this.props.translation,
                                                            "shared.no_label"
                                                        )}
                                                    </option>
                                                </Input>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="table-row-item">
                                                <small className="contact-text">
                                                    {applicant.applicantMetaData.phone}
                                                    {"\n"}
                                                    <a
                                                        href={`mailto:${applicant.applicantMetaData.email}`}
                                                    >
                                                        {applicant.applicantMetaData.email}
                                                    </a>
                                                </small>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="table-row-text-area-item">
                                                <Input
                                                    type="textarea"
                                                    defaultValue={applicant.privateNotes}
                                                    onChange={(e) =>
                                                        this.setState({
                                                            [`private_note_${applicant.id}`]: e
                                                                .target.value,
                                                        })
                                                    }
                                                    onBlur={() =>
                                                        this.props.onPutPrivateNote(
                                                            applicant.jobApplicationId,
                                                            this.state[
                                                                `private_note_${applicant.id}`
                                                            ]
                                                        )
                                                    }
                                                    placeholder={_.get(
                                                        translation,
                                                        "invitations_management_page.private_note_label"
                                                    )}
                                                />
                                            </div>
                                        </td>
                                        {index === 0 && (
                                            <td
                                                rowSpan={this.state.applicants.length}
                                                className="full-compare-icon-wrapper"
                                            >
                                                <Icon
                                                    type="lightning"
                                                    color="secondary"
                                                    title={_.replace(
                                                        _.get(
                                                            translation,
                                                            `invitations_management_page.full_top_x_compare_label`
                                                        ),
                                                        "{x}",
                                                        this.state.applicants.length
                                                    )}
                                                    onClick={onOpenTopXCompareModal}
                                                />
                                            </td>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
                <SendFeedbackModal />
                <TopXCompareModal
                    jobId={this.props.location.state.job.id}
                    jobApplicants={this.state.applicants}
                />
            </>
        );
    }
}

InvitationsManagement.propTypes = {
    translation: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onOpenSendFeedbackModal: PropTypes.func.isRequired,
    onOpenTopXCompareModal: PropTypes.func.isRequired,
};

export default withRouter(InvitationsManagement);
