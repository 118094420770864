import { createAction, handleActions } from 'redux-actions';

//- Actions
export const setForbiddenError = createAction('SET_FORBIDDEN_ERROR');
export const clearForbiddenError = createAction('CLEAR_FORBIDDEN_ERROR');

export const setServerError = createAction('SET_SERVER_ERROR');

export const setUserSubRoleMissingError = createAction('SET_USER_SUB_ROLE_MISSING_ERROR');
export const clearUserSubRoleMissingError = createAction('CLEAR_USER_SUB_ROLE_MISSING_ERROR');

//- State
const initialState = {
    isForbidden: false,
    isServerError: false,
    isUserSubRoleMissing: false
};

//- Reducers
export default handleActions({

    SET_FORBIDDEN_ERROR: (state) => {
        return { ...state, isForbidden: true };
    },
    CLEAR_FORBIDDEN_ERROR: (state) => {
        return { ...state, isForbidden: false };
    },
    SET_SERVER_ERROR: (state) => {
        return { ...state, isServerError: true };
    },
    SET_USER_SUB_ROLE_MISSING_ERROR: (state) => {
        return { ...state, isUserSubRoleMissing: true };
    },
    CLEAR_USER_SUB_ROLE_MISSING_ERROR: (state) => {
        return { ...state, isUserSubRoleMissing: false };
    }

}, initialState);


//- Selectors
export const getErrorsState = state => state.errors;

export const getForbiddenErrorStatus = state => getErrorsState(state).isForbidden;

export const getServerErrorStatus = state => getErrorsState(state).isServerError;

export const getUserSubRoleMissingStatus = state => getErrorsState(state).isUserSubRoleMissing;