import { connect } from 'react-redux';
import LicenseAgreementModal from "./LicenseAgreementModal";
import { getTranslation } from '../../../../reducers/common/localization';
import { getActiveModalType, MODAL_LICENSE_AGREEMENT, hideModal } from '../../../../reducers/common/activeModal';

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_LICENSE_AGREEMENT
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(hideModal());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseAgreementModal);
