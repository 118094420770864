import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ApplicantRegistration.scss';
import _ from 'lodash';
import BackLink from '../../../common/buttons/backLink/BackLink.container';
import { ROUTES } from '../../../../constants/constants';
import LicenseAgreementModal from '../../../common/modals/licenseAgreementModal/LicenseAgreementModal.container';
import { FormGroup, Label, Input, Col, Button, Form } from 'reactstrap';
import { FormUtils } from '../../../../utils/formUtils';
import ContinentAndCountryFormGroups from '../../../common/formGroups/continentAndCountryFormGroups/ContinentAndCountryFormGroups.container';

class ApplicantRegistration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAgreeWithLicense: false,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            countryCode: null,
            city: "",
            district: "",
            street: "",
        };
    }

    render() {
        return (
            <div className="applicant-registration">
                <div className="back-link-container">
                    <BackLink to={ROUTES.LOGIN} label={_.get(this.props.translation, 'login_page.back_to_login_page_label')} />
                </div>
                {this.renderContent()}
                <LicenseAgreementModal onAgree={this.handleAgree} />
            </div>
        )
    }

    renderContent() {
        if (this.props.success) {
            return (
                <h6 className="text-center success-registration-message">
                    {_.get(this.props.translation, 'registration.success_registration_message')}
                </h6>
            )
        }

        return (
            <>
                <h4 className="text-center applicant-registration-header">{_.get(this.props.translation, 'applicant_registration_page.page_label')}</h4>
                <h6 className="applicant-registration-hint">{_.get(this.props.translation, 'applicant_registration_page.page_hint')}</h6>
                <Form onSubmit={this.onSubmitRegistration}>
                    {this.renderMainFormGroups()}
                    {this.renderLocationFromGroups()}
                    <FormGroup row>
                        <Col>
                            <FormGroup check>
                                <Label>
                                    <Input
                                        type="checkbox"
                                        checked={this.state.isAgreeWithLicense}
                                        onChange={this.handleChangeAgreementFlag}
                                    />
                                    {_.get(this.props.translation, "license_agreement.agree_label")}
                                    {" "}
                                    <Button color="link" onClick={this.handleClickShowLicenseAgreement}>
                                        {_.get(this.props.translation, "license_agreement.agree_link")}
                                    </Button>
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="auto">
                            <Button color="primary" type="submit" disabled={!this.state.isAgreeWithLicense}>
                                {_.get(this.props.translation, 'applicant_registration_page.submit_button')}
                            </Button>
                        </Col>
                    </FormGroup>
                </Form>
            </>
        )
    }

    renderMainFormGroups() {
        let formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField,
        };

        let params = [
            {
                fieldName: "firstName",
                title: _.get(this.props.translation, 'person_data.first_name_label'),
                isRequired: true
            },
            {
                fieldName: "lastName",
                title: _.get(this.props.translation, 'person_data.last_name_label'),
                isRequired: true
            },
            {
                fieldName: "email",
                title: _.get(this.props.translation, 'person_data.personal_email_label'),
                type: 'email',
                isRequired: true
            },
            {
                fieldName: "phoneNumber",
                title: _.get(this.props.translation, 'person_data.phone_number_label'),
            },
        ];

        return _.map(params, (fieldParams) => FormUtils.renderFormGroup(fieldParams, formParams));
    }

    renderLocationFromGroups() {
        let formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField,
        };

        let params = [
            {
                fieldName: "city",
                title: _.get(this.props.translation, 'shared.city_label'),
                isRequired: true
            },
            {
                fieldName: "district",
                title: _.get(this.props.translation, 'shared.district_label'),
            },
            {
                fieldName: "street",
                title: _.get(this.props.translation, 'shared.street_label'),
            }
        ];

        return (
            <>
                <ContinentAndCountryFormGroups
                    countryCode={this.state.countryCode}
                    handleChangeCountry={this.handleChangeCountry}
                />
                {_.map(params, (fieldParams) => FormUtils.renderFormGroup(fieldParams, formParams))}
            </>
        )
    }

    handleChangeField = (fieldName) => (e) => {
        this.setState({ [fieldName]: e.target.value });
    }

    handleChangeCountry = (countryCode) => {
        this.setState({ countryCode: countryCode });
    }

    handleChangeAgreementFlag = () => {
        this.setState({ isAgreeWithLicense: !this.state.isAgreeWithLicense });
    }

    handleAgree = () => {
        this.setState({ isAgreeWithLicense: true });
    };

    handleClickShowLicenseAgreement = () => {
        this.props.onShowLicenseAgreement();
    }

    onSubmitRegistration = (e) => {
        e.preventDefault();

        let data = {
            email: this.state.email,
            identityAlias: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phoneNumber: this.state.phoneNumber,
            },
            address: {
                countryCode: this.state.countryCode,
                city: this.state.city,
                district: this.state.district,
                street: this.state.street,
            }
        };

        this.props.onRegister(data);
    }
}

ApplicantRegistration.propTypes = {
    translation: PropTypes.object.isRequired,
    success: PropTypes.bool,
    onShowLicenseAgreement: PropTypes.func.isRequired,
    onRegister: PropTypes.func.isRequired
};

export default ApplicantRegistration;
