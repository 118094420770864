import React, { Component } from 'react';

class Contact extends Component {
	render() {
		return (
			<svg width="128" height="128" viewBox="0 0 128 128">
				<path fill="#2b77c0" d="M78.73144,39.38691a24.6131,24.6131,0,1,0,18.9678,40.291,2.4547,2.4547,0,0,0-3.79068-3.11977,19.71077,19.71077,0,1,1,4.51432-12.57372,4.33179,4.33179,0,0,1-.02636.6817c-.00449.02741.00629.05257.0027.07982a2.96042,2.96042,0,0,1-5.91636-.17567v-1.718a2.45364,2.45364,0,1,0-4.90728,0V63.9976a8.85442,8.85442,0,1,1-2.80348-6.466,2.45385,2.45385,0,1,0,3.35459-3.58222A13.77192,13.77192,0,1,0,90.67482,70.7905a7.8077,7.8077,0,0,0,4.784,1.66067,7.89051,7.89051,0,0,0,7.88328-7.88089v-1.718a2.338,2.338,0,0,0-.0927-.45946A24.609,24.609,0,0,0,78.73144,39.38691Z"/>
				<path fill="#3b4551" d="M108.16555,2.04554H27.19537A12.89571,12.89571,0,0,0,14.31375,14.92716V31.48925H10.01988a3.06705,3.06705,0,1,0,0,6.1341h4.29387v23.3096H10.01988a3.06705,3.06705,0,1,0,0,6.1341h4.29387v23.3096H10.01988a3.06705,3.06705,0,1,0,0,6.1341h4.29387v16.56208a12.89647,12.89647,0,0,0,12.88162,12.88162h80.97018a12.89725,12.89725,0,0,0,12.88162-12.88162V14.92716A12.89647,12.89647,0,0,0,108.16555,2.04554Zm6.74752,111.02729a6.75438,6.75438,0,0,1-6.74752,6.74752H42.53063V8.17965h65.63492a6.75438,6.75438,0,0,1,6.74752,6.74751Z"/>
			</svg>
		);
	}
}

export default Contact;
