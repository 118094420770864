import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ActivateEmployeeAccount.scss';
import _ from 'lodash';
import { FormGroup, Label, Input, Col, Button, Form } from 'reactstrap';
import ConfirmPasswordFormGroups from '../../../common/formGroups/confirmPasswordFormGroups/ConfirmPasswordFormGroups.container';
import { Utils } from '../../../../utils/utils';
import LicenseAgreementModal from '../../../common/modals/licenseAgreementModal/LicenseAgreementModal.container';
import { FormUtils } from '../../../../utils/formUtils';

class ActivateEmployeeAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: props.data.alias.firstName || "",
            lastName: props.data.alias.lastName || "",
            password: "",
            passwordConfirm: "",
            isAgreeWithLicense: false,
        }
    }

    render() {
        if (_.isNil(this.props.data)) {
            return null;
        }

        return (
            <div className="employee-account-activation">
                <h4 className="text-center employee-account-activation-header">{_.get(this.props.translation, 'activate_account_page.page_label')}</h4>
                <Form onSubmit={this.onSubmitActivation}>
                    <h6 className="employee-account-activation-hint">{_.get(this.props.translation, 'activate_account_page.activate_employee_personal_data_label')}</h6>
                    {this.renderPersonalDataFields()}
                    <FormGroup row>
                        <Label sm="3">{_.get(this.props.translation, 'person_data.professional_email_label')}</Label>
                        <Col sm="9">
                            <Input
                                type="email"
                                defaultValue={this.props.data.email}
                                disabled
                            >
                            </Input>
                        </Col>
                    </FormGroup>
                    <h6 className="employee-account-activation-hint">{_.get(this.props.translation, 'activate_account_page.activate_employee_password_label')}</h6>
                    <ConfirmPasswordFormGroups
                        password={this.state.password}
                        passwordConfirm={this.state.passwordConfirm}
                        handleChangeField={this.handleChangeField}
                    />
                    <FormGroup row>
                        <Col>
                            <FormGroup check>
                                <Label>
                                    <Input
                                        type="checkbox"
                                        checked={this.state.isAgreeWithLicense}
                                        onChange={this.handleChangeAgreementFlag}
                                    />
                                    {_.get(this.props.translation, "license_agreement.agree_label")}
                                    {" "}
                                    <Button color="link" onClick={this.handleClickShowLicenseAgreement}>
                                        {_.get(this.props.translation, "license_agreement.agree_link")}
                                    </Button>
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="auto">
                            <Button color="primary" type="submit" disabled={!this.state.isAgreeWithLicense || this.isSubmitFormDisabled()}>
                                {_.get(this.props.translation, 'activate_account_page.submit_button')}
                            </Button>
                        </Col>
                    </FormGroup>
                </Form>
                <LicenseAgreementModal onAgree={this.handleAgree} />
            </div>
        )
    }

    renderPersonalDataFields() {
        let formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField,
        };

        let params = [
            {
                fieldName: "firstName",
                title: _.get(this.props.translation, 'person_data.first_name_label'),
                isRequired: true
            },
            {
                fieldName: "lastName",
                title: _.get(this.props.translation, 'person_data.last_name_label'),
                isRequired: true
            },
        ];

        return _.map(params, (fieldParams) => FormUtils.renderFormGroup(fieldParams, formParams));
    }

    handleChangeField = (fieldName) => (e) => {
        this.setState({ [fieldName]: e.target.value });
    }

    isSubmitFormDisabled = () => {
        return _.isEmpty(this.state.password) || _.isEmpty(this.state.passwordConfirm) ||
            !Utils.isValidPassword(this.state.password) ||
            this.state.password !== this.state.passwordConfirm;
    }

    handleChangeAgreementFlag = () => {
        this.setState({ isAgreeWithLicense: !this.state.isAgreeWithLicense });
    }

    handleAgree = () => {
        this.setState({ isAgreeWithLicense: true });
    };

    handleClickShowLicenseAgreement = () => {
        this.props.onShowLicenseAgreement();
    }

    onSubmitActivation = (e) => {
        e.preventDefault();

        let data = {
            password: this.state.password,
            identityAlias: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
            }
        };

        this.props.onActivateAccount(data, this.props.token);
    }

}

ActivateEmployeeAccount.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.object,
    token: PropTypes.string.isRequired,
    onActivateAccount: PropTypes.func.isRequired
}

export default ActivateEmployeeAccount;
