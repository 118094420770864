import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CandidatesGroupModal.scss';
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    ModalHeader,
    Badge,
    Alert
} from 'reactstrap';
import _ from "lodash";
import Icon from '../../../../../common/icon/Icon';
import UserPhoto from '../../../../../common/userPhoto/UserPhoto.container';
import { USER_PHOTO_MODE } from '../../../../../common/userPhoto/UserPhoto';
import { CANDIDATE_GROUP } from '../../../../../../constants/candidatesComparisonConstants';
import { Utils } from '../../../../../../utils/utils';

export const MODAL_GROUP_TYPE = {
    TOP_X: "TOP_X",
    NO_GO: "NO_GO",
    DEADLINE_POSTPONED: "DEADLINE_POSTPONED",
    CANCELED_JOB: "CANCELED_JOB",
}

const MODAL_GROUP_TITLE = {
    [MODAL_GROUP_TYPE.TOP_X]: "job.top_x_candidates_label",
    [MODAL_GROUP_TYPE.NO_GO]: "job.no_go_candidates_label",
    [MODAL_GROUP_TYPE.DEADLINE_POSTPONED]: "job.deadline_postponed_label",
    [MODAL_GROUP_TYPE.CANCELED_JOB]: "job.canceled_job_label",
}

const MODAL_GROUP_ICON = {
    [MODAL_GROUP_TYPE.TOP_X]: "best_mark",
    [MODAL_GROUP_TYPE.NO_GO]: "email_delete",
    [MODAL_GROUP_TYPE.DEADLINE_POSTPONED]: "email_expire",
    [MODAL_GROUP_TYPE.CANCELED_JOB]: "email_discard",
}

class CandidatesGroupModal extends Component {
    render() {
        if (!this.props.groupType) {
            return null;
        }

        return (
            <Modal isOpen={this.props.isOpen} className="candidates-group-modal" toggle={this.props.onCancel}>
                <ModalHeader toggle={this.props.onCancel} tag="div">
                    <Icon type={MODAL_GROUP_ICON[this.props.groupType]} size="xl" />
                    <h5 className="group-name">
                        {_.replace(_.get(this.props.translation, MODAL_GROUP_TITLE[this.props.groupType]), "{x}", this.props.plannedTopAmount)}
                        {this.renderGroupAmount()}
                    </h5>
                </ModalHeader>
                <ModalBody>
                    {this.renderModalBody()}
                </ModalBody>
                {/* <ModalFooter className="content-space-between"> */}
                {this.renderModalFooter()}
                {/* </ModalFooter> */}
            </Modal>
        );
    }

    renderGroupAmount() {
        switch (this.props.groupType) {
            case MODAL_GROUP_TYPE.TOP_X:
                return (
                    <Badge pill color="success">{this.props.topXCandidatesData.length}</Badge>
                );

            case MODAL_GROUP_TYPE.NO_GO:
                return (
                    <Badge pill color="success">{this.props.noGoCandidatesData.length}</Badge>
                );

            default:
                break;
        }
    }

    renderModalBody() {
        switch (this.props.groupType) {
            case MODAL_GROUP_TYPE.TOP_X:
                return (
                    <div className="candidates-container">
                        {
                            _.map(this.props.topXCandidatesData, (candidateData) => {
                                return this.renderCandidateCard(candidateData);
                            })
                        }
                    </div>
                );

            case MODAL_GROUP_TYPE.NO_GO:
                return (
                    <div className="candidates-container">
                        {
                            _.map(this.props.noGoCandidatesData, (candidateData) => {
                                return this.renderCandidateCard(candidateData);
                            })
                        }
                    </div>
                )

            case MODAL_GROUP_TYPE.DEADLINE_POSTPONED:
                return (
                    <Alert color="primary">
                        {_.get(this.props.translation, "candidates_comparison_page.not_dragging_candidates_group_modal_hint")}
                    </Alert>
                )

            case MODAL_GROUP_TYPE.CANCELED_JOB:
                return (
                    <Alert color="primary">
                        {_.get(this.props.translation, "candidates_comparison_page.not_dragging_candidates_group_modal_hint")}
                    </Alert>
                )

            default:
                break;
        }
    }

    renderCandidateCard(candidateData) {
        return (
            <div key={candidateData.user.id} className="candidate-card">
                <UserPhoto
                    mode={USER_PHOTO_MODE.COMPARISON}
                    userId={candidateData.user.id}
                    // TODO: remove hardcoded values
                    imageSource=""
                    size="lg"
                />
                {this.getUserName(candidateData)}
                <Icon
                    className="remove-candidate-icon"
                    type="minus_outline"
                    size="sm"
                    color="danger"
                    onClick={this.handleRemoveCandidateFromGroup(candidateData.user.id)}
                    title={_.get(this.props.translation, 'candidates_comparison_page.remove_candidate_from_group_label')}
                />
            </div>
        )
    }

    getUserName = (candidateData) => {
        if (this.props.anonymityMode) {
            return Utils.getUserAnonymousNameByUUID(candidateData.user.id);
        } else {
            return candidateData.user.alias.firstName + " " + candidateData.user.alias.lastName;
        }
    }

    renderModalFooter() {
        switch (this.props.groupType) {
            case MODAL_GROUP_TYPE.TOP_X:
                return (
                    <ModalFooter className="content-space-between">
                        <Button color="danger" onClick={this.handleRemoveAllCandidates}>
                            {_.get(this.props.translation, "job_and_candidates.reset_all_label")}
                        </Button>
                        <Button color="primary" onClick={this.handleFreezeTopXCandidates}>
                            {_.replace(_.get(this.props.translation, "job.freeze_top_x_button"), "{x}", this.props.plannedTopAmount)}
                        </Button>
                    </ModalFooter>
                )

            case MODAL_GROUP_TYPE.NO_GO:
                return (
                    <ModalFooter className="content-space-between">
                        <Button color="danger" onClick={this.handleRemoveAllCandidates}>
                            {_.get(this.props.translation, "job_and_candidates.reset_all_label")}
                        </Button>
                        <Button color="primary" onClick={this.handleSendFeedbackToAllCandidates}>
                            {_.get(this.props.translation, "job.send_feedback_to_all_button")}
                        </Button>
                    </ModalFooter>
                )

            case MODAL_GROUP_TYPE.DEADLINE_POSTPONED:
                return (
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleSendFeedbackToAllLeftCandidates}>
                            {_.get(this.props.translation, "job_and_candidates.send_to_all_left_label")}
                        </Button>
                    </ModalFooter>
                )

            case MODAL_GROUP_TYPE.CANCELED_JOB:
                return (
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleSendFeedbackToAllCandidates}>
                            {_.get(this.props.translation, "job_and_candidates.send_to_all_label")}
                        </Button>
                    </ModalFooter>
                )

            default:
                break;
        }
    }

    handleRemoveAllCandidates = () => {
        switch (this.props.groupType) {
            case MODAL_GROUP_TYPE.TOP_X:
                this.props.onRemoveAllCandidatesFromGroup(CANDIDATE_GROUP.TOP_X);
                this.props.onCancel();
                break;

            case MODAL_GROUP_TYPE.NO_GO:
                this.props.onRemoveAllCandidatesFromGroup(CANDIDATE_GROUP.NO_GO);
                this.props.onCancel();
                break;

            default:
                break;
        }
    }

    handleRemoveCandidateFromGroup = (userId) => () => {
        switch (this.props.groupType) {
            case MODAL_GROUP_TYPE.TOP_X:
                this.props.onRemoveCandidateFromGroup(CANDIDATE_GROUP.TOP_X, userId);
                break;

            case MODAL_GROUP_TYPE.NO_GO:
                this.props.onRemoveCandidateFromGroup(CANDIDATE_GROUP.NO_GO, userId);
                break;

            default:
                break;
        }
    }

    handleFreezeTopXCandidates = () => {
        // TODO: (BE) add the ability to freeze top x candidates and send them to BO
    }

    handleSendFeedbackToAllLeftCandidates = () => {
        // TODO: (BE) add the ability to send feedback
    }

    handleSendFeedbackToAllCandidates = () => {
        // TODO: (BE) add the ability to send feedback
    }
}

CandidatesGroupModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    groupType: PropTypes.string,
    topXCandidatesData: PropTypes.array,
    noGoCandidatesData: PropTypes.array,
    plannedTopAmount: PropTypes.number,
    anonymityMode: PropTypes.bool,
    onRemoveCandidateFromGroup: PropTypes.func.isRequired,
    onRemoveAllCandidatesFromGroup: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default CandidatesGroupModal;
