import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './LanguageSelect.scss';
import _ from 'lodash';
import { components } from 'react-select';
import CustomSelect from '../../../common/inputs/customSelect/CustomSelect';

class LanguageSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedLanguage: props.selectedLanguage
        }
    }

    render() {
        return (
            <CustomSelect
                className="language-select"
                value={_.find(this.props.languageList, lang => lang.value === this.state.selectedLanguage)}
                options={this.props.languageList}
                onChange={this.handleChange}
                components={this.getComponents()}
                sizeType="sm"
            />
        );
    }

    getComponents = _ => ({
        Option: (props) => {
            return (
                <div title={props.data.title}>
                    <components.Option {...props} />
                </div>
            );
        }
    });

    handleChange = (selectedOption) => {
        let newLanguage = selectedOption.value;

        if (this.state.selectedLanguage !== newLanguage) {
            this.setState({ selectedLanguage: newLanguage })
            this.props.onLanguageSelect(newLanguage);
        }
    };
}

LanguageSelect.propTypes = {
    selectedLanguage: PropTypes.string.isRequired,
    languageList: PropTypes.array.isRequired,
    onLanguageSelect: PropTypes.func.isRequired
};

export default LanguageSelect;
