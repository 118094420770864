import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import {
    ROUTES,
    UNAUTHORIZED_ROUTES,
    USER_ROLE,
    SYS_ADMIN_ROUTES,
    APPLICANT_ROUTES,
    EMPLOYEE_ROUTES_BY_SUB_ROLE,
    COMMON_AUTHORIZED_ROUTES,
    ROUTES_ACCESSIBLE_VIA_EMAIL,
    ROUTES_VISIBLE_TO_ALL,
} from "../../../constants/constants";
import { StorageManager } from "../../../utils/storageManager";
import RouteView from "./routeView/RouteView.container";

class RoutesManager extends Component {
    constructor(props) {
        super(props);

        if (_.includes(ROUTES_ACCESSIBLE_VIA_EMAIL, props.location.pathname)) {
            this.props.onLogOut();
        }
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location) => {
            let userIdFromLocalStorage = StorageManager.getUserId();
            if (
                !_.isNil(userIdFromLocalStorage) &&
                !_.isNil(this.props.userId) &&
                this.props.userId.toString() !== userIdFromLocalStorage.toString()
            ) {
                this.props.onUserNotMatch();
            } else if (_.isNil(userIdFromLocalStorage) && !_.isNil(this.props.userId)) {
                this.props.onUserMissing();
            }
            this.props.onResetRequestStatusData();
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        return (
            <Switch>
                {this.renderRoutes(ROUTES_ACCESSIBLE_VIA_EMAIL)}
                {this.renderRoutesVisibleToAll(ROUTES_VISIBLE_TO_ALL)}
                {this.props.isAuthorized
                    ? this.getAuthorizedRoutes()
                    : this.getUnauthorizedRoutes()}
                <Route path="/" render={this.renderView()} />
            </Switch>
        );
    }

    getUnauthorizedRoutes() {
        return [
            ...this.renderRoutes(UNAUTHORIZED_ROUTES),
            <Redirect key="redirect-to-login" from="*" to={ROUTES.LOGIN} />,
        ];
    }

    getAuthorizedRoutes() {
        return [
            ..._.map(UNAUTHORIZED_ROUTES, (route) => (
                <Redirect key={route} exact from={route} to={ROUTES.HOME} />
            )),
            <Redirect key="/" exact from="/" to={ROUTES.HOME} />,
            <Route key={ROUTES.HOME}
                   exact
                   path={ROUTES.HOME}
                   render={this.renderView(ROUTES.HOME)} />,
            ...this.renderRoutes(COMMON_AUTHORIZED_ROUTES),
            ...this.getUserSpecificAuthorizedRoutes(),
        ];
    }

    getUserSpecificAuthorizedRoutes() {
        switch (this.props.userRole) {
            case USER_ROLE.SYS_ADMIN:
                return this.renderRoutes([
                    "/model-management",
                    "/personal-settings"
                ]);

            case USER_ROLE.APPLICANT:
                return this.renderRoutes(APPLICANT_ROUTES);

            case USER_ROLE.EMPLOYEE:
                return this.renderRoutes(EMPLOYEE_ROUTES_BY_SUB_ROLE[this.props.userSubRole]);

            default:
                break;
        }

        return [];
    }

    renderRoutes(routesArray) {
        return _.map(routesArray, (route) => {
            return <Route key={route} exact path={route} render={this.renderView(route)} />;
        });
    }

    renderView = (view) => () => {
        return <RouteView route={view} role={this.props.userRole} />;
    };

    renderRoutesVisibleToAll(routes) {
        return _.map(routes, (route) => (
            <Route key={route} path={route} render={this.renderView(route)} />
        ));
    }
}

RoutesManager.propTypes = {
    translation: PropTypes.object.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
    userRole: PropTypes.string,
    userSubRole: PropTypes.string,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onUserNotMatch: PropTypes.func.isRequired,
    onUserMissing: PropTypes.func.isRequired,
    onResetRequestStatusData: PropTypes.func.isRequired,
    onLogOut: PropTypes.func.isRequired,
};

export default withRouter(RoutesManager);
