import { connect } from "react-redux";
import ChangeParentClusterModal from "./ChangeParentClusterModal";
import { getTranslation } from "../../../../../reducers/common/localization";
import {
    getActiveModalType,
    MODAL_CHANGE_PARENT_CLUSTER,
    hideModal,
} from "../../../../../reducers/common/activeModal";
import {
    getAllClustersData,
    getAllClustersFetchingStatus,
    getNewParentCluster,
    onFetchGetAllClustersData,
    updateNewParentCluster,
} from "../../../../../reducers/admin/clusters";
import {
    fetchAddClusterToSkill,
    getSystemCurrentNodeData,
    getSystemCurrentNodeParentCluster,
} from "../../../../../reducers/admin/systemCurrentNode";
import { onFetchGetSystemNodesList } from "../../../../../reducers/admin/systemModel";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_CHANGE_PARENT_CLUSTER,
        nodesList: getAllClustersData(state),
        loading: getAllClustersFetchingStatus(state),
        currentNodeParentCluster: getSystemCurrentNodeParentCluster(state),
        newCluster: getNewParentCluster(state),
        currentNode: getSystemCurrentNodeData(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClose: () => {
            dispatch(hideModal());
        },
        onFetchGetClustersData: () => {
            dispatch(onFetchGetAllClustersData());
        },
        onChangeParentCluster: (nodeId, clusterId) => {
            dispatch(fetchAddClusterToSkill(nodeId, clusterId));
            dispatch(onFetchGetSystemNodesList());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeParentClusterModal);
