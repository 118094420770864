
import React, { Component } from 'react';

class PersonsComparisonOutline extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 512 512">
                <path d="M257,17.3c22.5-1.3,45,1.7,66.4,8.8c2.9,1,6.1,0.3,8.4-1.7c2.3-2,3.3-5.1,2.7-8.1c-0.6-3-2.8-5.4-5.7-6.4
                    c-23.4-7.8-48.1-11.1-72.7-9.6c-27.8,1.9-55.4,5.9-82.5,11.8c-38.5,7.7-66.4,21.2-85.5,41.3C73,69.6,61.4,88.6,53.9,109.3
                    c-3.1,8.9-5.6,18-7.6,27.2l-0.9,3.6c-1.8,6.7-4.3,13.2-7.3,19.4c-1.7,3.9-3.5,7.8-5,11.8c-2,4.5-2.7,9.4-2.3,14.3
                    c1.7,7.6,6.1,14.4,12.4,19.1c4.2,3.8,6.9,6.5,6.9,8.9c-0.3,1.3-0.8,2.5-1.5,3.7c-10.2,21.5-22.7,41.9-37.3,60.7
                    c-6.2,7.5-12.7,16.3-11.3,25.1c1.8,6.8,6.3,12.5,12.4,16c2.8,1.9,5.8,3.7,8.9,5.1c3.7,1.9,9.9,5,10.3,7.3c0.5,2.9,0.2,5.9-1,8.6
                    c-1.3,2.7-2.6,5.1-3.8,7.1c-3.1,5.5-8.4,14.8-0.5,21.2c1.8,1.7,5,4.2,7.9,6.4c-2.1,3.7-3.1,8-2.8,12.3c0.5,3.4,2.3,6.4,5.1,8.4
                    c1.9,1.3,3.9,2.5,5.9,3.6c5.1,2.9,7,4.2,7.7,6.9c0.5,3.4,0.2,6.9-0.9,10.2c-0.5,2.3-1,4.6-1.3,6.8c-0.8,7.8,0.1,15.6,2.8,23
                    c3.8,13.6,16.1,23,30.2,23.3c2.4,0,4.7-0.3,7-0.7c24.4-5.8,48.4-13,71.9-21.5c19.2,11.3,31.4,45.6,34.5,58.5
                    c0.9,3.8,4.4,6.5,8.3,6.5l214.2-0.6c2.7,0,5.3-1.3,6.9-3.5c1.6-2.2,2.1-5,1.3-7.6c-2.1-5.8-5-11.2-8.6-16.2c-2-2.9-3.9-6-5.5-9.2
                    c-3.9-7.8-7.1-15.9-9.6-24.3c-4.9-16.5-8.3-33.4-10.1-50.5c-2.2-18.9-0.6-38.1,4.7-56.4c0.9-3,0.2-6.2-1.9-8.4
                    c-2.1-2.3-5.3-3.2-8.3-2.4c-3,0.8-5.4,3.1-6.1,6.1c-5.9,20.5-7.7,42-5.2,63.2c2,18.1,5.6,36.1,10.8,53.5c2.8,9.2,6.3,18.1,10.6,26.7
                    c1.9,3.9,4.2,7.6,6.6,11.2l0.6,0.9l-193.6,0.5c-5.7-20.2-15.9-38.9-30-54.5l7.7-2.7c5.7-2,10-3.5,12.2-4.2
                    c36.6-11.6,54.8-28.2,54.3-49.5c0-2.3-0.9-4.5-2.5-6.1c-1.6-1.6-3.9-2.4-6.2-2.3c-4.7,0.1-8.4,4-8.3,8.7c0.2,8.8-7,21.6-42.5,32.8
                    c-2.3,0.7-6.8,2.3-12.7,4.4c-6.3,2.2-14.4,5-23.3,8.1h-0.1c-24,8.8-48.5,16.2-73.4,22.2c-8,1.5-15.7-3.5-17.6-11.4
                    c-1.8-4.9-2.5-10.1-2-15.2c0.2-1.8,0.6-3.6,1-5.4c1.7-5.9,2-12.1,0.7-18.2c-2.5-7.8-8.2-14.2-15.8-17.5l-1.8-1
                    c0.9-1.9,2-3.6,3.3-5.3c1.4-1.8,2.1-4.1,1.8-6.4s-1.5-4.4-3.4-5.7c-2-1.5-6.9-5.1-10.4-8c0.4-0.7,0.9-1.6,1.3-2.3
                    c1.3-2.3,2.9-5.1,4.4-8.2c2.7-6,3.5-12.6,2.3-19.1c-2-10.4-12-15.5-19.3-19.2c-2.5-1.2-4.8-2.5-7-4.1c-1.8-1.2-3.5-2.6-5.1-4.1
                    c0-1.3,1.4-4.4,7.9-12.3c15.3-19.7,28.4-41,39.1-63.5c2-3.5,3.1-7.5,3.3-11.5c-0.9-8.6-5.4-16.4-12.5-21.3c-3.4-3.2-6.7-6.2-7.1-8.9
                    c0.1-2,0.5-4,1.3-5.8c1.4-3.7,3-7.2,4.6-10.8c3.5-7.2,6.3-14.8,8.3-22.5l0.9-3.6c1.8-8.5,4.1-16.9,7-25.2
                    c6.7-18.6,17.1-35.6,30.6-50c16.3-17.3,42-29.5,76.5-36.3C203.3,23.1,230.1,19.2,257,17.3z M95.3,151.9l0.9-3.7
                    c1.5-7.1,3.4-14.2,5.7-21.1c5.1-14.2,13.1-27.3,23.4-38.4c11.1-10.4,24.6-17.9,39.3-21.6c3-0.8,5.3-3.2,6-6.2c0.7-3-0.2-6.2-2.5-8.3
                    c-2.3-2.1-5.5-2.8-8.4-1.8C142,55.4,125.9,64.4,112.9,77c-11.9,12.8-21.1,27.8-27,44.2c-2.6,7.6-4.7,15.3-6.3,23.1l-0.9,3.7
                    c-0.7,3,0.2,6.1,2.4,8.2c2.2,2.1,5.4,2.9,8.4,2S94.6,154.9,95.3,151.9L95.3,151.9z M76.8,170.7c4.7,0,8.5,3.8,8.5,8.5
                    s-3.8,8.5-8.5,8.5c-4.7,0-8.5-3.8-8.5-8.5S72.1,170.7,76.8,170.7z"/>
                <path d="M511.6,125.9c-0.1-0.6-0.3-1.1-0.5-1.6c-0.1-0.1-0.1-0.3-0.1-0.4l-42.7-76.8c-0.1-0.1-0.1-0.1-0.2-0.2
                    c-0.2-0.2-0.3-0.4-0.5-0.7c-0.3-0.4-0.7-0.9-1.2-1.2c-1.9-1.8-4.5-2.6-7-2.2l-69.8,11.7c-6.2-14.2-21.3-22.4-36.7-19.9
                    c-15.3,2.5-27,15.2-28.3,30.6l-70,11.7c-1.5,0.3-2.9,1-4.1,2.1c-0.4,0.3-0.7,0.6-1,1c-0.2,0.3-0.4,0.5-0.6,0.8
                    c-0.2,0.3-0.3,0.3-0.3,0.5L205.9,158c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.5-0.4,1.1-0.5,1.6c-0.2,0.6-0.3,1.1-0.3,1.7
                    c0,0.2-0.1,0.3-0.1,0.4v17.1c0,23.6,19.1,42.6,42.7,42.7h17.1c23.6,0,42.6-19.1,42.7-42.7v-17.1c0-0.2-0.1-0.3-0.1-0.4
                    c-0.1-0.6-0.2-1.2-0.3-1.7c-0.1-0.6-0.3-1.1-0.5-1.6c-0.1-0.1-0.1-0.3-0.1-0.4l-36.8-66.2l57.9-9.6c1.4,3.1,3.3,6,5.5,8.5v157.6
                    c-24.6,4.2-42.6,25.5-42.7,50.4v8.5c0,4.7,3.8,8.5,8.5,8.5h119.5c4.7,0,8.5-3.8,8.5-8.5v-8.5c0-25-18.1-46.3-42.7-50.4V90.6
                    c4.7-5.4,7.6-12.2,8.2-19.3l52.5-8.8l-34.1,61.3c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.5-0.4,1.1-0.5,1.6c-0.2,0.6-0.3,1.1-0.3,1.7
                    c0,0.2-0.1,0.3-0.1,0.4v17.1c0,23.6,19.1,42.6,42.7,42.7h17.1c23.6,0,42.6-19.1,42.7-42.7V128c0-0.2-0.1-0.3-0.1-0.4
                    C511.9,127,511.7,126.4,511.6,125.9z M489,119.5h-56.3l28.2-50.7L489,119.5z M349.9,101.2c5.6,1.6,11.5,1.6,17.1,0v146.3h-17.1
                    V101.2z M358.4,51.2c9.4,0,17.1,7.6,17.1,17.1s-7.6,17.1-17.1,17.1s-17.1-7.6-17.1-17.1S349,51.2,358.4,51.2z M256,102.9l28.2,50.7
                    h-56.3L256,102.9z M290.1,179.2c0,14.1-11.5,25.6-25.6,25.6h-17.1c-14.1,0-25.6-11.5-25.6-25.6v-8.5h68.3V179.2z M409.6,298.7H307.2
                    c0-18.9,15.3-34.1,34.1-34.1h34.1C394.3,264.5,409.6,279.8,409.6,298.7z M494.9,145.1c0,14.1-11.5,25.6-25.6,25.6h-17.1
                    c-14.1,0-25.6-11.5-25.6-25.6v-8.5h68.3V145.1z"/>
            </svg>
        );
    }
}

export default PersonsComparisonOutline;
