import React, { Component } from "react";
import JobsFilter from "./JobsFilter/JobsFilter.container";
import JobsLifecycleLine from "./JobsLifecycleLine/JobsLifecycleLine.container";
import { Row } from "reactstrap";
import JobsTable from "./JobsTable/JobsTable.container";
import "./EmployeeJobs.scss";

const initialFilterState = {
    dept: "",
    jobTitleAndRef: "",
    includeClosedJobs: false,
};

class EmployeeJobs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filtersData: { ...initialFilterState },
            activeTablesStatuses: [],
            fetchedJobs: []
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { jobs } = this.props;

        if (prevProps.jobs !== jobs) this.setState({ fetchedJobs: jobs });
    }

    componentDidMount() {
        this.props.onMount();
    }

    getFilteredJobs() {
        const {
            filtersData: { dept, jobTitleAndRef },
        } = this.state;
        if (dept && jobTitleAndRef) {
            return this.state.fetchedJobs.filter(
                ({ department, title }) => department === dept && title === jobTitleAndRef
            );
        } else if (dept && !jobTitleAndRef) {
            return this.state.fetchedJobs.filter(({ department }) => department === dept);
        } else if (jobTitleAndRef && !dept) {
            return this.state.fetchedJobs.filter(({ title }) => title === jobTitleAndRef);
        }
    }

    setActiveTablesStatuses(status) {
        const { activeTablesStatuses } = this.state;
        const updatedActiveTableStatuses = [...activeTablesStatuses];
        const tableStatusIndex = updatedActiveTableStatuses.findIndex(
            (activeStatus) => activeStatus === status.toLowerCase()
        );

        if (tableStatusIndex >= 0) {
            updatedActiveTableStatuses.splice(tableStatusIndex, 1);
        } else {
            updatedActiveTableStatuses.push(status.toLowerCase());
        }
        this.setState({ activeTablesStatuses: updatedActiveTableStatuses });
    }

    render() {
        const { filtersData, activeTablesStatuses } = this.state;
        return (
            <div>
                <Row>
                    <JobsFilter
                        filtersData={filtersData}
                        setParentStateValue={(stateField, key, value) =>
                            this.setState({
                                [stateField]: { ...this.state[stateField], [key]: value },
                            })
                        }
                        resetFilters={() =>
                            this.setState({ filtersData: { ...initialFilterState } })
                        }
                    />
                </Row>
                <JobsLifecycleLine
                    setActiveTablesStatuses={(status) => this.setActiveTablesStatuses(status)}
                />
                <JobsTable activeTablesStatuses={activeTablesStatuses} />
            </div>
        );
    }
}

EmployeeJobs.propTypes = {};

export default EmployeeJobs;
