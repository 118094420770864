import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './PageNotFound.scss';

class PageNotFound extends Component {
    render() {
        return (
            <div className='page-not-found'>
                <h2 className="text-center">{_.get(this.props.translation, 'page_not_found.page_not_found_label')}</h2>
                <h6 className="text-center">{_.get(this.props.translation, 'page_not_found.sorry_label')}</h6>
                <h6 className="text-center">{_.get(this.props.translation, 'page_not_found.cause_label')}</h6>
            </div>
        );
    }
}

PageNotFound.propTypes = {
    translation: PropTypes.object.isRequired
};

export default PageNotFound;
