import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './LicenseAgreementModal.scss';
import _ from 'lodash';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import LicenseText from './LicenseText';

class LicenseAgreementModal extends Component {
    render() {
        return (
            <Modal
                className="license-agreement-modal"
                isOpen={this.props.isOpen}
                toggle={this.props.onClose}
            >
                <ModalHeader toggle={this.props.onClose} tag="h3">
                    {_.get(this.props.translation, "license_agreement.modal_label")}
                </ModalHeader>
                <ModalBody>
                    <LicenseText />
                </ModalBody>
                <ModalFooter className="content-center">
                    <Button color="primary" onClick={this.handleAgree}>
                        {_.get(this.props.translation, "license_agreement.agree_label") + ' ' + _.get(this.props.translation, "license_agreement.agree_link")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    handleAgree = () => {
        this.props.onAgree();
        this.props.onClose();
    };
}

LicenseAgreementModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAgree: PropTypes.func.isRequired
};

export default LicenseAgreementModal;
