import { connect } from "react-redux";
import SkillsJourney from "./SkillsJourney";
import { getTranslation } from "../../../reducers/common/localization";
import { getUserId } from "../../../reducers/user/userData";
import {
    clearCurrentSkillAndCluster,
    fetchGetApplicantSkills,
    fetchGetApplicantSkillsEvents,
    getApplicantCurrentSkillData,
    getApplicantCurrentSkillId,
    getApplicantSkillsData,
    getApplicantSkillsDataTree,
    getApplicantSkillsLQGraphData,
} from "../../../reducers/applicant/applicantSkills";
import {
    fetchGetApplicantProfiles,
    getApplicantProfilesInitialFetchingStatus,
} from "../../../reducers/applicant/applicantProfiles";
import { getSuggestedProfilesFetchingStatus } from "../../../reducers/profiles/suggestedProfiles";
import { getSearchProfilesFetchingStatus } from "../../../reducers/profiles/searchProfiles";
import { onChangeCurrentSkill } from "../../../reducers/job/jobSkills";
import {
    MODAL_ADD_PAST_JOB_META_DATA,
    MODAL_SKILL_NOTES,
    showModal,
} from "../../../reducers/common/activeModal";
import {
    fetchGetApplicantPastJobs,
    getApplicantPastJobsData,
} from "../../../reducers/applicant/applicantPastJobs";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        userId: getUserId(state),
        // TODO: remove hardcoded values
        imageSource: "",
        loadingProfiles: getApplicantProfilesInitialFetchingStatus(state),
        loadingSearchProfiles: getSearchProfilesFetchingStatus(state),
        loadingSuggestedProfiles: getSuggestedProfilesFetchingStatus(state),
        skills: getApplicantSkillsData(state),
        skillsTree: getApplicantSkillsDataTree(state),
        lqGraphData: getApplicantSkillsLQGraphData(state),
        currentSkillId: getApplicantCurrentSkillId(state),
        currentSkillData: getApplicantCurrentSkillData(state),
        pastJobs: getApplicantPastJobsData(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onMount: () => {
            dispatch(fetchGetApplicantSkills());
            dispatch(fetchGetApplicantProfiles());
            dispatch(fetchGetApplicantPastJobs());
            dispatch(fetchGetApplicantSkillsEvents());
        },
        onAddPastJobClick: () => dispatch(showModal(MODAL_ADD_PAST_JOB_META_DATA)),
        onAddSkillNoteClick: () => dispatch(showModal(MODAL_SKILL_NOTES)),
        onSelectSkill: (skillId, clusterId) => dispatch(onChangeCurrentSkill(skillId, clusterId)),
        onСlearCurrentSkillAndCluster: () => dispatch(clearCurrentSkillAndCluster()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsJourney);
