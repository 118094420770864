import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormUtils } from '../../../../utils/formUtils';

export const PROFESSIONAL_FORM_MODE = "PROFESSIONAL_FORM_MODE";
export const PERSONAL_FORM_MODE = "PERSONAL_FORM_MODE";

class ContinentAndCountryFormGroups extends Component {
    constructor(props) {
        super(props);

        this.state = {
            continentCode: null
        };
    }

    componentDidMount() {
        if (this.props.countryCode && !this.state.continentCode) {
            this.setState({ continentCode: this.getContinentByCountry(this.props.countryCode) });
        }
    }

    componentDidUpdate() {
        if (this.props.countryCode && !this.state.continentCode) {
            this.setState({ continentCode: this.getContinentByCountry(this.props.countryCode) });
        }

        if (!_.isNil(this.props.continentCode) && this.props.continentCode !== this.state.continentCode) {
            this.setState({ continentCode: this.props.continentCode });
        }
    }

    getContinentByCountry = (countryCode) => {
        return _.find(_.mapValues(this.props.locationData, (countries, continentCode) => {
            return _.includes(countries, countryCode) && continentCode;
        }));
    };

    render() {
        return (
            <>
                {this.renderContinentFormGroup()}
                {this.renderCountryFormGroup()}
            </>
        );
    }

    renderContinentFormGroup() {
        let formParams = {
            ...this.props.formParams,
            data: this.state
        };

        let continentOptions = _.map(_.keys(this.props.locationData), (continentCode) => {
            return { value: continentCode, label: _.get(this.props.translation, `continents.${continentCode}`) };
        });
        continentOptions = _.sortBy(continentOptions, ["label"]);

        let params = {
            fieldName: "continentCode",
            title: _.get(this.props.translation, this.props.showContinentFilterLabel ? "shared.continent_filter_label" : "shared.continent_label"),
            type: "select",
            handleChange: this.handleChangeContinent,
            options: continentOptions,
            placeholder: _.get(this.props.translation, "shared.continent_input_placeholder"),
            isDisabled: !this.props.locationData,
            isRequired: this.props.isRegionRequired,
            isSearchable: true
        }

        return FormUtils.renderFormGroup(params, formParams);
    }

    renderCountryFormGroup() {
        let formParams = {
            ...this.props.formParams,
            data: this.props
        };

        let countryOptions = _.map(_.get(this.props.locationData, this.state.continentCode), (countryCode) => {
            return { value: countryCode, label: _.get(this.props.translation, `countries.${countryCode}`) };
        });
        countryOptions = _.sortBy(countryOptions, ["label"]);

        let params = {
            fieldName: "countryCode",
            title: _.get(this.props.translation, "shared.country_label"),
            type: "select",
            handleChange: this.handleChangeCountry,
            options: countryOptions,
            placeholder: _.get(this.props.translation, "shared.country_input_placeholder"),
            isDisabled: !this.props.locationData || !this.state.continentCode,
            isRequired: this.props.isCountryRequired,
            isSearchable: true
        }

        return FormUtils.renderFormGroup(params, formParams);
    }

    handleChangeContinent = (selectedOption) => {
        let value = selectedOption.value;

        if (value !== this.state.continentCode) {
            this.setState({ continentCode: value });
            if (this.props.handleChangeContinent) {
                this.props.handleChangeContinent(value);
            }
            this.props.handleChangeCountry(null);
        }
    };

    handleChangeCountry = (selectedOption) => {
        this.props.handleChangeCountry(selectedOption.value);
    }
}

ContinentAndCountryFormGroups.defaultProps = {
    showContinentFilterLabel: true,
    isCountryRequired: true,
    isRegionRequired: true
}

ContinentAndCountryFormGroups.propTypes = {
    translation: PropTypes.object.isRequired,
    locationData: PropTypes.object,
    continentCode: PropTypes.string,
    countryCode: PropTypes.string,
    formParams: PropTypes.object,
    showContinentFilterLabel: PropTypes.bool,
    isCountryRequired: PropTypes.bool,
    handleChangeCountry: PropTypes.func.isRequired,
    handleChangeContinent: PropTypes.func,
    isRegionRequired: PropTypes.bool,
};

export default ContinentAndCountryFormGroups;
