import { connect } from 'react-redux';
import ResetPassword from './ResetPassword';
import { getTranslation } from '../../../../reducers/common/localization';
import {
    getAccountToResetPasswordFetchingStatus,
    getAccountToResetPasswordData,
    getAccountToResetPasswordError,
    fetchAccountToResetPasswordData,
    fetchResetPassword
} from '../../../../reducers/authorization/resetEmailAndPassword';
import { getRequestSuccessStatus } from '../../../../reducers/common/requestStatus';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        data: getAccountToResetPasswordData(state),
        loading: getAccountToResetPasswordFetchingStatus(state),
        tokenError: getAccountToResetPasswordError(state),
        success: getRequestSuccessStatus(state)
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onGetAccountToResetPasswordData: (token) => dispatch(fetchAccountToResetPasswordData(token)),
        onResetPassword: (data, token) => dispatch(fetchResetPassword(data, token)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
