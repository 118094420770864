export const NODE_STATUS = {
    VALIDATED: "VALIDATED",
    NOT_VALIDATED: "NOT_VALIDATED",
    REJECTED: "REJECTED",
    PENDING: "PENDING",
    REMOVED: "REMOVED",
};

export const NODE_STATUS_LIST = [
    NODE_STATUS.VALIDATED,
    NODE_STATUS.NOT_VALIDATED,
    NODE_STATUS.REMOVED,
];

export const NODE_STATUS_CLASS_NAME = {
    [NODE_STATUS.VALIDATED]: "reference-model",
    [NODE_STATUS.NOT_VALIDATED]: "node-cemetery",
    [NODE_STATUS.REJECTED]: "node-cemetery",
    [NODE_STATUS.REMOVED]: "new-incoming",
    [NODE_STATUS.PENDING]: "new-incoming",
};

export const NODE_STATUS_LABEL = {
    [NODE_STATUS.VALIDATED]: "system_nodes.validated_label",
    [NODE_STATUS.NOT_VALIDATED]: "system_nodes.not_validated_label",
    [NODE_STATUS.REJECTED]: "system_nodes.not_validated_label",
    [NODE_STATUS.REMOVED]: "system_nodes.removed_label",
    [NODE_STATUS.PENDING]: "system_nodes.removed_label",
};

export const NODE_STATUS_SHORT_LABEL = {
    [NODE_STATUS.VALIDATED]: "system_nodes.validated_short_label",
    [NODE_STATUS.NOT_VALIDATED]: "system_nodes.not_validated_short_label",
    [NODE_STATUS.REJECTED]: "RJ",
    [NODE_STATUS.REMOVED]: "system_nodes.removed_short_label",
    [NODE_STATUS.PENDING]: "P",
};

export const NODE_STATUS_COLOR = {
    [NODE_STATUS.VALIDATED]: "#89aa11",
    [NODE_STATUS.NOT_VALIDATED]: "#be2525",
    [NODE_STATUS.REJECTED]: "#be2525",
    [NODE_STATUS.REMOVED]: "#e08600",
    [NODE_STATUS.PENDING]: "#e08600",
};

export const NODE_TYPE = {
    PROFILE: "PROFILE",
    CLUSTER: "SKILL_CLUSTER",
    SKILL: "SKILL",
    DOMAIN: "DOMAIN",
};

export const NODE_TYPE_LIST = [
    NODE_TYPE.PROFILE,
    NODE_TYPE.CLUSTER,
    NODE_TYPE.SKILL,
    NODE_TYPE.DOMAIN,
];

export const NODE_TYPE_CLASS_NAME = {
    [NODE_TYPE.PROFILE]: "profile",
    [NODE_TYPE.CLUSTER]: "skill_cluster",
    [NODE_TYPE.SKILL]: "skill",
    [NODE_TYPE.DOMAIN]: "domain",
};

export const NODE_TYPE_LABEL = {
    [NODE_TYPE.PROFILE]: "system_nodes.profile_label",
    [NODE_TYPE.CLUSTER]: "system_nodes.skill_cluster_label",
    [NODE_TYPE.SKILL]: "system_nodes.skill_label",
    [NODE_TYPE.DOMAIN]: "system_nodes.domain_label",
};

export const NODE_TYPE_SHORT_LABEL = {
    [NODE_TYPE.PROFILE]: "system_nodes.profile_short_label",
    [NODE_TYPE.CLUSTER]: "system_nodes.cluster_short_label",
    [NODE_TYPE.SKILL]: "system_nodes.skill_short_label",
    [NODE_TYPE.DOMAIN]: "system_nodes.domain_short_label",
};

export const NODE_SOURCE = {
    USER: "USER",
    ADMIN: "ADMIN",
    OTHER: "OTHER",
};

export const NODE_SOURCE_LIST = [NODE_SOURCE.USER, NODE_SOURCE.ADMIN];

export const NODE_SOURCE_CLASS_NAME = {
    [NODE_SOURCE.USER]: "user-source",
    [NODE_SOURCE.ADMIN]: "admin-source",
};

export const NODE_SOURCE_LABEL = {
    [NODE_SOURCE.USER]: "system_nodes.user_source_label",
    [NODE_SOURCE.ADMIN]: "system_nodes.admin_source_label",
};

export const NODE_SOURCE_SHORT_LABEL = {
    [NODE_SOURCE.USER]: "system_nodes.user_source_short_label",
    [NODE_SOURCE.ADMIN]: "system_nodes.admin_source_short_label",
};

export const NODE_SORTING = {
    CREATION_DATE: "CREATED_AT",
    LAST_UPDATE_DATE: "EDITED_AT",
    NAME: "TITLE",
};

export const NODE_SORTING_LABEL = {
    [NODE_SORTING.CREATION_DATE]: "shared.creation_date_label",
    [NODE_SORTING.LAST_UPDATE_DATE]: "shared.last_update_label",
    [NODE_SORTING.NAME]: "shared.name_label",
};

export const NODE_SORTING_LIST = [
    NODE_SORTING.NAME,
    NODE_SORTING.LAST_UPDATE_DATE,
    NODE_SORTING.CREATION_DATE,
];

export const EDGE_TYPE = {
    CONFIRMED: "CONFIRMED",
    NOT_MODERATED: "NOT_MODERATED",
};

export const NODE_DOMAIN = {
    ADMINISTRATION_AND_MANAGEMENT: "Administration & Management",
    COMMUNICATION: "Communication",
    CULTURE_SPORT_AND_ENTERTAINMENT: "Art, Culture, Sports & Entertainment",
    GOV: "Gov",
    IT: "IT",
    LAND_SEA_AND_AIR: "Land, Sea & Air",
    LEGAL: "Legal",
    LIFE_AND_DEATH: "Life & Death",
    MANUFACTURING: "Manufacturing & Working with Materials",
    SALES_AND_MARKETING: "Sales, Business & Finance",
    SERVICES: "Services",
    STEM: "STEM",
};

export const NODE_DOMAIN_LIST = [
    NODE_DOMAIN.ADMINISTRATION_AND_MANAGEMENT,
    NODE_DOMAIN.COMMUNICATION,
    NODE_DOMAIN.CULTURE_SPORT_AND_ENTERTAINMENT,
    NODE_DOMAIN.GOV,
    NODE_DOMAIN.IT,
    NODE_DOMAIN.LAND_SEA_AND_AIR,
    NODE_DOMAIN.LEGAL,
    NODE_DOMAIN.LIFE_AND_DEATH,
    NODE_DOMAIN.MANUFACTURING,
    NODE_DOMAIN.SALES_AND_MARKETING,
    NODE_DOMAIN.SERVICES,
    NODE_DOMAIN.STEM,
];

export const NODE_DOMAIN_CLASS_NAME = {
    [NODE_DOMAIN.ADMINISTRATION_AND_MANAGEMENT]: "Administration & Management",
    [NODE_DOMAIN.COMMUNICATION]: "Communication",
    [NODE_DOMAIN.CULTURE_SPORT_AND_ENTERTAINMENT]: "Art, Culture, Sports & Entertainment",
    [NODE_DOMAIN.GOV]: "Gov",
    [NODE_DOMAIN.IT]: "IT",
    [NODE_DOMAIN.LAND_SEA_AND_AIR]: "Land, Sea & Air",
    [NODE_DOMAIN.LEGAL]: "Legal",
    [NODE_DOMAIN.LIFE_AND_DEATH]: "Life & Death",
    [NODE_DOMAIN.MANUFACTURING]: "Manufacturing & Working with Materials",
    [NODE_DOMAIN.SALES_AND_MARKETING]: "Sales, Business & Finance",
    [NODE_DOMAIN.SERVICES]: "Services",
    [NODE_DOMAIN.STEM]: "STEM",
};

export const NODE_DOMAIN_LABEL = {
    [NODE_DOMAIN.ADMINISTRATION_AND_MANAGEMENT]:
        "system_nodes.domains.administration_and_management",
    [NODE_DOMAIN.COMMUNICATION]: "system_nodes.domains.communication",
    [NODE_DOMAIN.CULTURE_SPORT_AND_ENTERTAINMENT]:
        "system_nodes.domains.culture_sport_and_entertainment",
    [NODE_DOMAIN.GOV]: "system_nodes.domains.gov",
    [NODE_DOMAIN.IT]: "system_nodes.domains.it",
    [NODE_DOMAIN.LAND_SEA_AND_AIR]: "system_nodes.domains.land_sea_and_air",
    [NODE_DOMAIN.LEGAL]: "system_nodes.domains.legal",
    [NODE_DOMAIN.LIFE_AND_DEATH]: "system_nodes.domains.life_and_death",
    [NODE_DOMAIN.MANUFACTURING]: "system_nodes.domains.manufacturing",
    [NODE_DOMAIN.SALES_AND_MARKETING]: "system_nodes.domains.sales_and_marketing",
    [NODE_DOMAIN.SERVICES]: "system_nodes.domains.services",
    [NODE_DOMAIN.STEM]: "system_nodes.domains.stem",
};

export const NODE_DOMAIN_DESCRIPTION = {
    [NODE_DOMAIN.ADMINISTRATION_AND_MANAGEMENT]:
        "system_nodes.domains.administration_and_management_description",
    [NODE_DOMAIN.COMMUNICATION]: "system_nodes.domains.communication_description",
    [NODE_DOMAIN.CULTURE_SPORT_AND_ENTERTAINMENT]:
        "system_nodes.domains.culture_sport_and_entertainment_description",
    [NODE_DOMAIN.GOV]: "system_nodes.domains.gov_description",
    [NODE_DOMAIN.IT]: "system_nodes.domains.it_description",
    [NODE_DOMAIN.LAND_SEA_AND_AIR]: "system_nodes.domains.land_sea_and_air_description",
    [NODE_DOMAIN.LEGAL]: "system_nodes.domains.legal_description",
    [NODE_DOMAIN.LIFE_AND_DEATH]: "system_nodes.domains.life_and_death_description",
    [NODE_DOMAIN.MANUFACTURING]: "system_nodes.domains.manufacturing_description",
    [NODE_DOMAIN.SALES_AND_MARKETING]: "system_nodes.domains.sales_and_marketing_description",
    [NODE_DOMAIN.SERVICES]: "system_nodes.domains.services_description",
    [NODE_DOMAIN.STEM]: "system_nodes.domains.stem_description",
};
