import { connect } from 'react-redux';
import JobsStack from './JobsStack';
import { getTranslation } from '../../../../../reducers/common/localization';
import { onJobPress } from '../../../../../reducers/quickfind/quickfind';

const mapStateToProps = state => ({
	translation: getTranslation(state)
});

const mapDispatchToProps = dispatch => ({
	onRemoveJobClick: (jobId) => dispatch(onJobPress(jobId))
});

export default connect(mapStateToProps, mapDispatchToProps)(JobsStack);
