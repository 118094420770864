import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './AlternativeNowBlackListModal.scss';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import _ from "lodash";
import Icon from '../../../common/icon/Icon';

class AlternativeNowBlackListModal extends Component {
    render() {
        return (
            <Modal isOpen={this.props.isOpen} className="alternative-now-black-list-modal" toggle={this.props.onCancel}>
                <ModalHeader>
                    {_.get(this.props.translation, "job_compass_page.hidden_alternative_profiles_modal_label")}
                </ModalHeader>
                <ModalBody>
                    {this.renderModalBody()}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        onClick={this.props.onCancel}
                    >
                        {_.get(this.props.translation, 'actions.cancel_button')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalBody() {
        if (!this.props.blackList || this.props.blackList.length === 0) {
            return (
                <h6 className="no-profiles-label">{_.get(this.props.translation, "job_compass_page.no_hidden_alternative_profiles_label")}</h6>
            )
        }
        return (
            <ListGroup className="hidden-profiles-container" flush>
                {
                    _.map(this.props.blackList, (item) => {
                        return (
                            <ListGroupItem key={item.profile.id} className="hidden-profile-item">
                                <h6>{item.profile.titles.en}</h6>
                                <Icon
                                    type="undo"
                                    size="sm"
                                    color="info"
                                    title={_.get(this.props.translation, "job_compass_page.remove_from_hidden_alternative_profiles_label")}
                                    onClick={() => this.props.onRemoveProfileFromBlackList(item.profile.id)}
                                />
                            </ListGroupItem>
                        )
                    })
                }
            </ListGroup>
        )
    }
}

AlternativeNowBlackListModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    blackList: PropTypes.array.isRequired,
    onRemoveProfileFromBlackList: PropTypes.func.isRequired
};

export default AlternativeNowBlackListModal;
