import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SystemNodeSource.scss';
import _ from 'lodash';
import { NODE_SOURCE_CLASS_NAME } from '../../../../constants/systemNodesConstants';
import { Badge } from 'reactstrap';

class SystemNodeSource extends Component {
    render() {
        return (
            // TODO: remove hardcoded value
            <Badge color="secondary" className={"system-node-source " + (NODE_SOURCE_CLASS_NAME[_.toUpper(this.props.source)] || "")}>
                {this.props.source}
            </Badge>
        );
    }
}

SystemNodeSource.propTypes = {
    translation: PropTypes.object.isRequired,
    source: PropTypes.string,
}

export default SystemNodeSource;
