import React, { Component } from "react";
import "./RadialProgressBar.scss";
import PropTypes from "prop-types";

class RadialProgressBar extends Component {
    render() {
        const radius = 8;
        const totalLength = 2 * Math.PI * radius;
        const size = 20;

        if (this.props.progress === 100) {
            return (
                <svg
                    className="radial-progress-bar-done-mark"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${size} ${size}`}
                >
                    <circle cx={size / 2} cy={size / 2} r={radius + 1.5} />
                    <path
                        fill="#ffffff"
                        d="M13.9,6.3L8,12.2l-1.9-1.9c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l2.6,2.6c0.4,0.4,1,0.4,1.4,0l6.6-6.6 c0.4-0.4,0.4-1,0-1.4C14.9,5.9,14.3,5.9,13.9,6.3z"
                    />
                </svg>
            );
        }

        return (
            <svg
                className={`radial-progress-bar`}
                width={size}
                height={size}
                viewBox={`0 0 ${size} ${size}`}
            >
                <circle cx={size / 2} cy={size / 2} r={radius} stroke={this.props.circleColor} />
                <circle
                    className="bar"
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeDasharray={totalLength}
                    strokeDashoffset={
                        totalLength - (totalLength * (this.props.progress || 0)) / 100
                    }
                />
                {this.props.showWarning && (
                    <circle className="warning-circle" cx={size / 2} cy={size / 2} r={1} />
                )}
            </svg>
        );
    }
}

RadialProgressBar.propTypes = {
    progress: PropTypes.number.isRequired,
    circleColor: PropTypes.string.isRequired,
};

export default RadialProgressBar;
