import { connect } from "react-redux";
import { getTranslation } from "../../../../reducers/common/localization";
import JobEditModal from "./JobEditModal";
import {
    getActiveModalType,
    hideModal,
    MODAL_JOB_EDIT,
} from "../../../../reducers/common/activeModal";
import { getActiveJobId } from "../../../../reducers/common/activeModal";
import { getJobById } from "../../../../reducers/organization/organizations";

const mapStateToProps = (state) => {
    return {
        isOpen: getActiveModalType(state) === MODAL_JOB_EDIT,
        translation: getTranslation(state),
        activeJobId: getActiveJobId(state),
        activeJob: getJobById(state, getActiveJobId(state)),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClose: () => dispatch(hideModal(MODAL_JOB_EDIT)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobEditModal);
