
import React, { Component } from 'react';

class VideoMessage extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 -2 512 512">
                <path d="m448 508.800781-148.59375-92.800781h-267.40625c-17.671875 0-32-14.328125-32-32v-352c0-17.671875 14.328125-32 32-32h448c17.671875 0 32 14.328125 32 32v352c0 17.671875-14.328125 32-32 32h-32zm0 0" fill="#ffda6b" />
                <path d="m416 294.191406-128-42.65625v-87.070312l128-42.65625zm0 0" fill="#fc6454" />
                <path d="m128 112h160c17.671875 0 32 14.328125 32 32v128c0 17.671875-14.328125 32-32 32h-160c-17.671875 0-32-14.328125-32-32v-128c0-17.671875 14.328125-32 32-32zm0 0" fill="#ff7761" />
                <g fill="#fff">
                    <path d="m160 192h32v32h-32zm0 0" />
                    <path d="m224 192h32v32h-32zm0 0" />
                </g>
            </svg>
        );
    }
}

export default VideoMessage;
