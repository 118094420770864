import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Carousel, CarouselItem } from "reactstrap";
import "./Feedback.scss";
import Icon from "../../../../common/icon/Icon";
import _ from "lodash";

class Feedback extends Component {
    next() {
        if (this.props.animating) return;
        const nextIndex =
            this.props.currentFeedbackIndex === this.props.feedbacks.length - 1
                ? 0
                : this.props.currentFeedbackIndex + 1;
        this.props.setParentStateValue("currentFeedbackIndex", nextIndex);
    }

    previous() {
        if (this.props.animating) return;
        const nextIndex =
            this.props.currentFeedbackIndex === 0
                ? this.props.feedbacks.length - 1
                : this.props.currentFeedbackIndex - 1;
        this.props.setParentStateValue("currentFeedbackIndex", nextIndex);
    }

    goToIndex(newIndex) {
        if (this.props.animating) return;
        this.props.setParentStateValue("currentFeedbackIndex", newIndex);
    }

    getSlides(feedbacks) {
        const { translation } = this.props;
        return feedbacks.map(({ text, from, date }, index, arr) => (
            <CarouselItem
                onExiting={() => this.props.setParentStateValue("animating", true)}
                onExited={() => this.props.setParentStateValue("animating", false)}
                key={index} // switch on job.id
            >
                <div className="feedback-inner">
                    <div className="meta-info">
                        <span>{`${_.get(translation, "shared.from")}: ${from}`}</span>
                        <span>{`${_.get(translation, "shared.date_label")}: ${date}`}</span>
                    </div>
                    <div className="feedback-info">{text}</div>
                    <Button size="sm" color="success" disabled={index !== arr.length - 1}>
                        {/* TODO: send request to remove all notifications from job if user click "ok" on the last notification */}
                        {_.get(translation, "actions.ok_button")}
                    </Button>
                </div>
            </CarouselItem>
        ));
    }
    render() {
        const { feedbacks, translation } = this.props;
        return feedbacks ? (
            <div style={{ position: "relative" }}>
                <div className="feedback-controls">
                    <Icon type="red_notification" number={feedbacks.length} x={50} y={65} />
                    <span>{_.get(translation, "applicant_jobs_page.job_notifications_label")}</span>
                    <div className="dots-controls-container">
                        {feedbacks.length > 1 &&
                            feedbacks.map((feedback, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => this.goToIndex(index)}
                                        className={`job-dot ${
                                            this.props.currentFeedbackIndex === index
                                                ? "active"
                                                : ""
                                        }`}
                                    />
                                );
                            })}
                    </div>
                </div>
                <div className="feedback-info-container">
                    <Carousel
                        activeIndex={this.props.currentFeedbackIndex}
                        next={() => this.next()}
                        previous={() => this.previous()}
                        interval={false}
                        slide={true}
                    >
                        {this.getSlides(this.props.feedbacks, "jobDescription")}
                    </Carousel>
                </div>
            </div>
        ) : null;
    }
}

Feedback.propTypes = {
    animating: PropTypes.bool.isRequired,
    activeIndex: PropTypes.number.isRequired,
    jobs: PropTypes.array,
    setCurrentJobIndex: PropTypes.func.isRequired,
    setAnimating: PropTypes.func.isRequired,
};

export default Feedback;
