import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import { LOCATION_MAP } from '../../constants/constants';
import _ from 'lodash';

//- Actions
export const fetchGetCountriesFailed = createAction('FETCH_GET_COUNTRIES_FAILED');
export const fetchGetCountriesSuccess = createAction('FETCH_GET_COUNTRIES_SUCCESS');
export const fetchGetCountries = createAction(
    'FETCH_GET_COUNTRIES',
    () => Api.getCountries(),
    () => ({
        apiCall: true,
        onSuccess: fetchGetCountriesSuccess,
        onFail: fetchGetCountriesFailed,
        ignoreUnauthorized: true,
        ignoreForbidden: true
    })
);

//- State
const initialState = {
    data: null,
    error: null,
    fetching: false
};

//- Reducers
export default handleActions({

    FETCH_GET_COUNTRIES: (state) => {
        return { ...state, data: null, error: null, fetching: true };
    },
    FETCH_GET_COUNTRIES_FAILED: (state, action) => {
        return { ...state, data: null, error: action.payload, fetching: false };
    },
    FETCH_GET_COUNTRIES_SUCCESS: (state, action) => {
        let countries = action.payload;
        let data = {};
        _.mapValues(LOCATION_MAP, (value, continentCode) => {
            let newValue = _.filter(value, (elem) => _.includes(countries, elem));
            data[continentCode] = newValue;
        });
        return { ...state, data: data, error: null, fetching: false };
    }

}, initialState);


//- Selectors
export const getLocationState = state => state.location;

export const getLocationData = state => getLocationState(state).data;

export const getLocationDataFetchingStatus = state => getLocationState(state).fetching;
