import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getActiveJobId } from "../../../../reducers/applicant/applicantJobs";
import { getTranslation } from "../../../../reducers/common/localization";
import {
    fetchDeleteDepartmentMedia,
    fetchGetDepartmentsList,
    fetchUpdateDepartmentMedia,
    getDepartmentsListData,
    getDepartmentsListDataError,
} from "../../../../reducers/organization/departmentsList";
import {
    fetchDeleteOrgMedia,
    fetchGetOrganizationsList,
    fetchUpdateOrganizationMedia,
    getOrganizationsListError,
    getOrganizationsListState,
} from "../../../../reducers/organization/organizations";
import {
    fetchDeleteTeamsMedia,
    fetchGetTeams,
    fetchUpdateTeamsMedia,
    getTeamsError,
    getTeamsList,
} from "../../../../reducers/organization/teams";
import {
    deleteUserMediaByURI,
    getUserData,
    getUserDataError,
    getUserJobsState,
    uploadUserMediaData,
} from "../../../../reducers/user/userData";
import { getUserCurrentSubRole, getUserRole } from "../../../../reducers/user/userRole";
import RichMedia from "./RichMedia";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        userData: getUserData(state),
        activeJobId: getActiveJobId(state),
        usersJob: getUserJobsState(state),
        userRole: getUserRole(state),
        userSubRole: getUserCurrentSubRole(state),
        userDepts: getDepartmentsListData(state),
        userOrganization: getOrganizationsListState(state),
        userTeams: getTeamsList(state),
        err:
            getUserDataError(state) ||
            getDepartmentsListDataError(state)?.message ||
            getOrganizationsListError(state)?.message ||
            getTeamsError(state)?.message,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        approveUserMedia: (data) => dispatch(uploadUserMediaData(data)),

        onGetDepartmentsList: () => dispatch(fetchGetDepartmentsList()),

        onGetOrganizationsList: () => dispatch(fetchGetOrganizationsList()),

        onUpdateDepartmentsMedia: (departmentId, data) =>
            dispatch(fetchUpdateDepartmentMedia(departmentId, data)),

        onFetchTeamsRelatedToDept: (departmentId) => dispatch(fetchGetTeams(departmentId)),

        onUpdateOrgMedia: (data) => dispatch(fetchUpdateOrganizationMedia(data)),

        onUpdateTeamsMedia: (teamId, data) => dispatch(fetchUpdateTeamsMedia(teamId, data)),

        onDeleteOrgMedia: (mediaId) =>
            dispatch(fetchDeleteOrgMedia(mediaId)),

        onDeleteDepartmentMedia: (departmentId, mediaId) =>
            dispatch(fetchDeleteDepartmentMedia(departmentId, mediaId)),

        onDeleteTeamsMedia: (teamId, mediaId) =>
            dispatch(fetchDeleteTeamsMedia(teamId, mediaId)),

        onDeleteUserMedia: (mediaId) =>
            dispatch(deleteUserMediaByURI(mediaId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RichMedia));
