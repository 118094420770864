import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './StaffAndDepartments.scss';
import StaffList from '../staff/staffList/StaffList.container';
import DepartmentsList from '../department/departmentsList/DepartmentsList.container';

class StaffAndDepartments extends Component {
    render() {
        return (
            <div className="staff-and-departments">
                <StaffList />
                <div className="content-divider" />
                <DepartmentsList />
            </div>
        );
    }
}

StaffAndDepartments.propTypes = {
    translation: PropTypes.object.isRequired,
};

export default StaffAndDepartments;
