import _ from "lodash";

export const validate = (resetFunction, setStateFunction, fields, state, props) => {
    resetFunction();
    const requiredFields = Object.values(fields);
    let fieldsObject = {};
    const errors = {};
    requiredFields.forEach((obj) => {
        fieldsObject = { ...fieldsObject, ...obj };
    });

    for (const field in fieldsObject) {
        fieldsObject[field].forEach((validateFunction) => {
            try {
                const value = _.isNil(state[field]) ? props[field] : state[field];
                validateFunction(value);
            } catch ({ message }) {
                errors[field] = errors[field] ? [...errors[field], message] : [message];
            }
        });
    }
    setStateFunction(errors);
};

export const emptyObjectValidator = (value, message) => {
    if (_.isNil(value) || (_.isArray(value) && _.isEmpty(value))) {
        throw new Error(message);
    }
};

export const requiredMediaValidator = (value, type, message) => {
    const isRequiredTypeIncluded = value.find((file) => file.type.includes(type));
    if (!isRequiredTypeIncluded) {
        throw new Error(message);
    }
};

export const nullValidator = (value, message) => {
    if (_.isNil(value)) {
        throw new Error(message);
    }
};

export const emptyStringValidator = (value, message) => {
    if (value === "") {
        throw new Error(message);
    }
};
