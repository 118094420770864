import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import _ from "lodash";

//- Actions
export const fetchGetSearchSkillsFailed = createAction("FETCH_GET_SEARCH_SKILLS_FAILED");
export const fetchGetSearchSkillsSuccess = createAction("FETCH_GET_SEARCH_SKILLS_SUCCESS");
export const onFetchGetSearchSkillsSuccess = (data) => (dispatch) => {
    let formattedSkills = _.map(data.content, (skill) => {
        return {
            ...skill,
            id: skill.id,
            title: skill.titles.en,
            clusters: skill.clusters
                ? skill.clusters.map((cluster) => ({
                      clusterId: cluster.id,
                      clusterTitle: cluster.titles.en,
                  }))
                : [],
        };
    });
    dispatch(fetchGetSearchSkillsSuccess(formattedSkills));
};
export const fetchGetSearchSkills = createAction(
    "FETCH_GET_SEARCH_SKILLS",
    (filter) => Api.getSearchSkills(filter),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSearchSkillsSuccess,
        onFail: fetchGetSearchSkillsFailed,
    })
);

export const fetchGetSearchClustersFailed = createAction("FETCH_GET_SEARCH_CLUSTERS_FAILED");
export const fetchGetSearchClustersSuccess = createAction("FETCH_GET_SEARCH_CLUSTERS_SUCCESS");
export const onFetchGetSearchClustersSuccess = (data) => (dispatch) => {
    let formattedClusters = _.map(data.content, (cluster) => {
        let skills = _.map(cluster.skills, (skill) => {
            return {
                id: skill.id,
                title: skill.titles.en,
                categoryId: skill.category.id,
                categoryTitle: skill.category.titles.en,
            };
        });
        return {
            categoryId: cluster.id,
            categoryTitle: cluster.titles.en,
            skills: skills,
        };
    });
    dispatch(fetchGetSearchClustersSuccess(formattedClusters));
};
export const fetchGetSearchClusters = createAction(
    "FETCH_GET_SEARCH_CLUSTERS",
    (filter) => Api.getSearchClusters(filter),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSearchClustersSuccess,
        onFail: fetchGetSearchClustersFailed,
    })
);

export const onGetSearchSkillsAndClusters = (filter) => (dispatch) => {
    dispatch(fetchGetSearchSkills(filter));
    dispatch(fetchGetSearchClusters(filter));
};

export const cleanSearchSkillsAndClusters = createAction("CLEAN_SEARCH_SKILLS_AND_CLUSTERS");

//- State
const initialState = {
    searchSkills: null,
    searchClusters: null,
    error: null,
    fetchingSkills: false,
    fetchingClusters: false,
};

//- Reducers
export default handleActions(
    {
        FETCH_GET_SEARCH_SKILLS: (state) => {
            return { ...state, searchSkills: null, error: null, fetchingSkills: true };
        },
        FETCH_GET_SEARCH_SKILLS_FAILED: (state, action) => {
            return { ...state, searchSkills: null, error: action.payload, fetchingSkills: false };
        },
        FETCH_GET_SEARCH_SKILLS_SUCCESS: (state, action) => {
            return { ...state, searchSkills: action.payload, error: null, fetchingSkills: false };
        },
        FETCH_GET_SEARCH_CLUSTERS: (state) => {
            return { ...state, searchClusters: null, error: null, fetchingClusters: true };
        },
        FETCH_GET_SEARCH_CLUSTERS_FAILED: (state, action) => {
            return {
                ...state,
                searchClusters: null,
                error: action.payload,
                fetchingClusters: false,
            };
        },
        FETCH_GET_SEARCH_CLUSTERS_SUCCESS: (state, action) => {
            return {
                ...state,
                searchClusters: action.payload,
                error: null,
                fetchingClusters: false,
            };
        },
        CLEAN_SEARCH_SKILLS_AND_CLUSTERS: (state) => {
            return { ...state, searchSkills: null, searchClusters: null };
        },
    },
    initialState
);

//- Selectors
export const getSearchSkillsAndClustersState = (state) => state.searchSkillsAndClusters;

export const getSearchSkillsData = (state) => getSearchSkillsAndClustersState(state).searchSkills;

export const getSearchClustersData = (state) =>
    getSearchSkillsAndClustersState(state).searchClusters;

export const getSearchSkillsFetchingStatus = (state) =>
    getSearchSkillsAndClustersState(state).fetchingSkills;

export const getSearchClustersFetchingStatus = (state) =>
    getSearchSkillsAndClustersState(state).fetchingClusters;

export const getSearchSkillsAndClustersFetchingStatus = (state) =>
    getSearchSkillsFetchingStatus(state) || getSearchClustersFetchingStatus(state);
