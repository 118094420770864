import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./components/application/App.container";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { createEpicMiddleware } from "redux-observable";
import thunk from "redux-thunk";
import { rootEpic, rootReducer } from "./reducers/index";
import { fetchGetCountries } from "./reducers/common/location";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { changeLanguage, getDefaultLanguage } from "./reducers/common/localization";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./i18n/resources";

i18n.use(initReactI18next)
    .init({
        lng: "en",
        resources: resources,
    });

const epicMiddleware = createEpicMiddleware();
let store = createStore(rootReducer, applyMiddleware(thunk, epicMiddleware));
epicMiddleware.run(rootEpic);

store.dispatch(fetchGetCountries());

store.dispatch(changeLanguage(getDefaultLanguage()));

let AppContainer = withRouter(App);

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <AppContainer />
        </Router>
    </Provider>,
    document.getElementById("root"),
);

serviceWorker.unregister();
