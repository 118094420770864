import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import { setRequestStatusFailed, onSetRequestStatusSuccess, setRequestStatusSubmit } from '../common/requestStatus';
import { REQUEST_SUCCESS_MESSAGE } from '../../constants/requestStatusConstants';

//- Actions
export const fetchAccountToResetPasswordDataSuccess = createAction('FETCH_ACCOUNT_TO_RESET_PASSWORD_DATA_SUCCESS');
export const fetchAccountToResetPasswordDataFailed = createAction('FETCH_ACCOUNT_TO_RESET_PASSWORD_DATA_FAILED');
export const fetchAccountToResetPasswordData = createAction(
    'FETCH_ACCOUNT_TO_RESET_PASSWORD_DATA',
    (token) => Api.getAccountDataByToken(token),
    () => ({
        apiCall: true,
        onSuccess: fetchAccountToResetPasswordDataSuccess,
        onFail: fetchAccountToResetPasswordDataFailed,
        ignoreUnauthorized: true,
        ignoreForbidden: true
    })
);

export const fetchChangePasswordSuccess = createAction('FETCH_CHANGE_PASSWORD_SUCCESS');
export const fetchChangePasswordFailed = createAction('FETCH_CHANGE_PASSWORD_FAILED');
export const fetchChangePassword = createAction(
    'FETCH_CHANGE_PASSWORD',
    (data) => Api.resetPassword(data),
    () => ({
        apiCall: true,
        onSuccess: fetchChangePasswordSuccess,
        onFail: fetchChangePasswordFailed,
        ignoreUnauthorized: true,
        ignoreForbidden: true
    })
);

export const fetchAccountToSendResetPasswordEmailSuccess = createAction('FETCH_ACCOUNT_TO_SEND_RESET_PASSWORD_EMAIL_SUCCESS');
export const fetchAccountToSendResetPasswordEmailFailed = createAction('FETCH_ACCOUNT_TO_SEND_RESET_PASSWORD_EMAIL_FAILED');
export const fetchAccountToSendResetPasswordEmail = createAction(
    'FETCH_ACCOUNT_TO_SEND_RESET_PASSWORD_EMAIL',
    (email) => Api.sendUserPasswordResetEmail(email),
    () => ({
        apiCall: true,
        onSuccess: fetchAccountToSendResetPasswordEmailSuccess,
        onFail: fetchAccountToSendResetPasswordEmailFailed,
        ignoreUnauthorized: true,
        ignoreForbidden: true
    })
);

export const fetchChangeEmailSuccess = createAction('FETCH_CHANGE_EMAIL_SUCCESS');
export const fetchChangeEmailFailed = createAction('FETCH_CHANGE_EMAIL_FAILED');
export const fetchChangeEmail = createAction(
    'FETCH_CHANGE_EMAIL',
    (data) => Api.resetUserEmail(data),
    () => ({
        apiCall: true,
        onSuccess: fetchChangePasswordSuccess,
        onFail: fetchChangePasswordFailed,
        ignoreUnauthorized: true,
        ignoreForbidden: true
    })
)

export const fetchResetPassword = (data, token) => (dispatch) => {
    dispatch(setRequestStatusSubmit());
    dispatch(onFetchResetPassword(data, token));
}
export const onFetchResetPassword = createAction(
    'FETCH_RESET_PASSWORD',
    (data, token) => Api.resetPassword(data, token),
    () => ({
        apiCall: true,
        onSuccess: onSetRequestStatusSuccess(REQUEST_SUCCESS_MESSAGE.NO_MESSAGE),
        onFail: setRequestStatusFailed,
        ignoreUnauthorized: true,
        ignoreForbidden: true
    })
);

//- State
const initialState = {
    data: null,
    fetching: false,
    error: null
};

//- Reducers
export default handleActions({

    FETCH_ACCOUNT_TO_RESET_PASSWORD_DATA: (state) => {
        return { ...state, data: null, error: null, fetching: true };
    },
    FETCH_ACCOUNT_TO_RESET_PASSWORD_DATA_FAILED: (state, action) => {
        return { ...state, data: null, error: action.payload, fetching: false };
    },
    FETCH_ACCOUNT_TO_RESET_PASSWORD_DATA_SUCCESS: (state, action) => {
        return { ...state, data: action.payload, error: null, fetching: false };
    },
    FETCH_CHANGE_PASSWORD: (state) => {
        return {...state}
    },
    FETCH_CHANGE_PASSWORD_SUCCESS: (state) => {
        return { ...state, error: null, fetching: false }
    },
    FETCH_CHANGE_PASSWORD_FAILED: (state, action) => {
        return { ...state, error: action.payload, fetching: false };
    },
    FETCH_CHANGE_EMAIL: (state) => {
        return {...state}
    },
    FETCH_CHANGE_EMAIL_SUCCESS: (state) => {
        return { ...state, error: null, fetching: false }
    },
    FETCH_CHANGE_EMAIL_FAILED: (state, action) => {
        return { ...state, error: action.payload, fetching: false };
    }

}, initialState);


//- Selectors
export const getResetPasswordState = state => state.resetPassword;

export const getAccountToResetPasswordData = state => getResetPasswordState(state).data;

export const getAccountToResetPasswordFetchingStatus = state => getResetPasswordState(state).fetching;

export const getAccountToResetPasswordError = state => getResetPasswordState(state).error;
