import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap';

class RemoveStaffMemberModal extends Component {
    render() {
        const { data, isOpen, onClose, translation } = this.props;

        if (!data) {
            return null;
        }

        return (
            <Modal
                isOpen={isOpen}
                toggle={onClose}
            >
                <ModalBody>
                    <h6>{_.get(translation, 'staff.remove_staff_member_modal_alert').replace('{name}', `${data.firstName} ${data.lastName}`)}</h6>
                </ModalBody>
                <ModalFooter className="content-space-between">
                    <Button
                        color="secondary"
                        onClick={onClose}
                    >
                        {_.get(translation, 'actions.cancel_button')}
                    </Button>
                    <Button
                        color="primary"
                        onClick={this.onSubmit}
                    >
                        {_.get(translation, "actions.remove_button")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }


    onSubmit = () => {
        const { data, onClose, onRemoveStaffMember } = this.props;
        onRemoveStaffMember({ staffId: data.id });
        onClose();
    }
}

RemoveStaffMemberModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    data: PropTypes.object,
    onRemoveStaffMember: PropTypes.func,
    onClose: PropTypes.func
};

export default RemoveStaffMemberModal;
