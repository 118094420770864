import React, { Component } from "react";
import PropTypes from "prop-types";
import "./SkillsWithoutLevelModal.scss";
import _ from "lodash";
import { Modal, ModalBody, ModalFooter, Button, ListGroup, ListGroupItem } from "reactstrap";
import { Utils } from "../../../../utils/utils";

class SkillsWithoutLevelModal extends Component {
    onHandleActionButton = () => {
        const { onClose, cbAfterClose } = this.props;
        onClose();
        if (cbAfterClose) {
            cbAfterClose();
        }
    };
    render() {
        if (!this.props.data) {
            return null;
        }

        return (
            <Modal
                className="skills-without-level-modal"
                isOpen={this.props.isOpen}
                toggle={this.onHandleActionButton}
            >
                <ModalBody>{this.renderModalBody()}</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.onHandleActionButton}>
                        {_.get(this.props.translation, "actions.ok_button")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalBody = () => {
        return (
            <div>
                <p>
                    {Utils.getTagsFromStringWithStyles(
                        _.get(this.props.translation, "skills_management.level_not_specified_label")
                    )}
                </p>
                <p>
                    {Utils.getTagsFromStringWithStyles(
                        _.get(
                            this.props.translation,
                            "skills_management.select_level_for_skills_label"
                        )
                    )}
                </p>
                {this.renderContent()}
            </div>
        );
    };

    renderContent = () => {
        return (
            <ListGroup flush>
                {_.map(this.props.data, (skill) => {
                    return <ListGroupItem key={skill.id}>{skill.title}</ListGroupItem>;
                })}
            </ListGroup>
        );
    };
}

SkillsWithoutLevelModal.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.array,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
};

export default SkillsWithoutLevelModal;
