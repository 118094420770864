import React, { Component } from "react";
import PropTypes from "prop-types";
import "./ChangeParentClusterModal.scss";
import _ from "lodash";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    Table,
    FormGroup,
    Badge,
} from "reactstrap";
import { NODE_DOMAIN_LABEL } from "../../../../../constants/systemNodesConstants";
import SystemNodeStatus from "../../systemNodeStatus/SystemNodeStatus.container";
import Icon from "../../../../common/icon/Icon";
import ChangeParentClusterPagination from "../../../../common/tablePagination/ChangeParentClusterPagination.container";
import BlockLoader from "../../../../common/blockLoader/BlockLoader.container";
import ChangeParentClusterFilters from "../../nodesFilters/ChangeParentClusterFilters.container";
import SystemNodeSource from "../../systemNodeSource/SystemNodeSource.container";

class ChangeParentClusterModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedParentCluster: null,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpen && !prevProps.isOpen) {
            this.props.onFetchGetClustersData(this.props.nodesType);
            this.setState({ selectedParentCluster: this.props.newCluster });
        }
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.onClose}
                className="change-parent-cluster-modal"
            >
                <ModalHeader>
                    {_.get(this.props.translation, "system_nodes.select_parent_cluster_label")}
                </ModalHeader>
                <ModalBody>{this.renderModalBody()}</ModalBody>
                <ModalFooter className="content-space-between">
                    <Button color="secondary" onClick={this.props.onClose}>
                        {_.get(this.props.translation, "actions.cancel_button")}
                    </Button>
                    <Button color="primary" onClick={this.onChangeParentClusterSubmit}>
                        {_.get(this.props.translation, "actions.save_button")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalBody = () => {
        return (
            <>
                <FormGroup row>
                    <ChangeParentClusterFilters />
                </FormGroup>
                <div className="clusters-list-table-container">
                    {!this.props.loading &&
                    (!this.props.nodesList || this.props.nodesList.length === 0) ? (
                        <h6 className="text-center">
                            {_.get(this.props.translation, "model_management_page.no_data_label")}
                        </h6>
                    ) : (
                        <Table className="clickable-rows">
                            <tbody>{this.renderNodesData()}</tbody>
                        </Table>
                    )}
                    <BlockLoader loading={this.props.loading} />
                </div>
                <ChangeParentClusterPagination />
                {this.props.currentNodeParentCluster ? (
                    <div>
                        <b>
                            {_.get(
                                this.props.translation,
                                "system_nodes.old_parent_cluster_label"
                            ) + ": "}
                        </b>
                        {this.props.currentNodeParentCluster.title}
                    </div>
                ) : null}
                <div>
                    <b>
                        {_.get(
                            this.props.translation,
                            `system_nodes.${
                                !_.isNil(this.props.currentNodeParentCluster)
                                    ? "new_parent_cluster_label"
                                    : "skill_cluster_label"
                            }`
                        ) + ": "}
                    </b>
                    {this.state.selectedParentCluster
                        ? this.state.selectedParentCluster.title
                        : "-"}
                    {this.state.selectedParentCluster ? (
                        <Icon
                            className="reset-selected-parent"
                            size="sm"
                            type="undo"
                            color="secondary"
                            onClick={this.onResetSelectedParent}
                            title={_.get(
                                this.props.translation,
                                "system_nodes.undo_change_cluster_label"
                            )}
                        />
                    ) : null}
                </div>
            </>
        );
    };

    renderNodesData() {
        return _.map(this.props.nodesList, (nodeData) => {
            let nodeStatus = nodeData.status;
            let nodeType = nodeData.type;
            let rowClassName = "clusters-list-row";
            let isCurrentParent =
                this.props.currentNodeParentCluster &&
                nodeData.id === this.props.currentNodeParentCluster.id;
            let isNewParent =
                this.state.selectedParentCluster &&
                nodeData.id === this.state.selectedParentCluster.id;
            if (isCurrentParent) {
                rowClassName += " current-parent";
            }
            if (isNewParent) {
                rowClassName += " new-parent";
            }

            return (
                <tr key={nodeData.id} className={rowClassName}>
                    {/* TODO: add details popup */}
                    <td className="clusters-list-type-cell">
                        <SystemNodeStatus type={nodeType} status={nodeStatus} />
                    </td>
                    <td className="clusters-list-name-cell">
                        <div className="clusters-list-name-cell-content">
                            <div className="node-name">{nodeData.title}</div>
                            {nodeData.domain && (
                                <div
                                    className="node-domain"
                                    title={_.get(
                                        this.props.translation,
                                        NODE_DOMAIN_LABEL[nodeData.domain]
                                    )}
                                >
                                    <i>
                                        {_.get(
                                            this.props.translation,
                                            "system_nodes.domain_label"
                                        ) + ": "}
                                    </i>
                                    {nodeData.domain.map((domain) => {
                                        return (
                                            <Badge className="node-domain-badge ml-3" color="light">
                                                {_.get(
                                                    this.props.translation,
                                                    NODE_DOMAIN_LABEL[domain.titles.en]
                                                )}
                                            </Badge>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </td>
                    <td className="system-node-source-cell">
                        <SystemNodeSource source={nodeData.source} />
                    </td>
                    {!isCurrentParent ? (
                        <td
                            className="clusters-list-action-cell table-cell-with-icon"
                            title={
                                isNewParent
                                    ? _.get(
                                          this.props.translation,
                                          "system_nodes.undo_change_cluster_label"
                                      )
                                    : _.get(
                                          this.props.translation,
                                          "system_nodes.set_as_new_cluster_label"
                                      )
                            }
                            onClick={
                                isNewParent
                                    ? this.onResetSelectedParent
                                    : this.onSelectNodeAsParent(nodeData)
                            }
                        >
                            <Icon type={isNewParent ? "undo" : "add"} color="secondary" />
                        </td>
                    ) : (
                        <td className="clusters-list-action-cell"></td>
                    )}
                </tr>
            );
        });
    }

    onSelectNodeAsParent = (nodeData) => () => {
        this.setState({ selectedParentCluster: nodeData });
    };

    onResetSelectedParent = () => {
        this.setState({ selectedParentCluster: null });
    };

    onChangeParentClusterSubmit = () => {
        this.props.onChangeParentCluster(
            this.props.currentNode.id,
            this.state.selectedParentCluster.id
        );
        this.props.onClose();
    };

    onClose = () => {
        this.props.onClose();
    };
}

ChangeParentClusterModal.propTypes = {
    // TODO: props
    translation: PropTypes.object.isRequired,
    data: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
};

export default ChangeParentClusterModal;
