import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SupportPage.scss';
import _ from 'lodash';
import { ROUTES } from '../../../../constants/constants';
import { Link } from 'react-router-dom';
import BackLink from '../../../common/buttons/backLink/BackLink.container';

class SupportPage extends Component {
    render() {
        return (
            <div className="support-page">
                <div className="back-link-container">
                    <BackLink to={ROUTES.LOGIN} label={_.get(this.props.translation, 'login_page.back_to_login_page_label')} />
                </div>
                <h4 className="text-center">{_.get(this.props.translation, 'support_page.page_label')}</h4>
                <ul>
                    <li>
                        <h5>{_.get(this.props.translation, 'support_page.question_activation_email')}</h5>
                        <div>
                            {_.get(this.props.translation, 'support_page.answer_activation_email_start')}
                            <Link to={ROUTES.RESEND_ACTIVATION}>
                                {_.get(this.props.translation, 'support_page.answer_activation_email_link')}
                            </Link>
                            {_.get(this.props.translation, 'support_page.answer_activation_email_end')}
                        </div>
                    </li>
                </ul>
            </div>
        );
    }
}

SupportPage.propTypes = {
    translation: PropTypes.object.isRequired,
};

export default SupportPage;
