import { connect } from 'react-redux';
import JobItem from './JobItem';
import { getSelectedJobsIds, onJobPress } from '../../../reducers/quickfind/quickfind';
import { onOpenJobQuickCompareModal } from '../../../reducers/quickfind/jobComparison';

const mapStateToProps = state => {
	return {
		selectedJobsIds: getSelectedJobsIds(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onJobClick: (jobId) => dispatch(onJobPress(jobId)),
		onOpen: (jobId) => dispatch(onOpenJobQuickCompareModal(jobId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(JobItem)
