import { connect } from 'react-redux';
import { getTranslation } from '../../../reducers/common/localization';
import TagComponent from './TagComponent';
import {
	MODAL_QUICKFIND_SET_SKILL_LEVEL,
	showModal
} from '../../../reducers/common/activeModal';
import { setSelectedSkillId } from '../../../reducers/quickfind/quickfind';

const mapStateToProps = state => {
	return {
		translation: getTranslation(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setSelectedSkillId: (id) => dispatch(setSelectedSkillId(id)),
		onShowSkillLevelModal: () => dispatch(showModal(MODAL_QUICKFIND_SET_SKILL_LEVEL)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TagComponent);
