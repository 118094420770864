import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Skill from "../skills/skill/Skill.container";
import {
    COMPARISON_LEVEL_CLASS_NAME,
    LEVEL_CLASS_NAME,
    REQUIREMENT_COEFFICIENT_CLASS_NAME,
} from "../../../constants/skillsConstants";
import SkillComparisonPopover from "../popovers/skillComparisonPopover/SkillComparisonPopover.container";
import "./QuickfindMultipleHardSkillComparison.scss";

class QuickfindMultipleHardSkillComparison extends Component {
    render() {
        const { clusters, comparedSkills, isShowAllSkills } = this.props;

        return (
            <div className="hard-skills">
                {_.map(clusters, (cluster) => {
                    return (
                        <div className="hard-skills-row" key={cluster.categoryId}>
                            <div className="cluster-name">{cluster.categoryTitle}</div>
                            <div className="cluster job-cluster">
                                {_.map(cluster.children, (skill) => {
                                    return (
                                        <Skill
                                            key={skill.id}
                                            text={skill.title}
                                            title={skill.title}
                                            className={
                                                LEVEL_CLASS_NAME[skill.level] +
                                                "-secondary " +
                                                REQUIREMENT_COEFFICIENT_CLASS_NAME[
                                                    skill.requirementCoefficient
                                                ]
                                            }
                                            isTextOverflowEllipsis
                                        />
                                    );
                                })}
                            </div>
                            {_.map(comparedSkills, (candidateClusters, candidateIndex) => {
                                let candidateCluster = _.find(
                                    candidateClusters,
                                    (elem) => elem.categoryId === cluster.categoryId
                                );
                                let children = candidateCluster.children;
                                if (!isShowAllSkills) {
                                    children = _.filter(children, (skill) => !skill.isSecondary);
                                } else {
                                    children = _.orderBy(children, ["isSecondary"], ["desc"]);
                                }
                                return (
                                    <div className="cluster candidate-cluster" key={candidateIndex}>
                                        {_.map(children, (skill) => {
                                            let props = {
                                                className: `${
                                                    !skill.isSecondary
                                                        ? COMPARISON_LEVEL_CLASS_NAME[
                                                              skill.comparisonLevel
                                                          ] +
                                                          " " +
                                                          REQUIREMENT_COEFFICIENT_CLASS_NAME[
                                                              skill.requiredData
                                                                  .requirementCoefficient
                                                          ] +
                                                          (skill.actualData
                                                              ? " actual-" +
                                                                LEVEL_CLASS_NAME[
                                                                    skill.actualData.level
                                                                ]
                                                              : "")
                                                        : LEVEL_CLASS_NAME[skill.actualData.level] +
                                                          "-secondary"
                                                } `,
                                                iconsPositions: {
                                                    topRight: "notes",
                                                },
                                                iconsIds: {
                                                    notes:
                                                        "skill-map-skill-" +
                                                        skill.id +
                                                        "-notes" +
                                                        candidateIndex,
                                                },
                                            };

                                            return (
                                                <React.Fragment key={skill.id}>
                                                    <Skill
                                                        key={skill.id}
                                                        id={
                                                            "skill-map-skill-" +
                                                            skill.id +
                                                            candidateIndex
                                                        }
                                                        text={skill.title}
                                                        isTextOverflowEllipsis
                                                        level={skill.level}
                                                        {...props}
                                                    />
                                                    <SkillComparisonPopover
                                                        target={
                                                            "skill-map-skill-" +
                                                            skill.id +
                                                            candidateIndex
                                                        }
                                                        data={skill}
                                                    />
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }
}

QuickfindMultipleHardSkillComparison.propTypes = {
    clusters: PropTypes.array.isRequired,
    comparedSkills: PropTypes.array.isRequired,
    isShowAllSkills: PropTypes.bool.isRequired,
};

export default QuickfindMultipleHardSkillComparison;
