import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Icon.scss";
import _ from "lodash";

import ArrowRight from "../../icons/ArrowRight";
import ArrowLeft from "../../icons/ArrowLeft";
import CheckBox from "../../icons/CheckBox";
import CheckBoxBlank from "../../icons/CheckBoxBlank";
import EmojiNegative from "../../icons/EmojiNegative";
import EmojiNeutral from "../../icons/EmojiNeutral";
import EmojiPositive from "../../icons/EmojiPositive";
import ExpandLess from "../../icons/ExpandLess";
import ExpandMore from "../../icons/ExpandMore";
import Folder from "../../icons/Folder";
import FolderAdd from "../../icons/FolderAdd";
import Info from "../../icons/Info";
import MinusOutline from "../../icons/MinusOutline";
import NotModerated from "../../icons/NotModerated";
import Notes from "../../icons/Notes";
import Person from "../../icons/Person";
import PlusOutline from "../../icons/PlusOutline";
import Search from "../../icons/Search";
import Visibility from "../../icons/Visibility";
import Compare from "../../icons/Compare";
import Calendar from "../../icons/Calendar";
import Gear from "../../icons/Gear";
import Brain from "../../icons/Brain";
import GearAndBrain from "../../icons/GearAndBrain";
import CalendarOutline from "../../icons/CalendarOutline";
import GearOutline from "../../icons/GearOutline";
import BrainOutline from "../../icons/BrainOutline";
import GearAndBrainOutline from "../../icons/GearAndBrainOutline";
import Star from "../../icons/Star";
import StarOutline from "../../icons/StarOutline";
import BestMark from "../../icons/BestMark";
import BestMarkOutline from "../../icons/BestMarkOutline";
import Microphone from "../../icons/Microphone";
import MicrophoneOutline from "../../icons/MicrophoneOutline";
import BrainChain from "../../icons/BrainChain";
import Delete from "../../icons/Delete";
import Record from "../../icons/Record";
import PersonHardSkills from "../../icons/PersonHardSkills";
import PersonHardSkillsOutline from "../../icons/PersonHardSkillsOutline";
import PersonSoftSkills from "../../icons/PersonSoftSkills";
import PersonSoftSkillsOutline from "../../icons/PersonSoftSkillsOutline";
import PersonsComparison from "../../icons/PersonsComparison";
import PersonsComparisonOutline from "../../icons/PersonsComparisonOutline";
import EmailsDelete from "../../icons/EmailsDelete";
import EmailDelete from "../../icons/EmailDelete";
import EmailExpire from "../../icons/EmailExpire";
import EmailDiscard from "../../icons/EmailDiscard";
import Radio from "../../icons/Radio";
import RadioBlank from "../../icons/RadioBlank";
import Movie from "../../icons/Movie";
import CV from "../../icons/CV";
import TextMessage from "../../icons/TextMessage";
import AudioMessage from "../../icons/AudioMessage";
import VideoMessage from "../../icons/VideoMessage";
import Email from "../../icons/Email";
import Phone from "../../icons/Phone";
import PersonInCircle from "../../icons/PersonInCircle";
import PersonArrowsIn from "../../icons/PersonArrowsIn";
import PersonArrowsOut from "../../icons/PersonArrowsOut";
import FFM from "../../icons/FFM";
import FFMOutline from "../../icons/FFMOutline";
import Test from "../../icons/Test";
import TestOutline from "../../icons/TestOutline";
import Cancel from "../../icons/Cancel";
import Sort from "../../icons/Sort";
import Edit from "../../icons/Edit";
import Undo from "../../icons/Undo";
import Add from "../../icons/Add";
import Clear from "../../icons/Clear";
import RightArrowOutlined from "../../icons/RightArrowOutlined";
import QuestionMark from "../../icons/QuestionMark";
import Job from "../../icons/Job";
import MyProfile from "../../icons/MyProfile";
import JobPassport from "../../icons/JobPassport";
import JobDiscovery from "../../icons/JobDiscovery";
import JobCompass from "../../icons/JobCompass";
import SkillsJourney from "../../icons/SkillsJourney";
import Picture from "../../icons/Picture";
import Letter from "../../icons/Letter";
import Youtube from "../../icons/Youtube";
import Photo from "../../icons/Photo";
import Lightning from "../../icons/Lightning";
import Hired from "../../icons/Hired";
import Interviewed from "../../icons/Interviewed";
import Contact from "../../icons/Contact";
import ImCompare from "../../icons/ImCompare";
import ImPerson from "../../icons/ImPerson";
import LockPerson from "../../icons/LockPerson";
import Feedback from "../../icons/Feedback";
import RedNotification from "../../icons/RedNotification";
import Heart from "../../icons/Heart";
import EmptyHeart from "../../icons/EmptyHeart";
import ModelManagement from "../../icons/ModelManagements";
import SandWatches from "../../icons/SandWatches";
import PeopleDiscovery from "../../icons/PeopleDiscovery";
import StaffAndDertments from "../../icons/StaffAndDepartments";
import RichMedia from "../../icons/RichMediaIcon";
import CompanyProfileIcon from "../../icons/CompanyProfileIcon";
import Financial from "../../icons/Financial";
import DepartmentsProfile from "../../icons/DepartmentsProfile";
import CorporateAccount from "../../icons/CorporateAccount";
import Document from "../../icons/Document";

const ICONS_MAP = {
    arrow_right: ArrowRight,
    arrow_left: ArrowLeft,
    checkbox: CheckBox,
    checkbox_blank: CheckBoxBlank,
    emoji_negative: EmojiNegative,
    emoji_neutral: EmojiNeutral,
    emoji_positive: EmojiPositive,
    expand_less: ExpandLess,
    expand_more: ExpandMore,
    folder: Folder,
    folder_add: FolderAdd,
    info: Info,
    minus_outline: MinusOutline,
    not_moderated: NotModerated,
    notes: Notes,
    person: Person,
    plus_outline: PlusOutline,
    search: Search,
    visibility: Visibility,
    compare: Compare,
    calendar: Calendar,
    gear: Gear,
    brain: Brain,
    gear_and_brain: GearAndBrain,
    calendar_outline: CalendarOutline,
    gear_outline: GearOutline,
    brain_outline: BrainOutline,
    gear_and_brain_outline: GearAndBrainOutline,
    star: Star,
    star_outline: StarOutline,
    best_mark: BestMark,
    best_mark_outline: BestMarkOutline,
    microphone: Microphone,
    microphone_outline: MicrophoneOutline,
    brainchain: BrainChain,
    delete: Delete,
    record: Record,
    person_hard_skills: PersonHardSkills,
    person_hard_skills_outline: PersonHardSkillsOutline,
    person_soft_skills: PersonSoftSkills,
    person_soft_skills_outline: PersonSoftSkillsOutline,
    persons_comparison: PersonsComparison,
    persons_comparison_outline: PersonsComparisonOutline,
    emails_delete: EmailsDelete,
    email_delete: EmailDelete,
    email_expire: EmailExpire,
    email_discard: EmailDiscard,
    radio: Radio,
    radio_blank: RadioBlank,
    movie: Movie,
    cv: CV,
    text_message: TextMessage,
    audio_message: AudioMessage,
    video_message: VideoMessage,
    email: Email,
    phone: Phone,
    person_in_circle: PersonInCircle,
    person_arrows_in: PersonArrowsIn,
    person_arrows_out: PersonArrowsOut,
    ffm: FFM,
    ffm_outline: FFMOutline,
    test: Test,
    test_outline: TestOutline,
    cancel: Cancel,
    sort: Sort,
    edit: Edit,
    undo: Undo,
    add: Add,
    clear: Clear,
    right_arrow_outlined: RightArrowOutlined,
    question_mark: QuestionMark,
    job: Job,
    my_profile: MyProfile,
    job_passport: JobPassport,
    job_discovery: JobDiscovery,
    skills_journey: SkillsJourney,
    job_compass: JobCompass,
    picture: Picture,
    c: Letter,
    youtube: Youtube,
    photo: Photo,
    lightning: Lightning,
    hired: Hired,
    interviewed: Interviewed,
    contact: Contact,
    im_compare: ImCompare,
    im_person: ImPerson,
    lock_person: LockPerson,
    feedback: Feedback,
    red_notification: RedNotification,
    heart: Heart,
    empty_heart: EmptyHeart,
    model_management: ModelManagement,
    sand_watches: SandWatches,
    people_discovery: PeopleDiscovery,
    staff_and_departments: StaffAndDertments,
    rich_media: RichMedia,
    company_profile: CompanyProfileIcon,
    financial: Financial,
    departments_profile: DepartmentsProfile,
    corporate_account: CorporateAccount,
    document: Document,
};

const COMPLEX_ICONS = [
    "emoji_negative",
    "emoji_neutral",
    "emoji_positive",
    "notes",
    "calendar",
    "gear",
    "brain",
    "gear_and_brain",
    "best_mark",
    "microphone",
    "brainchain",
    "person_hard_skills",
    "person_soft_skills",
    "persons_comparison",
    "emails_delete",
    "email_delete",
    "email_expire",
    "email_discard",
    "movie",
    "cv",
    "text_message",
    "audio_message",
    "video_message",
    "email",
    "phone",
    "person_in_circle",
    "person_arrows_in",
    "person_arrows_out",
    "ffm",
    "test",
];

class Icon extends Component {
    render() {
        const IconTag = ICONS_MAP[this.props.type];

        if (IconTag) {
            return (
                <div
                    className={this.getClassName()}
                    id={this.props.id}
                    title={this.props.title}
                    onClick={this.props.onClick}
                    hidden={this.props.hidden}
                >
                    <IconTag {...this.props} />
                </div>
            );
        } else {
            return null;
        }
    }

    getClassName() {
        let className = "icon-container";

        if (this.props.size) {
            className += " icon-" + this.props.size;
        }

        if (!COMPLEX_ICONS.includes(this.props.type)) {
            className += " icon-plain";
            className += " icon-" + (this.props.color || "secondary");
        }

        if (!_.isNil(this.props.onClick)) {
            className += " icon-clickable";
        }

        className += " " + (this.props.className || "");

        return className;
    }
}

Icon.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
    title: PropTypes.string,
    hidden: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Icon;
