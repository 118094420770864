import { connect } from 'react-redux';
import { getTranslation } from '../../../reducers/common/localization';
import {
	cleanSearchSkills,
	getSearchSkills,
	getSelectedCandidatesIds,
	getSelectedSkillId,
	getSelectedSkills,
	onAddSkill,
	onRemoveSkill,
	onSearchJobs,
	onSearchSkills,
	onSearchApplicants,
	setQuickfindFormDistanceField,
	setQuickfindFormDistanceUnitField,
	setQuickfindFormCityField,
	setQuickfindFormWorkRegimeField,
	setQuickfindFormExpectedWorkRegimeField,
	setQuickfindFormSalaryField,
	setQuickfindFormPreferredContractTypeField,
	setQuickfindFormCurrencyField,
	getQuickfindFormValues
} from '../../../reducers/quickfind/quickfind';
import QuickfindForm from './QuickfindForm';

const mapStateToProps = state => {
	return {
		translation: getTranslation(state),
		searchSkills: getSearchSkills(state),
		selectedCandidatesIds: getSelectedCandidatesIds(state),
		skills: getSelectedSkills(state),
		selectedSkillId: getSelectedSkillId(state),
		formValues: getQuickfindFormValues(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSearchSkills: (filter) => dispatch(onSearchSkills(filter)),
		onAddSkill: (skill) => dispatch(onAddSkill(skill)),
		onRemoveSkill: (id) => dispatch(onRemoveSkill(id)),
		onCleanSearchSkills: () => dispatch(cleanSearchSkills()),
		onSearchJobs: (params) => dispatch(onSearchJobs(params)),
		onSearchApplicants: (params) => dispatch(onSearchApplicants(params)),
		onChangeDistance: (value) => dispatch(setQuickfindFormDistanceField(value)),
		onChangeDistanceUnit: (value) => dispatch(setQuickfindFormDistanceUnitField(value)),
		onChangeCity: (value) => dispatch(setQuickfindFormCityField(value)),
		onChangeWorkRegime: (value) => dispatch(setQuickfindFormWorkRegimeField(value)),
		onChangeExpectedWorkRegime: (value) => dispatch(setQuickfindFormExpectedWorkRegimeField(value)),
		onChangeSalary: (value) => dispatch(setQuickfindFormSalaryField(value)),
		onChangeContractType: (value) => dispatch(setQuickfindFormPreferredContractTypeField(value)),
		onChangeCurrency: (value) => dispatch(setQuickfindFormCurrencyField(value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickfindForm);
