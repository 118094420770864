import React, { Component } from 'react';

class EmojiPositive extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 473.931 473.931">
                <circle fill="#7BB43B" cx="236.966" cy="236.966" r="236.966" />
                <g fill="#333333">
                    <circle cx="164.937" cy="155.227" r="37.216" />
                    <circle cx="305.664" cy="155.227" r="37.216" />
                    <path d="M383.164,237.123c-1.332,80.699-65.514,144.873-146.213,146.206
                        c-80.702,1.332-144.907-67.52-146.206-146.206c-0.198-12.052-18.907-12.071-18.709,0c1.5,90.921,73.993,163.414,164.914,164.914
                        c90.929,1.5,163.455-76.25,164.922-164.914C402.071,225.052,383.362,225.071,383.164,237.123L383.164,237.123z"/>
                </g>
            </svg>
        );
    }
}

export default EmojiPositive;
