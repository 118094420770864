import { connect } from 'react-redux';
import SystemNodeSource from './SystemNodeSource';
import { getTranslation } from '../../../../reducers/common/localization';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state)
    };
};

export default connect(mapStateToProps)(SystemNodeSource);
