import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "./JobRichMedia.scss";
import Dropzone from "react-dropzone-uploader";
import { TabContent, TabPane } from "reactstrap";
import { DROPZONE_TABS } from "../../employee/postJob/PostJob";
import Icon from "../icon/Icon";

const tabsConfig = [
    {
        name: "office",

        title: "shared.office_label",
        iconType: "picture",
    },
    {
        name: "boss",

        title: "shared.boss_label",
        iconType: "video_message",
    },
];

class JobRichMedia extends Component {
    handleChangeStatus = ({ meta: { status }, file }, key) => {
        const fieldValue = this.props[key];
        if (status === "done") {
            this.props.setParentStateValue(key, [...fieldValue, file]);
        }
    };

    handleSubmit = (files, allFiles) => {
        allFiles.forEach((f) => f.remove());
    };

    handleNavChange = (name) => {
        this.props.setParentStateValue("activeDropzoneTab", name);
    };

    render() {
        const { activeDropzoneTab, translation, isRequired } = this.props;

        const dropzoneCommonProps = {
            multiple: true,
            addClassNames: { dropzone: `dropzone-container ${isRequired ? "required" : ""}` },
            accept: "image/*, video/*",
        };
        return (
            <div className="job-rich-media-container">
                <div className="navigation-container">
                    {tabsConfig.map(({ name, iconType, title }) => (
                        <div
                            className={`navigation-item ${
                                name === activeDropzoneTab ? "active" : ""
                            }`}
                            onClick={() => this.handleNavChange(name)}
                            key={name}
                        >
                            <Icon type={iconType} />
                            <div>{_.get(translation, title)}</div>
                        </div>
                    ))}
                </div>
                <TabContent activeTab={activeDropzoneTab} style={{ width: "100%" }}>
                    <TabPane tabId={DROPZONE_TABS.OFFICE}>
                        <Dropzone
                            {...dropzoneCommonProps}
                            inputContent={
                                <div>
                                    {_.get(
                                        translation,
                                        "post_job.first_step.upload_office_rm_dropzone_label"
                                    )}
                                </div>
                            }
                            onChangeStatus={(file) => {
                                this.handleChangeStatus(file, "officeMedia");
                            }}
                            disabled={this.props.readOnly}
                        />
                    </TabPane>
                    <TabPane tabId={DROPZONE_TABS.BOSS}>
                        <Dropzone
                            {...dropzoneCommonProps}
                            inputContent={
                                <div>
                                    {_.get(
                                        translation,
                                        "post_job.first_step.upload_boss_rm_dropzone_label"
                                    )}
                                </div>
                            }
                            disabled={this.props.readOnly}
                            onChangeStatus={(file) => this.handleChangeStatus(file, "bossMedia")}
                        />
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}

JobRichMedia.propTypes = {
    translation: PropTypes.object.isRequired,
};

export default JobRichMedia;
