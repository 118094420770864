import { connect } from 'react-redux';
import TablePagination from "./TablePagination";
import {
    getRelatedProfilesCurrentPage,
    getRelatedProfilesTotalPages,
    getRelatedProfilesFetchingStatus,
    onFetchGetRelatedProfilesByPage
} from '../../../reducers/admin/relatedProfiles';

const mapStateToProps = state => {
    return {
        current: getRelatedProfilesCurrentPage(state),
        total: getRelatedProfilesTotalPages(state),
        loading: getRelatedProfilesFetchingStatus(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getDataForPage: (page) => {
            dispatch(onFetchGetRelatedProfilesByPage(page));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePagination);
