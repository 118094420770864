import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import _ from "lodash";
import { getApplicantSkillsData } from "../applicant/applicantSkills";

//- Actions
export const fetchGetSuggestedSkillsFailed = createAction("FETCH_GET_SUGGESTED_SKILLS_FAILED");
export const fetchGetSuggestedSkillsSuccess = createAction("FETCH_GET_SUGGESTED_SKILLS_SUCCESS");
export const onFetchGetSuggestedSkillsSuccess = (skills) => (dispatch) => {
    let formattedSkills = _.map(skills, (skill) => {
        return {
            id: skill.id,
            title: skill.titles.en,
            clusters: skill.clusters
                ? skill.clusters.map((cluster) => ({
                      clusterId: cluster.id,
                      clusterTitle: cluster.titles.en,
                  }))
                : [],
        };
    });
    dispatch(fetchGetSuggestedSkillsSuccess(formattedSkills));
};
export const fetchGetSuggestedSkills = createAction(
    "FETCH_GET_SUGGESTED_SKILLS",
    (skills) => Api.getSkillsBySkills(_.map(skills, (skill) => skill.id)),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSuggestedSkillsSuccess,
        onFail: fetchGetSuggestedSkillsFailed,
    })
);
export const onFetchGetSuggestedSkillsForApplicant = () => (dispatch, getState) => {
    let skills = getApplicantSkillsData(getState());
    dispatch(fetchGetSuggestedSkills(skills));
};

export const cleanSuggestedSkills = createAction("CLEAN_SUGGESTED_SKILLS");

//- State
const initialState = {
    data: null,
    error: null,
    fetching: false,
};

//- Reducers
export default handleActions(
    {
        FETCH_GET_SUGGESTED_SKILLS: (state) => {
            return { ...state, data: null, error: null, fetching: true };
        },
        FETCH_GET_SUGGESTED_SKILLS_FAILED: (state, action) => {
            return { ...state, data: null, error: action.payload, fetching: false };
        },
        FETCH_GET_SUGGESTED_SKILLS_SUCCESS: (state, action) => {
            return { ...state, data: action.payload, error: null, fetching: false };
        },
        CLEAN_SUGGESTED_SKILLS: (state) => {
            return { ...state, data: null };
        },
    },
    initialState
);

//- Selectors
export const getSuggestedSkillsState = (state) => state.suggestedSkills;

export const getSuggestedSkillsData = (state) => getSuggestedSkillsState(state).data;

export const getSuggestedSkillsFetchingStatus = (state) => getSuggestedSkillsState(state).fetching;
