
import React, { Component } from 'react';

class Compare extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M23.5,8.3c0,0,0-0.1,0-0.1c0,0,0,0,0,0l-3.2-5.8c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1
                    c-0.1-0.1-0.3-0.2-0.5-0.2L14.3,3c-0.5-1.1-1.6-1.7-2.7-1.5c-1.1,0.2-2,1.1-2.1,2.3L4.2,4.7C4.1,4.7,4,4.7,3.9,4.8
                    c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0l-3.2,5.8c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0v1.3
                    c0,1.8,1.4,3.2,3.2,3.2H5c1.8,0,3.2-1.4,3.2-3.2V11c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0l-2.8-5L9.7,5
                    c0.1,0.2,0.2,0.4,0.4,0.6v11.8c-1.8,0.3-3.2,1.9-3.2,3.8v0.6c0,0.4,0.3,0.6,0.6,0.6h8.9c0.4,0,0.6-0.3,0.6-0.6v-0.6
                    c0-1.9-1.4-3.5-3.2-3.8V5.7c0.4-0.4,0.6-0.9,0.6-1.4l3.9-0.7l-2.6,4.6c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0v1.3
                    c0,1.8,1.4,3.2,3.2,3.2h1.3c1.8,0,3.2-1.4,3.2-3.2V8.5c0,0,0,0,0,0C23.5,8.4,23.5,8.4,23.5,8.3z M21.8,7.8h-4.2l2.1-3.8L21.8,7.8z
                    M11.4,6.5c0.4,0.1,0.9,0.1,1.3,0v11h-1.3V6.5z M12,2.7c0.7,0,1.3,0.6,1.3,1.3S12.7,5.3,12,5.3S10.7,4.7,10.7,4S11.3,2.7,12,2.7z
                    M4.3,6.6l2.1,3.8H2.2L4.3,6.6z M6.9,12.3c0,1.1-0.9,1.9-1.9,1.9H3.7c-1.1,0-1.9-0.9-1.9-1.9v-0.6h5.1V12.3z M15.8,21.3H8.2
                    c0-1.4,1.1-2.6,2.6-2.6h2.6C14.7,18.7,15.8,19.9,15.8,21.3z M22.2,9.8c0,1.1-0.9,1.9-1.9,1.9H19c-1.1,0-1.9-0.9-1.9-1.9V9.1h5.1V9.8 z"
                />
            </svg>
        );
    }
}

export default Compare;
