import { connect } from 'react-redux';
import CreateNewJob from './CreateNewJob';
import { getTranslation } from '../../../../reducers/common/localization';
import { initEmptyProfiles, getJobProfilesData } from '../../../../reducers/job/jobProfiles';
import { initEmptySkills, getJobSkillsData } from '../../../../reducers/job/jobSkills';
import { showModal, MODAL_SKILLS_WITHOUT_LEVEL, MODAL_NO_PROFILES } from '../../../../reducers/common/activeModal';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        profiles: getJobProfilesData(state),
        skills: getJobSkillsData(state),
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onMount: () => {
            dispatch(initEmptyProfiles());
            dispatch(initEmptySkills());
        },
        onShowNoProfilesModal: () => dispatch(showModal(MODAL_NO_PROFILES)),
        onShowSkillsWithoutLevelModal: () => dispatch(showModal(MODAL_SKILLS_WITHOUT_LEVEL)),
        onPostNewJob: () => {
            // TODO: add functionality
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewJob);
