import { connect } from 'react-redux';
import Login from './Login';
import { getTranslation } from "../../../reducers/common/localization";
import {
    fetchAuthorize,
    getLoginFetchingStatus,
    getLoginError,
    cleanLoginError,
} from './../../../reducers/authorization/login';
import { setAccountToRestoreEmail } from '../../../reducers/authorization/restoreAccount';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        error: getLoginError(state),
        fetching: getLoginFetchingStatus(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLogIn: (data) => dispatch(fetchAuthorize(data)),
        onForgotPassword: (email) => dispatch(setAccountToRestoreEmail(email)),
        onUnmount: () => dispatch(cleanLoginError()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
