import { connect } from 'react-redux';
import AudioModal from './AudioModal';
import { getTranslation } from '../../../../../reducers/common/localization';
import { getIsAudioModalOpen, onCloseAudioModal } from '../../../../../reducers/quickfind/jobsComparison';

const mapStateToProps = state => ({
	translation: getTranslation(state),
	isOpen: getIsAudioModalOpen(state),
});

const mapDispatchToProps = dispatch => ({
	onCancel: () => dispatch(onCloseAudioModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AudioModal);
