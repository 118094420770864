import { connect } from "react-redux";
import PendingApplication from "./PendingApplication";
import { getTranslation } from "../../../../reducers/common/localization";
import { getUserJobsState } from "../../../../reducers/user/userData";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingApplication);
