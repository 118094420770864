import { connect } from 'react-redux';
import HardSkillsComparison from './HardSkillsComparison';
import { getTranslation } from '../../../../../reducers/common/localization';
import {
    getOpenJobAnonymityMode,
    onAddCandidateToFavorite,
    onRemoveCandidateFromFavorite,
    setCandidateForActiveModal,
    onAddCandidateToTopXGroup,
    getOpenJobPlannedTopCandidatesAmount,
} from '../../../../../reducers/job/openJob';
import {
    getCurrentCandidateId,
    getCurrentCandidateFetchingStatus,
    getCurrentCandidateData,
    getCandidateAndJobComparedSkillsTree
} from '../../../../../reducers/job/selectedCandidates';
import { showModal, MODAL_CANDIDATE_NOTES } from '../../../../../reducers/common/activeModal';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        userId: getCurrentCandidateId(state),
        data: getCurrentCandidateData(state),
        comparedSkills: getCandidateAndJobComparedSkillsTree(state),
        loading: getCurrentCandidateFetchingStatus(state),
        anonymityMode: getOpenJobAnonymityMode(state),
        plannedTopAmount: getOpenJobPlannedTopCandidatesAmount(state)
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onAddToFavorite: (userId) => dispatch(onAddCandidateToFavorite(userId)),
        onRemoveFromFavorite: (userId) => dispatch(onRemoveCandidateFromFavorite(userId)),
        onAddToTopXGroup: (userId) => dispatch(onAddCandidateToTopXGroup(userId)),
        onCandidateNotesClick: (userId) => {
            dispatch(setCandidateForActiveModal(userId));
            dispatch(showModal(MODAL_CANDIDATE_NOTES));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HardSkillsComparison);
