import { connect } from 'react-redux';
import DepartmentsList from './DepartmentsList';
import { getTranslation } from '../../../../reducers/common/localization';
import {
    getDepartmentsListData,
    getDepartmentsListFetchingStatus,
    getInitialDepartmentsList
} from '../../../../reducers/organization/departmentsList';
import {
    MODAL_ADD_DEPARTMENT,
    MODAL_REMOVE_DEPARTMENT,
    showModal
} from '../../../../reducers/common/activeModal';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        data: getDepartmentsListData(state),
        loading: getDepartmentsListFetchingStatus(state),

    };
};

const mapDispatchToProps = dispatch => {
    return {
        getInitialDepartmentsList: () => dispatch(getInitialDepartmentsList()),
        onAddOrEditDepartment: () => dispatch(showModal(MODAL_ADD_DEPARTMENT)),
        onRemoveDepartment: () => dispatch(showModal(MODAL_REMOVE_DEPARTMENT)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsList);
