import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SkillComparisonNotesPopover.scss';
import _ from "lodash";
import {
    UncontrolledPopover,
    PopoverBody,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import moment from 'moment';
import { LANGUAGE_LEVEL_TRANSLATION_LABEL, VERSION_RATIO_TRANSLATION_LABEL } from '../../../../constants/skillsConstants';

class SkillComparisonNotesPopover extends Component {
    render() {
        if (!this.props.data) {
            return null;
        }

        return (
            <UncontrolledPopover
                className="skill-comparison-notes-popover"
                target={this.props.target}
                placement={this.props.placement || "right"}
                trigger={this.props.trigger || "legacy"}
                boundariesElement="window"
            >
                <PopoverBody>
                    {this.renderRequiredNotes()}
                    {this.renderActualNotes()}
                </PopoverBody>
            </UncontrolledPopover>
        );
    }

    renderRequiredNotes() {
        if (this.props.data.requiredData) {
            let items = [];
            items.push(this.renderVersion(this.props.data.requiredData.version));
            items.push(this.renderSpokenLevel(this.props.data.requiredData.spokenLevel));
            items.push(this.renderUnderStandingLevel(this.props.data.requiredData.understandingLevel));
            items.push(this.renderNotes(this.props.data.requiredData.notes));
            items = _.filter(items, (item) => !_.isNil(item));

            if (items.length > 0) {
                return (
                    <div>
                        <div className="notes-group-title">
                            {_.get(this.props.translation, 'job.job_notes_label')}
                        </div>
                        <ListGroup className="notes-container">
                            {items}
                        </ListGroup>
                    </div>
                )
            }
        }
    }

    renderActualNotes() {
        if (this.props.data.actualData) {
            let items = [];
            items.push(this.renderVersion(this.props.data.actualData.version));
            items.push(this.renderSpokenLevel(this.props.data.actualData.spokenLevel));
            items.push(this.renderUnderStandingLevel(this.props.data.actualData.understandingLevel));
            items.push(this.renderNotes(this.props.data.actualData.notes));
            items = _.filter(items, (item) => !_.isNil(item));

            if (items.length > 0) {
                return (
                    <div>
                        <div className="notes-group-title">
                            {_.get(this.props.translation, 'job.candidate_notes_label')}
                        </div>
                        <ListGroup className="notes-container">
                            {items}
                        </ListGroup>
                    </div>
                )
            }
        }
    }

    renderVersion(version) {
        if (!_.isNil(version)) {
            return (
                <ListGroupItem key="version">
                    <div className="custom-property-title">
                        {_.get(this.props.translation, 'skill_properties.version_label')}
                    </div>
                    <div>
                        {_.get(this.props.translation, `skill_properties.version_ratio_values.${VERSION_RATIO_TRANSLATION_LABEL[version.ratio]}`)}
                        {" "}
                        {version.value}
                    </div>
                </ListGroupItem>
            )
        }
    }

    renderSpokenLevel(spokenLevel) {
        if (!_.isNil(spokenLevel)) {
            return (
                <ListGroupItem key="spoken-level">
                    <div className="custom-property-title">
                        {_.get(this.props.translation, 'skill_properties.spoken_level_label')}
                    </div>
                    <div>
                        {_.get(this.props.translation, `skill_properties.language_levels_values.${LANGUAGE_LEVEL_TRANSLATION_LABEL[spokenLevel]}`)}
                    </div>
                </ListGroupItem>
            )
        }
    }

    renderUnderStandingLevel(understandingLevel) {
        if (!_.isNil(understandingLevel)) {
            return (
                <ListGroupItem key="understanding-level">
                    <div className="custom-property-title">
                        {_.get(this.props.translation, 'skill_properties.understanding_level_label')}
                    </div>
                    <div>
                        {_.get(this.props.translation, `skill_properties.language_levels_values.${LANGUAGE_LEVEL_TRANSLATION_LABEL[understandingLevel]}`)}
                    </div>
                </ListGroupItem>
            )
        }
    }

    renderNotes(notes) {
        if (!_.isNil(notes)) {
            return _.map(notes, (note, id) => {
                return (
                    <ListGroupItem key={id}>
                        <div className="note-date">
                            {moment(note.date).format('LL')}
                        </div>
                        <div>
                            {note.text}
                        </div>
                    </ListGroupItem>
                )
            })
        }
    }
}

SkillComparisonNotesPopover.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.object,
    target: PropTypes.string.isRequired,
    placement: PropTypes.string,
    trigger: PropTypes.string
};

export default SkillComparisonNotesPopover;
