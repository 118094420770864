import React from "react";
import { Link } from "react-router-dom";
import Alert from "reactstrap/lib/Alert";
import Badge from "reactstrap/lib/Badge";
import { ROUTES } from "../../constants/constants";

export const JobStaticlink = ({ hash }) => (
    <div className="d-flex justify-content-between">
        <Alert color="dark" className="px-3">
            <span>
                To avoid you would have to set up this job again to publish it on other job boards
                as well,{" "}
            </span>{" "}
            <span className="mr-3">
                the below link can easily be used to publish this job outside the HireForceOne
                platform:
            </span>
            <Link
                to={`${ROUTES.JOB_PROFILE}/${hash}`}
                className="mr-3 job-profile__link-title"
                color="primary"
            >
                Link
            </Link>
            <Badge
                color="primary"
                className="job-profile__copy-link"
                onClick={() =>
                    navigator.clipboard.writeText(
                        `${window.location.host}${ROUTES.JOB_PROFILE}/${hash}`
                    )
                }
            >
                Copy Link
            </Badge>
        </Alert>
    </div>
);
