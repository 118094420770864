import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap';

class ResendStaffMemberActivationModal extends Component {
    render() {
        const { data, isOpen, onClose, translation } = this.props;

        if (!data) {
            return null;
        }

        return (
            <Modal
                isOpen={isOpen}
                toggle={onClose}
            >
                <ModalBody>
                    <h6>{_.get(translation, 'staff.resend_staff_member_activation_label').replace('{email}', data.email)}</h6>
                </ModalBody>
                <ModalFooter className="content-space-between">
                    <Button
                        color="secondary"
                        onClick={onClose}
                    >
                        {_.get(translation, 'actions.cancel_button')}
                    </Button>
                    <Button
                        color="primary"
                        onClick={this.onSubmit}
                    >
                        {_.get(translation, "staff.resend_activation_label")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }


    onSubmit = () => {
        const { onClose, onResendStaffMemberActivation } = this.props;

        onResendStaffMemberActivation({ email: this.props.data.email });
        onClose();
    }
}

ResendStaffMemberActivationModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    data: PropTypes.object,
    onResendStaffMemberActivation: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default ResendStaffMemberActivationModal;
