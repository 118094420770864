import React, { Component } from "react";
import PropTypes from "prop-types";
import "./SkillsMap.scss";
import _ from "lodash";
import Icon from "../../icon/Icon";
import Skill from "../skill/Skill.container";
import {
    LEVEL_CLASS_NAME,
    STATUS_CLASS_NAME,
    REQUIREMENT_COEFFICIENT_CLASS_NAME,
    COMPARISON_LEVEL_CLASS_NAME,
    SKILL_PROPERTY,
} from "../../../../constants/skillsConstants";
import UserPhoto from "../../userPhoto/UserPhoto.container";
import SkillNotesPopover from "../../popovers/skillNotesPopover/SkillNotesPopover.container";
import { USER_PHOTO_MODE } from "../../userPhoto/UserPhoto";
import SkillComparisonPopover from "../../popovers/skillComparisonPopover/SkillComparisonPopover.container";
import SkillComparisonNotesPopover from "../../popovers/skillComparisonNotesPopover/SkillComparisonNotesPopover.container";

export const SKILLS_MAP_MODE = {
    APPLICANT_VIEW: "APPLICANT_VIEW",
    APPLICANT_EDIT: "APPLICANT_EDIT",
    JOB_VIEW: "JOB_VIEW",
    JOB_EDIT: "JOB_EDIT",
    COMPARISON: "COMPARISON",
    SKILLS_VIEW: "SKILLS_VIEW",
    POST_JOB_REQUIRED_SKILLS: "POST_JOB_REQUIRED_SKILLS",
};

const START_Y_LEVEL = 60;
const START_X_LEVEL = 125;
const START_X_LEVEL_SECONDARY = -25;

const X_LEVEL_CONNECTOR = START_X_LEVEL - 10;
const X_LEVEL_CONNECTOR_SECONDARY = START_X_LEVEL_SECONDARY - 10;

const MAX_SKILL_WIDTH = 400;

const SKILL_TEXT_HEIGHT = 24;
const SKILL_PADDING_TOP = 2;
const SKILL_PADDING_RIGHT = 12;
const SKILL_BORDER = 2;
const SKILL_MARGIN = 5;
const SKILL_HEIGHT = SKILL_TEXT_HEIGHT + (SKILL_PADDING_TOP + SKILL_BORDER + SKILL_MARGIN) * 2;

class SkillsMap extends Component {
    constructor(props) {
        super(props);

        this.y_level = START_Y_LEVEL;
        this.x_level = START_X_LEVEL;
        this.maxTextWidth = MAX_SKILL_WIDTH - 58;
    }

    render() {
        if (_.isNil(this.props.skillsTree) || this.props.skillsTree.length === 0) {
            return null;
        }

        this.y_level = START_Y_LEVEL;
        this.x_level = START_X_LEVEL;
        this.maxTextWidth = MAX_SKILL_WIDTH - 58;

        return (
            <div
                className={`skills-map ${this.props.showSecondarySkills ? "with-secondary" : ""} ${
                    this.props.centered ? "сentered" : ""
                }`}
            >
                {this.renderSkillsMap()}
            </div>
        );
    }

    renderSkillsMap() {
        let tags = [];
        let points = [];

        let requiredClusters = _.filter(this.props.skillsTree, (cluster) => !cluster.isSecondary);

        requiredClusters.forEach((cluster) => {
            let clusterData = this.getClusterData(cluster);
            tags.push(clusterData.tags);
            points.push(clusterData.point);

            this.x_level = START_X_LEVEL;
        });

        let max_y_level = this.y_level;
        if (this.props.showSecondarySkills) {
            this.y_level = START_Y_LEVEL;
            this.x_level = START_X_LEVEL;
            this.maxTextWidth = MAX_SKILL_WIDTH - 58;
            let secondaryClusters = _.filter(
                this.props.skillsTree,
                (cluster) => cluster.isSecondary
            );
            secondaryClusters.forEach((cluster) => {
                let clusterData = this.getClusterData(cluster, true);
                tags.push(clusterData.tags);
                points.push(clusterData.point);

                this.x_level = START_X_LEVEL;
            });
            if (this.y_level > max_y_level) {
                max_y_level = this.y_level;
            }
            this.y_level = max_y_level;
        }

        let middlePoint = this.getMiddlePoint();
        tags.push(this.getConnectors(middlePoint, points));
        tags.push(this.getPhoto(middlePoint));

        return (
            <svg width="100%" height="100%" viewBox={this.getViewBox()}>
                {" "}
                {tags}{" "}
            </svg>
        );
    }

    getClusterData(data, isSecondary) {
        let result = {
            tags: [],
            point: {},
        };
        let childrenTags = [];
        let y_start = this.y_level;
        let max_y_level = this.y_level;
        let max_x_level = this.x_level;
        let children = data.children.filter((child) => !!child);
        if (children.length === 0) {
            max_x_level += 50;
        } else {
            if (this.props.mode === SKILLS_MAP_MODE.COMPARISON) {
                children = _.orderBy(
                    children,
                    ["requiredData.requirementCoefficient", "title"],
                    ["desc", "asc"]
                );
            }
            if (!isSecondary) {
                children = _.filter(children, (skill) => !skill?.isSecondary);
                if (this.props.showSecondarySkills) {
                    this.maxTextWidth = MAX_SKILL_WIDTH / 2 - 58;
                }
            }
            children.forEach((child) => {
                if (
                    this.x_level + child.textWidth > START_X_LEVEL + this.maxTextWidth &&
                    this.x_level !== START_X_LEVEL
                ) {
                    this.x_level = START_X_LEVEL;
                    this.y_level += SKILL_HEIGHT;
                }

                let categoryId = child?.categoryId?.filter((id) => id === data.categoryId);
                let childData = this.getSkillData(child, categoryId);
                childrenTags.push(childData.tags);

                if (this.x_level > max_x_level) {
                    max_x_level = this.x_level;
                }
            });
            max_y_level = this.y_level;
            if (!isSecondary && this.props.showSecondarySkills) {
                children = _.filter(data.children, (skill) => skill.isSecondary);
                this.y_level = y_start;
                this.x_level = START_X_LEVEL + MAX_SKILL_WIDTH / 2;
                children.forEach((child) => {
                    if (
                        this.x_level + child.textWidth > START_X_LEVEL + this.maxTextWidth &&
                        this.x_level !== START_X_LEVEL + MAX_SKILL_WIDTH / 2
                    ) {
                        this.x_level = START_X_LEVEL + MAX_SKILL_WIDTH / 2;
                        this.y_level += SKILL_HEIGHT;
                    }
                    let childData = this.getSkillData(child);
                    childrenTags.push(childData.tags);
                });
                if (this.y_level > max_y_level) {
                    max_y_level = this.y_level;
                }
                this.y_level = max_y_level;
                max_x_level = START_X_LEVEL + MAX_SKILL_WIDTH - 14;
            }
        }

        this.y_level += START_Y_LEVEL;
        let textWidth =
            data.textWidth > MAX_SKILL_WIDTH - 15 ? MAX_SKILL_WIDTH - 15 : data.textWidth;
        let clusterWidth = max_x_level - START_X_LEVEL + 14;

        result.tags.push(
            <g
                key={data.categoryId}
                style={
                    isSecondary
                        ? {
                              transform: `translate(-${
                                  clusterWidth + (X_LEVEL_CONNECTOR - X_LEVEL_CONNECTOR_SECONDARY)
                              }px)`,
                          }
                        : {}
                }
            >
                <rect
                    className={`cluster ${
                        !this.props.currentSkillId &&
                        this.props.currentClusterId === data.categoryId
                            ? "cluster-selected"
                            : ""
                    } ${data.isSuggestion ? "cluster-suggested" : ""}`}
                    x={START_X_LEVEL - 10}
                    y={y_start - START_Y_LEVEL / 2}
                    width={clusterWidth}
                    height={this.y_level - y_start}
                    strokeWidth={2}
                    rx={15}
                    ry={15}
                />
                <foreignObject
                    className="cluster-text-container"
                    x={START_X_LEVEL}
                    y={y_start - START_Y_LEVEL / 2 - 32}
                    width={textWidth}
                    height={SKILL_TEXT_HEIGHT}
                    style={
                        isSecondary
                            ? { transform: `translate(${clusterWidth - textWidth - 20}px)` }
                            : {}
                    }
                >
                    <div className="text-container" style={{ maxWidth: textWidth }}>
                        <span title={data.categoryTitle}>{data.categoryTitle}</span>
                    </div>
                </foreignObject>
                {!isSecondary && this.props.showSecondarySkills ? (
                    <line
                        className="secondary-skills-divider"
                        x1={START_X_LEVEL - 10 + (max_x_level - START_X_LEVEL + 14) / 2}
                        y1={y_start - START_Y_LEVEL / 2}
                        x2={START_X_LEVEL - 10 + (max_x_level - START_X_LEVEL + 14) / 2}
                        y2={this.y_level - START_Y_LEVEL / 2}
                        strokeWidth={2}
                        strokeDasharray="4 2"
                    />
                ) : null}
                {this.props.mode === SKILLS_MAP_MODE.APPLICANT_EDIT ||
                this.props.mode === SKILLS_MAP_MODE.JOB_EDIT ? (
                    <g>
                        <foreignObject
                            x={max_x_level - 7}
                            y={y_start - START_Y_LEVEL / 2 - 4}
                            width={16}
                            height={16}
                        >
                            <Icon
                                type="plus_outline"
                                color="success"
                                size="xs"
                                title={_.get(
                                    this.props.translation,
                                    "skills_management.select_cluster_label"
                                )}
                                onClick={this.props.onSelectCluster(data.categoryId, {
                                    isSuggestion: data.isSuggestion,
                                    isNotModerated: data.isNotModerated,
                                })}
                            />
                        </foreignObject>
                        {!data.isSuggestion ? (
                            <foreignObject
                                x={max_x_level - 7}
                                y={this.y_level - START_Y_LEVEL / 2 - 11}
                                width={16}
                                height={16}
                            >
                                <Icon
                                    type="minus_outline"
                                    color="danger"
                                    size="xs"
                                    title={_.get(
                                        this.props.translation,
                                        "skills_management.remove_cluster_label"
                                    )}
                                    onClick={this.props.onRemoveCluster(
                                        data.categoryId,
                                        data.categoryTitle
                                    )}
                                />
                            </foreignObject>
                        ) : null}
                    </g>
                ) : null}
                {data.isNotModerated ? (
                    <foreignObject
                        x={START_X_LEVEL - 14}
                        y={this.y_level - START_Y_LEVEL / 2 - 11}
                        width={16}
                        height={16}
                    >
                        <Icon
                            key="not_moderated"
                            type="not_moderated"
                            size="xs"
                            title={_.get(
                                this.props.translation,
                                "system_nodes.not_moderated_cluster_label"
                            )}
                        />
                    </foreignObject>
                ) : null}
                {childrenTags}
            </g>
        );

        if (data.textWidth + START_X_LEVEL + 10 > this.max_x_level) {
            this.max_x_level = data.textWidth + START_X_LEVEL + 10;
        }

        result.point = {
            x: isSecondary ? X_LEVEL_CONNECTOR_SECONDARY : X_LEVEL_CONNECTOR,
            y: y_start - START_Y_LEVEL / 2 + (this.y_level - y_start) / 2,
        };

        result.tags = [result.tags];

        this.y_level += 50;

        return result;
    }

    getSkillData(skill, clusterId) {
        let result = {
            tags: [],
        };
        let textWidth = skill.textWidth > this.maxTextWidth ? this.maxTextWidth : skill.textWidth;
        let notesNumber = this.getSkillNotesNumber(skill);

        result.tags.push(
            <foreignObject
                x={this.x_level}
                y={this.y_level - SKILL_HEIGHT / 2}
                width={textWidth + (SKILL_PADDING_RIGHT + SKILL_BORDER + SKILL_MARGIN) * 2}
                height={SKILL_HEIGHT}
                key={skill.id}
            >
                <Skill
                    id={"skill-map-skill-" + skill.id}
                    text={skill.title}
                    level={skill.level}
                    eagerness={skill.eagerness}
                    notesNumber={notesNumber}
                    isNotModerated={skill.isNotModerated}
                    isSelected={skill.id === this.props.currentSkillId}
                    {...this.getSkillProps(skill)}
                    isTextOverflowEllipsis
                    onClick={
                        this.props.onSelectSkill
                            ? this.props.onSelectSkill(skill.id, clusterId)
                            : null
                    }
                    onRemoveClick={
                        this.props.onSelectSkill ? this.props.onRemoveSkill(skill.id) : null
                    }
                    onNotesClick={
                        this.props.onSelectSkill
                            ? this.props.onNotesClick(skill.id, skill.categoryId)
                            : null
                    }
                />
                {this.getSkillPopover(skill, notesNumber)}
            </foreignObject>
        );

        this.x_level += textWidth + 46;

        return result;
    }

    getSkillNotesNumber(skill) {
        if (this.props.mode === SKILLS_MAP_MODE.COMPARISON) {
            let requiredNotes =
                skill.requiredData && skill.requiredData.notes ? skill.requiredData.notes : [];
            let actualNotes =
                skill.actualData && skill.actualData.notes ? skill.actualData.notes : [];
            let notes = [...requiredNotes, ...actualNotes];
            let amount = notes.length;
            const PROPS_TO_NOTES = [
                SKILL_PROPERTY.VERSION,
                SKILL_PROPERTY.SPOKEN_LEVEL,
                SKILL_PROPERTY.UNDERSTANDING_LEVEL,
            ];
            if (skill.requiredData) {
                _.forEach(PROPS_TO_NOTES, (property) => {
                    if (!_.isNil(skill.requiredData[property])) {
                        amount++;
                    }
                });
            }
            if (skill.actualData) {
                _.forEach(PROPS_TO_NOTES, (property) => {
                    if (!_.isNil(skill.actualData[property])) {
                        amount++;
                    }
                });
            }
            return amount > 0 ? amount : null;
        } else {
            return skill.notes ? skill.notes.length : null;
        }
    }

    getSkillPopover(skill, notesNumber) {
        switch (this.props.mode) {
            case SKILLS_MAP_MODE.APPLICANT_VIEW:
                return (
                    <SkillNotesPopover
                        target={"skill-map-skill-" + skill.id + "-notes"}
                        data={skill.notes}
                    />
                );

            case SKILLS_MAP_MODE.JOB_VIEW:
                return (
                    <SkillNotesPopover
                        target={"skill-map-skill-" + skill.id + "-notes"}
                        data={skill.notes}
                    />
                );

            case SKILLS_MAP_MODE.COMPARISON:
                return (
                    <>
                        <SkillComparisonPopover
                            target={"skill-map-skill-" + skill.id}
                            data={skill}
                        />
                        {!_.isNil(notesNumber) && notesNumber > 0 ? (
                            <SkillComparisonNotesPopover
                                target={"skill-map-skill-" + skill.id + "-notes"}
                                data={skill}
                            />
                        ) : null}
                    </>
                );

            default:
                return null;
        }
    }

    getSkillProps(skill) {
        switch (this.props.mode) {
            case SKILLS_MAP_MODE.APPLICANT_VIEW:
                return {
                    className: LEVEL_CLASS_NAME[skill.level] || LEVEL_CLASS_NAME.NO_LEVEL,
                    title: skill.title,
                    iconsTitle: {
                        notes: _.get(this.props.translation, "skills_management.show_notes_label"),
                    },
                    iconsPositions: {
                        topLeft: "notes",
                        topRight: "eagerness",
                        bottomLeft: "not_moderated",
                    },
                    iconsIds: {
                        notes: "skill-map-skill-" + skill.id + "-notes",
                    },
                };

            case SKILLS_MAP_MODE.SKILLS_VIEW:
                return {
                    className: STATUS_CLASS_NAME["2"] || LEVEL_CLASS_NAME.NO_LEVEL,
                    title: skill.title,
                    iconsTitle: {
                        notes: _.get(this.props.translation, "skills_management.show_notes_label"),
                    },
                    iconsPositions: {
                        topLeft: "notes",
                        topRight: "eagerness",
                        bottomLeft: "not_moderated",
                    },
                    iconsIds: {
                        notes: "skill-map-skill-" + skill.id + "-notes",
                    },
                    eagerness: null,
                };

            case SKILLS_MAP_MODE.APPLICANT_EDIT:
                return {
                    className: LEVEL_CLASS_NAME[skill.level] || LEVEL_CLASS_NAME.NO_LEVEL,
                    title: _.replace(
                        _.get(this.props.translation, "skills_management.select_skill_label"),
                        "{skillName}",
                        skill.title
                    ),
                    iconsTitle: {
                        notes: _.get(this.props.translation, "skills_management.show_notes_label"),
                        remove: _.get(
                            this.props.translation,
                            "skills_management.remove_skill_label"
                        ),
                    },
                    iconsPositions: {
                        topLeft: "notes",
                        bottomRight: "remove",
                        topRight: "eagerness",
                        bottomLeft: "not_moderated",
                    },
                };

            case SKILLS_MAP_MODE.JOB_EDIT:
                return {
                    className: `${LEVEL_CLASS_NAME[skill.level] || LEVEL_CLASS_NAME.NO_LEVEL} ${
                        REQUIREMENT_COEFFICIENT_CLASS_NAME[skill.requirementCoefficient]
                    }`,
                    title: _.replace(
                        _.get(this.props.translation, "skills_management.select_skill_label"),
                        "{skillName}",
                        skill.title
                    ),
                    iconsTitle: {
                        notes: _.get(this.props.translation, "skills_management.show_notes_label"),
                        remove: _.get(
                            this.props.translation,
                            "skills_management.remove_skill_label"
                        ),
                    },
                    iconsPositions: {
                        topLeft: "notes",
                        bottomRight: "remove",
                        bottomLeft: "not_moderated",
                    },
                };

            case SKILLS_MAP_MODE.JOB_VIEW:
                return {
                    className: `${LEVEL_CLASS_NAME[skill.level] || LEVEL_CLASS_NAME.NO_LEVEL} ${
                        REQUIREMENT_COEFFICIENT_CLASS_NAME[skill.requirementCoefficient]
                    }`,
                    title: skill.title,
                    iconsTitle: {
                        notes: _.get(this.props.translation, "skills_management.show_notes_label"),
                    },
                    iconsPositions: {
                        topLeft: "notes",
                        bottomLeft: "not_moderated",
                    },
                    iconsIds: {
                        notes: "skill-map-skill-" + skill.id + "-notes",
                    },
                };

            case SKILLS_MAP_MODE.COMPARISON:
                return {
                    className: `${
                        !skill.isSecondary
                            ? COMPARISON_LEVEL_CLASS_NAME[skill.comparisonLevel] +
                              " " +
                              REQUIREMENT_COEFFICIENT_CLASS_NAME[
                                  skill.requiredData.requirementCoefficient
                              ] +
                              (skill.actualData
                                  ? " actual-" + LEVEL_CLASS_NAME[skill.actualData.level]
                                  : "")
                            : LEVEL_CLASS_NAME[skill.actualData.level] + "-secondary"
                    } `,
                    iconsTitle: {
                        notes: _.get(this.props.translation, "skills_management.show_notes_label"),
                    },
                    iconsPositions: {
                        topRight: "notes",
                    },
                    iconsIds: {
                        notes: "skill-map-skill-" + skill.id + "-notes",
                    },
                };

            default:
                return {};
        }
    }

    getMiddlePoint() {
        let point = { x: 50, y: 50 };
        if (this.props.skillsTree.length >= 1) {
            point.y = START_Y_LEVEL + (this.y_level - START_Y_LEVEL) / 2 - 50;
        }
        return point;
    }

    getViewBox() {
        const MIN_HEIGHT = 130;
        let x_min = 0;
        let y_min = -20;
        let width = 530;
        let height = this.y_level > MIN_HEIGHT ? this.y_level - 30 : MIN_HEIGHT;

        if (this.props.showSecondarySkills) {
            x_min = -450;
            width = 980;
        }

        return `${x_min} ${y_min} ${width} ${height}`;
    }

    getConnectors(fromPoint, toPointsArray) {
        let connectors = [];

        toPointsArray.forEach((point) => {
            connectors.push(
                <path
                    key={`${point.x}_${point.y}`}
                    className="connector"
                    d={`M ${point.x},${point.y} Q ${fromPoint.x},${point.y} ${fromPoint.x},${fromPoint.y}`}
                    fill="none"
                    strokeWidth="3"
                />
            );
        });

        return connectors;
    }

    getPhoto(point) {
        const PHOTO_SIZE = 90;
        return (
            <g key="user_photo">
                <foreignObject
                    height={PHOTO_SIZE}
                    width={PHOTO_SIZE}
                    x={point.x - PHOTO_SIZE / 2}
                    y={point.y - PHOTO_SIZE / 2}
                >
                    <UserPhoto
                        mode={
                            this.props.mode === SKILLS_MAP_MODE.COMPARISON
                                ? USER_PHOTO_MODE.COMPARISON
                                : USER_PHOTO_MODE.VIEW
                        }
                        userId={this.props.userId}
                        imageSource={this.props.imageSource}
                        isDraggable={this.props.mode === SKILLS_MAP_MODE.COMPARISON}
                        size="md"
                    />
                </foreignObject>
            </g>
        );
    }
}

SkillsMap.defaultProps = {
    showSecondarySkills: false,
};

SkillsMap.propTypes = {
    translation: PropTypes.object.isRequired,
    skillsTree: PropTypes.array,
    mode: PropTypes.string,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageSource: PropTypes.string,
    currentSkillId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currentClusterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showSecondarySkills: PropTypes.bool,
    onSelectSkill: PropTypes.func,
    onRemoveSkill: PropTypes.func,
    onSelectCluster: PropTypes.func,
    onRemoveCluster: PropTypes.func,
    onNotesClick: PropTypes.func,
};

export default SkillsMap;
