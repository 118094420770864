import React, { Component } from 'react';

class EmojiNegative extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 473.935 473.935">
                <circle fill="#f9a700" cx="236.967" cy="236.967" r="236.967" />
                <g fill="#333333">
                    <path d="M356.671,354.1c-66.226-67.618-174.255-67.337-240.096,0.703
                        c-8.389,8.666,4.827,21.912,13.227,13.227c58.87-60.83,154.386-61.204,213.641-0.703C351.896,375.96,365.116,362.721,356.671,354.1
                        L356.671,354.1z"/>
                    <circle cx="164.938" cy="155.232" r="37.216" />
                    <circle cx="305.667" cy="155.232" r="37.216" />
                </g>
            </svg>
        );
    }
}

export default EmojiNegative;
