import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import _ from 'lodash';
import HardSkillsComparison from '../../common/quickfindHardSkillsComparison/HardSkillsComparison.container';

class JobQuickComparison extends Component {

	componentDidUpdate(prevProps, prevState) {
		const { onFetchJobById, selectedJobId } = this.props;
		if (prevProps.selectedJobId !== selectedJobId && selectedJobId) {
			onFetchJobById(selectedJobId);
		}
	}

	render() {
		const { translation, isOpen, onClose, loading, jobData } = this.props;
		return (
			<Modal
				centered
				isOpen={isOpen}
				toggle={onClose}
			>
				<ModalBody>
					{
						loading ? (
							<h6 className="page-message">{_.get(translation, "actions.fetching_message")}</h6>
						) : (
							<HardSkillsComparison data={jobData} />
						)
					}
				</ModalBody>
				<ModalFooter>
					<Button
						color="primary"
						onClick={onClose}
					>
						{_.get(translation, "actions.cancel_button")}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

JobQuickComparison.propTypes = {
	translation: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	selectedJobId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onClose: PropTypes.func.isRequired,
	onFetchJobById: PropTypes.func.isRequired,
	jobData: PropTypes.object.isRequired,
};

export default JobQuickComparison;
