import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "./PostJobFirstStep.scss";
import {
    currenciesOptions,
    PREFERRED_CONTRACT_TYPE,
    USER_SUB_ROLE,
} from "../../../../../constants/constants";
import FilteredList from "../../../../common/FilteredList/FilteredList.container";
import { Button } from "reactstrap";
import JobRichMedia from "../../../../common/jobRichMedia/JobRichMedia.container";
import { DROPZONE_TABS } from "../../PostJob";
import moment from "moment";
import { StorageManager } from "../../../../../utils/storageManager";

class PostJobFirstStep extends Component {
    componentDidMount() {
        this.props.onMount();
    }

    postJob = (postJobData, taskData) => {
        const { validate } = this.props;
        const data = { ...postJobData };
        if (taskData) {
            data.job = { ...data.job, task: { ...taskData } };
        }

        validate((errors) => {
            if (_.isEmpty(errors)) {
                const formData = new FormData();
                for (const prop in data) {
                    if (data[prop] instanceof Array) {
                        data[prop].forEach((item) => {
                            formData.append(prop, item);
                        });
                    } else {
                        const json = JSON.stringify(data[prop]);
                        formData.append(
                            prop,
                            new Blob([json], {
                                type: "application/json",
                            })
                        );
                    }
                }
                !this.props.isEditMode
                    ? this.props.onPostJob(formData)
                    : this.props.onEditJob(this.props.hash, formData);
            }
        }, !taskData && ["timer", "question"]);
    };

    filterEmployeesBySubRole = (employees) => {
        const { userData } = this.props;

        const subRoleForInvite =
            StorageManager.getLastUsedSubRole() === USER_SUB_ROLE.HM
                ? USER_SUB_ROLE.BO
                : USER_SUB_ROLE.HM;
        return employees.filter(
            (employee) => employee.roles.includes(subRoleForInvite)
            // && employee.id !== userData.id
        );
    };

    getCountryOptions = () => {
        const { translation, locationData, region } = this.props;
        const countryOptions = _.map(_.get(locationData, region), (countryCode) => {
            return {
                value: countryCode,
                label: _.get(translation, `countries.${countryCode}`),
            };
        });
        return _.sortBy(countryOptions, ["label"]);
    };

    render() {
        const {
            translation,
            selectedDept,
            departmentsList,
            homeWorkingAllowed,
            setParentStateValue,
            locationData,
            isTravelRequired,
            topX,
            topY,
            activeDropzoneTab,
            errors,
            officeMedia,
            bossMedia,
            applicationDeadline,
            expectedStart,
            staffList,
            travelImpact,
            homeWorkingImpact,
            maxSalary,
            contractType,
            assistHRM,
            assistBO,
            countryCode,
            city,
            district,
            salaryCurrency,
            workRegime,
            internalReference,
            skills,
            profiles,
            jobDescription,
            jobTitle,
            deepScreeningAmount,
        } = this.props;
        const currentSubRole = StorageManager.getLastUsedSubRole();
        const currentSubRoleLabel = currentSubRole === USER_SUB_ROLE.HM ? "assistBO" : "assistHRM";
        const preferredContractTypeOptions = [
            {
                value: PREFERRED_CONTRACT_TYPE.FREELANCE,
                label: _.get(translation, "shared.freelance"),
            },
            {
                value: PREFERRED_CONTRACT_TYPE.EMPLOYEE,
                label: _.get(translation, "shared.employee"),
            },
            {
                value: PREFERRED_CONTRACT_TYPE.NO_PREFERENCE,
                label: _.get(translation, "shared.no_preference"),
            },
        ];

        const teamSportFields = [
            {
                fieldName: "selectedDept",
                title: _.get(translation, `post_job.first_step.select_depts`),
                type: "select",
                options: departmentsList.map((department) => ({
                    label: department.name,
                    title: department.name,
                    value: department,
                })),
                hide: !departmentsList.length,
            },
        ];

        const jobDescriptionFields = [
            {
                fieldName: "jobTitle",
                title: _.get(translation, `job.job_title_label`),
                type: "text",
                isRequired: true,
                className: "job-description-field-container",
            },
            {
                fieldName: "internalReference",
                title: _.get(translation, `post_job.first_step.internal_reference_label`),
                type: "text",
                placeholder: _.get(
                    translation,
                    `post_job.first_step.internal_reference_placeholder_label`
                ),
                className: "job-description-field-container",
            },
        ];
        const locationFields = [
            {
                fieldName: "region",
                title: _.get(translation, `shared.continent_label`),
                type: "select",
                isRequired: true,
                options: Object.keys(locationData || {}).map((region) => ({
                    label: _.get(translation, `continents.${region}`),
                    title: _.get(translation, `continents.${region}`),
                    value: region,
                })),
                className: "location-field-container",
            },
            {
                fieldName: "countryCode",
                title: _.get(translation, `shared.country_label`),
                type: "select",
                isRequired: true,
                options: this.getCountryOptions().map(({ value, label }) => ({
                    label: label,
                    title: label,
                    value,
                })),
                className: "location-field-container",
            },
            {
                fieldName: "city",
                title: _.get(translation, `shared.city_label`),
                type: "text",
                isRequired: true,
                className: "location-field-container",
            },
            {
                fieldName: "district",
                title: _.get(translation, `shared.district_label`),
                type: "text",
                className: "location-field-container",
            },
        ];

        const workRegimeFields = [
            {
                fieldName: "isTravelRequired",
                title: _.get(translation, `post_job.first_step.is_travel_required_label`),
                type: "checkbox",
                className: "checkbox-container",
            },
            {
                fieldName: "travelImpact",
                title: _.get(translation, `post_job.first_step.travel_impact_label`),
                type: "range",
                hide: !isTravelRequired,
                className: "range-container",
            },
            {
                fieldName: "homeWorkingAllowed",
                title: _.get(translation, `post_job.first_step.home_working_label`),
                type: "checkbox",
                className: "checkbox-container",
            },
            {
                fieldName: "homeWorkingImpact",
                title: _.get(translation, "post_job.first_step.working_home_impact_label"),
                type: "range",
                hide: !homeWorkingAllowed,
                className: "range-container",
            },
            {
                fieldName: "workRegime",
                title: _.get(translation, "job.work_regime_label"),
                type: "range",
                isRequired: true,
                className: "work-regime-container",
            },
        ];

        const numberFields = {
            salary: [
                {
                    fieldName: "maxSalary",
                    title: "",
                    type: "number",
                    className: "max-salary-container",
                    isRequired: true,
                    min: this.props.salary ? this.props.salary.amount : 1,
                    value: 500,
                },
                {
                    fieldName: "salaryCurrency",
                    title: "",
                    type: "select",
                    options: currenciesOptions.map(({ value, label }) => ({
                        label: `${value} ${label}`,
                        title: `${value} ${label}`,
                        value,
                    })),
                    className: "salary-currency-container",
                },
            ],
            contractType: [
                {
                    fieldName: "contractType",
                    // title: _.get(translation, "job.contract_type"),
                    title: "",
                    type: "select",
                    options: preferredContractTypeOptions.map(({ value, label }) => ({
                        label,
                        title: label,
                        value,
                    })),
                    className: "select-container",
                },
            ],
            aplicantsAmount: [
                {
                    fieldName: "deepScreeningAmount",
                    title: _.get(translation, "post_job.first_step.deep_screening_amount_label"),
                    type: "number",
                    min: 1,
                    max: 10000,
                    isRequired: true,
                    className: "number-container",
                },
                {
                    fieldName: "topX",
                    title: _.get(translation, "post_job.first_step.top_x_label"),
                    type: "number",
                    min: topY || 1,
                    max: 10000,
                    isRequired: true,
                    className: "number-container",
                },
                {
                    fieldName: "topY",
                    title: _.get(translation, "post_job.first_step.top_y_label"),
                    type: "number",
                    min: 1,
                    max: topX || 1,
                    isRequired: true,
                    className: "number-container",
                },
            ],
        };

        const datesFields = [
            {
                fieldName: "applicationDeadline",
                title: _.get(translation, `post_job.first_step.aplication_deadline_label`),
                type: "date",
                popover: _.get(translation, `post_job.first_step.aplication_deadline_popover`),
                isRequired: true,
                className: "date-container",
                min: moment().add(1, "d").format("YYYY-MM-DD"),
                max: moment(expectedStart).add(-1, "d").format("YYYY-MM-DD"),
            },
            {
                fieldName: "expectedStart",
                title: _.get(translation, `post_job.first_step.expected_start_date_label`),
                type: "date",
                isRequired: true,
                className: "date-container",
                min: moment(applicationDeadline).add(1, "d").format("YYYY-MM-DD"),
            },
        ];

        const postJobData = {
            officeMedia,
            bossMedia,
            jobDescription,
            job: {
                jobTitle,
                internalReference,
                deepScreeningAmount,
                deadline: applicationDeadline,
                expectedImpactOfTravel: isTravelRequired ? travelImpact : 0,
                percentageOfHomeWorking: homeWorkingAllowed ? homeWorkingImpact : 0,
                salary: { amount: maxSalary, currency: salaryCurrency },
                topAmount: topX,
                amountToHire: topY,
                startDate: expectedStart,
                contractType,
                workRegime,
                profileId: profiles?.length ? profiles[0].id : null,
                skills: skills?.map(({ id, level, requirementCoefficient }) => ({
                    skillId: id,
                    level,
                    requirementCoefficient,
                })),
                hireManagerId: assistHRM?.id,
                businessOwnerId: assistBO?.id,

                location: {
                    countryCode,
                    city,
                    district,
                    street: "",
                    postal: "",
                },
            },
        };

        return (
            <div className="post-job-page-wrapper">
                <h5 className="page-subsection-header">
                    {_.get(translation, "post_job.first_step.team_sport_label")}
                </h5>
                <h6
                    className="description-text"
                    dangerouslySetInnerHTML={{
                        __html: _.get(translation, "post_job.first_step.team_sport_description"),
                    }}
                />
                <div className="rationale-info-fields-wrapper">
                    {_.map(teamSportFields, (params) =>
                        this.props.renderInlineSelectFormGroup(params)
                    )}
                </div>
                <h6>{_.get(translation, `post_job.first_step.${currentSubRoleLabel}_label`)}</h6>
                {errors[currentSubRoleLabel] && (
                    <div style={{ color: "orange" }}>{errors[currentSubRoleLabel]}</div>
                )}
                <FilteredList
                    data={this.filterEmployeesBySubRole(selectedDept?.employees || staffList)}
                    placeholderText={_.get(translation, "post_job.first_step.filter_by_fn_ln")}
                    filteredFields={["firstName", "lastName", "email"]}
                    activeLiElem={this.props[currentSubRoleLabel]}
                    getLiElemInnerText={(elem) =>
                        `${elem.firstName} ${elem.lastName} - ${elem.email}`
                    }
                    disabled={this.props.disabled}
                    getLiOnClickHandler={(selectedElement) => {
                        setParentStateValue(currentSubRoleLabel, selectedElement);
                    }}
                    listContainerClassName="required"
                    jobHr={this.props.job.hireManager}
                />
                <h5 className="page-subsection-header">
                    {_.get(translation, "post_job.first_step.rm_label")}
                </h5>
                <h6
                    className="description-text"
                    dangerouslySetInnerHTML={{
                        __html: _.get(translation, "post_job.first_step.rich_media_description"),
                    }}
                />
                <h6>{_.get(translation, "post_job.first_step.rich_media_title_label")}</h6>
                {errors[
                    activeDropzoneTab === DROPZONE_TABS.OFFICE ? "officeMedia" : "bossMedia"
                ] && (
                    <div style={{ color: "orange" }}>
                        {errors[
                            activeDropzoneTab === DROPZONE_TABS.OFFICE ? "officeMedia" : "bossMedia"
                        ].join(", ")}
                    </div>
                )}
                <JobRichMedia
                    activeDropzoneTab={activeDropzoneTab}
                    setParentStateValue={(key, value) => setParentStateValue(key, value)}
                    isRequired
                    officeMedia={officeMedia}
                    bossMedia={bossMedia}
                    readOnly={this.props.readOnly}
                />
                <h5 className="page-subsection-header">
                    {_.get(translation, "post_job.first_step.further_job_label")}
                </h5>
                <div className="inputs-container end-aligned">
                    {_.map(jobDescriptionFields, (params) =>
                        this.props.renderInlineSelectFormGroup(params)
                    )}
                </div>
                <h6 className="category-subsection-title">
                    {_.get(translation, "shared.location")}
                </h6>
                <div className="inputs-container">
                    {_.map(locationFields, (params) =>
                        this.props.renderInlineSelectFormGroup(params)
                    )}
                </div>
                <h6 className="category-subsection-title">
                    {_.get(translation, "job.work_regime_label")}
                </h6>
                <div className="inputs-container">
                    {_.map(workRegimeFields, (params) =>
                        this.props.renderInlineSelectFormGroup(params)
                    )}
                </div>
                <h6 className="category-subsection-title">
                    {_.get(translation, "shared.numbers_label")}
                </h6>
                <div className="salary-contract-type-container">
                    <div className="salary">
                        <h6>{_.get(translation, `post_job.first_step.max_salary_label`)}</h6>
                        <div className="salary-container">
                            {_.map(numberFields.salary, (params) =>
                                this.props.renderInlineSelectFormGroup(params)
                            )}
                        </div>
                    </div>
                    <div className="contract-type">
                        <h6>{_.get(translation, "job.contract_type")}</h6>
                        {_.map(numberFields.contractType, (params) =>
                            this.props.renderInlineSelectFormGroup(params)
                        )}
                    </div>
                </div>
                <h6
                    className="additional_descripton_container"
                    dangerouslySetInnerHTML={{
                        __html: _.get(
                            translation,
                            `post_job.first_step.applicants_amount_description`
                        ),
                    }}
                />
                <div className="inputs-container">
                    {_.map(numberFields.aplicantsAmount, (params) =>
                        this.props.renderInlineSelectFormGroup(params)
                    )}
                </div>
                <h6 className="category-subsection-title">
                    {_.get(translation, "shared.dates_label")}
                </h6>
                <div className="inputs-container">
                    {_.map(datesFields, (params) => this.props.renderInlineSelectFormGroup(params))}
                </div>
                <div className="submit-buttons-container">
                    <Button
                        color="primary"
                        disabled={this.props.disabled}
                        onClick={() => this.postJob(postJobData)}
                    >
                        {_.get(translation, "post_job.save_button_label")}
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => this.props.validate()}
                        disabled={this.props.disabled}
                    >
                        {_.get(translation, "shared.validate_button_label")}
                    </Button>
                </div>
            </div>
        );
    }
}

PostJobFirstStep.propTypes = {
    translation: PropTypes.object.isRequired,
    handleChangeFileField: PropTypes.func.isRequired,
    renderInlineSelectFormGroup: PropTypes.func.isRequired,
};

export default PostJobFirstStep;
