import { createAction, handleActions } from "redux-actions";

//- Actions
export const setActiveJobId = createAction("SET_ACTIVE_JOB_ID");

//- Selectors

export const getActiveJobId = (state) => getApplicantJobs(state).activeJobId;

export const getApplicantJobs = (state) => state.applicantJobs;

//- State
const initialState = {
    activeJobId: null,
};

//- Reducers
export default handleActions(
    {
        SET_ACTIVE_JOB_ID: (state, action) => {
            return { ...state, activeJobId: action.payload };
        },
    },
    initialState
);
