import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Input, Button, InputGroup, InputGroupAddon } from "reactstrap";
import Icon from "../icon/Icon";
import "./FilteredList.scss";

class FilteredList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filterValue: "",
            selectedElem: null,
        };
    }

    onChangeHandler = ({ target: { value } }) => {
        this.setState({ filterValue: value });
    };

    onClickHandler = (id, filteredData) => {
        const selectedElem = filteredData.find((elem) => elem.id === id);
        this.setState({ selectedElem });
    };

    onSubmitHandler = () => {
        const { getLiOnClickHandler } = this.props;
        if (getLiOnClickHandler) {
            getLiOnClickHandler(this.state.selectedElem);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.jobHr && prevState.selectedElem !== this.props.jobHr) {
            this.setState({ selectedElem: this.props.jobHr });
            this.onSubmitHandler();
        }
    }

    render() {
        const {
            data,
            filteredFields,
            fallbackText,
            getLiElemInnerText,
            placeholderText,
            translation,
            activeLiElem,
            listContainerClassName,
        } = this.props;

        const { filterValue, selectedElem } = this.state;
        const filteredData = filterValue
            ? data.filter((elem) =>
                  filteredFields.find((field) => elem[field].toLowerCase().includes(filterValue))
              )
            : data;

        return (
            <div className="filtered-list-container">
                <div className="select-area">
                    <ul className={`${listContainerClassName || ""} list-container`}>
                        {!this.props.disabled ? (
                            filteredData.length ? (
                                filteredData.map((elem, index) => (
                                    <li
                                        key={elem.id || index}
                                        className={elem.id === selectedElem?.id ? "active" : ""}
                                        onClick={(e) => this.onClickHandler(elem.id, filteredData)}
                                    >
                                        {getLiElemInnerText(elem)}
                                    </li>
                                ))
                            ) : (
                                <div className="fallback">
                                    {fallbackText || _.get(translation, "shared.no_data_label")}
                                </div>
                            )
                        ) : filteredData.length ? (
                            <li className="active">{`${filteredData[0].firstName} ${filteredData[0].lastName} --- ${filteredData[0].email}`}</li>
                        ) : (
                            <div className="fallback">
                                {fallbackText || _.get(translation, "shared.no_data_label")}
                            </div>
                        )}
                    </ul>
                    {activeLiElem && <div>{getLiElemInnerText(activeLiElem)}</div>}
                </div>
                <div className="filter-container">
                    <InputGroup>
                        <InputGroupAddon addonType="append">
                            <Input
                                type="text"
                                value={filterValue}
                                onChange={this.onChangeHandler}
                                placeholder={
                                    placeholderText || _.get(translation, "shared.filter_label")
                                }
                                className="with-button right"
                                disabled={this.props.disabled}
                            />
                            <Button
                                onClick={() => this.setState({ filterValue: "" })}
                                title={_.get(translation, "shared.reset_label")}
                                color="danger"
                            >
                                <Icon type="clear" color="light" />
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                    <Button
                        color="primary"
                        onClick={this.onSubmitHandler}
                        disabled={this.props.disabled}
                    >
                        {_.get(translation, "shared.select_button_label")}
                    </Button>
                </div>
            </div>
        );
    }
}

FilteredList.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.array,
    filteredFields: PropTypes.arrayOf(PropTypes.string),
    fallbackText: PropTypes.string,
    getLiElemInnerText: PropTypes.func.isRequired,
    placeholderText: PropTypes.string,
    getLiOnClickHandler: PropTypes.func,
};

export default FilteredList;
