import { connect } from 'react-redux';
import { getTranslation } from '../../../reducers/common/localization';
import ApplicantQuickfind from './ApplicantQuickfind';
import {
	getSelectedCandidatesIds,
	getSelectedSkills,
	getSelectedSkillId,
	getFetchApplicantsLoading,
	getApplicants
} from '../../../reducers/quickfind/quickfind';

const mapStateToProps = state => {
	return {
		translation: getTranslation(state),
		selectedCandidatesIds: getSelectedCandidatesIds(state),
		skills: getSelectedSkills(state),
		selectedSkillId: getSelectedSkillId(state),
		loading: getFetchApplicantsLoading(state),
		applicants: getApplicants(state)
	};
};

export default connect(mapStateToProps, null)(ApplicantQuickfind);
