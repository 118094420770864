import React, { Component } from "react";
import PropTypes from "prop-types";
import "./WelcomePage.scss";
import _ from "lodash";
import { Utils } from "../../../../utils/utils";
import { USER_ROLE } from "../../../../constants/constants";
import { Button } from "reactstrap";

const TRANSLATION_BY_USER_ROLE = {
    [USER_ROLE.APPLICANT]: "applicant",
    [USER_ROLE.EMPLOYEE]: "employee",
};

class WelcomePage extends Component {
    render() {
        if (
            this.props.userRole !== USER_ROLE.APPLICANT &&
            this.props.userRole !== USER_ROLE.EMPLOYEE
        ) {
            return null;
        }

        return (
            <div className="welcome-page">
                <h1 className="main-color-text font-italic text-center">
                    {Utils.getTagsFromStringWithStyles(
                        _.get(this.props.translation, "welcome_page.intro")
                    )}
                </h1>
                <h1 className="main-color-text mt-5">
                    {Utils.getTagsFromStringWithStyles(
                        _.get(this.props.translation, "welcome_page.our_vision_header")
                    )}
                </h1>
                <h2 style={{ textIndent: "15px" }} className="mt-5 font-weight-bold text-center">
                    {Utils.getTagsFromStringWithStyles(
                        _.get(
                            this.props.translation,
                            "welcome_page.our_vision_first_paragraph_header"
                        )
                    )}
                </h2>
                <h5 className="white-space-normal mt-3">
                    {Utils.getTagsFromStringWithStyles(
                        _.get(
                            this.props.translation,
                            `welcome_page.${
                                TRANSLATION_BY_USER_ROLE[this.props.userRole]
                            }.our_vision_first_paragraph`
                        )
                    )}
                </h5>
                <h2 style={{ textIndent: "15px" }} className="mt-5 font-weight-bold text-center">
                    {Utils.getTagsFromStringWithStyles(
                        _.get(
                            this.props.translation,
                            `welcome_page.${
                                TRANSLATION_BY_USER_ROLE[this.props.userRole]
                            }.our_vision_second_paragraph_header`
                        )
                    )}
                </h2>
                <h5 className="white-space-normal mt-3">
                    {Utils.getTagsFromStringWithStyles(
                        _.get(
                            this.props.translation,
                            `welcome_page.${
                                TRANSLATION_BY_USER_ROLE[this.props.userRole]
                            }.our_vision_second_paragraph`
                        )
                    )}
                </h5>
                <h1 className="main-color-text mt-5">
                    {Utils.getTagsFromStringWithStyles(
                        _.get(
                            this.props.translation,
                            `welcome_page.${
                                TRANSLATION_BY_USER_ROLE[this.props.userRole]
                            }.our_mission_header`
                        )
                    )}
                </h1>
                <h2 className="mt-5 font-weight-bold text-center">
                    {Utils.getTagsFromStringWithStyles(
                        _.get(this.props.translation, "welcome_page.our_mission_paragraph")
                    )}
                </h2>
                <h3 className="mt-5 mb-3">
                    {Utils.getTagsFromStringWithStyles(
                        _.get(this.props.translation, "welcome_page.visual_tools_paragraph")
                    )}
                </h3>
                <ul>
                    <li>
                        {Utils.getTagsFromStringWithStyles(
                            _.get(
                                this.props.translation,
                                `welcome_page.${
                                    TRANSLATION_BY_USER_ROLE[this.props.userRole]
                                }.visual_tools_paragraph_list_item_1`
                            )
                        )}
                    </li>
                    <li>
                        {Utils.getTagsFromStringWithStyles(
                            _.get(
                                this.props.translation,
                                `welcome_page.${
                                    TRANSLATION_BY_USER_ROLE[this.props.userRole]
                                }.visual_tools_paragraph_list_item_2`
                            )
                        )}
                    </li>
                    <li>
                        {Utils.getTagsFromStringWithStyles(
                            _.get(
                                this.props.translation,
                                `welcome_page.${
                                    TRANSLATION_BY_USER_ROLE[this.props.userRole]
                                }.visual_tools_paragraph_list_item_3`
                            )
                        )}
                    </li>
                    <li>
                        {Utils.getTagsFromStringWithStyles(
                            _.get(
                                this.props.translation,
                                `welcome_page.${
                                    TRANSLATION_BY_USER_ROLE[this.props.userRole]
                                }.visual_tools_paragraph_list_item_4`
                            )
                        )}
                    </li>
                    <li>
                        {Utils.getTagsFromStringWithStyles(
                            _.get(
                                this.props.translation,
                                `welcome_page.${
                                    TRANSLATION_BY_USER_ROLE[this.props.userRole]
                                }.visual_tools_paragraph_list_item_5`
                            )
                        )}
                    </li>
                    <li>
                        {Utils.getTagsFromStringWithStyles(
                            _.get(
                                this.props.translation,
                                `welcome_page.${
                                    TRANSLATION_BY_USER_ROLE[this.props.userRole]
                                }.visual_tools_paragraph_list_item_6`
                            )
                        )}
                    </li>
                    {this.props.userRole === USER_ROLE.EMPLOYEE ? (
                        <li>
                            {Utils.getTagsFromStringWithStyles(
                                _.get(
                                    this.props.translation,
                                    `welcome_page.${
                                        TRANSLATION_BY_USER_ROLE[this.props.userRole]
                                    }.visual_tools_paragraph_list_item_7`
                                )
                            )}
                        </li>
                    ) : null}
                </ul>
                <h4 className="mt-3 font-weight-bold">
                    {Utils.getTagsFromStringWithStyles(
                        _.get(
                            this.props.translation,
                            `welcome_page.${
                                TRANSLATION_BY_USER_ROLE[this.props.userRole]
                            }.final_paragraph`
                        )
                    )}
                </h4>
                <div className="buttons-container content-center p-3">
                    <Button color="primary" onClick={this.props.onReadWelcomePage}>
                        {_.get(this.props.translation, "welcome_page.submit_button")}
                    </Button>
                </div>
            </div>
        );
    }
}

WelcomePage.propTypes = {
    translation: PropTypes.object.isRequired,
    userRole: PropTypes.string,
    onReadWelcomePage: PropTypes.func.isRequired,
};

export default WelcomePage;
