import React, { Component } from "react";

class StaffAndDepartments extends Component {
    render() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 128"
            >
                <defs></defs>
                <title>a</title>
                <path
                    fill="#1d75b8"
                    d="M109.58121,65.256H66.78628V58.34256a2.78635,2.78635,0,1,0-5.5727,0V65.256H18.28748a2.78952,2.78952,0,0,0-2.78641,2.78629v9.69989a2.78641,2.78641,0,0,0,5.57283,0V70.82886h40.166v6.91335a2.78641,2.78641,0,0,0,5.57283,0V70.82886h39.98194v6.91335a2.78641,2.78641,0,0,0,5.57283,0V68.04232A2.78941,2.78941,0,0,0,109.58121,65.256Z"
                />
                <path
                    fill="#2d3e50"
                    d="M83.02395,38.243a4.07365,4.07365,0,0,0-2.19537-2.95649c-3.81735-1.90354-12.1941-4.71486-12.1941-4.71486V27.8058l.23318-.176a8.00286,8.00286,0,0,0,3.03862-5.08589l.04693-.29477h.22731a3.09144,3.09144,0,0,0,2.86118-1.92407,3.36653,3.36653,0,0,0,.42089-1.63077,3.09747,3.09747,0,0,0-.22-1.14681,1.59952,1.59952,0,0,0-.62033-.99724l-.77286-.46928.19211-.83884c1.39613-6.08605-3.31873-11.56791-9.64969-11.719-.154-.00294-.3065-.00439-.45755-.00147-.15106-.00292-.30357-.00147-.45755.00147-6.331.15106-11.04582,5.63292-9.64969,11.719l.19211.83884-.77286.46928a1.59952,1.59952,0,0,0-.62033.99724,3.09747,3.09747,0,0,0-.22,1.14681,3.36653,3.36653,0,0,0,.42089,1.63077,3.09144,3.09144,0,0,0,2.86118,1.92407h.22731l.04693.29477a8.00286,8.00286,0,0,0,3.03862,5.08589l.23318.176v2.76585S50.85734,33.383,47.04,35.28651A4.07365,4.07365,0,0,0,44.84462,38.243a88.69854,88.69854,0,0,0-.76992,10.25684H83.79387A88.69854,88.69854,0,0,0,83.02395,38.243Z"
                />
                <path
                    fill="#2d3e50"
                    d="M34.37878,115.74224a3.47028,3.47028,0,0,0-1.8702-2.51859c-3.25194-1.62159-10.388-4.01652-10.388-4.01652V106.851l.19864-.14991a6.81751,6.81751,0,0,0,2.58855-4.33259l.04-.25111h.19364a2.63355,2.63355,0,0,0,2.43739-1.63909,2.86789,2.86789,0,0,0,.35855-1.38923,2.63869,2.63869,0,0,0-.1874-.97695,1.36261,1.36261,0,0,0-.52845-.84953l-.65839-.39977.16366-.7146c1.18934-5.18461-2.82718-9.85453-8.22042-9.98321-.13118-.0025-.2611-.00374-.38978-.00125-.12868-.00249-.25861-.00125-.38978.00125-5.39324.12868-9.40976,4.7986-8.22042,9.98321l.16366.7146-.65839.39977a1.36261,1.36261,0,0,0-.52845.84953,2.63869,2.63869,0,0,0-.1874.97695,2.86789,2.86789,0,0,0,.35855,1.38923,2.63355,2.63355,0,0,0,2.43739,1.63909h.19364l.04.25111a6.81751,6.81751,0,0,0,2.58855,4.33259l.19864.14991v2.35619s-7.136,2.39492-10.388,4.01652a3.47028,3.47028,0,0,0-1.8702,2.51859,75.56091,75.56091,0,0,0-.65588,8.73764H35.03467A75.56091,75.56091,0,0,0,34.37878,115.74224Z"
                />
                <path
                    fill="#2d3e50"
                    d="M80.19647,115.74224a3.47028,3.47028,0,0,0-1.8702-2.51859c-3.25194-1.62159-10.388-4.01652-10.388-4.01652V106.851l.19864-.14991a6.81751,6.81751,0,0,0,2.58855-4.33259l.04-.25111h.19364a2.63355,2.63355,0,0,0,2.43739-1.63909,2.86789,2.86789,0,0,0,.35855-1.38923,2.63869,2.63869,0,0,0-.1874-.97695,1.36261,1.36261,0,0,0-.52845-.84953l-.65839-.39977.16366-.7146c1.18934-5.18461-2.82718-9.85453-8.22042-9.98321-.13118-.0025-.2611-.00374-.38978-.00125-.12868-.00249-.25861-.00125-.38978.00125-5.39324.12868-9.40976,4.7986-8.22042,9.98321l.16366.7146-.65839.39977a1.36261,1.36261,0,0,0-.52845.84953,2.63869,2.63869,0,0,0-.1874.97695,2.86789,2.86789,0,0,0,.35855,1.38923,2.63355,2.63355,0,0,0,2.43739,1.63909h.19364l.04.25111a6.81751,6.81751,0,0,0,2.58855,4.33259l.19864.14991v2.35619s-7.136,2.39492-10.388,4.01652a3.47028,3.47028,0,0,0-1.8702,2.51859,75.56091,75.56091,0,0,0-.65588,8.73764H80.85235A75.56091,75.56091,0,0,0,80.19647,115.74224Z"
                />
                <path
                    fill="#2d3e50"
                    d="M126.14559,115.74224a3.47028,3.47028,0,0,0-1.8702-2.51859c-3.25194-1.62159-10.388-4.01652-10.388-4.01652V106.851l.19864-.14991a6.81751,6.81751,0,0,0,2.58855-4.33259l.04-.25111h.19364a2.63355,2.63355,0,0,0,2.43739-1.63909,2.86789,2.86789,0,0,0,.35855-1.38923,2.63869,2.63869,0,0,0-.1874-.97695,1.36261,1.36261,0,0,0-.52845-.84953l-.65839-.39977.16366-.7146c1.18934-5.18461-2.82718-9.85453-8.22042-9.98321-.13118-.0025-.2611-.00374-.38978-.00125-.12868-.00249-.25861-.00125-.38978.00125-5.39324.12868-9.40976,4.7986-8.22042,9.98321l.16366.7146-.65839.39977a1.36261,1.36261,0,0,0-.52845.84953,2.63869,2.63869,0,0,0-.1874.97695,2.86789,2.86789,0,0,0,.35855,1.38923,2.63355,2.63355,0,0,0,2.43739,1.63909h.19364l.04.25111a6.81751,6.81751,0,0,0,2.58855,4.33259l.19864.14991v2.35619s-7.136,2.39492-10.388,4.01652a3.47028,3.47028,0,0,0-1.8702,2.51859,75.56091,75.56091,0,0,0-.65588,8.73764h33.83615A75.5609,75.5609,0,0,0,126.14559,115.74224Z"
                />
            </svg>
        );
    }
}

export default StaffAndDepartments;
