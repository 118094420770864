import React, { Component } from "react";
import PropTypes from "prop-types";
import "./DepartmentsList.scss";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { Button, Table } from "reactstrap";
import Icon from "../../../common/icon/Icon";
import DepartmentsListPagination from "../../../common/tablePagination/DepartmentsListPagination.container";
import AddOrEditDepartmentModal from "../modals/addOrEditDepartmentModal/AddOrEditDepartmentModal.container";
import { ROUTES, USER_SUB_ROLE } from "../../../../constants/constants";
import RemoveDepartmentModal from "../modals/removeDepartmentModal/RemoveDepartmentModal.container";
import { ACCOUNT_SETUP_STEPS } from "../../../employee/profile/EmployeeProfile";

class DepartmentsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditDepartment: false,
            selectedDepartment: {},
        };
    }

    componentDidMount() {
        this.props.getInitialDepartmentsList();
    }

    render() {
        return (
            <>
                <div className="departments-list">
                    <h4 className="page-header">
                        {_.get(this.props.translation, "departments.list_label")}
                    </h4>
                    <div className="buttons-container">
                        <Button onClick={this.props.onAddOrEditDepartment} color="primary">
                            {_.get(this.props.translation, "actions.add_label")}
                        </Button>
                        <Button
                            color="success"
                            className="align-self-end ml-3"
                            onClick={() =>
                                this.props.history.push("/my-profile", {
                                    activeTab: ACCOUNT_SETUP_STEPS.RICH_MEDIA,
                                    activeMediaSource: "departments",
                                })
                            }
                        >
                            {_.get(this.props.translation, "actions.manage_rich_media")}
                        </Button>
                    </div>
                    <DepartmentsListPagination />
                    {this.renderContent()}
                </div>
                <AddOrEditDepartmentModal
                    isEditDepartment={this.state.isEditDepartment}
                    disableEditMode={() => {
                        if (this.state.isEditDepartment) {
                            this.setState({ isEditDepartment: false, selectedDepartment: {} });
                        }
                    }}
                    selectedDepartment={this.state.selectedDepartment}
                />
                <RemoveDepartmentModal
                    departmentData={this.state.selectedDepartment}
                    clearDepartment={() => this.setState({ selectedDepartment: {} })}
                />
            </>
        );
    }

    renderContent() {
        if (this.props.loading) {
            return (
                <h6 className="text-center">
                    {_.get(this.props.translation, "actions.fetching_message")}
                </h6>
            );
        }

        if (_.isNil(this.props.data) || this.props.data.length === 0) {
            // TODO: add hint
            return (
                <h6 className="text-center">
                    {_.get(this.props.translation, "departments.no_data_label")}
                </h6>
            );
        }

        return (
            <Table className="clickable-rows">
                {/* TODO: add department details functionality */}
                <thead>
                    <tr>
                        <th>
                            {_.get(this.props.translation, "departments.department_name_label")}
                        </th>
                        <th className="table-cell-align-center">
                            {_.get(this.props.translation, "departments.hiring_managers_label")}
                        </th>
                        <th className="table-cell-align-center">
                            {_.get(this.props.translation, "departments.business_owners_label")}
                        </th>
                        <th />
                        <th />
                    </tr>
                </thead>
                <tbody>{this.renderListData()}</tbody>
            </Table>
        );
    }

    renderListData() {
        return _.map(this.props.data, (elem) => (
            <tr key={elem.id}>
                <td onClick={this.onSelectDepartment(elem.id)}>{elem.name}</td>
                <td onClick={this.onSelectDepartment(elem.id)} className="table-cell-align-center">
                    {_.isEmpty(elem.employees)
                        ? 0
                        : _.filter(elem.employees, (employee) =>
                              employee.roles.includes(USER_SUB_ROLE.HM)
                          ).length}
                </td>
                <td onClick={this.onSelectDepartment(elem.id)} className="table-cell-align-center">
                    {_.isEmpty(elem.employees)
                        ? 0
                        : _.filter(elem.employees, (employee) =>
                              employee.roles.includes(USER_SUB_ROLE.BO)
                          ).length}
                </td>
                <td
                    onClick={() => {
                        this.setState({ isEditDepartment: true, selectedDepartment: elem });
                        this.props.onAddOrEditDepartment();
                    }}
                    className="table-cell-with-icon"
                >
                    <Icon type="edit" title={_.get(this.props.translation, "actions.edit_label")} />
                </td>
                <td
                    className="table-cell-with-icon"
                    onClick={() => {
                        this.setState({ selectedDepartment: elem });
                        this.props.onRemoveDepartment();
                    }}
                >
                    <Icon
                        type="delete"
                        title={_.get(this.props.translation, "actions.remove_button")}
                    />
                </td>
            </tr>
        ));
    }

    onSelectDepartment = (departmentId) => () => {
        this.props.history.push(`${ROUTES.DEPARTMENT_DETAILS}?id=${departmentId}`);
    };
}

DepartmentsList.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    onAddOrEditDepartment: PropTypes.func.isRequired,
    getInitialDepartmentsList: PropTypes.func.isRequired,
    onRemoveDepartment: PropTypes.func.isRequired,
};

export default withRouter(DepartmentsList);
