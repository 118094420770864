import React, { Component } from "react";
import {
    Modal,
    ModalBody,
    Button,
    Alert,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
} from "reactstrap";
import _ from "lodash";
import "./DFALoginModal.scss";

class DFALoginModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            verificationCode: "",
        };
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.onCancel}
                className="two-fa-login-modal-container"
            >
                <ModalBody>
                    <Alert color="warning" className="two-fa-alert" hidden={!this.props.fetching}>
                        {_.get(this.props.translation, "actions.fetching_message")}
                    </Alert>
                    <Alert color="danger" className="two-fa-alert" hidden={!this.props.error}>
                        {_.get(this.props.translation, "errors.DEFAULT_ERROR")}
                    </Alert>
                    <Form onSubmit={this.onSubmit}>
                        <h5 className="text-center two-fa-login-label">
                            {_.get(this.props.translation, "security_settings.2fa_label")}
                        </h5>
                        <h6>
                            {_.get(
                                this.props.translation,
                                "security_settings.verification_code_enter_label"
                            )}
                        </h6>
                        <FormGroup row>
                            <Label sm={4}>
                                {_.get(
                                    this.props.translation,
                                    "security_settings.check_code_label"
                                )}
                            </Label>
                            <Col sm={8}>
                                <Input
                                    value={this.state.verificationCode}
                                    onChange={this.handleChangeVerificationCode}
                                    type="text"
                                    size="6"
                                    placeholder="_ _ _ _ _ _"
                                    required
                                    invalid={
                                        this.state.verificationCode.length < 6 &&
                                        this.state.verificationCode.length > 0
                                    }
                                />
                                <FormFeedback>
                                    {_.get(
                                        this.props.translation,
                                        "security_settings.verification_code_details_label"
                                    )}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <div className="two-fa-login-modal-buttons-container">
                            <Button color="secondary" onClick={this.props.onCancel}>
                                {_.get(this.props.translation, "actions.cancel_button")}
                            </Button>
                            <Button
                                className="olive-green-btn"
                                disabled={
                                    this.state.verificationCode.length < 6 &&
                                    this.state.verificationCode.length > 0
                                }
                            >
                                {_.get(this.props.translation, "login_page.login_button")}
                            </Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        );
    }

    handleChangeVerificationCode = (e) => {
        let regexp = /^[0-9]{0,6}$/;
        let value = e.target.value;
        if (regexp.test(value)) {
            this.setState({ verificationCode: value });
        }
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (!_.isNil(this.state.verificationCode) && this.state.verificationCode.length === 6) {
            this.props.onSubmit(this.state.verificationCode);
        }
    };
}

export default DFALoginModal;
