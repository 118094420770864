import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'reactstrap';

class ButtonWithTooltip extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tooltipOpen: false,
		}
	}

	render() {
		const { id, onClick, buttonTitle, buttonTooltip } = this.props;

		const { tooltipOpen } = this.state;

		return (
			<>
				<Button
					color="primary"
					onClick={onClick}
					id={id}
				>
					{buttonTitle}
				</Button>
				<Tooltip
					placement="top"
					target={id}
					isOpen={tooltipOpen}
					toggle={() => this.setState({ tooltipOpen: !tooltipOpen })}
				>
					{buttonTooltip}
				</Tooltip>
			</>
		)
	}
}

ButtonWithTooltip.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClick: PropTypes.func.isRequired,
	buttonTitle: PropTypes.string.isRequired,
	buttonTooltip: PropTypes.string.isRequired
};

export default ButtonWithTooltip;
