import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';

//- Actions
export const fetchApplicantByIdSuccess = createAction('FETCH_APPLICANT_BY_ID_SUCCESS');
export const fetchApplicantByIdFailed = createAction('FETCH_APPLICANT_BY_ID_FAILED');
export const setApplicantComparisonModalVisibility = createAction('SET_APPLICANT_COMPARISON_MODAL_VISIBILITY');
export const setSelectedApplicantId = createAction('SET_SELECTED_APPLICANT_ID');

export const fetchApplicantById = createAction(
	'FETCH_APPLICANT_BY_ID',
	(applicantId) => Api.getApplicantById(applicantId),
	() => ({
		apiCall: true,
		onSuccess: fetchApplicantByIdSuccess,
		onFail: fetchApplicantByIdFailed
	})
);

export const onFetchApplicantById = (applicantId) => (dispatch) => {
	dispatch(fetchApplicantById(applicantId));
};

export const onOpenModal = (id) => (dispatch) => {
	dispatch(setSelectedApplicantId(id));
	dispatch(setApplicantComparisonModalVisibility(true));
};

export const onCloseModal = () => (dispatch) => {
	dispatch(setApplicantComparisonModalVisibility(false));
	dispatch(setSelectedApplicantId(''));
};

//- State
const initialState = {
	isOpen: false,
	selectedApplicantId: '',
	applicantData: {},
	error: null,
	fetchingApplicantById: false,
};

//- Reducer
export default handleActions({
	FETCH_APPLICANT_BY_ID: (state) => {
		return { ...state, applicantData: {}, error: null, fetchingApplicantById: true };
	},
	FETCH_APPLICANT_BY_ID_SUCCESS: (state, action) => {
		return { ...state, applicantData: action.payload, error: null, fetchingApplicantById: false };
	},
	FETCH_APPLICANT_BY_ID_FAILED: (state, action) => {
		return { ...state, applicantData: {}, error: action.payload, fetchingApplicantById: false };
	},
	SET_APPLICANT_COMPARISON_MODAL_VISIBILITY: (state, action) => {
		return { ...state, isOpen: action.payload };
	},
	SET_SELECTED_APPLICANT_ID: (state, action) => {
		return { ...state, selectedApplicantId: action.payload };
	},
}, initialState);

//- Selectors
export const getApplicantComparisonState = state => state.applicantComparison;

export const getApplicantData = state => getApplicantComparisonState(state).applicantData;

export const getFetchApplicantByIdLoading = state => getApplicantComparisonState(state).fetchingApplicantById;

export const getApplicantComparisonModalVisibility = state => getApplicantComparisonState(state).isOpen;

export const getSelectedApplicantId = state => getApplicantComparisonState(state).selectedApplicantId;


