import { connect } from "react-redux";
import NodesFilters from "./NodesFilters";
import { getTranslation } from "../../../../reducers/common/localization";
import {
    getAllClustersNameFilter,
    getAllClustersSourceFilter,
    getAllClustersStatusFilter,
    getAllClustersDomainFilter,
    onFetchGetAllClustersData,
    updateAllClustersNameFilter,
    onUpdateAllClustersSourceFilter,
    onUpdateAllClustersStatusFilter,
    onUpdateAllClustersDomainFilter,
    onUpdateAllClustersSortingMode,
    getAllClustersSortingMode,
} from "../../../../reducers/admin/clusters";
import {
    getSystemNodesExternalSources,
    getSystemNodesExternalSourcesFetchingStatus,
    refreshSystemNodesExternalSources,
} from "../../../../reducers/admin/systemModel";
import { fetchGetDomainList, getDomains } from "../../../../reducers/admin/domainsAndSC";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        disableTypeFilter: true,
        nodeNameFilter: getAllClustersNameFilter(state),
        nodeSourceFilter: getAllClustersSourceFilter(state),
        nodeStatusFilter: getAllClustersStatusFilter(state),
        nodeDomainFilter: getAllClustersDomainFilter(state),
        sortingMode: getAllClustersSortingMode(state),
        externalSources: getSystemNodesExternalSources(state),
        loadingExternalSources: getSystemNodesExternalSourcesFetchingStatus(state),
        domains: getDomains(state),
        nodeRelation: "Skill-Skill_cluster"
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onMount: () => {
            dispatch(refreshSystemNodesExternalSources());
            dispatch(fetchGetDomainList());
        },
        onGetNodesList: () => dispatch(onFetchGetAllClustersData()),
        onUpdateNodesNameFilter: (filter) => dispatch(updateAllClustersNameFilter(filter)),
        onUpdateNodesSourceFilter: (filter) => dispatch(onUpdateAllClustersSourceFilter(filter)),
        onUpdateNodesStatusFilter: (filter) => dispatch(onUpdateAllClustersStatusFilter(filter)),
        onUpdateNodesDomainFilter: (filter) => dispatch(onUpdateAllClustersDomainFilter(filter)),
        onUpdateNodesSortingMode: (sortingMode) =>
            dispatch(onUpdateAllClustersSortingMode(sortingMode)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NodesFilters);
