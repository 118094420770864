import React, { Component } from "react";
import PropTypes from "prop-types";
import { Carousel, CarouselItem } from "reactstrap";

class DataSlider extends Component {
    next() {
        if (this.props.animating) return;
        const nextIndex =
            this.props.activeIndex === this.props.jobs.length - 1 ? 0 : this.props.activeIndex + 1;
        this.props.setCurrentJobIndex(nextIndex);
    }

    previous() {
        if (this.props.animating) return;
        const nextIndex =
            this.props.activeIndex === 0 ? this.props.jobs.length - 1 : this.props.activeIndex - 1;
        this.props.setCurrentJobIndex(nextIndex);
    }

    goToIndex(newIndex) {
        if (this.props.animating) return;
        this.props.setCurrentJobIndex(newIndex);
    }

    getSlides(jobs) {
        return jobs.map((job, index) => (
            <CarouselItem
                onExiting={() => this.props.setAnimating(true)}
                onExited={() => this.props.setAnimating(false)}
                key={index} // switch on job.id
            >
                {job}
            </CarouselItem>
        ));
    }
    render() {
        return (
            this.props.jobs && (
                <Carousel
                    activeIndex={this.props.activeIndex}
                    next={() => this.next()}
                    previous={() => this.previous()}
                    interval={false}
                    slide={true}
                >
                    {this.getSlides(this.props.jobs, "jobDescription")}
                </Carousel>
            )
        );
    }
}

DataSlider.propTypes = {
    animating: PropTypes.bool.isRequired,
    activeIndex: PropTypes.number.isRequired,
    jobs: PropTypes.array,
    setCurrentJobIndex: PropTypes.func.isRequired,
    setAnimating: PropTypes.func.isRequired,
};

export default DataSlider;
