import React, { Component } from 'react';

class Interviewed extends Component {
	render() {
		return (
			<svg width="128" height="128" viewBox="0 0 128 128">
				<path fill="#2d3e50" d="M14.65757,40.53276A10.39692,10.39692,0,0,0,25.04286,30.14747V26.371a10.38529,10.38529,0,1,0-20.77058,0v3.77644A10.397,10.397,0,0,0,14.65757,40.53276Z"/>
				<path fill="#2d3e50" d="M38.48686,78.38737H23.86247V55.76785a11.01747,11.01747,0,1,0-22.03493,0V81.7376h.01069A12.03521,12.03521,0,0,0,13.8505,93.33967H37.24087a.26257.26257,0,0,1,.26231.26231v22.42844a5.37751,5.37751,0,1,0,10.755,0V88.15882A9.78245,9.78245,0,0,0,38.48686,78.38737Z"/>
				<path fill="#2d3e50" d="M113.34243,40.53276a10.397,10.397,0,0,0,10.38529-10.38529V26.371a10.38529,10.38529,0,1,0-20.77058,0v3.77644A10.39692,10.39692,0,0,0,113.34243,40.53276Z"/>
				<path fill="#2d3e50" d="M115.155,44.75036a11.02989,11.02989,0,0,0-11.01745,11.01749V78.38737H89.51314a9.78245,9.78245,0,0,0-9.77133,9.77145v27.87161a5.37751,5.37751,0,1,0,10.755,0V93.602a.26257.26257,0,0,1,.26231-.26231H114.1495A12.03521,12.03521,0,0,0,126.16178,81.7376h.01069V55.76785A11.0299,11.0299,0,0,0,115.155,44.75036Z"/>
				<path fill="#1d75b8" d="M89.70744,67.31A5.25237,5.25237,0,0,0,84.461,62.06355H43.539a5.24641,5.24641,0,1,0,0,10.49283h14.69V121.408H69.77107V72.55637h14.69A5.25237,5.25237,0,0,0,89.70744,67.31Z"/>
				<path fill="#1d75b8" d="M39.19929,40.693h14.975l9.826,9.826,9.826-9.826h14.9744a.175.175,0,0,0,.17222-.17222V6.76423a.175.175,0,0,0-.17222-.17222H39.19929a.175.175,0,0,0-.17222.17222V40.52074A.175.175,0,0,0,39.19929,40.693ZM74.3095,21.20721a3.44284,3.44284,0,1,1-1.00921,2.4353A3.456,3.456,0,0,1,74.3095,21.20721Zm-12.7448,0a3.44274,3.44274,0,1,1-1.00925,2.4353A3.47063,3.47063,0,0,1,61.5647,21.20721Zm-12.7448,0a3.44274,3.44274,0,1,1-1.00925,2.4353A3.45584,3.45584,0,0,1,48.8199,21.20721Z"/>
			</svg>
		);
	}
}

export default Interviewed;
