import { connect } from "react-redux";
import JobsQuickfindComparison from "./JobsQuickfindComparison";
import { getTranslation } from "../../../reducers/common/localization";
import { getSelectedJobsIds, getSelectedSkills } from "../../../reducers/quickfind/quickfind";
import {
    getJobsData,
    getFetchingJobsByIds,
    onFetchJobsByIds,
    // onOpenApplyJobsModal,
    // getIsJobsApplyModalOpen,
    // onCloseApplyJobsModal,
    onOpenAudioModal,
    onOpenVideoModal,
    onOpenDescriptionModal,
    getFetchingJobsApplications,
    getApplyJobsByIdsFetchingStatus,
    getJobsApplicationsIds,
    applyJobs,
    fetchJobsApplications,
} from "../../../reducers/quickfind/jobsComparison";
import { MODAL_APPLICANT_APPLIES_JOB, showModal } from "../../../reducers/common/activeModal";

const mapStateToProps = (state) => ({
    translation: getTranslation(state),
    selectedJobsIds: getSelectedJobsIds(state),
    skills: getSelectedSkills(state),
    jobsData: getJobsData(state),
    loading:
        getFetchingJobsByIds(state) ||
        getFetchingJobsApplications(state) ||
        getApplyJobsByIdsFetchingStatus(state),
    // isJobsApplyModalOpen: getIsJobsApplyModalOpen(state),
    jobsApplicationsIds: getJobsApplicationsIds(state),
});

const mapDispatchToProps = (dispatch) => ({
    onFetchJobsByIds: (jobIds) => dispatch(onFetchJobsByIds(jobIds)),
    // onOpenApplyJobsModal: () => dispatch(onOpenApplyJobsModal()),
    onApplyJobs: (jobIds) => dispatch(applyJobs(jobIds)),
    // onCloseApplyJobsModal: () => dispatch(onCloseApplyJobsModal()),
    onOpenAudioModal: () => dispatch(onOpenAudioModal()),
    onOpenVideoModal: () => dispatch(onOpenVideoModal()),
    onOpenDescriptionModal: () => dispatch(onOpenDescriptionModal()),
    onFetchJobsApplications: () => dispatch(fetchJobsApplications()),
    onOpenApplyJobsModal: (data) => dispatch(showModal(MODAL_APPLICANT_APPLIES_JOB, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobsQuickfindComparison);
