import { connect } from "react-redux";
import { getTranslation } from "../../../reducers/common/localization";
import {
    fetchGetDepartmentsList,
    getDepartmentsListData,
    fetchUpdateDepartmentMedia,
} from "../../../reducers/organization/departmentsList";
import {
    fetchGetOrganizationsList,
    fetchUpdateOrganizationMedia,
    getOrganizationsListState,
} from "../../../reducers/organization/organizations";
import CorporateIdentity from "./CorporateIdentity";
import { getUserCurrentSubRole } from "../../../reducers/user/userRole";
import {
    fetchGetStaffMemberJobs,
    fetchUpdateOrganizationJobMedia,
    getStaffMemberJobs,
} from "../../../reducers/job/organizationJobs";
import { USER_SUB_ROLE } from "../../../constants/constants";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        departmentsList: getDepartmentsListData(state),
        organization: getOrganizationsListState(state),
        teams: getStaffMemberJobs(state),
        userSubRole: getUserCurrentSubRole(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onMount: (userSubRole) => {
            dispatch(fetchGetOrganizationsList());
            dispatch(fetchGetDepartmentsList());

            if (userSubRole !== USER_SUB_ROLE.ANCHOR) {
                dispatch(fetchGetStaffMemberJobs());
            }
        },
        onUpdateOrgMedia: (id, data) => {
            dispatch(fetchUpdateOrganizationMedia(id, data));
        },
        onUpdateDepartmentMedia: (id, data) => {
            dispatch(fetchUpdateDepartmentMedia(id, data));
        },
        onUpdateJobMedia: (jobId, data) => {
            dispatch(fetchUpdateOrganizationJobMedia(jobId, data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CorporateIdentity);
