import React, { Component } from "react";
import { SKILLS_MAP_MODE } from "../../../common/skills/skillsMap/SkillsMap";
import SkillsMap from "../../../common/skills/skillsMap/SkillsMap";
import { SkillsUtils } from "../../../../utils/skillsUtils";
import Feedback from "./feedback/Feedback.container";

class SkillsAndFeedback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentFeedbackIndex: 0,
            animating: false,
        };
    }

    handleChange = ({ target: { value, name } }) => {
        this.props.setParentStateValue(name, value);
    };

    render() {
        const {
            translation,
            activeJobId,
            userId,
            imageSource,
            currentSkillId,
            usersJobs,
        } = this.props;
        const { currentFeedbackIndex, animating } = this.state;
        const selectedJob = usersJobs.find((job) => job.id === activeJobId);

        const skillsTree = SkillsUtils.createSkillsTree(selectedJob?.skills);
        return selectedJob ? (
            <>
                {skillsTree && (
                    <SkillsMap
                        skillsTree={skillsTree}
                        mode={SKILLS_MAP_MODE.SKILLS_JOURNEY_VIEW}
                        imageSource={imageSource || ""}
                        userId={userId}
                        onSelectSkill={this.onSelectSkill}
                        onRemoveClick={() => {}}
                        onNotesClick={() => {}}
                        onRemoveSkill={() => {}}
                        currentSkillId={currentSkillId || 0}
                        translation={translation}
                    />
                )}

                <Feedback
                    feedbacks={selectedJob?.feedback}
                    currentFeedbackIndex={currentFeedbackIndex}
                    setParentStateValue={(key, value) => this.setState({ [key]: value })}
                    animating={animating}
                />
            </>
        ) : null;
    }
}

SkillsAndFeedback.propTypes = {};

export default SkillsAndFeedback;
