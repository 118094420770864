import moment from "moment";
import Heap from "collections/heap";
import _ from "lodash";

export const getYearInMs = () => moment.duration(1, "years").asMilliseconds();

export const getCareerStartedDate = (firstJob) => {
    const yearInMs = getYearInMs();
    return !firstJob
        ? new Date().getTime() - yearInMs
        : getMsFromTimeString(firstJob.startedAt) - yearInMs;
};

export const getFirstJob = (timelineData) =>
    timelineData.reduce(
        (acc, curr) => (acc.startedAt < curr.startedAt ? acc : curr),
        timelineData[0]
    );

export const splitJobsOnParallelsLines = (timelineData) => {
    const result = [];
    const endHeap = new Heap([], null, (a, b) => b[0] - a[0]);
    const freeLinesHeap = new Heap([], null, (a, b) => b - a);

    const sortedJobs = timelineData.sort(
        (a, b) => getMsFromTimeString(a.startedAt) - getMsFromTimeString(b.startedAt)
    );

    let numberOfLines = 0;

    sortedJobs.forEach((job) => {
        const date = getMsFromTimeString(job.startedAt);

        while (endHeap.length && endHeap.peek()[0] < date) {
            const freeLine = endHeap.peek()[1];
            endHeap.pop();
            freeLinesHeap.push(freeLine);
        }

        if (!freeLinesHeap.length) {
            const newline = [];
            newline.push(job);
            result.push(newline);
            endHeap.push([getMsFromTimeString(job.endedAt), numberOfLines]);
            numberOfLines++;
        } else {
            const freeLineIndex = freeLinesHeap.peek();
            freeLinesHeap.pop();
            result[freeLineIndex].push(job);
            endHeap.push([getMsFromTimeString(job.endedAt), freeLineIndex]);
        }
    });
    return result;
};

export const getYearFromDate = (date) => {
    return Number(moment(date).format("YYYY"));
};

export const getShareOfTotal = (date, timeStarted, yearInMs, duration, skillMod) =>
    Math.round(((date - timeStarted + (skillMod ? 0 : yearInMs)) * 100) / duration);

export const getMsFromTimeString = (string) => new Date(string).getTime();

export const isArrayEqual = (x, y) => _(x).xorWith(y, _.isEqual).isEmpty();
