import React, { Component } from 'react';

class QuestionMark extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M12,3c-4.97,0-9,4.03-9,9c0,4.97,4.03,9,9,9s9-4.03,9-9C21,7.03,16.97,3,12,3z M12.97,18.21h-1.63v-1.64h1.63V18.21z
                    M15.55,10.9c-0.24,0.44-0.71,0.96-1.41,1.58c-0.46,0.42-0.76,0.73-0.91,0.92c-0.15,0.2-0.25,0.43-0.31,0.67
                    c-0.07,0.26-0.11,0.68-0.12,1.25h-1.37c-0.01-0.19-0.01-0.33-0.01-0.43c0-0.56,0.08-1.06,0.24-1.46c0.12-0.31,0.3-0.62,0.56-0.93
                    c0.19-0.24,0.54-0.56,1.03-1c0.5-0.45,0.82-0.8,0.97-1.06c0.15-0.26,0.23-0.54,0.23-0.86c0-0.56-0.21-1.05-0.65-1.47
                    c-0.44-0.43-0.97-0.64-1.6-0.64c-0.62,0-1.13,0.19-1.53,0.59c-0.42,0.38-0.67,0.98-0.81,1.79L8.38,9.67
                    c0.13-1.09,0.53-1.94,1.19-2.51c0.65-0.59,1.52-0.88,2.6-0.88c1.14,0,2.05,0.32,2.72,0.93c0.69,0.63,1.02,1.38,1.02,2.27
                    C15.91,10,15.79,10.47,15.55,10.9z"
                />
            </svg>

        );
    }
}

export default QuestionMark;
