import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalFooter, ModalBody, ModalHeader, Input } from "reactstrap";
import _ from "lodash";

class SendFeedbackModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			feedback: ""
		}
	}
	render() {
		const { onCloseModal, isOpen, translation} = this.props;

		const { feedback } = this.state;

		return (
			<Modal
				centered
				isOpen={isOpen}
				toggle={onCloseModal}
			>
				<ModalHeader>
					{_.get(translation, "invitations_management_page.send_feedback")}
				</ModalHeader>
				<ModalBody>
					<Input
						type="textarea"
						value={feedback}
						onChange={(e) => this.setState({ feedback : e.target.value })}
						placeholder={_.get(translation, "invitations_management_page.feedback")}
					/>
				</ModalBody>
				<ModalFooter className="content-space-between">
					<Button
						color="secondary"
						onClick={onCloseModal}
					>
						{_.get(translation, "actions.cancel_button")}
					</Button>
					<Button
						color="primary"
						onClick={() => {}}
					>
						{_.get(translation, "invitations_management_page.send_label")}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

SendFeedbackModal.propTypes = {
	translation: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onCloseModal: PropTypes.func.isRequired,
};

export default SendFeedbackModal;
