import React from "react";
import _ from "lodash";
import Slider from "rc-slider";
import PropTypes from "prop-types";
import moment from "moment";
import { Popover, PopoverBody } from "reactstrap";
import { Timeline } from "../Timeline/Timeline";
import {
    getCareerStartedDate,
    getFirstJob,
    getShareOfTotal,
    getYearInMs,
    splitJobsOnParallelsLines,
    getMsFromTimeString,
    isArrayEqual,
} from "../../utils";

class JobTimeline extends Timeline {
    constructor(props) {
        super(props);
        this.sliderContainerRef = React.createRef();

        this.state = {
            isSlidersMounted: false,
            isPopoverOpen: false,
            hoveredJob: null,
            hoveredJobData: { left: 0, top: 0, lineNumber: 0 },
        };
    }

    componentDidMount() {
        const { setParentStateValue, translation, pastJobs } = this.props;
        if (this.sliderContainerRef.current) {
            const sliderHeight = this.sliderContainerRef.current.getBoundingClientRect().height;
            const containerNode = this.sliderContainerRef.current.parentElement.parentElement;
            const { width, height } = containerNode.getBoundingClientRect();
            const sliderContainerTopOffset = (height - sliderHeight) / 2 - 1;
            setParentStateValue("jobSliderContainerTopOffset", sliderContainerTopOffset);
            setParentStateValue("jobTimelineSliderHeight", sliderHeight);
            setParentStateValue("jobTimelineContainerSizes", { width, height });
            setParentStateValue("jobTimelineHandlerTop", sliderContainerTopOffset);
        }
        if (pastJobs?.length) {
            const { marks, careerDuration, careerStarted } = this.getTimelineMarks(translation);
            setParentStateValue("jobTimelineData", { marks, careerDuration, careerStarted });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isArrayEqual(prevProps.pastJobs, this.props.pastJobs)) {
            const { marks, careerDuration, careerStarted } = this.getTimelineMarks(
                this.props.translation
            );
            this.props.setParentStateValue("jobTimelineData", {
                marks,
                careerDuration,
                careerStarted,
            });
        }
        if (this.sliderContainerRef.current.childNodes.length && !this.state.isSlidersMounted) {
            this.sliderContainerRef.current.childNodes.forEach((slider) => {
                slider.onmouseover = (e) => this.getHoveredJob(e, slider);
                slider.onmouseout = () => this.setState({ hoveredJob: null });
            });
            this.setState({ isSlidersMounted: true });
        }
        if (!prevState.hoveredJob && this.state.hoveredJob) {
            this.setState({ isPopoverOpen: true });
        } else if (prevState.hoveredJob && !this.state.hoveredJob) {
            this.setState({ isPopoverOpen: false });
        }
    }

    getHoveredJob(e, DOMElement) {
        if (
            e.target.className === "rc-slider-handle" ||
            e.target.className === "slider-tooltip-inner"
        ) {
            return false;
        }
        const {
            jobTimelineSliderHeight,
            jobTimelineData: { marks },
        } = this.props;
        const percent = Math.abs((e.offsetY / jobTimelineSliderHeight) * 100 - 100);
        const marksKeys = _.chunk(Object.keys(marks[DOMElement.dataset.orderNumber]), 2);
        const hoveredJobMarks = marksKeys.find(
            (jobKeysPair) => jobKeysPair[0] <= percent && jobKeysPair[1] >= percent
        );
        if (hoveredJobMarks) {
            this.setState({
                hoveredJob: marks[DOMElement.dataset.orderNumber][hoveredJobMarks[0]].job,
            });
            this.setState({
                hoveredJobData: {
                    left: e.offsetX,
                    top: e.offsetY,
                    lineNumber: DOMElement.dataset.orderNumber,
                },
            });
        } else if (!hoveredJobMarks && this.state.hoveredJob) {
            this.setState({ hoveredJob: null });
        }
    }

    getTimelineMarks() {
        const { pastJobs } = this.props;
        const yearInMs = getYearInMs();
        const firstJob = getFirstJob(pastJobs);
        const careerDuration = pastJobs?.length
            ? Date.now() - getMsFromTimeString(firstJob.startedAt) + yearInMs
            : yearInMs;
        const jobsSplittedOnParallelsLines = splitJobsOnParallelsLines(pastJobs);

        const marks = jobsSplittedOnParallelsLines.map((line, index) => {
            const currentLineMarks = {};

            line.forEach((job) => {
                const currentJobStartedTimeShareOfTotal = getShareOfTotal(
                    getMsFromTimeString(job.startedAt),
                    getMsFromTimeString(firstJob.startedAt),
                    yearInMs,
                    careerDuration
                );
                const currentJobEndedAtShareOfTotal = getShareOfTotal(
                    getMsFromTimeString(job.endedAt) || new Date().getTime(),
                    getMsFromTimeString(firstJob.startedAt),
                    yearInMs,
                    careerDuration
                );
                currentLineMarks[currentJobStartedTimeShareOfTotal] = {
                    label: "",
                    job,
                };
                currentLineMarks[currentJobEndedAtShareOfTotal] = { label: "" };
            });
            return currentLineMarks;
        });

        return { marks, careerDuration, careerStarted: getCareerStartedDate(firstJob) };
    }

    generateGradientForTimeline(marks, lineIndex) {
        const baseColor = "rgb(204, 204, 204)";
        const colors = ["rgb(0, 0, 0)", "rgb(245, 197, 44)", "rgb(128, 0, 128)"];

        const marksChunks = _.chunk(marks, 2);
        const startOfString = `linear-gradient(0deg, ${baseColor} 0%,`;
        const endOfString = ` ${baseColor} 100%`;
        let baseString = "";
        marksChunks.forEach((chunk, index) => {
            const chunkStartDate = chunk[0];
            const chunkEndDate = chunk[1];
            baseString += `${baseColor} ${chunkStartDate}%, ${colors[lineIndex]} ${chunkStartDate}%, ${colors[lineIndex]} ${chunkEndDate}%, ${baseColor} ${chunkEndDate}%,`;
        });
        return startOfString + baseString + endOfString;
    }

    render() {
        const {
            translation,
            selectedDateOnTimeline,
            jobTimelineContainerSizes: { width },
            jobTimelineData: { marks, careerDuration, careerStarted },
        } = this.props;
        const {
            hoveredJobData: { top, left, lineNumber },
            hoveredJob,
            isPopoverOpen,
        } = this.state;
        return (
            <div className="job-timeline-container">
                <div className="label">{_.get(translation, "skills_journey_page.now")}</div>
                <div
                    className="multiple-lines-container"
                    ref={this.sliderContainerRef}
                    id="multiple-lines-container"
                >
                    {marks &&
                        marks.map((lineMarks, index) => (
                            <div
                                id={`id-${index}`}
                                key={index}
                                style={{ position: "relative", height: "inherit" }}
                                data-order-number={index}
                            >
                                <Slider
                                    marks={lineMarks}
                                    style={{ margin: "0 1rem" }}
                                    onChange={(value) =>
                                        this.onChangeHandler(value, careerDuration, careerStarted)
                                    }
                                    handle={
                                        index === 0
                                            ? (props) =>
                                                  this.customHandle(
                                                      props,
                                                      careerDuration,
                                                      careerStarted
                                                  )
                                            : () => {}
                                    }
                                    step={0.0001}
                                    vertical
                                    dotStyle={{ borderColor: "#40e0d0" }}
                                    railStyle={{
                                        background: this.generateGradientForTimeline(
                                            Object.keys(lineMarks),
                                            index
                                        ),
                                    }}
                                    trackStyle={{ background: "transparent" }}
                                    handleStyle={
                                        index === 0
                                            ? {
                                                  height: "2px",
                                                  backgroundColor: "#acabab",
                                                  border: "none",
                                                  borderRadius: "0",
                                                  transform: "none",
                                                  left: "-22px",
                                                  width,
                                              }
                                            : { display: "none" }
                                    }
                                    value={this.getValueFromSelectedDate(
                                        selectedDateOnTimeline,
                                        careerDuration,
                                        careerStarted
                                    )}
                                />
                                <div className="arrow" />
                                <div
                                    style={{ position: "absolute", top, left: left + 30 }}
                                    id={`popover-element-${index}`}
                                />
                                {hoveredJob && (
                                    <Popover
                                        delay={150}
                                        isOpen={isPopoverOpen}
                                        placement="right"
                                        target={`popover-element-${lineNumber}`}
                                        trigger="legacy"
                                    >
                                        <PopoverBody>
                                            <h6
                                                style={{ textAlign: "center" }}
                                            >{`${hoveredJob.jobTitle} - ${hoveredJob.employer}`}</h6>
                                            <div>
                                                {`${moment(hoveredJob.startedAt).format(
                                                    "DD.MM.YYYY"
                                                )} - ${moment(
                                                    hoveredJob.endedAt || new Date().getTime()
                                                ).format("DD.MM.YYYY")}`}
                                            </div>
                                        </PopoverBody>
                                    </Popover>
                                )}
                            </div>
                        ))}
                </div>
                <div className="label">
                    {_.get(translation, "skills_journey_page.main_degree_label")}
                </div>
            </div>
        );
    }
}

JobTimeline.propTypes = {
    setParentStateValue: PropTypes.func.isRequired,
    translation: PropTypes.object.isRequired,
    jobTimelineSliderHeight: PropTypes.number.isRequired,
    jobTimelineData: PropTypes.object.isRequired,
    selectedDateOnTimeline: PropTypes.number.isRequired,
};

export default JobTimeline;
