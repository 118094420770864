import React, { Component } from "react";

class JobPassport extends Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <defs></defs>
                <title>n</title>
                <path
                    fill="#1d75b8"
                    d="M68.82885,44.85358a3.17425,3.17425,0,0,0-1.71062-2.3037c-2.97446-1.48321-9.50159-3.67378-9.50159-3.67378V36.72095l.1817-.1371A6.23589,6.23589,0,0,0,60.166,32.621l.03657-.22971h.1771A2.40885,2.40885,0,0,0,62.60912,30.892a2.62325,2.62325,0,0,0,.32795-1.27069,2.41341,2.41341,0,0,0-.17141-.8936,1.24629,1.24629,0,0,0-.48335-.777L61.6801,27.585l.14969-.65362c1.08785-4.74222-2.58594-9.01366-7.519-9.13137-.12-.00228-.23883-.00342-.35654-.00114-.1177-.00228-.23653-.00114-.35652.00114-4.93305.11772-8.60683,4.38915-7.519,9.13137l.14968.65362-.6022.36567a1.24637,1.24637,0,0,0-.48336.777,2.41341,2.41341,0,0,0-.17141.8936,2.62326,2.62326,0,0,0,.328,1.27069,2.40883,2.40883,0,0,0,2.22943,1.49921h.1771l.03657.22971a6.23589,6.23589,0,0,0,2.36768,3.96289l.1817.1371v2.15516s-6.52712,2.19056-9.50159,3.67378a3.17424,3.17424,0,0,0-1.71063,2.3037,69.11442,69.11442,0,0,0-.59992,7.99206h30.949A69.111,69.111,0,0,0,68.82885,44.85358Z"
                />
                <path
                    fill="#2d3e50"
                    d="M96.85481,93.99553v22.69778a.46768.46768,0,0,1-.46631.46631H11.51963a.46769.46769,0,0,1-.46631-.46631V11.30669a.46767.46767,0,0,1,.46631-.46631H96.3885a.46766.46766,0,0,1,.46631.46631V43.98585l7.83871-10.63422c.1772-.24016.36139-.47565.55491-.70414V11.30669A8.871,8.871,0,0,0,96.3885,2.44675H11.51963a8.871,8.871,0,0,0-8.85994,8.85994V116.69331a8.871,8.871,0,0,0,8.85994,8.85994H96.3885a8.871,8.871,0,0,0,8.85994-8.85994V82.916l-7.21852,9.79256A8.5801,8.5801,0,0,1,96.85481,93.99553Z"
                />
                <path
                    fill="#1d75b8"
                    d="M66.54466,93.844H44.39482a1.86525,1.86525,0,0,0,0,3.7305H66.54466a1.86525,1.86525,0,0,0,0-3.7305Z"
                />
                <path
                    fill="#1d75b8"
                    d="M66.54466,80.78725H23.41075a1.86525,1.86525,0,1,0,0,3.7305h43.1339a1.86525,1.86525,0,0,0,0-3.7305Z"
                />
                <path
                    fill="#1d75b8"
                    d="M66.54466,67.7305H23.41075a1.86525,1.86525,0,1,0,0,3.7305h43.1339a1.86525,1.86525,0,0,0,0-3.7305Z"
                />
                <path
                    fill="#1d75b8"
                    d="M121.83419,35.26255a8.63216,8.63216,0,0,0-12.07261,1.825l-30.69764,41.64a2.07513,2.07513,0,0,0-.35057.7653L75.086,95.4959a2.05533,2.05533,0,0,0,3.03179,2.23462l14.21645-8.197a2.07258,2.07258,0,0,0,.6275-.56046l30.69764-41.64A8.6429,8.6429,0,0,0,121.83419,35.26255ZM81.37346,91.10891l-.87684-.8864L82.028,83.46544l5.52851,4.07616Z"
                />
            </svg>
        );
    }
}

export default JobPassport;
