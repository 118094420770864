
import React, { Component } from 'react';

class FFM extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 512.002 512.002">
                <path fill="#FFFFFF" d="M10.001,200.77l93.964,289.19h304.073l93.964-289.19l-246-178.729 M256.001,158.008l116.687,84.778
                    l-44.57,137.174H183.884l-44.57-137.174"/>
                <polygon fill="#2BBA68" points="256.001,158.008 372.687,242.787 502.001,200.77 256.001,22.041 " />
                <polygon fill="#F4B831" points="372.687,242.787 328.117,379.961 408.037,489.96 502.001,200.77 " />
                <polygon fill="#12ADDD" points="256.001,22.041 10.001,200.77 139.314,242.787 256.001,158.008 " />
                <polygon fill="#F25353" points="10.001,200.77 103.964,489.96 183.884,379.961 139.314,242.787 " />
                <path d="M507.879,192.68l-246-178.729c-3.505-2.546-8.251-2.546-11.756,0l-246,178.729c-3.505,2.546-4.971,7.06-3.633,11.181
                    l93.964,289.19c1.339,4.12,5.179,6.91,9.511,6.91h304.072c4.332,0,8.172-2.79,9.511-6.91l93.964-289.19
                    C512.85,199.74,511.384,195.226,507.879,192.68z M374.443,231.701l-108.442-78.788V41.667l214.244,155.658L374.443,231.701z
                    M191.15,369.961l-40.08-123.354l21.543-15.652c4.468-3.247,5.458-9.5,2.212-13.968c-3.246-4.467-9.499-5.458-13.968-2.212
                    l-23.299,16.928L31.757,197.324L246.001,41.667v111.246l-21.926,15.93c-4.468,3.247-5.458,9.5-2.212,13.968
                    c3.247,4.469,9.5,5.458,13.968,2.212l20.17-14.655l104.931,76.237l-40.08,123.354H191.15V369.961z M25.576,216.346l105.802,34.377
                    l41.421,127.482l-65.389,89.999L25.576,216.346z M404.591,468.204l-22.467-30.923c-3.246-4.467-9.499-5.458-13.968-2.212
                    c-4.468,3.247-5.458,9.5-2.212,13.968l22.468,30.924h-264.82l65.389-90h139.137c4.332,0,8.172-2.79,9.511-6.91l42.996-132.328
                    l105.802-34.377L404.591,468.204z"/>
                <path d="M353.291,404.609c-2.63,0-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.43-2.93,7.07c0,2.63,1.07,5.21,2.93,7.07
                    c1.86,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07c0-2.64-1.07-5.21-2.93-7.07
                    C358.501,405.68,355.921,404.609,353.291,404.609z"/>
            </svg>
        );
    }
}

export default FFM;
