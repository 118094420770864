
import React, { Component } from 'react';

class Microphone extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 57 57">
                <path fill="#556080" d="M34.1,55.2H22.9c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h4.7v-4.7c-7.8-0.5-14.1-7-14.1-15V28
                    c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9v5.6c0,7.2,5.9,13.1,13.1,13.1s13.1-5.9,13.1-13.1V28c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9
                    v5.6c0,8-6.2,14.5-14.1,15v4.7h4.7c0.5,0,0.9,0.4,0.9,0.9S34.6,55.2,34.1,55.2z"/>
                <path fill="#005fa2" d="M37.9,33.7V11.2c0-5.2-4.2-9.4-9.4-9.4V43C33.7,43,37.9,38.8,37.9,33.7z" />
                <path fill="#006ebb" d="M19.1,11.2v22.5c0,5.2,4.2,9.4,9.4,9.4V1.8C23.3,1.8,19.1,6,19.1,11.2z" />
            </svg>
        );
    }
}

export default Microphone;
