import { connect } from 'react-redux';
import ContinentAndCountryFormGroups from './ContinentAndCountryFormGroups';
import { getTranslation } from "../../../../reducers/common/localization";
import { getLocationData } from '../../../../reducers/common/location';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        locationData: getLocationData(state)
    };
};

export default connect(mapStateToProps)(ContinentAndCountryFormGroups);
