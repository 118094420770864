import { createAction } from 'redux-actions';
import { Api } from '../../api/api';
import { getSelectedLanguage } from "../common/localization";
import { setRequestStatusSubmit, onSetRequestStatusSuccess, setRequestStatusFailed } from '../common/requestStatus';
import { REQUEST_SUCCESS_MESSAGE } from '../../constants/requestStatusConstants';

//- Actions
export const onFetchRegisterApplicant = createAction(
    'FETCH_REGISTER_APPLICANT',
    (language, data) => Api.registerApplicant(language, data),
    () => ({
        apiCall: true,
        onSuccess: onSetRequestStatusSuccess(REQUEST_SUCCESS_MESSAGE.NO_MESSAGE),
        onFail: setRequestStatusFailed,
        ignoreUnauthorized: true,
        ignoreForbidden: true
    })
);

export const fetchRegisterApplicant = (data) => (dispatch, getState) => {
    dispatch(setRequestStatusSubmit());
    dispatch(onFetchRegisterApplicant(getSelectedLanguage(getState()), data));
};

export const onFetchRegisterCompany = createAction(
    'FETCH_REGISTER_COMPANY_BY_ANCHOR',
    (data) => Api.registerOrgByAnchor(data),
    () => ({
        apiCall: true,
        onSuccess: onSetRequestStatusSuccess(REQUEST_SUCCESS_MESSAGE.NO_MESSAGE),
        onFail: setRequestStatusFailed,
        ignoreUnauthorized: true,
        ignoreForbidden: true
    })
);

export const fetchRegisterCompany = (data) => (dispatch) => {
    dispatch(setRequestStatusSubmit());
    dispatch(onFetchRegisterCompany(data));
};
