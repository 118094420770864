import React, { Component } from "react";
import { Button } from "reactstrap";
import _ from "lodash";
import "./JobsLifecycleLine.scss";
import { Link, withRouter } from "react-router-dom";
import { ROUTES, tableConfig, TABLE_STATUSES } from "../../../../constants/constants";

const lifeCycleMilestones = [
    { title: "Created", key: "created", main: true },
    { title: "Completed", key: "completed" },
    { title: "Approved", key: "approved" },
    { title: "Published", key: "published", main: true },
    { title: "Pre-Filtering", key: "pre_filtering" },
    { title: "Deep Screening", key: "deep_screening" },
    { title: "Invitations", key: "invitations" },
    { title: "Closed", key: "closed", main: true },
];
class JobsLifecycleLine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetchedJobs: [],
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { jobs } = this.props;

        if (prevProps.jobs !== jobs) this.setState({ fetchedJobs: jobs });
    }

    render() {
        const { translation, setActiveTablesStatuses } = this.props;
        return (
            <div className="jobs-lifecycle-line-container">
                <h5>Job Life Cycle</h5>
                <div>
                    <Button color="primary" tag={Link} to={ROUTES.POST_JOB}>
                        {_.get(translation, "open_jobs_page.create_new_job_button")}
                    </Button>
                    <div className="jobs-lifecycle-line">
                        {lifeCycleMilestones.map(({ title, key, main }, index) => {
                            let jobFilteredByStatus = this.state.fetchedJobs.filter(
                                ({ status }) => key.toLowerCase() === status.toLowerCase()
                            );

                            if (key === TABLE_STATUSES.PUBLISHED) {
                                jobFilteredByStatus = this.state.fetchedJobs.filter(({ status }) =>
                                    tableConfig.lifecycleStatuses[
                                        TABLE_STATUSES.PUBLISHED
                                    ].includes(status.toLowerCase())
                                );
                            }

                            return (
                                <React.Fragment key={index}>
                                    {index !== 0 && (
                                        <div className="arrow-container">
                                            <div className="jobs-milestone divider" />
                                            <div className="arrow right" />
                                        </div>
                                    )}
                                    <small
                                        className={`jobs-milestone card ${main ? "main" : ""}`}
                                        onClick={() => setActiveTablesStatuses(title)}
                                    >
                                        {`${title} (${jobFilteredByStatus.length})`}
                                    </small>
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

JobsLifecycleLine.propTypes = {};

export default withRouter(JobsLifecycleLine);
