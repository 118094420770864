import React, { Component } from "react";
import PropTypes from "prop-types";
import "./SkillNotesModal.scss";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Input,
    ListGroup,
    ListGroupItem,
    FormGroup,
    Label,
} from "reactstrap";
import _ from "lodash";
import Icon from "../../icon/Icon";
import { v4 as uuidv4 } from "uuid";

class SkillNotesModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: "",
            id: "",
        };
    }

    render() {
        if (!this.props.skillTitle) {
            return null;
        }
        return (
            <Modal isOpen={this.props.isOpen} className="skill-notes-modal" toggle={this.onClose}>
                <ModalHeader toggle={this.onClose}>
                    {_.replace(
                        _.get(
                            this.props.translation,
                            "skills_management.notes_by_me_modal_title_label"
                        ),
                        "{skillName}",
                        this.props.skillTitle
                    )}
                    <Icon type="notes" number={this.props.notes ? this.props.notes.length : 0} />
                </ModalHeader>
                <ModalBody>{this.renderModalBody()}</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.onClose}>
                        {_.get(this.props.translation, "actions.ok_button")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalBody() {
        const { text } = this.state;
        return (
            <div>
                <div>
                    <FormGroup>
                        <Label>
                            {_.replace(
                                _.get(
                                    this.props.translation,
                                    "skills_management.write_new_note_by_me_label"
                                ),
                                "{skillName}",
                                this.props.skillTitle
                            )}
                        </Label>
                        <Input
                            type="textarea"
                            value={text}
                            onChange={({ target }) => this.onChangeNote(target)}
                            placeholder={_.get(
                                this.props.translation,
                                "skills_management.note_by_me_input_placeholder_label"
                            )}
                            name="text"
                        />
                    </FormGroup>
                    <div className="button-container">
                        <Button color="secondary" onClick={this.onCancel}>
                            {_.get(this.props.translation, "actions.cancel_button")}
                        </Button>
                        <Button color="primary" onClick={this.onAddNote}>
                            {_.get(this.props.translation, "skills_management.add_note_button")}
                        </Button>
                    </div>
                </div>
                {this.props.notes && this.props.notes.length > 0 ? (
                    <ListGroup flush className="notes-container">
                        {this.renderNotes()}
                    </ListGroup>
                ) : (
                    <h6>{_.get(this.props.translation, "skills_management.no_notes_label")}</h6>
                )}
            </div>
        );
    }

    renderNotes = () => {
        let notes = _.orderBy(this.props.notes, ["date"], ["desc"]);
        return _.map(notes, (note) => {
            return (
                <ListGroupItem key={note.tempId} className="note">
                    <div>
                        <div className="note-date">{note.date}</div>
                        <div>{note.text}</div>
                    </div>
                    <Button color="danger" onClick={() => this.props.onRemoveNote(note.tempId)}>
                        Delete
                    </Button>
                </ListGroupItem>
            );
        });
    };

    onChangeNote = ({ name, value }) => {
        this.setState({ [name]: value });
    };

    onAddNote = () => {
        const { text } = this.state;
        if (text) {
            this.setState({ text: "" });
            this.props.onAddNewNote({ text, id: uuidv4() });
        }
    };

    onRemoveNote = (id) => {
        this.props.onRemoveNote({ id });
    };

    onClose = () => {
        this.props.onCancel();
    };

    onCancel = () => {
        this.setState({ text: "" });
        this.props.onCancel();
    };
}

SkillNotesModal.propTypes = {
    translation: PropTypes.object.isRequired,
    skillTitle: PropTypes.string,
    notes: PropTypes.array,
    isOpen: PropTypes.bool.isRequired,
    onAddNewNote: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default SkillNotesModal;
