import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, Table } from "reactstrap";
import { withRouter } from "react-router-dom";
import Icon from "../../../common/icon/Icon";
import {
    tableConfig,
    TABLE_STATUSES,
    TABLE_STATUSES_LABELS,
} from "../../../../constants/constants";
import _ from "lodash";
import "./JobsTable.scss";
import { ROUTES, USER_SUB_ROLE } from "../../../../constants/constants";
import ButtonWithTooltip from "../../../common/ButtonWithTooltip/ButtonWithTooltip";
import JobEditModal from "./JobEditModal.container";

class JobsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetchedJobs: [],
            openedToolTipId: "",
        };
    }

    componentDidMount() {
        if (!!this.props.jobs) this.setState({ fetchedJobs: this.props.jobs });
    }

    componentDidUpdate(prevProps, prevState) {
        const { jobs } = this.props;

        if (prevProps.jobs !== jobs) this.setState({ fetchedJobs: jobs });
    }

    renderTableCell(job, field, status, history) {
        const {
            userData: { subRoles },
        } = this.props;
        let value = job[field];

        if (field === "status") {
            value = TABLE_STATUSES_LABELS[job[field].toLowerCase()];
        }

        if (field === "title") {
            return (
                <span
                    className="jobs-table__title-link"
                    onClick={() => history.push(ROUTES.POST_JOB, { job })}
                >
                    {value}
                </span>
            );
        }

        if (typeof job[field] === "boolean") {
            value = <Icon type={job[field] ? "checkbox" : "clear"} color="black" size="xs" />;
        } else if (field === "nextStep") {
            switch (job.status.toLowerCase()) {
                case TABLE_STATUSES.COMPLETED:
                    if (subRoles.includes(USER_SUB_ROLE.BO)) {
                        const isApprovedByBo = job.approvedByBo;
                        value = (
                            <Button
                                disabled={isApprovedByBo}
                                color={isApprovedByBo ? "secondary" : "success"}
                                onClick={() => this.props.approveByBo(job.id)}
                            >
                                {_.get(
                                    this.props.translation,
                                    `jobs_page.${
                                        isApprovedByBo ? "approvedByBo_label" : "approve_as_BO"
                                    }`
                                )}
                            </Button>
                        );
                    } else if (subRoles.includes(USER_SUB_ROLE.HM)) {
                        const isApprovedByHRM = job.approvedByHrm;
                        value = (
                            <Button
                                disabled={isApprovedByHRM}
                                color={isApprovedByHRM ? "secondary" : "success"}
                                onClick={() => this.props.approveByHrm(job.id)}
                            >
                                {_.get(
                                    this.props.translation,
                                    `jobs_page.${
                                        isApprovedByHRM ? "approvedByHrm_label" : "approve_as_HRM"
                                    }`
                                )}
                            </Button>
                        );
                    }
                    break;
                case TABLE_STATUSES.APPROVED:
                    if (subRoles.includes(USER_SUB_ROLE.BO)) {
                        value = (
                            <Button color="success" onClick={() => this.props.publishJob(job.id)}>
                                {_.get(this.props.translation, "jobs_page.publish_label")}
                            </Button>
                        );
                    }
                    break;
                case TABLE_STATUSES.PRE_FILTERING:
                    if (
                        subRoles.includes(USER_SUB_ROLE.BO)
                        // && this.props.userData.alias.firstName === job.bo
                    ) {
                        value = (
                            <ButtonWithTooltip
                                id={`Tooltip-${job.id}`}
                                buttonTitle={_.get(
                                    this.props.translation,
                                    "jobs_page.end_pre_filtering_label"
                                )}
                                onClick={() => this.props.moveJobToDeepScreening(job.id)}
                                buttonTooltip={`Clicking will move this job to the Deep Screening phase, conducted by this Job’s Business Owner: ${job.bo}`}
                            />
                        );
                    }
                    break;
                case TABLE_STATUSES.DEEP_SCREENING:
                    if (subRoles.includes(USER_SUB_ROLE.BO)) {
                        value = (
                            <ButtonWithTooltip
                                id={`Tooltip-${job.id}`}
                                buttonTitle={_.get(
                                    this.props.translation,
                                    "jobs_page.end_deep_screening_label"
                                )}
                                onClick={() => {
                                    this.props.moveJobToInvitatons(job.id);
                                }}
                                buttonTooltip={_.get(
                                    this.props.translation,
                                    "jobs_page.end_deep_screening_sublabel"
                                )}
                            />
                        );
                    }

                    break;
                case TABLE_STATUSES.CLOSED:
                    value = (
                        <Button disabled>
                            {_.get(this.props.translation, "job_page.closed_label")}
                        </Button>
                    );
                    break;

                default:
                    return value;
            }
        }
        return value;
    }

    filterJobsByActiveTableStatuses(statuses) {
        if (statuses.length === 0) {
            return this.state.fetchedJobs;
        } else {
            return this.state.fetchedJobs.filter((job) =>
                statuses.includes(job.status.toLowerCase())
            );
        }
    }

    shouldRenderTableWithStatus(status, filteredJobs) {
        if (!this.props.activeTablesStatuses.length) {
            return true;
        } else {
            const stateStatuses = tableConfig.lifecycleStatuses[status];
            return filteredJobs.find((job) => stateStatuses.includes(job.status.toLowerCase()));
        }
    }

    render() {
        const { activeTablesStatuses, translation, history } = this.props;
        const filteredJobs = this.filterJobsByActiveTableStatuses(activeTablesStatuses);
        return (
            <>
                {tableConfig.defaultTables.map((tableStatus, i) => {
                    return (
                        this.shouldRenderTableWithStatus(tableStatus, filteredJobs) && (
                            <Col style={{ margin: "1rem 0" }} key={i}>
                                <h6 style={{ fontWeight: "bold", color: "#75b444" }}>
                                    {_.get(translation, `jobs_page.${tableStatus}_label`)}
                                </h6>
                                <Table>
                                    <thead>
                                        <tr>
                                            {tableConfig[tableStatus].map((columnName, i) => (
                                                <th key={i}>
                                                    {_.get(
                                                        translation,
                                                        `jobs_page.${columnName}_label`
                                                    )}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredJobs
                                            .filter((job) => {
                                                return tableConfig.lifecycleStatuses[
                                                    tableStatus
                                                ].includes(job.status.toLowerCase());
                                            })
                                            .map((job, i) => {
                                                const isInvitations =
                                                    job.status.toLowerCase() ===
                                                    "Invitations".toLowerCase();
                                                return (
                                                    <tr key={i}>
                                                        {tableConfig[tableStatus].map(
                                                            (field, i) => {
                                                                const shouldBeRenderedAsLink =
                                                                    isInvitations &&
                                                                    field === "status";
                                                                return (
                                                                    <td key={i}>
                                                                        <small
                                                                            onClick={
                                                                                shouldBeRenderedAsLink
                                                                                    ? () => {
                                                                                          history.push(
                                                                                              ROUTES.INVITATIONS_MANAGEMENT,
                                                                                              {
                                                                                                  job,
                                                                                              }
                                                                                          );
                                                                                      }
                                                                                    : () => {}
                                                                            }
                                                                            className={
                                                                                shouldBeRenderedAsLink
                                                                                    ? "jobs-table__title-link"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            {this.renderTableCell(
                                                                                job,
                                                                                field,
                                                                                tableStatus,
                                                                                history
                                                                            )}
                                                                        </small>
                                                                    </td>
                                                                );
                                                            }
                                                        )}
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                                <JobEditModal />
                            </Col>
                        )
                    );
                })}
            </>
        );
    }
}

JobsTable.propTypes = {
    history: PropTypes.object.isRequired,
    translation: PropTypes.object.isRequired,
    activeTablesStatuses: PropTypes.array.isRequired,
};

export default withRouter(JobsTable);
