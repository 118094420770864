import React, { Component } from "react";

class JobCompass extends Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 58.67">
                <defs></defs>
                <title>Asset 12</title>
                <g>
                    <g>
                        <path
                            fill="#2d3e50"
                            d="M63.43,30.36,58.1,23.55a2.76,2.76,0,0,0-4.2,0l-5.33,6.81a2.66,2.66,0,1,0,4.2,3.28l.19-.25a24,24,0,1,1-6.49-20.86A2.67,2.67,0,0,0,50.28,8.8a29.35,29.35,0,1,0,8.18,23.86l.77,1a2.66,2.66,0,1,0,4.2-3.28Z"
                        />
                        <path
                            fill="#1d75b8"
                            d="M29.33,10.67a2.67,2.67,0,0,0-2.66,2.66v16a2.2,2.2,0,0,0,.08.62,1.9,1.9,0,0,0,.21.56,1.66,1.66,0,0,0,.29.48s.06.08.08.1a2.11,2.11,0,0,0,.38.35l0,0,.11.08L32,34.67l6.4,4.8A2.59,2.59,0,0,0,40,40a2.67,2.67,0,0,0,1.6-4.8l-.85-.64L37.33,32l-3.54-2.67L32,28V13.33A2.67,2.67,0,0,0,29.33,10.67Z"
                        />
                    </g>
                </g>
            </svg>
        );
    }
}

export default JobCompass;
