import { connect } from 'react-redux';
import AlternativeNowBlackListModal from './AlternativeNowBlackListModal';
import { getTranslation } from '../../../../reducers/common/localization';
import { getActiveModalType, hideModal, MODAL_ALTERNATIVE_NOW_BLACK_LIST } from '../../../../reducers/common/activeModal';
import { getBlackListData } from '../../../../reducers/jobCompass/blackList';
import { deleteProfile } from '../../../../reducers/jobCompass/jobCompassProfiles';
import { PROFILE_TYPES } from '../../../../constants/constants';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_ALTERNATIVE_NOW_BLACK_LIST,
        blackList: getBlackListData(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => dispatch(hideModal()),
        onRemoveProfileFromBlackList: (profileId) => dispatch(deleteProfile({ id: profileId, type: PROFILE_TYPES.ALTERNATIVE }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlternativeNowBlackListModal);
