import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import _ from "lodash";
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../../../../constants/constants';

class UserSubRoleMissingErrorModal extends Component {
    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.onCancel}>
                <ModalHeader toggle={this.onCancel}>
                    {_.get(this.props.translation, "user_sub_role_missing_modal.header_label")}
                </ModalHeader>
                <ModalBody>
                    <h6>{_.get(this.props.translation, "user_sub_role_missing_modal.no_access_label")}</h6>
                    <div>{_.get(this.props.translation, "user_sub_role_missing_modal.continue_label")}</div>
                </ModalBody>
                <ModalFooter className="content-center">
                    <Button color="primary" onClick={this.onCancel}>
                        {_.get(this.props.translation, "actions.ok_button")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    onCancel = () => {
        this.props.history.push(ROUTES.HOME);
        this.props.onCancel();
    }
}

UserSubRoleMissingErrorModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default withRouter(UserSubRoleMissingErrorModal);
