import { connect } from 'react-redux';
import LanguageSelect from "./LanguageSelect";
import {
    changeLanguage,
    getSelectedLanguage,
    getTranslationsList
} from "../../../../reducers/common/localization";

const mapStateToProps = state => {
    return {
        selectedLanguage: getSelectedLanguage(state),
        languageList: getTranslationsList(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLanguageSelect: (language) => {
            dispatch(changeLanguage(language));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelect);
