import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import { StorageManager } from "../../utils/storageManager";
import { UNAUTHORIZED, TOTP_CODE_REQUIRED } from "./../common/handleApiCall";
import { fetchGetUserData } from "../user/userData";

//- Actions
export const logout = createAction("LOGOUT");

export const fetchAuthorizeFailed = createAction("FETCH_AUTHORIZE_FAILED");
export const fetchAuthorizeSuccess = createAction("FETCH_AUTHORIZE_SUCCESS");
export const fetchAuthorize2FARequired = createAction("FETCH_AUTHORIZE_2FA_REQUIRED");
export const onAuthorizeSuccess = (result) => (dispatch) => {
    dispatch(fetchAuthorizeSuccess(result));
    dispatch(fetchGetUserData());
};
export const onAuthorizeFailed = (error) => (dispatch) => {
    switch (error.type) {
        case UNAUTHORIZED:
            dispatch(fetchAuthorizeFailed("INCORRECT_USERNAME_OR_PASSWORD"));
            break;

        case TOTP_CODE_REQUIRED:
            dispatch(fetchAuthorize2FARequired());
            break;

        default:
            dispatch(fetchAuthorizeFailed(error));
            break;
    }
};

export const fetchAuthorize = createAction(
    "FETCH_AUTHORIZE",
    (data) => Api.getLoginToken(data),
    () => ({
        apiCall: true,
        onSuccess: onAuthorizeSuccess,
        onFail: onAuthorizeFailed,
        ignoreUnauthorized: true,
        ignoreForbidden: true,
    })
);

export const cleanLoginError = createAction("CLEAN_LOGIN_ERROR");
export const clean2FARequiredStatus = createAction("CLEAN_2FA_REQUIRED_STATUS");

export const setIsWelcomePageRead = createAction("SET_IS_WELCOME_PAGE_READ");

//- State
const initialState = {
    isAuthorized: !!StorageManager.getLoginToken(),
    isFirstLogin: JSON.parse(StorageManager.getIsFirstLogin()),
    isWelcomePageRead: JSON.parse(StorageManager.getIsWelcomePageRead()),
    fetching: false,
    error: null,
    is2FARequired: false,
};

//- Reducers
export default handleActions(
    {
        FETCH_AUTHORIZE: (state) => {
            StorageManager.removeUserData();
            return {
                ...state,
                isAuthorized: false,
                isFirstLogin: null,
                isWelcomePageRead: null,
                error: null,
                fetching: true,
            };
        },
        FETCH_AUTHORIZE_FAILED: (state, action) => {
            StorageManager.removeLoginToken();
            return {
                ...state,
                isAuthorized: false,
                isFirstLogin: null,
                isWelcomePageRead: null,
                error: action.payload,
                fetching: false,
            };
        },
        FETCH_AUTHORIZE_SUCCESS: (state, action) => {
            StorageManager.setLoginToken(action.payload.token);

            let isFirstLogin = JSON.parse(action.payload.firstLogin);
            StorageManager.setIsFirstLogin(isFirstLogin);
            StorageManager.setIsWelcomePageRead(false);

            return {
                ...state,
                isAuthorized: true,
                isFirstLogin: isFirstLogin,
                isWelcomePageRead: false,
                error: null,
                fetching: false,
            };
        },
        LOGOUT: (state) => {
            StorageManager.removeLoginToken();
            StorageManager.removeUserData();
            return {
                ...state,
                isAuthorized: false,
                isFirstLogin: null,
                isWelcomePageRead: null,
                error: null,
            };
        },
        CLEAN_LOGIN_ERROR: (state) => {
            return { ...state, error: null };
        },
        SET_IS_WELCOME_PAGE_READ: (state, action) => {
            StorageManager.setIsWelcomePageRead(action.payload);
            return { ...state, isWelcomePageRead: action.payload };
        },
        CLEAN_2FA_REQUIRED_STATUS: (state) => {
            return { ...state, is2FARequired: false };
        },
        FETCH_AUTHORIZE_2FA_REQUIRED: (state) => {
            return { ...state, isAuthorised: false, is2FARequired: true, fetching: false };
        },
    },
    initialState
);

//- Selectors
export const getLoginState = (state) => state.login;

export const getLoginAuthorizeStatus = (state) => getLoginState(state).isAuthorized;

export const isUserFirstLogin = (state) => getLoginState(state).isFirstLogin;

export const isUserReadWelcomePage = (state) => getLoginState(state).isWelcomePageRead;

export const getLoginFetchingStatus = (state) => getLoginState(state).fetching;

export const getLoginError = (state) => getLoginState(state).error;

export const getIs2FARequired = (state) => getLoginState(state).is2FARequired;
