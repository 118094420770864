import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ActivateAccount.scss';
import _ from 'lodash';
import { Link, withRouter } from "react-router-dom";
import { Utils } from '../../../../utils/utils';
import { ROUTES, USER_ROLE } from '../../../../constants/constants';
import ActivateApplicantAccount from '../activateApplicantAccount/ActivateApplicantAccount.container';
import ActivateEmployeeAccount from '../activateEmployeeAccount/ActivateEmployeeAccount.container';

class ActivateAccount extends Component {
    constructor(props) {
        super(props);

        this.token = Utils.getParamsFromLocationSearch(props.location.search).token;
    }

    componentDidMount() {
        this.props.onGetAccountToActivateData(this.token);
    }

    componentDidUpdate() {
        if (this.props.activationSuccess) {
            this.props.history.push(ROUTES.LOGIN);
        }
    }

    render() {
        if (!this.token) {
            return <h6 className="page-message">{_.get(this.props.translation, 'activate_account_page.incorrect_activation_link_label')}</h6>;
        }
        if (this.props.loading) {
            return <h6 className="page-message">{_.get(this.props.translation, "actions.fetching_message")}</h6>;
        }
        if (this.props.tokenError) {
            return this.renderIrrelevantTokenMessage();
        }
        if (this.props.data) {
            switch (USER_ROLE[this.props.data.role]) {
                case USER_ROLE.SYS_ADMIN:
                case USER_ROLE.EMPLOYEE:
                    return <ActivateEmployeeAccount token={this.token} />;

                case USER_ROLE.APPLICANT:
                    return <ActivateApplicantAccount token={this.token} />;

                default:
                    return null;
            }
        } else {
            return null;
        }
    }

    renderIrrelevantTokenMessage() {
        return (
            <div className="irrelevant-account-activation-link-message-container">
                <div className="irrelevant-account-activation-link-message">
                    <h5>{_.get(this.props.translation, 'activate_account_page.activation_link_irrelevant_message')}</h5>
                    <div>
                        <span>{_.get(this.props.translation, 'activate_account_page.activation_link_irrelevant_forgot_password_message_start')}</span>
                        <Link to={ROUTES.RESTORE_ACCOUNT}>
                            {_.get(this.props.translation, 'activate_account_page.activation_link_irrelevant_forgot_password_message_link')}
                        </Link>
                        <span>{_.get(this.props.translation, 'activate_account_page.activation_link_irrelevant_forgot_password_message_end')}</span>
                    </div>
                    <div>
                        <span>{_.get(this.props.translation, 'activate_account_page.activation_link_irrelevant_log_in_message_start')}</span>
                        <Link to={ROUTES.LOGIN}>
                            {_.get(this.props.translation, 'activate_account_page.activation_link_irrelevant_log_in_message_link')}
                        </Link>
                        <span>{_.get(this.props.translation, 'activate_account_page.activation_link_irrelevant_log_in_message_end')}</span>
                    </div>
                </div>
            </div>
        )
    }

}

ActivateAccount.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.object,
    loading: PropTypes.bool,
    tokenError: PropTypes.object,
    activationSuccess: PropTypes.bool,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onGetAccountToActivateData: PropTypes.func.isRequired
}

export default withRouter(ActivateAccount);
