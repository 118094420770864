import { connect } from 'react-redux';
import ApplicantQuickComparison from './ApplicantQuickComparison';
import { getTranslation } from '../../../reducers/common/localization';
import {
	getApplicantComparisonModalVisibility,
	getApplicantData,
	getFetchApplicantByIdLoading,
	onCloseModal,
	onFetchApplicantById,
	getSelectedApplicantId
} from '../../../reducers/quickfind/applicantComparison';

const mapStateToProps = state => {
	return {
		translation: getTranslation(state),
		applicantData: getApplicantData(state),
		loading: getFetchApplicantByIdLoading(state),
		isOpen: getApplicantComparisonModalVisibility(state),
		applicantId: getSelectedApplicantId(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onFetchApplicantById: (applicantId) => dispatch(onFetchApplicantById(applicantId)),
		onCloseModal: () => dispatch(onCloseModal())
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantQuickComparison);
