import React, { Component } from "react";

class MyProfile extends Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <defs></defs>
                <path
                    fill="#2d3e50"
                    d="M52.2586,72.67626l6.74487,8.99455-5.05971,43.75525H5.567S5.90819,106.48062,7.83352,95.249a11.98538,11.98538,0,0,1,6.45842-8.69964c11.23157-5.599,35.87708-13.8731,35.87708-13.8731Z"
                />
                <path
                    fill="#2d3e50"
                    d="M122.433,125.42605h-47.113L68.99648,81.675l6.74487-8.99876h2.08542s24.64972,8.27413,35.88135,13.8731a11.99594,11.99594,0,0,1,6.45837,8.69964C122.09176,106.48062,122.433,125.42605,122.433,125.42605Z"
                />
                <path
                    fill="#2d3e50"
                    d="M64,64.62753h0a22.227,22.227,0,0,1-21.81889-17.987L38.78137,29.1458A23.419,23.419,0,0,1,61.98722,2.57395h4.02555A23.419,23.419,0,0,1,89.21863,29.1458L85.81891,46.64051A22.227,22.227,0,0,1,64,64.62753Z"
                />
            </svg>
        );
    }
}

export default MyProfile;
