import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import _ from "lodash";

export const updateDomainList = createAction("UPDATE_DOMAIN_LIST");
export const updateDomainListErr = createAction("UPDATE_DOMAIN_LIST_ERROR");

export const onFetchGetDomainListSuccess = (data) => (dispatch) => {
    dispatch(updateDomainList(data));
};

export const fetchGetDomainList = createAction(
    "FETCH_GET_DOMAIN_LIST",
    () => Api.getDomainList(),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetDomainListSuccess,
        onFail: updateDomainListErr,
    })
);

export const updateSkillClusters = createAction("UPDATE_SKILL_CLUSTERS");
export const onFetchGetSkillClustersByDomainError = createAction(
    "FETCH_SKILL_CLUSTERS_BY_DOMAIN_ERR"
);
export const onFetchGetAllSkillClusters = (data) => (dispatch) => {
    dispatch(updateSkillClusters(data.content));
};
export const onFetchGetSkillClustersByDomain = (data) => (dispatch) => {
    dispatch(updateSkillClusters(data));
};

export const fetchGetSkillClustersByDomain = createAction(
    "FETCH_GET_SKILL_CLUSTERS_BY_DOMAIN",
    (domainTitle) => Api.getSkillClustersByDomain(domainTitle),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSkillClustersByDomain,
        onFail: onFetchGetSkillClustersByDomainError,
    })
);

export const fetchGetAllSkillClusters = createAction(
    "FETCH_GET_ALL_SKILL_CLUSTERS",
    () => Api.getAllSkillClusters(),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetAllSkillClusters,
        onFail: onFetchGetSkillClustersByDomainError,
    })
);

const initialState = {
    domains: [],
    err: null,
    domainsFetching: false,

    skillClusters: [],
    scFetching: false,
};

export default handleActions(
    {
        UPDATE_DOMAIN_LIST: (state, action) => ({
            ...state,
            domains: action.payload,
        }),
        UPDATE_SKILL_CLUSTERS: (state, action) => ({
            ...state,
            skillClusters: action.payload,
        }),
    },
    initialState
);

export const getDomainsAndSC = (state) => state.domainAndSC;

export const getDomains = (state) => getDomainsAndSC(state).domains;

export const getSC = (state) => getDomainsAndSC(state).skillClusters;
