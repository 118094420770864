import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    ModalHeader
} from 'reactstrap';
import { FormUtils } from '../../../../../utils/formUtils';

class AddOrEditDepartmentModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: ""
        };
    }

    componentDidUpdate(prevProps) {
        const { selectedDepartment, isEditDepartment } = this.props;
        if (!_.isEmpty(selectedDepartment) && !_.isEqual(selectedDepartment, prevProps.selectedDepartment) && isEditDepartment) {
            this.setState({ name: selectedDepartment.name, email: selectedDepartment.email })
        }
    }

    closeModal = () => {
        const { onClose, disableEditMode } = this.props;

        this.setState({ name: "", email: "" });
        disableEditMode();
        onClose();
    };

    handleChangeField = (fieldName) => (e) => {
        this.setState({ [fieldName]: e.target.value });
    };

    onSubmit = (e) => {
        e.preventDefault();

        const { onAddDepartment, isEditDepartment, onEditDepartment, selectedDepartment } = this.props;

        const { name, email,  } = this.state;

        const data = {
            name,
            email,
        };

        if (isEditDepartment) {
            onEditDepartment({ data, departmentId: selectedDepartment.id });
        } else {
            onAddDepartment({ data });
        }

        this.closeModal();
    };

    checkIsSubmitDisabled = () => {
        const { name, email } = this.state;
        const { selectedDepartment, isEditDepartment } = this.props;

        if (!name || !email) {
            return true;
        } else if (
          !_.isEmpty(selectedDepartment)
          && isEditDepartment
          && _.isEqual(this.state, { name: selectedDepartment.name, email: selectedDepartment.email })
        ) {
            return true;
        } else return false;
    };

    render() {
        const { isOpen, translation, isEditDepartment } = this.props;

        const formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField
        };

        const params = [
            {
                fieldName: "name",
                title: _.get(translation, 'departments.department_name_label'),
                isRequired: true
            },
            {
                fieldName: "email",
                title: _.get(translation, 'departments.department_email_label'),
                type: 'email',
                isRequired: true
            },
        ];

        return (
          <Modal
            isOpen={isOpen}
            toggle={this.closeModal}
          >
              <ModalHeader>
                  {
                      isEditDepartment
                        ? _.get(translation, 'departments.edit_department_label')
                        : _.get(translation, 'departments.create_a_new_department_label')
                  }
              </ModalHeader>
              <ModalBody>
                  <Form id="add-department-modal-form" onSubmit={this.onSubmit}>
                      {_.map(params, (fieldParams) => FormUtils.renderFormGroup(fieldParams, formParams))}
                  </Form>
              </ModalBody>
              <ModalFooter className="content-space-between">
                  <Button
                    color="secondary"
                    onClick={this.closeModal}
                  >
                      {_.get(translation, 'actions.cancel_button')}
                  </Button>
                  <Button
                    disabled={this.checkIsSubmitDisabled()}
                    color="primary"
                    form="add-department-modal-form"
                    type="submit"
                  >
                      {
                          isEditDepartment
                            ? _.get(translation, "actions.edit_label")
                            : _.get(translation, "departments.create_department_button")
                      }
                  </Button>
              </ModalFooter>
          </Modal>
        );
    }
}

AddOrEditDepartmentModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    disableEditMode: PropTypes.func.isRequired,
    isEditDepartment: PropTypes.bool.isRequired,
    onAddDepartment: PropTypes.func,
    onEditDepartment: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    selectedDepartment: PropTypes.object,
};

export default AddOrEditDepartmentModal;

