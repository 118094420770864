import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { Utils } from "../../../../utils/utils";

class NoProfilesModal extends Component {
    onHandleActionButton = () => {
        const { onClose, cbAfterClose } = this.props;
        onClose();
        if (cbAfterClose) {
            cbAfterClose();
        }
    };

    render() {
        return (
            <Modal
                className="no-profiles-modal"
                isOpen={this.props.isOpen}
                toggle={this.onHandleActionButton}
            >
                <ModalBody>{this.renderModalBody()}</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.onHandleActionButton}>
                        {_.get(this.props.translation, "actions.ok_button")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalBody = () => {
        return (
            <div>
                <div>
                    {Utils.getTagsFromStringWithStyles(
                        _.get(
                            this.props.translation,
                            "profiles_management.no_profile_selected_modal_label_part_1"
                        )
                    )}
                </div>
                <br />
                <div>
                    {_.get(
                        this.props.translation,
                        "profiles_management.no_profile_selected_modal_label_part_2"
                    )}
                </div>
            </div>
        );
    };
}

NoProfilesModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
};

export default NoProfilesModal;
