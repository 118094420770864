import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import _ from "lodash";
import { USER_ROLE, USER_SUB_ROLE, NO_SUB_ROLES } from "../../constants/constants";
import { StorageManager } from "../../utils/storageManager";
import { setUserSubRoleMissingError } from "../common/errors";

//- Actions
export const setUserRole = createAction("SET_USER_ROLE");

export const fetchGetUserSubRolesFailed = createAction("FETCH_GET_USER_SUB_ROLES_FAILED");
export const fetchGetUserSubRolesSuccess = createAction("FETCH_GET_USER_SUB_ROLES_SUCCESS");
export const fetchGetUserSubRoles = createAction(
    "FETCH_GET_USER_SUB_ROLES",
    () => Api.getUserData(),
    () => ({
        apiCall: true,
        onSuccess: fetchGetUserSubRolesSuccess,
        onFail: fetchGetUserSubRolesFailed,
    })
);

export const setUserRoleAndSubRoles = (role, subRoles) => (dispatch) => {
    dispatch(setUserRole(role));
    if (role === USER_ROLE.EMPLOYEE) {
        dispatch(setUserSubRoles(subRoles));
        // TODO: add get org data
    }
    if (role === USER_ROLE.SYS_ADMIN) {
        // TODO: add get currencies and presets
    }
};

export const onSetUserSubRoles = createAction("SET_USER_SUB_ROLES");
export const setUserSubRoles = (subRoles) => (dispatch) => {
    subRoles = _.sortBy(subRoles);

    let lastUsedSubRole = StorageManager.getLastUsedSubRole();
    let currentSubRole = subRoles.length > 0 ? subRoles[0] : NO_SUB_ROLES;

    if (currentSubRole !== NO_SUB_ROLES) {
        if (!_.isNil(lastUsedSubRole) && subRoles.includes(lastUsedSubRole)) {
            currentSubRole = lastUsedSubRole;
        } else {
            StorageManager.setLastUsedSubRole(currentSubRole);
        }
        if (currentSubRole === USER_SUB_ROLE.ANCHOR) {
            // TODO: add get org balance
        } else {
            // dispatch(fetchGetGroupsBySubRole(currentSubRole));  UNNECESSARY FAILED REQUEST
        }
    }

    dispatch(onSetUserSubRoles(subRoles));
    dispatch(setUserCurrentSubRole(currentSubRole));
};

export const setUserCurrentSubRole = createAction("SET_USER_CURRENT_SUB_ROLE");

export const changeUserCurrentSubRole = (newSubRole) => (dispatch) => {
    dispatch(fetchUpdateUserSubRoles(newSubRole));
};

export const fetchUpdateUserSubRolesSuccess = createAction("FETCH_UPDATE_USER_SUB_ROLES_SUCCESS");
export const fetchUpdateUserSubRolesFailed = createAction("FETCH_UPDATE_USER_SUB_ROLES_FAILED");
export const onUpdateUsersSubRolesSuccess = (newSubRole) => (userData) => (dispatch, getState) => {
    let subRoles = _.sortBy(userData.subRoles);

    if (subRoles.includes(newSubRole)) {
        let role = getUserRole(getState());
        if (
            role === USER_ROLE.EMPLOYEE &&
            newSubRole !== USER_SUB_ROLE.ANCHOR &&
            newSubRole !== NO_SUB_ROLES
        ) {
            // dispatch(fetchGetGroupsBySubRole(newSubRole));  UNNECESSARY FAILED REQUEST
        }
        StorageManager.setLastUsedSubRole(newSubRole);
        dispatch(setUserCurrentSubRole(newSubRole));
    } else {
        let oldSubRole = getUserCurrentSubRole(getState());
        if (!subRoles.includes(oldSubRole)) {
            dispatch(setUserCurrentSubRole(subRoles.length > 0 ? subRoles[0] : NO_SUB_ROLES));
        }
        dispatch(setUserSubRoleMissingError());
    }
    dispatch(fetchUpdateUserSubRolesSuccess(subRoles));
};
export const fetchUpdateUserSubRoles = createAction(
    "FETCH_UPDATE_USER_SUB_ROLES",
    () => Api.getUserData(),
    (newSubRole) => ({
        apiCall: true,
        onSuccess: onUpdateUsersSubRolesSuccess(newSubRole),
        onFail: fetchUpdateUserSubRolesFailed,
    })
);

export const fetchGetGroupsBySubRoleFailed = createAction("FETCH_GET_GROUPS_BY_SUBROLE_FAILED");
export const fetchGetGroupsBySubRoleSuccess = createAction("FETCH_GET_GROUPS_BY_SUBROLE_SUCCESS");
export const onGetGroupsBySubRoleSuccess = (groups) => (dispatch) => {
    if (groups.length === 0) {
        dispatch(fetchGetGroupsBySubRoleFailed());
        dispatch(setUserSubRoleMissingError());
    } else {
        groups = _.sortBy(groups);
        let lastUsedGroup = StorageManager.getLastUsedGroup();
        let group = groups[0].id;
        if (!_.isNil(lastUsedGroup) && !_.isNil(groups.find((elem) => lastUsedGroup === elem.id))) {
            group = lastUsedGroup;
        }
        dispatch(fetchGetGroupsBySubRoleSuccess(groups));
        dispatch(changeUserCurrentGroup(group));
    }
};
export const fetchGetGroupsBySubRole = createAction(
    "FETCH_GET_GROUPS_BY_SUBROLE",
    (subRole) => {
        switch (subRole) {
            case USER_SUB_ROLE.HM:
                return Api.getHMGroups();
            case USER_SUB_ROLE.BO:
                return Api.getBOGroups();
            default:
                break;
        }
    },
    () => ({
        apiCall: true,
        onSuccess: onGetGroupsBySubRoleSuccess,
        onFail: fetchGetGroupsBySubRoleFailed,
    })
);

export const setUserCurrentGroup = createAction("SET_USER_CURRENT_GROUP");

export const changeUserCurrentGroup = (newGroupId) => (dispatch, getState) => {
    StorageManager.setLastUsedGroup(newGroupId);
    dispatch(setUserCurrentGroup(newGroupId));
};

//- State
const initialState = {
    role: null,
    currentSubRole: null,
    subRoles: null,
    fetchingSubRoles: false,
    fetchingUpdateSubRoles: false,
    currentGroup: null,
    groups: null,
    fetchingGroups: false,
    error: null,
    userSubRoleIsMissing: false,
};

//- Reducers
export default handleActions(
    {
        SET_USER_ROLE: (state, action) => {
            return { ...state, role: action.payload };
        },
        FETCH_GET_USER_SUB_ROLES: (state) => {
            return { ...state, subRoles: null, error: null, fetchingSubRoles: true };
        },
        FETCH_GET_USER_SUB_ROLES_FAILED: (state, action) => {
            return { ...state, subRoles: null, error: action.payload, fetchingSubRoles: false };
        },
        FETCH_GET_USER_SUB_ROLES_SUCCESS: (state, action) => {
            return { ...state, subRoles: action.payload, error: null, fetchingSubRoles: false };
        },
        SET_USER_SUB_ROLES: (state, action) => {
            return { ...state, subRoles: action.payload };
        },
        SET_USER_CURRENT_SUB_ROLE: (state, action) => {
            return { ...state, currentSubRole: action.payload };
        },
        FETCH_UPDATE_USER_SUB_ROLES: (state) => {
            return { ...state, fetchingUpdateSubRoles: true };
        },
        FETCH_UPDATE_USER_SUB_ROLES_SUCCESS: (state, action) => {
            return { ...state, subRoles: action.payload, fetchingUpdateSubRoles: false };
        },
        FETCH_UPDATE_USER_SUB_ROLES_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetchingUpdateSubRoles: false };
        },
        FETCH_GET_GROUPS_BY_SUBROLE: (state) => {
            return {
                ...state,
                groups: null,
                currentGroup: null,
                error: null,
                fetchingGroups: true,
            };
        },
        FETCH_GET_GROUPS_BY_SUBROLE_FAILED: (state, action) => {
            return { ...state, groups: null, error: action.payload, fetchingGroups: false };
        },
        FETCH_GET_GROUPS_BY_SUBROLE_SUCCESS: (state, action) => {
            return { ...state, groups: action.payload, error: null, fetchingGroups: false };
        },
        SET_USER_CURRENT_GROUP: (state, action) => {
            return { ...state, currentGroup: action.payload };
        },
    },
    initialState
);

//- Selectors
export const getUserRoleState = (state) => state.userRole;

export const getUserRole = (state) => getUserRoleState(state).role;

export const getUserCurrentSubRole = (state) => getUserRoleState(state).currentSubRole;

export const getUserSubRoles = (state) => getUserRoleState(state).subRoles;

export const getUserSubRolesLoading = (state) => getUserRoleState(state).fetchingSubRoles;

export const getUserUpdateSubRolesLoading = (state) =>
    getUserRoleState(state).fetchingUpdateSubRoles;

export const getUserGroupsForSubRole = (state) => getUserRoleState(state).groups;

export const getUserCurrentGroup = (state) => getUserRoleState(state).currentGroup;

export const getUserGroupsLoading = (state) => getUserRoleState(state).fetchingGroups;
