import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './DashboardComparison.scss';
import _ from 'lodash';
import { Alert } from 'reactstrap';
import { Utils } from '../../../../../utils/utils';
import Icon from '../../../../common/icon/Icon';
import { CANDIDATE_GROUP, COMPARING_MODE } from '../../../../../constants/candidatesComparisonConstants';
import UserPhoto from '../../../../common/userPhoto/UserPhoto.container';
import { USER_PHOTO_MODE } from '../../../../common/userPhoto/UserPhoto';
import BrainChainCheckMark from '../../../../common/brainChainCheckMark/BrainChainCheckMark.container';
import Legend from '../../../../common/skills/legend/Legend.container';
import { LEGEND_TYPE } from '../../../../common/skills/legend/Legend';
import { CROSS_JOB_INFO_TYPE } from '../modals/candidateCrossJobInfoModal/CandidateCrossJobInfoModal';
import Skill from '../../../../common/skills/skill/Skill.container';
import { COMPARISON_LEVEL_CLASS_NAME, REQUIREMENT_COEFFICIENT_CLASS_NAME, LEVEL_CLASS_NAME, SKILL_PROPERTY } from '../../../../../constants/skillsConstants';
import SkillComparisonPopover from '../../../../common/popovers/skillComparisonPopover/SkillComparisonPopover.container';
import SkillComparisonNotesPopover from '../../../../common/popovers/skillComparisonNotesPopover/SkillComparisonNotesPopover.container';
import FFMChart from '../../../../common/softSkills/FFMChart/FFMChart.container';
import { FFM_CHART_MODE } from '../../../../common/softSkills/FFMChart/FFMChart';


class DashboardComparison extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowAllSkills: false
        }
    }

    render() {
        if (this.props.loading) {
            return (
                <h6 className="text-center">{_.get(this.props.translation, "actions.fetching_message")}</h6>
            )
        }

        if (!this.props.loading && (!this.props.candidatesData || this.props.candidatesData.length === 0)) {
            return (
                <div className="select-candidate-hint right-padding">
                    <Alert>
                        {_.get(this.props.translation, 'candidates_comparison_page.select_candidates_to_compare_hint')}
                    </Alert>
                </div>
            )
        }

        return (
            <div className="dashboard-comparison">
                {this.renderCandidatesInfo()}
                {this.renderHardSkills()}
                {this.renderSoftSkills()}
                {this.renderQualityCheck()}
            </div>
        );
    }

    renderCandidatesInfo() {
        return (
            <div className="candidates-info">
                <div className="candidates-info-col job-requirements">
                    <h6 className="text-center">
                        {_.get(this.props.translation, 'job.job_requirements_label')}
                    </h6>
                </div>
                {
                    _.map(this.props.candidatesData, (candidateData) => {
                        return this.renderCandidateInfo(candidateData)
                    })
                }
            </div>
        );
    }

    renderCandidateInfo(candidateData) {
        return (
            <div className="candidates-info-col candidate-info" key={candidateData.userId}>
                <div className="candidate-controls">
                    <div className="buttons-group">
                        {
                            candidateData.applicantMetaData.groupId === CANDIDATE_GROUP.TOP_X ?
                                <Icon
                                    className="top-x-icon"
                                    type="best_mark"
                                    size="lg"
                                    title={_.replace(_.get(this.props.translation, 'candidates_comparison_page.top_x_candidate_label'), "{x}", this.props.plannedTopAmount)}
                                />
                                :
                                <Icon
                                    className="top-x-icon"
                                    type="best_mark_outline"
                                    size="lg"
                                    onClick={this.handleAddToTopXGroup(candidateData.userId)}
                                    title={_.replace(_.get(this.props.translation, 'candidates_comparison_page.add_to_top_x_label'), "{x}", this.props.plannedTopAmount)}
                                />
                        }
                        {
                            candidateData.applicantMetaData.favorite ?
                                <Icon
                                    className="favorite-icon"
                                    type="star"
                                    size="lg"
                                    title={_.get(this.props.translation, 'candidates_comparison_page.remove_from_favorite_label')}
                                    onClick={this.handleRemoveFromFavorite(candidateData.userId)}
                                />
                                :
                                <Icon
                                    className="favorite-icon"
                                    type="star_outline"
                                    size="lg"
                                    title={_.get(this.props.translation, 'candidates_comparison_page.add_to_favorite_label')}
                                    onClick={this.handleAddToFavorite(candidateData.userId)}
                                />
                        }
                    </div>
                    <UserPhoto
                        mode={USER_PHOTO_MODE.COMPARISON}
                        userId={candidateData.userId}
                        // TODO: remove hardcoded values
                        imageSource=""
                        isDraggable
                        size="sm"
                    />
                    <div className="buttons-group">
                        <Icon
                            type="movie"
                            size="lg"
                            onClick={this.handleOpenCrossJobInfoModal(candidateData.userId, CROSS_JOB_INFO_TYPE.MOVIE)}
                        />
                        <Icon
                            type="cv"
                            size="lg"
                            onClick={this.handleOpenCrossJobInfoModal(candidateData.userId, CROSS_JOB_INFO_TYPE.CV)}
                        />
                    </div>
                </div>
                <div className="candidate-name">
                    {`${this.getUserName(candidateData)},`}
                    <br />
                    {candidateData.user.currentJob}
                </div>
                <Icon
                    className="candidate-notes-icon"
                    type={candidateData.applicantMetaData.notes ? "microphone" : "microphone_outline"}
                    size="lg"
                    onClick={this.handleCandidateNotesClick(candidateData.userId)}
                    title={_.get(this.props.translation, 'candidates_comparison_page.notes_about_candidate_label')}
                />
                <Icon
                    className="remove-candidate-from-comparison"
                    type="cancel"
                    size="sm"
                    color="danger"
                    onClick={this.handleRemoveCandidateFromComparison(candidateData.userId)}
                    title={_.get(this.props.translation, 'candidates_comparison_page.remove_candidate_from_comparison_label')}
                />
            </div>
        )
    }

    getUserName = (candidateData) => {
        if (this.props.anonymityMode) {
            return Utils.getUserAnonymousNameByUUID(candidateData.userId);
        } else {
            return candidateData.user.alias.firstName + " " + candidateData.user.alias.lastName;
        }
    }

    renderHardSkills() {
        if (this.props.comparingMode === COMPARING_MODE.HARD_SKILLS || this.props.comparingMode === COMPARING_MODE.COMBINED) {
            return (
                <>
                    <div className="hard-skills-header">
                        <h5 className="text-center">
                            {_.get(this.props.translation, 'job_and_candidates.hard_skills_label')}
                        </h5>
                        {this.renderHardSkillsControls()}

                    </div>
                    <div className="hard-skills">
                        {this.renderHardSkillsClusters()}
                    </div>
                    <Legend type={LEGEND_TYPE.COMPARISON_REVERSED} />
                </>
            );
        }
    }

    renderHardSkillsControls() {
        return (
            <div className="hard-skills-controls">
                <div className="controls-title">
                    {_.get(this.props.translation, 'actions.show_label')}
                </div>
                <div className="control-button" onClick={this.toggleSkillsShowingMode(false)}>
                    <Icon type={`radio${this.state.isShowAllSkills ? "_blank" : ""}`} size="sm" />
                    {_.get(this.props.translation, 'job.required_skills_job_label')}
                </div>
                <div className="control-button" onClick={this.toggleSkillsShowingMode(true)} >
                    <Icon type={`radio${this.state.isShowAllSkills ? "" : "_blank"}`} size="sm" />
                    {_.get(this.props.translation, 'job.all_skills_candidate_label')}
                </div>
            </div>
        );
    }

    toggleSkillsShowingMode = (isShow) => () => {
        if (this.state.isShowAllSkills !== isShow) {
            this.setState({ isShowAllSkills: !this.state.isShowAllSkills });
        }
    }

    renderHardSkillsClusters() {
        let jobClusters = _.orderBy(this.props.jobSkillsTree, ["categoryTitle"]);

        return (
            <>
                {
                    _.map(jobClusters, (cluster) => {
                        return (
                            <div className="hard-skills-row" key={cluster.categoryId}>
                                <div className="cluster-name">
                                    {cluster.categoryTitle}
                                </div>
                                <div className="cluster job-cluster">
                                    {
                                        _.map(cluster.children, (skill) => {
                                            return (
                                                <Skill
                                                    key={skill.id}
                                                    text={skill.title}
                                                    title={skill.title}
                                                    className={LEVEL_CLASS_NAME[skill.level] + "-secondary " + REQUIREMENT_COEFFICIENT_CLASS_NAME[skill.requirementCoefficient]}
                                                    isTextOverflowEllipsis
                                                />
                                            )
                                        })
                                    }
                                </div>
                                {
                                    _.map(this.props.comparedSkills, (candidateClusters, candidateIndex) => {
                                        let candidateCluster = _.find(candidateClusters, (elem) => elem.categoryId === cluster.categoryId);
                                        let children = candidateCluster.children;
                                        if (!this.state.isShowAllSkills) {
                                            children = _.filter(children, (skill) => !skill.isSecondary);
                                        } else {
                                            children = _.orderBy(children, ["isSecondary"], ["desc"]);
                                        }
                                        return (
                                            <div className="cluster candidate-cluster" key={candidateIndex}>
                                                {
                                                    _.map(children, (skill) => {
                                                        let props = {
                                                            className: `${!skill.isSecondary ?
                                                                COMPARISON_LEVEL_CLASS_NAME[skill.comparisonLevel] + " " + REQUIREMENT_COEFFICIENT_CLASS_NAME[skill.requiredData.requirementCoefficient] + (skill.actualData ? " actual-" + LEVEL_CLASS_NAME[skill.actualData.level] : "")
                                                                :
                                                                LEVEL_CLASS_NAME[skill.actualData.level] + "-secondary"
                                                                } `,
                                                            iconsTitle: {
                                                                notes: _.get(this.props.translation, 'skills_management.show_notes_label'),
                                                            },
                                                            iconsPositions: {
                                                                topRight: "notes"
                                                            },
                                                            iconsIds: {
                                                                notes: "skill-map-skill-" + skill.id + "-notes" + candidateIndex,
                                                            }
                                                        }

                                                        let notesNumber = this.getSkillNotesNumber(skill);
                                                        return (
                                                            <React.Fragment key={skill.id}>
                                                                <Skill
                                                                    key={skill.id}
                                                                    id={"skill-map-skill-" + skill.id + candidateIndex}
                                                                    text={skill.title}
                                                                    isTextOverflowEllipsis
                                                                    level={skill.level}
                                                                    notesNumber={notesNumber}
                                                                    {...props}
                                                                />
                                                                <SkillComparisonPopover
                                                                    target={"skill-map-skill-" + skill.id + candidateIndex}
                                                                    data={skill}
                                                                />
                                                                {
                                                                    !_.isNil(notesNumber) && notesNumber > 0 ?
                                                                        <SkillComparisonNotesPopover
                                                                            target={"skill-map-skill-" + skill.id + "-notes" + candidateIndex}
                                                                            data={skill}
                                                                        />
                                                                        : null
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </>
        )
    }

    getSkillNotesNumber(skill) {
        let requiredNotes = skill.requiredData && skill.requiredData.notes ? skill.requiredData.notes : [];
        let actualNotes = skill.actualData && skill.actualData.notes ? skill.actualData.notes : [];
        let notes = [...requiredNotes, ...actualNotes];
        let amount = notes.length;
        const PROPS_TO_NOTES = [SKILL_PROPERTY.VERSION, SKILL_PROPERTY.SPOKEN_LEVEL, SKILL_PROPERTY.UNDERSTANDING_LEVEL];
        if (skill.requiredData) {
            _.forEach(PROPS_TO_NOTES, (property) => {
                if (!_.isNil(skill.requiredData[property])) {
                    amount++;
                }
            })
        }
        if (skill.actualData) {
            _.forEach(PROPS_TO_NOTES, (property) => {
                if (!_.isNil(skill.actualData[property])) {
                    amount++;
                }
            })
        }
        return amount > 0 ? amount : null;

    }

    renderQualityCheck() {
        return (
            <div className="quality-checks">
                <div className="quality-check-label">
                    <h6 className="text-center">
                        {_.get(this.props.translation, 'candidate.quality_check_label')}
                    </h6>
                </div>
                {
                    _.map(this.props.candidatesData, (candidateData, id) => {
                        return (
                            <div key={id} className="quality-check">
                                <BrainChainCheckMark
                                    userId={candidateData.userId}
                                    qualityCheck={candidateData.user.qualityCheck}
                                    size="xxl"
                                />
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    renderSoftSkills() {
        if (this.props.comparingMode === COMPARING_MODE.SOFT_SKILLS || this.props.comparingMode === COMPARING_MODE.COMBINED) {
            return (
                <>
                    <h5 className="soft-skills-header text-center">
                        {_.get(this.props.translation, 'job_and_candidates.soft_skills_label')}
                    </h5>
                    <div className="soft-skills">
                        <div className="ffm-chart-container job-ffm">
                            <FFMChart
                                mode={FFM_CHART_MODE.COMPARE}
                                actualData={_.map(this.props.jobFFMData.normalized, value => value.min + (value.max - value.min) / 2)}
                                requirementsData={this.props.jobFFMData.normalized}
                            />
                        </div>
                        {
                            _.map(this.props.candidatesFFMData, (candidateFFMData, id) => {
                                return (
                                    <div className="ffm-chart-container" key={id}>
                                        <FFMChart
                                            mode={FFM_CHART_MODE.COMPARE}
                                            actualData={candidateFFMData}
                                            requirementsData={this.props.jobFFMData.normalized}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            );
        }
    }

    handleRemoveFromFavorite = (userId) => () => {
        this.props.onRemoveFromFavorite(userId);
    }

    handleAddToFavorite = (userId) => () => {
        this.props.onAddToFavorite(userId);
    }

    handleCandidateNotesClick = (userId) => () => {
        this.props.onCandidateNotesClick(userId);
    }

    handleAddToTopXGroup = (userId) => () => {
        this.props.onAddToTopXGroup(userId);
    }

    handleRemoveCandidateFromComparison = (userId) => () => {
        this.props.onDeselectCandidate(userId);
    }

    handleOpenCrossJobInfoModal = (userId, type) => () => {
        this.props.onSetCrossJobInfoType(type);
        this.props.onShowCrossJobInfoModal(userId);
    }
}

DashboardComparison.propTypes = {
    translation: PropTypes.object.isRequired,
    candidatesData: PropTypes.array,
    comparedSkills: PropTypes.array,
    loading: PropTypes.bool,
    anonymityMode: PropTypes.bool,
    plannedTopAmount: PropTypes.number,
    jobSkillsTree: PropTypes.array,
    jobFFMData: PropTypes.object,
    candidatesFFMData: PropTypes.array,
    comparingMode: PropTypes.string,
    onAddToFavorite: PropTypes.func.isRequired,
    onRemoveFromFavorite: PropTypes.func.isRequired,
    onAddToTopXGroup: PropTypes.func.isRequired,
    onCandidateNotesClick: PropTypes.func.isRequired,
    onDeselectCandidate: PropTypes.func.isRequired,
    onShowCrossJobInfoModal: PropTypes.func.isRequired,
    onSetCrossJobInfoType: PropTypes.func.isRequired,
}

export default DashboardComparison;
