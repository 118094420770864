import { connect } from 'react-redux';
import RemoveClusterModal from "./RemoveClusterModal";
import { getTranslation } from '../../../../reducers/common/localization';
import { getActiveModalType, MODAL_REMOVE_CLUSTER, hideModal } from '../../../../reducers/common/activeModal';

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_REMOVE_CLUSTER
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(hideModal());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveClusterModal);
