import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import _ from "lodash";

//- Actions
export const fetchGetApplicantPastJobsFailed = createAction("FETCH_GET_APPLICANT_PAST_JOBS_FAILED");
export const fetchGetApplicantPastJobsSuccess = createAction(
    "FETCH_GET_APPLICANT_PAST_JOBS_SUCCESS"
);
export const onFetchGetApplicantPastJobsSuccess = () => (pastJobs) => (dispatch) => {
    let formattedPastJobs = _.map(pastJobs, (pastJob) => {
        return {
            ...pastJob,
        };
    });
    dispatch(fetchGetApplicantPastJobsSuccess(formattedPastJobs));
};
export const fetchGetApplicantPastJobs = createAction(
    "FETCH_GET_APPLICANT_PAST_JOBS",
    () => Api.getApplicantPastJobs(),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetApplicantPastJobsSuccess(),
        onFail: fetchGetApplicantPastJobsFailed(),
    })
);

export const fetchAddApplicantPastJobFailed = createAction("FETCH_ADD_APPLICANT_PAST_JOB_FAILED");
export const fetchAddApplicantPastJobSuccess = createAction("FETCH_ADD_APPLICANT_PAST_JOB_SUCCESS");
export const onFetchAddApplicantPastJobSuccess = (data, onSuccessCallback) => (dispatch) => {
    dispatch(fetchAddApplicantPastJobSuccess());
    dispatch(fetchGetApplicantPastJobs());
    if (onSuccessCallback) {
        onSuccessCallback();
    }
};
export const fetchAddApplicantPastJob = createAction(
    "FETCH_ADD_APPLICANT_PAST_JOB",
    (data) => Api.addApplicantPastJob(data),
    ({ onSuccessCallback }) => ({
        apiCall: true,
        onSuccess: (data) => onFetchAddApplicantPastJobSuccess(data, onSuccessCallback),
        onFail: fetchAddApplicantPastJobFailed,
    })
);

//- Selectors

export const getApplicantPastJobsData = (state) => getApplicantPastJobs(state).pastJobsData;

export const getApplicantPastJobs = (state) => state.applicantPastJobs;

//- State
const initialState = {
    employer: null,
    jobTitle: null,
    startedAt: null,
    endedAt: null,
    whyTookJob: null,
    whatLearned: null,
};

//- Reducers
export default handleActions(
    {
        FETCH_GET_APPLICANT_PAST_JOBS: (state) => {
            return { ...state, error: null, fetching: true };
        },
        FETCH_GET_APPLICANT_PAST_JOBS_FAILED: (state, action) => {
            return {
                ...state,
                skillsData: null,
                initialSkillsData: null,
                error: action.payload,
                fetching: false,
            };
        },
        FETCH_GET_APPLICANT_PAST_JOBS_SUCCESS: (state, action) => {
            return {
                ...state,
                pastJobsData: action.payload,
                error: null,
                fetching: false,
            };
        },
        FETCH_ADD_APPLICANT_PAST_JOB: (state) => {
            return { ...state, error: null, fetchingAddPastJob: true };
        },
        FETCH_ADD_APPLICANT_PAST_JOB_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetchingAddPastJob: false };
        },
        FETCH_ADD_APPLICANT_PAST_JOB_SUCCESS: (state) => {
            return { ...state, error: null, fetchingAddPastJob: false };
        },
    },
    initialState
);

//- Selectors
export const getApplicantSkillsState = (state) => state.applicantSkills;
