import React, { Component } from "react";

class Info extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24">
                <circle cx="12" cy="12" r="10" fill="#ffffff" />
                <path
                    fill="#00CEFF"
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
                />
            </svg>
        );
    }
}

export default Info;
