import { connect } from "react-redux";
import NodesFilters from "./NodesFilters";
import { getTranslation } from "../../../../reducers/common/localization";
import {
    getSystemNodesToAddNameFilter,
    getSystemNodesToAddSourceFilter,
    getSystemNodesToAddStatusFilter,
    getSystemNodesToAddDomainFilter,
    onFetchGetSystemNodesToAdd,
    updateSystemNodesToAddNameFilter,
    onUpdateSystemNodesToAddSourceFilter,
    onUpdateSystemNodesToAddStatusFilter,
    onUpdateSystemNodesToAddDomainFilter,
    onUpdateSystemNodesToAddSortingMode,
    getSystemNodesToAddSortingMode,
    onResetSystemNodesToAddData,
    fetchGetSkillsNodesToAdd,
} from "../../../../reducers/admin/systemNodesToAdd";
import {
    getSystemNodesExternalSources,
    getSystemNodesExternalSourcesFetchingStatus,
    refreshSystemNodesExternalSources,
} from "../../../../reducers/admin/systemModel";
import { getSystemCurrentNodeData } from "../../../../reducers/admin/systemCurrentNode";
import {
    fetchGetDomainList,
    fetchGetSkillClustersByDomain,
    getDomains,
    getSC,
} from "../../../../reducers/admin/domainsAndSC";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        disableTypeFilter: true,
        nodeNameFilter: getSystemNodesToAddNameFilter(state),
        nodeSourceFilter: getSystemNodesToAddSourceFilter(state),
        nodeStatusFilter: getSystemNodesToAddStatusFilter(state),
        nodeDomainFilter: getSystemNodesToAddDomainFilter(state),
        sortingMode: getSystemNodesToAddSortingMode(state),
        externalSources: getSystemNodesExternalSources(state),
        loadingExternalSources: getSystemNodesExternalSourcesFetchingStatus(state),
        currentNodeData: getSystemCurrentNodeData(state),
        domains: getDomains(state),
        skill_clusters: getSC(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onMount: () => {
            dispatch(refreshSystemNodesExternalSources());
            dispatch(fetchGetDomainList());
            dispatch(fetchGetSkillClustersByDomain());
        },
        onUnmount: () => {
            dispatch(onResetSystemNodesToAddData());
        },
        onGetNodesList: () => dispatch(onFetchGetSystemNodesToAdd()),
        onUpdateNodesNameFilter: (filter) => dispatch(updateSystemNodesToAddNameFilter(filter)),
        onUpdateNodesSourceFilter: (filter) =>
            dispatch(onUpdateSystemNodesToAddSourceFilter(filter)),
        onUpdateNodesStatusFilter: (filter) =>
            dispatch(onUpdateSystemNodesToAddStatusFilter(filter)),
        onUpdateNodesDomainFilter: (filter) =>
            dispatch(onUpdateSystemNodesToAddDomainFilter(filter)),
        onUpdateNodesSortingMode: (sortingMode) =>
            dispatch(onUpdateSystemNodesToAddSortingMode(sortingMode)),
        onChooseDomain: (domainTitle) => dispatch(fetchGetSkillClustersByDomain(domainTitle)),
        onChooseSc: (skillClusterId) =>
            dispatch(
                fetchGetSkillsNodesToAdd(skillClusterId, {
                    nodesType: "SKILL",
                    related: true,
                })
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NodesFilters);
