
import React, { Component } from 'react';

class PersonSoftSkills extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 512 512">
                <path fill="#757575" d="M490.7,196.8c0.5-24.5-2.5-48.9-8.9-72.5c-8.6-29.3-26.5-55.1-51-73.4C382.5,13.3,337.5-2.4,284.9,0.3
                    c-27.8,1.9-55.4,5.9-82.5,11.8c-38.5,7.7-66.5,21.3-85.5,41.4c-15.1,16.1-26.7,35-34.2,55.8c-3.2,9.1-5.8,18.5-7.7,27.9l-0.7,3
                    c-1.8,6.6-4.2,13.1-7.2,19.3c-1.8,3.9-3.5,7.9-5.1,11.9c-2,4.5-2.8,9.4-2.3,14.3c1.7,7.6,6.2,14.4,12.5,19c4.2,3.8,6.9,6.5,7,9
                    c-0.3,1.3-0.8,2.5-1.5,3.6c-10.2,21.5-22.7,41.9-37.4,60.8c-6.1,7.5-12.5,16.2-11.2,25.1c1.9,6.7,6.3,12.4,12.4,15.9
                    c2.8,1.9,5.8,3.7,8.9,5.1c3.7,1.9,9.8,5,10.2,7.3c0.6,2.9,0.2,5.8-0.9,8.5c-1.3,2.7-2.6,5-3.7,7c-3.1,5.5-8.4,14.8-0.5,21.5
                    c1.8,1.6,5,4.1,7.9,6.3c-2.1,3.7-3.1,8-2.7,12.3c0.5,3.3,2.3,6.3,5,8.3c1.9,1.4,4,2.6,6,3.7c5,2.9,7,4.2,7.7,6.8
                    c0.5,3.5,0.1,7-0.9,10.4c-0.5,2.2-1,4.5-1.3,6.7c-0.9,7.8,0.1,15.6,2.9,22.9c4.4,16.2,20.8,26.1,37.2,22.6
                    c24.4-5.9,48.4-13.1,72-21.5c19.2,11.3,31.3,45.6,34.5,58.5c0.9,3.8,4.4,6.5,8.3,6.5l214.2-0.6c2.7,0,5.2-1.3,6.8-3.5
                    c1.6-2.2,2.1-5,1.3-7.6c-2.1-5.8-5-11.3-8.7-16.3c-2-2.9-3.8-5.9-5.5-9.1c-3.8-7.9-7.1-16-9.6-24.4c-4.9-16.5-8.2-33.4-10.1-50.5
                    c-3-25.5,0.8-51.4,11.2-74.9c6.3-13,14.1-25.3,23.2-36.6c5.1-6.9,10.5-14.1,15.3-21.7C483.2,246,490.5,221.7,490.7,196.8z
                    M440.8,278.5c-9.9,12.3-18.3,25.7-25.1,39.9c-11.5,26.3-15.8,55.2-12.5,83.8c1.9,18.1,5.5,36,10.6,53.5c2.8,9.2,6.4,18.2,10.6,26.8
                    c1.9,3.8,4.1,7.5,6.6,11l0.6,0.9l-193.5,0.5c-5.7-20.2-16-38.9-30.1-54.5c2.2-0.8,4.3-1.5,6.2-2.2c6.5-2.3,11.4-4,13.8-4.7
                    c36.6-11.5,54.9-28.1,54.4-49.4c-0.3-4.6-4.1-8.3-8.7-8.3c-2.3,0.1-4.4,1-6,2.6s-2.4,3.8-2.4,6.1c0.2,8.8-7,21.6-42.4,32.7
                    c-2.6,0.8-7.6,2.6-14.3,4.9c-6.1,2.1-13.6,4.7-21.7,7.5c-24,8.7-48.5,16.1-73.4,22.1c-8,1.5-15.8-3.5-17.6-11.5
                    c-1.8-4.8-2.5-9.9-2.1-15.1c0.2-1.8,0.6-3.5,1-5.3c1.8-5.9,2-12.2,0.8-18.2c-2.4-7.8-8.2-14.2-15.7-17.5l-1.8-1
                    c0.9-1.9,2-3.6,3.3-5.3c1.4-1.8,2-4.1,1.7-6.4c-0.3-2.3-1.5-4.3-3.4-5.7c-2-1.5-6.9-5.2-10.4-8c0.4-0.8,0.9-1.7,1.4-2.5
                    c1.3-2.3,2.8-5,4.3-8c2.7-6,3.5-12.6,2.3-19.1c-2-10.5-11.9-15.6-19.3-19.3c-2.5-1.2-4.9-2.6-7.1-4.1c-1.8-1.2-3.4-2.6-5-4.1
                    c0-1.3,1.4-4.4,7.9-12.4c15.2-19.7,28.3-40.9,39-63.4c2.1-3.5,3.2-7.5,3.3-11.6c-0.9-8.6-5.4-16.4-12.5-21.3
                    c-3.4-3.2-6.7-6.1-7.1-8.8c0-2.1,0.5-4.1,1.4-6c1.4-3.7,3-7.3,4.7-11c3.5-7.2,6.3-14.7,8.3-22.4l0.7-3c1.8-8.8,4.2-17.4,7.1-25.8
                    c6.7-18.6,17.1-35.6,30.6-50c16.5-17.4,41.5-29.4,76.5-36.4c26.4-5.8,53.1-9.6,80.1-11.5c48.3-2.6,89.7,12.1,134.4,47.1
                    c21.7,16,37.6,38.7,45.1,64.6c6,22,8.8,44.7,8.3,67.5c-0.1,21.7-6.5,43-18.3,61.3C450.9,264.9,445.7,271.8,440.8,278.5L440.8,278.5z"/>
                <path fill="#ffa7a6" d="M472.3,194.2c0,3.7-0.3,7.3-0.7,11c-0.6,7.1-2.1,14.2-4.3,21c-2.8,9.2-6.9,18-12,26.2l0.4,0.1l-1.4,1.4
                    c-6,9-13.7,16.7-22.6,22.7l-4.9,4.9h-0.1c-13,13.3-25,28.7-25,45.2c0.1,10.3-1.4,20.6-4.6,30.4c-3.5,10.6-13.5,17.8-24.7,17.7h0
                    c-10.9,0-20.7-6.6-24.8-16.7c-2.4-5.8-11-14.1-27-26.1c-23.2-17.4-30.9-47.1-21.6-81c-3.6-3.3-7.1-6.8-10.5-10.4
                    c-6.5-6.5-14.6-11.2-23.5-13.4c-4.7-1.1-9.4-1.7-14.2-2c-1.8-0.1-3.7-0.1-5.7-0.1c-19.6,0.8-39.2,2.8-58.6,6.2l-12,1.8
                    c-5.3,0.7-10.6,1.1-15.9,1.1c-1.7,0-3.3,0-4.9-0.1c-7.8-0.2-15.5-1.7-22.9-4.4c-21.4-8.2-36.2-27.8-38.2-50.6l-0.2-1.3
                    c-0.9-6.7-1.1-13.5-0.6-20.2c0.2-5.1,0.9-10.1,1.9-15.1c0.2-1,3.7-15.7,6.4-24.6c0.6-2,1-3.3,1.4-4.5c2.1-5.6,4.3-10.7,6.6-15.3
                    c6.1-12.3,13.9-23.6,23.3-33.5C141,54.9,152.4,47.3,165,42c6.3-2.8,12.7-5.1,19.3-7.1c6.9-2.1,14.1-3.9,21.5-5.3
                    c8.2-1.7,16.7-3.3,25.4-4.8c5.4-1,11-1.9,16.6-2.7c6.5-1,13-1.8,19.6-2.6c6.4-0.7,12.5-1.2,18.7-1.5c0.4,0,0.9-0.1,1.4-0.1
                    c3.4-0.2,6.8-0.2,10.2-0.2c2.6,0,5.2,0.1,7.7,0.2c6.6,0.2,13.1,0.8,19.6,1.9c19.2,3,37.7,9.2,54.9,18.4c4.7,2.4,9.2,5,13.6,7.8
                    c6.3,3.8,13.2,8.5,20.7,14.1c2.1,1.5,4.2,3.1,6.3,4.8c4,3,7.8,6.3,11.4,9.9c15.4,14.1,26.5,32.3,32.2,52.3
                    c3.5,12.7,5.9,25.8,7.1,38.9c0.6,6.8,1,13,1.1,19.1C472.3,187.9,472.3,191,472.3,194.2z"/>
                <path fill="#FF93A7" d="M319.3,245.3c-5.8,15.2-15.7,51.9,11.7,72.5c20.6,15.4,29.1,24.8,32.7,33.5c1.3,3.5,4.6,5.8,8.3,5.8
	                s7-2.3,8.3-5.8c2.6-8,3.8-16.3,3.7-24.7c0-25.4,18.4-46.3,35.2-62.7c0.1,0,0.2-0.1,0.3-0.2L319.3,245.3z"/>
                <path fill="#FFA7A6" d="M454.5,185.4c-0.1-6-0.4-11.9-1-17.7c-1.1-12.1-3.3-24.1-6.5-35.8c-4.9-17.2-14.5-32.7-27.7-44.7
                    c-3.1-3-6.4-5.9-9.8-8.5c-2-1.6-4-3.1-6-4.6c-6.6-4.9-13.2-9.4-19.6-13.3c-4.1-2.6-8.2-4.9-12.4-7.1c-15.4-8.3-32.1-13.9-49.3-16.6
                    c-5.8-0.9-11.7-1.5-17.6-1.7c-2.3-0.1-4.7-0.2-7.1-0.2c-3.1,0-6.3,0.1-9.4,0.3c-0.4,0-0.9,0.1-1.3,0.1c-5.8,0.3-11.7,0.8-17.6,1.4
                    c-6.4,0.7-12.6,1.5-19,2.5c-5.4,0.8-10.8,1.7-16.1,2.6c-8.5,1.5-16.9,3.1-25,4.8c-7.1,1.4-13.7,3.1-19.9,4.9
                    c-5.9,1.7-11.6,3.8-17.2,6.3c-10.5,4.3-20,10.6-28.1,18.6c-8.1,8.7-15,18.5-20.2,29.1c-2.1,4.3-4.1,8.9-5.8,13.6
                    c-0.4,1-0.7,2.1-1.1,3.5c-2.6,8.5-6,23.1-6,23.1c-0.9,4.2-1.4,8.5-1.6,12.8c-0.5,5.8-0.3,11.7,0.6,17.5v0.1
                    c1.2,16.4,11.6,30.6,26.9,36.5c5.6,2.1,11.6,3.2,17.6,3.3c5.8,0.3,11.6,0,17.4-0.9c26.3-4.9,53-7.6,79.8-7.9
                    c5.9,0.3,11.7,1.1,17.5,2.5c12,3,22.9,9.2,31.6,17.9c11.7,12.4,24.9,23.3,39.2,32.5c21,12.7,46.4,15.7,69.7,8
                    c12.3-5,22.9-13.6,30.3-24.7c4.6-7.2,8.1-14.9,10.6-23c1.9-5.7,3-11.6,3.5-17.6c0.4-3,0.6-5.9,0.6-8.9
                    C454.6,191.2,454.6,188.3,454.5,185.4z"/>
                <g>
                    <path fill="#F97D8C" d="M154.5,150.1c0,14.6-11.9,26.4-26.5,26.5h-18.1v-0.1c-0.9-5.8-1.1-11.6-0.6-17.5c0.3-0.1,0.7-0.1,1.1-0.1H128
		                c4.9,0,8.8-4,8.8-8.8c0-4.9,4-8.8,8.8-8.8C150.5,141.2,154.5,145.2,154.5,150.1z"/>
                    <path fill="#F97D8C" d="M154.5,203v12.4c0,0.3,0,0.6-0.1,0.9c-6-0.1-11.9-1.2-17.6-3.3V203c0-4.9,4-8.8,8.8-8.8
		                C150.5,194.2,154.5,198.2,154.5,203z"/>
                    <path fill="#F97D8C" d="M189.8,150.1v8.8c0,4.9,4,8.8,8.8,8.8h8.8c4.9,0,8.8,4,8.8,8.8c0,4.9-4,8.8-8.8,8.8h-8.8
		                c-14.6,0-26.5-11.9-26.5-26.5v-8.8c0-14.6,11.9-26.5,26.5-26.5h8.8c4.9,0,8.8,4,8.8,8.8c0,4.9-4,8.8-8.8,8.8h-8.8
		                C193.7,141.2,189.8,145.2,189.8,150.1z"/>
                    <path fill="#F97D8C" d="M163.3,114.8c0,4.9-4,8.8-8.8,8.8h-34.6c-1,0-2-0.2-3-0.5c0.4-1.4,0.8-2.6,1.1-3.5c1.8-4.7,3.7-9.3,5.8-13.6
		                h30.6C159.4,105.9,163.3,109.9,163.3,114.8z"/>
                    <path fill="#F97D8C" d="M172.1,79.4V58.2c5.6-2.4,11.4-4.5,17.2-6.3c0.3,0.9,0.4,1.8,0.4,2.7v24.8c0,4.9,4,8.8,8.8,8.8
		                c4.9,0,8.8,4,8.8,8.8c0,4.9-4,8.8-8.8,8.8C184,105.9,172.2,94.1,172.1,79.4z"/>
                    <path fill="#F97D8C" d="M269.2,123.6v17.7c0,14.6-11.9,26.5-26.5,26.5h-8.8c-4.9,0-8.8-4-8.8-8.8c0-4.9,4-8.8,8.8-8.8h8.8
		                c4.9,0,8.8-4,8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8C265.3,114.8,269.2,118.7,269.2,123.6z"/>
                    <path fill="#F97D8C" d="M286.9,123.6c-4.9,0-8.8-4-8.8-8.8v-8.8c0-4.9-4-8.8-8.8-8.8h-17.7c-4.9,0-8.8,4-8.8,8.8v8.8
		                c0,4.9-4,8.8-8.8,8.8c-4.9,0-8.8-4-8.8-8.8v-8.8c0-14.6,11.9-26.5,26.5-26.5h17.7c14.6,0,26.5,11.9,26.5,26.5v8.8
		                C295.7,119.6,291.8,123.6,286.9,123.6z"/>
                    <path fill="#F97D8C" d="M233.9,53v-8.8c0-0.7,0.1-1.3,0.3-1.9c5.3-1,10.7-1.9,16.1-2.6c0.9,1.4,1.3,3,1.3,4.6V53c0,4.9-4,8.8-8.8,8.8
		                C237.9,61.8,233.9,57.8,233.9,53z"/>
                    <path fill="#F97D8C" d="M269.2,53V37.1c5.9-0.6,11.7-1.1,17.6-1.4c0.1,0.2,0.1,0.3,0.1,0.5V53c0,4.9-4,8.8-8.8,8.8
		                S269.2,57.8,269.2,53z"/>
                    <path fill="#F97D8C" d="M304.6,79.4V36.1c0-0.2,0-0.4,0.1-0.6c5.9,0.2,11.8,0.8,17.6,1.7v33.5h8.8c4.9,0,8.8,4,8.8,8.8
		                c0,4.9-4,8.8-8.8,8.8h-17.7C308.5,88.3,304.6,84.3,304.6,79.4z"/>
                    <path fill="#F97D8C" d="M269.2,194.2v14.1c0,0.6-0.1,1.1-0.2,1.7c-5.7-1.4-11.6-2.2-17.5-2.5v-13.3c0-4.9,4-8.8,8.8-8.8
		                C265.3,185.4,269.2,189.3,269.2,194.2z"/>
                    <path fill="#F97D8C" d="M331,185.4c0,4.9-4,8.8-8.8,8.8h-8.8c-14.6,0-26.5-11.9-26.5-26.5v-8.8c0-4.9,4-8.8,8.8-8.8
		                c4.9,0,8.8,4,8.8,8.8v8.8c0,4.9,4,8.8,8.8,8.8h8.8C327.1,176.6,331,180.5,331,185.4z"/>
                    <path fill="#F97D8C" d="M348.7,105.9h8.8c4.9,0,8.8,4,8.8,8.8c0,4.9-4,8.8-8.8,8.8h-8.8c-4.9,0-8.8,4-8.8,8.8c0,4.9,4,8.8,8.8,8.8h8.8
		                c4.9,0,8.8,4,8.8,8.8c0,4.9-4,8.8-8.8,8.8h-8.8c-14.6,0-26.5-11.9-26.5-26.5C322.2,117.8,334.1,105.9,348.7,105.9z"/>
                    <path fill="#F97D8C" d="M366.3,88.3V61.8c0-3.5,2-6.6,5.2-8c4.1,2.1,8.2,4.4,12.4,7.1c0.1,0.3,0.1,0.6,0.1,1v26.5c0,4.9-4,8.8-8.8,8.8
		                C370.3,97.1,366.3,93.2,366.3,88.3z"/>
                    <path fill="#F97D8C" d="M419.3,105.9c0,14.6-11.9,26.4-26.5,26.5c-4.9,0-8.8-4-8.8-8.8c0-4.9,4-8.8,8.8-8.8c4.9,0,8.8-4,8.8-8.8V79.5
		                c0-2,0.7-3.8,1.9-5.4c2,1.5,4,3,6,4.6c3.4,2.6,6.7,5.4,9.8,8.5V105.9z"/>
                    <path fill="#F97D8C" d="M401.7,158.9v17.7c0,14.6-11.9,26.5-26.5,26.5h-8.8c-4.9,0-8.8-4-8.8-8.8c0-4.9,4-8.8,8.8-8.8h8.8
		                c4.9,0,8.8-4,8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8C397.7,150.1,401.7,154,401.7,158.9z"/>
                    <path fill="#F97D8C" d="M399.1,223.3c1.7,1.7,2.6,3.9,2.6,6.2c0,4.9-4,8.8-8.8,8.8h-26.5c-4.9,0-8.8,4-8.8,8.8v17.7
		                c0,1.2-0.2,2.4-0.7,3.4c-5.9-2-11.6-4.6-16.9-7.9v-13.2c0-14.6,11.9-26.4,26.5-26.5h26.5C395.2,220.7,397.4,221.6,399.1,223.3z"/>
                    <path fill="#F97D8C" d="M419.3,176.6c0-4.9,4-8.8,8.8-8.8h25.4c0.5,5.7,0.9,11.7,1,17.7h-26.4C423.3,185.4,419.3,181.4,419.3,176.6z" />
                    <path fill="#F97D8C" d="M452.8,203c0.4,0,0.8,0,1.2,0.1c-0.5,6-1.7,11.9-3.5,17.6h-22.3c-4.9,0-8.8-4-8.8-8.8c0-4.9,4-8.8,8.8-8.8 H452.8z" />
                </g>
            </svg>
        );
    }
}

export default PersonSoftSkills;
