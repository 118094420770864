import { connect } from "react-redux";
import { getTranslation } from "../../../../reducers/common/localization";
import { getStaffMemberJobs } from "../../../../reducers/job/organizationJobs";
import {
    fetchGetDepartmentsList,
    getDepartmentsListData,
} from "../../../../reducers/organization/departmentsList";
import {
    addNewTeam,
    fetchDefineNewTeam,
    fetchGetAllBOTeams,
    fetchGetTeams,
    getAllBOTeams,
    getTeamsList,
} from "../../../../reducers/organization/teams";
import TeamsList from "./TeamsList";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        data: getStaffMemberJobs(state),
        userDepts: getDepartmentsListData(state),
        userTeams: getTeamsList(state),
        allBOTeams: getAllBOTeams(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onMount: () => {
            dispatch(fetchGetDepartmentsList());
            dispatch(fetchGetAllBOTeams());
        },
        onFetchTeamsRelatedToDept: (departmentId) => dispatch(fetchGetTeams(departmentId)),
        onFetchGetAllBOTeams: () => dispatch(fetchGetAllBOTeams()),
        onDefineNewTeam: (departmentId, teamName) =>
            dispatch(fetchDefineNewTeam(departmentId, teamName)),

        onHandleBoTeam: (teamId, assignBo) => dispatch(addNewTeam(teamId, assignBo)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsList);
