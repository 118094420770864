
import React, { Component } from 'react';

class Sort extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z" />
            </svg>
        );
    }
}

export default Sort;
