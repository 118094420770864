import { connect } from 'react-redux';
import ApplicantSkills from './ApplicantSkills';
import { getTranslation } from '../../../reducers/common/localization';
import {
    fetchGetApplicantSkills,
    getApplicantSkillsFetchingStatus,
    getApplicantSkillsDataTree
} from '../../../reducers/applicant/applicantSkills';
import { getUserId } from '../../../reducers/user/userData';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        loading: getApplicantSkillsFetchingStatus(state),
        skillsTree: getApplicantSkillsDataTree(state),
        userId: getUserId(state),
        // TODO: remove hardcoded values
        imageSource: ""
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onMount: () => dispatch(fetchGetApplicantSkills())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantSkills);
