
import React, { Component } from 'react';

class PersonHardSkills extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 512 512">
                 <g fill="#757575">
                    <path d="M452.1,215.3c-4.5-1.1-9.1,1.7-10.2,6.3c-3,12.9-8.2,25.1-15.4,36.3c-4.4,7-9.5,13.9-14.5,20.6
                        c-9.9,12.3-18.3,25.7-25.1,40c-11.6,26.3-15.9,55.2-12.5,83.8c2,18.1,5.6,36,10.8,53.5c2.8,9.2,6.3,18.1,10.6,26.7
                        c1.9,3.8,4.2,7.6,6.6,11.1l0.6,0.9l-193.6,0.6c-5.7-20.2-16-38.9-30-54.5l7.7-2.7c5.7-2,10.1-3.5,12.3-4.3
                        c36.5-11.6,54.8-28.3,54.3-49.5c0-2.3-1-4.4-2.6-6s-3.8-2.4-6.1-2.3c-4.7,0.1-8.4,4-8.3,8.7c0.2,8.7-7,21.5-42.5,32.8
                        c-2.3,0.7-6.8,2.3-12.7,4.4c-6.3,2.2-14.4,5-23.3,8h-0.1c-24,8.8-48.5,16.2-73.3,22.2c-7.9,1.5-15.7-3.5-17.6-11.4
                        c-1.8-4.8-2.6-10-2.1-15.1c0.2-1.7,0.6-3.5,1-5.2c1.8-6,2.1-12.3,0.9-18.4c-2.5-7.8-8.2-14.2-15.8-17.4l-1.8-1
                        c0.9-1.9,2-3.6,3.3-5.3c1.4-1.8,2.1-4.1,1.8-6.4s-1.5-4.4-3.4-5.7c-2-1.5-6.8-5.1-10.4-8c0.4-0.7,0.9-1.6,1.3-2.3
                        c1.3-2.4,2.9-5.1,4.4-8.3c2.7-6,3.5-12.6,2.3-19.1c-2-10.4-12-15.5-19.3-19.2c-2.5-1.2-4.8-2.5-7-4.1c-1.8-1.2-3.5-2.6-5.1-4.1
                        c0-1.3,1.4-4.4,7.9-12.3c15.3-19.7,28.4-40.9,39-63.4c2-3.5,3.1-7.5,3.3-11.5c-0.9-8.6-5.5-16.4-12.5-21.3
                        c-3.5-3.2-6.7-6.2-7.1-8.7c0.1-2.1,0.5-4.1,1.4-6.1c1.4-3.7,3-7.2,4.6-10.8c3.6-7.4,6.5-15.2,8.5-23.1c2-9.6,4.5-19,7.7-28.2
                        c6.7-18.6,17.1-35.6,30.6-50c16.3-17.3,42-29.5,76.5-36.3c26.4-5.8,53.1-9.6,80.1-11.5c9.1-0.5,18.1-0.3,27.2,0.6
                        c3,0.3,6-1.1,7.8-3.6s2.1-5.7,0.8-8.5c-1.3-2.8-3.9-4.7-7-4.9c-9.9-0.9-19.8-1.1-29.7-0.6c-27.8,1.9-55.3,5.9-82.5,11.8
                        c-38.5,7.7-66.4,21.2-85.5,41.3c-15.4,15.8-27,34.8-34.5,55.5c-3.5,10.1-6.3,20.4-8.4,30.8c-1.8,6.7-4.3,13.2-7.3,19.5
                        c-1.7,3.9-3.5,7.8-5,11.8c-1.9,4.5-2.7,9.4-2.3,14.3c1.8,7.6,6.2,14.4,12.5,19c4.2,3.8,6.9,6.5,7,8.9c-0.3,1.3-0.8,2.5-1.5,3.7
                        c-10.2,21.5-22.7,41.9-37.3,60.7c-6.2,7.5-12.7,16.3-11.3,25.1c1.8,6.8,6.3,12.5,12.4,16c2.8,1.9,5.8,3.7,8.9,5.1
                        c3.7,1.9,9.9,5,10.3,7.3c0.5,2.9,0.2,5.9-1,8.6c-1.3,2.7-2.6,5.1-3.8,7.1c-3.1,5.5-8.4,14.8-0.5,21.2c1.8,1.7,5,4.2,7.9,6.4
                        c-2.1,3.7-3.1,8-2.8,12.3c0.4,3.4,2.3,6.4,5,8.4c1.9,1.4,4,2.6,6,3.7c5,2.9,7,4.2,7.7,6.8c0.5,3.5,0.1,7-0.9,10.3
                        c-0.5,2.2-1,4.4-1.3,6.7c-0.8,7.8,0.1,15.7,2.9,23c3.8,13.6,16.1,23.1,30.2,23.3c2.3,0,4.7-0.3,7-0.7c24.4-5.8,48.4-13,71.9-21.5
                        c19.2,11.3,31.4,45.6,34.5,58.5c0.9,3.8,4.4,6.5,8.3,6.5l214.2-0.6c2.7,0,5.3-1.3,6.9-3.5s2.1-5,1.3-7.6c-2.1-5.8-5-11.2-8.6-16.2
                        c-2-2.9-3.9-6-5.5-9.2c-3.9-7.8-7.1-16-9.6-24.3c-4.9-16.5-8.2-33.4-10.1-50.5c-3-25.5,0.9-51.4,11.2-74.9
                        c6.3-13.1,14.1-25.3,23.2-36.6c5.1-6.9,10.4-14.1,15.2-21.6c8.2-12.7,14.1-26.8,17.6-41.6C459.5,220.8,456.6,216.3,452.1,215.3z"/>
                    <path d="M101.9,127.1c5.1-14.2,13.1-27.3,23.4-38.4c11.4-12,31-20.9,58.5-26.4c4.6-0.9,7.6-5.4,6.7-10c-0.9-4.6-5.4-7.6-10-6.7
		                c-31,6.2-53.7,16.7-67.5,31.4c-11.9,12.8-21.1,27.8-27,44.3c-2.9,8.5-5.2,17.2-7,26l16.7,3.7C97.1,142.8,99.3,134.9,101.9,127.1z"/>
                    <path d="M76.8,170.7c4.7,0,8.5,3.8,8.5,8.5s-3.8,8.5-8.5,8.5s-8.5-3.8-8.5-8.5S72.1,170.7,76.8,170.7z" />
                </g>
                <g>
                    <path fill="#35495E" d="M512,97.6v16.7c0,3.4-2.4,6.2-5.6,6.9l-25.2,5c-1.7,6.4-4.3,12.6-7.6,18.4l14.3,21.4c1.9,2.8,1.5,6.5-0.9,8.9
                        l-11.8,11.8c-2.4,2.4-6.1,2.7-8.9,0.9l-21.4-14.3c-5.8,3.3-11.9,5.9-18.4,7.6l-5.1,25.3c-0.7,3.3-3.6,5.7-6.9,5.6h-16.7
                        c-3.4,0-6.2-2.4-6.9-5.6l-5-25.2c-6.4-1.7-12.6-4.3-18.4-7.6L346,187.7c-2.8,1.9-6.5,1.5-8.9-0.9L325.3,175
                        c-2.4-2.4-2.7-6.1-0.9-8.9l14.3-21.4c-3.4-5.8-6-12-7.7-18.5l-25.3-5.1c-3.3-0.7-5.7-3.6-5.6-6.9V97.6c0-3.4,2.4-6.2,5.6-6.9
                        l25.3-5c1.7-6.4,4.3-12.6,7.6-18.4l-14.3-21.4c-1.9-2.8-1.5-6.5,0.9-8.9L337,25.1c2.4-2.4,6.1-2.7,8.9-0.9l21.4,14.3
                        c5.8-3.4,12-5.9,18.4-7.7l5.1-25.3c0.7-3.3,3.6-5.6,6.9-5.6h16.7c3.4,0,6.2,2.4,6.9,5.6l5,25.2c6.4,1.7,12.6,4.3,18.4,7.6
                        l21.4-14.3c2.8-1.9,6.5-1.5,8.9,0.9l11.8,11.8c2.4,2.4,2.7,6.1,0.9,8.9l-14.3,21.5c3.3,5.8,5.9,12,7.7,18.4l25.3,5.1
                        C509.6,91.4,512,94.3,512,97.6z"/>
                    <path fill="#2C3E50" d="M406.1,44.1c34.1,0,61.8,27.7,61.8,61.8s-27.7,61.8-61.8,61.8s-61.8-27.7-61.8-61.8S371.9,44.1,406.1,44.1z" />
                    <path fill="#35495E" d="M406.1,61.8c24.4,0,44.1,19.8,44.1,44.1s-19.8,44.1-44.1,44.1s-44.1-19.8-44.1-44.1S381.7,61.8,406.1,61.8z" />
                    <path fill="#3F5C6C" d="M441.4,157.3v20.8c0,4.2-3,7.8-7.1,8.7l-31.6,6.4c-2.2,8-5.4,15.7-9.5,23l17.9,26.7c2.3,3.5,1.8,8.2-1.1,11.1
                        l-14.7,14.7c-3,3-7.6,3.5-11.1,1.1l-26.7-17.9c-7.2,4.2-14.9,7.4-23,9.5l-6.4,31.6c-0.8,4.1-4.5,7.1-8.7,7.1h-20.8
                        c-4.2,0-7.8-3-8.7-7.1l-6.4-31.6c-8-2.2-15.7-5.4-23-9.5l-26.7,17.9c-3.5,2.3-8.2,1.8-11.1-1.1L208,254c-3-3-3.5-7.6-1.1-11.1
                        l17.9-26.7c-4.2-7.2-7.4-14.9-9.5-23l-31.6-6.4c-4.1-0.8-7.1-4.5-7.1-8.7v-20.8c0-4.2,3-7.8,7.1-8.7l31.6-6.4
                        c2.2-8,5.4-15.7,9.5-23l-17.9-26.7c-2.3-3.5-1.8-8.2,1.1-11.1l14.7-14.7c3-3,7.6-3.5,11.1-1.1l26.7,17.9c7.2-4.2,14.9-7.4,23-9.5
                        l6.4-31.6c0.8-4.1,4.5-7.1,8.7-7.1h20.8c4.2,0,7.8,3,8.7,7.1l6.4,31.6c8,2.2,15.7,5.4,23,9.5l26.7-17.9c3.5-2.3,8.2-1.8,11.1,1.1
                        L410,81.5c3,3,3.5,7.6,1.1,11.1l-17.9,26.7c4.2,7.2,7.4,14.9,9.5,23l31.6,6.4C438.4,149.5,441.4,153.1,441.4,157.3z"/>
                    <path fill="#35495E" d="M309,97.1c39,0,70.6,31.6,70.6,70.6S348,238.3,309,238.3s-70.6-31.6-70.6-70.6S270,97.1,309,97.1z" />
                    <path fill="#2C3E50" d="M309,114.8c29.3,0,53,23.7,53,53s-23.7,53-53,53s-53-23.7-53-53S279.7,114.8,309,114.8z" />
                    <path fill="#547580" d="M309,158.9c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8s-8.8-4-8.8-8.8S304.1,158.9,309,158.9z" />
                </g>
            </svg>
        );
    }
}

export default PersonHardSkills;
