import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import _ from "lodash";
import { NODE_SORTING, NODE_TYPE } from "../../constants/systemNodesConstants";
import { getSystemCurrentNodeId } from "./systemCurrentNode";

//- Actions
export const fetchGetSystemNodesToAddFailed = createAction("FETCH_GET_NODES_TO_ADD_DATA_FAILED");
export const fetchGetSystemNodesToAddSuccess = createAction("FETCH_GET_NODES_TO_ADD_DATA_SUCCESS");
export const onFetchGetSystemNodesToAddSuccess = (data) => (dispatch, getState) => {
    let nodesType = getSystemNodesToAddNodesType(getState());
    data.content = _.map(data.content, (node) => {
        if (nodesType === NODE_TYPE.SKILL) {
            return {
                id: node.id,
                title: node.titles.en,
                clusters: node.clusters.map((cluster) => ({
                    id: cluster.id,
                    title: cluster.titles.en,
                    domains: cluster.domains
                })),
                type: node.resourceType,
                source: node.resourceOwner && null,
                status: node.status,
                domain: node.domain && [],
            };
        }
        if (nodesType === NODE_TYPE.PROFILE) {
            return {
                id: node.id,
                title: node.titles.en,
                description: node.description,
                type: node.resourceType,
                status: node.status && null,
                source: node.resourceOwner && null,
                domain: node.domain && [],
            };
        }
    });
    dispatch(fetchGetSystemNodesToAddSuccess(data));
};

export const handleFetchSkillsToAdd = (data) => (dispatch) => {
    const newData = data.content.map((node) => {
        return {
            id: node.id,
            title: node.titles.en,
            clusters: node.clusters.map((cluster) => ({
                id: cluster.id,
                title: cluster.titles.en,
                domains: cluster.domains
            })),
            type: node.resourceType,
            source: node.resourceOwner && null,
            status: node.status,
            domain: node.domain && [],
        };
    });

    dispatch(fetchGetSystemNodesToAddSuccess({ ...data, content: newData }));
};

export const fetchGetSystemNodesToAdd = createAction(
    "FETCH_GET_NODES_TO_ADD_DATA",
    (nodeId, params, page) => Api.getSystemNotRelatedNodes(nodeId, params, page),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetSystemNodesToAddSuccess,
        onFail: fetchGetSystemNodesToAddFailed,
    })
);

export const fetchGetSkillsNodesToAdd = createAction(
    "FETCH_GET_SKILLS_NODES_TO_ADD",
    (nodeId, params) => Api.getSystemNotRelatedNodes(nodeId, params),
    () => ({
        apiCall: true,
        onSuccess: handleFetchSkillsToAdd,
        onFail: fetchGetSystemNodesToAddFailed,
    })
);

export const onSetNodesTypeAndFetchSystemNodesToAddData = (nodesType) => (dispatch) => {
    dispatch(updateSystemNodesToAddNodesType(nodesType));
    dispatch(onFetchGetSystemNodesToAdd({ nodesType: nodesType }));
};

export const onFetchGetSystemNodesToAdd =
    (params = {}) =>
    (dispatch, getState) => {
        let nodesId = getSystemCurrentNodeId(getState());
        let nodesType = getSystemNodesToAddNodesType(getState());
        let nameFilter = getSystemNodesToAddNameFilter(getState());
        let sourceFilter = getSystemNodesToAddSourceFilter(getState());
        let statusFilter = getSystemNodesToAddStatusFilter(getState());
        let domainFilter = getSystemNodesToAddDomainFilter(getState());
        let sortingMode = getSystemNodesToAddSortingMode(getState());
        let listParams = {
            nodesType: !_.isUndefined(params.nodesType) ? params.nodesType : nodesType,
            nameFilter: !_.isUndefined(params.nameFilter) ? params.nameFilter : nameFilter,
            sourceFilter: !_.isUndefined(params.sourceFilter) ? params.sourceFilter : sourceFilter,
            statusFilter: !_.isUndefined(params.statusFilter) ? params.statusFilter : statusFilter,
            domainFilter: !_.isUndefined(params.domainFilter) ? params.domainFilter : domainFilter,
            sortingMode: !_.isUndefined(params.sortingMode) ? params.sortingMode : sortingMode,
        };
        dispatch(fetchGetSystemNodesToAdd(nodesId, listParams, params.page));
    };

export const onFetchGetSystemNodesToAddByPage = (page) => (dispatch) => {
    dispatch(onFetchGetSystemNodesToAdd({ page: page }));
};

export const updateSystemNodesToAddNodesType = createAction("UPDATE_NODES_TO_ADD_NODES_TYPE");
export const updateSystemNodesToAddNameFilter = createAction("UPDATE_NODES_TO_ADD_NAME_FILTER");
export const updateSystemNodesToAddSourceFilter = createAction("UPDATE_NODES_TO_ADD_SOURCE_FILTER");
export const onUpdateSystemNodesToAddSourceFilter = (sourceFilter) => (dispatch) => {
    dispatch(updateSystemNodesToAddSourceFilter(sourceFilter));
    let params = {
        sourceFilter: sourceFilter,
    };
    dispatch(onFetchGetSystemNodesToAdd(params));
};
export const updateSystemNodesToAddStatusFilter = createAction("UPDATE_NODES_TO_ADD_STATUS_FILTER");
export const onUpdateSystemNodesToAddStatusFilter = (statusFilter) => (dispatch) => {
    dispatch(updateSystemNodesToAddStatusFilter(statusFilter));
    let params = {
        statusFilter: statusFilter,
    };
    dispatch(onFetchGetSystemNodesToAdd(params));
};

export const updateSystemNodesToAddSkillCluster = createAction("UPDATE_SYSTEM_NODES_TO_ADD_SC");

export const onUpdateSystemNodesToAddSkillCluster = (scFilter) => (dispatch) => {
    dispatch(updateSystemNodesToAddSkillCluster(scFilter));
    let params = {
        scFilter,
    };

    dispatch(onFetchGetSystemNodesToAdd(params));
};

export const updateSystemNodesToAddDomainFilter = createAction("UPDATE_NODES_TO_ADD_DOMAIN_FILTER");
export const onUpdateSystemNodesToAddDomainFilter = (domainFilter) => (dispatch) => {
    dispatch(updateSystemNodesToAddDomainFilter(domainFilter));
    let params = {
        domainFilter: domainFilter,
    };
    dispatch(onFetchGetSystemNodesToAdd(params));
};
export const updateSystemNodesToAddSortingMode = createAction("UPDATE_NODES_TO_ADD_SORTING");
export const onUpdateSystemNodesToAddSortingMode = (sortingMode) => (dispatch) => {
    dispatch(updateSystemNodesToAddSortingMode(sortingMode));
    dispatch(onFetchGetSystemNodesToAdd({ sortingMode: sortingMode }));
};

export const onResetSystemNodesToAddData = createAction("RESET_NODES_TO_ADD_DATA");

export const updateSelectedSystemNodesToAdd = createAction("UPDATE_SELECTED_NODES_TO_ADD");
export const onSelectSystemNodeToAdd = (nodeId) => (dispatch, getState) => {
    let data = getSystemNodesToAddData(getState());
    let selectedNodesToAdd = _.cloneDeep(getSelectedSystemNodesToAdd(getState())) || [];
    let nodeToAdd = _.find(data, (elem) => elem.id === nodeId);
    selectedNodesToAdd.push(nodeToAdd);
    dispatch(updateSelectedSystemNodesToAdd(selectedNodesToAdd));
};
export const onRemoveSelectedSystemNodeToAdd = (nodeId) => (dispatch, getState) => {
    let selectedNodesToAdd = _.cloneDeep(getSelectedSystemNodesToAdd(getState())) || [];
    selectedNodesToAdd = _.filter(selectedNodesToAdd, (elem) => elem.id !== nodeId);
    dispatch(updateSelectedSystemNodesToAdd(selectedNodesToAdd));
};

//- State
const initialState = {
    nodesType: null,
    data: null,
    currentPage: null,
    totalPages: null,
    error: null,
    fetching: false,
    nameFilter: "",
    sourceFilter: null,
    statusFilter: null,
    domainFilter: null,
    sortingMode: NODE_SORTING.NAME,
    selectedNodesToAdd: [],
    skillClustersFilter: null,
};

//- Reducers
export default handleActions(
    {
        UPDATE_NODES_TO_ADD_NODES_TYPE: (state, action) => {
            return { ...state, nodesType: action.payload };
        },

        UPDATE_SKILL_CLUSTER_FILTER: (state, action) => {
            return {
                ...state,
                skillClusterFilter: action.payload,
            };
        },
        FETCH_GET_NODES_TO_ADD_DATA: (state) => {
            return {
                ...state,
                data: null,
                currentPage: null,
                totalPages: null,
                error: null,
                fetching: true,
            };
        },
        FETCH_GET_NODES_TO_ADD_DATA_FAILED: (state, action) => {
            return {
                ...state,
                data: null,
                currentPage: null,
                totalPages: null,
                error: action.payload,
                fetching: false,
            };
        },
        FETCH_GET_NODES_TO_ADD_DATA_SUCCESS: (state, action) => {
            return {
                ...state,
                data: action.payload.content,
                currentPage: action.payload.number,
                totalPages: action.payload.totalPages,
                error: null,
                fetching: false,
            };
        },
        UPDATE_NODES_TO_ADD_NAME_FILTER: (state, action) => {
            return { ...state, nameFilter: action.payload };
        },
        UPDATE_NODES_TO_ADD_SOURCE_FILTER: (state, action) => {
            return { ...state, sourceFilter: action.payload };
        },
        UPDATE_NODES_TO_ADD_STATUS_FILTER: (state, action) => {
            return { ...state, statusFilter: action.payload };
        },
        UPDATE_NODES_TO_ADD_DOMAIN_FILTER: (state, action) => {
            return { ...state, domainFilter: action.payload };
        },
        UPDATE_NODES_TO_ADD_SORTING: (state, action) => {
            return { ...state, sortingMode: action.payload };
        },
        RESET_NODES_TO_ADD_DATA: () => {
            return { ...initialState };
        },
        UPDATE_SELECTED_NODES_TO_ADD: (state, action) => {
            return { ...state, selectedNodesToAdd: action.payload };
        },
    },
    initialState
);

//- Selectors
export const getSystemNodesToAddState = (state) => state.systemNodesToAdd;

export const getSystemNodesToAddNodesType = (state) => getSystemNodesToAddState(state).nodesType;

export const getSystemNodesToAddData = (state) => getSystemNodesToAddState(state).data;

export const getSystemNodesToAddCurrentPage = (state) =>
    getSystemNodesToAddState(state).currentPage;

export const getSystemNodesToAddTotalPages = (state) => getSystemNodesToAddState(state).totalPages;

export const getSystemNodesToAddFetchingStatus = (state) =>
    getSystemNodesToAddState(state).fetching;

export const getSystemNodesToAddNameFilter = (state) => getSystemNodesToAddState(state).nameFilter;

export const getSystemNodesToAddSourceFilter = (state) =>
    getSystemNodesToAddState(state).sourceFilter;

export const getSystemNodesToAddStatusFilter = (state) =>
    getSystemNodesToAddState(state).statusFilter;

export const getSystemNodesToAddDomainFilter = (state) =>
    getSystemNodesToAddState(state).domainFilter;

export const getSystemNodesToAddSortingMode = (state) =>
    getSystemNodesToAddState(state).sortingMode;

export const getSelectedSystemNodesToAdd = (state) =>
    getSystemNodesToAddState(state).selectedNodesToAdd;
