import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import "./AddJobRequiredSkillsModal.scss";
import { withRouter } from "react-router-dom";
import JobSkillsSetup from "../../skills/skillsSetup/JobSkillsSetup.container";

class AddJobRequiredSkillsModal extends Component {
    componentDidMount() {
        this.props.onMount();
    }

    renderModalButtons() {
        const { translation } = this.props;
        return (
            <Button color="primary" onClick={this.checkSkills}>
                {_.get(translation, "shared.save_button_label")}
            </Button>
        );
    }

    checkSkills = () => {
        if (this.props.skills.length && this.props.skills.find((elem) => _.isNil(elem.level))) {
            this.props.onShowSkillsWithoutLevelModal();
            return;
        } else if (!this.props.profiles || this.props.profiles.length === 0) {
            this.props.onShowNoProfilesModal();
            return;
        } else {
            this.props.onClose();
        }
    };

    render() {
        const { translation, isOpen, onClose } = this.props;
        return (
            <>
                <Modal isOpen={isOpen} toggle={onClose} className="add-job-skills-modal-container">
                    <ModalHeader toggle={onClose} tag="h3">
                        {_.get(translation, "post_job.first_step.add_skills_button_label")}
                    </ModalHeader>
                    <ModalBody>
                        <JobSkillsSetup />
                    </ModalBody>
                    <ModalFooter className="content-center">
                        {this.renderModalButtons()}
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

AddJobRequiredSkillsModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(AddJobRequiredSkillsModal);
