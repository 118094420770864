import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";

//- Actions
export const fetchGetOpenJobsListFailed = createAction("FETCH_GET_ORGANIZATION_JOBS_LIST_FAILED");
export const fetchGetOpenJobsListSuccess = createAction("FETCH_GET_ORGANIZATION_JOBS_LIST_SUCCESS");
export const fetchGetOpenJobsList = createAction(
    "FETCH_GET_ORGANIZATION_JOBS_LIST",
    () => Api.getOpenJobsList(), // fix here
    () => ({
        apiCall: true,
        onSuccess: fetchGetOpenJobsListSuccess,
        onFail: fetchGetOpenJobsListFailed,
    })
);

export const fetchPostOrganizationJobFailed = createAction("FETCH_POST_ORGANIZATION_JOB_FAILED");
export const fetchPostOrganizationJobSuccess = createAction("FETCH_POST_ORGANIZATION_JOB_SUCCESS");
export const fetchPostOrganizationJob = createAction(
    "FETCH_POST_ORGANIZATION_JOB",
    (data) => Api.postJob(data),
    () => ({
        apiCall: true,
        onSuccess: fetchPostOrganizationJobSuccess,
        onFail: fetchPostOrganizationJobFailed,
    })
);
export const fetchUpdateOrganizationJob = createAction(
    "FETCH_POST_ORGANIZATION_JOB",
    (jobId, data) => Api.updateJob(jobId, data),
    () => ({
        apiCall: true,
        onSuccess: fetchPostOrganizationJobSuccess,
        onFail: fetchPostOrganizationJobFailed,
    })
);

export const updateOrganizationJobsMedia = createAction("UPDATE_ORGANIZATION_JOBS_MEDIA");
export const onUpdateOrganizationJobMediaFail = createAction("UPDATE_ORGANIZATION_JOBS_FAIL");
export const onUpdateOrganizationJobMediaSuccess = (data) => (dispatch) => {
    dispatch(updateOrganizationJobsMedia(data));
};
export const fetchUpdateOrganizationJobMedia = createAction(
    "FETCH_UPDATE_ORGANIZATION_JOB_MEDIA",
    (jobId, data) => Api.updateJobMedia(jobId, data),
    () => ({
        apiCall: true,
        onSuccess: onUpdateOrganizationJobMediaSuccess,
        onFail: onUpdateOrganizationJobMediaFail,
    })
);

export const updateStaffMemberJobs = createAction("UPDATE_STAFF_MEMBER_JOBS");
export const onGetStaffMemberJobsFail = createAction("SET_STAFF_MEMBER_ERROR");
export const onGetStaffMemberJobsSuccess = (data) => (dispatch) => {
    dispatch(updateStaffMemberJobs(data));
};
export const fetchGetStaffMemberJobs = createAction(
    "FETCH_GET_STAFF_MEMBER_JOBS",
    () => Api.getStaffMemberJob(),
    () => ({
        apiCall: true,
        onSuccess: onGetStaffMemberJobsSuccess,
        onFail: onGetStaffMemberJobsFail,
    })
);

//- State
const initialState = {
    staffMemberJobs: [],
    organizationJobsList: null,
    error: null,
    fetching: false,
};

//- Reducers
export default handleActions(
    {
        FETCH_POST_ORGANIZATION_JOB: (state) => {
            return { ...state, error: null, fetching: true };
        },
        FETCH_POST_ORGANIZATION_JOB_FAILED: (state, action) => {
            return { ...state, organizationJobsList: null, error: action.payload, fetching: false };
        },
        FETCH_POST_ORGANIZATION_JOB_SUCCESS: (state, action) => {
            return {
                ...state,
                organizationJobsList: action.payload.content,
                error: null,
                fetching: false,
            };
        },
        UPDATE_ORGANIZATION_JOBS_MEDIA: (state, action) => {
            return {
                ...state,
                staffMemberJobs: state.staffMemberJobs.map((job) => {
                    return job.id === action.payload.id
                        ? { ...action.payload, isUpdated: true, name: action.payload.jobTitle }
                        : { ...job };
                }),
            };
        },
        UPDATE_ORGANIZATION_JOBS_FAIL: (state, action) => {
            return { ...state, error: action.payload };
        },
        UPDATE_STAFF_MEMBER_JOBS: (state, action) => {
            return {
                ...state,
                staffMemberJobs: action.payload,
            };
        },
        SET_STAFF_MEMBER_ERROR: (state, action) => {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
    initialState
);

//- Selectors
export const getPostJobLoading = (state) => getOrganizationJob(state).fetching;

export const getOrganizationJob = (state) => state.organizationJobs;

export const getStaffMemberJobs = (state) => state.organizationJobs.staffMemberJobs;
