import React, { Component } from "react";
import ReactDOM from "react-dom";
import "../../applicant/applicantAccount/ApplicantAccount.scss";
import _ from "lodash";
import {
    Button,
    TabContent,
    TabPane,
    FormGroup,
    Row,
    Input,
    Badge,
    Modal,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import CustomSelect from "../../common/inputs/customSelect/CustomSelect";
import ContinentAndCountryFormGroups from "../../common/formGroups/continentAndCountryFormGroups/ContinentAndCountryFormGroups.container";
import Icon from "../../common/icon/Icon";
import CustomSwitch from "../../common/controls/customSwitch/CustomSwitch";
import { FormUtils } from "../../../utils/formUtils";
import {
    COMMUTE_OPTION,
    COMMUTE_TIME_OPTION_TRANSLATION,
    COMMUTE_OPTION_SHORT_TRANSLATION,
    START_WORK_PERIOD_OPTION_TRANSLATION,
    USER_SUB_ROLE,
} from "../../../constants/constants";
import { Utils } from "../../../utils/utils";
import RichMedia from "../../applicant/applicantJobs/richMedia/RichMedia.container";
import { withRouter } from "react-router";

export const ACCOUNT_SETUP_STEPS = {
    JOB_RELATED_DATA: "JOB_RELATED_DATA",
    LOCATION_AND_COMMUTE_DATA: "LOCATION_AND_COMMUTE_DATA",
    RICH_MEDIA: "RICH_MEDIA",
};

export const REQUIRED_FIELDS = {
    [ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA]: ["firstName", "lastName", "email"],
    [ACCOUNT_SETUP_STEPS.LOCATION_AND_COMMUTE_DATA]: ["countryCode", "city"],
    [ACCOUNT_SETUP_STEPS.RICH_MEDIA]: [],
};

class EmployeeProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab:
                this.props.location?.state?.activeTab || ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA,
            firstName: props.userData.firstName || "",
            lastName: props.userData.lastName || "",
            email: props.userData.email || "",
            countryCode: props.userData.countryCode || null,
            city: props.userData.city || "",
            district: props.userData.district || "",
            region: props.userData.region || "",
            street: props.userData.street || "",
            maxCommuteTime: props.userData.maxCommuteTime || null,
            twitterLink: props.userData.twitterLink || "",
            linkedInLink: props.userData.linkedInLink || "",
            facebookLink: props.userData.facebookLink || "",
            emptyFieldError: null,
            userProfilePictureFile: this.props.userData.profilePictureFile,
            orgProfilePictureFile: this.props.userOrganization.profilePictureFile,
            departmentsProfilePictureFile: this.props.userDepts.profilePictureFile,
            teamsProfilePictureFile: this.props.userTeams.profilePictureFile,
            userVideoFileURI: this.props.userData.videoFile,
            orgVideoFileURI: this.props.userOrganization.videoFile,
            departmentsVideoFileURI: this.props.userDepts.videoFile,
            teamsVideoFileURI: this.props.userTeams.videoFile,
            userPictureFilesURI: this.props.userData.profilePictureFile,
            orgPictureFilesURI: this.props.userOrganization.profilePictureFile,
            departmentsPictureFilesURI: this.props.userDepts.profilePictureFile,
            teamsPictureFilesURI: this.props.userTeams.profilePictureFile,
            userAudioFileURI: this.props.userData.audioFile,
            orgAudioFileURI: this.props.userOrganization.audioFile,
            departmentsAudioFileURI: this.props.userDepts.audioFile,
            teamsAudioFileURI: this.props.userTeams.audioFile,
            userProfileCvFileURI: this.props.userData.cvFile,
            orgProfileCvFileURI: this.props.userOrganization.cvFile,
            departmentsProfileCvFileURI: this.props.userDepts.cvFile,
            teamsProfileCvFileURI: this.props.userTeams.cvFile,
        };

        this.containerRef = React.createRef();
    }

    render() {
        return (
            <div className="applicant-account">
                <div className="account-header-container">
                    <h4 className="page-header">
                        {_.get(this.props.translation, "applicant_account_page.page_label")}
                    </h4>
                    <Button
                        className="save-changes-button"
                        color="primary"
                        onClick={this.onSaveChanges}
                    >
                        {_.get(this.props.translation, "actions.save_button")}
                    </Button>
                </div>
                {this.renderContent()}
                {this.renderEmptyFiledErrorModal()}
            </div>
        );
    }

    renderEmptyFiledErrorModal() {
        return (
            <Modal isOpen={!_.isNil(this.state.emptyFieldError)} toggle={this.onCloseModal}>
                <ModalBody>
                    {Utils.getTagsFromStringWithStyles(
                        _.replace(
                            _.get(
                                this.props.translation,
                                "employee_account_page.empty_fields_page"
                            ),
                            "{fieldName}",
                            this.state.emptyFieldError
                        )
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.onCloseModal}>
                        {_.get(this.props.translation, "actions.ok_button")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    onCloseModal = () => {
        this.setState({ emptyFieldError: null });
    };

    renderContent() {
        let tabsOptions = [
            {
                value: ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA,
                label: _.get(this.props.translation, "employee_account_page.job_related_tab_label"),
                progress: this.getTabCompleteProgress(ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA),
            },
            {
                value: ACCOUNT_SETUP_STEPS.LOCATION_AND_COMMUTE_DATA,
                label: _.get(this.props.translation, "employee_account_page.location_tab_label"),
                progress: this.getTabCompleteProgress(
                    ACCOUNT_SETUP_STEPS.LOCATION_AND_COMMUTE_DATA
                ),
            },
            {
                value: ACCOUNT_SETUP_STEPS.RICH_MEDIA,
                label: _.get(this.props.translation, "applicant_account_page.rich_media_tab_label"),
                progress: this.getTabCompleteProgress(ACCOUNT_SETUP_STEPS.RICH_MEDIA),
            },
        ];

        return (
            <>
                <CustomSwitch
                    selectedOption={_.find(
                        tabsOptions,
                        (option) => option.value === this.state.activeTab
                    )}
                    options={tabsOptions}
                    onChange={this.handleChangeTab}
                    circleColor={"red"}
                />
                <div className="applicant-account-content-divider" />
                <TabContent
                    className="applicant-account-content"
                    activeTab={this.state.activeTab}
                    ref={this.containerRef}
                >
                    <TabPane tabId={ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA}>
                        {this.renderJobRelatedTab()}
                    </TabPane>
                    <TabPane tabId={ACCOUNT_SETUP_STEPS.LOCATION_AND_COMMUTE_DATA}>
                        {this.renderLocationAndCommuteTab()}
                    </TabPane>
                    <TabPane tabId={ACCOUNT_SETUP_STEPS.RICH_MEDIA}>
                        {this.renderRichMediaTab()}
                    </TabPane>
                </TabContent>
            </>
        );
    }

    renderJobRelatedTab() {
        let formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField,
            onSelectChange: this.handleChangeSelectField,
        };

        let aboutYouParams = [
            {
                fieldName: "firstName",
                title: _.get(this.props.translation, "person_data.first_name_label"),
                isRequired:
                    REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].includes("firstName"),
            },
            {
                fieldName: "lastName",
                title: _.get(this.props.translation, "person_data.last_name_label"),
                isRequired:
                    REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].includes("lastName"),
            },
            {
                fieldName: "email",
                title: _.get(this.props.translation, "person_data.personal_email_label"),
                type: "email",
                isRequired: REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].includes("email"),
                isDisabled: true,
            },
        ];

        return (
            <div className="tab-content-container">
                <h5 className="text-center">
                    {_.get(this.props.translation, "applicant_account_page.job_related_tab_label")}
                </h5>
                <h5 className="page-subsection-header">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.about_you_section_label"
                    )}
                </h5>
                {_.map(aboutYouParams, (fieldParams) =>
                    FormUtils.renderFormGroup(fieldParams, formParams)
                )}
            </div>
        );
    }

    renderLocationAndCommuteTab() {
        let formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField,
            onSelectChange: this.handleChangeSelectField,
        };

        let cityParams = {
            fieldName: "city",
            title: _.get(this.props.translation, "shared.city_label"),
            isRequired:
                REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.LOCATION_AND_COMMUTE_DATA].includes("city"),
        };

        let locationSecondaryParams = [
            {
                fieldName: "district",
                title: _.get(this.props.translation, "shared.district_label"),

                isRequired:
                    REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.LOCATION_AND_COMMUTE_DATA].includes(
                        "district"
                    ),
            },
            {
                fieldName: "street",
                title: _.get(this.props.translation, "shared.street_label"),

                isRequired:
                    REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.LOCATION_AND_COMMUTE_DATA].includes(
                        "street"
                    ),
            },
        ];

        return (
            <div className="tab-content-container">
                <h5 className="text-center">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.location_and_commute_tab_label"
                    )}
                </h5>
                <h5 className="page-subsection-header">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.your_current_location_label"
                    )}
                </h5>
                <Row>
                    <ContinentAndCountryFormGroups
                        countryCode={this.state.countryCode}
                        handleChangeCountry={this.handleChangeCountry}
                        formParams={{ inline: true, inlineCol: true, inlineColSm: "4" }}
                        showContinentFilterLabel={false}
                        isRegionRequired={true}
                        isCountryRequired={true}
                        handleChangeContinent={this.handleChangeRegion}
                    />
                    {FormUtils.renderFormGroup(cityParams, {
                        ...formParams,
                        inline: true,
                        inlineCol: true,
                        inlineColSm: "4",
                    })}
                </Row>
                <Row>
                    {_.map(locationSecondaryParams, (fieldParams) =>
                        FormUtils.renderFormGroup(fieldParams, {
                            ...formParams,
                            inline: true,
                            inlineCol: true,
                            inlineColSm: "4",
                        })
                    )}
                </Row>
            </div>
        );
    }

    renderDreamLocations() {
        let result = _.map(this.state.dreamLocations, (location) => {
            let locationLabel = "";

            locationLabel += _.get(this.props.translation, `continents.${location.continentCode}`);

            if (location.countryCode) {
                locationLabel += ", ";
                locationLabel += _.get(this.props.translation, `countries.${location.countryCode}`);
            }

            if (location.city) {
                locationLabel += ", ";
                locationLabel += location.city;
            }

            if (location.district) {
                locationLabel += ", ";
                locationLabel += location.district;
            }

            return (
                <div key={location} className="dream-location-card">
                    <h6>{locationLabel}</h6>
                    <Icon
                        className="dream-location-remove-icon"
                        type="cancel"
                        color="danger"
                        size="xs"
                        onClick={this.handleRemoveDreamLocation(location.id)}
                    />
                    {location.commuteType ? (
                        <Badge className="preference-badge commute" color="light">
                            {_.get(
                                this.props.translation,
                                COMMUTE_OPTION_SHORT_TRANSLATION[location.commuteType]
                            ) +
                                (location.commuteType === COMMUTE_OPTION.MAX_COMMUTE_TIME
                                    ? " - " +
                                      _.get(
                                          this.props.translation,
                                          COMMUTE_TIME_OPTION_TRANSLATION[location.maxCommuteTime]
                                      )
                                    : "")}
                        </Badge>
                    ) : null}
                    {location.workRegime ? (
                        <Badge className="preference-badge work-regime" color="light">
                            {_.get(this.props.translation, "job.work_regime_label") +
                                " - " +
                                location.workRegime +
                                "%"}
                        </Badge>
                    ) : null}
                    <Badge className="preference-badge start-period" color="light">
                        {_.get(
                            this.props.translation,
                            START_WORK_PERIOD_OPTION_TRANSLATION[location.startPeriod]
                        )}
                    </Badge>
                </div>
            );
        });

        return <div className="dream-locations-container">{result}</div>;
    }

    renderRichMediaTab() {
        let formParams = {
            data: this.state,
            onFieldChange: this.handleChangeField,
        };

        let socialParams = [
            {
                fieldName: "twitterLink",
                title: _.get(this.props.translation, "person_data.twitter_label"),
            },
            {
                fieldName: "linkedInLink",
                title: _.get(this.props.translation, "person_data.linkedin_label"),
            },
            {
                fieldName: "facebookLink",
                title: _.get(this.props.translation, "person_data.facebook_label"),
            },
        ];

        return (
            <div className="tab-content-container">
                <div>
                    <h5 className="page-subsection-header">
                        {_.get(this.props.translation, "rich_media.title")}
                    </h5>
                    <p className="subsection-hint mb-4">
                        {_.get(this.props.translation, "rich_media.applicant_account_help_label")}
                    </p>
                    <b>
                        Solving the <i>Double Blind Date</i> problem - Authenticity beats Beauty!
                    </b>
                    <p className="subsection-hint applicant-account__rich-media-subtitle">
                        {_.get(
                            this.props.translation,
                            "rich_media.applicant_account_second_help_label"
                        )}
                    </p>
                </div>
                <div className="mb-5 mt-5">
                    <RichMedia />
                </div>
                {this.props.userSubRole === USER_SUB_ROLE.BO && (
                    <>
                        <h5 className="page-subsection-header">
                            {Utils.getTagsFromStringWithStyles(
                                _.get(
                                    this.props.translation,
                                    "applicant_account_page.social_antennas_section_label"
                                )
                            )}
                        </h5>
                        {/* <div className="subsection-hint">
                    {_.get(
                        this.props.translation,
                        "applicant_account_page.social_antennas_section_hint"
                    )}
                </div> */}
                        {_.map(socialParams, (fieldParams) =>
                            FormUtils.renderFormGroup(fieldParams, formParams)
                        )}
                    </>
                )}
            </div>
        );
    }

    renderInlineSelectFormGroup(params) {
        if (params.type === "select") {
            return (
                <FormGroup key={params.fieldName}>
                    <h6>{`${params.title}${params.isRequired ? "*" : ""}`}</h6>
                    <CustomSelect
                        className={params.className}
                        value={
                            _.find(
                                params.options,
                                (option) => option.value === this.state[params.fieldName]
                            ) || null
                        }
                        options={params.options}
                        onChange={this.handleChangeSelectField(params.fieldName)}
                        isSearchable={params.isSearchable}
                        menuPosition="fixed"
                    />
                </FormGroup>
            );
        } else {
            return (
                <FormGroup key={params.fieldName}>
                    <h6>{`${params.title}${params.isRequired ? "*" : ""}`}</h6>
                    <Input
                        className={params.className}
                        type={params.type || "text"}
                        value={this.state[params.fieldName]}
                        onChange={this.handleChangeField(params.fieldName)}
                        placeholder={params.placeholder || params.title}
                        disabled={params.isDisabled}
                    ></Input>
                </FormGroup>
            );
        }
    }

    getTabCompleteProgress = (step) => {
        let counter = 0;

        if (!REQUIRED_FIELDS[step]) {
            return null;
        }

        _.map(REQUIRED_FIELDS[step], (fieldName) => {
            if (
                _.isArray(fieldName) &&
                !_.every(fieldName, (field) => {
                    return (
                        _.isNil(this.state[field]) &&
                        (_.isString(this.state[field]) || _.isEmpty(this.state[field]))
                    );
                })
            ) {
                counter++;
            }
            if (
                !_.isArray(fieldName) &&
                !_.isNil(this.state[fieldName]) &&
                (!_.isString(this.state[fieldName]) || !_.isEmpty(this.state[fieldName]))
            ) {
                counter++;
            }
        });

        return (counter / REQUIRED_FIELDS[step].length) * 100;
    };

    renderCurrentLocationData() {
        let location = "";

        if (this.state.countryCode) {
            let continentCode = _.find(
                _.mapValues(this.props.locationData, (countries, continentCode) => {
                    return _.includes(countries, this.state.countryCode) && continentCode;
                })
            );
            location += _.get(this.props.translation, `continents.${continentCode}`);
            location += ", ";
            location += _.get(this.props.translation, `countries.${this.state.countryCode}`);

            if (this.state.city) {
                location += ", ";
                location += this.state.city;
            }

            if (this.state.district) {
                location += ", ";
                location += this.state.district;
            }

            if (this.state.street) {
                location += ", ";
                location += this.state.street;
            }
        } else {
            location = "xx";
        }

        return location;
    }

    uploadUserData = () => {
        const data = {
            email: this.state.email,
            identityAlias: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
            },
            address: {
                countryCode: this.state.countryCode,
                city: this.state.city,
                region: this.state.region,
                district: this.state.district,
                street: this.state.street,
            },
            twitterURI: this.state.twitterLink,
            linkedinURI: this.state.linkedInLink,
            facebookURI: this.state.facebookLink,
        };

        this.props.onUpdateUser(data);
    };

    handleChangeCountry = (countryCode) => {
        this.setState({ countryCode: countryCode });
    };

    handleChangeRegion = (region) => {
        this.setState({ region: region });
    };

    handleChangeTab = (selectedOption) => {
        if (this.state.activeTab !== selectedOption.value) {
            this.setState({ activeTab: selectedOption.value });
            let containerNode = ReactDOM.findDOMNode(this.containerRef.current);
            containerNode.scrollTo({ top: 0 });
        }
    };

    handleChangeField = (fieldName) => (e) => {
        this.setState({ [fieldName]: e.target.value });
    };

    onSaveChanges = () => {
        const emptyLocationFields = REQUIRED_FIELDS[
            ACCOUNT_SETUP_STEPS.LOCATION_AND_COMMUTE_DATA
        ].reduce((missingFields, fieldName) => {
            if (_.isEmpty(this.state[fieldName])) return [...missingFields, fieldName];
            else return missingFields;
        }, []);
        const emptyGeneralFields = REQUIRED_FIELDS[ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA].reduce(
            (missingFields, fieldName) => {
                if (_.isEmpty(this.state[fieldName])) return [...missingFields, fieldName];
                else return missingFields;
            },
            []
        );
        if (!_.isEmpty(emptyLocationFields) || !_.isEmpty(emptyGeneralFields)) {
            this.setState({
                activeTab: !_.isEmpty(emptyLocationFields)
                    ? ACCOUNT_SETUP_STEPS.LOCATION_AND_COMMUTE_DATA
                    : !_.isEmpty(emptyGeneralFields)
                    ? ACCOUNT_SETUP_STEPS.JOB_RELATED_DATA
                    : this.state.activeTab,
                emptyFieldError: [...emptyLocationFields, ...emptyGeneralFields],
            });
            return;
        }
        this.uploadUserData();
    };
}

export default withRouter(EmployeeProfile);
