import { connect } from "react-redux";
import HomePage from "./HomePage";
import "./HomePage.scss";
import { getTranslation } from "../../../../reducers/common/localization";
import { getUserRole, getUserCurrentSubRole } from "../../../../reducers/user/userRole";
import { isUserFirstLogin, isUserReadWelcomePage } from "../../../../reducers/authorization/login";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        userRole: getUserRole(state),
        userSubRole: getUserCurrentSubRole(state),
        isFirstLogin: isUserFirstLogin(state),
        isWelcomePageRead: isUserReadWelcomePage(state),
    };
};

export default connect(mapStateToProps)(HomePage);
