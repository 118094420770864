
import React, { Component } from 'react';

class Calendar extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 512 512">
                <path fill="#f1f1f1" d="m490.667969 171.519531h-469.335938c-11.773437 0-21.332031 9.558594-21.332031 21.332031v255.148438c0 35.285156 28.714844 64 64 64h384c35.285156 0 64-28.714844 64-64v-255.148438c0-11.773437-9.558594-21.332031-21.332031-21.332031zm0 0" />
                <g fill="#82b1fc">
                    <path d="m448 64h-384c-35.347656 0-64 28.652344-64 64v64.851562h512v-64.851562c0-35.347656-28.652344-64-64-64zm0 0" />
                    <path d="m133.332031 234.667969h-53.332031c-8.832031 0-16 7.167969-16 16v53.332031c0 8.832031 7.167969 16 16 16h53.332031c8.832031 0 16-7.167969 16-16v-53.332031c0-8.832031-7.167969-16-16-16zm0 0" />
                    <path d="m133.332031 362.667969h-53.332031c-8.832031 0-16 7.167969-16 16v53.332031c0 8.832031 7.167969 16 16 16h53.332031c8.832031 0 16-7.167969 16-16v-53.332031c0-8.832031-7.167969-16-16-16zm0 0" />
                    <path d="m282.667969 234.667969h-53.335938c-8.832031 0-16 7.167969-16 16v53.332031c0 8.832031 7.167969 16 16 16h53.335938c8.832031 0 16-7.167969 16-16v-53.332031c0-8.832031-7.167969-16-16-16zm0 0" />
                    <path d="m282.667969 362.667969h-53.335938c-8.832031 0-16 7.167969-16 16v53.332031c0 8.832031 7.167969 16 16 16h53.335938c8.832031 0 16-7.167969 16-16v-53.332031c0-8.832031-7.167969-16-16-16zm0 0" />
                    <path d="m432 234.667969h-53.332031c-8.832031 0-16 7.167969-16 16v53.332031c0 8.832031 7.167969 16 16 16h53.332031c8.832031 0 16-7.167969 16-16v-53.332031c0-8.832031-7.167969-16-16-16zm0 0" />
                </g>
                <g fill="#458afc">
                    <path d="m133.332031 320h-53.332031c-8.832031 0-16-7.167969-16-16v-53.332031c0-8.832031 7.167969-16 16-16h53.332031c8.832031 0 16 7.167969 16 16v53.332031c0 8.832031-7.167969 16-16 16zm-37.332031-32h21.332031v-21.332031h-21.332031zm0 0" />
                    <path d="m133.332031 448h-53.332031c-8.832031 0-16-7.167969-16-16v-53.332031c0-8.832031 7.167969-16 16-16h53.332031c8.832031 0 16 7.167969 16 16v53.332031c0 8.832031-7.167969 16-16 16zm-37.332031-32h21.332031v-21.332031h-21.332031zm0 0" />
                    <path d="m282.667969 320h-53.335938c-8.832031 0-16-7.167969-16-16v-53.332031c0-8.832031 7.167969-16 16-16h53.335938c8.832031 0 16 7.167969 16 16v53.332031c0 8.832031-7.167969 16-16 16zm-37.335938-32h21.335938v-21.332031h-21.335938zm0 0" />
                    <path d="m282.667969 448h-53.335938c-8.832031 0-16-7.167969-16-16v-53.332031c0-8.832031 7.167969-16 16-16h53.335938c8.832031 0 16 7.167969 16 16v53.332031c0 8.832031-7.167969 16-16 16zm-37.335938-32h21.335938v-21.332031h-21.335938zm0 0" />
                    <path d="m432 320h-53.332031c-8.832031 0-16-7.167969-16-16v-53.332031c0-8.832031 7.167969-16 16-16h53.332031c8.832031 0 16 7.167969 16 16v53.332031c0 8.832031-7.167969 16-16 16zm-37.332031-32h21.332031v-21.332031h-21.332031zm0 0" />
                    <path d="m453.332031 512h-394.664062c-32.363281 0-58.667969-26.304688-58.667969-58.667969v-352c0-32.363281 26.304688-58.664062 58.667969-58.664062h394.664062c32.363281 0 58.667969 26.300781 58.667969 58.664062v352c0 32.363281-26.304688 58.667969-58.667969 58.667969zm-394.664062-437.332031c-14.699219 0-26.667969 11.964843-26.667969 26.664062v352c0 14.699219 11.96875 26.667969 26.667969 26.667969h394.664062c14.699219 0 26.667969-11.96875 26.667969-26.667969v-352c0-14.699219-11.96875-26.664062-26.667969-26.664062zm0 0" />
                    <path d="m496 202.667969h-480c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h480c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
                    <path d="m122.667969 128c-8.832031 0-16-7.167969-16-16v-96c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v96c0 8.832031-7.167969 16-16 16zm0 0" />
                    <path d="m389.332031 128c-8.832031 0-16-7.167969-16-16v-96c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v96c0 8.832031-7.167969 16-16 16zm0 0" />
                </g>
            </svg>
        );
    }
}

export default Calendar;
