import { connect } from "react-redux";
import { getUserData, getUserId } from "../../../../reducers/user/userData";
import {
    getOrganizationsJobsState,
    fetchApproveByBO,
    fetchApproveByHRM,
    fetchPublishJobs,
    fetchDeepScreeningJob,
    fetchMoveToInvitationsJob,
} from "../../../../reducers/organization/organizations";
import { getTranslation } from "../../../../reducers/common/localization";
import JobsTable from "./JobsTable";
import { MODAL_JOB_EDIT, showModal } from "../../../../reducers/common/activeModal";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        userId: getUserId(state),
        userData: getUserData(state),
        jobs: getOrganizationsJobsState(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        approveByBo: (data) => dispatch(fetchApproveByBO(data)),
        approveByHrm: (data) => dispatch(fetchApproveByHRM(data)),
        publishJob: (data) => dispatch(fetchPublishJobs(data)),
        moveJobToDeepScreening: (data) => dispatch(fetchDeepScreeningJob(data)),
        moveJobToInvitatons: (data) => dispatch(fetchMoveToInvitationsJob(data)),
        openJobEditModal: (jobId) => dispatch(showModal(MODAL_JOB_EDIT, jobId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsTable);
