import { connect } from "react-redux";
import { logout } from "../../../reducers/authorization/login";
import {
    DELETE_APPLICANT_ACCOUNT_MODAL,
    getActiveModalType,
    hideModal,
} from "../../../reducers/common/activeModal";
import { getTranslation } from "../../../reducers/common/localization";
import { deleteUserData } from "../../../reducers/user/userData";
import DeleteAccountModal from "./DeleteAccountModal";

const mapStateToProps = (state) => ({
    translation: getTranslation(state),
    isOpen: getActiveModalType(state) === DELETE_APPLICANT_ACCOUNT_MODAL,
});

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(hideModal(DELETE_APPLICANT_ACCOUNT_MODAL)),
    onDeleteApplicantAccount: () => {
        dispatch(deleteUserData());
        dispatch(logout());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountModal);
