import _ from "lodash";
import { SkillsUtils } from "./skillsUtils";

export const getStaticSkillsTree = (data) => {
    // const formattedSkills = !_.isEmpty(data)
    //     ? _.map(data.skills, (skill) => ({
    //           id: skill.skill.id,
    //           title: skill.skill.titles.en,
    //           // categoryId: skill.skill.category.id,
    //           categoryId: skill.skill.clusters,
    //           // categoryTitle: skill.skill.category.titles.en,
    //           categoryTitle: skill.skill.clusters.map((cluster) => cluster.titles.en),
    //           level: skill.level,
    //       }))
    //     : null;

    return data && data.clusters ? SkillsUtils.createSkillsTree(null, data?.clusters) : null;
};
