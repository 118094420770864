import { connect } from 'react-redux';
import SkillNotesModal from "./SkillNotesModal";
import { getTranslation } from "../../../../reducers/common/localization";
import {getActiveModalType, MODAL_SKILL_NOTES, hideModal } from '../../../../reducers/common/activeModal';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_SKILL_NOTES
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(hideModal());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillNotesModal);
