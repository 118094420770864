
import React, { Component } from 'react';

class EmailDiscard extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 60 60">
                <polygon fill="#feca57" points="0,5 0,44 28,44 56,44 56,5 " />
                <path fill="#f4b537" d="M30.965,27.107c-1.637,1.462-4.292,1.462-5.93,0l-2.087-1.843C16.419,31.091,0,44,0,44h21.607 h12.787H56c0,0-16.419-12.909-22.948-18.736L30.965,27.107z"/>
                <path fill="#fcd386" d="M0,5l25.035,22.107c1.637,1.462,4.292,1.462,5.93,0L56,5H0z" />
                <circle fill="#ff6b6a" cx="46" cy="41" r="12" />
                <path fill="#FFFFFF" d="M52,42H40c-0.552,0-1-0.448-1-1s0.448-1,1-1h12c0.552,0,1,0.448,1,1S52.552,42,52,42z" />
            </svg>
        );
    }
}

export default EmailDiscard;
