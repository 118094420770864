import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";

export const fetchGetTeamsSuccess = createAction("FETCH_GET_TEAMS_SUCCESS");
export const fetchGetTeamsFail = createAction("FETCH_GET_TEAMS_FAIL");

export const fetchGetTeams = createAction(
    "FETCH_GET_TEAMS",
    (departmentId) => Api.getTeams(departmentId),
    () => ({
        apiCall: true,
        onSuccess: fetchGetTeamsSuccess,
        onFail: fetchGetTeamsFail,
    })
);

export const fetchUpdateTeamsMediaSuccess = createAction("FETCH_UPDATE_TEAMS_MEDIA_SUCCESS");
export const fetchUpdateTeamsMediaFail = createAction("FETCH_UPDATE_TEAMS_MEDIA_FAIL");

export const fetchUpdateTeamsMedia = createAction(
    "FETCH_UPDATE_TEAMS_MEDIA",
    (teamId, data) => Api.updateTeamsMedia(teamId, data),
    () => ({
        apiCall: true,
        onSuccess: fetchUpdateTeamsMediaSuccess,
        onFail: fetchUpdateTeamsMediaFail,
    })
);

export const fetchDeleteTeamsMedia = createAction(
    "FETCH_DELETE_TEAMS_MEDIA",
    (teamId, mediaId) => Api.deleteTeamsMediaMedia(teamId, mediaId),
    () => ({
        apiCall: true,
        onSuccess: fetchUpdateTeamsMediaSuccess,
        onFail: fetchUpdateTeamsMediaFail,
    })
);

export const updateExistingTeamsList = createAction("UPDATE_EXISTING_TEAMS_LIST");
export const onFetchUpdatedTeamsList = (data) => (dispatch) => {
    dispatch(fetchGetAllBOTeams());
};

export const fetchDefineNewTeam = createAction(
    "FETCH_DEFINE_NEW_TEAM",
    (departmentId, teamName) => Api.defineNewTeam(departmentId, teamName),
    () => ({ apiCall: true, onSuccess: updateExistingTeamsList, onFail: fetchUpdateTeamsMediaFail })
);

export const onAddNewTeamSuccess = createAction("ADD_NEW_TEAM_SUCCESS");

export const addNewTeam = createAction(
    "ADD_NEW_TEAM",
    (teamId, assignBO) => Api.addNewTeam(teamId, assignBO),
    () => ({ apiCall: true, onSuccess: onFetchUpdatedTeamsList, onFail: fetchUpdateTeamsMediaFail })
);

export const onFetchGetAllBOTeamsSuccess = createAction("GET_ALL_BO_TEAMS_SUCCESS");

export const fetchGetAllBOTeams = createAction(
    "GET_ALL_BO_TEAMS",
    () => Api.getAllTeams(),
    () => ({
        apiCall: true,
        onSuccess: onFetchGetAllBOTeamsSuccess,
        onFail: fetchUpdateTeamsMediaFail,
    })
);

const initialState = {
    teamsList: [],
    allTeams: [],
    err: null,
    pending: false,
};

export default handleActions(
    {
        FETCH_GET_TEAMS: (state) => ({
            ...state,
            teamsList: [],
            err: null,
            pending: true,
        }),
        FETCH_GET_TEAMS_SUCCESS: (state, action) => ({
            ...state,
            teamsList: action.payload.content,
            pending: false,
            err: null,
        }),
        FETCH_GET_TEAMS_FAIL: (state, action) => ({
            ...state,
            teamsList: [],
            pending: false,
            err: action.payload,
        }),
        FETCH_UPDATE_TEAMS_MEDIA: (state) => ({
            ...state,
            pending: true,
        }),
        FETCH_UPDATE_TEAMS_MEDIA_FAIL: (state, action) => ({
            ...state,
            err: action.payload,
            pending: false,
        }),
        FETCH_UPDATE_TEAMS_MEDIA_SUCCESS: (state, action) => ({
            ...state,
            pending: false,
            teamsList: state.teamsList.map((team) =>
                team.id === action.payload.id ? { ...action.payload } : { ...team }
            ),
        }),
        UPDATE_EXISTING_TEAMS_LIST: (state, action) => ({
            ...state,
            teamsList: [...state.teamsList, action.payload],
        }),
        GET_ALL_BO_TEAMS: (state, action) => ({
            ...state,
            pending: true,
        }),
        GET_ALL_BO_TEAMS_SUCCESS: (state, action) => {
            return {
                ...state,
                pending: false,
                allTeams: action.payload.content,
            };
        },
    },
    initialState
);

export const getTeamsState = (state) => state.teams;
export const getTeamsList = (state) => getTeamsState(state).teamsList;
export const getTeamsPending = (state) => getTeamsState(state).pending;
export const getTeamsError = (state) => getTeamsState(state).err;
export const getAllBOTeams = (state) => getTeamsState(state).allTeams;
