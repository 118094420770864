import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, Alert } from "reactstrap";
import { NODE_TYPE } from "../../../../constants/systemNodesConstants";
import _ from "lodash";
import CustomSelect from "../../../common/inputs/customSelect/CustomSelect";

export default class CreateNodeModal extends Component {
    state = {
        selectedLanguageForSimpleMenu: this.props.languageList[0],
        nodeName: "",
        domainLanguageBundle: {},
        showErrMessage: false,
        clusterIds: [],
    };
    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen}>
                    <ModalHeader>
                        {_.get(
                            this.props.translation,
                            `create_new_node.create_${this.props.nodeType?.toLowerCase()}_label`
                        )}
                    </ModalHeader>
                    <ModalBody className="d-flex flex-column px-5">
                        {this.props.nodeType !== NODE_TYPE.DOMAIN &&
                            this.renderSimpleNodeCreation()}
                        {this.props.nodeType === NODE_TYPE.DOMAIN && this.renderContentForDomains()}
                        {this.props.nodeType === NODE_TYPE.SKILL &&
                            this.renderClusterSelectForSkill()}
                        {this.state.showErrMessage && (
                            <Alert color="danger">
                                {_.get(this.props.translation, "create_new_node.error_message")}
                            </Alert>
                        )}
                        <span>{_.get(this.props.translation, "create_new_node.hint_message")}</span>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.props.onClose}>
                            {_.get(this.props.translation, "actions.cancel_button")}
                        </Button>
                        <Button color="success" onClick={() => this.onAddNewNode()}>
                            {_.get(this.props.translation, "actions.create_button")}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderSimpleNodeCreation() {
        return (
            <div className="d-flex flex-row justify-content-between mb-3 mt-3">
                <Input
                    className="w-25"
                    type="select"
                    menuPosition="fixed"
                    onChange={(e) =>
                        this.setState({ selectedLanguageForSimpleMenu: JSON.parse(e.target.value) })
                    }
                >
                    {this.props.languageList.map((language) => (
                        <option value={JSON.stringify(language)}>{language.label}</option>
                    ))}
                </Input>
                <Input
                    type="text"
                    className={`ml-3 ${!this.state.showErrMessage ? "" : "border border-danger"}`}
                    onChange={(e) => this.setState({ nodeName: e.target.value })}
                />
            </div>
        );
    }

    validateSimpleAdding() {
        let isValidated = !_.isEmpty(this.state.nodeName);

        this.setState({ showErrMessage: !isValidated });

        return isValidated;
    }

    validateDomainAdding() {
        let isValidated = false;

        this.props.languageList.forEach((language) => {
            isValidated = !_.isEmpty(this.state.domainLanguageBundle[language.value]);
        });
        this.setState({ showErrMessage: !isValidated });

        return isValidated;
    }

    validateSkill() {
        let isValidated = !_.isEmpty(this.state.nodeName) && this.state.clusterIds.length;

        this.setState({ showErrMessage: !isValidated });

        return isValidated;
    }

    renderContentForDomains() {
        return (
            <div className="d-flex flex-column justify-content-between mb-3 mt-3">
                {this.props.languageList.map((language) => (
                    <div className="mb-3">
                        <span className="mb-1">{language.label}</span>
                        <Input
                            type="text"
                            className={`${
                                this.state.showErrMessage &&
                                _.isEmpty(this.state.domainLanguageBundle[language.value])
                                    ? "border border-danger"
                                    : ""
                            }`}
                            onChange={(e) =>
                                this.setState({
                                    domainLanguageBundle: {
                                        ...this.state.domainLanguageBundle,
                                        [language.value]: e.target.value,
                                    },
                                })
                            }
                        />
                    </div>
                ))}
            </div>
        );
    }

    renderClusterSelectForSkill() {
        return (
            <div>
                <Input type="select" onChange={(e) => this.props.onChooseDomain(e.target.value)}>
                    <option hidden>
                        {_.get(this.props.translation, "create_new_node.select_domain")}
                    </option>
                    {this.props.domainList?.map((domain) => (
                        <option value={domain.titles.en}>{domain.titles.en}</option>
                    ))}
                </Input>
                <CustomSelect
                    className={`mt-3 mb-3 ${this.state.showErrMessage && "border border-danger"}`}
                    options={this.props.skillClusterList.map((skillCluster) => ({
                        label: skillCluster.titles.en,
                        value: skillCluster.id,
                    }))}
                    isMulti
                    isDisabled={!this.props.skillClusterList.length}
                    isSearchable
                    onChange={(e) =>
                        this.setState({
                            clusterIds: e.map((cluster) => cluster.value),
                        })
                    }
                />
            </div>
        );
    }

    onAddNewNode() {
        switch (this.props.nodeType) {
            case NODE_TYPE.SKILL:
            case NODE_TYPE.CLUSTER:
                if (this.validateSkill()) {
                    const data = {
                        resourceStatus: "VALIDATED",
                        resourceType: this.props.nodeType,
                        resourceOwner: "ADMIN",
                        titles: {
                            [this.state.selectedLanguageForSimpleMenu.value]: this.state.nodeName,
                        },
                        clusterIds: this.state.clusterIds,
                    };

                    this.props.onCreateNewNode(data);
                    this.props.onClose();
                }
                break;
            case NODE_TYPE.PROFILE:
                if (this.validateSimpleAdding()) {
                    const data = {
                        resourceStatus: "VALIDATED",
                        resourceType: this.props.nodeType,
                        resourceOwner: "ADMIN",
                        titles: {
                            [this.state.selectedLanguageForSimpleMenu.value]: this.state.nodeName,
                        },
                    };

                    this.props.onCreateNewNode(data);
                    this.props.onClose();
                }
                break;
            case NODE_TYPE.DOMAIN:
                if (this.validateDomainAdding()) {
                    const data = {
                        resourceStatus: "VALIDATED",
                        resourceType: this.props.nodeType,
                        resourceOwner: "ADMIN",
                        titles: {
                            ...this.state.domainLanguageBundle,
                        },
                    };

                    this.props.onCreateNewNode(data);
                    this.props.onClose();
                }
                break;
            default:
                break;
        }
    }
}
