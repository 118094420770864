import { connect } from 'react-redux';
import SystemNodeDetails, { SYSTEM_NODE_DETAILS_MODE } from './SystemNodeDetails';
import { getTranslation } from '../../../../reducers/common/localization';
import { resetAddedRelatedProfiles } from '../../../../reducers/admin/relatedProfiles';
import { resetAddedRelatedSkills } from '../../../../reducers/admin/relatedSkills';
import { showModal, MODAL_CHANGE_PARENT_CLUSTER } from '../../../../reducers/common/activeModal';
import { onResetAllClustersData, getNewParentCluster } from '../../../../reducers/admin/clusters';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        mode: SYSTEM_NODE_DETAILS_MODE.CREATE,
        newCluster: getNewParentCluster(state)
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onResetNodesToAddData: () => {
            dispatch(resetAddedRelatedProfiles());
            dispatch(resetAddedRelatedSkills());
        },
        onOpenChangeParentClusterModal: () => {
            dispatch(showModal(MODAL_CHANGE_PARENT_CLUSTER));
        },
        onResetParentClusterData: () => {
            dispatch(onResetAllClustersData());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemNodeDetails);
