import React from "react";
import _ from "lodash";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { CORPORATE_CONSTANTS, USER_SUB_ROLE } from "../../../constants/constants";
import { DROPZONE_TABS } from "../postJob/PostJob";
import "./CorporateIdentity.scss";
import CompanyRichMedia from "../../common/companyRichMedia/CompanyRichMedia.container";

class CorporateIdentity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeOrg: {},
            dropdownOpen: false,
            activeDropzoneTab: DROPZONE_TABS.OFFICE,
            officeMedia: [],
            bossMedia: [],
        };
    }

    componentDidMount() {
        this.props.onMount();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            switch (this.state.activeOrg.key) {
                case CORPORATE_CONSTANTS.ORGANIZATION:
                    this.setState({
                        activeOrg: {
                            ...this.props.organization,
                            key: CORPORATE_CONSTANTS.ORGANIZATION,
                        },
                    });
                    break;
                case CORPORATE_CONSTANTS.DEPARTMENT:
                    this.setState({
                        activeOrg: {
                            ...this.props.departmentsList.filter(
                                (department) => department.id === this.state.activeOrg.id
                            )[0],
                            key: CORPORATE_CONSTANTS.ORGANIZATION,
                        },
                    });
                    break;
                case CORPORATE_CONSTANTS.TEAM:
                    this.setState({
                        activeOrg: {
                            ...this.props.teams.filter(
                                (team) => team.id === this.state.activeOrg.id
                            )[0],
                            key: CORPORATE_CONSTANTS.TEAM,
                        },
                    });
                    break;
                default:
                    break;
            }
        }
    }

    renderDropdownContent() {
        const { userSubRole, organization, departmentsList, teams } = this.props;

        const commonContent = [
            {
                ...organization,
                key: CORPORATE_CONSTANTS.ORGANIZATION,
            },
            ...departmentsList.map((department) => ({
                ...department,
                key: CORPORATE_CONSTANTS.DEPARTMENT,
            })),
        ];

        const teamContent = [
            ...teams.map((team) => ({
                ...team,
                name: team.jobTitle,
                key: CORPORATE_CONSTANTS.TEAM,
            })),
        ];

        if (userSubRole.includes(USER_SUB_ROLE.ANCHOR)) {
            return [...commonContent];
        }

        return [...teamContent, ...commonContent];
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    handleAddMedia(companyFiles) {
        this.setState(
            {
                bossMedia: companyFiles.bossMedia,
                officeMedia: companyFiles.officeMedia,
            },
            () => {
                this.handleUpdateMedia();
            }
        );
    }

    handleUpdateMedia() {
        const formData = new FormData();
        this.state.bossMedia.forEach((media) => {
            formData.append("bossMedia", media);
        });
        this.state.officeMedia.forEach((media) => {
            formData.append("officeMedia", media);
        });

        switch (this.state.activeOrg.key) {
            case CORPORATE_CONSTANTS.ORGANIZATION:
                this.props.onUpdateOrgMedia(this.state.activeOrg.id, formData);
                break;
            case CORPORATE_CONSTANTS.DEPARTMENT:
                this.props.onUpdateDepartmentMedia(this.state.activeOrg.id, formData);
                break;
            case CORPORATE_CONSTANTS.TEAM:
                this.props.onUpdateJobMedia(this.state.activeOrg.id, formData);
                break;
            default:
        }
    }

    render() {
        return (
            <div className="container flex-column">
                <div className="pt-3 d-flex justify-content-center">
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggleDropdown()}>
                        <DropdownToggle caret color="success">
                            {!_.isEmpty(this.state.activeOrg)
                                ? this.state.activeOrg.name
                                : _.get(
                                      this.props.translation,
                                      "corporate_identity_page.dropdown_title"
                                  )}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem text className="bg-light">
                                {_.get(
                                    this.props.translation,
                                    "corporate_identity_page.organizations_label"
                                )}
                            </DropdownItem>

                            {this.renderDropdownContent()
                                .filter((e) => e.key === CORPORATE_CONSTANTS.ORGANIZATION)
                                .map((item) => (
                                    <DropdownItem
                                        key={item.id}
                                        onClick={() =>
                                            this.setState(
                                                {
                                                    activeOrg: {},
                                                },
                                                () =>
                                                    this.setState({
                                                        activeOrg: { ...item },
                                                    })
                                            )
                                        }
                                    >
                                        {item.name}
                                    </DropdownItem>
                                ))}

                            <DropdownItem text className="bg-light">
                                {_.get(
                                    this.props.translation,
                                    "corporate_identity_page.departments_label"
                                )}
                            </DropdownItem>

                            {this.renderDropdownContent()
                                .filter((e) => e.key === CORPORATE_CONSTANTS.DEPARTMENT)
                                .map((item) => (
                                    <DropdownItem
                                        key={item.id}
                                        onClick={() =>
                                            this.setState(
                                                {
                                                    activeOrg: {},
                                                },
                                                () =>
                                                    this.setState({
                                                        activeOrg: { ...item },
                                                    })
                                            )
                                        }
                                    >
                                        {item.name}
                                    </DropdownItem>
                                ))}

                            {!this.props.userSubRole.includes(USER_SUB_ROLE.ANCHOR) && (
                                <>
                                    <DropdownItem text className="bg-light">
                                        {_.get(
                                            this.props.translation,
                                            "corporate_identity_page.job_label"
                                        )}
                                    </DropdownItem>
                                    <div className="dropdown-expanded-content">
                                        {this.renderDropdownContent()
                                            .filter((e) => e.key === CORPORATE_CONSTANTS.TEAM)
                                            .map((item) => (
                                                <DropdownItem
                                                    key={item.id}
                                                    onClick={() =>
                                                        this.setState(
                                                            {
                                                                activeOrg: {},
                                                            },
                                                            () =>
                                                                this.setState({
                                                                    activeOrg: { ...item },
                                                                })
                                                        )
                                                    }
                                                >
                                                    {item.name}
                                                </DropdownItem>
                                            ))}
                                    </div>
                                </>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </div>
                {!_.isEmpty(this.state.activeOrg) && (
                    <CompanyRichMedia
                        addMedia={this.handleAddMedia.bind(this)}
                        companyFiles={this.state.activeOrg}
                        onSetActiveTab={(activeTab) =>
                            this.setState({ activeDropzoneTab: activeTab })
                        }
                    />
                )}
            </div>
        );
    }
}

export default CorporateIdentity;
