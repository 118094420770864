
import React, { Component } from 'react';

class EmailsDelete extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 -16 512.00002 512">
                <path d="m408.96875 86.230469c0-39.242188 0-70.875 0-72.9375 0-7.3125-5.882812-13.292969-13.078125-13.292969h-342.695313c-7.195312 0-13.082031 5.980469-13.082031 13.292969v72.9375zm0 0" fill="#f4b537" />
                <path d="m84.101562 0h-30.90625c-7.195312 0-13.082031 5.980469-13.082031 13.292969v72.9375h30.910157c0-39.242188 0-70.875 0-72.9375 0-7.3125 5.882812-13.292969 13.078124-13.292969zm0 0" fill="#eaa01c" />
                <path d="m334.917969 86.230469 74.050781-72.9375c0-7.3125-5.882812-13.292969-13.078125-13.292969h-342.695313c-7.195312 0-13.082031 5.980469-13.082031 13.292969l74.050781 72.9375zm0 0" fill="#feca57" />
                <path d="m40.113281 13.292969 30.910157 30.445312c0-17.527343 0-29.214843 0-30.445312 0-7.3125 5.882812-13.292969 13.078124-13.292969h-30.90625c-7.195312 0-13.082031 5.980469-13.082031 13.292969zm0 0" fill="#f4b537" />
                <path d="m326.84375 387.878906c0-51.132812 41.449219-92.578125 92.578125-92.578125 10.375 0 20.351563 1.707031 29.664063 4.851563 0-80.453125 0-195.015625 0-199.378906 0-8.757813-7.167969-15.921876-15.925782-15.921876h-417.234375c-8.761719 0-15.925781 7.164063-15.925781 15.921876v277.148437c0 8.757813 7.164062 15.921875 15.925781 15.921875h311.113281c-.125-1.972656-.195312-3.960938-.195312-5.964844zm0 0" fill="#feca57" />
                <path d="m30.90625 377.921875c0-2.886719 0-270.433594 0-277.148437 0-8.757813 7.167969-15.921876 15.925781-15.921876h-30.90625c-8.761719 0-15.925781 7.164063-15.925781 15.921876v277.148437c0 8.757813 7.164062 15.921875 15.925781 15.921875h30.90625c-8.757812 0-15.925781-7.164062-15.925781-15.921875zm0 0" fill="#f4b537" />
                <path d="m326.84375 387.878906c0-35.171875 19.609375-65.761718 48.496094-81.433594l-69.230469-67.097656-33.820313 32.773438c-26.605468 25.792968-68.886718 25.792968-95.496093 0l-33.816407-32.773438-142.976562 138.574219c0 8.757813 7.164062 15.921875 15.925781 15.921875h311.113281c-.125-1.972656-.195312-3.960938-.195312-5.964844zm0 0" fill="#f4b537" />
                <path d="m164.503906 260.210938-21.527344-20.863282-142.976562 138.574219c0 8.757813 7.164062 15.921875 15.925781 15.921875h10.695313zm0 0" fill="#eaa01c" />
                <path d="m433.160156 84.851562h-417.234375c-8.761719 0-15.925781 7.164063-15.925781 15.921876l176.792969 173.410156c26.609375 25.789062 68.890625 25.789062 95.496093 0l176.796876-173.410156c0-8.757813-7.167969-15.921876-15.925782-15.921876zm0 0" fill="#fcd486" />
                <path d="m0 100.773438 30.90625 30.316406c0-17.546875 0-28.992188 0-30.316406 0-8.757813 7.167969-15.921876 15.925781-15.921876h-30.90625c-8.761719 0-15.925781 7.164063-15.925781 15.921876zm0 0" fill="#feca57" />
                <path d="m512 387.324219c0 51.433593-41.695312 93.128906-93.132812 93.128906-51.433594 0-93.132813-41.695313-93.132813-93.128906 0-51.4375 41.699219-93.132813 93.132813-93.132813 51.4375 0 93.132812 41.695313 93.132812 93.132813zm0 0" fill="#ff6b6b" />
                <path d="m356.644531 387.324219c0-46.171875 33.597657-84.488281 77.675781-91.851563-5.023437-.839844-10.1875-1.28125-15.453124-1.28125-51.433594 0-93.132813 41.695313-93.132813 93.128906 0 51.4375 41.699219 93.132813 93.132813 93.132813 5.265624 0 10.429687-.441406 15.453124-1.28125-44.078124-7.363281-77.675781-45.679687-77.675781-91.847656zm0 0" fill="#ff6b6b" />
                <path d="m430.347656 387.878906 27.199219-27.199218c3.019531-3.019532 3.019531-7.910157 0-10.929688-3.015625-3.015625-7.910156-3.015625-10.925781 0l-27.199219 27.199219-27.199219-27.199219c-3.015625-3.015625-7.910156-3.015625-10.925781 0-3.019531 3.019531-3.019531 7.910156 0 10.929688l27.199219 27.199218-27.199219 27.195313c-3.019531 3.019531-3.019531 7.910156 0 10.929687 1.507813 1.507813 3.484375 2.261719 5.464844 2.261719 1.976562 0 3.953125-.753906 5.460937-2.261719l27.199219-27.199218 27.199219 27.199218c1.511718 1.507813 3.488281 2.261719 5.464844 2.261719 1.976562 0 3.953124-.753906 5.464843-2.261719 3.015625-3.019531 3.015625-7.910156 0-10.929687zm0 0" fill="#ffffff" />
            </svg>
        );
    }
}

export default EmailsDelete;
