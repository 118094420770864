import { connect } from 'react-redux';
import TablePagination from "./TablePagination";
import {
    getDepartmentsListCurrentPage,
    getDepartmentsListTotalPages,
    getDepartmentsListFetchingStatus,
    onClickDepartmentsListPaginationItem,
} from '../../../reducers/organization/departmentsList';

const mapStateToProps = state => {
    return {
        current: getDepartmentsListCurrentPage(state),
        total: getDepartmentsListTotalPages(state),
        loading: getDepartmentsListFetchingStatus(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getDataForPage: (page) => dispatch(onClickDepartmentsListPaginationItem(page))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePagination);
