import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    ModalHeader,
    FormGroup,
    Label,
    Col,
    Input,
} from "reactstrap";
import CustomSelect from "../../inputs/customSelect/CustomSelect";
import {
    VERSION_RATIO_OPTIONS,
    VERSION_RATIO_TRANSLATION_LABEL,
    SKILL_PROPERTY,
} from "../../../../constants/skillsConstants";

class CustomSkillLevelModal extends Component {
    state = {
        newVersionTitle: null,
    };
    onHandleActionButton = () => {
        const { onClose } = this.props;
        onClose();
    };

    onSave = () => {
        this.props.onAddNewVersion(
            this.props.currentSkillData.id,
            this.props.currentSkillData.versions
                ? [...this.props.currentSkillData.versions, this.state.newVersionTitle]
                : [this.state.newVersionTitle]
        );
        this.onHandleActionButton();
    };

    render() {
        return (
            <Modal
                className="custom-skill-level-modal"
                isOpen={this.props.isOpen}
                toggle={this.onHandleActionButton}
            >
                <ModalHeader toggle={this.onHandleActionButton}>
                    {_.replace(
                        _.get(this.props.translation, "skills_management.select_skill_version")
                    )}
                </ModalHeader>
                <ModalBody>{this.renderModalBody()}</ModalBody>
                <ModalFooter className="content-space-between">
                    <Button color="secondary" onClick={this.onHandleActionButton}>
                        {_.get(this.props.translation, "actions.cancel_button")}
                    </Button>
                    <Button color="primary" onClick={this.onSave}>
                        {_.get(this.props.translation, "actions.ok_button")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalBody = () => {
        return (
            <div>
                <FormGroup row key="skill-version">
                    <Label sm="4">
                        {_.get(this.props.translation, "skill_properties.version_label")}
                    </Label>
                    <Col className="skill-version-value-col">
                        <Input
                            onChange={(e) =>
                                this.setState({
                                    newVersionTitle: e.target.value,
                                })
                            }
                            placeholder={_.get(
                                this.props.translation,
                                "skill_properties.version_value_select_placeholder"
                            )}
                            menuPosition="fixed"
                        />
                    </Col>
                </FormGroup>
            </div>
        );
    };
}

CustomSkillLevelModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
};

export default CustomSkillLevelModal;
