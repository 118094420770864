import React, { Component } from "react";
import { Alert } from "reactstrap";
import PropTypes from "prop-types";
import _ from "lodash";
import DataSlider from "../DataSlider/DataSlider";

class SkillAndJobDescription extends Component {
    componentDidUpdate(prevProps) {
        if (prevProps.data && this.props.data && prevProps.data.length !== this.props.data.length) {
            this.props.setCurrentJobIndex(0);
        }
    }

    goToIndex(newIndex) {
        if (this.props.animating) return;
        this.props.setCurrentJobIndex(newIndex);
    }

    render() {
        const {
            title,
            addDots,
            data,
            activeIndex,
            animating,
            setAnimating,
            setCurrentJobIndex,
            translation,
        } = this.props;
        return (
            <div className="description">
                <div>
                    <h6>{title}</h6>
                    <div className="dots-controls-container">
                        {addDots &&
                            data &&
                            data.length > 1 &&
                            data.map((job, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => this.goToIndex(index)}
                                        className={`job-dot ${
                                            activeIndex === index ? "active" : ""
                                        }`}
                                    />
                                );
                            })}
                    </div>
                </div>
                <span className={`inner-block-container ${data ? "scrolable-content" : ""}`}>
                    {data && data.length ? (
                        <DataSlider
                            jobs={data}
                            animating={animating}
                            activeIndex={activeIndex}
                            setAnimating={setAnimating}
                            setCurrentJobIndex={setCurrentJobIndex}
                        />
                    ) : (
                        <Alert color="info" hidden={false} style={{ marginBottom: 0 }}>
                            {_.get(translation, "skills_journey_page.why_i_took_job_fallback")}
                        </Alert>
                    )}
                </span>
            </div>
        );
    }
}

SkillAndJobDescription.propTypes = {
    data: PropTypes.array,
    setCurrentJobIndex: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    addDots: PropTypes.bool,
    activeIndex: PropTypes.number.isRequired,
    animating: PropTypes.bool.isRequired,
    setAnimationg: PropTypes.func,
    translation: PropTypes.object.isRequired,
};

export default SkillAndJobDescription;
