import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CandidateEssenceInfoModal.scss';
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';
import _ from "lodash";
import Icon from '../../../../../common/icon/Icon';
import CandidateModalHeader from '../candidateModalHeader/CandidateModalHeader.container';
import { Utils } from '../../../../../../utils/utils';

export const CANDIDATE_ESSENCE_INFO_TYPE = {
    PERSON: "PERSON",
    PERSON_IN: "PERSON_IN",
    PERSON_OUT: "PERSON_OUT"
}

class CandidateEssenceInfoModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: CANDIDATE_ESSENCE_INFO_TYPE.PERSON
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeTab && this.props.activeTab !== prevProps.activeTab) {
            this.setState({ activeTab: this.props.activeTab });
        }
    }

    // TODO: (BE) add person essence data
    render() {
        if (!this.props.data) {
            return null;
        }
        return (
            <Modal isOpen={this.props.isOpen} className="candidate-job-specific-info-modal" toggle={this.props.onCancel}>
                <CandidateModalHeader toggle={this.props.onCancel} />
                <ModalBody>
                    {this.renderModalBody()}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        onClick={this.props.onCancel}
                    >
                        {_.get(this.props.translation, 'actions.cancel_button')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalBody() {
        return (
            <>
                <div className="modes-buttons">
                    <div
                        className={"mode-button " + (this.state.activeTab === CANDIDATE_ESSENCE_INFO_TYPE.PERSON ? "active" : "")}
                        title={_.get(this.props.translation, 'psychology.essential_personal_traits_label')}
                        onClick={this.handleChangeTab(CANDIDATE_ESSENCE_INFO_TYPE.PERSON)}
                    >
                        <Icon type="person_in_circle" size="xl" />
                    </div>
                    <div
                        className={"mode-button " + (this.state.activeTab === CANDIDATE_ESSENCE_INFO_TYPE.PERSON_IN ? "active" : "")}
                        title={_.replace(_.get(this.props.translation, 'psychology.how_to_speak_to_label'), "{personName}", this.getUserFirstName())}
                        onClick={this.handleChangeTab(CANDIDATE_ESSENCE_INFO_TYPE.PERSON_IN)}
                    >
                        <Icon type="person_arrows_in" size="xl" />
                    </div>
                    <div
                        className={"mode-button " + (this.state.activeTab === CANDIDATE_ESSENCE_INFO_TYPE.PERSON_OUT ? "active" : "")}
                        title={_.replace(_.get(this.props.translation, 'psychology.how_to_engage_label'), "{personName}", this.getUserFirstName())}
                        onClick={this.handleChangeTab(CANDIDATE_ESSENCE_INFO_TYPE.PERSON_OUT)}
                    >
                        <Icon type="person_arrows_out" size="xl" />
                    </div>
                </div>
                <div className="modes-content">
                    <div className="mode-content" hidden={this.state.activeTab !== CANDIDATE_ESSENCE_INFO_TYPE.PERSON}>
                        <h6 className="text-center">
                            {_.get(this.props.translation, 'psychology.essential_personal_traits_label')}
                        </h6>
                        {this.renderPersonTabContent()}
                    </div>
                    <div className="mode-content" hidden={this.state.activeTab !== CANDIDATE_ESSENCE_INFO_TYPE.PERSON_IN}>
                        <h6 className="text-center">
                            {_.replace(_.get(this.props.translation, 'psychology.how_to_speak_to_label'), "{personName}", this.getUserFirstName())}
                        </h6>
                        {this.renderPersonInTabContent()}
                    </div>
                    <div className="mode-content" hidden={this.state.activeTab !== CANDIDATE_ESSENCE_INFO_TYPE.PERSON_OUT}>
                        <h6 className="text-center">
                            {_.replace(_.get(this.props.translation, 'psychology.how_to_engage_label'), "{personName}", this.getUserFirstName())}
                        </h6>
                        {this.renderPersonOutTabContent()}
                    </div>
                </div>
            </>
        )
    }

    getUserFirstName = () => {
        if (this.props.anonymityMode) {
            return Utils.getUserAnonymousNameByUUID(this.props.data.user.id);
        } else {
            return this.props.data.user.alias.firstName;
        }
    }

    renderPersonTabContent() {
        // TODO: remove hardcoded values
        return (
            <div>
                Id minim irure incididunt veniam labore labore nostrud culpa. Ullamco voluptate tempor nisi minim sit veniam ut. Eiusmod sint eiusmod consequat pariatur reprehenderit veniam. Duis dolore Lorem cillum ex eu qui dolor sunt ullamco.
            </div>
        )
    }

    renderPersonInTabContent() {
        // TODO: remove hardcoded values
        return (
            <div>
                Sint consectetur pariatur aute aliquip excepteur aliqua incididunt aute aliqua laboris quis. Qui voluptate sint esse amet. Deserunt dolor incididunt quis deserunt qui ad qui occaecat officia qui laborum quis laborum cupidatat.
            </div>
        )
    }

    renderPersonOutTabContent() {
        // TODO: remove hardcoded values
        return (
            <div>
                Eu ullamco duis ipsum laboris labore exercitation dolor cillum consectetur in dolore do. Pariatur qui et minim et labore. Proident est mollit qui laboris amet aliqua esse laborum. Nisi laboris est aliqua nostrud elit ut.
            </div>
        )
    }

    handleChangeTab = (type) => () => {
        if (this.state.activeTab !== type) {
            this.setState({ activeTab: type });
        }
    }
}

CandidateEssenceInfoModal.propTypes = {
    translation: PropTypes.object.isRequired,
    activeTab: PropTypes.string,
    data: PropTypes.object,
    anonymityMode: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default CandidateEssenceInfoModal;
