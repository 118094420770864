import { connect } from "react-redux";
import SystemNodeDetails, { SYSTEM_NODE_DETAILS_MODE } from "./SystemNodeDetails";
import { getTranslation, getTranslationsList } from "../../../../reducers/common/localization";
import {
    getSystemCurrentNodeId,
    getSystemCurrentNodeData,
    getSystemCurrentNodeDataFetchingStatus,
    onSetCurrentSystemNode,
    fetchHandleNodeDomainList,
    fetchUpdateCurrentNode,
    fetchRemoveClusterFromSkill, dataUpdatingStarted,
} from "../../../../reducers/admin/systemCurrentNode";
import {
    resetAddedRelatedProfiles,
    resetRemovedRelatedProfiles,
} from "../../../../reducers/admin/relatedProfiles";
import {
    resetAddedRelatedSkills,
    resetRemovedRelatedSkills,
} from "../../../../reducers/admin/relatedSkills";
import { showModal, MODAL_CHANGE_PARENT_CLUSTER } from "../../../../reducers/common/activeModal";
import { onResetAllClustersData, getNewParentCluster } from "../../../../reducers/admin/clusters";
import {
    getSkillNodesList,
    onFetchGetSystemNodesList,
} from "../../../../reducers/admin/systemModel";
import {
    fetchGetAllSkillClusters,
    getDomains,
    getSC,
} from "../../../../reducers/admin/domainsAndSC";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        mode: SYSTEM_NODE_DETAILS_MODE.EDIT,
        currentNodeId: getSystemCurrentNodeId(state),
        currentNodeData: getSystemCurrentNodeData(state),
        loading: getSystemCurrentNodeDataFetchingStatus(state),
        saving: state.systemCurrentNode.saving,
        skillList: getSkillNodesList(state),
        newCluster: getNewParentCluster(state),
        domainList: getDomains(state),
        skillClusters: getSC(state),
        languageList: getTranslationsList(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectNode: (nodeId) => dispatch(onSetCurrentSystemNode(nodeId)),
        onResetNodesToAddData: () => {
            dispatch(resetAddedRelatedProfiles());
            dispatch(resetAddedRelatedSkills());
        },
        onResetNodesToRemoveData: () => {
            dispatch(resetRemovedRelatedProfiles());
            dispatch(resetRemovedRelatedSkills());
        },
        onOpenChangeParentClusterModal: () => {
            dispatch(showModal(MODAL_CHANGE_PARENT_CLUSTER));
        },
        onResetParentClusterData: () => {
            dispatch(onResetAllClustersData());
        },

        onGetAllSkillClusters: () => dispatch(fetchGetAllSkillClusters()),
        onHandleNodeDomainList: (nodeId, domainTitle, isRemove, nodeType) =>
            dispatch(fetchHandleNodeDomainList(nodeId, domainTitle, isRemove, nodeType)),
        onSaveChanges: (nodeId, data) => {
            dispatch(dataUpdatingStarted());
            dispatch(fetchUpdateCurrentNode(nodeId, data));
        },
        onRemoveClusterFromSKill: (nodeId, clusterId) => {
            dispatch(fetchRemoveClusterFromSkill(nodeId, clusterId));
            // dispatch(onFetchGetSystemNodesList());
        },
        // onAddSkillToSKill: (skillId, skillToAddId) =>
        //     dispatch(fetchAddSkillToSKill(skillId, skillToAddId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemNodeDetails);
