import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import _ from "lodash";

//- Actions
export const fetchJobsByIdsFailed = createAction("FETCH_JOBS_BY_IDS_FAILED");
export const fetchJobsByIdsSuccess = createAction("FETCH_JOBS_BY_IDS_SUCCESS");
export const setJobsApplyModalOpen = createAction("SET_JOBS_APPLY_MODAL_OPEN");
export const setAudioModalOpen = createAction("SET_AUDIO_MODAL_OPEN");
export const setVideoModalOpen = createAction("SET_VIDEO_MODAL_OPEN");
export const setDescriptionModalOpen = createAction("SET_DESCRIPTION_MODAL_OPEN");

export const fetchJobsByIds = createAction(
    "FETCH_JOBS_BY_IDS",
    (jobsIds) => Api.getJobsByIds(jobsIds),
    () => ({
        apiCall: true,
        onSuccess: fetchJobsByIdsSuccess,
        onFail: fetchJobsByIdsFailed,
    })
);

export const fetchJobsApplicationsFailed = createAction("FETCH_JOBS_APPLICATIONS_FAILED");
export const fetchJobsApplicationsSuccess = createAction("FETCH_JOBS_APPLICATIONS_SUCCESS");

const onFetchJobsApplicationsSuccess = (data) => (dispatch) => {
    const jobsApplicationsIds = _.map(data, (item) => item.jobId);
    dispatch(fetchJobsApplicationsSuccess(jobsApplicationsIds));
};

export const fetchJobsApplications = createAction(
    "FETCH_JOBS_APPLICATIONS",
    () => Api.getAllJobsApplications(),
    () => ({
        apiCall: true,
        onSuccess: onFetchJobsApplicationsSuccess,
        onFail: fetchJobsApplicationsFailed,
    })
);

export const applyJobsFailed = createAction("APPLY_JOBS_FAILED");
export const applyJobsSuccess = createAction("APPLY_JOBS_SUCCESS");

export const onApplyJobsSuccess = () => (dispatch) => {
    dispatch(applyJobsSuccess());
    dispatch(fetchJobsApplications());
};

export const applyJobs = createAction(
    "APPLY_JOBS",
    (jobsIds, data) => Api.applyJobsByIds(jobsIds, data),
    () => ({
        apiCall: true,
        onSuccess: onApplyJobsSuccess,
        onFail: applyJobsFailed,
    })
);

export const onOpenApplyJobsModal = () => (dispatch) => {
    dispatch(setJobsApplyModalOpen(true));
};

export const onCloseApplyJobsModal = () => (dispatch) => {
    dispatch(setJobsApplyModalOpen(false));
};

export const onOpenAudioModal = () => (dispatch) => {
    dispatch(setAudioModalOpen(true));
};

export const onCloseAudioModal = () => (dispatch) => {
    dispatch(setAudioModalOpen(false));
};

export const onOpenVideoModal = () => (dispatch) => {
    dispatch(setVideoModalOpen(true));
};

export const onCloseVideoModal = () => (dispatch) => {
    dispatch(setVideoModalOpen(false));
};

export const onOpenDescriptionModal = () => (dispatch) => {
    dispatch(setDescriptionModalOpen(true));
};

export const onCloseDescriptionModal = () => (dispatch) => {
    dispatch(setDescriptionModalOpen(false));
};

export const onFetchJobsByIds = (jobsIds) => (dispatch) => {
    dispatch(fetchJobsByIds(jobsIds));
};

//- State
const initialState = {
    jobsData: [],
    jobsApplicationsIds: [],
    error: null,
    fetchingJobsByIds: false,
    isJobsApplyModalOpen: false,
    isAudioModalOpen: false,
    isVideoModalOpen: false,
    isDescriptionModalOpen: false,
    applyJobsByIdsFetchingStatus: false,
    fetchingJobsApplications: false,
};

//- Reducer
export default handleActions(
    {
        FETCH_JOBS_BY_IDS: (state) => {
            return { ...state, jobsData: [], error: null, fetchingJobsByIds: true };
        },
        FETCH_JOBS_BY_IDS_SUCCESS: (state, action) => {
            return { ...state, jobsData: action.payload, error: null, fetchingJobsByIds: false };
        },
        FETCH_JOBS_BY_IDS_FAILED: (state, action) => {
            return { ...state, jobsData: [], error: action.payload, fetchingJobsByIds: false };
        },
        FETCH_JOBS_APPLICATIONS: (state) => {
            return {
                ...state,
                jobsApplicationsIds: [],
                error: null,
                fetchingJobsApplications: true,
            };
        },
        FETCH_JOBS_APPLICATIONS_SUCCESS: (state, action) => {
            return {
                ...state,
                jobsApplicationsIds: action.payload,
                error: null,
                fetchingJobsApplications: false,
            };
        },
        FETCH_JOBS_APPLICATIONS_FAILED: (state, action) => {
            return {
                ...state,
                jobsApplicationsIds: [],
                error: action.payload,
                fetchingJobsApplications: false,
            };
        },
        APPLY_JOBS: (state) => {
            return { ...state, error: null, applyJobsByIdsFetchingStatus: true };
        },
        APPLY_JOBS_SUCCESS: (state) => {
            return { ...state, error: null, applyJobsByIdsFetchingStatus: false };
        },
        APPLY_JOBS_FAILED: (state, action) => {
            return { ...state, error: action.payload, applyJobsByIdsFetchingStatus: false };
        },
        SET_JOBS_APPLY_MODAL_OPEN: (state, action) => {
            return { ...state, isJobsApplyModalOpen: action.payload };
        },
        SET_AUDIO_MODAL_OPEN: (state, action) => {
            return { ...state, isAudioModalOpen: action.payload };
        },
        SET_VIDEO_MODAL_OPEN: (state, action) => {
            return { ...state, isVideoModalOpen: action.payload };
        },
        SET_DESCRIPTION_MODAL_OPEN: (state, action) => {
            return { ...state, isDescriptionModalOpen: action.payload };
        },
    },
    initialState
);

//- Selectors
export const getJobsComparisonState = (state) => state.jobsComparison;

export const getJobsData = (state) => getJobsComparisonState(state).jobsData;

export const getFetchingJobsByIds = (state) => getJobsComparisonState(state).fetchingJobsByIds;

export const getApplyJobsByIdsFetchingStatus = (state) =>
    getJobsComparisonState(state).applyJobsByIdsFetchingStatus;

export const getFetchingJobsApplications = (state) =>
    getJobsComparisonState(state).fetchingJobsApplications;

export const getJobsApplicationsIds = (state) => getJobsComparisonState(state).jobsApplicationsIds;

export const getIsJobsApplyModalOpen = (state) =>
    getJobsComparisonState(state).isJobsApplyModalOpen;

export const getIsAudioModalOpen = (state) => getJobsComparisonState(state).isAudioModalOpen;

export const getIsVideoModalOpen = (state) => getJobsComparisonState(state).isVideoModalOpen;

export const getIsDescriptionModalOpen = (state) =>
    getJobsComparisonState(state).isDescriptionModalOpen;
