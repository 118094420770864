import { connect } from "react-redux";
import PostJob from "./PostJob";
import { getTranslation } from "../../../reducers/common/localization";
import { getUserData } from "../../../reducers/user/userData";
import {
    fetchGetDepartmentsList,
    getDepartmentsListData,
} from "../../../reducers/organization/departmentsList";
import {
    fetchGetOrganizationsList,
    getOrganizationsListState,
} from "../../../reducers/organization/organizations";
import { getLocationData } from "../../../reducers/common/location";
import { getJobSkillsData, getJobSkillsDataTree } from "../../../reducers/job/jobSkills";
import { getJobProfilesData } from "../../../reducers/job/jobProfiles";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        userData: getUserData(state),
        departmentsList: getDepartmentsListData(state),
        organization: getOrganizationsListState(state),
        locationData: getLocationData(state),
        skills: getJobSkillsData(state),
        skillsTree: getJobSkillsDataTree(state),
        profiles: getJobProfilesData(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onMount: () => {
            dispatch(fetchGetOrganizationsList());
            dispatch(fetchGetDepartmentsList());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostJob);
