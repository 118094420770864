import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './App.scss';
import { StorageManager } from '../../utils/storageManager';
import Header from './header/Header.container';
import RoutesManager from './routesManager/RoutesManager.container';
import ForbiddenErrorModal from '../common/modals/forbiddenErrorModal/ForbiddenErrorModal.container';
import UserSubRoleMissingErrorModal from '../common/modals/userSubRoleMissingErrorModal/UserSubRoleMissingErrorModal.container';
import RequestStatus from '../common/requestStatus/RequestStatus.container';
import { api } from "../../api/apibase";
import { Api } from "../../api/api";

class App extends Component {
    componentDidMount() {
        if (!!StorageManager.getLoginToken()) {
            this.props.onGetUserData();
        }

    }

    renderGlobalModals() {
        return (
            <>
                <ForbiddenErrorModal />
                <UserSubRoleMissingErrorModal />
            </>
        )
    }

    render() {
        return (
            <div className="app">
                <Header />
                <RoutesManager />
                {this.renderGlobalModals()}
                <RequestStatus />
            </div>
        );
    }
}

App.propTypes = {
    translation: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onGetUserData: PropTypes.func.isRequired
}

export default App;



