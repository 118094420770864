import { connect } from 'react-redux';
import SecondaryNavBar from './SecondaryNavBar';
import { getTranslation } from "../../../../reducers/common/localization";
import {
    getUserRole,
    getUserCurrentSubRole,
    getUserSubRoles,
    getUserCurrentGroup,
    getUserGroupsForSubRole,
    getUserSubRolesLoading,
    getUserUpdateSubRolesLoading,
    getUserGroupsLoading,
    changeUserCurrentGroup,
    changeUserCurrentSubRole
} from '../../../../reducers/user/userRole';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        role: getUserRole(state),
        currentSubRole: getUserCurrentSubRole(state),
        subRoles: getUserSubRoles(state),
        currentGroup: getUserCurrentGroup(state),
        groups: getUserGroupsForSubRole(state),
        loadingSubRolesAndGroups: getUserSubRolesLoading(state) || getUserUpdateSubRolesLoading(state) || getUserGroupsLoading(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeCurrentSubRole: (subRole) => {
            dispatch(changeUserCurrentSubRole(subRole));
        },
        onChangeCurrentGroup: (group) => {
            dispatch(changeUserCurrentGroup(group));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryNavBar);
