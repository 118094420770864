import { connect } from "react-redux";
import { getTranslation } from "../../../reducers/common/localization";
import ApplyMediaModal from "./ApplyMediaModal";
import {
    getActiveJobId,
    getActiveModalType,
    hideModal,
} from "../../../reducers/common/activeModal";
import { MODAL_APPLICANT_APPLIES_JOB } from "../../../reducers/common/activeModal";
import { applyJobs } from "../../../reducers/quickfind/jobsComparison";

const mapStateToProps = (state) => {
    return {
        isOpen: getActiveModalType(state) === MODAL_APPLICANT_APPLIES_JOB,
        translation: getTranslation(state),
        jobId: getActiveJobId(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClose: () => dispatch(hideModal(MODAL_APPLICANT_APPLIES_JOB)),
        onApplyJobs: (jobId, data) => dispatch(applyJobs(jobId, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyMediaModal);
