import { connect } from "react-redux";
import AddEmployeeToDepartmentModal from "./AddEmployeeToDepartmentModal";
import { getTranslation } from "../../../../../reducers/common/localization";
import {
    getActiveModalType,
    hideModal,
    MODAL_ADD_EMPLOYEE_TO_DEPARTMENT,
} from "../../../../../reducers/common/activeModal";
import {
    getAllEmployees,
    getEmployeesData,
    getInitialStaffList,
    getStaffListData,
} from "../../../../../reducers/organization/staffList";
import { addEmployeeToDepartment } from "../../../../../reducers/organization/departments";

const mapStateToProps = (state) => ({
    translation: getTranslation(state),
    isOpen: getActiveModalType(state) === MODAL_ADD_EMPLOYEE_TO_DEPARTMENT,
    employees: getStaffListData(state).map((employee) => ({
        value: employee.id,
        label: `${employee.firstName} ${employee.lastName} (${employee.email})`,
        data: employee,
    })),
    employeesToTest: getEmployeesData(state),
});

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(hideModal()),
    getAllEmployees: () => dispatch(getAllEmployees()),
    addEmployeeToDepartment: (data) => dispatch(addEmployeeToDepartment(data)),
    getInitialStaffList: () => dispatch(getInitialStaffList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployeeToDepartmentModal);
