import { connect } from "react-redux";
import AddJobRequiredSkillsModal from "./AddJobRequiredSkillsModal";
import { getTranslation } from "../../../../reducers/common/localization";
import {
    getActiveModalType,
    MODAL_ADD_JOB_REQUIRED_SKILLS,
    hideModal,
    showModal,
    MODAL_NO_PROFILES,
    MODAL_SKILLS_WITHOUT_LEVEL,
} from "../../../../reducers/common/activeModal";
import { getJobProfilesData, initEmptyProfiles } from "../../../../reducers/job/jobProfiles";
import {
    getJobSkillsData,
    getJobSkillsDataTree,
    initEmptySkills,
} from "../../../../reducers/job/jobSkills";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_ADD_JOB_REQUIRED_SKILLS,
        skills: getJobSkillsData(state),
        skillsTree: getJobSkillsDataTree(state),
        profiles: getJobProfilesData(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClose: () => {
            dispatch(hideModal());
        },
        onMount: () => {
            dispatch(initEmptyProfiles());
            dispatch(initEmptySkills());
        },
        onShowNoProfilesModal: () => dispatch(showModal(MODAL_NO_PROFILES)),
        onShowSkillsWithoutLevelModal: () => dispatch(showModal(MODAL_SKILLS_WITHOUT_LEVEL)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddJobRequiredSkillsModal);
