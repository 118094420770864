import React, { Component } from "react";
import PropTypes from "prop-types";
import "./AddRelationWithNodeModal.scss";
import _ from "lodash";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    Table,
    FormGroup,
    Badge,
} from "reactstrap";
import { NODE_TYPE, NODE_DOMAIN_LABEL } from "../../../../../../constants/systemNodesConstants";
import SystemNodeStatus from "../../../systemNodeStatus/SystemNodeStatus.container";
import Icon from "../../../../../common/icon/Icon";
import NodesToAddPagination from "../../../../../common/tablePagination/NodesToAddPagination.container";
import BlockLoader from "../../../../../common/blockLoader/BlockLoader.container";
import NodesToAddFilters from "../../../nodesFilters/NodesToAddFilters.container";
import SystemNodeSource from "../../../systemNodeSource/SystemNodeSource.container";

const TITLE_TRANSLATION_BY_NODE_TYPE = {
    [NODE_TYPE.PROFILE]: "system_nodes.add_relations_with_profiles_label",
    [NODE_TYPE.SKILL]: "system_nodes.add_relations_with_skills_label",
};

const ADD_RELATIONS_TRANSLATION_BY_NODE_TYPE = {
    [NODE_TYPE.PROFILE]: "system_nodes.add_relations_with_next_profiles_label",
    [NODE_TYPE.SKILL]: "system_nodes.add_relations_with_next_skills_label",
};

const NODES_GROUP = {
    NON_RELATED_NODES: "NON_RELATED_NODES",
    NODES_TO_ADD: "NODES_TO_ADD",
};

class AddRelationWithNodeModal extends Component {
    componentDidUpdate(prevProps) {
        // if (this.props.isOpen && !prevProps.isOpen) {
        //     this.props.onSetNodesTypeAndFetchNodesData(this.props.nodesType);
        // }
        // if (prevProps.isOpen && !this.props.isOpen) {
        //     this.props.onResetNodesData();
        // }
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen}
                   toggle={this.onClose}
                   className="add-relation-with-node-modal">
                <ModalHeader>
                    {_.get(
                        this.props.translation,
                        TITLE_TRANSLATION_BY_NODE_TYPE[this.props.nodesType],
                    )}
                </ModalHeader>
                <ModalBody>{this.renderModalBody()}</ModalBody>
                <ModalFooter className="content-space-between">
                    <Button color="secondary" onClick={this.props.onClose}>
                        {_.get(this.props.translation, "actions.cancel_button")}
                    </Button>
                    <Button color="primary"
                            onClick={this.onAddRelationsSubmit}
                            disabled={
                                !this.props.selectedNodesToAdd ||
                                this.props.selectedNodesToAdd.length === 0
                            }>
                        {_.get(this.props.translation, "system_nodes.add_relations_label")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalBody = () => {
        return (
            <>
                <FormGroup row>
                    <NodesToAddFilters />
                </FormGroup>
                <div className="nodes-to-add-table-container">
                    {!this.props.loading &&
                    (!this.props.nodesList || this.props.nodesList.length === 0) ? (
                        <h6 className="text-center">
                            {_.get(this.props.translation, "model_management_page.no_data_label")}
                        </h6>
                    ) : (
                        <Table className="clickable-rows">
                            <tbody>{this.renderNodesData(NODES_GROUP.NON_RELATED_NODES)}</tbody>
                        </Table>
                    )}
                    <BlockLoader loading={this.props.loading} />
                </div>
                <NodesToAddPagination />
                {this.props.selectedNodesToAdd && this.props.selectedNodesToAdd.length > 0 ? (
                    <div>
                        <h6 className="text-center">
                            {_.get(
                                this.props.translation,
                                ADD_RELATIONS_TRANSLATION_BY_NODE_TYPE[this.props.nodesType],
                            )}
                        </h6>
                        {this.renderAddedNodes()}
                    </div>
                ) : null}
            </>
        );
    };

    renderNodesData(nodesGroup) {
        let nodesList = this.props.nodesList;


        if (nodesGroup === NODES_GROUP.NODES_TO_ADD) {
            nodesList = this.props.selectedNodesToAdd;
        }

        return _.map(nodesList, (nodeData) => {
            let nodeStatus = nodeData.status;
            let nodeType = nodeData.type;
            let rowClassName = "node-to-add-row";
            let isNodeAdded =
                nodesGroup === NODES_GROUP.NON_RELATED_NODES
                    ? _.find(this.props.addedNodes, (addedNode) => addedNode.id === nodeData.id)
                    : null;
            let isNodeSelected =
                nodesGroup === NODES_GROUP.NON_RELATED_NODES
                    ? _.find(
                        this.props.selectedNodesToAdd,
                        (addedNode) => addedNode.id === nodeData.id,
                    )
                    : null;

            switch (nodesGroup) {
                case NODES_GROUP.NON_RELATED_NODES:
                    rowClassName += isNodeAdded || isNodeSelected ? " added-node" : "";
                    break;

                case NODES_GROUP.NODES_TO_ADD:
                    rowClassName += " added-node";
                    break;

                default:
                    break;
            }

            return (
                <tr key={nodeData.id} className={rowClassName}>
                    {/* TODO: add details popup */}
                    <td className="node-to-add-type-cell">
                        <SystemNodeStatus type={nodeType}
                                          statusDisabled={true}
                                          status={nodeStatus} />
                    </td>
                    <td className="node-to-add-name-cell">
                        <div className="node-to-add-name-cell-content">
                            <div className="node-name">{nodeData.title}</div>
                            {nodeData.type === NODE_TYPE.SKILL
                                ? nodeData.clusters.map((cluster, i) => (
                                    <div className="node-parent-cluster"
                                         key={cluster.id}
                                         title={cluster.title}>
                                        <i>
                                            {_.get(
                                                this.props.translation,
                                                "system_nodes.skill_cluster_label",
                                            ) + ": "}
                                        </i>
                                        <Badge style={{ backgroundColor: "#9fc643" }}
                                               className="node-parent-cluster-badge">
                                            {cluster.title}
                                        </Badge>
                                        <i style={{ marginRight: 3, marginLeft: 3 }}>
                                            {this.props.t("Domains")}:
                                        </i>
                                        {cluster.domains?.map(domain => <Badge
                                            style={{ marginRight: 3, backgroundColor: "#cb2a7b" }}
                                            key={domain.id}
                                            className="node-parent-cluster-badge">
                                            {domain.titles.en}
                                        </Badge>)}
                                    </div>
                                ))
                                : null}
                        </div>
                    </td>
                    <td className="system-node-source-cell">
                        <SystemNodeSource source={nodeData.source} />
                    </td>
                    {nodesGroup === NODES_GROUP.NON_RELATED_NODES ? (
                        <td
                            className={"node-to-add-action-cell table-cell-with-icon"}
                            title={
                                isNodeSelected
                                    ? _.get(
                                        this.props.translation,
                                        "system_nodes.undo_add_relation_label",
                                    )
                                    : _.get(
                                        this.props.translation,
                                        "system_nodes.add_relation_label",
                                    )
                            }
                            onClick={() => {
                                if (!this.props.selectedNodesToAdd.length) {
                                    this.onAddRelation(nodeData.id);
                                } else if (this.props.selectedNodesToAdd.includes(nodeData)) {
                                    this.onUndoAddRelation(nodeData.id);
                                } else console.log("plug");
                            }}
                        >
                            {!isNodeAdded && isNodeSelected && (
                                <Icon type="undo" color="secondary" />
                            )}

                            {!this.props.selectedNodesToAdd.length && (
                                <Icon type="add" color="secondary" />
                            )}
                        </td>
                    ) : null}
                    {nodesGroup === NODES_GROUP.NODES_TO_ADD ? (
                        <td className="node-to-add-action-cell table-cell-with-icon"
                            title={_.get(
                                this.props.translation,
                                "system_nodes.undo_add_relation_label",
                            )}
                            onClick={() => this.onUndoAddRelation(nodeData.id)}>
                            <Icon type="undo" color="secondary" />
                        </td>
                    ) : null}
                </tr>
            );
        });
    }

    renderAddedNodes() {
        return (
            <div className="nodes-to-add-table-container added-nodes">
                <Table className="clickable-rows">
                    <tbody>{this.renderNodesData(NODES_GROUP.NODES_TO_ADD)}</tbody>
                </Table>
            </div>
        );
    }

    onAddRelation = (nodeId) => {
        this.props.onSelectNodeToAdd(nodeId);
    };

    onUndoAddRelation = (nodeId) => {
        this.props.onRemoveSelectedNodeToAdd(nodeId);
    };

    onAddRelationsSubmit = () => {
        this.props.onSubmitSelectedNodesToAdd(
            this.props.currentNode.id,
            this.props.selectedNodesToAdd[0].id,
        );
        this.props.onClose();
    };

    onClose = () => {
        this.props.cleanSelectedNodesToAdd();
        this.props.onClose();
    };
}

AddRelationWithNodeModal.propTypes = {
    // TODO: props
    translation: PropTypes.object.isRequired,
    data: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
};

export default AddRelationWithNodeModal;
