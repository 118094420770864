
import React, { Component } from 'react';

class Email extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 512 512">
                <circle fill="#82b1ff" cx="256" cy="256" r="256" />
                <path fill="#FFFFFF" d="M277.216,97.344c-52.352-6.624-102.816,11.392-138.528,49.824
                c-35.648,38.432-49.952,90.4-39.232,142.624c12.448,60.48,59.232,108.352,119.2,121.984c12.544,2.88,25.088,4.288,37.472,4.288
                c24.608,0,48.608-5.6,70.816-16.608c7.904-3.936,11.136-13.536,7.232-21.472c-3.968-7.936-13.504-11.104-21.472-7.232
                c-26.72,13.28-56.8,16.672-86.944,9.824c-47.04-10.688-85.184-49.792-94.944-97.216c-8.64-41.984,2.816-83.68,31.328-114.432
                c28.544-30.72,68.96-45.216,110.976-39.84c63.232,8.192,110.88,65.6,110.88,133.6V272c0,17.664-14.336,32-32,32s-32-14.336-32-32
                v-64c0-8.832-7.168-16-16-16c-6.56,0-12.16,3.968-14.624,9.6C279.84,195.584,268.64,192,256,192c-37.696,0-64,29.6-64,72
                s26.304,72,64,72c19.872,0,36.416-8.384,47.776-22.4C315.52,327.2,332.672,336,352,336c35.296,0,64-28.704,64-64v-9.312
                C416,178.656,356.352,107.584,277.216,97.344z M256,304c-19.744,0-32-15.328-32-40s12.256-40,32-40s32,15.328,32,40
                S275.744,304,256,304z"/>
            </svg>
        );
    }
}

export default Email;
