import { connect } from 'react-redux';
import { getTranslation } from '../../../../reducers/common/localization';
import SetQuickfindSkillLevelModal from './SetQuickfindSkillLevelModal';
import { getActiveModalType, hideModal, MODAL_QUICKFIND_SET_SKILL_LEVEL } from '../../../../reducers/common/activeModal';
import { setSelectedSkillId, updateSelectedSkillProperty } from '../../../../reducers/quickfind/quickfind';

const mapStateToProps = (state) => {
	return {
		translation: getTranslation(state),
		isOpen: getActiveModalType(state) === MODAL_QUICKFIND_SET_SKILL_LEVEL,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onClose: () => {
			dispatch(hideModal());
		},
		updateSelectedSkillProperty: (id, option) => dispatch(updateSelectedSkillProperty(id, option)),
		setSelectedSkillId: (id) => dispatch(setSelectedSkillId(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SetQuickfindSkillLevelModal);
