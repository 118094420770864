import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Skill from '../skills/skill/Skill.container';
import _ from 'lodash';
import { LEVEL_CLASS_NAME } from '../../../constants/skillsConstants';

class TagComponent extends Component {
	render() {
		const {
			tag,
			onDeleteSkill,
			onShowSkillLevelModal,
			setSelectedSkillId,
			translation
		} = this.props;

		return (
			<Skill
				className={LEVEL_CLASS_NAME[tag.level] || LEVEL_CLASS_NAME.NO_LEVEL}
				id={tag.id}
				level={1}
				isAutocompleteSkill
				text={tag.name}
				isTextOverflowEllipsis
				iconsTitle={{
					info: _.replace(_.get(translation, 'skills_management.specify_skill_level')),
					remove: _.get(translation, 'skills_management.remove_skill_label')
				}}
				iconsPositions={{
					bottomRight: "remove",
					topRight: "info"
				}}
				onRemoveClick={onDeleteSkill}
				onInfoClick={() => {
					setSelectedSkillId(tag.id);
					onShowSkillLevelModal();
				}}
			/>
		)
	}
}

TagComponent.propTypes = {
	translation: PropTypes.object.isRequired,
	onShowSkillLevelModal: PropTypes.func,
	setSelectedSkillId: PropTypes.func,
};

export default TagComponent;
