import { connect } from 'react-redux';
import NavItemsManager from './NavItemsManager';
import { getTranslation } from '../../../../reducers/common/localization';
import { getUserRole, getUserCurrentSubRole } from '../../../../reducers/user/userRole';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        userRole: getUserRole(state),
        userSubRole: getUserCurrentSubRole(state)
    };
};


const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavItemsManager);
