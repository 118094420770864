
import React, { Component } from 'react';

class Phone extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 512 512">
                <circle fill="#82b1ff" cx="256" cy="256" r="256" />
                <path fill="#FFFFFF" d="M349.593,300.614c-8.192-5.559-18.954-5.531-27.116,0.071l-11.752,8.066
                    c-13.09,8.984-30.498,8.496-43.08-1.187c-11.858-9.127-23.176-18.913-33.924-29.283c-10.371-10.748-20.156-22.065-29.283-33.924
                    c-9.684-12.581-10.171-29.989-1.187-43.08l8.066-11.752c5.601-8.162,5.63-18.924,0.071-27.116l-33.64-49.575
                    c-9.293-13.694-27.381-18.282-42.054-10.627l-0.009,0.004c-29.639,15.464-42.074,51.222-28.494,81.77
                    c19.547,43.975,45.793,84.198,77.468,119.423l23.939,23.939c35.226,31.674,75.449,57.921,119.423,77.468
                    c30.549,13.58,66.306,1.145,81.77-28.494l0.004-0.009c7.655-14.672,3.068-32.761-10.627-42.054L349.593,300.614z"/>
            </svg>
        );
    }
}

export default Phone;
