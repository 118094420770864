import { connect } from "react-redux";
import Legend from "./Legend";
import { getTranslation } from "../../../../../reducers/common/localization";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
    };
};

export default connect(mapStateToProps)(Legend);
