import React from "react";
import _ from "lodash";
import queryString from "query-string";
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from "../constants/constants";

const BOLD_ANNOTATION = "$";
const ITALIC_ANNOTATION = "#";

const DEFAULT_BANK_DATA_COUNTRY = "LT";

export class Utils {
    static getParamsFromLocationSearch(data) {
        return queryString.parse(data);
    }

    static objectToFormData(obj) {
        return Object.keys(obj)
            .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]))
            .join("&");
    }

    static getTagsFromStringWithStyles(str = "") {
        let array = this.parseStringToArrayWithStyles(str);

        return _.map(array, (elem, id) => {
            if (elem.italic && elem.bold) {
                return (
                    <b key={id}>
                        <i>{elem.text}</i>
                    </b>
                );
            } else if (elem.italic) {
                return <i key={id}>{elem.text}</i>;
            } else if (elem.bold) {
                return <b key={id}>{elem.text}</b>;
            } else {
                return <span key={id}>{elem.text}</span>;
            }
        });
    }

    static parseStringToArrayWithStyles(str) {
        let result = [];
        let bold = false,
            italic = false;

        let text = str.split("").reduce((a, b) => {
            if (b === BOLD_ANNOTATION) {
                if (bold) {
                    if (a !== "") {
                        if (italic) result.push({ text: a, bold: true, italic: true });
                        else result.push({ text: a, bold: true });
                    }
                    bold = false;
                } else {
                    if (italic) result.push({ text: a, italic: true });
                    else result.push({ text: a });
                    bold = true;
                }
                return "";
            } else if (b === ITALIC_ANNOTATION) {
                if (italic) {
                    if (a !== "") {
                        if (bold) result.push({ text: a, bold: true, italic: true });
                        else result.push({ text: a, italic: true });
                    }
                    italic = false;
                } else {
                    if (bold) result.push({ text: a, bold: true });
                    else result.push({ text: a });
                    italic = true;
                }
                return "";
            } else {
                return a + b;
            }
        }, "");

        if (text !== "") {
            result.push({ text: text });
        }

        return result;
    }

    static getUserAnonymousNameByUUID(userId) {
        return "Applicant @" + userId.substring(userId.length - 6);
    }

    static getColorByUUID(userId) {
        return "#" + userId.substring(userId.length - 6);
    }

    static isValidPassword(password) {
        return (
            password &&
            password.length <= PASSWORD_MAX_LENGTH &&
            password.length >= PASSWORD_MIN_LENGTH
        );
    }

    static getBankDataByCountry(countryCode, translations) {
        let banksData = _.get(translations, "payment.bank_data_by_location");

        if (_.isNil(translations) || !banksData) {
            return null;
        }

        return _.get(banksData, countryCode) || _.get(banksData, DEFAULT_BANK_DATA_COUNTRY);
    }
}
