import { connect } from 'react-redux';
import UserSubRoleMissingErrorModal from "./UserSubRoleMissingErrorModal";
import { getTranslation } from "../../../../reducers/common/localization";
import { getUserSubRoleMissingStatus, clearUserSubRoleMissingError } from '../../../../reducers/common/errors';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        isOpen: getUserSubRoleMissingStatus(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(clearUserSubRoleMissingError());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSubRoleMissingErrorModal);
