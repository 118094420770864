import { connect } from 'react-redux';
import Skill from './Skill';
import { getTranslation } from '../../../../reducers/common/localization';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state)
    };
};

export default connect(mapStateToProps)(Skill);
