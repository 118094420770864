import React, { Component } from "react";
import _ from "lodash";
import { FormGroup, Input } from "reactstrap";
import PendingApplication from "../pendingApplication/PendingApplication.container";
import "./ApplicationOverview.scss";

class ApplicationOverview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: "",
        };
    }

    handleChange = ({ target: { value, name } }) => {
        this.setState({ [name]: value });
    };

    render() {
        const { translation, setParentStateValue, usersJob } = this.props;
        const { searchValue } = this.state;
        return (
            <div>
                <FormGroup>
                    <Input
                        type="text"
                        value={searchValue}
                        id="searchValue"
                        name="searchValue"
                        onChange={this.handleChange}
                        placeholder={_.get(
                            translation,
                            "applicant_jobs_page.search_company_title_placeholder"
                        )}
                    />
                </FormGroup>
                <div>
                    <PendingApplication
                        setParentStateValue={setParentStateValue}
                        searchValue={searchValue}
                        jobUsers={usersJob}
                    />
                </div>
            </div>
        );
    }
}

ApplicationOverview.propTypes = {};

export default ApplicationOverview;
