import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ResetPassword.scss';
import _ from 'lodash';
import { withRouter } from "react-router-dom";
import { Utils } from '../../../../utils/utils';
import { ROUTES } from '../../../../constants/constants';
import { Button, Form } from 'reactstrap';
import ConfirmPasswordFormGroups from '../../../common/formGroups/confirmPasswordFormGroups/ConfirmPasswordFormGroups.container';

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.token = Utils.getParamsFromLocationSearch(props.location.search).token;

        this.state = {
            password: "",
            passwordConfirm: ""
        }
    }

    componentDidMount() {
        this.props.onGetAccountToResetPasswordData(this.token);
    }

    componentDidUpdate() {
        if (this.props.success) {
            this.props.history.push(ROUTES.LOGIN);
        }
    }

    render() {
        if (!this.token) {
            return <h6 className="page-message">{_.get(this.props.translation, 'reset_password_page.incorrect_link_label')}</h6>;
        }
        if (this.props.loading) {
            return <h6 className="page-message">{_.get(this.props.translation, "actions.fetching_message")}</h6>;
        }
        if (this.props.tokenError) {
            return <h6 className="page-message">{_.get(this.props.translation, 'reset_password_page.expired_link_label')}</h6>;
        }
        if (this.props.data) {
            return (
                <div className="reset-password">
                    <h4 className="text-center">{_.get(this.props.translation, 'reset_password_page.page_label')}</h4>
                    <Form onSubmit={this.onSubmit}>
                        <ConfirmPasswordFormGroups
                            password={this.state.password}
                            passwordConfirm={this.state.passwordConfirm}
                            handleChangeField={this.handleChangeField}
                        />
                        <div className="buttons-container form-buttons">
                            <Button
                                color="primary"
                                type="submit"
                                disabled={this.isSubmitFormDisabled()}
                            >
                                {_.get(this.props.translation, 'reset_password_page.submit_button')}
                            </Button>
                        </div>
                    </Form>
                </div>
            )
        } else {
            return null;
        }
    }

    handleChangeField = (fieldName) => (e) => {
        this.setState({ [fieldName]: e.target.value });
    }

    isSubmitFormDisabled = () => {
        return _.isEmpty(this.state.password) || _.isEmpty(this.state.passwordConfirm) ||
            !Utils.isValidPassword(this.state.password) ||
            this.state.password !== this.state.passwordConfirm;
    }

    onSubmit = (e) => {
        e.preventDefault();

        this.props.onResetPassword({ newPassword: this.state.password }, this.token);
    }
}

ResetPassword.propTypes = {
    translation: PropTypes.object.isRequired,
    data: PropTypes.object,
    loading: PropTypes.bool,
    tokenError: PropTypes.string,
    success: PropTypes.bool,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onGetAccountToResetPasswordData: PropTypes.func.isRequired,
    onResetPassword: PropTypes.func.isRequired
}

export default withRouter(ResetPassword);
