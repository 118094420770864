import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import SkillAndJobDescription from "../SkillsAndJobsDescription/SkillsAndJobDescription";

class JobsDescription extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentJobIndex: 0,
            animating: false,
        };
    }

    render() {
        const { translation, jobs } = this.props;
        const { currentJobIndex, animating } = this.state;

        const skillsAndJobsDescriptionCommonProps = {
            translation,
            setCurrentJobIndex: (index) => this.setState({ currentJobIndex: index }),
            setAnimating: (bool) => this.setState({ animating: bool }),
            animating,
            activeIndex: currentJobIndex,
        };
        return (
            <>
                {jobs && jobs[currentJobIndex] && (
                    <h6>{`${_.get(translation, "skills_journey_page.selected_job_label")}: ${
                        jobs[currentJobIndex].jobTitle
                    }`}</h6>
                )}
                <SkillAndJobDescription
                    title={_.get(translation, "skills_journey_page.why_i_took_job_label")}
                    addDots
                    data={jobs && jobs.map((job) => job.whyTookJob)}
                    {...skillsAndJobsDescriptionCommonProps}
                />
                <SkillAndJobDescription
                    title={_.get(translation, "skills_journey_page.what_i_learned_label")}
                    data={jobs && jobs.map((job) => job.whatLearned)}
                    {...skillsAndJobsDescriptionCommonProps}
                />
            </>
        );
    }
}

JobsDescription.propTypes = {
    translation: PropTypes.object.isRequired,
    jobs: PropTypes.array,
};

export default JobsDescription;
