import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    ModalHeader,
    FormGroup,
    Label,
    Col,
} from "reactstrap";
import CustomSelect from "../../inputs/customSelect/CustomSelect";

class PotentialClustersModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clusterId: "",
            profileId: "",
            profileTitle: "",
        };
    }
    onHandleActionButton = () => {
        const { onClose } = this.props;
        onClose();
    };

    onSave = () => {
        this.props.onSaveChanges(this.state.profileId, this.state.profileTitle); // add save logic when all data with cluster and profile will be get from BE side
        this.props.onСlearCurrentSkillAndCluster();
        this.onHandleActionButton();
    };

    render() {
        return (
            <Modal
                className="custom-skill-level-modal"
                isOpen={this.props.isOpen}
                toggle={this.onHandleActionButton}
            >
                <ModalHeader toggle={this.onHandleActionButton}>
                    {_.replace(
                        _.get(
                            this.props.translation,
                            "skills_management.select_cluster_and_profile"
                        )
                    )}
                </ModalHeader>
                <ModalBody>{this.renderModalBody()}</ModalBody>
                <ModalFooter className="content-space-between">
                    <Button color="secondary" onClick={this.onHandleActionButton}>
                        {_.get(this.props.translation, "actions.cancel_button")}
                    </Button>
                    <Button color="primary" onClick={this.onSave}>
                        {_.get(this.props.translation, "actions.ok_button")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalBody = () => {
        let skill = this.props.currentSkillData;

        return (
            <div>
                <FormGroup row key="skill-version">
                    <Label sm="4">{skill?.title}</Label>
                    <Col className="skill-version-ratio-col">
                        <Label>{_.get(this.props.translation, "system_nodes.cluster_label")}</Label>
                        <CustomSelect
                            options={this.props.currentSkillData?.clusters.map((cluster) => ({
                                label: cluster.clusterTitle,
                                value: cluster.clusterId,
                            }))}
                            placeholder={_.get(
                                this.props.translation,
                                "skill_properties.version_ratio_select_placeholder"
                            )}
                            isSearchable={false}
                            menuPosition="fixed"
                            onChange={(cluster) => this.props.getProfilesByClusterId(cluster.value)}
                        />
                    </Col>
                    <Col className="skill-version-value-col">
                        <Label>{_.get(this.props.translation, "system_nodes.profile_label")}</Label>
                        <CustomSelect
                            options={this.props.profilesByClusterId?.map((profile) => ({
                                label: profile.titles.en,
                                value: profile.id,
                            }))}
                            placeholder={_.get(
                                this.props.translation,
                                "skill_properties.version_ratio_select_placeholder"
                            )}
                            disabled={!this.props.profilesByClusterId}
                            isSearchable={false}
                            menuPosition="fixed"
                            onChange={(profile) =>
                                this.setState({
                                    profileId: profile.value,
                                    profileTitle: profile.label,
                                })
                            }
                        />
                    </Col>
                </FormGroup>
            </div>
        );
    };
}

PotentialClustersModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
};

export default PotentialClustersModal;
