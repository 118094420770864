
import React, { Component } from 'react';

class RightArrowOutlined extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 18 18">
                <circle
                    cx="9"
                    cy="9"
                    r="8"
                    fill="#ffffff"
                />
                <path d="M9,18c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9C18,14,14,18,9,18z M9,2C5.1,2,2,5.1,2,9c0,3.9,3.1,7,7,7c3.9,0,7-3.1,7-7C16,5.1,12.9,2,9,2z" />
                <path d="M7.8,3.3L6.5,4.7L10.8,9l-4.3,4.3l1.3,1.3L13.5,9L7.8,3.3z" />
            </svg>
        );
    }
}

export default RightArrowOutlined;
