import React, { Component } from 'react';

class LockPerson extends Component {
	render() {
		return (
			<svg width="128" height="128" viewBox="0 0 128 128">
				<path fill="#2d3e50" d="M92.4285,92.70214a9.558,9.558,0,0,0-5.151-6.93676C78.32095,81.29915,58.66671,74.703,58.66671,74.703V68.21353l.54712-.4129a18.77694,18.77694,0,0,0,7.12945-11.93294l.11012-.69162h.53333a7.2534,7.2534,0,0,0,6.71314-4.51443,7.8988,7.8988,0,0,0,.98752-3.82622,7.26756,7.26756,0,0,0-.51614-2.69076,3.753,3.753,0,0,0-1.45547-2.33981l-1.81335-1.101.45075-1.96817c3.27573-14.2796-7.78668-27.14161-22.6409-27.496-.36127-.00688-.71912-.01029-1.07354-.00344-.35442-.00685-.71225-.00344-1.07354.00344C31.711,11.594,20.6486,24.456,23.9243,38.73564l.45075,1.96817-1.81332,1.101a3.753,3.753,0,0,0-1.4555,2.33981,7.26754,7.26754,0,0,0-.51611,2.69076,7.89848,7.89848,0,0,0,.98752,3.82622,7.25339,7.25339,0,0,0,6.71311,4.51443h.53333l.11012.69162a18.77694,18.77694,0,0,0,7.12945,11.93294l.54712.4129V74.703S16.95657,81.29915,8,85.76538a9.55783,9.55783,0,0,0-5.151,6.93676c-1.53464,8.95659-1.80645,24.06542-1.80645,24.06542H94.235S93.96311,101.65873,92.4285,92.70214Z"/>
				<path fill="#1d75b8" d="M121.37018,34.15191V27.59562a16.35974,16.35974,0,1,0-32.71949,0v6.57a6.06113,6.06113,0,0,0-5.58722,6.03394V61.1398A6.079,6.079,0,0,0,89.11805,67.16H120.9097a6.05661,6.05661,0,0,0,6.04774-6.0477V40.179A6.05471,6.05471,0,0,0,121.37018,34.15191ZM107.80409,51.05109l-.39861.31615v5.38109a2.25825,2.25825,0,0,1-1.16831,2.09607,2.545,2.545,0,0,1-2.48092-.055,2.40684,2.40684,0,0,1-1.14768-2.048V51.381l-.12374-.1031-.25428-.20616A4.28921,4.28921,0,0,1,101.021,45.89a4.36726,4.36726,0,0,1,4.79008-2.52908h.00688a4.44018,4.44018,0,0,1,3.608,4.26777A4.21607,4.21607,0,0,1,107.80409,51.05109Zm7.61458-16.89919H94.59533V27.59562a10.41167,10.41167,0,1,1,20.82334,0Z"/>
			</svg>
		);
	}
}

export default LockPerson;
