import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './QuickfindForm.scss';
import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	PopoverBody,
	Row,
	UncontrolledPopover
} from 'reactstrap';
import Icon from '../icon/Icon';
import CustomSelect from '../inputs/customSelect/CustomSelect';
import AutocompleteTagInput from '../autocompleteTagInput/AutocompleteTagInput';
import { CURRENCY, CURRENCY_SIGN, DISTANCE, PREFERRED_CONTRACT_TYPE } from '../../../constants/constants';
import SkillsValidationModal from './components/skillsValidationModal/SkillsValidationModal.container';

class QuickfindForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isSkillsValidationModalOpen: false
		}
	}

	onSubmit = (e) => {
		e.preventDefault();

		const {
			formValues: {
				distance,
				city,
				workRegime,
				expectedWorkRegime,
				salary,
				preferredContractType,
				currency,
				distanceUnit,
			},
			isQuickfindJob,
			onSearchJobs,
			skills,
			onSearchApplicants
		} = this.props;

		const isSkillsSpecified = !!skills.length;


		if (isQuickfindJob && isSkillsSpecified) {
			const params = {
				cityFilter: city,
				percentageOfHomeWorkingFilter: workRegime,
				workRegimeFilter: expectedWorkRegime,
				contractTypeFilter: preferredContractType,
				salaryFilter: salary,
				distanceFilter: distance,
				distanceUnitFilter: distanceUnit,
				currencyFilter: currency,
				skills
			};

			onSearchJobs(params);
		} else if (!isQuickfindJob && isSkillsSpecified) {
			const params = {
				cityFilter: city,
				percentageOfHomeWorkingFilter: workRegime,
				workRegimeFilter: expectedWorkRegime,
				contractTypeFilter: preferredContractType,
				salaryFilter: salary,
				currencyFilter: currency,
				skills
			};

			onSearchApplicants(params);
		} else {
			this.setState({ isSkillsValidationModalOpen: true });
		}
	};

	render() {
		const {
			translation,
			onSearchSkills,
			searchSkills,
			onCleanSearchSkills,
			onAddSkill,
			onRemoveSkill,
			skills,
			isQuickfindJob,
			onChangeCity,
			onChangeContractType,
			onChangeCurrency,
			onChangeDistance,
			onChangeDistanceUnit,
			onChangeExpectedWorkRegime,
			onChangeSalary,
			onChangeWorkRegime,
			formValues: {
				distance,
				city,
				workRegime,
				expectedWorkRegime,
				salary,
				preferredContractType,
				currency,
				distanceUnit,
			}
		} = this.props;

		const {
			isSkillsValidationModalOpen
		} = this.state;

		const distanceUnits = [
			{ value: DISTANCE.KM, label: _.get(translation, 'shared.km')},
			{ value: DISTANCE.MILES, label: _.get(translation, 'shared.miles')},
		];

		const preferredContractTypeOptions = [
			{ value: PREFERRED_CONTRACT_TYPE.FREELANCE, label: _.get(translation, 'shared.freelance') },
			{ value: PREFERRED_CONTRACT_TYPE.EMPLOYEE, label: _.get(translation, 'shared.employee') },
			{ value: PREFERRED_CONTRACT_TYPE.NO_PREFERENCE, label: _.get(translation, 'shared.no_preference') },
		];

		const currenciesOptions = [
			{ value: CURRENCY.USD, label: CURRENCY_SIGN[CURRENCY.USD] },
			{ value: CURRENCY.EUR, label: CURRENCY_SIGN[CURRENCY.EUR] },
		];

		return (
			<>
				<Form onSubmit={this.onSubmit} className="form">
					<h4 className="page-header center">{_.get(translation,
						isQuickfindJob ?
							"quickfind_job_page.page_label" :
							"applicant_quickfind_page.page_label"
					)}</h4>
					<div className="rows-wrapper">
						<h5 className="page-subsection-header">{_.get(translation, 'shared.location')}</h5>
						{
							isQuickfindJob ? (
								<Row className="with-top-margin quickfind-row">
									<FormGroup check inline>
										<Label>
											{_.get(translation, 'shared.distance_label')}
										</Label>
										<Input
											className="input"
											value={distance}
											type="number"
											placeholder={_.get(translation, 'shared.distance_placeholder')}
											onChange={e => onChangeDistance(e.target.value)}
										/>
									</FormGroup>
									<FormGroup className="distance-unit-select-wrapper">
										<CustomSelect
											value={_.find(distanceUnits, (option) => option.value === distanceUnit) || null}
											options={distanceUnits}
											onChange={option => onChangeDistanceUnit(option.value)}
											menuPosition="fixed"
											isRequired={distance}
											isDisabled={!distance}
										/>
									</FormGroup>
									<FormGroup check inline className="city-input-wrapper">
										<Label className="form-label">
											{_.get(translation, 'shared.from_label')}
										</Label>
										<Input
											className="input"
											type="text"
											value={city}
											onChange={e => onChangeCity(e.target.value)}
											placeholder={_.get(translation, 'shared.city_label')}
											required
										/>
									</FormGroup>
								</Row>
							) : (
								<Row className="with-top-margin quickfind-row">
									<FormGroup check inline className="city-input-wrapper">
										<Label>
											{_.get(translation, 'applicant_quickfind_page.city_input_label')}
										</Label>
										<Input
											className="input"
											type="text"
											value={city}
											onChange={e => onChangeCity(e.target.value)}
											placeholder={_.get(translation, 'shared.city_label')}
											required
										/>
									</FormGroup>
								</Row>
							)
						}
						<Row className="with-top-margin quickfind-row">
							<FormGroup check inline className="regime-input-wrapper">
								<Label>
									{_.get(translation, isQuickfindJob ? 'quickfind_job_page.work_regime_label' : 'applicant_quickfind_page.work_regime')}
								</Label>
								<div className="work-regime-input-wrapper">
									<Input
										className="work-regime-input"
										value={workRegime}
										type="range"
										step="1"
										onChange={e => onChangeWorkRegime(e.target.value)}
									/>
									<div className="work-regime-label">
										<div>{`${workRegime}%`}</div>
									</div>
								</div>
								<Icon
									className="info-icon"
									type="info"
									color="info"
									size="sm"
									title={_.get(translation, 'actions.about_label')}
									id="work-regime-info-icon"
								/>
								<UncontrolledPopover placement="left" target="work-regime-info-icon" trigger="legacy">
									<PopoverBody>
										{_.get(translation, 'shared.work_regime_info')}
									</PopoverBody>
								</UncontrolledPopover>
							</FormGroup>
						</Row>
					</div>
					<h5 className="page-subsection-header">{_.get(translation, 'shared.regime_label')}</h5>
					<Row className="with-top-margin quickfind-row">
						<FormGroup className="flex">
							<h6 className="text-center">{_.get(translation, 'shared.expected_work_regime_label')}</h6>
							<Input
								className="work-regime-input"
								value={expectedWorkRegime}
								type="range"
								step="1"
								onChange={e => onChangeExpectedWorkRegime(e.target.value)}
							/>
							<div className="work-regime-labels">
								<div>{_.get(translation, 'job.part_time_label')}</div>
								<div>{expectedWorkRegime}</div>
								<div>{_.get(translation, 'job.full_time_label')}</div>
							</div>
						</FormGroup>
						<FormGroup className="flex preferred-contract-type-wrapper">
							<h6 className="text-center">{_.get(translation, 'shared.preferred_contract_type_label')}</h6>
							<CustomSelect
								value={_.find(preferredContractTypeOptions, (option) => option.value === preferredContractType) || null}
								options={preferredContractTypeOptions}
								onChange={option => onChangeContractType(option.value)}
								menuPosition="fixed"
							/>
						</FormGroup>
						<FormGroup className="flex">
							<h6 className="text-center">{_.get(translation, 'shared.indicative_monthly_salary')}</h6>
							<div className="salary-input-wrapper">
								<Input
									className="input salary-input"
									type="number"
									value={salary}
									onChange={e => onChangeSalary(e.target.value)}
									placeholder={_.get(translation, 'shared.salary')}
								/>
								<CustomSelect
									value={_.find(currenciesOptions, (option) => option.value === currency) || null}
									options={currenciesOptions}
									onChange={option => onChangeCurrency(option.value)}
									menuPosition="fixed"
									isRequired={salary}
									isDisabled={!salary}
								/>
							</div>
						</FormGroup>
					</Row>
					<h5 className="page-subsection-header">{_.get(translation, 'shared.key_expertise')}</h5>
					<Row className="with-top-margin quickfind-row">
						<div className="autocomplete-wrapper">
							<AutocompleteTagInput
								tags={skills}
								suggestions={searchSkills}
								onAddition={onAddSkill}
								onDelete={onRemoveSkill}
								onSearchSkills={onSearchSkills}
								onCleanSearchSkills={onCleanSearchSkills}
								placeholder={_.get(translation, 'shared.tag_input_placeholder')}
							/>
						</div>
					</Row>
					<div className="search-button-wrapper">
						<Button type="submit" color="primary" className="search-button">
							{_.get(translation, 'actions.search_label')}
						</Button>
					</div>
				</Form>
				<SkillsValidationModal
					isOpen={isSkillsValidationModalOpen}
					onClose={() => this.setState({ isSkillsValidationModalOpen: false })}
				/>
			</>
		)
	}
}

QuickfindForm.propTypes = {
	translation: PropTypes.object.isRequired,
	onSearchSkills: PropTypes.func.isRequired,
	searchSkills: PropTypes.array.isRequired,
	onCleanSearchSkills: PropTypes.func.isRequired,
	onAddSkill: PropTypes.func.isRequired,
	onRemoveSkill: PropTypes.func.isRequired,
	selectedCandidatesIds: PropTypes.array.isRequired,
	selectedSkillId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	skills: PropTypes.array.isRequired,
	isQuickfindJob: PropTypes.bool,
	onSearchJobs: PropTypes.func.isRequired,
	onSearchApplicants: PropTypes.func.isRequired,
	onChangeDistance: PropTypes.func.isRequired,
	onChangeDistanceUnit: PropTypes.func.isRequired,
	onChangeCity: PropTypes.func.isRequired,
	onChangeWorkRegime: PropTypes.func.isRequired,
	onChangeExpectedWorkRegime: PropTypes.func.isRequired,
	onChangeSalary: PropTypes.func.isRequired,
	onChangeContractType: PropTypes.func.isRequired,
	onChangeCurrency: PropTypes.func.isRequired,
	formValues: PropTypes.object.isRequired,
};

export default QuickfindForm;
