export const NODE_TYPE = {
    CLUSTER: "CLUSTER",
    SKILL_CLUSTER: "SKILL_CLUSTER",
    SKILL: "SKILL",
    DOMAIN: "DOMAIN",
    PROFILE: "PROFILE",
};

export const LICENSE_TYPES = [
    {text:'Open Source',value:'open_source'},
    {text:'Commercial',value:'commercial'},
    {text:'Commercial & Open Source',value:'commercial_and_opensource'},
    {text:'Not Applicable',value:'not_applicable'}
]

export const SKILL_PROPERTY = {
    LEVEL: "level",
    EAGERNESS: "eagerness",
    REQUIREMENT_COEFFICIENT: "requirementCoefficient",
    VERSION: "version",
    SPOKEN_LEVEL: "spokenLevel",
    UNDERSTANDING_LEVEL: "understandingLevel",
    NOTES: "notes",
    LIFE_CYCLE_ACQUIRED_AT: "acquiredAt",
    LIFE_CYCLE_FORGOTTEN_AT: "forgottenAt",
};

export const LEVEL = {
    NINJA: 4,
    SENIOR: 3,
    MEDIOR: 2,
    JUNIOR: 1,
};

export const STATUS = {
    NEW: 1,
    EVERGREEN: 2,
    FADING: 3,
};

export const STATUS_CLASS_NAME = {
    [STATUS.NEW]: "status-new",
    [STATUS.EVERGREEN]: "status-evergreen",
    [STATUS.FADING]: "status-fading",
};

export const STATUS_TRANSLATION_LABEL = {
    [STATUS.NEW]: "new",
    [STATUS.EVERGREEN]: "evergreen",
    [STATUS.FADING]: "fading",
};
export const LEVEL_CLASS_NAME = {
    [LEVEL.NINJA]: "level-ninja",
    [LEVEL.SENIOR]: "level-senior",
    [LEVEL.MEDIOR]: "level-medior",
    [LEVEL.JUNIOR]: "level-junior",
    NO_LEVEL: "no-level",
};

export const LEVEL_TRANSLATION_LABEL = {
    [LEVEL.NINJA]: "ninja",
    [LEVEL.SENIOR]: "senior",
    [LEVEL.MEDIOR]: "medior",
    [LEVEL.JUNIOR]: "junior",
};

export const LEVEL_OPTIONS = [LEVEL.JUNIOR, LEVEL.MEDIOR, LEVEL.SENIOR, LEVEL.NINJA];

export const LEVEL_COLORS = {
    [LEVEL.NINJA]: "#333333",
    [LEVEL.SENIOR]: "#ab00de",
    [LEVEL.MEDIOR]: "#1e90ff",
    [LEVEL.JUNIOR]: "#f57900",
};

export const LEVEL_LIGHT_COLORS = {
    [LEVEL.NINJA]: "rgba(51, 51, 51, 0.125)",
    [LEVEL.SENIOR]: "rgba(171, 0, 222, 0.125)",
    [LEVEL.MEDIOR]: "rgba(30, 144, 255, 0.125)",
    [LEVEL.JUNIOR]: "rgba(245, 121, 0, 0.125)",
};

export const EAGERNESS = {
    LOVE_IT: 3,
    OK: 2,
    IF_NEED_BE: 1,
};

export const EAGERNESS_CLASS_NAME = {
    [EAGERNESS.LOVE_IT]: "love-it",
    [EAGERNESS.OK]: "ok",
    [EAGERNESS.IF_NEED_BE]: "if-need-be",
};

export const EAGERNESS_OPTIONS = [EAGERNESS.LOVE_IT, EAGERNESS.OK, EAGERNESS.IF_NEED_BE];

export const EAGERNESS_ICON = {
    [EAGERNESS.LOVE_IT]: "emoji_positive",
    [EAGERNESS.OK]: "emoji_neutral",
    [EAGERNESS.IF_NEED_BE]: "emoji_negative",
};

export const EAGERNESS_TRANSLATION_LABEL = {
    [EAGERNESS.LOVE_IT]: "love_it",
    [EAGERNESS.OK]: "ok",
    [EAGERNESS.IF_NEED_BE]: "if_need_be",
};

export const REQUIREMENT_COEFFICIENT = {
    CRUCIAL: 3,
    REQUIRED: 2,
    NICE_TO_HAVE: 1,
};

export const DEFAULT_REQUIREMENT_COEFFICIENT = REQUIREMENT_COEFFICIENT.REQUIRED;

export const REQUIREMENT_COEFFICIENT_CLASS_NAME = {
    [REQUIREMENT_COEFFICIENT.CRUCIAL]: "requirement-coefficient-crucial",
    [REQUIREMENT_COEFFICIENT.REQUIRED]: "requirement-coefficient-required",
    [REQUIREMENT_COEFFICIENT.NICE_TO_HAVE]: "requirement-coefficient-nice-to-have",
};

export const REQUIREMENT_COEFFICIENT_TRANSLATION_LABEL = {
    [REQUIREMENT_COEFFICIENT.CRUCIAL]: "crucial",
    [REQUIREMENT_COEFFICIENT.REQUIRED]: "required",
    [REQUIREMENT_COEFFICIENT.NICE_TO_HAVE]: "nice_to_have",
};

export const REQUIREMENT_COEFFICIENT_OPTIONS = [
    REQUIREMENT_COEFFICIENT.NICE_TO_HAVE,
    REQUIREMENT_COEFFICIENT.REQUIRED,
    REQUIREMENT_COEFFICIENT.CRUCIAL,
];

export const REQUIREMENT_COEFFICIENT_COLORS = {
    [REQUIREMENT_COEFFICIENT.CRUCIAL]: "#89aa11",
    [REQUIREMENT_COEFFICIENT.REQUIRED]: "#6c757c",
    [REQUIREMENT_COEFFICIENT.NICE_TO_HAVE]: "#c7c7c7",
};

export const REQUIREMENT_COEFFICIENT_LIGHT_COLORS = {
    [REQUIREMENT_COEFFICIENT.CRUCIAL]: "rgba(137, 170, 17, 0.125)",
    [REQUIREMENT_COEFFICIENT.REQUIRED]: "rgba(108, 117, 124, 0.125)",
    [REQUIREMENT_COEFFICIENT.NICE_TO_HAVE]: "rgba(199, 199, 199, 0.125)",
};

export const LANGUAGE_LEVEL = {
    NATIVE: 4,
    PROFESSIONALLY_FLUENT: 3,
    INDEPENDENT_USER: 2,
    NOTIONS: 1,
};

export const LANGUAGE_LEVEL_OPTIONS = [
    LANGUAGE_LEVEL.NATIVE,
    LANGUAGE_LEVEL.PROFESSIONALLY_FLUENT,
    LANGUAGE_LEVEL.INDEPENDENT_USER,
    LANGUAGE_LEVEL.NOTIONS,
];

export const LANGUAGE_LEVEL_TRANSLATION_LABEL = {
    [LANGUAGE_LEVEL.NATIVE]: "native",
    [LANGUAGE_LEVEL.PROFESSIONALLY_FLUENT]: "professionally_fluent",
    [LANGUAGE_LEVEL.INDEPENDENT_USER]: "independent_user",
    [LANGUAGE_LEVEL.NOTIONS]: "notions",
};

export const VERSION_RATIO = {
    AT_MOST: 3,
    EXACTLY: 2,
    AT_LEAST: 1,
};

export const VERSION_RATIO_OPTIONS = [
    VERSION_RATIO.AT_LEAST,
    VERSION_RATIO.EXACTLY,
    VERSION_RATIO.AT_MOST,
];

export const VERSION_RATIO_TRANSLATION_LABEL = {
    [VERSION_RATIO.AT_LEAST]: "at_least",
    [VERSION_RATIO.EXACTLY]: "exactly",
    [VERSION_RATIO.AT_MOST]: "at_most",
};

export const COMPARISON_LEVEL = {
    PERFECT: 3,
    LOWER: 2,
    MISSING: 1,
};

export const COMPARISON_LEVEL_CLASS_NAME = {
    [COMPARISON_LEVEL.PERFECT]: "perfect-match",
    [COMPARISON_LEVEL.LOWER]: "lower-level",
    [COMPARISON_LEVEL.MISSING]: "missing-skill",
};

export const COMPARISON_LEVEL_TRANSLATION_LABEL = {
    [COMPARISON_LEVEL.PERFECT]: "perfect_match",
    [COMPARISON_LEVEL.LOWER]: "not_enough_level",
    [COMPARISON_LEVEL.MISSING]: "missing_skill",
};
