import { connect } from 'react-redux';
import { getTranslation } from '../../../../../reducers/common/localization';
import { getIsDescriptionModalOpen, onCloseDescriptionModal } from '../../../../../reducers/quickfind/jobsComparison';
import DescriptionModal from './DescriptionModal';

const mapStateToProps = state => ({
	translation: getTranslation(state),
	isOpen: getIsDescriptionModalOpen(state),
});

const mapDispatchToProps = dispatch => ({
	onCancel: () => dispatch(onCloseDescriptionModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionModal);
