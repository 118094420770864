import React, { Component } from "react";
import "./CustomSwitch.scss";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import RadialProgressBar from "../../indicators/radialProgressBar/RadialProgressBar";

class CustomSwitch extends Component {
    constructor(props) {
        super(props);

        this.itemsRefs = [];

        this.state = {
            indicatorCurrentOption: null,
            indicatorWidth: 0,
            indicatorPosition: 0,
            isIndicatorAnimated: false,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.onResizeWindow);
        this.updateIndicator(this.props.selectedOption);
    }

    onResizeWindow = () => {
        this.updateIndicator(this.props.selectedOption);
    };

    componentDidUpdate(prevProps) {
        if (
            (!_.isEqual(prevProps.selectedOption.value, this.props.selectedOption.value) &&
                !_.isEqual(
                    this.state.indicatorCurrentOption.value,
                    this.props.selectedOption.value
                )) ||
            !_.isEqual(prevProps.options, this.props.options)
        ) {
            this.updateIndicator(this.props.selectedOption);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResizeWindow);
    }

    render() {
        return (
            <div className={`hf1-custom-switch  ${_.toLower(this.props.selectedOption.value)}`}>
                {_.map(this.props.options, (option, i) => (
                    <div
                        className="hf1-custom-switch-item"
                        key={option.value}
                        onClick={this.onChangeCurrentOption(option)}
                        ref={(elem) => {
                            this.itemsRefs[i] = elem;
                        }}
                    >
                        {option.label}
                        {!_.isNil(option.progress) ? (
                            <RadialProgressBar
                                progress={option.progress}
                                circleColor={this.props.circleColor}
                                showWarning={this.props.showWarning}
                            />
                        ) : null}
                    </div>
                ))}
                <div
                    className="hf1-custom-switch-indicator"
                    style={{
                        width: this.state.indicatorWidth,
                        left: this.state.indicatorPosition,
                        transition: this.state.isIndicatorAnimated ? "" : "none",
                    }}
                />
            </div>
        );
    }

    onChangeCurrentOption = (option) => () => {
        this.updateIndicator(option, true);
        this.props.onChange(option);
    };

    updateIndicator = (option, isAnimate = false) => {
        let itemIndex = _.findIndex(this.props.options, ["value", option.value]);
        let currentItemNode = ReactDOM.findDOMNode(this.itemsRefs[itemIndex]);
        let leftPosition = 0;
        let currentItemInfo = currentItemNode ? currentItemNode.getBoundingClientRect() : null;

        if (itemIndex > 0 && currentItemInfo) {
            let firstItemNode = ReactDOM.findDOMNode(this.itemsRefs[0]);
            if (firstItemNode) {
                let firstItemInfo = firstItemNode.getBoundingClientRect();
                leftPosition = currentItemInfo.x - firstItemInfo.x;
            }
        }

        this.setState({
            indicatorCurrentOption: option,
            indicatorWidth: currentItemInfo ? currentItemInfo.width : 0,
            indicatorPosition: leftPosition,
            isIndicatorAnimated: isAnimate,
        });
    };
}

CustomSwitch.propTypes = {
    selectedOption: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    circleColor: PropTypes.string,
};

CustomSwitch.defaultProps = {
    circleColor: "#fff",
};

export default CustomSwitch;
