import { connect } from "react-redux";
import SkillsWithoutLevelModal from "./SkillsWithoutLevelModal";
import { getTranslation } from "../../../../reducers/common/localization";
import {
    getActiveModalType,
    MODAL_SKILLS_WITHOUT_LEVEL,
    hideModal,
} from "../../../../reducers/common/activeModal";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_SKILLS_WITHOUT_LEVEL,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClose: () => {
            dispatch(hideModal());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsWithoutLevelModal);
