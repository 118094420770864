
import React from 'react';
import _ from 'lodash';
import { FormGroup, Label, Col, Input, UncontrolledPopover, PopoverBody } from 'reactstrap';
import CustomSelect from '../components/common/inputs/customSelect/CustomSelect';
import Icon from '../components/common/icon/Icon';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_LABEL_COL_NUM = 3;
const DEFAULT_INPUT_COL_NUM = 9;

export class FormUtils {

    static renderFormGroup(params, formParams) {
        if (!params || typeof (params) !== 'object') {
            return;
        }
        if (params.type === 'select') {
            return this.renderSelectFormGroup(params, formParams);
        }

        const id = _.kebabCase(params.fieldName);
        let handleChange = params.handleChange || (_.isFunction(formParams.onFieldChange) ? formParams.onFieldChange(params.fieldName) : null);

        // TODO: refactor

        if (formParams.inline) {
            if (formParams.inlineCol) {
                return (
                    <Col sm={formParams.inlineColSm || null} key={id}>
                        <FormGroup key={id}>
                            <Label for={id}>{params.title + (params.isRequired ? "*" : "")}</Label>
                            <Input
                                id={id}
                                className={params.className}
                                type={params.type || 'text'}
                                value={params.type === 'file' ? "" : formParams.data[params.fieldName]}
                                onChange={handleChange}
                                placeholder={params.placeholder || params.title}
                                required={params.isRequired}
                                disabled={params.isDisabled}
                            >
                            </Input>
                        </FormGroup>
                    </Col>
                );
            } else {
                return (
                    <FormGroup key={id}>
                        <Label for={id}>{params.title + (params.isRequired ? "*" : "")}</Label>
                        <Input
                            id={id}
                            className={params.className}
                            type={params.type || 'text'}
                            value={params.type === 'file' ? "" : formParams.data[params.fieldName]}
                            onChange={handleChange}
                            placeholder={params.placeholder || params.title}
                            required={params.isRequired}
                            disabled={params.isDisabled}
                        >
                        </Input>
                    </FormGroup>
                );
            }
        }

        return (
            <FormGroup row key={id} className={params.hintText ? "form-group-with-hint" : ""}>
                {this.renderFromGroupHint(params, formParams)}
                <Label for={id} sm={formParams.labelColNum || DEFAULT_LABEL_COL_NUM}>{params.title + (params.isRequired ? "*" : "")}</Label>
                <Col sm={formParams.inputColNum || DEFAULT_INPUT_COL_NUM}>
                    <Input
                        id={id}
                        className={params.className}
                        type={params.type || 'text'}
                        value={params.type === 'file' ? "" : formParams.data[params.fieldName]}
                        onChange={handleChange}
                        placeholder={params.placeholder || params.title}
                        required={params.isRequired}
                        disabled={params.isDisabled}
                    >
                    </Input>
                </Col>
            </FormGroup>
        );
    }

    static renderFromGroupHint(params, formParams) {
        if (params.hintText) {
            const id = "popover-" + uuidv4();
            return (
                <>
                    <Icon
                        id={id}
                        type="question_mark"
                        className="form-group-hint-icon"
                        title={_.get(formParams.translation, "actions.about_label")}
                    />
                    <UncontrolledPopover
                        placement="bottom"
                        target={id}
                        trigger="legacy"
                        className="text-pre-wrap"
                    >
                        <PopoverBody>
                            {params.hintText}
                        </PopoverBody>
                    </UncontrolledPopover>
                </>
            )
        }
    }

    static renderSelectFormGroup(params, formParams) {
        if (!params || typeof (params) !== 'object') {
            return;
        }

        const id = _.kebabCase(params.fieldName);

        let handleChange = params.handleChange || (_.isFunction(formParams.onSelectChange) ? formParams.onSelectChange(params.fieldName) : null);

        // TODO: refactor

        if (formParams.inline) {
            if (formParams.inlineCol) {
                return (
                    <Col sm={formParams.inlineColSm || null} key={id}>
                        <FormGroup key={id}>
                            <Label>{params.title + (params.isRequired ? "*" : "")}</Label>
                            <CustomSelect
                                className={params.className}
                                value={_.find(params.options, (option) => option.value === formParams.data[params.fieldName]) || null}
                                options={params.options}
                                onChange={handleChange}
                                isSearchable={params.isSearchable}
                                menuPosition="fixed"
                                placeholder={params.placeholder}
                                isRequired={params.isRequired}
                                isDisabled={params.isDisabled}
                            />
                        </FormGroup>
                    </Col>
                );
            } else {
                return (
                    <FormGroup key={id}>
                        <Label>{params.title + (params.isRequired ? "*" : "")}</Label>
                        <CustomSelect
                            className={params.className}
                            value={_.find(params.options, (option) => option.value === formParams.data[params.fieldName]) || null}
                            options={params.options}
                            onChange={handleChange}
                            isSearchable={params.isSearchable}
                            menuPosition="fixed"
                            placeholder={params.placeholder}
                            isRequired={params.isRequired}
                            isDisabled={params.isDisabled}
                        />
                    </FormGroup>
                );
            }
        }

        return (
            <FormGroup row key={id}>
                <Label sm={formParams.labelColNum || DEFAULT_LABEL_COL_NUM}>{params.title + (params.isRequired ? "*" : "")}</Label>
                <Col sm={formParams.inputColNum || DEFAULT_INPUT_COL_NUM}>
                    <CustomSelect
                        className={params.className}
                        value={_.find(params.options, (option) => option.value === formParams.data[params.fieldName]) || null}
                        options={params.options}
                        onChange={handleChange}
                        isSearchable={params.isSearchable}
                        menuPosition="fixed"
                        placeholder={params.placeholder}
                        isRequired={params.isRequired}
                        isDisabled={params.isDisabled}
                    />
                </Col>
            </FormGroup>
        );
    }

}
