
import React, { Component } from 'react';

class MicrophoneOutline extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 58 58">
                <path d="M43.1,28.1c-0.5,0-0.9,0.4-0.9,0.9v5.6c0,7.2-5.9,13.1-13.1,13.1s-13.1-5.9-13.1-13.1V29c0-0.5-0.4-0.9-0.9-0.9
		            S14,28.5,14,29v5.6c0,8,6.2,14.5,14.1,15v4.7h-4.7c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h11.3c0.5,0,0.9-0.4,0.9-0.9
		            s-0.4-0.9-0.9-0.9h-4.7v-4.7c7.8-0.5,14.1-7,14.1-15V29C44,28.5,43.6,28.1,43.1,28.1z"/>
                <path d="M29,44.9c5.7,0,10.3-4.6,10.3-10.3V12.1c0-5.7-4.6-10.3-10.3-10.3S18.7,6.4,18.7,12.1v22.5C18.7,40.3,23.3,44.9,29,44.9z
		            M20.6,12.1c0-4.7,3.8-8.4,8.4-8.4s8.4,3.8,8.4,8.4v22.5c0,4.7-3.8,8.4-8.4,8.4s-8.4-3.8-8.4-8.4V12.1z"/>
            </svg>
        );
    }
}

export default MicrophoneOutline;
