import { connect } from 'react-redux';
import ForbiddenErrorModal from "./ForbiddenErrorModal";
import { getTranslation } from "../../../../reducers/common/localization";
import { getForbiddenErrorStatus } from '../../../../reducers/common/errors';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        isOpen: getForbiddenErrorStatus(state)
    };
};

export default connect(mapStateToProps)(ForbiddenErrorModal);
