export const getFormData = (object) =>
    Object.keys(object).reduce((formData, key) => {
        if (key === "pictureFiles") {
            object[key].forEach((item) => {
                formData.append("pictureFiles", item);
            });
            return formData;
        } else {
            formData.append(key, object[key]);
            return formData;
        }
    }, new FormData());
