
import React, { Component } from 'react';

class TextMessage extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 -2 512 512">
                <path d="m448 508.800781-148.59375-92.800781h-267.40625c-17.671875 0-32-14.328125-32-32v-352c0-17.671875 14.328125-32 32-32h448c17.671875 0 32 14.328125 32 32v352c0 17.671875-14.328125 32-32 32h-32zm0 0" fill="#ffda6b" />
                <g fill="#ff7761">
                    <path d="m112 96h288c8.835938 0 16 7.164062 16 16s-7.164062 16-16 16h-288c-8.835938 0-16-7.164062-16-16s7.164062-16 16-16zm0 0" />
                    <path d="m112 160h288c8.835938 0 16 7.164062 16 16s-7.164062 16-16 16h-288c-8.835938 0-16-7.164062-16-16s7.164062-16 16-16zm0 0" />
                    <path d="m112 224h288c8.835938 0 16 7.164062 16 16s-7.164062 16-16 16h-288c-8.835938 0-16-7.164062-16-16s7.164062-16 16-16zm0 0" />
                    <path d="m416 320h-32v-32h32zm-64 0h-32v-32h32zm-64 0h-176c-8.835938 0-16-7.164062-16-16s7.164062-16 16-16h176zm0 0" />
                </g>
            </svg>
        );
    }
}

export default TextMessage;
