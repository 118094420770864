import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './UserPhoto.scss';
import { DEFAULT_IMAGE_PLACEHOLDER, JOB_IMAGE_PLACEHOLDER, USER_IMAGE_PLACEHOLDER } from '../../../constants/imagesConstants';
import { Utils } from '../../../utils/utils';
import { SELECTION_MODE } from '../../../constants/candidatesComparisonConstants';

export const USER_PHOTO_MODE = {
    VIEW: "VIEW",
    COMPARISON: "COMPARISON",
    SELECTION: "SELECTION"
}

// TODO: (BE) add candidates photos
// TODO: remove hardcoded values
const applicantsIcons = {
    7: "avatar_m_7",
    10: "avatar_m_1",
    11: "avatar_w_1",
    12: "avatar_m_2",
    13: "avatar_w_2",
    14: "avatar_m_3",
    15: "avatar_m_4",
    16: "avatar_m_5",
    17: "avatar_w_3",
    18: "avatar_m_6",
    19: "avatar_w_4",
    20: "avatar_w_5",
    21: "avatar_m_7",
    22: "avatar_w_6",
}

class UserPhoto extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDraggingUser: false
        }
    }

    render() {
        return (
            <div
                className={this.getClassName()}
                draggable={this.props.isDraggable}
                onDragStart={this.props.isDraggable ? this.handleDragStart : null}
                onDragEnd={this.props.isDraggable ? this.handleDragEnd : null}
                style={this.getStyles()}
                onClick={this.props.isClickable ? this.handlePhotoClick : null}
            />
        )
    }

    getClassName() {
        let className = "user-photo";

        if (this.state.isDraggingUser) {
            className += " is-dragging";
        }

        if (this.props.mode === USER_PHOTO_MODE.COMPARISON && this.props.anonymityMode) {
            className += " anonymity-mode";
        }

        if (this.props.isDraggable || this.props.isClickable) {
            className += " clickable";
        }

        if (this.props.mode === USER_PHOTO_MODE.COMPARISON && this.props.isUserSelected) {
            className += " selected";
            className += this.props.selectionMode === SELECTION_MODE.SINGLE ? " single-mode" : " multiple-mode";
        }

        if (this.props.size) {
            className += " size-" + this.props.size;
        }

        if (this.props.mode === USER_PHOTO_MODE.SELECTION && this.props.isUserSelected) {
            className += " selected multiple-mode";
        }

        return className;
    }

    getStyles() {
        let styles = {};

        if (this.props.mode === USER_PHOTO_MODE.COMPARISON && this.props.anonymityMode) {
            styles.backgroundColor = Utils.getColorByUUID(this.props.userId);
        } else {
            // TODO: (BE) add candidates photos
            let imageSource = DEFAULT_IMAGE_PLACEHOLDER;
            if (!this.props.userId) {
                // TODO: remove hardcoded values
                // imageSource = this.props.imageSource || JOB_IMAGE_PLACEHOLDER;
                imageSource = JOB_IMAGE_PLACEHOLDER;
            } else {
                if (applicantsIcons[this.props.userId]) {
                    // TODO: remove hardcoded values
                    // if (this.props.imageSource) {
                    // imageSource = this.props.imageSource;
                    imageSource = applicantsIcons[this.props.userId];
                } else {
                    imageSource = USER_IMAGE_PLACEHOLDER;
                }
            }
            styles.backgroundImage = "url(" + require(`./../../../images/userPhotos/${imageSource}.png`) + ")";
        }

        return styles;
    }

    handleDragStart = (e) => {
        this.setState({ isDraggingUser: true });
        e.dataTransfer.setData('userId', this.props.userId);
        this.props.onStartDragCandidate();
    }

    handleDragEnd = () => {
        this.setState({ isDraggingUser: false });
        this.props.onFinishDragCandidate();
    }

    handlePhotoClick = () => {
        this.props.onPhotoClick(this.props.userId);
    }
}

UserPhoto.defaultProps = {
    mode: USER_PHOTO_MODE.VIEW,
    size: "md",
    isDraggable: false,
    isClickable: false
}

UserPhoto.propTypes = {
    mode: PropTypes.string,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageSource: PropTypes.string,
    isUserSelected: PropTypes.bool,
    selectionMode: PropTypes.string,
    isDraggable: PropTypes.bool,
    isClickable: PropTypes.bool,
    anonymityMode: PropTypes.bool,
    size: PropTypes.string,
    onPhotoClick: PropTypes.func,
    onStartDragCandidate: PropTypes.func,
    onFinishDragCandidate: PropTypes.func
};

export default UserPhoto;
