import { connect } from 'react-redux';
import HardSkillsComparison from './HardSkillsComparison';
import { getSelectedSkills } from '../../../reducers/quickfind/quickfind';

const mapStateToProps = state => {
	return {
		skills: getSelectedSkills(state),
	};
};

export default connect(mapStateToProps, null)(HardSkillsComparison);
