import { connect } from "react-redux";
import { getTranslation } from "../../../reducers/common/localization";
import { deleteUserData, getUserData } from "../../../reducers/user/userData";
import PersonalSettings from "./PersonalSettings";
import {
    fetchAccountToResetPasswordData,
    fetchAccountToSendResetPasswordEmail,
    fetchChangeEmail,
    fetchChangePassword,
    getAccountToResetPasswordError,
} from "../../../reducers/authorization/resetEmailAndPassword";
import { logout } from "../../../reducers/authorization/login";
import {
    clearSecurity,
    fetchDisable2FA,
    fetchEnable2FA,
    fetchGenerate2FASecret,
    fetchGetSecurity,
    is2FAEnable,
} from "../../../reducers/authorization/security";
import {
    DELETE_APPLICANT_ACCOUNT_MODAL,
    DOUBLE_FA_MODAL,
    showModal,
} from "../../../reducers/common/activeModal";

const mapStateToProps = (state) => ({
    translation: getTranslation(state),
    userData: getUserData(state),
    error: getAccountToResetPasswordError(state),
    is2FAEnable: is2FAEnable(state),
});
const mapDispatchToProps = (dispatch) => ({
    onEnable2fa: (data) => dispatch(fetchEnable2FA(data)),
    onDisable2fa: () => dispatch(fetchDisable2FA()),
    onChangeEmail: (data) => {
        dispatch(fetchChangeEmail(data));
        dispatch(logout());
    },
    onChangePassword: (data) => {
        dispatch(fetchChangePassword(data));
        dispatch(logout());
    },
    onMount: () => dispatch(fetchGetSecurity()),
    onUnmount: () => dispatch(clearSecurity()),

    openDFAModal: () =>  dispatch(showModal(DOUBLE_FA_MODAL)),
    openDeleteAccountModal: () => dispatch(showModal(DELETE_APPLICANT_ACCOUNT_MODAL)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalSettings);
