import { connect } from 'react-redux';
import TablePagination from "./TablePagination";
import {
    getAllClustersCurrentPage,
    getAllClustersFetchingStatus,
    getAllClustersTotalPages,
    onFetchGetAllClustersDataByPage
} from '../../../reducers/admin/clusters';

const mapStateToProps = state => {
    return {
        current: getAllClustersCurrentPage(state),
        total: getAllClustersTotalPages(state),
        loading: getAllClustersFetchingStatus(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getDataForPage: (page) => {
            dispatch(onFetchGetAllClustersDataByPage(page));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePagination);
