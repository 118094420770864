import TopXCompareModal from "./TopXCompareModal";
import { connect } from "react-redux";
import { getTranslation } from "../../../../../reducers/common/localization";
import {
    getActiveModalType,
    hideModal,
    MODAL_INVITATIONS_TOP_X_COMPARE,
} from "../../../../../reducers/common/activeModal";
import {
    fetchGetApplicantsToCompare,
    getApplicantsToCompare,
} from "../../../../../reducers/organization/organizations";

const mapStateToProps = (state) => ({
    translation: getTranslation(state),
    isOpen: getActiveModalType(state) === MODAL_INVITATIONS_TOP_X_COMPARE,
    applicantsToCompare: getApplicantsToCompare(state),
});

const mapDispatchToProps = (dispatch) => ({
    onCloseModal: () => dispatch(hideModal()),
    onMount: (jobId) => dispatch(fetchGetApplicantsToCompare(jobId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopXCompareModal);
