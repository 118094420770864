import { connect } from 'react-redux';
import DepartmentsDetailsEditModal from './DepartmentsDetailsEditModal';
import { getTranslation } from '../../../../../reducers/common/localization';
import { getActiveModalType, MODAL_EDIT_DEPARTMENT, hideModal } from '../../../../../reducers/common/activeModal';
import { onFetchEditDepartment } from '../../../../../reducers/organization/departments';

const mapStateToProps = (state) => {
	return {
		translation: getTranslation(state),
		isOpen: getActiveModalType(state) === MODAL_EDIT_DEPARTMENT,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onClose: () => dispatch(hideModal()),
		onEditDepartment: (data) => dispatch(onFetchEditDepartment(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsDetailsEditModal);
