import _ from "lodash";
import { COMPARISON_LEVEL } from "../constants/skillsConstants";
export const SVG_TEXT_STYLE = "16px Arial, Helvetica, sans-serif";

export class SkillsUtils {
    static getTextWidth(text, style) {
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        context.font = style;
        return context.measureText(text).width;
    }

    static createSkillsTree(_skills, _clusters) {
        let clusters = [];
        if (!_skills) {
            _.forEach(_clusters, (cluster) => {
                if (cluster.userSkills.length) {
                    clusters.push({
                        categoryId: cluster.id,
                        categoryTitle: cluster.titles.en,
                        textWidth: this.getTextWidth(cluster.titles.en, SVG_TEXT_STYLE),
                        isNotModerated: cluster.isNotModerated,
                        children: cluster.userSkills.map((skill) => ({
                            clusters: skill.skill.clusters,
                            eagerness: skill.eagerness,
                            id: skill.skill.id,
                            isNotModerated: skill?.isNotModerated,
                            level: skill.level,
                            notes: skill?.notes,
                            skillLifecycle: skill.skillLifecycle,
                            spokenLevel: skill?.spokenLevel,
                            textWidth: this.getTextWidth(skill.skill.titles.en, SVG_TEXT_STYLE),
                            title: skill.skill.titles.en,
                            understandingLevel: skill?.understandingLevel,
                            version: skill?.version,
                            isSecondary: skill?.isSecondary,
                        })),
                    });
                }
            });
            return clusters;
        } else if (!_clusters) {
            let cluster = _skills.reduce((acc, skill) => {
                skill.textWidth = this.getTextWidth(skill.title, SVG_TEXT_STYLE);
                const isNewCluster = _.find(acc, (id) => id.categoryId === skill.categoryId[0].id);

                if (isNewCluster) {
                    isNewCluster.children.push(skill);
                    return acc;
                }
                let cluster = {
                    ...skill,

                    categoryId: skill.categoryId[0].id,
                    categoryTitle: skill.categoryTitle[0],
                    textWidth: this.getTextWidth(skill.categoryTitle, SVG_TEXT_STYLE),
                    children: [skill],
                };
                acc.push(cluster);
                return acc;
            }, []);
            return cluster;
        } else {
            let newSkills = _skills && _skills.filter((skill) => !skill.categoryId);
            _.forEach(_clusters, (cluster) => {
                if (!cluster.skills) {
                    clusters.push({
                        categoryId: cluster.categoryId,
                        categoryTitle: cluster.categoryTitle,
                        textWidth: this.getTextWidth(cluster.categoryTitle, SVG_TEXT_STYLE),
                        isNotModerated: cluster.isNotModerated,
                        children: [],
                    });
                } else {
                    clusters.push({
                        categoryId: cluster.categoryId,
                        categoryTitle: cluster.categoryTitle,
                        textWidth: this.getTextWidth(cluster.categoryTitle, SVG_TEXT_STYLE),
                        isNotModerated: cluster.isNotModerated,
                        children: cluster.skills.map((skill) => ({
                            ...skill,
                            clusters: skill.skill.clusters,
                            eagerness: skill.eagerness,
                            id: skill.skill.id,
                            isNotModerated: skill?.isNotModerated,
                            level: skill.level,
                            notes: skill?.notes,
                            skillLifecycle: skill.skillLifecycle,
                            spokenLevel: skill?.spokenLevel,
                            textWidth: this.getTextWidth(skill.skill.titles.en, SVG_TEXT_STYLE),
                            title: skill.skill.titles.en,
                            understandingLevel: skill?.understandingLevel,
                            version: skill?.version,
                            isSecondary: skill?.isSecondary,
                        })),
                    });
                }
            });

            if (newSkills && newSkills.length) {
                clusters.map((cluster) => {
                    newSkills.map((skill) => {
                        skill.textWidth = this.getTextWidth(skill.title, SVG_TEXT_STYLE);
                        skill.clusters &&
                            skill.clusters.map((clusters) => {
                                if (cluster.categoryId === clusters.clusterId) {
                                    cluster.children.push(skill);
                                }
                            });
                    });
                });
            }

            return clusters.map((cluster) => {
                if (cluster.children.length > 0) {
                    let children = cluster.children.map((child) => {
                        return _skills.find((skill) => {
                            if (child.id === skill.id) {
                                skill.textWidth = this.getTextWidth(skill.title, SVG_TEXT_STYLE);
                                return skill;
                            }
                        });
                    });
                    return { ...cluster, children };
                } else {
                    return cluster;
                }
            });
        }
    }

    static createComparisonSkillsTree(requiredSkills, actualSkills) {
        if (!requiredSkills || !actualSkills) {
            return null;
        }

        let result = [];

        _.map(requiredSkills, (requiredSkill) => {
            let actualSkill = _.find(actualSkills, (skill) => skill.id === requiredSkill.id);
            let comparisonLevel = null;
            if (actualSkill) {
                if (actualSkill.level >= requiredSkill.level) {
                    comparisonLevel = COMPARISON_LEVEL.PERFECT;
                } else {
                    comparisonLevel = COMPARISON_LEVEL.LOWER;
                }
            } else {
                comparisonLevel = COMPARISON_LEVEL.MISSING;
            }
            let resultSkill = {
                id: requiredSkill.id,
                title: requiredSkill.title,
                categoryId: requiredSkill.categoryId,
                categoryTitle: requiredSkill.categoryTitle,
                comparisonLevel: comparisonLevel,
                requiredData: {
                    level: requiredSkill.level,
                    requirementCoefficient: requiredSkill.requirementCoefficient,
                    notes: requiredSkill.notes,
                    version: requiredSkill.version,
                    spokenLevel: requiredSkill.spokenLevel,
                    understandingLevel: requiredSkill.understandingLevel,
                },
            };

            if (actualSkill) {
                resultSkill.actualData = {
                    level: actualSkill.level,
                    eagerness: actualSkill.eagerness,
                    notes: actualSkill.notes,
                    version: actualSkill.version,
                    spokenLevel: actualSkill.spokenLevel,
                    understandingLevel: actualSkill.understandingLevel,
                };
            }

            result.push(resultSkill);
        });

        let secondarySkills = _.differenceBy(actualSkills, requiredSkills, "id");
        _.map(secondarySkills, (secondarySkill) => {
            let resultSkill = {
                id: secondarySkill.id,
                title: secondarySkill.title,
                categoryId: secondarySkill.categoryId,
                categoryTitle: secondarySkill.categoryTitle,
                comparisonLevel: null,
                actualData: {
                    level: secondarySkill.level,
                    eagerness: secondarySkill.eagerness,
                    notes: secondarySkill.notes,
                    version: secondarySkill.version,
                    spokenLevel: secondarySkill.spokenLevel,
                    understandingLevel: secondarySkill.understandingLevel,
                },
                isSecondary: true,
            };

            result.push(resultSkill);
        });

        return this.createSkillsTree(result);
    }
}
