import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import _ from "lodash";
import { withRouter } from 'react-router-dom';

class ForbiddenErrorModal extends Component {
    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.onCancel}>
                <ModalHeader toggle={this.onCancel}>
                    {_.get(this.props.translation, "forbidden_modal.header_label")}
                </ModalHeader>
                <ModalBody>
                    <div>{_.get(this.props.translation, "forbidden_modal.no_access_label")}</div>
                    <div>{_.get(this.props.translation, "forbidden_modal.reload_page_label")}</div>
                </ModalBody>
                <ModalFooter className="content-center">
                    <Button color="primary" onClick={this.onCancel}>
                        {_.get(this.props.translation, "forbidden_modal.reload_page_button")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    onCancel = () => {
        this.props.history.go(0);
    }
}

ForbiddenErrorModal.propTypes = {
    translation: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default withRouter(ForbiddenErrorModal);
