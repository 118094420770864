import { connect } from 'react-redux';
import CandidateNotesModal from "./CandidateNotesModal";
import { getTranslation } from "../../../../../../reducers/common/localization";
import { getActiveModalType, hideModal, MODAL_CANDIDATE_NOTES } from '../../../../../../reducers/common/activeModal';
import {
    getCandidateIdForActiveModal,
    getCandidateNoteForActiveModal,
    onUpdateCandidateNotes,
    setCandidateForActiveModal
} from '../../../../../../reducers/job/openJob';

const mapStateToProps = state => {
    return {
        translation: getTranslation(state),
        isOpen: getActiveModalType(state) === MODAL_CANDIDATE_NOTES,
        userId: getCandidateIdForActiveModal(state),
        note: getCandidateNoteForActiveModal(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(hideModal());
            dispatch(setCandidateForActiveModal(null));
        },
        onUpdateCandidateNotes: (userId, notes) => {
            dispatch(onUpdateCandidateNotes(userId, notes));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateNotesModal);
