import { createAction, handleActions } from "redux-actions";
import { Api } from "../../api/api";
import _ from "lodash";
import { StorageManager } from "../../utils/storageManager";
import { setUserRoleAndSubRoles } from "./userRole";
import userApi from "../../api/userApi";

export const clearUserDataError = createAction("CLEAR_USER_DATA_ERROR");
export const fetchGetUserDataFailed = createAction("FETCH_GET_USER_DATA_FAILED");
export const fetchGetUserDataSuccess = createAction("FETCH_GET_USER_DATA_SUCCESS");
export const updateUserDataFailed = createAction("UPDATE_USER_DATA_FAILED");
export const updateUserDataSuccess = createAction("UPDATE_USER_DATA_SUCCESS");
export const deleteUserDataFailed = createAction("DELETE_USER_DATA_FAILED");
export const deleteUserDataSuccess = createAction("DELETE_USER_DATA_SUCCESS");
export const updateUserJobs = createAction("UPDATE_USER_JOBS");
export const updateUserJob = createAction("UPDATE_USER_JOB");
export const updateUserMediaDataFailed = createAction("UPDATE_USER_MEDIA_DATA_FAILED");
export const updateUserMediaDataSuccess = createAction("UPDATE_USER_MEDIA_DATA_SUCCESS");

export const onGetUserJobsSuccess = (data) => (dispatch) => {
    dispatch(updateUserJobs(data));
};

export const onGetUserDataSuccess = (data) => (dispatch) => {
    console.log("on user data ", data);

    StorageManager.setUserId(data.id);
    dispatch(setUserRoleAndSubRoles(data.role, data.subRoles));
    dispatch(fetchGetUserDataSuccess(data));
};

export const fetchGetUserData = createAction(
    "FETCH_GET_USER_DATA",
     () => Api.getUserData(),
    () => ({
        apiCall: true,
        onSuccess: onGetUserDataSuccess,
        onFail: fetchGetUserDataFailed,
    }),
);

export const updateUserData = createAction(
    "UPDATE_USER_DATA",
    (data) => Api.updateUserData(data),
    () => ({
        apiCall: true,
        onSuccess: updateUserDataSuccess,
        onFail: updateUserDataFailed,
    }),
);


export const deleteUserData = createAction(
    "DELETE_USER_DATA",
    () => Api.deleteApplicantProfile(),
    () => ({
        apiCall: true,
        onSuccess: deleteUserDataSuccess,
        onFail: deleteUserDataFailed,
    }),
);


export const getUserJobs = createAction(
    "GET_USER_JOBS",
    () => Api.fetchGetUsersJob(),
    () => ({
        apiCall: true,
        onSuccess: onGetUserJobsSuccess,
        onFail: updateUserDataFailed,
    }),
);

export const uploadUserMediaData = createAction(
    "UPLOAD_USER_MEDIA_DATA",
    (data) => Api.uploadUserMediaData(data),
    () => ({
        apiCall: true,
        onSuccess: updateUserMediaDataSuccess,
        onFail: updateUserMediaDataFailed,
    }),
);

export const deleteUserMediaByURI = createAction(
    "DELETE_USER_MEDIA_DATA",
    (mediaId) => Api.deleteUserMedia(mediaId),
    () => ({
        apiCall: true,
        onSuccess: updateUserMediaDataSuccess,
        onFail: updateUserMediaDataFailed,
    }),
);

export const putFavoriteJobApplicant = createAction(
    "PUT_FAVORITE_JOB_APPLICANT",
    (jobId, isFavorite) => Api.makeUserJobfavorite(jobId, isFavorite),
    () => ({
        apiCall: true,
        onSuccess: updateUserJob,
        onFail: updateUserDataFailed,
    }),
);

//- State
const initialState = {
    data: null,
    error: null,
    fetching: false,
    fetchingUploadMedia: false,
    fetchingUpdateUserData: false,
    userJobs: [],
};

//- Reducers
export default handleActions(
    {
        FETCH_GET_USER_DATA: (state) => {
            return { ...state, data: null, error: null, fetching: true };
        },
        FETCH_GET_USER_DATA_FAILED: (state, action) => {
            console.log("FETCH_GET_USER_DATA_FAILED", action);
            return { ...state, data: null, error: action.payload, fetching: false };
        },
        FETCH_GET_USER_DATA_SUCCESS: (state, action) => {
            return { ...state, data: action.payload, error: null, fetching: false };
        },
        UPDATE_USER_DATA: (state) => {
            return { ...state, error: null, fetchingUpdateUserData: true };
        },
        UPDATE_USER_DATA_FAILED: (state, action) => {
            return { ...state, error: action.payload, fetchingUpdateUserData: false };
        },
        UPDATE_USER_DATA_SUCCESS: (state, action) => {
            return { ...state, data: action.payload, error: null, fetchingUpdateUserData: false };
        },
        UPLOAD_USER_MEDIA_DATA: (state) => {
            return { ...state, error: null, fetchingUploadMedia: true };
        },
        UPDATE_USER_MEDIA_DATA_FAILED: (state, action) => {
            return { ...state, error: action.payload.message, fetchingUploadMedia: false };
        },
        UPDATE_USER_MEDIA_DATA_SUCCESS: (state, action) => {
            return { ...state, data: action.payload, error: null, fetchingUploadMedia: false };
        },
        CLEAR_USER_DATA_ERROR: (state) => {
            return { ...state, error: null };
        },
        UPDATE_USER_JOBS: (state, action) => {
            return {
                ...state,
                userJobs: action.payload.map((userJob) => ({
                    job: { ...userJob.job, favorite: userJob.applicantMetaData.applicantsFavorite },
                    jobAppId: userJob.jobApplicationId,
                })),
            };
        },
        DELETE_USER_DATA_SUCCESS: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    isDeleted: true,
                },
            };
        },
        UPDATE_USER_JOB: (state, action) => {
            return {
                ...state,
                userJobs: state.userJobs.map((userJob) => {
                    return userJob.jobAppId === action.payload.jobApplicationId
                        ? {
                            ...userJob,
                            job: {
                                ...userJob.job,
                                favorite: action.payload.applicantMetaData.applicantsFavorite,
                            },
                        }
                        : { ...userJob };
                }),
            };
        },
    },
    initialState,
);

//- Selectors
export const getUserDataState = (state) => state.userData;

export const getUserData = (state) => getUserDataState(state).data;

export const getUserDataLoading = (state) => getUserDataState(state).fetching;

export const getUserDataFetchingStatus = (state) =>
    getUserDataState(state).fetching ||
    getUserDataState(state).fetchingUploadMedia ||
    getUserDataState(state).fetchingUpdateUserData;

export const getUserDataError = (state) => getUserDataState(state).error;
export const getUserJobsState = (state) => state.userData.userJobs;

export const getUserDataFormatted = (state) => {
    let data = getUserData(state);

    if (_.isNil(data)) {
        return null;
    }

    const preferredJobDescription = !_.isEmpty(data.preferredJobDescription)
        ? {
            nationalId: data.preferredJobDescription.nationalId,
            exposeNationalId: data.preferredJobDescription.exposeNationalIdLevel,
            notifyBy: data.preferredJobDescription.notifyBy,
            currentSituation: data.preferredJobDescription.currentSituation,
            disturbingTime: data.preferredJobDescription.distributingTime,
            jobTitle: data.preferredJobDescription.jobTitle,
            isChooseProjectToContribute: data.preferredJobDescription.chooseProjectToContribute,
            isIncludeTrainingBudget: data.preferredJobDescription.includeTrainingBudget,
            isIncludeSpecialFood: !!data.preferredJobDescription.specialFoodType,
            specialFoodType: data.preferredJobDescription.specialFoodType,
            isIncludeWorkOut: data.preferredJobDescription.includeWorkOut,
            isSetOtherJobOpportunity: !!data.preferredJobDescription.otherJobOpportunity,
            otherJobOpportunity: data.preferredJobDescription.otherJobOpportunity,
            notificationsFrequency: data.preferredJobDescription.notificationsFrequency,
            experienceLevel: data.preferredJobDescription.experienceLevel,
            instantNotification: data.preferredJobDescription.instantNotification,
            isSetMinSalary: !!data.preferredJobDescription.minSalary,
            minSalary: data.preferredJobDescription.minSalary.amount,
            minSalaryCurrency: data.preferredJobDescription.minSalary.currency,
            maxCommuteTime: data.preferredJobDescription.maxCommuteTime,
            dreamLocations: data.preferredJobDescription.locations,
            percentageOfHomeWorking: data.preferredJobDescription.percentageOfHomeWorking,
            preferredContractType: data.preferredJobDescription.contractType,
        }
        : {};

    return {
        id: data.id,
        email: data.email,
        role: data.role,
        subRoles: data.subRoles,
        firstName: data.alias.firstName,
        lastName: data.alias.lastName,
        phoneNumber: data.alias.phoneNumber,
        countryCode: data.address?.countryCode,
        city: data.address?.city,
        district: data.address?.district,
        street: data.address?.street,
        twitterLink: data.twitterURI,
        linkedInLink: data.linkedinURI,
        facebookLink: data.facebookURI,
        profilePictureURI: data.profilePictureURI,
        videoFileUrl: data.videoFileURI,
        audioFileUrl: data.audioFileURI,
        pictureFileURIs: data.pictureFilesURI,
        videoLink: data.videoFileLink,
        ...preferredJobDescription,
    };
};

export const getUserDataField = (state, field) => {
    let data = getUserData(state);

    if (_.isNil(data)) {
        return null;
    }

    return data[field];
};

export const getUserEmail = (state) => getUserDataField(state, "email");

export const getUserId = (state) => getUserDataField(state, "id");
