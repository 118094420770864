import { createAction, handleActions } from 'redux-actions';
import { Api } from '../../api/api';
import { PROFILE_TYPES } from '../../constants/constants';
import { fetchGetBlackList } from './blackList';

// Actions
export const clearJobCompassError = createAction('CLEAR_JOB_COMPASS_ERROR');

export const fetchAlternativeProfilesFailed = createAction('FETCH_ALTERNATIVE_PROFILES_FAILED');
export const fetchAlternativeProfilesSuccess = createAction('FETCH_ALTERNATIVE_PROFILES_SUCCESS');
export const fetchAlternativeProfiles = createAction(
	'FETCH_ALTERNATIVE_PROFILES',
	() => Api.getAlternativeProfiles(),
	() => ({
		apiCall: true,
		onSuccess: fetchAlternativeProfilesSuccess,
		onFail: fetchAlternativeProfilesFailed
	})
);

export const fetchCurrentProfilesFailed = createAction('FETCH_CURRENT_PROFILES_FAILED');
export const fetchCurrentProfilesSuccess = createAction('FETCH_CURRENT_PROFILES_SUCCESS');
export const fetchCurrentProfiles = createAction(
	'FETCH_CURRENT_PROFILES',
	() => Api.getCurrentProfiles(),
	() => ({
		apiCall: true,
		onSuccess: fetchCurrentProfilesSuccess,
		onFail: fetchCurrentProfilesFailed
	})
);

export const fetchSelectedFutureProfilesFailed = createAction('FETCH_SELECTED_FUTURE_PROFILES_FAILED');
export const fetchSelectedFutureProfilesSuccess = createAction('FETCH_SELECTED_FUTURE_PROFILES_SUCCESS');
export const fetchSelectedFutureProfiles = createAction(
	'FETCH_SELECTED_FUTURE_PROFILES',
	() => Api.getFutureProfiles(),
	() => ({
		apiCall: true,
		onSuccess: fetchSelectedFutureProfilesSuccess,
		onFail: fetchSelectedFutureProfilesFailed
	})
);

export const setFutureProfileFailed = createAction('SET_FUTURE_PROFILE_FAILED');
export const setFutureProfileSuccess = createAction('SET_FUTURE_PROFILE_SUCCESS');
export const onSetFutureProfileSuccess = () => (dispatch) => {
	dispatch(setFutureProfileSuccess());
	dispatch(fetchSelectedFutureProfiles());
};
export const setFutureProfile = createAction(
	'SET_FUTURE_PROFILE',
	(profileId) => Api.setFutureProfile(profileId),
	() => ({
		apiCall: true,
		onSuccess: onSetFutureProfileSuccess,
		onFail: setFutureProfileFailed
	})
);

export const deleteProfileSuccess = createAction('DELETE_PROFILE_SUCCESS');
export const deleteProfileFailed = createAction('DELETE_PROFILE_FAILED');
export const onDeleteProfileSuccess = (type) => (dispatch) => {
	dispatch(deleteProfileSuccess());
	if (type === PROFILE_TYPES.ALTERNATIVE) {
		dispatch(fetchGetBlackList());
		dispatch(fetchAlternativeProfiles());
	}
	if (type === PROFILE_TYPES.FUTURE) {
		dispatch(fetchSelectedFutureProfiles());
	}
};
export const deleteProfile = createAction(
	'DELETE_PROFILE',
	({ id }) => Api.deleteProfile(id),
	({ type }) => ({
		apiCall: true,
		onSuccess: () => onDeleteProfileSuccess(type),
		onFail: deleteProfileFailed
	})
);

//- State
const initialState = {
	alternativeProfiles: [],
	currentProfiles: [],
	selectedFutureProfiles: [],
	error: null,
	fetchingAlternativeProfiles: false,
	fetchingCurrentProfiles: false,
	fetchingFutureProfiles: false,
	fetchingSetFutureProfile: false,
	deleteProfileLoading: false,
};

//- Reducers
export default handleActions({
	FETCH_ALTERNATIVE_PROFILES: (state) =>
		({ ...state, alternativeProfiles: [], error: null, fetchingAlternativeProfiles: true }),
	FETCH_ALTERNATIVE_PROFILES_SUCCESS: (state, action) =>
		({ ...state, alternativeProfiles: action.payload, error: null, fetchingAlternativeProfiles: false }),
	FETCH_ALTERNATIVE_PROFILES_FAILED: (state, action) =>
		({ ...state, alternativeProfiles: [], error: action.payload, fetchingAlternativeProfiles: false }),
	FETCH_CURRENT_PROFILES: (state) =>
		({ ...state, currentProfiles: [], error: null, fetchingCurrentProfiles: true }),
	FETCH_CURRENT_PROFILES_SUCCESS: (state, action) =>
		({ ...state, currentProfiles: action.payload, error: null, fetchingCurrentProfiles: false }),
	FETCH_CURRENT_PROFILES_FAILED: (state, action) =>
		({ ...state, currentProfiles: [], error: action.payload, fetchingCurrentProfiles: false }),
	FETCH_SELECTED_FUTURE_PROFILES: (state) =>
		({ ...state, selectedFutureProfiles: [], error: null, fetchingFutureProfiles: true }),
	FETCH_SELECTED_FUTURE_PROFILES_SUCCESS: (state, action) =>
		({ ...state, selectedFutureProfiles: action.payload, error: null, fetchingFutureProfiles: false }),
	FETCH_SELECTED_FUTURE_PROFILES_FAILED: (state, action) =>
		({ ...state, selectedFutureProfiles: [], error: action.payload, fetchingFutureProfiles: false }),
	SET_FUTURE_PROFILE: (state) =>
		({ ...state, error: null, fetchingSetFutureProfile: true }),
	SET_FUTURE_PROFILE_SUCCESS: (state) =>
		({ ...state, error: null, fetchingSetFutureProfile: false }),
	SET_FUTURE_PROFILE_FAILED: (state, action) =>
		({ ...state, error: action.payload, fetchingSetFutureProfile: false }),
	DELETE_PROFILE: (state) =>
		({ ...state, error: null, deleteProfileLoading: true }),
	DELETE_PROFILE_SUCCESS: (state) =>
		({ ...state, error: null, deleteProfileLoading: false }),
	DELETE_PROFILE_FAILED: (state, action) =>
		({ ...state, error: action.payload, deleteProfileLoading: false }),
	CLEAR_JOB_COMPASS_ERROR: (state) =>
		({ ...state, error: null }),
}, initialState);

//- Selectors
export const getJobCompassProfilesState = state => state.jobCompassProfiles;

export const getAlternativeProfilesData = state => getJobCompassProfilesState(state).alternativeProfiles;

export const getCurrentProfilesData = state => getJobCompassProfilesState(state).currentProfiles;

export const getSelectedFutureProfilesData = state => getJobCompassProfilesState(state).selectedFutureProfiles;

export const getJobCompassProfilesLoading = state =>
	getJobCompassProfilesState(state).fetchingAlternativeProfiles
	|| getJobCompassProfilesState(state).fetchingCurrentProfiles
	|| getJobCompassProfilesState(state).fetchingFutureProfiles
	|| getJobCompassProfilesState(state).fetchingSetFutureProfile
	|| getJobCompassProfilesState(state).deleteProfileLoading;

export const getJobCompassProfilesError = state => getJobCompassProfilesState(state).error;
