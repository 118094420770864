import React, { useState, useCallback, useEffect } from "react";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Button,
    Modal,
    ModalBody,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import classnames from "classnames";
import ModalHeader from "reactstrap/lib/ModalHeader";
import "react-voice-recorder/dist/index.css";
import useRecorder from "./useRecorder";
import { useDropzone } from "react-dropzone";

const ApplyMediaModal = (props) => {
    const [activeTab, setActiveTab] = useState("voiceTab");

    let [audioURL, isRecording, startRecording, stopRecording] = useRecorder();

    const [applyMedia, setApplyMedia] = useState({});

    const onDrop = useCallback((acceptedFiles) => {
        setApplyMedia({ key: "videoNote", content: acceptedFiles[0] });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    
    const { isOpen, onClose } = props;

    useEffect(() => {
        if (audioURL) {
            setApplyMedia({ key: "audioNote", content: audioURL });
        }
    }, [audioURL]);

    return (
        <Modal isOpen={isOpen} toggle={onClose} centered>
            <ModalHeader className="d-flex justify-content-center w-100 border-0">
                <div>
                    <h5> Choose media to apply with or apply with no media </h5>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "voiceTab",
                                })}
                                onClick={() => {
                                    setActiveTab("voiceTab");
                                }}
                            >
                                Voice Note
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "textTab",
                                })}
                                onClick={() => {
                                    setActiveTab("textTab");
                                }}
                            >
                                Text Note
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "videoTab",
                                })}
                                onClick={() => {
                                    setActiveTab("videoTab");
                                }}
                            >
                                Video Note
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="d-flex flex-column">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="voiceTab">
                            <div className="d-flex flex-column w-100 justify-content-center align-items-center">
                                <audio src={audioURL} controls />
                                <div className="d-flex flex-row justify-content-between mt-3">
                                    <Button
                                        onClick={startRecording}
                                        disabled={isRecording}
                                        className="mr-3"
                                    >
                                        start recording
                                    </Button>
                                    <Button onClick={stopRecording} disabled={!isRecording}>
                                        stop recording
                                    </Button>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="videoTab">
                            <div {...getRootProps()} className="apply-media__dropzone">
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop the files here ...</p>
                                ) : (
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                )}
                            </div>{" "}
                        </TabPane>
                        <TabPane tabId="textTab">
                            <FormGroup>
                                <Label for="exampleText">Provide your text note here</Label>
                                <Input
                                    type="textarea"
                                    name="text"
                                    id="exampleText"
                                    onChange={(e) =>
                                        setApplyMedia({ key: "textNote", content: e.target.value })
                                    }
                                />
                            </FormGroup>
                        </TabPane>
                    </TabContent>
                    <div className="d-flex flex-row justify-content-between mt-3">
                        <Button
                            color="primary"
                            onClick={() => props.onApplyJobs(props.jobId, applyMedia)}
                        >
                            Apply with media note
                        </Button>
                        <Button color="primary" onClick={() => props.onApplyJobs(props.jobId)}>
                            Or apply with no media
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ApplyMediaModal;
