import { connect } from "react-redux";
import { getActiveJobId } from "../../../../reducers/applicant/applicantJobs";
import { getTranslation } from "../../../../reducers/common/localization";
import { getUserId, getUserJobsState } from "../../../../reducers/user/userData";
import SkillsMap from "./SkillsAndFeedback";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        activeJobId: getActiveJobId(state),
        userId: getUserId(state),
        usersJobs: getUserJobsState(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsMap);
