import { connect } from "react-redux";
import CompanyRichMedia from "./CompanyRichMedia";
import { getTranslation } from "../../../reducers/common/localization";
import "./styles.scss";

const mapStateToProps = (state) => ({
    translation: getTranslation(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyRichMedia);
