import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Button, Label } from 'reactstrap';
import './ApplicantQuickfind.scss';
import { ROUTES } from '../../../constants/constants';
import CandidateItem from '../../common/candidateItem/CandidateItem.container';
import SetQuickfindSkillLevelModal
	from '../../common/modals/setQuickfindSkillLevelModal/SetQuickfindSkillLevelModal.container';
import QuickfindForm from '../../common/quickfindForm/QuickfindForm.container';
import Icon from '../../common/icon/Icon';
import ApplicantQuickComparison from '../applicantQuickComparison/ApplicantQuickComparison.container';

class ApplicantQuickfind extends Component {
	render() {
		const {
			translation,
			selectedCandidatesIds,
			skills,
			selectedSkillId,
			history,
			loading,
			applicants
		} = this.props;

		const selectedSkill = _.find(skills, (elem) => elem.id === selectedSkillId);

		if (loading) {
			return (
				<div className="loading-wrapper">
					<h6 className="text-center">{_.get(translation, "actions.fetching_message")}</h6>
				</div>
			)
		}

		return (
			<>
				<div className="applicant-quick-find">
					<QuickfindForm isQuickfindJob={false} />
					<div className="candidate-pull">
						<h5 className="page-subsection-header">{_.get(translation, 'applicant_quickfind_page.job_candidates_label')}</h5>
						<div className="balance-icon-description-wrapper">
							<h6>{_.get(translation, 'shared.the')}</h6>
							<Icon
								type="compare"
								color="secondary"
								className="compare-icon"
							/>
							<h6>{_.get(translation, 'applicant_quickfind_page.balance_icon_description')}</h6>
						</div>
						<h6>{_.get(translation, 'applicant_quickfind_page.job_candidates_subheader_label')}</h6>
						<div className="filtered-candidates-wrapper">
							{
								!!applicants.length
									? applicants.map(applicant => (
										<CandidateItem
											key={applicant.id}
											applicant={applicant}
										/>
									))
									: (
										<Label className="no-applicants-message">
											{_.get(translation, "applicant_quickfind_page.no_applicants_message")}
										</Label>
									)
							}
						</div>
						<div className="compare-button-wrapper">
							<Button
								color="primary"
								disabled={selectedCandidatesIds.length < 2}
								onClick={() => history.push(ROUTES.APPLICANTS_QUICKFIND_COMPARISON)}
							>
								{_.get(translation, 'actions.compare_selected_candidates')}
							</Button>
						</div>
					</div>
				</div>
				<SetQuickfindSkillLevelModal
					id={selectedSkillId}
					level={selectedSkill ? selectedSkill.level : null}
				/>
				<ApplicantQuickComparison />
			</>
		);
	}
}

ApplicantQuickfind.propTypes = {
	translation: PropTypes.object.isRequired,
	selectedCandidatesIds: PropTypes.array.isRequired,
	selectedSkillId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	skills: PropTypes.array.isRequired,
	history: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	applicants: PropTypes.array.isRequired
};

export default withRouter(ApplicantQuickfind);

