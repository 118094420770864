import React, { Component } from "react";

class CorporateAccount extends Component {
    render() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 128"
            >
                <defs></defs>
                <title>a</title>
                <path
                    fill="#3b4551"
                    d="M102.64854,88.474v4.17013L62.48166,86.32006,47.50738,56.38876H69.73621c-.448-1.03385-.87883-2.10221-1.27523-3.18781a51.739,51.739,0,0,1-1.55078-4.99721H4.96253A4.08891,4.08891,0,0,0,.8614,52.30487v68.80582a4.08891,4.08891,0,0,0,4.10113,4.10113h101.7699a4.08891,4.08891,0,0,0,4.10113-4.10113V81.02994A87.74276,87.74276,0,0,1,102.64854,88.474ZM9.06366,56.38876H38.34015L52.539,84.752,9.06366,77.911Zm0,60.6208V86.21667l47.97286,7.54745,11.63134,23.24543Zm93.58488,0H77.835L66.99633,95.33226l35.65221,5.61752Z"
                />
                <path
                    fill="#2b77c0"
                    d="M121.25635,14.47589C115.86327,7.37984,107.2219,2.78818,98.711,2.78818S81.55854,7.37984,76.16546,14.47589c-7.5769,9.9696-7.04357,25.51928-2.85009,36.94658C80.80644,71.8361,98.71085,84.983,98.71085,84.983s17.90451-13.14689,25.3957-33.56052C128.29992,39.99517,128.83325,24.44549,121.25635,14.47589ZM98.711,48.69869a17.97077,17.97077,0,1,1,17.97067-17.97077A17.97081,17.97081,0,0,1,98.711,48.69869Z"
                />
            </svg>
        );
    }
}

export default CorporateAccount;
