export const CANDIDATE_GROUP = {
    NO_GROUP: 0,
    TOP_X: 1,
    NO_GO: 2
}

export const SELECTION_MODE = {
    SINGLE: "SINGLE",
    MULTIPLE: "MULTIPLE"
}

export const VIEW_MODE = {
    HARD_SKILLS: "HARD_SKILLS",
    SOFT_SKILLS: "SOFT_SKILLS",
    COMPARING_DASHBOARD: "COMPARING_DASHBOARD"
}

export const COMPARING_MODE = {
    HARD_SKILLS: "HARD_SKILLS",
    SOFT_SKILLS: "SOFT_SKILLS",
    COMBINED: "COMBINED"
}

export const SORTING_MODE = {
    DATE: "DATE",
    HARD_SKILLS: "HARD_SKILLS",
    SOFT_SKILLS: "SOFT_SKILLS",
    COMBINED: "COMBINED"
}

export const SORTING_MODES_LIST = [SORTING_MODE.DATE, SORTING_MODE.HARD_SKILLS, SORTING_MODE.SOFT_SKILLS, SORTING_MODE.COMBINED];

export const SORTING_MODE_ICON = {
    [SORTING_MODE.DATE]: "calendar",
    [SORTING_MODE.HARD_SKILLS]: "gear",
    [SORTING_MODE.SOFT_SKILLS]: "brain",
    [SORTING_MODE.COMBINED]: "gear_and_brain"
};

export const SORTING_MODE_TITLE = {
    [SORTING_MODE.DATE]: "job_and_candidates.apply_date_label",
    [SORTING_MODE.HARD_SKILLS]: "job_and_candidates.hard_skills_label",
    [SORTING_MODE.SOFT_SKILLS]: "job_and_candidates.soft_skills_label",
    [SORTING_MODE.COMBINED]: "job_and_candidates.hard_and_soft_skills_label"
};

export const SOFT_SKILLS_MODE = {
    FFM: "FFM",
    TEST_1: "TEST_1",
    TEST_2: "TEST_2"
}
