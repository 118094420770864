
import React, { Component } from 'react';

class CV extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 -48 512 512">
                <path d="m512 416h-96v-32h-64v32h-192v-32h-64v32h-96v-368c0-26.507812 21.492188-48 48-48h416c26.507812 0 48 21.492188 48 48zm0 0" fill="#82b1ff" />
                <path d="m256 128c0 35.347656-28.652344 64-64 64s-64-28.652344-64-64 28.652344-64 64-64 64 28.652344 64 64zm0 0" fill="#448aff" />
                <path d="m320 320h-256v-48c0-26.507812 21.492188-48 48-48h160c26.507812 0 48 21.492188 48 48zm0 0" fill="#448aff" />
                <g fill="#fff">
                    <path d="m368 80h64c8.835938 0 16 7.164062 16 16s-7.164062 16-16 16h-64c-8.835938 0-16-7.164062-16-16s7.164062-16 16-16zm0 0" />
                    <path d="m368 144h64c8.835938 0 16 7.164062 16 16s-7.164062 16-16 16h-64c-8.835938 0-16-7.164062-16-16s7.164062-16 16-16zm0 0" />
                    <path d="m368 208h64c8.835938 0 16 7.164062 16 16s-7.164062 16-16 16h-64c-8.835938 0-16-7.164062-16-16s7.164062-16 16-16zm0 0" />
                    <path d="m368 272h64c8.835938 0 16 7.164062 16 16s-7.164062 16-16 16h-64c-8.835938 0-16-7.164062-16-16s7.164062-16 16-16zm0 0" />
                </g>
            </svg>
        );
    }
}

export default CV;
