import { connect } from "react-redux";
import InvitationsManagementLifecycle from "./InvitationsManagementLifecycle";
import { getTranslation } from "../../../../../reducers/common/localization";
import {
    fetchCloseJob,
    getApplicantsAppliedToJob,
} from "../../../../../reducers/organization/organizations";

const mapStateToProps = (state) => ({
    translation: getTranslation(state),
    appliedApplicants: getApplicantsAppliedToJob(state),
});

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseJob: (jobId) => dispatch(fetchCloseJob(jobId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitationsManagementLifecycle);
