import { connect } from "react-redux";
import ApplicantAccount from "./ApplicantAccount";
import { getTranslation } from "../../../reducers/common/localization";
import {
    getUserDataFormatted,
    updateUserData,
    uploadUserMediaData,
    deleteUserData,
    getUserData,
} from "../../../reducers/user/userData";
import { getLocationData } from "../../../reducers/common/location";

const mapStateToProps = (state) => {
    return {
        translation: getTranslation(state),
        userData: getUserDataFormatted(state),
        locationData: getLocationData(state),
        userDataNotFormatted: getUserData(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateUser: (data) => dispatch(updateUserData(data)),
        onUploadMediaUser: (data) => dispatch(uploadUserMediaData(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantAccount);
